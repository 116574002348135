import React, { useCallback } from "react"

import {DataTable as PDataTable} from "primereact/datatable";

import "./index.css";

export function DataTable(props) {

    const footerDataTable = useCallback(() => {
        if (!props.valuelength) {
            return null;
        }
        return (
            <div style={{textAlign: 'right'}}>
                {`Total de registros encontrados: ${props.valuelength}`}
            </div>
        );
    }, [props.valuelength]);

    return (
        <div className="data-table-container">
            <label>{props.label}</label>
            <PDataTable
                footer={footerDataTable}
                rows={20}
                paginator                
                emptyMessage="Nenhum registro encontrado"
                size="small"
                {...props}
                showGridlines
                stripedRows
                rowHover
                
            />
        </div>
    );

}
