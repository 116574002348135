import React from "react";
import {InputText as PInputText} from "primereact/inputtext";
import {InputTextarea as PInputTextarea} from "primereact/inputtextarea";
import classNames from "classnames";
import {calcularTamanho} from "../../service/guiHelper";

import './styles.scss'

export function InputText(props) {

    function handleChange(event) {
        if (props.onChange) {
            props.onChange({name: props.name, value: event.target.value, index: props.index});
        }
    }

    const classes = !!props.class_names ? props.class_names : calcularTamanho(props.col);

    const inputClasses = classNames({
        "p-invalid block": props.invalid,
    });

    const component = (
        props.multiline
        ? <PInputTextarea autoComplete={props.type === "password" ? "new-password" : "off"} {...props} placeholder={props.placeholder ? props.type === "password" ? props.placeholder : `Digite ${props.placeholder}` : null} className={inputClasses} onChange={handleChange}/>
        : <PInputText autoComplete={props.type === "password" ? "new-password" : "off"} {...props} placeholder={props.placeholder ? props.type === "password" ? props.placeholder : `Digite ${props.placeholder}` : null} className={inputClasses} onChange={handleChange}/>
    );

    const newLocal = props.required ? <small style={{color: props.value?.length ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : <small>&nbsp;</small>;

    return (
        <div style={{position: "relative"}} className={classes}>
            <label>{props.label}</label>
            {props.labelRight ? <label style={{float: "right"}}>{props.labelRight}</label> : null}
            {component}
            {props.reduced ? null : (props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : newLocal)}
            {props.children}
        </div>
    );

}
