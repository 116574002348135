import React, {useMemo} from 'react';
import {TabelaUsuario} from "./TabelaUsuario";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {Panel} from "primereact/panel";
import {ToggleViewHidden} from "../../components/button/ViewHiddenButton";
import {If} from "../../components/conditional/If";
import {useBreakpoint} from "../../context/BreakpointContext";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {SearchButton} from "../../components/button/SearchButton";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {useQueryClientUsuario} from "../../utils/query-clients/queryClients";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {BlockUI} from "primereact/blockui";

export function Usuarios() {

    const {roles} = useAuth();
    const navigate = useNavigate();
    const {isLargeDevice} = useBreakpoint();
    const {query, handleSearch} = useQueryClientUsuario();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.Usuario);

    function handleCriar() {
        navigate("/usuarios/new");
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel headerTemplate={() => headerTemplate("Usuários", "fa-user")}>
                <PanelContent>
                    <InputText placeholder="o nome da pessoa" col={4} label="Nome" value={params.nome} name="nome" onChange={handleChange}/>
                    <InputText placeholder="o e-mail do usuário" col={4} label="E-mail" value={params.email} name="email" onChange={handleChange}/>
                    <InputText placeholder="a descrição de um perfil" col={4} label="Perfil" value={params.perfil} name="perfil" onChange={handleChange}/>
                </PanelContent>
                <PanelFooter >
                    <div style={{float: "left"}}>
                        <ToggleViewHidden params={params} onChange={handleChange}/>
                    </div>
                    <If condition={roles.USRA}>
                        <Button success onClick={handleCriar} label={isLargeDevice ? "Novo" : null} icon="pi pi-plus" autowidth={!isLargeDevice}/>
                    </If>
                    <SearchButton query={query} onSearch={handleSearch}/>
                </PanelFooter>
            </Panel>
            {useMemo(() => <TabelaUsuario value={query.data || []}/>, [query.data])}
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
