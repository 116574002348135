
import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import './style.scss'

export const Spinner = (props) => {
    return (
        <div className={`${props.basico ? '' : 'card'} height w-full flex py-8 justify-content-center align-items-center`}>
            <ProgressSpinner
                style={{width: '100px', height: '100px'}}
                strokeWidth="3"
                fill="var(--surface-ground)"
                animationDuration='1s'
            />
        </div>
    );
}
