import React, {useEffect, useState} from 'react';
import {FormEdit} from "../../components/form/Edit";
import {useParams} from "react-router-dom";
import {InputText} from "../../components/inputtext";
import {buildValidator, isRequired} from "../../utils/fieldValidator";
import { useAuth } from '../../context/AuthContext';
import { marcaService } from '../../service/marcaService';

export function EditarProdutoMarca() {

    const {id} = useParams();
    const {roles} = useAuth();

    const [marca, setMarca] = useState(marcaService.criar());

    const [messages, setMessages] = useState(buildValidator());

    function handleChange(event) {
        setMarca({...marca, [event.name]: event.value});
    }

    useEffect(() => {
        if (id !== "new") {
            marcaService.buscar(id).then(setMarca);
        }
    }, [id]);

    return (
        <FormEdit podeDeletar={!!roles.MARD}
                  podeSalvar={!!roles.MARC}
                  descricao="Marca de Produto"
                  onValidate={setMessages}
                  service={marcaService}
                  value={marca}
                  blocked={id !== "new" && !marca.id}
        >
            <InputText 
                required 
                onBlur={() => {
                    messages.descricao = isRequired(marca.descricao);
                    setMessages({...messages});
                }} 
                invalid={messages.descricao} 
                col={12} 
                label="Descrição" 
                name="descricao" 
                value={marca.descricao} 
                onChange={handleChange}
            />
        </FormEdit>
    );

}
                            