import React from "react";
import {Dropdown} from "./index";
import {optionsFluxoOperacoes} from "../../service/ordemServicoEtapaService";

export function FluxoOperacaoDropdown(props) {

	if (props.nullable) {
		return <Dropdown options={[{label: "Todas", value: null}, ...optionsFluxoOperacoes]} label="Operação" {...props}/>;
	}

	return <Dropdown options={optionsFluxoOperacoes} label="Operação" {...props}/>;

}
