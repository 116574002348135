import moment from "moment";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import React from "react";
import {DataTable} from "../../components/datatable";
import {PanelContent} from "../../components/panel";

export function EditarOrdemServicoMovimentacaoFisica({item}) {

	const movimentacaoHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column header="Responsável"/>
				<Column style={{width: "13em"}} header="Data"/>
				<Column header="Prateleira"/>
				<Column header="Observação"/>
			</Row>
		</ColumnGroup>
	);

	return (
		<PanelContent>
			<div className="col-12">
				<DataTable headerColumnGroup={movimentacaoHeaderGroup} value={item.movimentacoes}>
					<Column style={{flexDirection: "column", alignItems: "start"}} field="responsavel.autoComplete" header="Responsável"/>
					<Column style={{width: "13em"}} field="registro" header="Data" body={osim => <div style={{textAlign: "center"}}>{moment(osim.registro).format("DD/MM/YYYY HH:mm")}</div>}/>
					<Column style={{flexDirection: "column", alignItems: "start"}} field="prateleira.autoComplete" header="Prateleira"/>
					<Column style={{flexDirection: "column", alignItems: "start"}} field="observacao" header="Observação"/>
				</DataTable>
			</div>
		</PanelContent>
	);

}
