import React, {useCallback, useEffect, useState} from 'react';
import { clienteService } from '../../../../service/clienteService';
import { transportadoraService } from '../../services/transportadoraService';
import { Calendar } from '../../../../components/calendar';
import { ClienteAutoComplete } from '../../../../components/autocomplete/ClienteAutoComplete';
import { InputText } from '../../../../components/inputtext';
import { CondicaoPagamentoAutoComplete } from '../../../../components/autocomplete/CondicaoPagamentoAutoComplete';
import { VendedorAutoComplete } from '../../../../components/autocomplete/VendedorAutoComplete';
import { TransportadoraAutoComplete } from '../../../../components/autocomplete/TransportadoraAutoComplete';
import { Dropdown } from '../../../../components/dropdown';
import { InputCurrency, InputNumber } from '../../../../components/inputnumber';
import { formaPagamento,  pagamentoFrete, tiposOrcamento } from '../../services/pedidoVendaService';
import { isBiggerThanZeroValor, isEntityRequired, isRequired } from '../../../../utils/fieldValidator';
import { BlockUI } from 'primereact/blockui';
import { PanelContent } from '../../../../components/panel';
import { IndiceAutoComplete } from '../../../../components/autocomplete/IndiceAutoComplete';
import { indiceService } from '../../services/indiceService';

export function EditarDadosPrincipais({pedidoVenda, setPedidoVenda, messages, setMessages, disabled }) {

	const [blocked, setBlocked] = useState(false);
    
    const handleChange = useCallback((event) => {

		setPedidoVenda({...pedidoVenda, [event.name]: event.value});
	}, [pedidoVenda, setPedidoVenda]);

	const handleChangeOrcamento = useCallback((event) => {
		setPedidoVenda({...pedidoVenda, orcamento: {...pedidoVenda.orcamento,[event.name]: event.value}});
	}, [pedidoVenda, setPedidoVenda]);
	
	const handleSelectCliente = useCallback((event) => {
		if (event.originalEvent?.type === "blur") return;
		if (event.value.id) {
			setBlocked(true);
			clienteService.buscar(event.value.id).then( (cliente) => {			
				pedidoVenda.orcamento.vendedor =  cliente.vwCliente.vendedor;
				pedidoVenda.orcamento.condicaoPagamento = cliente.vwCliente.condicaoPagamento;
				setBlocked(false);
				setPedidoVenda({...pedidoVenda, cliente})
			});
		}
    }, [pedidoVenda, setPedidoVenda]);

	const handleSelectIndice = useCallback((event) => {		
		if (event.originalEvent?.type === "blur") return;		
		if (event.value.id) {
			const params = [`indice=${event.value.codigo}`];			
			if (pedidoVenda.orcamento.data) {
				params.concat(`data=${pedidoVenda.orcamento.data}`);
			}

			setBlocked(true);
			indiceService.cotacao(params).then( (cotacao) => {
				pedidoVenda.orcamento.valorCotacao = cotacao?.valor;
				pedidoVenda.orcamento.indice = event.value;
				setBlocked(false);
				setPedidoVenda({...pedidoVenda})
			});
		}
    }, [pedidoVenda, setPedidoVenda]);

	const handleSelectTransportadora = useCallback((event) => {
		if (event.originalEvent?.type === "blur") return;
		if (event.value.id) {
			setBlocked(true);
			transportadoraService.buscar(event.value.id).then( (transportadora)  => {
				setBlocked(false);
				setPedidoVenda({...pedidoVenda, transportadora})				
			});
		}
    }, [pedidoVenda, setPedidoVenda]);

	const handleSelectDistribuidor = useCallback((event) => {
		if (event.originalEvent?.type === "blur") return;
		if (event.value.id) {
			setBlocked(true);
			clienteService.buscar(event.value.id).then( (distribuidor) => { 
				setBlocked(false);
				setPedidoVenda({...pedidoVenda, orcamento: {...pedidoVenda.orcamento, distribuidor}})
			});
		}
    }, [pedidoVenda, setPedidoVenda]);

	const  validarItem = (name) =>  {
		
		let erro;		
		switch (name) {
            case 'cliente':
                erro = isEntityRequired(pedidoVenda.cliente)
                break;
            case 'distribuidor':
				if(pedidoVenda.orcamento?.tipo === 'PEDIDO_VENDA') return
				erro = isEntityRequired(pedidoVenda.orcamento.distribuidor)				
                break;
            case 'condicaoPagamento':
                erro = isEntityRequired(pedidoVenda.orcamento?.condicaoPagamento);
                break;
            case 'vendedor':
                erro = isEntityRequired(pedidoVenda.orcamento?.vendedor);
                break;
            case 'frete':
				if (pedidoVenda.status === 'ORCAMENTO') return;
                erro = isRequired(pedidoVenda.frete);
                break;
            case 'formaPagamento':
                erro = isRequired(pedidoVenda.formaPagamento);
                break;
            case 'validade':
                erro = isRequired(pedidoVenda.orcamento?.validade);
                break;
			case 'dataComissao':
				if (pedidoVenda.status === 'APROVADO' && pedidoVenda.orcamento?.tipo === 'DISTRIBUIDOR') {
					erro = isRequired(pedidoVenda.orcamento?.dataComissao);
				}
				break;
            case 'valorFrete':
				if(pedidoVenda.frete === 'FOB' && pedidoVenda.orcamento?.tipo === 'PEDIDO_VENDA'){
					erro = isBiggerThanZeroValor(pedidoVenda.valorFrete);
				}else{
					erro = null
				}
                break;
            default:
                break;
		}
		
        setMessages((messages) => {
            return {...messages, [name]: erro}
        })
    }


	return (
		<BlockUI blocked={blocked}>
			<PanelContent>
				<Calendar 
					col={2} 
					disabled 
					name="registro" 
					showTime 
					showSeconds 
					label="Data de Inclusão" 
					value={pedidoVenda.registro}
				/>

			<div className="col-2"/>
				<Dropdown 
					options={tiposOrcamento} 					
					label="Tipo de Orçamento" 
					onChange={(e) =>  {
						setMessages((messages) => {
							return {...messages, distribuidor: null}
						})
						handleChangeOrcamento(e)
					}} 
					name='tipo'
					value={pedidoVenda?.orcamento?.tipo}
					col={2} 
					disabled={disabled} 
				/>
				<Calendar 
					col={2} 
					disabled={disabled} 
					name="data" 
					minDate={new Date()} 
					label="Data do Orçamento" 
					value={pedidoVenda.orcamento?.data} 
					onChange={handleChangeOrcamento} 
					invalid={messages.validade}
					onBlur={() => validarItem('data')}
				/>
				<Calendar 
					col={2} 
					disabled={disabled} 
					name="validade" 
					minDate={new Date()} 
					label="Data de Validade" 
					value={pedidoVenda.orcamento?.validade} 
					onChange={handleChangeOrcamento} 
					invalid={messages.validade}
					onBlur={() => validarItem('validade')}
				/>

				<InputNumber
					value={pedidoVenda.orcamento?.prazoEntrega} 
					onChange={handleChangeOrcamento} 
					name='prazoEntrega'
					mode="decimal" 
					showButtons 
					min={1} 
					max={360} 
					col={2}
					suffix=' dias'
					disabled={disabled} 
					label='Prazo de Entrega'
				/>	
				
				<ClienteAutoComplete 
					disabled={disabled} 
					col={4} value={pedidoVenda.cliente} 
					name="cliente" 
					onChange={handleChange} 
					onSelect={handleSelectCliente} 
					invalid={messages.cliente}
					onBlur={() => validarItem('cliente')}
				/>

				<InputText 
					readOnly col={4}  
					label="Nome Fantasia" 
					value={pedidoVenda.cliente?.nomeFantasia || ""} 
				/>

				<InputText 
					readOnly 
					col={2} 
					label="Status" 
					value={pedidoVenda.cliente?.vwCliente?.status || ""} 
				/>

				<InputText 
					readOnly 
					col={2} 
					label="CPF/CNPJ" 
					value={pedidoVenda.cliente?.vwCliente?.cpfCnpjFormatado || ""} 
				/>
				
				<ClienteAutoComplete 
					disabled={disabled}
					col={4} 
					value={pedidoVenda.orcamento?.distribuidor}
					name="distribuidor" 
					onChange={handleChangeOrcamento} 
					onSelect={handleSelectDistribuidor} 
					invalid={messages.distribuidor}
					label='Distribuidor'
					onBlur={() => validarItem('distribuidor')}
					required={pedidoVenda.orcamento?.tipo === 'DISTRIBUIDOR'}  
				/>

				<InputText 
					readOnly 
					col={3}  
					label="Nome Fantasia" 
					value={pedidoVenda.orcamento?.distribuidor?.nomeFantasia || ""} 
				/>

				<InputText 
					readOnly 
					col={1} 
					label="Status" 
					value={pedidoVenda.orcamento?.distribuidor?.vwCliente?.status || ""} 
				/>

				<InputText 
					readOnly 
					col={2} 
					label="CPF/CNPJ" 
					value={pedidoVenda.orcamento?.distribuidor?.vwCliente?.cpfCnpjFormatado || ""} 
				/>

				<Calendar 
					col={2} 
					disabled={disabled} 
					name="dataEnvioDistribuidor" 
					minDate={new Date()} 
					label="Data de Envio (Distribuidor)" 
					value={pedidoVenda.orcamento?.dataEnvioDistribuidor} 
					onChange={handleChangeOrcamento} 
					invalid={messages.validade}
				/>

				<VendedorAutoComplete 
					label='Responsável' 
					onBlur={() => validarItem('vendedor')} 
					disabled={disabled} 
					dropdown 
					col={12} 
					value={pedidoVenda.orcamento?.vendedor} 
					name="vendedor" 
					onChange={handleChangeOrcamento} 
					invalid={messages.vendedor}
				/>
				
				<CondicaoPagamentoAutoComplete 
					onBlur={() => validarItem('condicaoPagamento')} 
					disabled={disabled} 
					dropdown 
					col={4} 
					value={pedidoVenda.orcamento?.condicaoPagamento} 
					name="condicaoPagamento" 
					onChange={handleChangeOrcamento} 
					invalid={messages.condicaoPagamento}
				/>

				<IndiceAutoComplete 					
					// disabled={disabled || !pedidoVenda.orcamento.data} 
					dropdown
					onSelect={handleSelectIndice}  
					col={4} 
					value={pedidoVenda.orcamento?.indice} 
					name="indice" 
					onChange={handleChangeOrcamento}
				/>

				<Dropdown 
					disabled={disabled} 
					options={formaPagamento} 
					col={4} 
					onBlur={() => validarItem('formaPagamento')} 
					name="formaPagamento" 
					label="Forma de Pagamento" 
					value={pedidoVenda.formaPagamento} 
					onChange={handleChange} 
					invalid={messages.formaPagamento}
				/>

				<InputCurrency
					value={pedidoVenda.orcamento?.valorCotacao} 
					onChange={handleChangeOrcamento} 
					col={2}
					name='valorCotacao'
					label='Valor Cotação'
					maxFractionDigits={6}
				/>
				
				<InputCurrency
					currency={pedidoVenda.orcamento?.indice?.moeda || 'BRL'}
					value={pedidoVenda.orcamento?.valorIpi} 
					onChange={handleChangeOrcamento} 
					col={2}
					name='valorIpi'
					label='Valor IPI'
					onBlur={() => validarItem('valorIpi')}
					invalid={messages.valorIpi}
				/>

				<InputCurrency
					currency={pedidoVenda.orcamento?.indice?.moeda || 'BRL'} 
					onChange={handleChangeOrcamento} 
					value={pedidoVenda.orcamento?.valorSt}
					col={2}
					name='valorSt'
					label='Valor ST'
					onBlur={() => validarItem('valorSt')}
					invalid={messages.valorSt}
				/>

				<InputCurrency
					currency={pedidoVenda.orcamento?.indice?.moeda || 'BRL'} 
					onChange={handleChangeOrcamento} 
					value={pedidoVenda.orcamento?.valorIcms}
					col={2}
					name='valorIcms'
					label='Valor ICMS'
					onBlur={() => validarItem('valorIcms')}
					invalid={messages.valorIcms}
				/>

				<InputCurrency
					currency={pedidoVenda.orcamento?.indice?.moeda || 'BRL'}
					value={pedidoVenda.itens?.length > 0 ? pedidoVenda.itens?.reduce((soma, i) => soma + i.valorTotal, 0) : 0 } 
					col={2}
					label='Valor Produto'
					readOnly
				/>

				<TransportadoraAutoComplete 
					disabled={disabled} 
					col={4} 
					value={pedidoVenda.transportadora} 
					name="transportadora"
					onSelect={handleSelectTransportadora} 
					onChange={handleChange}
				/>
									
				<InputText 
					readOnly 
					col={4} 
					label="Nome Fantasia" 
					value={pedidoVenda?.transportadora?.nomeFantasia || ""} 
				/>

				<InputText 
					readOnly 
					col={2} 
					label="Status" 
					value={pedidoVenda?.transportadora?.vwTransportadora?.status || ""} 
				/>

				<InputText 
					readOnly 
					col={2} 
					label="CPF/CNPJ" 
					value={pedidoVenda?.transportadora?.vwTransportadora?.cpfCnpjFormatado || ""} 
				/>

				<Dropdown 
					disabled={disabled} 
					options={pagamentoFrete} 
					col={4} 
					onBlur={() => validarItem('frete')} 
					name="frete" 
					label="Pagamento do Frete" 
					value={pedidoVenda.frete} 
					onChange={handleChange} 
					invalid={messages.frete}
				/>

				<InputCurrency 
					disabled={disabled} 
					col={2} 
					value={pedidoVenda.valorFrete} 
					min={0} 
					label="Valor Frete" 
					name="valorFrete" 
					invalid={messages.valorFrete}
					onChange={handleChange} 
					onBlur={() => validarItem('valorFrete')}
					currency={pedidoVenda.orcamento?.indice?.moeda || 'BRL'}
				/>
				<div className="col-4"/>
	
				<Calendar 
					col={2}
					name="dataComissao" 
					label="Data da Comissão" 
					value={pedidoVenda.orcamento?.dataComissao} 
					onChange={handleChangeOrcamento} 
					// invalid={messages.dataComissao}
					// onBlur={() => validarItem('dataComissao')}
				/>

				<InputText 
					multiline 
					style={{height: "10em"}} 
					col={12} 
					name="observacaoEnvio" 
					label="Observações do Envio" 
					value={pedidoVenda.orcamento.observacaoEnvio} 
					onChange={handleChangeOrcamento}
					disabled={disabled} 
				/>
				<InputText 
					multiline 
					style={{height: "10em"}} 
					col={12} 
					name="observacao" 
					label="Observações" 
					value={pedidoVenda.orcamento.observacao} 
					onChange={handleChangeOrcamento}
					disabled={disabled} 
				/>

			</PanelContent>	
        </BlockUI>
    );
}