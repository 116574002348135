import React, {useMemo} from 'react';
import {TabelaProdutoMarca} from './TabelaProdutoMarca';
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {useBreakpoint} from "../../context/BreakpointContext";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {Panel} from "primereact/panel";
import {BlockUI} from "primereact/blockui";
import {useQueryClientMarcaProduto} from "../../utils/query-clients/queryClients";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {SearchButton} from "../../components/button/SearchButton";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {If} from "../../components/conditional/If";

export function ProdutoMarcas() {

    const {roles} = useAuth();
    const navigate = useNavigate();
    const {isLargeDevice} = useBreakpoint();
    const {query, handleSearch} = useQueryClientMarcaProduto();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.MarcaProduto);

    function handleCriar() {
        navigate("/produtos/marcas/new");
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel headerTemplate={() => headerTemplate('Marcas de Produto', 'fa-tag')}>
                <PanelContent>
                    <InputText placeholder="a descrição da marca" col={12} label="Descrição" value={params.descricao} name="descricao" onChange={handleChange}/>
                </PanelContent>
                <PanelFooter>
                    <If condition={!!roles.MARA}>
                        <Button success onClick={handleCriar} label={isLargeDevice ? "Novo" : null} icon="pi pi-plus" autowidth={!isLargeDevice}/>
                    </If>
                    <SearchButton query={query} onSearch={handleSearch}/>
                </PanelFooter>
            </Panel>
            {useMemo(() => <TabelaProdutoMarca value={query.data || []}/>, [query.data])}
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
