import React from "react";
import {Dropdown} from "./index";

export function TipoComissaoDropdown(props) {
	return (
		<Dropdown 
            label="Tipo de Comissão" 
            options={props.tiposComissao} 
            optionLabel='descricao' 
            {...props}
            optionValue='id'
        />
	);
}