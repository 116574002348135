import React, {useEffect, useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {previsaoCompraService} from "../../service/previsaoCompraService";
import {Button} from "../../components/button";
import moment from "moment";

export function VisualizarPrevisoesCompra({codigo}) {

	const [visible, setVisible] = useState(true);
	const [previsoesCompra, setPrevisoesCompra] = useState([]);

	useEffect(() => {
		previsaoCompraService.listar(codigo).then(setPrevisoesCompra);
	}, [codigo]);

	function handleClose() {
		setVisible(false);
	}

	return (
		<Dialog header={`Previsões de Compra para o Produto ${codigo}`} visible={visible} style={{width: "650px"}} onHide={handleClose}>
			<PanelContent>
				<div className="col-12">
					<DataTable footer={null} value={previsoesCompra} paginator={false}>
						<Column header="Nº do Pedido" field="numeroPedido"/>
						<Column header="Produto" field="codigoProduto"/>
						<Column header="Quantidade Prevista" field="quantidadePrevisao"/>
						<Column header="Data do Pedido" field="dataPedido" body={pc => moment(pc.dataPedido).format("DD/MM/YYYY")}/>
						<Column header="Previsão de Entrega" field="previsaoEntrega" body={pc => moment(pc.previsaoEntrega).format("DD/MM/YYYY")}/>
					</DataTable>
				</div>
			</PanelContent>
			<DialogFooter>
				<Button icon="fas fa-times" label="Fechar" secondary onClick={handleClose}/>
			</DialogFooter>
		</Dialog>
	);

}
