import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { HashRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import AppProvider from './context';
import { unregister } from './service-worker';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 300_000,
            refetchInterval: 300_000
        }
    }
});

ReactDOM.render(
    <HashRouter>
        <AppProvider>
            <ScrollToTop>
                <QueryClientProvider client={queryClient}>
                    <App/>
                </QueryClientProvider>
            </ScrollToTop>
        </AppProvider>
    </HashRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister();
