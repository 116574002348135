import {Dialog} from "primereact/dialog";
import React, {useEffect, useState} from "react";
import {DataTable} from "../../../components/datatable";
import {Column} from "primereact/column";
import {RadioButton} from "primereact/radiobutton";
import {Button} from "../../../components/button";
import {captionOperacao} from "../../../service/ordemServicoService";
import moment from "moment";

export const DialogsOsTransferidas = ({ordensDeServico, onHide, onConfirmar}) => {

	const [visible, setVisible] = useState(true);
	const [ordensDeServicoExistentes, setOrdensDeServicoExistentes] = useState(ordensDeServico);

	const handleHide = () => {
		if (onHide) {
			onHide();
		}
		return setVisible(false);
	};

	useEffect(() => {
		setOrdensDeServicoExistentes((ordensServico) =>
			ordensServico.map((os) => {
				return {
					...os,
					selecionado:
						os.ordensDeServico?.length < 2
							? os.ordensDeServico[0].id
							: os.ordensDeServico.reduce((maisRecente, atual) => {
								return new Date(atual.inicio) > new Date(maisRecente.inicio)
									? atual
									: maisRecente;
							}).id,
				};
			})
		);
	}, [ordensDeServico]);

	const handleChangeCheckbox = (e, data) => {
		setOrdensDeServicoExistentes(
			ordensDeServicoExistentes.map((os) => {
				if (os.equipamento.id === data.equipamento.id) {
					return {
						...os,
						selecionado: e.value,
					};
				} else {
					return os;
				}
			})
		);
	};

	const templateEquipamento = (data) => {
		return (
			<div className="flex align-items-center">
				<i className="text-lg fa-solid fa-user mr-5" />
				<div className="">{data.equipamento.autoComplete}</div>
			</div>
		);
	};

	const templateOrdensDeServico = (data, row) => {
		const itens = data.ordensDeServico.map((os) => {
			return (
				<div className="flex align-items-center py-1" key={os.numero}>
					<RadioButton
						inputId={os.id}
						value={os.id}
						onChange={(e) => handleChangeCheckbox(e, data)}
						checked={data.selecionado === os.id}
					/>
					<label htmlFor={os.id} className="ml-2 mr-5">
						<i
							className={`text-xl mr-2 fa-solid ${
								os.operacao === "SUPORTE" ? "fa-headset" : "fa-road"
							}`}
						/>
						OS nº {os.numero} de {captionOperacao[os.operacao]} aberta em {moment(os.inicio).format("DD/MM/YYYY HH:mm")}
					</label>
				</div>
			);
		});
		itens.push(
			<div className="flex align-items-center py-1" key="NOVAOS">
				<RadioButton
					inputId="novaOS"
					name="Gerar nova OS"
					value="novaOS"
					onChange={(e) => handleChangeCheckbox(e, data)}
					checked={data.selecionado === "novaOS"}
				/>
				<label htmlFor="novaOS" className="ml-2">
					<i className="text-xl fa-solid fa-house-flag mr-2"/>
					Ignorar e abrir uma nova ordem de serviço
				</label>
			</div>
		)
		return itens;
	};

	const handleConfirmar = () => {
		const transferirOSs = [];
		ordensDeServicoExistentes.forEach((os) => {
			if (os.selecionado !== "novaOS") {
				transferirOSs.push({
					equipamentoId: os.equipamento.id,
					os: os.ordensDeServico.filter((item) => item.id === os.selecionado)[0]
						.id,
				});
			}
		});
		onConfirmar(transferirOSs);
		handleHide();
	};

	const footerContent = (
		<div>
			<Button
				label="Cancelar"
				icon="fa-solid fa-x"
				onClick={handleHide}
				danger
			/>
			<Button
				label="Confirmar"
				icon="fa-solid fa-check"
				onClick={handleConfirmar}
			/>
		</div>
	);

	return (
		<Dialog
			header="Foram encontradas ordens de Serviço existentes"
			visible={visible}
			onHide={handleHide}
			footer={footerContent}
			style={{ width: "75vw" }}
			breakpoints={{ "960px": "75vw", "641px": "100vw" }}
		>
			<div className="grid">
				<div className="col-12">
					Alguns equipamentos têm ordens de serviço em processo de transferência
					para laboratório. Por gentileza, confirme as ordens de serviço a serem
					transferidas na tabela abaixo.
				</div>

				<div className="col-12">
					<DataTable rows={3} value={ordensDeServicoExistentes}>
						<Column
							header="Equipamento"
							field="equipamento"
							body={templateEquipamento}
							style={{ width: "40em" }}
						/>
						<Column
							header="Ordem de serviço"
							field="equipamento"
							body={templateOrdensDeServico}
						/>
					</DataTable>
				</div>
			</div>
		</Dialog>
	);

};
