import React from "react";
import moment from "moment";
import {If} from "../conditional/If";
import {iconesRestricoes, labelRestricao} from "../../service/ordemServicoService";
import {findRestricoesBloqueantes} from "../../utils/ordemServicoUtils";

function templateOrcamento(ordemServico) {
	if (ordemServico.countOrcamentoGerado || ordemServico.countOrcamentoEnviado) {
		return null;
	}
	switch (ordemServico.statusUltimoOrcamento) {
		case "APROVADO":
			return <><i className="fas fa-thumbs-up" style={{color: "#4CAF50"}}/> Orçamento aprovado</>;
		case "RECUSADO":
			return <><i className="fas fa-thumbs-down" style={{color: "#F44336"}}/> Orçamento reprovado</>;
		default:
			return null;
	}
}

function printStatusRessolda(ordemServico) {
	if (ordemServico.laudoConcluido || ordemServico.restricoes?.includes("RESSOLDA")) {
		return null;
	}
	if (ordemServico.countRessoldaConcluida) {
		return <div><i className="fas fa-eyedropper" style={{color: "#4CAF50"}}/> Recuperação de placa concluída com sucesso</div>;
	}
	if (ordemServico.countRessoldaPlacaCondenada) {
		return <div><i className="fas fa-eyedropper" style={{color: "#F44336"}}/> Placa condenada</div>;
	}
	if (ordemServico.countRessoldaMalsucedida) {
		return <div><i className="fas fa-eyedropper" style={{color: "#F44336"}}/> Recuperação de placa malsucedida</div>;
	}
}

export function CardOrdemServico({ordemServico, hideOrcamento}) {

	if (!ordemServico) {
		return null;
	}

	return (
		<table>
			<tr>
				<td style={{width: "3em", textAlign: "center"}}>
					<i className={`fas fa-big fa-${ordemServico.cancelada ? "times" : "scroll"}`} style={{color: ordemServico.cancelada ? "#F44336" : "#009688"}}/>
				</td>
				<td>
					<div style={{fontWeight: "bold"}}>{ordemServico.numero}</div>
					<If condition={ordemServico.notaFiscal?.numero}>
						<div style={{fontSize: ".9em"}}><i style={{color: "#607D8B"}} className="fas fa-file-pdf"/> NF Entrada: {ordemServico.notaFiscal?.numero}</div>
					</If>
					<If condition={!ordemServico.notaFiscal?.numero}>
						<div style={{color: "#F44336", fontSize: ".9em"}}><i className="fas fa-file-circle-xmark"/> Sem NF Entrada</div>
					</If>
					<div style={{fontSize: ".9em"}}>Início: {ordemServico.inicio && moment(ordemServico.inicio).format("DD/MM/YYYY HH:mm")}</div>
					{ordemServico.fim ? <div style={{fontSize: ".9em"}}>Conclusão: {ordemServico.inicio && moment(ordemServico.fim).format("DD/MM/YYYY HH:mm")}</div> : null}
					<div style={{fontSize: ".9em"}}>Última Alteração: {ordemServico.ultimaAlteracao && moment(ordemServico.ultimaAlteracao).format("DD/MM/YYYY HH:mm")}</div>
					{printStatusRessolda(ordemServico)}
					<If condition={ordemServico.status === "LAUDO" && ordemServico.laudoConcluido} children={<div style={{fontSize: ".9em"}}><i className="fas fa-user-md"/> Laudo Concluído</div>}/>
					<If condition={!hideOrcamento} children={<div style={{fontSize: ".9em"}}>{templateOrcamento(ordemServico)}</div>}/>
					<If condition={(ordemServico.status === 'EXPEDICAO' || ordemServico.status === 'FECHADA')  && ordemServico?.faturamento &&  ordemServico?.faturamento.notaFiscalRetorno} >
						<div style={{fontSize: ".9em"}}><i style={{color: "#607D8B"}} className="fas fa-file-pdf"/> 
							&nbsp;{`NF Retorno: ${ordemServico.faturamento?.notaFiscalRetorno} (${ordemServico.faturamento?.linhaNotaRetorno}/${ordemServico.faturamento?.totalItensRetorno})`}
						</div>
					</If>

					{/* Esse bloco precisa ser comentado */}
					{/* <If condition={ordemServico.status === 'EXPEDICAO' && ordemServico?.faturamento &&  ordemServico?.faturamento.notaFiscalRetorno}>
						<If condition={ordemServico.faturamento?.pdfDisponivelRetorno}>
							<div style={{fontSize: ".9em"}}><i style={{color: "#0c8f00"}} title="PDF disponível para download." className="fas fa-file-pdf"/> 
								&nbsp;{`NF Retorno: ${ordemServico.faturamento?.notaFiscalRetorno} (${ordemServico.faturamento?.linhaNotaRetorno}/${ordemServico.faturamento?.totalItensRetorno})`}
							</div>
						</If>
						<If condition={!ordemServico.faturamento?.pdfDisponivelRetorno}>							
							<div style={{fontSize: ".9em"}}>
								<i style={{color: "#F44336"}} className="fa-solid fa-file-circle-xmark" title="PDF não disponível para download."/> 
								&nbsp;{`NF Retorno: ${ordemServico.faturamento?.notaFiscalRetorno} (${ordemServico.faturamento?.linhaNotaRetorno}/${ordemServico.faturamento?.totalItensRetorno})`}
							</div>

						</If>
					</If> */}
					{/** Ate Aqui */}


					{findRestricoesBloqueantes(ordemServico).map(r => printRestricao(ordemServico, r))}
					{ordemServico.cancelada ? <div style={{color: "#F44336"}}>ORDEM DE SERVIÇO CANCELADA</div> : null}
				</td>
			</tr>
		</table>
	);

}

export function printRestricao(ordemServico, r) {
	switch (r) {
		case "LAUDO":
			if (ordemServico.ultimaMovimentacao?.prateleira?.tipo === "BANCADA" && ordemServico.responsavel?.id) {
				return <div className="os-restricao green" key={r}>{iconesRestricoes[r]} Laudo em andamento</div>;
			}
			return <div className="os-restricao" key={r}>{iconesRestricoes[r]} {labelRestricao[r]}</div>;
		case "RESSOLDA":
			if (ordemServico.ultimaMovimentacao?.prateleira?.tipo === "BANCADA" && ordemServico.responsavel?.id) {
				return <div className="os-restricao green" key={r}>{iconesRestricoes[r]} Recuperação de placa em andamento</div>;
			}
			return <div className="os-restricao" key={r}>{iconesRestricoes[r]} {labelRestricao[r]}</div>;
		default:
			return <div className="os-restricao" key={r}>{iconesRestricoes[r]} {labelRestricao[r]}</div>;
	}
}
