import React, {useContext, useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {Button} from "../../components/button";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {InputText} from "../../components/inputtext";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {equipamentoService} from "../../service/equipamentoService";
import {DialogContext} from "../../utils/dialogContext";
import {ProgressDialog} from "../../components/progressdialog/ProgressDialog";

export function CadastrarEquipamentosLote({onModalClose, contrato}) {

	const {showDialog} = useContext(DialogContext);
	const [visible, setVisible] = useState(true);
	const [params, setParams] = useState({
		produto: null
	});

	function handleClose() {
		setVisible(false);
	}

	async function handleSalvar() {
		if (!params.produto?.id) {
			showDialog(<InformationDialog header="Informação" message="Você precisa escolher o modelo dos equipamentos"/>)
			return;
		}
		const lines = params.serials?.split("\n");

		const serialRepetidos = [];

		contrato.equipamentos.forEach(equipamento => {
			console.log(equipamento)
			if(lines.includes(equipamento.equipamento.serial)) {
				serialRepetidos.push(equipamento.equipamento.serial);
			}
		});

		if(serialRepetidos.length > 0) {
			showDialog(
				<InformationDialog 
				header="Informação" 
				message={"Identificamos números de série repetidos. Por favor, exclua-os ou faça alguma alteração neles: " + serialRepetidos.join(", ")}
				/>
			);
			return;
		}
		
		showDialog(<ProgressDialog onProgress={async setProgresso => {
			const equipamentos = [];
			let progresso = 0;
			for (let line of lines) {
				let equipamento = await equipamentoService.findBySerial(line);
				if (equipamento) {
					equipamentos.push(equipamento);
				} else {
					equipamentos.push(await equipamentoService.salvar({...equipamentoService.criar(), postBack: true, produto: params.produto, serial: line}));
				}
				setProgresso(++progresso / lines.length);
			}
			if (onModalClose) {
				onModalClose(equipamentos);
			}
			handleClose();
		}}/>);
	}

	function handleChange(event) {
		setParams(prevParams => ({...prevParams, [event.name]: event.value}));
	}

	return (
		<Dialog visible={visible} header="Cadastrar Equipamentos em Lote" style={{width: "550px"}} onHide={handleClose}>
			<PanelContent>
				<ProdutoAutoComplete 
					label="Modelo" 
					name="produto" 
					value={params.produto}
					col={12} 
					onChange={handleChange}
				/>
				<InputText label="Nºs de Série" name="serials" value={params.serials} col={12} multiline style={{height: "15em"}} onChange={handleChange}/>
			</PanelContent>
			<DialogFooter>
				<Button icon="fas fa-save" onClick={handleSalvar} label="Salvar"/>
				<Button icon="fas fa-times" secondary onClick={handleClose} label="Fechar"/>
			</DialogFooter>
		</Dialog>
	);

}
