import {baseService} from "./baseService";

const resourceUrl = "/prateleiras";

export const prateleiraService = {

	...baseService(resourceUrl),

	async listarPrateleiras(params) {
		const query = [];
		if (params.descricao?.length) query.push(`descricao=lk=${params.descricao}`);
		if (params.setor?.length) query.push(`setor==${params.setor}`);
		if (params.tipo?.length) query.push(`tipo==${params.tipo}`);
		return await prateleiraService.listar([`query=${query.join(";")}`]);
	},

	criar() {
		return {
			descricao: ""
		};
	}

}

export const optionsSetor = [
	{label: "Recepção", value: "RECEPCAO"},
	{label: "Laboratório", value: "LABORATORIO"},
	{label: "Expedição", value: "EXPEDICAO"},
	{label: "Depósito", value: "DEPOSITO"}
];

export const optionsTipo = [
	{label: "Prateleira", value: "PRATELEIRA"},
	{label: "Bancada", value: "BANCADA"}
];

export const labelSetor = {
	RECEPCAO: "Recepção",
	LABORATORIO: "Laboratório",
	EXPEDICAO: "Expedição",
	DEPOSITO: "Depósito"
};

export const labelTipo = {
	PRATELEIRA: "Prateleira",
	BANCADA: "Bancada"
};
