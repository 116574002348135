import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Panel} from "primereact/panel";
import {Row} from "primereact/row";
import {TabPanel, TabView} from "primereact/tabview";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {Button} from "../../components/button";
import {Calendar} from "../../components/calendar";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {DataTable} from "../../components/datatable";
import {InputMask} from "../../components/inputmask";
import {InputCurrency, InputNumber} from "../../components/inputnumber";
import {InputText} from "../../components/inputtext";
import {PanelContent, PanelFooter} from "../../components/panel";
import {equipamentoService} from "../../service/equipamentoService";
import {ordemServicoNotaFiscalService} from "../../service/ordemServicoNotaFiscalService";
import {ordemServicoService} from "../../service/ordemServicoService";
import {withDialog} from "../../utils/dialogContext";
import {isBiggerThanZero, isEmpty, isEntityRequired, isRequired} from "../../utils/fieldValidator";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {useAuth} from "../../context/AuthContext";
import {Dropdown} from "../../components/dropdown";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {ordemServicoFluxoService} from "../../service/ordemServicoFluxoService";
import {OrdemServicoTipoDropdown} from "../../components/dropdown/ordemServicoTipoDropdown";
import {OrdemServicoFluxoDropdown} from "../../components/dropdown/ordemServicoFluxoDropdown";
import {contratoService} from "../../service/contratoService";
import {useBreakpoint} from "../../context/BreakpointContext";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import moment from "moment";
import {ProgressDialog} from "../../components/progressdialog/ProgressDialog";

export const OrdemServicoNotaFiscal = withDialog(({showDialog}) => {

	const navigate = useNavigate();
	const {usuario, roles} = useAuth();
	const {breakpoint} = useBreakpoint();
	const isLargeDevice = breakpoint === "lg" || breakpoint === "md";

	const [params, setParams] = useState({
		cliente: null,
		tipo: "CORRETIVA",
		fluxo: null,
		etapa: null,
		etapas: [],
		responsavel: usuario
	});

	const [messages, setMessages] = useState({});
	const [messagesParams, setMessagesParams] = useState({});
	const [messagesEquipamentos, setMessagesEquipamentos] = useState([]);
	const [equipamentos, setEquipamentos] = useState([]);
	const [optionsFluxos, setOptionsFluxos] = useState([]);
	const [optionsEtapas, setOptionsEtapas] = useState([]);
	const [optionsContratos, setOptionsContratos] = useState([]);
	const [equipamentosSelecionados, setEquipamentosSelecionados] = useState([]);
	const [notaFiscal, setNotaFiscal] = useState(ordemServicoNotaFiscalService.criar());

	function handleVoltar() {
		navigate(-1);
	}

	function handleChange(event) {
		setNotaFiscal({...notaFiscal, [event.name]: event.value});
	}

	function adicionarEquipamento() {
		setEquipamentos([...equipamentos, equipamentoService.criar()]);
	}

	function removerEquipamentos() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os equipamentos selecionados?" onYes={() => {
			setEquipamentos([...equipamentos.filter(e => !equipamentosSelecionados.some(x => (x._key && x._key === e._key) || (x.id && x.id === e.id)))]);
			setEquipamentosSelecionados([]);
		}}/>);
	}

	function handleChangeEquipamento(event) {
		equipamentos[event.index][event.name] = event.value;
		if (event.name === "produto") {
			equipamentos[event.index]._alterado = true;
		}
		setEquipamentos([...equipamentos]);
	}

	async function gerarOrdensServico() {
		const messages = ordemServicoNotaFiscalService.validar(notaFiscal);
		const messagesParams = ordemServicoNotaFiscalService.validarParams(params);
		const messagesEquipamentos = equipamentos.map(e => {
			const m = {
				produto: isEntityRequired(e.produto),
				_valor: isBiggerThanZero(e._valor)
			};
			m.isEmpty = () => isEmpty(m);
			return m;
		});
		setMessages(messages);
		setMessagesParams(messagesParams);
		setMessagesEquipamentos(messagesEquipamentos);
		if (!equipamentos.length) {
			showDialog(<InformationDialog header="Informação" message="Você deve preencher ao menos um equipamento para abrir as ordens de serviço."/>);
			return;
		}
		if (!messages.isEmpty() || !messagesParams.isEmpty() || messagesEquipamentos.some(me => !me.isEmpty())) {
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
			return;
		}
		//if (notaFiscal.valor !== equipamentos.map(e => e._valor || 0).reduce((a, b) => a + b, 0)) {
			//showDialog(<InformationDialog header="Informação" message="O valor informado para a nota fiscal é diferente dos valores dos itens declarados."/>);
			//return;
		//}
		if (equipamentos.length) {
			notaFiscal.cliente = params.cliente;
			showDialog(<ProgressDialog onProgress={async setProgresso => {
				let progresso = 0;
				for (let i = 0; i < equipamentos.length; ++i) {
					if (equipamentos[i]._alterado) {
						const _valor = equipamentos[i]._valor;
						const _numeroChamado = equipamentos[i]._numeroChamado;
						if (!equipamentos[i].serial?.length && equipamentos[i].numeroAtivo?.length) {
							equipamentos[i].serial = `PAT-${equipamentos[i].numeroAtivo}`;
						}
						if (!equipamentos[i].serial?.length) {
							equipamentos[i].serial = moment().format("YYMMDDHHmmssSSS");
						}
						equipamentos[i] = await equipamentoService.salvar({...equipamentos[i], postBack: true});
						equipamentos[i]._valor = _valor;
						equipamentos[i]._numeroChamado = _numeroChamado;
					}
					setProgresso(++progresso / (equipamentos.length * 2));
				}
				notaFiscal.itens = equipamentos.map(equipamento => ({
					equipamento,
					valor: equipamento._valor
				}));
				await ordemServicoNotaFiscalService.salvar({...notaFiscal, postBack: true}).then(async notaFiscal => {
					for (const equipamento of equipamentos) {
						let ordemServico = {
							...ordemServicoService.criar(),
							numeroChamado: equipamento._numeroChamado,
							status: params.etapa.status,
							...params,
							notaFiscal,
							acompanhamentos: [
								{
									...ordemServicoService.criarAcompanhamento(),
									etapa: params.etapa,
									observacoes: "Ordem de serviço iniciada",
									responsavel: params.responsavel
								}
							],
							fluxo: `${params.fluxo.descricao} (v${params.fluxo.versao})`,
							postBack: true
						};
						ordemServico = await ordemServicoService.salvar(ordemServico);
						await ordemServicoItemService.salvar({ordemServico, sequencia: 1, equipamento, valorNotaFiscal: equipamento._valor});
						setProgresso(++progresso / (equipamentos.length * 2));
					}
				});
				handleVoltar();
			}}/>);
		} else {
			showDialog(<InformationDialog header="Informação" message="Você precisa adicionar ao menos um equipamento para a abertura da ordem de serviço."/>);
		}
	}

	function buscarEquipamento(index) {
		equipamentoService.findBySerial(equipamentos[index].serial).then(equipamento => {
			if (equipamento) {
				equipamentos[index] = equipamento;
				setEquipamentos([...equipamentos]);
			} else {
				equipamentos[index]._alterado = true;
				setEquipamentos([...equipamentos]);
			}
		});
	}

	async function handleChangeParams(event) {
		switch (event.name) {
			case "fluxo":
				if (event.value?.id) {
					setOptionsEtapas([...event.value.etapas.map(e => ({label: e.descricao, value: e}))]);
					setParams(prevParams => ({...prevParams, fluxo: event.value, etapas: event.value.etapas, etapa: event.value.etapas[0]}));
				}
				break;
			case "cliente":
				if (event.value?.id) {
					const contratos = await contratoService.listarContratosAtivos(event.value.id);
					if (contratos.length === 1) {
						setParams(prevParams => ({...prevParams, contrato: contratos[0]}));
					}
					setOptionsContratos(contratos.map(c => ({label: c.autoComplete, value: c})));
				}
				setParams(prevParams => ({...prevParams, cliente: event.value}));
				break;
			default:
				setParams(prevParams => ({...prevParams, [event.name]: event.value}));
				break;
		}
	}

	useEffect(() => {
		ordemServicoFluxoService.listar(["query="]).then(async fluxos => {
			setOptionsFluxos(fluxos);
			if (fluxos.length) {
				await handleChangeParams({name: "fluxo", value: fluxos[0]});
			}
		});
	}, []);

	const equipamentoControlSet = (
		<div style={{whiteSpace: "nowrap"}}>
			<Button icon="pi pi-plus" onClick={adicionarEquipamento}/>
			<Button disabled={!equipamentosSelecionados?.length} onClick={removerEquipamentos} danger icon="pi pi-minus"/>
		</div>
	);

	const equipamentoHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={6} header={equipamentoControlSet}/>
			</Row>
			<Row>
				<Column selectionMode="multiple" style={{width: "3em"}}/>
				<Column style={{width: "20em"}} header="Nº do Chamado"/>
				<Column style={{width: "20em"}} header="Nº do Ativo"/>
				<Column style={{width: "20em"}} header="Nº de Série"/>
				<Column header="Modelo"/>
				<Column style={{width: "15em"}} header="Valor"/>
			</Row>
		</ColumnGroup>
	);

	const equipamentoFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={6} footer={equipamentoControlSet}/>
			</Row>
		</ColumnGroup>
	);

	return (
		<Panel header="Ordens de Serviço por Nota Fiscal de Entrada">
			<TabView>
				<TabPanel header="Dados da Nota Fiscal">
					<PanelContent>
						<InputNumber invalid={messages.numero} required onBlur={() => {
							messages.numero = isBiggerThanZero(notaFiscal.numero, "Campo obrigatório");
							setMessages({...messages});
						}} col={3} label="Número" name="numero" value={notaFiscal.numero} onChange={handleChange}/>
						<InputMask unmask mask="9999 9999 9999 9999 9999 9999 9999 9999 9999 9999 9999" invalid={messages.chave} required onBlur={() => {
							messages.chave = isRequired(notaFiscal.chave);
							setMessages({...messages});
						}} col={6} label="Chave" name="chave" value={notaFiscal.chave} onChange={handleChange}/>
						<Calendar invalid={messages.data} required onBlur={() => {
							messages.data = isRequired(notaFiscal.data);
							setMessages({...messages});
						}} col={3} label="Data" name="data" value={notaFiscal.data} onChange={handleChange}/>
						<InputText col={9} label="Descrição" name="descricao" value={notaFiscal.descricao} onChange={handleChange}/>
						<InputCurrency invalid={messages.valor} required onBlur={() => {
							messages.valor = isBiggerThanZero(notaFiscal.valor);
							setMessages({...messages});
						}} col={3} label="Valor" name="valor" value={notaFiscal.valor} onChange={handleChange}/>
						<InputText multiline style={{height: "13em"}} col={12} label="Observações" name="observacoes" value={notaFiscal.observacoes} onChange={handleChange}/>
					</PanelContent>
				</TabPanel>
				<TabPanel header="Dados para as Ordens de Serviço">
					<PanelContent>
						<ClienteAutoComplete required onBlur={() => {
							messagesParams.cliente = isEntityRequired(params.cliente);
							setMessages({...messages});
						}} invalid={messagesParams.cliente} col={8} label="Cliente" name="cliente" value={params.cliente} onChange={handleChangeParams}/>
						<Dropdown options={optionsContratos} col={4} name="contrato" value={params.contrato} onChange={handleChangeParams} label="Contrato"/>
						<OrdemServicoTipoDropdown onBlur={() => {
							messagesParams.tipo = isRequired(params.tipo);
							setMessages({...messages});
						}} invalid={messagesParams.tipo} required col={2} value={params.tipo} name="tipo" onChange={handleChangeParams}/>
						<OrdemServicoFluxoDropdown disabled={!roles.OSSE} onBlur={() => {
							messagesParams.fluxo = isEntityRequired(params.fluxo);
							setMessages({...messages});
						}} invalid={messagesParams.fluxo} required tipo={params.tipo} fluxos={optionsFluxos} col={2} name="fluxo" value={params.fluxo} onChange={handleChangeParams} label="Fluxo"/>
						<Dropdown disabled={!roles.OSSF} onBlur={() => {
							messagesParams.etapa = isEntityRequired(params.etapa);
							setMessages({...messages});
						}} invalid={messagesParams.etapa} required options={optionsEtapas} col={4} name="etapa" value={params.etapa} onChange={handleChangeParams} label="Etapa"/>
						<UsuarioAutoComplete onBlur={() => {
							messagesParams.responsavel = isEntityRequired(params.responsavel);
							setMessages({...messages});
						}} invalid={messagesParams.responsavel} forceSelection required readOnly={!roles.OSSG} col={4} name="responsavel" value={params.responsavel} onChange={handleChangeParams} label="Responsável"/>
						<div className="col-12">
							<DataTable header="Equipamentos"
							           value={equipamentos}
							           headerColumnGroup={equipamentoHeaderGroup}
							           footerColumnGroup={equipamentoFooterGroup}
							           footer={null}
							           selectionMode="checkbox"
							           selection={equipamentosSelecionados}
							           onSelectionChange={e => setEquipamentosSelecionados(e.value)}
							>
								<Column selectionMode="multiple"/>
								<Column header="Modelo" body={(e, c) => (
									<InputText label={null} col={12} value={e._numeroChamado} name="_numeroChamado" index={c.rowIndex} onChange={handleChangeEquipamento}/>
								)}/>
								<Column field="numeroAtivo" style={{width: "20em"}} header="Nº do Ativo" body={(e, c) => (
									<InputText index={c.rowIndex} name="numeroAtivo" value={e.numeroAtivo} onChange={handleChangeEquipamento}/>
								)}/>
								<Column field="serial" style={{width: "20em"}} header="Nº de Série" body={(e, c) => (
									<InputText index={c.rowIndex} name="serial" value={e.serial} onBlur={() => buscarEquipamento(c.rowIndex)} onChange={handleChangeEquipamento}/>
								)}/>
								<Column header="Modelo" body={(e, c) => (
									<ProdutoAutoComplete required invalid={messagesEquipamentos?.[c.rowIndex]?.produto} label={null} col={12} value={e.produto} name="produto" index={c.rowIndex} onChange={handleChangeEquipamento}/>
								)}/>
								<Column header="Valor" body={(e, c) => (
									<InputCurrency required invalid={messagesEquipamentos?.[c.rowIndex]?._valor} label={null} col={12} value={e._valor} name="_valor" index={c.rowIndex} onChange={handleChangeEquipamento}/>
								)}/>
							</DataTable>
						</div>
					</PanelContent>
				</TabPanel>
			</TabView>
			<PanelFooter>
				<Button autowidth={!isLargeDevice.toString()} icon="fa-solid fa-save" label={isLargeDevice ? "Gerar Ordens de Serviço" : null} success onClick={gerarOrdensServico}/>
				<Button autowidth={!isLargeDevice.toString()} icon="fa-solid fa-arrow-left" label={isLargeDevice ? "Voltar" : null} secondary onClick={handleVoltar}/>
			</PanelFooter>
		</Panel>
	);

});
