import React, { useState } from "react";
import { TipoComissaoDropdown } from "../../../../components/dropdown/tipoComissaoDropdown";
import ClienteRazaoSocialTemplate from "../../../Templates/ClienteRazaoSocialTemplate";
import { InputCurrency, InputNumber } from "../../../../components/inputnumber";
import { formatarParaReal, ordenarFaixas } from "./utils";
import moment from "moment";
import { SelectButton } from "../../../../components/selectbutton";
import { restricoesButtons } from "../../../../service/ordemServicoService";
import { optionsComissaoAcompanhamento } from "../../services/comissaoAcompanhamentoService";
import "./Comissao.scss";
import ClienteRazaoSocialDTOTemplate from "../../../Templates/ClienteRazaoSocialDTOTemplate";
export const tipoComissaoTemplate = (dataTableRowData, dataTableRowInfos, tiposComissao, handleChange) => (
    <div className="comissao-column-width sz-15">
       {dataTableRowData?.tipoComissao?.descricao}
    </div>
);

export const documentoTemplate = (data) => {
    const items = [];
  
    const addItem = (title, color, content, icon) => {
        items.push(
        <div title={title} className={`flex grid align-items-center `}>
            <i style={{ color }} className={`fa-solid ${icon} fa-lg col-1 mr-2`} />
            <span className="font-bold col-8">{content}</span>
        </div>
        );
    };
    
    switch (data.tipo) {
        case "VENDA_DIRETA":
            addItem("Venda Direta", "#006992", data.pedidoNumero, 'fa-warehouse');
        break;    
        case "NOTA_DEBITO":
            addItem("Nota de Debito", "#4caf50", data.duplicataNumero, 'fa-money-bills');
        break;
    
        case "NOTA_FISCAL":
            addItem("Nota Fiscal", "#F31CB7", `${data.notaFiscalNumero} - ${data.notaFiscalItem}`, 'fa-file-invoice-dollar');
        break;    
        default:
        break;
    }

    if (data.pedidoNumero && data.origem === "PEDIDO_VENDA")  {
        addItem("Pedido de Venda", "rgb(48, 63, 159)", data.pedidoNumero, 'fa-file-invoice');
    }
    
    if (data.ordemServicoNumero) {
        addItem("Ordem de Servico", "#009688", data.ordemServicoNumero, 'fa-scroll');
    }
    
    if (data.contratoNumero) {
        addItem("Contrato", "#795548", data.contratoNumero, 'fa-file-signature');
    }
    
    return <div className="flex flex-column">{items}</div>;
};

export const razaoSocialTemplate = (dataTableRowData) => {
    return <ClienteRazaoSocialDTOTemplate data={dataTableRowData?.cliente} />
}
export const percentualComissaoTemplate = (data) => {
    return (
        <div style={{textAlign: "right"}}>
            {data.percentualComissao}
        </div>
    );
}
export const valorFaturamento = (data) => {
    return (
        <div style={{textAlign: "right"}}>
            {formatarParaReal(data.valorFaturamento)}
        </div>
    )
}
export const comissaoTemplate = (data) => {
    return (
        <div style={{textAlign: "right"}}>
            {formatarParaReal(data.valorComissao)}
        </div>
    );
}

export const valorNotaFiscalTemplate = (data) => {
    return (
        <div style={{textAlign: "right"}}>
            {formatarParaReal(data.valorBaseCalculoComissao)}
        </div>
    )
}

export const percentualFaixaIndividual = (data, tipoComissao) => {
    const faixasOrdenadas = ordenarFaixas(tipoComissao.itens);
    const faixaComtemplada = faixasOrdenadas.find((faixa) => {
        if (faixa.acima && data.valorFaturamento >= faixa.valor) {
            return true;
        }

        if (data.valorFaturamento <= faixa.valor) {
            return true;
        }
    });

    return (
        <div style={{textAlign: "right"}}>
            {`${faixaComtemplada.percentual} %`}
        </div>
    );
}

export const comissaoFaixaIndividual = (data, tipoComissao) => {
    const faixasOrdenadas = ordenarFaixas(tipoComissao.itens);
    const faixaComtemplada = faixasOrdenadas.find((faixa) => {
        if (faixa.acima && data.valorFaturamento >= faixa.valor) {
            return true;
        }

        if (data.valorFaturamento <= faixa.valor) {
            return true;
        }
    });

    const comissao = (faixaComtemplada.percentual / 100) * data.valorBaseCalculoComissao;

    return (
        <div style={{textAlign: "right"}}>
            {formatarParaReal(comissao)}
        </div>
    );
}

export const dataTemplate = (data) => {
    return(
        <div className="flex align-items-center justify-content-center">
            <i className="fa-solid fa-calendar-day mr-2"/>
            <span>{moment(data?.dataEmissao).format("DD/MM/YYYY")}</span>
        </div>
    )
}

export function toggleStatus(dataTableRowData, dataTableRowInfos, handleChange) {
    return (
        <SelectButton 
            pt={{button: ({context}) => ({className: context.selected ? dataTableRowData.status === "APROVADO" ? "sb-success" : "sb-unsuccess" : null})}}
            index={dataTableRowInfos.rowIndex}
            onChange={(onChangeValue) =>
                handleChange(onChangeValue, dataTableRowData, dataTableRowInfos)
            }
            disabled={dataTableRowData.comissaoPaga}
            itemTemplate={restricoesButtons}
            col={12}
            name="status"
            value={dataTableRowData?.status}
            options={optionsComissaoAcompanhamento}
            naoPodeDesselecionar
        />
    );
}
export const icones = (acompanhamento) => {
    let fechada = 'Este item ja faz parte de um fechamento e não pode mais ser editado.';
    let textoComissao = "A comissão para essa venda já foi paga anteriormente";
    let divisor = "O valor base de cálculo da comissão foi divido por " + acompanhamento.divisorParcela + " de acordo com a regra do divisor do contrato."
    if (!acompanhamento.comissaoPaga && acompanhamento.contratoRenovado && acompanhamento?.tipoComissao?.descricao !== 'AVULSO') {
        return (
            <div className="flex justify-content-center" >
               {acompanhamento.divisorParcela > 1 ? <i className={`fa-solid fa-divide fa-lg`} title={divisor} style={{color: "red"}}/> : null} &nbsp;&nbsp;
               {acompanhamento.fechada ? <i className={`fa-solid fa-lock yellow-900 fa-lg`} title={fechada}/> : null} &nbsp;&nbsp;
               <i className={`fa-solid fa-calendar-plus fa-lg`} title={"Contrato renovado"} style={{color: "grey"}}/>
            </div>
        );
    } else if (acompanhamento.comissaoPaga && acompanhamento.contratoRenovado && acompanhamento?.tipoComissao?.descricao !== 'AVULSO') {
        return (
            <div className="flex justify-content-center" >
                {acompanhamento.divisorParcela > 1 ? <i className={`fa-solid fa-divide fa-lg`} title={divisor} style={{color: "red"}}/> : null} &nbsp;&nbsp;
                {acompanhamento.fechada ? <i className={`fa-solid fa-lock yellow-900 fa-lg `} title={fechada}/> : null} &nbsp;&nbsp;
                <i className={`fa-solid fa-sack-dollar fa-lg`} title={textoComissao} style={{color: "red"}}/>&nbsp;&nbsp;
                <i className={`fa-solid fa-calendar-plus fa-lg`} title={"Contrato renovado"} style={{color: "grey"}}/>
            </div>
        );
    } else if  (acompanhamento.comissaoPaga && !acompanhamento.contratoRenovado && acompanhamento?.tipoComissao?.descricao !== 'AVULSO') {
        return (
            <div className="flex justify-content-center" >
                {acompanhamento.divisorParcela > 1 ? <i className={`fa-solid fa-divide fa-lg`} title={divisor} style={{color: "red"}}/> : null} &nbsp;&nbsp;
                {acompanhamento.fechada ? <i className={`fa-solid fa-lock yellow-900 fa-lg`} title={fechada}/> : null} &nbsp;&nbsp;
                <i className={`fa-solid fa-sack-dollar fa-lg`} title={textoComissao} style={{color: "red"}}/>
                
            </div>
        );
    } else {
        return (
            <div className="flex justify-content-center" >
                {acompanhamento.divisorParcela > 1 ? <i className={`fa-solid fa-divide fa-lg`} title={divisor} style={{color: "red"}}/> : null}
                {acompanhamento.fechada ? <i className={`fa-solid fa-lock yellow-900 fa-lg`} title={fechada}/> : null}
            </div>
        );
    } 
}