import React, {useEffect, useState} from "react";

import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import moment from "moment";
import {formatNumber} from "chart.js/helpers";
import {headerTemplate} from "../../utils/templates";
import {useBreakpoint} from "../../context/BreakpointContext";
import {Calendar} from "../../components/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { DataTable } from "../../components/datatable";
import { TabPanel, TabView } from "primereact/tabview";
import { relatorioOperacoesService } from "../../service/relatorioOperacoesService";
import { InputNumber } from "../../components/inputnumber";
import { UsuarioAutoComplete } from "../../components/autocomplete/usuarioAutoComplete";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

const sum = [(a, b) => a + b, 0];

export function RelatorioOperacoes() {

    const [loading, setLoading] = useState(false);    
    const [params, setParams] = useState({
        responsavel: null,
        inicio: moment().subtract(1, 'week').format("YYYY-MM-DD"),
        fim: moment().format("YYYY-MM-DD"),
    });
    const [geralPorOperador, setPorOperador] = useState([{}]);
    const [geralPorOrdem, setPorOrdem] = useState([{}]);

    const {breakpoint} = useBreakpoint()
    const isLargeDevice = breakpoint === 'lg' || breakpoint === 'md'

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    
    function handleChange(event) {
        setParams({...params, [event.name]: event.value});
    }

    useEffect(listar, []);

    async function listar() {        
        let query = {...params};
        query.responsavel = params.responsavel?.id;

        setPorOperador(await relatorioOperacoesService.listarPorOperador(query));
        setPorOrdem(await relatorioOperacoesService.listarPorOrdem(query));                
    } 

    async function csvOperador() {
        setLoading(true);
        const query = [];

        if (params.inicio) query.push(`inicio=${params.inicio}`);
        if (params.fim) query.push(`fim=${params.fim}`);
        if (params.usuario?.id) query.push(`usuario=${params.usuario.id}`);
        
        if (params.cliente?.id) {
			query.push(`cliente=${params.cliente.id}`);
		} else if (params.cliente?.length > 0) {
			query.push(`clienteStr=${params.cliente}`);
		}

        toast.promise(
            relatorioOperacoesService.csvOperador(query).then((data) => {
                console.log("data_antes",data);
            saveAs(new Blob([data], {type: "application/csv"}), `${moment().format("YYYYDDMMHHmmss")}.csv`);           
            }).finally(() => { setLoading(false) }),
            {
                "pending": `Gerando relatório. Aguarde...`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao procurar ordens de Ordens de Serviço - Cliente: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }

    async function csvOrdemServico() {
        setLoading(true);
        const query = [];

        if (params.inicio) query.push(`inicio=${params.inicio}`);
        if (params.fim) query.push(`fim=${params.fim}`);
        if (params.usuario?.id) query.push(`usuario=${params.usuario.id}`);
        
        if (params.cliente?.id) {
			query.push(`cliente=${params.cliente.id}`);
		} else if (params.cliente?.length > 0) {
			query.push(`clienteStr=${params.cliente}`);
		}

        toast.promise(
            relatorioOperacoesService.csvOrdemServico(query).then((data) => {
                console.log("data_antes",data);
            saveAs(new Blob([data], {type: "application/csv"}), `${moment().format("YYYYDDMMHHmmss")}.csv`);           
            }).finally(() => { setLoading(false) }),
            {
                "pending": `Gerando relatório. Aguarde...`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao procurar ordens de Ordens de Serviço - Cliente: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }

    function formataMinutos(minutos) {
        const min = minutos % 60;

        const horas = ((minutos - min)/60);
        
        const textoHoras = (`${horas}`);
        const textoMinutos = (`0${min}`).slice(-2);
        
        return `${textoHoras }:${textoMinutos}`;
    }

    const headerColumnOperador = (
        <ColumnGroup>
           <Row>
                <Column rowSpan={2} sortable field="nome" header="Operador"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="recepcao" header="Recepção"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="laudo" header="Laudo"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="limpeza" header="Limpeza"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="manutencao" header="Manutenção"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="outros" header="Outros"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="teste" header="Teste"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="expedicao" header="Expedição"/>
                <Column colSpan={2} style={{textAlign: "center"}} field="total" header="Total"/>
           </Row>
            <Row>
                <Column sortable field="total" header="Qtd"/>
                <Column sortable field="totalTempo" header="Tempo"/>
            </Row>
        </ColumnGroup>
    );

    const footerColumnOperador = (
        <ColumnGroup>
            <Row>
                <Column rowSpan={1} footer={"Quantidade: " + geralPorOperador.length}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOperador.map(g => g.recepcao).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOperador.map(g => g.laudo).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOperador.map(g => g.limpeza).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOperador.map(g => g.manutencao).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOperador.map(g => g.outros).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOperador.map(g => g.teste).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOperador.map(g => g.expedicao).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOperador.map(g => g.total).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formataMinutos(geralPorOperador.map(g => g.totalTempo).reduce(...sum))}</div>}/>
            </Row>
        </ColumnGroup>
    );    

    const headerColumnOrdem = (
        <ColumnGroup>
            <Row>
                <Column rowSpan={2} sortable field="ordemServicoId" header="Ordem de Serviço"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="recepcao" header="Recepção"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="laudo" header="Laudo"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="limpeza" header="Limpeza"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="manutencao" header="Manutenção"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="outros" header="Outros"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="teste" header="Teste"/>
                <Column rowSpan={2} sortable style={{textAlign: "center"}} field="expedicao" header="Expedição"/>
                <Column colSpan={2} style={{textAlign: "center"}} field="total" header="Total"/>
           </Row>
            <Row>
                <Column sortable field="total" header="Qtd"/>
                <Column sortable field="totalTempo" header="Tempo"/>
            </Row>
        </ColumnGroup>
    );

    const footerColumnOrdem = (
        <ColumnGroup>
            <Row>
            <Column colSpan={1} footer={"Quantidade: " + geralPorOrdem.length}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOrdem.map(g => g.recepcao).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOrdem.map(g => g.laudo).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOrdem.map(g => g.limpeza).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOrdem.map(g => g.manutencao).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOrdem.map(g => g.outros).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOrdem.map(g => g.teste).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOrdem.map(g => g.expedicao).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geralPorOrdem.map(g => g.total).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formataMinutos(geralPorOrdem.map(g => g.totalTempo).reduce(...sum))}</div>}/>
            </Row>
        </ColumnGroup>
    );

    useEffect(() => {
        
    }, []);

    return (
        <div>
            <Panel headerTemplate={() => headerTemplate('Relatório de Operações', 'fa-file-lines')} >
                <PanelContent>
                    <Calendar label="Período" col={2} name="inicio" value={params.inicio} onChange={handleChange}/>
                    <Calendar label="&nbsp;" col={2} name="fim" value={params.fim} onChange={handleChange}/>

                    <InputNumber value={params.ordemServico} name="ordemServico" onChange={handleChange} label="Nº da Ordem de Serviço" col={2}/>
					<UsuarioAutoComplete col={4} name="responsavel" label="Responsável" onChange={handleChange} value={params.responsavel}/>

                </PanelContent>
                <PanelFooter>
                    <Button disabled={false} loading={false} onClick={csvOperador} label="CSV Operador" icon="fa-solid fa-file-arrow-down"/>
                    <Button disabled={false} loading={false} onClick={csvOrdemServico} label="CSV Ordem de Serviço" icon="fa-solid fa-file-arrow-down"/>
                    <Button disabled={false} loading={false} onClick={listar} label="Procurar" icon="pi pi-search"/>                    
                </PanelFooter>
            </Panel>

            <TabView>
                <TabPanel header="por Operador" leftIcon="pi pi-user ml-2">
                    <DataTable paginator={false} footer={null} headerColumnGroup={headerColumnOperador} footerColumnGroup={footerColumnOperador} value={geralPorOperador} valuelength={geralPorOperador?.length}>
                        <Column field="nome" style={{textAlign: "left"}} />
                        <Column field="recepcao" style={{textAlign: "right"}} body={r => formatNumber(r.recepcao)}/>
                        <Column field="laudo" style={{textAlign: "right"}} body={r => formatNumber(r.laudo)}/>
                        <Column field="limpeza" style={{textAlign: "right"}} body={r => formatNumber(r.limpeza)}/>
                        <Column field="manutencao" style={{textAlign: "right"}} body={r => formatNumber(r.manutencao)}/>
                        <Column field="outros" style={{textAlign: "right"}} body={r => formatNumber(r.outros)}/>
                        <Column field="teste" style={{textAlign: "right"}} body={r => formatNumber(r.teste)}/>
                        <Column field="expedicao" style={{textAlign: "right"}} body={r => formatNumber(r.expedicao)}/>
                        <Column field="total" style={{textAlign: "right"}} body={r => formatNumber(r.total)}/>
                        <Column field="total" style={{textAlign: "right"}} body={r => formataMinutos(r.totalTempo)}/>
                    </DataTable>
                </TabPanel>
                
                <TabPanel header="por Ordem de Serviço" leftIcon="fa-solid fa-filter">
                    <DataTable paginator={false} footer={null} headerColumnGroup={headerColumnOrdem} footerColumnGroup={footerColumnOrdem} value={geralPorOrdem} valuelength={geralPorOrdem?.length}>
                        <Column field="ordemServicoId" style={{textAlign: "left"}} />
                        <Column field="recepcao" style={{textAlign: "right"}} body={r => formatNumber(r.recepcao)}/>
                        <Column field="laudo" style={{textAlign: "right"}} body={r => formatNumber(r.laudo)}/>
                        <Column field="limpeza" style={{textAlign: "right"}} body={r => formatNumber(r.limpeza)}/>
                        <Column field="manutencao" style={{textAlign: "right"}} body={r => formatNumber(r.manutencao)}/>
                        <Column field="outros" style={{textAlign: "right"}} body={r => formatNumber(r.outros)}/>
                        <Column field="teste" style={{textAlign: "right"}} body={r => formatNumber(r.teste)}/>
                        <Column field="expedicao" style={{textAlign: "right"}} body={r => formatNumber(r.expedicao)}/>
                        <Column field="total" style={{textAlign: "right"}} body={r => formatNumber(r.total)}/>
                        <Column field="total" style={{textAlign: "right"}} body={r => formataMinutos(r.totalTempo)}/>
                    </DataTable>
                </TabPanel>

                {/**
                <TabPanel  disabled header="Gráfico" leftIcon="fa-solid fa-chart-simple">
                <ResponsiveContainer align={"end"}
                    width="70%"
                    height="100%"
                    aspect={2}>
                    <BarChart
                    data={geralPorOperador}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 10,
                        bottom: 150,
                    }}
                    >

                    <XAxis dataKey="nome" angle="-75" textAnchor="end"/>
                    <YAxis />
                    <Tooltip />
                    <Legend layout="vetical" verticalAlign="top" align="right" wrapperStyle={{top: 0, left: ResponsiveContainer.height}} />

                    <Bar dataKey="laudo" label={{ fill: 'white', fontSize: 10}} stackId="a" fill="blue" />
                    <Bar dataKey="limpeza" stackId="a" fill="red" />
                    <Bar dataKey="manutencao" stackId="a" fill="yellow" />
                    <Bar dataKey="outros" stackId="a" fill="green" />
                    <Bar dataKey="reprovados" stackId="a" fill="orange" />
                    <Bar dataKey="resssolda" stackId="a" fill="cyan" />
                    <Bar dataKey="teste" stackId= "a" fill="lightblue" />
                    </BarChart>
                </ResponsiveContainer>
                    </TabPanel>                 * 
                 */}
            </TabView>
            
        </div>
    );

}
