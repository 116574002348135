import React, {useCallback, useState} from "react";

import moment from "moment";
import {toast} from "react-toastify";
import {Panel} from "primereact/panel";

import {agrupar, desagruparDados, ordenarFaixas} from "./utils";
import {Button} from "../../../../components/button";
import {useAuth} from "../../../../context/AuthContext";
import {Calendar} from "../../../../components/calendar";
import {headerTemplate} from "../../../../utils/templates";
import { withDialog } from "../../../../utils/dialogContext";
import {useBreakpoint} from "../../../../context/BreakpointContext";
import {PanelContent, PanelFooter} from "../../../../components/panel";
import {comissaoAcompanhamentoService, optionsComissaoAcompanhamento} from "../../services/comissaoAcompanhamentoService";
import { InformationDialog } from "../../../../components/dialog/InformationDialog";
import { TipoComissaoDropdown } from "../../../../components/dropdown/tipoComissaoDropdown";
import { VendedorAutoComplete } from "../../../../components/autocomplete/VendedorAutoComplete";
import { SelectButton } from "../../../../components/selectbutton";
import { restricoesButtons } from "../../../../service/ordemServicoService";
import { comissaoFechamentoService } from "../../services/comissaoFechamentoService";
import { ConfirmDialog, ConfirmDialogV2 } from "../../../../components/confirmdialog";
import { TIPO_COMISSAO_NAO_IDENTIFICADO } from "../AcompanhamentoIndividual/utils";

export const FiltrosAcompanhamento = withDialog(React.memo(({setValue, acompanhamento, tiposComissao, showDialog, setBlocked, 
	setFechamento, fechamento, itensSelecionados, setModalConsultor, setModalTipoComissao
}) => {

	const {usuario} = useAuth();
    const {breakpoint} = useBreakpoint()
    const isLargeDevice = breakpoint === 'lg' || breakpoint === 'md'
    const [loading, setLoading] = useState(0);
    const [params, setParams] = useState({
		mes: moment().format("YYYY-MM"),
        vendedor: null,
        tipoComissao: 'todos',
		status: null,
	});

    const {roles} = useAuth();

    const handleChange = useCallback((event) => {
		setParams({...params, [event.name]: event.value});
	}, [params])
   
	const handleProcurar = useCallback(async () => {
		const query = [];
		if(!params?.mes){
			return showDialog(<InformationDialog 
				header="Informação" 
				message="A data não pode ser nula. Por favor informe uma data."
			/>);
		}

		const jaTemFechamento = await comissaoFechamentoService.buscarFechamento(moment(params.mes).startOf('month').format("YYYY-MM-DD"));

		

		query.push(`inicio=${moment(params.mes).startOf('month').format("YYYY-MM-DD")}`);
		query.push(`fim=${moment(params.mes).endOf('month').format("YYYY-MM-DD")}`);
		if (params?.vendedor) query.push(`vendedor=${params.vendedor?.id}`);
		if (params?.tipoComissao && params?.tipoComissao !== 'todos') query.push(`tipoComissao=${params.tipoComissao}`);
		if (params?.status) query.push(`status=${params.status}`);
		setLoading("Procurar");
		setBlocked(true);
		toast.promise(
			comissaoAcompanhamentoService.listar(query).then((value) => {
				if(jaTemFechamento.id){
					setFechamento(jaTemFechamento)
				}else{
					setFechamento(null);
				}
				setValue(agrupar(value));
			}).then(() => setLoading(0)).finally(() => setBlocked(false)),
			{
				"pending": `Procurando acompanhamento. Aguarde...`,
				"error": {
					render(e) {
						setLoading(0);
						return `Falha ao procurar acompanhamento: ${e.data?.response?.data}`;
					}
				}
			}
		);
	}, [params.mes, params.status, params.tipoComissao, params.vendedor, setBlocked, setFechamento, setValue, showDialog])

	const handleSalvar = async () => {
		const dadosOriginais= desagruparDados(acompanhamento);
		const apenasAlterados= dadosOriginais.filter(item => item?.modificado === true);

		try {
			apenasAlterados.forEach(item => {
				if (item.valorBaseCalculoComissao === null) {
					throw new Error(`O campo "Valor Base Cálculo Comissão" para o consultor de vendas ${item.vendedor?.nome} em "${item.tipoComissao?.descricao}" não está preenchido. Por favor, informe um valor.`);
				}
				if (item.percentualComissao === null) {
					throw new Error(`O campo "% Comissão" para o consultor de vendas ${item.vendedor?.nome} em "${item.tipoComissao?.descricao}" não está preenchido. Por favor, informe um valor.`);
				}
				if (item.valorComissao === null) {
					throw new Error(`O campo "Comissão" para o consultor de vendas ${item.vendedor?.nome} em "${item.tipoComissao?.descricao}" não está preenchido. Por favor, informe um valor.`);
				}
			});
		} catch (error) {
			return showDialog(<InformationDialog header="Informação" message={error.message} />);
		}
		setLoading("Salvar");
		const salvar = apenasAlterados.map( item => {
			if (!item.tipoComissao.id) {
				item.tipoComissao = null;
			}
			return item;
		})
		await comissaoAcompanhamentoService.salvarTodos(salvar).then(handleProcurar);
		setLoading(0);
	}

	const handleCleamFilters = () => {
		const newParams = {
			mes: params.mes,
			vendedor: null,
			tipoComissao: 'todos',
			status: null,
		}

		setParams(newParams);

		const query = [];
		if(!newParams?.mes){
			return showDialog(<InformationDialog 
				header="Informação" 
				message="A data não pode ser nula. Por favor informe uma data."
			/>);
		}
		query.push(`inicio=${moment(newParams.mes).startOf('month').format("YYYY-MM-DD")}`);
		query.push(`fim=${moment(newParams.mes).endOf('month').format("YYYY-MM-DD")}`);

		setLoading("Procurar");
		toast.promise(
			comissaoAcompanhamentoService.listar(query).then((value) => {
				setValue(agrupar(value));
			}).then(() => setLoading(0)),
			{
				"pending": `Procurando acompanhamento. Aguarde...`,
				"error": {
					render(e) {
						setLoading(0);
						return `Falha ao procurar acompanhamento: ${e.data?.response?.data}`;
					}
				}
			}
		);
	}
	const handleGerarFechamento = async () => {
		const dadosOriginais= desagruparDados(acompanhamento);
		
		const alterado = dadosOriginais.find(item => item?.modificado === true);
		if(alterado?.id){
			return showDialog(<InformationDialog 
				header="Informação" 
				message='Foi encontrado dados alterados não salvos, por favor salve os dados antes de realizar o fechamento de comissão.'
			/>);
		}
	
		const notaSemTipo = dadosOriginais.find(item => item.tipoComissao.descricao === TIPO_COMISSAO_NAO_IDENTIFICADO);
		if(notaSemTipo?.id){
			return showDialog(<InformationDialog 
				header="Informação" 
				message='Foram encontrados itens com o tipo de comissão "Não identificado", para continuar o fechamento selecione um tipo de comissão.'
			/>);
		}
		setBlocked(true);
		const dadosFormatados = dadosOriginais
			.reduce((grupos, item) => {
				const { vendedor, tipoComissao, ...rest } = item;
				const tipoComissaoCompleto = tiposComissao?.find(tipoComissaoCompleto => tipoComissaoCompleto.id === tipoComissao?.id);
				
				let existingGroup = grupos.find(group => group.vendedor.id === vendedor.id);
				if (!existingGroup) {
					existingGroup = {
						vendedor: vendedor,
						itens: [],
						tiposComissao: {}
					};
					grupos.push(existingGroup);
				}

				let descricaoExiste;
				
				existingGroup.itens = existingGroup.itens.map((ComissaoFechamentoVendedorItem) => {
					if(ComissaoFechamentoVendedorItem.descricao === tipoComissao.descricao){
						descricaoExiste = true;
						return {
							...ComissaoFechamentoVendedorItem,
							notas: [...ComissaoFechamentoVendedorItem.notas, item]
						}
					}else{
						return ComissaoFechamentoVendedorItem;
					}
				})
				if(!descricaoExiste){
					existingGroup.itens.push({ 
						descricao: tipoComissao.descricao,
						faixas: tipoComissaoCompleto?.itens.map(faixa =>{
							return {
								...faixa,
								valorComissao: 0,
								tipo: tipoComissaoCompleto?.tipo
							}
						}),
						tipo: tipoComissaoCompleto?.tipo,
						notas: [item],
						valorComissao: 0,
						valorFaturamento: 0,
					});
				}

				descricaoExiste = false;
				return grupos;
			}, [])

		let valorComissao = 0;
		let valorFaturamento = 0;

		const vendedores = dadosFormatados.map(vendedor => {
			return {
				...vendedor,
				itens: vendedor.itens.map((item) => {
					
					let valorComissaoItem = 0;
					let valorFaturamentoItem = 0;
					let somaValorBaseCalculoComissao = 0;
					let faixas = [];
					const notas = item.notas.map((nota, index, array) => {
						nota.comissaoAcompanhamento = {id: nota.id};

						
						if(item.tipo === 'UNICA'){
							valorFaturamentoItem += nota?.valorFaturamento || 0;
							if(nota.status === 'APROVADO'){
								valorComissaoItem += nota?.valorComissao || 0;
								somaValorBaseCalculoComissao += nota?.valorBaseCalculoComissao || 0;
							}
							
							return nota;
						}
						if(item.tipo === 'FAIXA_INDIVIDUAL'){
							
							const faixasOrdenadas = ordenarFaixas(item.faixas);
							const faixaComtemplada = faixasOrdenadas.find((faixa) => {
								if (faixa.acima && nota.valorFaturamento >= faixa.valor) {
									return true;
								}

								if (nota.valorFaturamento <= faixa.valor) {
									return true;
								}
							});	
							
							let comissao = 0; 
							
							if(nota.status === 'APROVADO'){
								somaValorBaseCalculoComissao += nota?.valorBaseCalculoComissao;
								let exists = false;
								comissao = (faixaComtemplada.percentual / 100) * nota.valorBaseCalculoComissao;
								valorComissaoItem += comissao
								faixas = faixas?.map(faixa => {
									if(faixa?.id === faixaComtemplada?.id){
										const soma = comissao + faixa.valorComissao;
				
										exists = true;
										return {
											...faixa,
											valorComissao: soma
										}
									}else{
										return faixa;
									}
								})
				
								if(!exists){
									faixas.push({
										...faixaComtemplada,
										percentual: faixaComtemplada.percentual,
										valorFaixa: faixaComtemplada.valor,
										valorComissao: comissao,
										tipo: faixaComtemplada.tipo,
										
									})
								}
				
								exists = false;
							}
							
							valorFaturamentoItem += nota?.valorFaturamento || 0;
							

							return {
								...nota,								
								valorComissao: comissao,
								percentualComissao: faixaComtemplada.percentual,
							}
						}
						if(item.tipo === 'FAIXA_SOMA'){
							valorFaturamentoItem += nota?.valorFaturamento || 0;

							if(nota.status === 'APROVADO'){
								somaValorBaseCalculoComissao += nota?.valorBaseCalculoComissao || 0;
							}

							if(array.length -1 === index){
								const faixasOrdenadas = ordenarFaixas(item?.faixas);
								const faixaComtemplada = faixasOrdenadas.find((faixa) => {
									if (faixa.acima && somaValorBaseCalculoComissao >= faixa.valor) {
										return true;
									}
	
									if (somaValorBaseCalculoComissao <= faixa.valor) {
										return true;
									}
								});
								
								valorComissaoItem = (faixaComtemplada.percentual / 100) * somaValorBaseCalculoComissao;
								faixas.push({
									percentual: faixaComtemplada.percentual,
									valorFaixa: faixaComtemplada.valor,
									valorComissao: valorComissaoItem,
									tipo: faixaComtemplada.tipo,
									
								})
							}


							return nota
						}			

						return nota;
					});

					return {
						...item,
						valorComissao: valorComissaoItem,
						valorFaturamento: valorFaturamentoItem,
						notas,
						faixas,
						totalValorBaseCalculoComissao: somaValorBaseCalculoComissao,
					}
				})
			}
			
		});
		

		const fechamento = {
			inicio: moment(params.mes).startOf('month').format("YYYY-MM-DD"),
			fim: moment(params.mes).endOf('month').format("YYYY-MM-DD"),
			valorComissao,
			valorFaturamento,
			fechadoPor: usuario.email,
			fechadoEm: moment().format("YYYY-MM-DDTHH:mm:ss"),
			vendedores
		}
		
		comissaoFechamentoService.salvar(fechamento).finally(() =>{ 
			handleProcurar();
			setBlocked(false)
		});
		// setLoading("Salvar");
		//await comissaoAcompanhamento.salvarTodos(apenasAlterados).then(handleProcurar);
		// setLoading(0);
	}

	const handleConfirmacaoFechamento = () => {
		if(params.tipoComissao !== 'todos' || params?.vendedor || params?.status){
			return showDialog(<ConfirmDialogV2 
				message="Para gerar um fechamento é nescessário que os parâmetros de busca sejam vazios. Gostaria de limpar os parâmetros e realizar a busa novamente?"
				onYes={handleCleamFilters}
			/>);
		}

		return showDialog(<ConfirmDialogV2 
			message="Tem certeza que você deseja executar o fechamento da comissão? Este é um processo definitivo e não poderá ser alterado."
			onYes={handleGerarFechamento}
			red
		/>);
	}
	const handleConfirmacaoSalvar = () => {
		return showDialog(<ConfirmDialogV2 
			message="Tem certeza que você deseja salvar as modificações?"
			onYes={handleSalvar}
		/>);
	}

    return (
        <Panel headerTemplate={() => headerTemplate('Acompanhamento de Comissão', 'fa-sack-dollar')}>
            <PanelContent>
                <Calendar 
					label="Período de fechamento" 
					col={2} 
					name="mes" 
					value={params.mes} 
					onChange={handleChange}
					view="month"
					dateFormat="mm/yy"
					showButtonBar={false}
				/>
                <VendedorAutoComplete 
                    col={3} 
                    //disabled={!roles.OSSL} 
                    value={params.vendedor} 
                    name="vendedor" 
                    onChange={handleChange} 
                    label="Consultor de Vendas"
                />

				<TipoComissaoDropdown
					value={params?.tipoComissao}
					tiposComissao={[{descricao: 'TODOS', id: 'todos'} , ...tiposComissao]}
					label="Tipo de Comissão"
					name="tipoComissao"
					onChange={handleChange} 
					col={3}
				/>


				<SelectButton 
					pt={{button: ({context}) => ({className: context.selected ? params.status === "APROVADO" ? "sb-success" : "sb-unsuccess" : null})}}
					onChange={handleChange}
					itemTemplate={restricoesButtons}
					col={2}
					label="Status"
					name="status"
					value={params?.status}
					options={optionsComissaoAcompanhamento}
				/>
            </PanelContent>
            <PanelFooter>
                <Button 
                    info 
                    disabled={loading}
                    loading={loading === "Procurar"}
                    onClick={handleProcurar}
                    label={isLargeDevice ? "Procurar" : null}
                    icon="pi pi-search"
                    autowidth={!isLargeDevice}
                />
				<div style={{float: "left"}}>
					<Button 
						success 
						disabled={loading || acompanhamento?.length === 0 || fechamento?.id}
						loading={loading === "Salvar"}
						onClick={handleConfirmacaoSalvar}
						icon="fa-solid fa-floppy-disk"
						autowidth
						tooltip='Salvar modificações'
						tooltipOptions={{ position: 'bottom' }}
					/>
					<Button 
						secondary 
						disabled={loading || acompanhamento?.length === 0 || fechamento?.id}
						loading={loading === "Salvar"}
						onClick={handleConfirmacaoFechamento}
						icon="fa-solid fa-lock"
						autowidth
						tooltip='Fechamento da comissão: Após realizar o fechamento da comissão, as notas incluídas não poderão ser alteradas.'
						tooltipOptions={{ position: 'top' }}
					/>
					<Button 
						secondary 
						disabled={loading || acompanhamento?.length === 0 || fechamento?.id || itensSelecionados.length === 0}
						loading={loading === "Salvar"}
						onClick={() => setModalTipoComissao(true)}
						icon="fa-solid fa-percent"
						autowidth
						tooltip='Trocar tipo de comissão'
						tooltipOptions={{ position: 'bottom' }} 
					/>
					<Button 
						secondary 
						disabled={loading || acompanhamento?.length === 0 || fechamento?.id || itensSelecionados.length === 0}
						loading={loading === "Salvar"}
						onClick={() => setModalConsultor(true)}
						icon="fa-solid fa-user-tie"
						autowidth
						tooltip='Trocar consultor de venda'
						tooltipOptions={{ position: 'top' }} 
					/>
				</div>
            </PanelFooter> 
        </Panel>
    );

}));
