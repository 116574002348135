import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {SeloIpemAutoComplete} from "../../components/autocomplete/seloIpemAutoComplete";
import {Checkbox} from "../../components/checkbox";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {InputNumber} from "../../components/inputnumber";
import {InputText} from "../../components/inputtext";
import {PanelContent} from "../../components/panel";
import {InputCheckbox} from "../../components/toogleinputtext";
import {equipamentoService} from "../../service/equipamentoService";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";
import {withDialog} from "../../utils/dialogContext";
import {useAuth} from "../../context/AuthContext";

export const acessorios = [
	{name: "CABO_COMUNICACAO", label: "Cabo de Comunicação"},
	{name: "CABO_FORCA", label: "Cabo de Força"},
	{name: "ALCA", label: "Alça"},
	{name: "MANOPLA", label: "Manopla"},
	{name: "BATERIA", label: "Bateria"},
	{name: "ETIQUETA", label: "Etiqueta"},
	{name: "RIBBON", label: "Ribbon"},
	{name: "CARTAO", label: "Cartão"},
	{name: "FONTE", label: "Fonte"},
	{name: "CHIP", label: "Chip"},
	{name: "OUTRO", label: "Outros", col: 6}
];

export const EditarOrdemServicoItemDadosPrincipais = withDialog(({ordemServico, item, index, handleChange, messages, showDialog, setMessages}) => {

	const [lock, setLock] = useState(true);
	const [hasSerial, setHasSerial] = useState(true);
	const {roles} = useAuth();

	function buscarEquipamento() {
		if (item.equipamento?.serial?.length) {
			setLock(true);
			equipamentoService.findBySerial(item.equipamento?.serial).then(async equipamento => {
				if (equipamento) {
					if (await ordemServicoItemService.existeEquipamentoEmOrdemServico(item.ordemServico?.id, equipamento.id)) {
						handleChange({name: "equipamento", value: {serial: "", produto: null}});
						showDialog(<InformationDialog message="Não é possível adicionar o mesmo equipamento novamente na mesma ordem de serviço."/>);
						setMessages(prevMessages => {
							prevMessages.itens[index] = ordemServicoItemService.validar(item, ordemServico);
							return prevMessages;
						});
						return;
					}
					item.equipamento = equipamento;
				} else {
					item.equipamento = {
						_alterado: true,
						serial: item.equipamento.serial,
						produto: null
					};
				}
				handleChange({name: "equipamento", value: item.equipamento});
				setLock(false);
				setMessages(prevMessages => {
					prevMessages.itens[index] = ordemServicoItemService.validar(item, ordemServico);
					return prevMessages;
				});
			});
		} else if (!hasSerial) {
			item.equipamento = {
				_alterado: true,
				serial: moment().format("YYMMDDHHmmssSSS"),
				produto: item.equipamento?.produto
			};
			handleChange({name: "equipamento", value: item.equipamento});
			setMessages(prevMessages => {
				prevMessages.itens[index] = ordemServicoItemService.validar(item, ordemServico);
				return prevMessages;
			});
			setLock(false);
		} else {
			setLock(true);
		}
	}

	useEffect(() => {
		if (!item.id) {
			if (hasSerial) {
				handleChange({name: "equipamento", value: {...item.equipamento, serial: ""}});
				setLock(true);
			} else {
				buscarEquipamento();
			}
		}
	}, [hasSerial]);

	function handleChangeAcessorio(event) {
		item.acessorios.filter(ia => ia.tipo === event.option).forEach(ia => ia[event.name] = event.value);
		handleChange({name: "acessorios", value: item.acessorios});
	}

	function handleChangeToggleAcessorio(event) {
		if (event.value) {
			if (!item.acessorios.some(ia => ia.tipo === event.name)) {
				item.acessorios.push({tipo: event.name, identificacao: ""});
			} else {
				item.acessorios = item.acessorios.filter(ia => ia.tipo !== event.name);
			}
		}
		handleChange({name: "acessorios", value: item.acessorios});
	}

	const handleChangeEquipamento = useCallback(event => {
		let {equipamento} = item;
		if (!equipamento) {
			equipamento = equipamentoService.criar();
		}
		equipamento[event.name] = event.value;
		equipamento._alterado = true;
		handleChange({name: "equipamento", value: equipamento});
	}, [handleChange, item]);

	const handleChangeEquipamentoProduto = useCallback(event => {
		const {equipamento} = item;
		if (equipamento.produto) {
			equipamento.produto[event.name] = event.value;
			equipamento.produto._alterado = true;
			handleChange({name: "equipamento", value: equipamento});
		}
	}, [handleChange, item]);

	function handleChangeFlag(event) {
		if (!event.value) {
			item.flags = item.flags.filter(f => event.name !== f);
		} else {
			item.flags.push(event.name);
		}
		handleChange({name: "flags", value: item.flags});
	}

	function validarItem() {
		messages.itens[index] = ordemServicoItemService.validar(item, ordemServico);
		setMessages({...messages});
	}

	function toggleRevisado() {
		item.status = item.status === "REVISADO" ? "PENDENTE" : "REVISADO";
		handleChange({name: "status", value: item.status});
	}

	return (
		<PanelContent>
			<InputCheckbox readOnly={!roles.OSSS && item.id} required onBlur={buscarEquipamento} invalid={messages.itens?.[index]?.equipamento} col={3} inputName="serial" checkboxName="hasSerial" label="Nº de Série" inputValue={item.equipamento?.serial} checkboxValue={hasSerial} onInputChange={handleChangeEquipamento} onCheckboxChange={() => !item.id && setHasSerial(prevHasSerial => !prevHasSerial)}/>
			<ProdutoAutoComplete onBlur={validarItem} readOnly={lock && !item.equipamento?.produto} required invalid={messages.itens?.[index]?.equipamento} label="Modelo do Equipamento" name="produto" col={4} onChange={handleChangeEquipamento} value={item.equipamento?.produto}/>
			<InputText readOnly={lock && !item.id} col={2} name="numeroAtivo" label="Nº do Ativo" value={item.equipamento?.numeroAtivo} onChange={handleChangeEquipamento}/>
			<InputText readOnly={lock && !item.id} col={3} name="descricao" label="Identificação" value={item.equipamento?.descricao} onChange={handleChangeEquipamento}/>
			{
				ordemServico.operacao === "LABORATORIO" ? null : (
					<>
						<InputText readOnly={lock && !item.id} col={3} name="setor" label="Setor" value={item.equipamento?.setor} onChange={handleChangeEquipamento}/>
						<InputText readOnly={lock && !item.id} col={2} name="pam" label="PAM" value={item.equipamento?.produto?.pam || ""} onChange={handleChangeEquipamentoProduto}/>
						<InputText readOnly={lock && !item.id} col={2} name="divisao" label="Divisão" value={item.equipamento?.produto?.divisao || ""} onChange={handleChangeEquipamentoProduto}/>
						<InputNumber readOnly={lock && !item.id} col={2} name="capacidade" label="Capacidade" value={item.equipamento?.produto?.capacidade || ""} onChange={handleChangeEquipamentoProduto}/>
						<InputText readOnly={lock && !item.id} col={1} name="unidadeCapacidade" label="Unidade" value={item.equipamento?.produto?.unidadeCapacidade || ""} onChange={handleChangeEquipamentoProduto}/>
						<InputText readOnly={lock && !item.id} col={2} name="anoFabricacao" label="Mês/Ano" value={item.equipamento?.anoFabricacao} onChange={handleChangeEquipamento}/>
						<SeloIpemAutoComplete multiple disabled={lock && !item.id} col={4} name="selosIpem" label="Selos do IPEM Afixados" value={item.selosIpem} onChange={handleChange}/>
						<InputText disabled={lock && !item.id} col={4} label="Lacres Retirados" name="lacresRetirados" onChange={handleChange} value={item.lacresRetirados}/>
						<InputText disabled={lock && !item.id} col={4} label="Lacres Afixados" name="lacresAfixados" onChange={handleChange} value={item.lacresAfixados}/>
					</>
				)
			}
			<label style={{display: "block", width: "100%", marginLeft: "6px"}}>Acessórios</label>
			{acessorios.map(a => <InputCheckbox disabled={lock && !item.id} placeholder="Nº de Identificação" onCheckboxChange={handleChangeToggleAcessorio} col={a.col || 3} label={a.label} checkboxName={a.name} inputName="identificacao" inputValue={item.acessorios.filter(ia => ia.tipo === a.name)[0]?.identificacao} checkboxValue={item.acessorios.some(osia => osia.tipo === a.name)} onInputChange={handleChangeAcessorio}/>)}
			<div style={{height: "15px", width: "100%"}}/>
			<InputText disabled={lock && !item.id} col={12} name="complemento" label="Complemento (Detalhamento do Equipamento)" value={item.complemento} onChange={handleChange}/>
			<InputText maxLength={3800} disabled={lock && !item.id} multiline style={{height: "6em"}} col={12} name="problemaIndicado" label="Problema Indicado" value={item.problemaIndicado} onChange={handleChange}/>
			<InputText maxLength={3800} disabled={lock && !item.id} multiline style={{height: "10em"}} col={12} name="laudoExterno" label="Laudo Externo" value={item.laudoExterno} onChange={handleChange}/>
			<InputText maxLength={3800} disabled={lock && !item.id} multiline style={{height: "13em"}} col={12} name="laudoInterno" label="Laudo Interno" value={item.laudoInterno} onChange={handleChange}/>
			<Checkbox disabled={lock && !item.id} col={2} label="Perda Total" name="PERDA_TOTAL" value={item.flags.includes("PERDA_TOTAL")} onChange={handleChangeFlag}/>
			<Checkbox disabled={lock && !item.id} col={2} label="Local Diferente" name="LOCAL_DIFERENTE" value={item.flags.includes("LOCAL_DIFERENTE")} onChange={handleChangeFlag}/>
			<Checkbox disabled={lock && !item.id} col={2} label="Mau Uso" name="MAU_USO" value={item.flags.includes("MAU_USO")} onChange={handleChangeFlag}/>
			<Checkbox disabled={lock && !item.id} col={2} label="Equipamento Não Encontrado" name="EQUIPAMENTO_NAO_ENCONTRADO" value={item.flags.includes("EQUIPAMENTO_NAO_ENCONTRADO")} onChange={handleChangeFlag}/>
			<Checkbox disabled={lock && !item.id} col={4} label="Revisão, Limpeza e Regulagem" name="LIMPEZA_E_REGULAGEM" value={item.status === "REVISADO"} onChange={toggleRevisado}/>
		</PanelContent>
	);

});