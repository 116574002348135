import React, { useState } from "react";
import { Dialog } from ".";

export function InformationDialog({header, message, onClose}) {

	const [visible, setVisible] = useState(true);

	function handleHide() {
		setVisible(false);
		if (onClose) {
			onClose();
		}
	}

	return (
		<Dialog header={header} visible={visible} onHide={handleHide} children={message} onClose={onClose} footer="information"/>
	);

}