import React, {useContext, useEffect, useState} from "react";
import {Dialog, DialogFooter} from "../../../components/dialog";
import {PanelContent} from "../../../components/panel";
import {Button} from "../../../components/button";
import {DataTable} from "../../../components/datatable";
import moment from "moment";
import {Column} from "primereact/column";
import {DialogContext} from "../../../utils/dialogContext";
import {EditarDeslocamento} from "./EditarDeslocamento";
import {ordemServicoDeslocamentoService} from "../../../service/ordemServicoService";
import {ConfirmDialogV2} from "../../../components/confirmdialog";
import {If} from "../../../components/conditional/If";
import {ProgressDialog} from "../../../components/progressdialog/ProgressDialog";
import {BlockUI} from "primereact/blockui";
import {formatMinutes} from "../../../utils/numberFormatter";
import {useAuth} from "../../../context/AuthContext";

export function Deslocamentos({ordemServico, onModalClose}) {

    const {usuario} = useAuth();
    const [loading, setLoading] = useState(true);
    const {showDialog} = useContext(DialogContext);
    const [visible, setVisible] = useState(true);
    const [deslocamentos, setDeslocamentos] = useState([]);
    const [selecionados, setSelecionados] = useState([]);

    useEffect(() => {
        ordemServicoDeslocamentoService.listar(ordemServico.id).then(deslocamentos => {
            setDeslocamentos(deslocamentos);
            setLoading(false);
        });
    }, [ordemServico.id]);

    async function handleSalvar() {
        if (deslocamentos.length) {
            showDialog(<ProgressDialog onProgress={async setProgress => {
                let progress = 0;
                for (const deslocamento of deslocamentos) {
                    await ordemServicoDeslocamentoService.salvar(deslocamento);
                    setProgress(++progress / deslocamentos.length);
                }
                if (onModalClose) {
                    onModalClose();
                }
                handleClose();
            }}/>);
        } else {
            handleClose();
        }
    }

    function handleClose() {
        setVisible(false);
    }

    function adicionarDeslocamento() {
        editarDeslocamento({
            ...ordemServicoDeslocamentoService.criar(),
            ordemServico: {id: ordemServico.id},
            responsavel: usuario
        });
    }

    function editarDeslocamento(deslocamento, finalizar = false) {
        if (finalizar && !deslocamento.dataFinal) {
            deslocamento.dataFinal = moment().format("YYYY-MM-DDTHH:mm:ss");
        }
        showDialog(<EditarDeslocamento deslocamento={deslocamento} onChange={d => {
            setDeslocamentos(prevDeslocamentos => {
                const index = prevDeslocamentos.findIndex(pd => (pd.id && pd.id === d.id) || (pd._key && pd._key === d._key));
                if (index > -1) {
                    prevDeslocamentos[index] = d;
                } else {
                    prevDeslocamentos.push(d);
                }
                return [...prevDeslocamentos];
            });
        }}/>);
    }

    function removerDeslocamentos() {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os deslocamentos selecionados?" onYes={async () => {
            showDialog(<ProgressDialog onProgress={async setProgress => {
                let progress = 0;
                for (const selecionado of selecionados) {
                    if (selecionado.id) {
                        await ordemServicoDeslocamentoService.delete(selecionado.id, ordemServico.id);
                    }
                    setProgress(++progress / selecionados.length);
                }
                setDeslocamentos(prevDeslocamentos => [...prevDeslocamentos.filter(dd => (dd.id && !selecionados.some(ss => ss.id === dd.id)) || (dd._key && !selecionados.some(ss => ss._key === dd._key)))]);
                setSelecionados([]);
            }}/>);
        }}/>);
    }

    const controlSet = (
        <div>
            <Button disabled={deslocamentos.some(dd => !dd.dataFinal)} icon="fas fa-plus" onClick={adicionarDeslocamento}/>
            <Button disabled={!selecionados.length} icon="fas fa-minus" danger onClick={removerDeslocamentos}/>
        </div>
    );

    return (
        <Dialog header={`Deslocamentos para a OS ${ordemServico.numero}`} visible={visible} style={{width: "600px"}} onHide={handleClose}>
            <BlockUI blocked={loading}>
                <PanelContent>
                    <div>
                        <div>{ordemServico.cliente?.autoComplete}</div>
                        <div>{ordemServico.cliente?.endereco}</div>
                    </div>
                    <div className="col-12">
                        <DataTable selection={selecionados}
                                   onSelectionChange={e => setSelecionados(e.value)}
                                   selectionMode="checkbox"
                                   header={controlSet}
                                   footer={controlSet}
                                   value={deslocamentos}>
                            <Column style={{width: "3em"}} selectionMode="multiple"/>
                            <Column style={{width: "33%"}} header="Início" field="inicio" body={d => (
                                <div>
                                    <div><i className="fas fa-calendar"/> {moment(d.dataInicial).format("DD/MM/YYYY HH:mm")}</div>
                                    <div><i className="fas fa-map-marked"/> {d.odometroInicial} Km</div>
                                </div>
                            )}/>
                            <Column style={{width: "33%"}} header="Fim" field="fim" body={d => (
                                <If condition={d.dataFinal}>
                                    <div><i className="fas fa-calendar"/> {moment(d.dataFinal).format("DD/MM/YYYY HH:mm")}</div>
                                    <div><i className="fas fa-map-marked"/> {d.odometroFinal} Km</div>
                                </If>
                            )}/>
                            <Column style={{width: "33%"}} header="Tempo/Distância" field="fim" body={d => (
                                <If condition={d.dataFinal}>
                                    <div><i className="fas fa-clock"/> {formatMinutes(moment(d.dataFinal).diff(moment(d.dataInicial), "minutes"))}</div>
                                    <div><i className="fas fa-map-marked"/> {d.odometroFinal - d.odometroInicial} Km</div>
                                </If>
                            )}/>
                            <Column header="Ações" style={{width: "5em", whiteSpace: "nowrap"}} body={d => (
                                <Button icon="fas fa-edit" onClick={() => editarDeslocamento(d, true)}/>
                            )}/>
                        </DataTable>
                    </div>
                </PanelContent>
                <DialogFooter>
                    <Button icon="fas fa-save" success label="Salvar" onClick={handleSalvar}/>
                    <Button icon="fas fa-times" secondary label="Fechar" onClick={handleClose}/>
                </DialogFooter>
            </BlockUI>
        </Dialog>
    );

}
