import React, {cloneElement, useCallback, useContext, useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import {useBreakpoint} from "../../context/BreakpointContext";
import {faturamentoService, formaFaturamentoStatus, optionsFilterStatus} from "../../service/faturamentoService";
import {InputNumber} from "../../components/inputnumber";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {Dropdown} from "../../components/dropdown";
import {useNavigate} from "react-router-dom";
import {Key_FATURAMENTO_NEW} from "./EditarFaturamento";
import {formatCurrency} from "../../utils/numberFormatter";
import moment from "moment";
import {Dialog, DialogFooter} from "../../components/dialog";
import {SelectButton} from "../../components/selectbutton";
import {DialogContext} from "../../utils/dialogContext";
import {useAuth} from "../../context/AuthContext";
import {VendedorAutoComplete} from "../../components/autocomplete/VendedorAutoComplete";
import {vendedorService} from "../../service/vendedorService";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import { optionsOperacao, optionsOperacaoSelecionadaTemplate, optionsOperacaoTemplate } from "../../utils/operacao";
import { Faturamento } from ".";

const optionsNumero = [
    {label: "da OS", value: "OS"},
    {label: "do Pedido do Cliente", value: "PedCliente"},
    {label: "da NF de Entrada", value: "NF"},
    {label: "do Contrato", value: "Contrato"}
];


export const status = [
	{label: "Rascunho", value: "RASCUNHO" , color: '#FF9800', icon: 'fa-shopping-cart'},
	{label: "Erro", value: "ERRO" , color: '#F44336', icon: 'fa-times'},
	{label: "Aguardando", value: "AGUARDANDO", color: '#2196F3', icon: 'fa-clock'},
	{label: "Confirmado", value: "CONFIRMADO", color: 'rgb(96, 125, 139)', icon: 'fa-lock'},
	{label: "Bloqueado", value: "BLOQUEADO", color: '#F44336', icon: 'fa-lock'},
	{label: "Carteira", value: "CARTEIRA", color: '#607D8B', icon: 'fa-wallet'},
	{label: "Faturado", value: "FATURADO", color: '#4CAF50', icon: 'fa-check'},
	{label: "Orçamento", value: "ORCAMENTO", icon: "fa-solid fa-file-invoice-dollar", color: "#795548"},
	{label: "Aprovado", value: "APROVADO", icon: "fa-solid fa-thumbs-up", color: "#4CAF50"},
	{label: "Perdido", value: "PERDIDO", icon: "fa-solid fa-thumbs-down", color: "#F44336"}
];

export function PrepararFaturamento() {

    const navigate = useNavigate();
    const {roles} = useAuth();
    const {breakpoint} = useBreakpoint();
    const isLargeDevice = breakpoint === "lg" || breakpoint === "md";
    const [faturamentos, setFaturamentos] = useState([]);
    const {showDialog} = useContext(DialogContext);
    const [valorTotalGeral, setValorTotalGeral] = useState(formatCurrency(0));

    const [loading, setLoading] = useState(0);
    const [selection, setSelection] = useState([]);
    const [params, setParams] = useState({
        numero: null,
        modoNumero: "OS",
        cliente: null,
        clienteFaturamento: null,
        vendedor: null,
        operacao: 'LABORATORIO',
        status: null
    });

    useEffect(() => {
        
        vendedorService.findByUsuario().then(vendedor => {
            setParams(prevParams => ({...prevParams, vendedor}));
        });

        let total = 0;
        for(let faturamento of selection) {
            total += faturamento.valorTotal;
        }
        setValorTotalGeral(formatCurrency(total));
      }, [selection]);

    async function handleProcurar() {
        const query = [];
        if (params.numero && params.modoNumero?.length) {
            switch (params.modoNumero) {
                case "OS":
                    query.push(`ordemServico=${params.numero}`);
                    break;
                case "PedCliente":
                    query.push(`numeroPedidoCliente=${params.numero}`);
                    break;
                case "NF":
                    query.push(`notaFiscal=${params.numero}`);                    
                    break;
                case "Contrato":
                    query.push(`contrato=${params.numero}`);
                    break;
                default:
                    break;
            }
        }
        if (params.cliente?.id) query.push(`cliente=${params.cliente.id}`);
        if (params.clienteFaturamento?.id) query.push(`clienteFaturamento=${params.clienteFaturamento.id}`);
        if (params.vendedor?.id) query.push(`vendedor=${params.vendedor.id}`);
        if (params.operacao) query.push(`operacao=${params.operacao}`);
        if (params.status) query.push(`status=${params.status}`);
        setLoading("procurar");
        await faturamentoService.listarOrdensServico(query).then(setFaturamentos);
        setLoading(0);
    }

    function handleChange(event) {
        setParams(prevParams => ({...prevParams, [event.name]: event.value}));
    }

    async function handleComposicao() {
        // if (selection.some(f => !f.notaFiscal?.numero)) {
        //     showDialog(<InformationDialog message="Não é possível faturar ordens de serviço sem a nota fiscal de entrada" header="Aviso"/>);
        //     return;
        // }
        const idReferencia = selection[0].clienteFaturamento.id;

        const temClienteFaturamentoDiferente = selection.some((faturamento) => faturamento.clienteFaturamento.id !== idReferencia);

        if (temClienteFaturamentoDiferente) {
			showDialog(<InformationDialog header="Informação" message="Os faturamentos selecionados tem que ter o mesmo cliente de faturamento."/>);
            return;        
		}

        console.log(selection)

        showDialog(<SelecionarTipoFaturamento tipo={params.operacao} onModalClose={async tipo => {
            setLoading("composicao");
            window.sessionStorage.setItem(Key_FATURAMENTO_NEW, JSON.stringify(await faturamentoService.criar(
                {
                    tipo, ids: 
                    selection.map(os => os.id), 
                    operacao: params.operacao 
                })));
            navigate("/faturamentos/new");
        }}/>);
    }

    function handleVoltar() {
        navigate(-1);
    }

    const faturamentoSummary = (
        <div>
            <Button success disabled={loading || !selection?.length} loading={loading === "composicao"} icon="fas fa-dollar-sign" label="Faturar Selecionados" onClick={handleComposicao}/>
            <table style={{float: "right", textAlign: "right", width: "auto", minWidth: "auto"}}>
                <tr><td>Total de registros:</td><td style={{minWidth: "5em"}}>{faturamentos.length}</td></tr>
                <tr><td>Total de registros selecionados:</td><td>{selection.length}</td></tr>
                <tr><td>Valor total selecionado:</td><td>{formatCurrency(valorTotalGeral)}</td></tr>
            </table>
            <div style={{clear: "both"}}/>
        </div>
    );

    const bodyContrato = data => {
        if (data.contratoNumero && data.dataConsumoFranquia) {
            return <div>
                <i
                    style={{ color: "#795548" }}
                    className="fas fa-big fa-file-signature mr-2"
                    title={`Contrato nº ${data.contratoNumero} - Data de consumo da franquia (${moment(data.dataConsumoFranquia).format("DD/MM/YYYY")}) `} />
                {data.contratoNumero}
            </div>;
        } else if (data.contratoNumero && !data.dataConsumoFranquia) {
            return <div>
            <i
                style={{ color: "#ce9b0e" }}
                className="fas fa-big fa-file-invoice-dollar mr-2"
                title={`Contrato nº ${data.contratoNumero} - Fora da franquia do contrato`} />
            {data.contratoNumero}
        </div>;
        } else {
            return <i
                style={{ color: "#F44336" }}
                className="fas fa-big fa-file-circle-xmark"
                title="Avulso" />;
        }
    };

    const bodyCliente = os => {

        if (os.pedidoVendaRetorno) {
            return (
                <div>
                    <div>
                        <i className="fas fa-big"/>
                        <i title="Cliente para faturamento" style={{color: "#009688"}} className="fas fa-big fa-wallet"/> 
                        {os.clienteFaturamento?.autoComplete}
                    </div>
                    <div>                    
                        <i title={`Pedido Venda de Retorno Gerado nº ${os.pedidoVendaRetorno}`} style={{color: "#00be19"}} className="fas fa-big fa-check-to-slot"/>
                        <i title="Cliente da Ordem de Serviço" style={{color: "#673AB7"}} className="fas fa-big fa-truck"/>
                        {os.cliente?.autoComplete}
                    </div>
                </div>
            );

        } else {
            return (
                <div>
                    <div>
                        <i  className="fas fa-big "/>
                        <i title="Cliente para faturamento" style={{color: "#009688"}} className="fas fa-big fa-wallet"/> 
                        {os.clienteFaturamento?.autoComplete}
                    </div>
                    <div>                    
                        <i  className="fas fa-big"/>
                        <i title="Cliente da Ordem de Serviço" style={{color: "#673AB7"}} className="fas fa-big fa-truck"/>
                        {os.cliente?.autoComplete}
                    </div>
                </div>
            );
        }


    };

    
    return (
        <div>  
            <Panel header="Selecionar Ordens de Serviço para Faturamento">
                <PanelContent>
                    <SelectButton options={optionsFilterStatus} col={3} name="status" value={params.status} onChange={handleChange} label="Status"/>
                    <InputNumber col={2} label="Número" name="numero" value={params.numero} onChange={handleChange}/>
                    <Dropdown col={2} options={optionsNumero} label="&nbsp;" name="modoNumero" value={params.modoNumero} onChange={handleChange}/>
                    <ClienteAutoComplete col={4} name="cliente" value={params.cliente} onChange={handleChange}/>
                    <ClienteAutoComplete label="Cliente de Faturamento" col={4} name="clienteFaturamento" value={params.clienteFaturamento} onChange={handleChange}/>
                    <VendedorAutoComplete disabled={!roles.FATE} col={4} name="vendedor" value={params.vendedor} onChange={handleChange}/>
                    <Dropdown 
                        col={2} 
                        options={optionsOperacao} 
                        itemTemplate={optionsOperacaoTemplate}
                        valueTemplate={optionsOperacaoSelecionadaTemplate}
                        label="Operação" 
                        name="operacao" 
                        value={params.operacao} 
                        onChange={handleChange} 
                    />
                </PanelContent>
                <PanelFooter>
                    <Button info disabled={loading} loading={loading === "procurar"} onClick={handleProcurar} label={isLargeDevice ? "Procurar" : null} icon="pi pi-search" autowidth={!isLargeDevice}/>
                    <Button secondary onClick={handleVoltar} label={isLargeDevice ? "Voltar" : null} icon="pi pi-arrow-left" autowidth={!isLargeDevice}/>
                </PanelFooter>
            </Panel>
            <DataTable value={faturamentos}
                       selectionMode="checkbox"
                       selection={selection}
                       rows={50}
                       onSelectionChange={e => setSelection(e.value)}
                       header={faturamentoSummary}
                       footer={faturamentoSummary}                       
            >
                <Column selectionMode="multiple" style={{textAlign: "center", width: "3em"}}/>
                <Column sortable style={{width: "8em", textAlign: "right"}} header="OS" field="numero"/>
                <Column  
                    sortable
                    style={{width: "10em", textAlign: "center"}} 
                    header="NF de Entrada" 
                    alignHeader={"center"} 
                    field="notaFiscal.numero" 
                    body={f => {
                        return f.notaFiscal?.numero || <i style={{color: "#F44336"}} className="fas fa-big fa-file-circle-question"
                        title="Nota fiscal de entrada não encontrada"/>;
                    }}
                />
                <Column sortable style={{width: "12em", textAlign: "right"}} header="Pedido Cliente" field="numeroPedidoCliente"/>
                <Column 
                    sortable 
                    style={{width: "8em", textAlign: "center"}} 
                    header="Contrato" 
                    field="contratoNumero"
                    body={bodyContrato}
                />

                <Column sortable header="Cliente" field="clienteFaturamento.autoComplete" body={bodyCliente}/>
                <Column sortable header="Início" field="inicio" style={{width: "10em", textAlign: "center"}} body={os => moment(os.inicio).format("DD/MM/YYYY")}/>
                <Column sortable style={{width: "8em"}} header="Forma de Faturamento" field="formaFaturamento" body={os => formaFaturamentoStatus[os.formaFaturamento]}/>
                <Column sortable style={{width: "12em", textAlign: "right"}} header="Valor Total" field="valorTotal" body={os => formatCurrency(os.valorTotal)}/>
            </DataTable>
        </div>
    );
}

const optionsFaturamento = [
	{label: "Retorno", value: "RETORNO" , icon: 'fa-truck', color: '#673AB7' },
	{label: "Faturamento", value: "FATURAMENTO", icon: 'fa-wallet', color: '#009688' }
];

function SelecionarTipoFaturamento(props) {

    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(0);
    const [faturamento, setFaturamento] = useState("FATURAMENTO");

    function handleClose() {
        setVisible(false);
    }

    async function handleSalvar() {
        setLoading("salvar");
        await props.onModalClose(faturamento);
        setLoading(0);
        handleClose();
    }

    const operacaoTemplate = (option, a, b) => {
        return (
            <strong className="align-items-center flex justify-content-center text-center w-full">
                <i style={{color: `${option.value === faturamento ? '#ffffff' : option.color}`}} className={`fas fa-big ${option.icon} mr-2`}/> 
                {option.label}
            </strong>
        );
    }

    return (
        <Dialog header="Selecione a Operação" style={{width: "350px"}} onHide={handleClose} visible={visible}>
            <PanelContent>
                {
                    props.tipo === 'LABORATORIO' ? 
                        <SelectButton 
                            col={12} 
                            label={null} 
                            options={optionsFaturamento} 
                            value={faturamento} 
                            onChange={e => {
                                if(e.value === null) return;
                                setFaturamento(e.value)
                            }} 
                            itemTemplate={operacaoTemplate}
                        />
                    : <div className="w-full flex align-items-center justify-content-center">
                        <Button
                            label={'Faturamento'}
                            icon="fa-solid fa-wallet"   
                            col={6}
                            className=""
                            style={{width: '50%'}}
                        />
                    </div>
                }

            </PanelContent>
            <DialogFooter>
                <Button loading={loading === "salvar"} disabled={loading === "salvar"} primary icon="fas fa-save" label="Confirmar" onClick={handleSalvar}/>
                <Button secondary icon="fas fa-timex" label="Fechar" onClick={handleClose}/>
            </DialogFooter>
        </Dialog>
    );

}
