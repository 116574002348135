import {Column} from 'primereact/column';
import React, {useState, useCallback} from 'react';
import {DataTable} from '../../../components/datatable';
import {withDialog} from "../../../utils/dialogContext";
import {ConfirmDialogV2} from "../../../components/confirmdialog";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment';
import { isStacked } from '../../../context/BreakpointContext';
import { InputText } from '../../../components/inputtext';
import { SimpleFileUpload } from '../../../components/fileupload/SimpleFileUpload';
import { arquivoService } from '../../../service/arquivoService';
import saveAs from "file-saver";
import { pedidoVendaService } from '../services/pedidoVendaService';
import { Button } from '../../../components/button';
import {isRequired} from '../../../utils/fieldValidator';

export const EditarPedidoVendaAnexo = withDialog(({pedidoVenda, setPedidoVenda,  showDialog, messages, setMessages}) => {

	const [blocked, setBlocked] = useState(0);
	const [anexosSelecionados, setAnexosSelecionados] = useState([]);

	const  validarItem = useCallback((e, index, data) =>  {
        let erro;
        let anexos = [];
        
		switch (e?.target.name) {
            case 'descricao':
                erro = isRequired(data.descricao)
                anexos = [...messages.anexos]
                anexos[index] = {...messages.anexos[index], descricao: erro}
                break;
            case 'arquivo':
				if(data.arquivo){
				}
                erro = isRequired(data.arquivo)
                anexos = [...messages.anexos]
                anexos[index] = {...messages.anexos[index], arquivo: erro}
                break;
            default:
                break;
        }

        setMessages((messages) => {
            return {
                ...messages,
                anexos
            }
        })
	}, [messages.anexos, setMessages])

	function adicionarAnexo() {
		setPedidoVenda({...pedidoVenda, anexos: [...pedidoVenda.anexos, pedidoVendaService.criarAnexo()]})
	}

	function removerAnexos() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja excluir os anexos selecionados?" onYes={() => {
			const anexos = pedidoVenda.anexos.filter(osi => !anexosSelecionados.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
			setPedidoVenda({...pedidoVenda, anexos})
			setAnexosSelecionados([]);
		}}/>);
	}

	const controlSet = (
		<div>
			<Button icon="pi pi-plus" onClick={adicionarAnexo}/>
			<Button disabled={!anexosSelecionados?.length} onClick={removerAnexos} danger icon="pi pi-minus"/>
		</div>
	);

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={5} header={controlSet}/>
			</Row>
			<Row>
				<Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
				<Column style={{width: "13em"}} header="Registro"/>
				<Column header="Descrição"/>
				<Column header="Arquivo"/>
				<Column header="Download" style={{width: "5em", textAlign: "center"}}/>
			</Row>
		</ColumnGroup>
	);

	const footerGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={5} footer={controlSet}/>
			</Row>
		</ColumnGroup>
	);

	function handleChangeAnexo(event) {

		const  anexos = pedidoVenda.anexos.map((anexo, index) => {
            if (event.index === index) {
                return {...anexo,  [event.name]: event.value};
            } else {
                return anexo;
            }
        });
        setPedidoVenda({...pedidoVenda, anexos})
	}

	function baixarComprovante(index) {
		setBlocked(index + 1);
		arquivoService.baixar(pedidoVenda.anexos[index].arquivo).then(data => {
			setBlocked(0);
			saveAs(new Blob([data], {type: pedidoVenda.anexos[index].arquivo.contentType}), pedidoVenda.anexos[index].arquivo.fileName);
		});
	}
    return (
        <>
			<div className="col-12">
				<DataTable value={pedidoVenda.anexos}
				           headerColumnGroup={headerGroup}
				           footerColumnGroup={footerGroup}
				           selection={anexosSelecionados}
				           onSelectionChange={e => setAnexosSelecionados(e.value)}
				           header={isStacked() ? null : controlSet}
				           footer={isStacked() ? null : controlSet}
				>
					<Column selectionMode="multiple"/>
					<Column field="registro" body={osic => <div style={{textAlign: "center"}}>{moment(osic.registro).format("DD/MM/YYYY HH:mm")}</div>}/>
					<Column 
						field="descricao" 
						body={(osic, c) => <InputText 
							index={c.rowIndex} 
							name="descricao" 
							value={osic.descricao} 
							onChange={handleChangeAnexo}
							onBlur={(e) => validarItem(e, c.rowIndex, osic)}
							invalid={messages.anexos?.[c.rowIndex]?.descricao}
							reduced={!messages.anexos?.[c.rowIndex]?.descricao}
						/>}
					/>
					<Column 
						body={(osic, c) => <SimpleFileUpload 
							disabled={osic.id} 
							index={c.rowIndex} 
							name="arquivo" 
							value={osic.arquivo} 
							onChange={handleChangeAnexo}
							hide={osic.id}
							invalid={messages.anexos?.[c.rowIndex]?.arquivo}
							reduced={!messages.anexos?.[c.rowIndex]?.arquivo}
						/>}
					/>
					<Column style={{textAlign: "center"}} body={(osic, c) => {
							return osic.id ? <Button loading={blocked === c.rowIndex + 1} icon="fa-solid fa-download" onClick={() => baixarComprovante(c.rowIndex)}/> : null
						}} 
					/>
				</DataTable>
			</div>
        </>
    );

});
