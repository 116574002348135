import React, { useRef } from 'react';
import './styles.scss'

export function SeletorDeFoto({uploader, value, ...props}) {

    const handleLoadLogotipo = (e) => {
		refInput.current.click();
	}

	const refInput = useRef();

    const newLocal = props.required ? <small style={{color: props.value?.length ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : <small>&nbsp;</small>;

    return (
        <div className="col-3 grid-nogutter">
            <div className={`tzm-logotipo-container`}>
                <img 
                    onClick={handleLoadLogotipo} 
                    alt="" 
                    className={`tzm-logotipo ${props.invalid ? 'tzm-logotipo-invalid' : ''}`}
                    src={value} 
                />
                <input 
                    accept="image/jpeg" 
                    style={{display: "none"}} 
                    ref={refInput} 
                    type="file" 
                    files={value} 
                    value="" 
                    onChange={uploader} 
                />
                {props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : newLocal}
            </div>
        </div>
    ) 
}