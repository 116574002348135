import React, {useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {Button} from "../../components/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

export function SelecionarEquipamento({equipamentos, onModalClose}) {

	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(true);

	function handleClose() {
		setVisible(false);
	}

	async function handleSelecionar(event) {
		if (onModalClose) {
			setLoading(event.index);
			await onModalClose(event.data);
			setLoading(false);
		}
		handleClose();
	}

	return (
		<Dialog visible={visible} style={{width: "700px"}} onHide={handleClose} header="Selecione o Equipamento">
			<PanelContent>
				<div className="col-12">
					<DataTable value={equipamentos} onRowClick={handleSelecionar} style={{cursor: "pointer"}}>
						<Column style={{width: "3em", textAlign: "center"}} body={(e, c) => <i className={loading === c.rowIndex ? "pi pi-spin pi-spinner" : "fas fa-arrow-right"}/>}/>
						<Column header="Equipamento" field="autoComplete"/>
					</DataTable>
				</div>
			</PanelContent>
			<DialogFooter>
				<Button icon="fas fa-times" label="Cancelar" onClick={handleClose}/>
			</DialogFooter>
		</Dialog>
	);

}
