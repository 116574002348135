import React, { useCallback, useMemo, useState } from "react";

import moment from "moment";
import { debounce } from 'lodash';
import { Row } from "primereact/row";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import { BlockUI } from "primereact/blockui";
import { TabPanel } from "primereact/tabview";
import { FilterMatchMode } from "primereact/api";
import { ColumnGroup } from "primereact/columngroup";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "../../../components/button";
import { Dialog } from "../../../components/dialog";
import { FormTab } from "../../../components/formtab";
import { PanelContent } from "../../../components/panel";
import { DataTable } from "../../../components/datatable";
import { InputText } from "../../../components/inputtext";
import { InputCurrency } from "../../../components/inputnumber";
import { listaPrecoService } from "../services/listaPrecoService";
import { ConfirmDialogV2 } from "../../../components/confirmdialog";
import { InformationDialog } from "../../../components/dialog/InformationDialog";
import { InputTextAreaFull } from "../../../components/inputtextarea/InputTextAreaFull";
import { ProdutoAutoComplete } from "../../../components/autocomplete/produtoAutoComplete";
import { withDialog } from "../../../utils/dialogContext";
import {buildValidator} from '../../../utils/fieldValidator';
import { formatCurrency, formatPercentage } from "../../../utils/numberFormatter";

import './listaPreco.scss'

const { v4: uuidv4 } = require('uuid');

export const AtualizacaoEmLote = withDialog(({showDialog}) => {
    const location = useLocation()
	const [messages, setMessages] = useState({...buildValidator(), itens: []});

    const navigate = useNavigate()
    const [listaPreco, setListaPreco] = useState({
        ...location?.state?.listaPreco, 
        itens: location?.state?.listaPreco?.itens?.map(item => {
            if(item?.produto?.id){
                return item
            }else{
                return {
                    ...item,
                    id: uuidv4(),
                    produto: {
                        codigo: null,
                        descricao: '',
                        id: null,
                        autoComplete: ''
                    },
                }
            }
        })
    });

    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: ['ALTERADO', 'NOVO', 'SEM_ALTERACAO', 'ERRO'], matchMode: FilterMatchMode.IN},
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(false);
    
    const [modalObservacao, setModalObservacao] = useState(false);
    const [modalData, setModalData] = useState(null);
    
    const debouncedGlobalFilterChange = useCallback(
        debounce((value) => {
            const _filters = { ...filters };
            _filters['global'].value = value;
            setFilters(_filters);
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 200); // Atraso de 200 milissegundos
        }, 500), // Debounce de 500 milissegundos
        [filters]
    );
    const debouncedStatusFilterChange = useCallback(
        debounce((value) => {
            const _filters = { ...filters };
            _filters['status'].value = value;
            setFilters(_filters);
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 200); // Atraso de 200 milissegundos
        }, 500), // Debounce de 500 milissegundos
        [filters]
    );

    const handleExluirItem = useCallback((id) => {
        const itens = listaPreco.itens.filter(lpi => lpi.id !== id);
        setListaPreco({ ...listaPreco, itens: itens });
    }, [listaPreco])

    const handleConfirmarExcluirItem = useCallback((id) => {
        return showDialog(<ConfirmDialogV2 
			message="Tem certeza que você deseja excluir este item?"
			onYes={() => handleExluirItem(id)}
		/>);
    }, [handleExluirItem, showDialog])

    const handleChangeItem = useCallback((rowData, event) => {
        listaPreco.itens.filter(lpi => (lpi.id && lpi.id === rowData.id)).forEach(lpi => {
            lpi[event.name] = event.value;
            // lpi.ultimaAtualizacao = moment().format('YYYY-MM-DDTHH:mm:ss'); 
            lpi.alterado = true;  

            const idIgual = listaPreco.itens.filter(item => {
                return (item?.produto?.id === lpi?.produto?.id && lpi?.produto?.id)
            });
            
            if(idIgual?.length > 1)  {

                lpi[event.name] = null;

                return showDialog(<InformationDialog
                    header={'Atenção'}	
                    message={'Ja existe um item com este produto.'}	
                />)
            }
            if(lpi.produto?.id && lpi.preco && lpi.novoPreco && lpi.status === 'ERRO')  {
                lpi.status = 'ALTERADO'
            }     
        });
        setListaPreco({ ...listaPreco });
    }, [listaPreco, showDialog]);

    const body = useMemo(() => (rowData, props) => {
        switch (props.field) {
            case 'preco':
                return <InputCurrency
                    value={rowData.preco}
                    min={0}
                    name="preco"
                    reduced
                    onChange={(e) => handleChangeItem(rowData, e)}
                    readOnly={rowData.status !== 'ERRO'}
                />;
            case 'novoPreco':
                return <InputCurrency
                    value={rowData.novoPreco}
                    min={0}
                    name="novoPreco"
                    reduced
                    onChange={(e) => handleChangeItem(rowData, e)}
                />;
            case 'acoes':
                return (
                    <div 
                        className="w-full y-full flex justify-content-center align-content-center "
                    >
                        {rowData?.status === 'ERRO' && <div
                            onClick={() => handleConfirmarExcluirItem(rowData.id)}
                            title="Excluir linha"
                            className="cursor-pointer mr-3"
                        >
                            <i 
                                className="fa-solid fa-xmark fa-xl "
                                style={{color: 'red'}}
                            />
                        </div>}
                        <div
                            onClick={() =>{ 
                                setModalData(rowData)
                                setModalObservacao(true)
                            }}
                            title={`${rowData.observacao?.length > 0 ? 'Observação' : 'Sem Observações'}`}
                            className="cursor-pointer"
                        >
                            <i 
                                className={`fa-solid fa-comment${rowData.observacao?.length > 0 ? '' : '-slash'} fa-xl `}
                                style={{color: `${rowData.observacao?.length > 0 ? '#575454' : '#F44336'}`}}
                            />
                        </div>
                    </div>
                )
            case 'produto':
                return <ProdutoAutoComplete
                    col={12}
                    value={rowData.produto}
                    name="produto"
                    label={null}
                    reduced
                    onChange={(e) => handleChangeItem(rowData, e)}
                    //readOnly={rowData.status !== 'ERRO'}
                />;
            case 'ultimaAtualizacao':
                if(rowData.status === 'ERRO' || !rowData?.id || !rowData?.ultimaAtualizacao) return;
                const data = moment(rowData.ultimaAtualizacao).format("DD/MM/YYYY HH:mm");

                return (
                    <div className="w-full flex flex-column justify-content-center align-content-center">
                        <strong className="text-center">{data}</strong>
                        {rowData?.alteradoPor && <div className="text-sm font-italic text-center">{`Alterado por: ${rowData.alteradoPor}`}</div>}
                    </div>
                )
            case 'incluidoPor':
                if(rowData.status === 'ERRO' || !rowData?.id || !rowData?.registro) return;
                const registro = moment(rowData.registro).format("DD/MM/YYYY HH:mm");

                return (
                    <div className="w-full flex flex-column justify-content-center align-content-center">
                        <strong className="text-center">{registro}</strong>
                        {rowData.incluidoPor && <div className="text-sm font-italic text-center">{`Adicionado por: ${rowData.incluidoPor}`}</div>}
                    </div>
                )
            case 'fator':
                if(rowData.status === 'ERRO') return;
                let value;
                let color;
                let title;

                switch (rowData.fator) {
                    case 'PRIMEIRO_PRECO':
                        value =  "file-signature";
                        color = "#FF9800";
                        title = "Primeiro Preço";
                        break;
                    case 'AUMENTO':
                        value =  "arrow-circle-up";
                        color = "#2196F3";
                        title = `Aumento de ${formatCurrency(rowData.valorAjuste)} (${formatPercentage(rowData.percentualAjuste)}).`;    
                        break;
                    case 'REDUCAO':
                        value =  "arrow-circle-down";
                        color = "#F44336";
                        title = `Redução de ${formatCurrency(rowData.valorAjuste)} (${formatPercentage(rowData.percentualAjuste)}).`;
                        break;
                    default:
                        value =  "equals";
                        color = "#000000";
                        title = `Valor sem alteração`;
                        break;
                }                                  
                
                return (
                    <div className='ui-g' >
                        <div className='ui-g-12' style={{ textAlign: "center" }}>
                            <span key={1} className={`fa-solid fa-lg fa-${value}`} title={title} style={{ color }} />
                            &nbsp;
                            {rowData.listaVigente 
                                ? <span key={2} className={`fa-solid fa-lg fa-calendar-day`} title={"Lista Vigente"} style={{  color: '#000000' }}/>
                                : null
                            }
                        </div>
                    </div>
                )
                
            default:
                return null;
        }
    }, [handleChangeItem, handleConfirmarExcluirItem]);

    const handleSalvar = useCallback(() => {
        if(listaPreco.itens.find(item => item.status === 'ERRO')) {
            showDialog(<InformationDialog 
                header="Informação" 
                message={'Existem itens com erros. Por favor, corrija-os e tente novamente.'}
            />)
            return;
        }

        const itens = listaPreco.itens.filter(lpi => lpi.alterado || lpi.status === 'ALTERADO' || lpi.status === 'NOVO').map(item => {
            return {
                ...item,
                preco: item.novoPreco
            }
        })

        const messages = listaPrecoService.validar(listaPreco);		
        if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;
        }
		const produtosDuplicados = listaPrecoService.validarDuplicidade(listaPreco);
		if (produtosDuplicados && produtosDuplicados.size > 0) {
			const produtos = Array.from(produtosDuplicados).join(", ");
			showDialog(<InformationDialog header="Informação" message={"A lista contém os seguintes produtos duplicados. " + produtos}  />);
			return;
		}

        setLoading(true);

        toast.promise(
            listaPrecoService.salvar({...listaPreco, itens}).then(async () => {
				navigate(-1);
			}).finally(() => setLoading(false)),
            {
                "pending": `Salvando lista de preço. Aguarde...`,
                "success": `Lista de preço salva com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar lista de preço`;
                    }
                }
            }
        );
    }, [listaPreco, navigate, showDialog])

    const itemControlSet = useMemo(() => (
        <div>
            <Button 
                icon="pi pi-save" 
                style={{float: "right", width: "auto", minWidth: "100px"}} 
                label="Salvar" 
                success 
                // disabled={loading || !roles.LTPC} loading={loading} 
                onClick={handleSalvar}
            />
        </div>
    ), [handleSalvar]);

    const handleChange = useCallback((event) => {
        setListaPreco((prevListaPreco) => ({ ...prevListaPreco, [event.name]: event.value }));
    }, [setListaPreco]);

    const handleChangeModalData = useCallback((event) => {
        setModalData((prevListaPreco) => ({ ...prevListaPreco, [event.name]: event.value }));
    }, []);

    const onGlobalFilterChange = useCallback((e) => {
        const value = e.value;
        setGlobalFilterValue(value);
        debouncedGlobalFilterChange(value);
    }, [debouncedGlobalFilterChange]);

    const filtroTemplate = useCallback(() => {
        return (
            <InputText
            col={12}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Código ou descrição"
            reduced
            label='Filtro'
            />
        )
    }, [globalFilterValue, onGlobalFilterChange])

    const filtroCorTemplate = useCallback((options) => {
        return (
            <div 
                className="col-12 flex align-items-center"
            >
                <div 
                    onClick={() =>{
                        let novoFiltro;
                        if(filters.status.value.find(item => item === 'ALTERADO')){
                            novoFiltro = filters.status.value.filter(item => item !== 'ALTERADO')
                        }else{
                            novoFiltro = [...filters.status.value, 'ALTERADO']
                        }
                        debouncedStatusFilterChange(novoFiltro)
                    }}
                    className="flex align-items-center cursor-pointer mr-3"
                >
                    <div 
                        className={`w-2rem h-2rem border-circle border-solid border-1 border-gray-900 mr-2 ${filters.status.value.find(item => item === 'ALTERADO') ?'background-gradient azul' : 'bg-white'}`}>

                    </div>
                    <h3 className="m-0 font-bold text-lg">  
                        Alterados
                    </h3>
                </div>
                <div 
                    onClick={() =>{
                        let novoFiltro;
                        if(filters.status.value.find(item => item === 'NOVO')){
                            novoFiltro = filters.status.value.filter(item => item !== 'NOVO')
                        }else{
                            novoFiltro = [...filters.status.value, 'NOVO']
                        }
                        debouncedStatusFilterChange(novoFiltro)
                    }}
                    className="flex align-items-center cursor-pointer mr-3"
                >
                    <div 
                        className={`w-2rem h-2rem border-circle border-solid border-1 border-gray-900 mr-2 ${filters.status.value.find(item => item === 'NOVO') ?'background-gradient verde' : 'bg-white'}`}>

                    </div>
                    <h3 className="m-0 font-bold text-lg">  
                        Novos
                    </h3>
                </div>
                <div 
                    onClick={() =>{
                        let novoFiltro;
                        if(filters.status.value.find(item => item === 'SEM_ALTERACAO')){
                            novoFiltro = filters.status.value.filter(item => item !== 'SEM_ALTERACAO')
                        }else{
                            novoFiltro = [...filters.status.value, 'SEM_ALTERACAO']
                        }
                        debouncedStatusFilterChange(novoFiltro)
                    }}
                    className="flex align-items-center cursor-pointer mr-3"
                >
                    <div 
                        className={`w-2rem h-2rem border-circle border-solid border-1 border-gray-900 mr-2 ${filters.status.value.find(item => item === 'SEM_ALTERACAO') ?'background-gradient amarelo' : 'bg-white'}`}>

                    </div>
                    <h3 className="m-0 font-bold text-lg">  
                        Sem Alteração
                    </h3>
                </div>
                <div 
                    onClick={() =>{
                        let novoFiltro;
                        if(filters.status.value.find(item => item === 'ERRO')){
                            novoFiltro = filters.status.value.filter(item => item !== 'ERRO')
                        }else{
                            novoFiltro = [...filters.status.value, 'ERRO']
                        }
                        debouncedStatusFilterChange(novoFiltro)
                    }}
                    className="flex align-items-center cursor-pointer"
                >
                    <div 
                        className={`w-2rem h-2rem border-circle border-solid border-1 border-gray-900 mr-2 ${filters.status.value.find(item => item === 'ERRO') ?'background-gradient vermelho' : 'bg-white'}`}>

                    </div>
                    <h3 className="m-0 font-bold text-lg">  
                        Erro
                    </h3>
                </div>
            </div>
        )
    }, [debouncedStatusFilterChange, filters.status.value])

    let headerGroup = useMemo(() => (
        <ColumnGroup>
            <Row>
                <Column colSpan={listaPreco?.id ? 8 : 6} header={filtroTemplate} />
            </Row>
            <Row>
                <Column colSpan={listaPreco?.id ? 6 : 4} header={filtroCorTemplate} />
                <Column colSpan={2} header={itemControlSet} />
            </Row>
            <Row>
                <Column 
                    header="Linha CSV" 
                    style={{ width: '4em' }} 
                />
                <Column header="Produto" name="produto" />
                <Column sortable header="Preço" field="preco" />
                <Column sortable header="Fator" field="fator" />
                <Column sortable header="Novo Preço" field="preco" />
                {listaPreco?.id && <Column sortable header="Última Atualização" field="ultimaAtualizacao" />}
                {listaPreco?.id && <Column sortable header="Adicionado em" field="incluidoPor" />}
                <Column header='Ações' headerStyle={{ width: "10em" }} />
            </Row>
        </ColumnGroup>
    ), [filtroCorTemplate, filtroTemplate, itemControlSet, listaPreco.id]);

    function ordemServicoRowClassName(comissaoAcompanhamento) {
        const verde = comissaoAcompanhamento.status === "NOVO";
        const azul = comissaoAcompanhamento.status === "ALTERADO";
        const amarelo = comissaoAcompanhamento.status === "SEM_ALTERACAO";
        const vermelho = comissaoAcompanhamento.status === "ERRO";
        return {
            "tabela-os": true,
            amarelo,
            verde,
            azul,
            vermelho
        };
    }

    return(
        <BlockUI blocked={loading}>
            <FormTab
                descricao='Atualização em Lote'
                podeDeletar={false}
                podeSalvar={false}
                // service={li}
                value={listaPreco}
                salvar={null}
                semHistorico
            >
                <TabPanel header="Dados Principais" leftIcon='fa-solid fa-database'> 
                    <PanelContent>
                        
                        <InputText
                            col={12}
                            name="descricao"
                            label="Descrição"
                            value={listaPreco.descricao}
                            onChange={handleChange}
                            readOnly={listaPreco.id}
                        />

                        <div className='col-12 pt-0'>
                            <DataTable
                                emptyMessage='Nenhum item adicionado.'
                                paginator={true}
                                rows={50}
                                dataKey={"id"}
                                filters={filters}
                                loading={loading}
                                globalFilterFields={['preco', 'produto.descricao', 'produto.codigo', 'produto.autoComplete']}
                                headerColumnGroup={headerGroup}
                                selectionMode="checkbox"
                                selection={itensSelecionados}
                                onSelectionChange={e => setItensSelecionados(e.value)}
                                value={listaPreco.itens}
                                valuelength={listaPreco.itens?.length}
                                rowClassName={ordemServicoRowClassName}
                            >
                                <Column 
                                    header="Linha CSV" 
                                    style={{ width: '4em' , textAlign: 'center' }} 
                                    field="linha" 
                                    name="linha"
                                />
                                <Column 
                                    header="Produto" 
                                    style={{ width: '*' }} 
                                    field="produto" 
                                    name="produto" 
                                    body={body} 
                                />
                                <Column 
                                    header="Preço" 
                                    style={{ width: '12em' }} 
                                    field="preco" 
                                    name="preco" 
                                    body={body}
                                />
                                <Column 
                                    style={{ width: '4em'}} 
                                    field="fator" 
                                    name="fator" 
                                    body={body}
                                />
                                <Column 
                                    header="Novo Preço" 
                                    style={{ width: '12em' }} 
                                    field="novoPreco" 
                                    name="novoPreco" 
                                    body={body} 
                                />
                                {listaPreco?.id && <Column 
                                    header="Ultima Atualização"  
                                    style={{ width: '12em'}} 
                                    field="ultimaAtualizacao" 
                                    name="ultimaAtualizacao"  
                                    body={body}
                                />}
                                {listaPreco?.id && <Column 
                                    header="Adicionado em"  
                                    style={{ width: '12em'}} 
                                    field="incluidoPor" 
                                    name="incluidoPor"  
                                    body={body}
                                />}
                                <Column 
                                    name='acoes'
                                    field='acoes'
                                    body={body}
                                />

                            </DataTable>
                            <Dialog
                                onHide={() => setModalObservacao(false)}
                                visible={modalObservacao}
                                style={{ width: '50vw' }}
                                header='Observações'
                                footer='information'
                                closeLabel='Fechar'
                            >
                                <InputTextAreaFull
                                    col={12}
                                    value={modalData?.observacao}
                                    name="observacao"
                                    onChange={(e) => {
                                        handleChangeModalData(e)        
                                        handleChangeItem(modalData, e)
                                    }}
                                    label={null}
                                    reduced
                                    maxLength={255}
                                />
                            </Dialog>    
                        </div>

                    </PanelContent>
                </TabPanel>
            </FormTab>
        </BlockUI>
    )
});