import React from 'react';
import preval from "preval.macro";

const BUILD_VERSION = preval`
	const moment = require('moment');
	module.exports = moment().format('YYMMDD-HHmm');
`;

export function AppFooter() {

    return (
        <div className="layout-footer">
            <img src={'assets/layout/images/tzm.svg'} alt="Logo" height="20" className="mr-2" />
            <span className="font-medium ml-2">TZM Services 1.0.{BUILD_VERSION} - Zecode</span>
        </div>
    );

}
