import React, {useMemo} from 'react';
import {TabelaProduto} from './TabelaProduto';
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Panel} from "primereact/panel";
import {BlockUI} from "primereact/blockui";
import {useQueryClientProduto} from "../../utils/query-clients/queryClients";
import {SearchButton} from "../../components/button/SearchButton";
import {FiltrosProduto} from "./FiltrosProduto";
import {ToggleViewHidden} from "../../components/button/ViewHiddenButton";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";

export function Produto() {

    const {query, handleSearch} = useQueryClientProduto();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.Produto);

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel headerTemplate={() => headerTemplate("Produto", "fa-box")}>
                <PanelContent>
                    <FiltrosProduto/>
                </PanelContent>
                <PanelFooter>
                    <div style={{float: "left"}}>
                        <ToggleViewHidden params={params} onChange={handleChange}/>
                    </div>
                    <SearchButton query={query} onSearch={handleSearch}/>
                </PanelFooter>
            </Panel>
            {useMemo(() => <TabelaProduto value={query.data || []}/>, [query.data])}
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
