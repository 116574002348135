import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Panel } from "primereact/panel";
import { Row } from "primereact/row";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProdutoAutoComplete } from "../../../components/autocomplete/produtoAutoComplete";
import { Button } from "../../../components/button";
import { Calendar } from "../../../components/calendar";
import { ConfirmDialogV2 } from "../../../components/confirmdialog";
import { DataTable } from "../../../components/datatable";
import { InputMask } from "../../../components/inputmask";
import { InputCurrency, InputNumber } from "../../../components/inputnumber";
import { InputText } from "../../../components/inputtext";
import { PanelContent, PanelFooter } from "../../../components/panel";
import { equipamentoService } from "../../../service/equipamentoService";
import { ordemServicoNotaFiscalService } from "../../../service/ordemServicoNotaFiscalService";
import { ordemServicoService } from "../../../service/ordemServicoService";
import {
  isBiggerThanZero,
  isEmpty,
  isEntityRequired,
  isRequired,
} from "../../../utils/fieldValidator";
import { ClienteAutoComplete } from "../../../components/autocomplete/ClienteAutoComplete";
import { useAuth } from "../../../context/AuthContext";
import { Dropdown } from "../../../components/dropdown";
import { ordemServicoFluxoService } from "../../../service/ordemServicoFluxoService";
import { contratoService } from "../../../service/contratoService";
import { useBreakpoint } from "../../../context/BreakpointContext";
import { ordemServicoItemService } from "../../../service/ordemServicoItemService";
import { InformationDialog } from "../../../components/dialog/InformationDialog";
import { ProgressDialog } from "../../../components/progressdialog/ProgressDialog";
import { DialogContext } from "../../../utils/dialogContext";
import { PrateleiraAutoComplete } from "../../../components/autocomplete/PrateleiraAutoComplete";
import moment from "moment";
import { condicaoPagamentoService } from "../../../service/condicaoPagamentoService";
import { DialogsOsTransferidas } from "./DialogOsTransferidas";
import { BlockUI } from "primereact/blockui";

export function BackOfficeRecepcaoNotaFiscal(props) {
  const navigate = useNavigate();
  const { showDialog } = useContext(DialogContext);
  const { usuario } = useAuth();
  const { breakpoint } = useBreakpoint();
  const isLargeDevice = breakpoint === "lg" || breakpoint === "md";
  const [osExistentes, setOsExistentes] = useState([]);

  const [params, setParams] = useState({
    cliente: null,
    tipo: "CORRETIVA",
    fluxo: null,
    etapa: null,
    etapas: [],
    inicio: moment().format("YYYY-MM-DDTHH:mm:ss"),
    responsavel: usuario,
    prateleira: usuario.bancadaTrabalho,
  });

  const [loading, setLoading] = useState(false);

  const [messages, setMessages] = useState({});
  const [messagesParams, setMessagesParams] = useState({});
  const [messagesEquipamentos, setMessagesEquipamentos] = useState([]);
  const [equipamentos, setEquipamentos] = useState([]);
  const [optionsContratos, setOptionsContratos] = useState([]);
  const [equipamentosSelecionados, setEquipamentosSelecionados] = useState([]);
  const [notaFiscal, setNotaFiscal] = useState(
    ordemServicoNotaFiscalService.criar()
  );

  function handleVoltar() {
    navigate(-1);
  }

  function handleChange(event) {
    setNotaFiscal({ ...notaFiscal, [event.name]: event.value });
  }

  function adicionarEquipamento() {
    setEquipamentos([...equipamentos, equipamentoService.criar()]);
  }

  function removerEquipamentos() {
    showDialog(
      <ConfirmDialogV2
        message="Tem certeza de que deseja remover os equipamentos selecionados?"
        onYes={() => {
          setEquipamentos([
            ...equipamentos.filter(
              (e) =>
                !equipamentosSelecionados.some(
                  (x) =>
                    (x._key && x._key === e._key) || (x.id && x.id === e.id)
                )
            ),
          ]);
          setEquipamentosSelecionados([]);
        }}
      />
    );
  }

  function handleChangeEquipamento(event) {
    equipamentos[event.index][event.name] = event.value;
    if (event.name === "produto") {
      equipamentos[event.index]._alterado = true;
    }
    setEquipamentos([...equipamentos]);
  }

  async function gerarOrdensServico(ordensDeServicoExistentes) {
    const messages = ordemServicoNotaFiscalService.validar(notaFiscal);
    const messagesParams = ordemServicoNotaFiscalService.validarParams(params);
    const messagesEquipamentos = equipamentos.map((e) => {
      const m = {
        produto: isEntityRequired(e.produto),
        _valor: isBiggerThanZero(e._valor),
      };
      m.isEmpty = () => isEmpty(m);
      return m;
    });
    setMessages(messages);
    setMessagesParams(messagesParams);
    setMessagesEquipamentos(messagesEquipamentos);
    if (!equipamentos.length) {
      showDialog(
        <InformationDialog
          header="Informação"
          message="Você deve preencher ao menos um equipamento para abrir as ordens de serviço."
        />
      );
      return;
    }
    if (
      !messages.isEmpty() ||
      !messagesParams.isEmpty() ||
      messagesEquipamentos.some((me) => !me.isEmpty())
    ) {
      showDialog(
        <InformationDialog
          header="Informação"
          message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."
        />
      );
      return;
    }
    if (equipamentos.length) {
      notaFiscal.cliente = params.cliente;
      notaFiscal.itens = [];
      showDialog(
        <ProgressDialog
          onProgress={async (setProgresso) => {
            const ordensServico = [];
            let progresso = 0,
              total = equipamentos.length * 4 + 1;
            for (let equipamento of equipamentos) {
              const osSuporte = ordensDeServicoExistentes
                .filter((os) => os.equipamentoId === equipamento.id)
                .map((os) => os.os);

              await ordemServicoService.buscarOrdensServicoSuporte(
                params.cliente?.id,
                equipamento.id
              );
              if (osSuporte.length) {
                await ordemServicoService.encaminharParaLaboratorio(osSuporte);
                setProgresso(++progresso / total);
                const ordemServico = await ordemServicoService.buscar(
                  osSuporte
                );
                setProgresso(++progresso / total);
                ordensServico.push(ordemServico);
                setProgresso(++progresso / total);
                await ordemServicoService.registrarMovimentacao(usuario, {
                  numero: ordemServico.numero,
                  prateleira: params.prateleira?.id,
                });
                setProgresso(++progresso / total);
                continue;
              }
              let contratos = await contratoService.listarContratosCobertura({
                cliente: params.cliente.id,
                produto: equipamento.produto.id,
                serial: equipamento.serial,
              });
              contratos = contratos.filter((c) =>
                moment().isBetween(c.inicio, c.fim)
              );
              let contrato = null;
              if (contratos.length === 1) {
                contrato = contratos[0];
              }
              const ordemServico = await ordemServicoService.salvar({
                ...ordemServicoService.criar(),
                status: "RECEPCAO",
                notaFiscal: null,
                numeroChamado: equipamento._numeroChamado,
                ...params,
                contrato,
                clienteFaturamento: params.cliente,
                acompanhamentos: [
                  {
                    ...ordemServicoService.criarAcompanhamento(),
                    etapa: params.etapa,
                    observacoes: "Ordem de serviço iniciada",
                    responsavel: params.responsavel,
                    operacao: "LABORATORIO"
                  },
                ],
                fluxo: `${params.fluxo.descricao} (v${params.fluxo.versao})`,
                postBack: true,
              });
              ordensServico.push(ordemServico);
              setProgresso(++progresso / total);
              const valorNotaFiscal = equipamento._valor;
              if (!equipamento.id || equipamento._alterado) {
                if (
                  !equipamento.serial?.length &&
                  equipamento.numeroAtivo?.length
                ) {
                  equipamento.serial = `PAT-${equipamento.numeroAtivo}`;
                }
                if (!equipamento.serial?.length) {
                  equipamento.serial = `OS-${ordemServico.numero}`;
                }
                equipamento = await equipamentoService.salvar({
                  ...equipamento,
                  postBack: true,
                });
              }
              notaFiscal.itens.push({ equipamento, valor: valorNotaFiscal });
              setProgresso(++progresso / total);
              await ordemServicoItemService.salvar({
                ordemServico,
                sequencia: 1,
                equipamento,
                valorNotaFiscal,
              });
              setProgresso(++progresso / total);
              await ordemServicoService.registrarMovimentacao(usuario, {
                numero: ordemServico.numero,
                prateleira: params.prateleira?.id,
              });
              setProgresso(++progresso / total);
            }
            await ordemServicoNotaFiscalService
              .salvar({ ...notaFiscal, postBack: true })
              .then(async (notaFiscal) => {
                await ordemServicoNotaFiscalService.vincularNotaFiscal(
                  notaFiscal.id,
                  ordensServico.map((os) => os.id)
                );
              });
            setProgresso(++progresso / total);
            handleVoltar();
          }}
        />
      );
    } else {
      showDialog(
        <InformationDialog
          header="Informação"
          message="Você precisa adicionar ao menos um equipamento para a abertura da ordem de serviço."
        />
      );
    }
  }

  async function buscarEquipamento(index) {
    setLoading(true);
    equipamentoService
      .findBySerial(equipamentos[index].serial)
      .then(async (equipamento) => {
        if (equipamento) {
          const os = await ordemServicoService.buscarOrdensServicoSuporte(
            params.cliente?.id,
            equipamento.id
          );

          let adicionarEquipamento = { ...equipamento };

          if (os[0]?.id) {
            if (
              !osExistentes.find(
                (item) => item.equipamento.id === equipamento.id
              )
            ) {
              setOsExistentes((oss) => [
                ...oss,
                { equipamento, ordensDeServico: [...os] },
              ]);
            }

            adicionarEquipamento = {
              ...adicionarEquipamento,
              operacao: os[0].operacao,
            };
          }

          equipamentos[index] = {
            ...equipamentos[index],
            ...adicionarEquipamento,
            _alterado: true,
          };
          setEquipamentos([...equipamentos]);
        } else {
          equipamentos[index]._alterado = true;
          setEquipamentos([...equipamentos]);
        }
      })
      .finally(() => setLoading(false));
  }

  async function handleChangeParams(event) {
    switch (event.name) {
      case "fluxo":
        if (event.value?.id) {
          setParams((prevParams) => ({
            ...prevParams,
            fluxo: event.value,
            etapas: event.value.etapas,
            etapa: event.value.etapas[0],
          }));
        }
        break;
      case "cliente":
        if (event.value?.id) {
          const contratos = await contratoService.listarContratosAtivos(
            event.value.id
          );
          if (contratos.length === 1) {
            setParams((prevParams) => ({
              ...prevParams,
              contrato: contratos[0],
            }));
          }
          setOptionsContratos(
            contratos.map((c) => ({ label: c.autoComplete, value: c }))
          );
        }
        setParams((prevParams) => ({ ...prevParams, cliente: event.value }));
        break;
      default:
        setParams((prevParams) => ({
          ...prevParams,
          [event.name]: event.value,
        }));
        break;
    }
  }

  useEffect(() => {
    ordemServicoFluxoService.buscarFluxoLaboratorio().then(async (fluxo) => {
      await handleChangeParams({ name: "fluxo", value: fluxo });
    });
  }, []);

  const equipamentoControlSet = (
    <div style={{ whiteSpace: "nowrap" }}>
      <Button
        icon="pi pi-plus"
        onClick={adicionarEquipamento}
        disabled={!params.cliente?.id}
        title={`${
          params.cliente?.id
            ? "Adicioanr equipamento"
            : "Selecione um cliente antes de adicionar um equipamento"
        }`}
      />
      <Button
        disabled={!equipamentosSelecionados?.length}
        onClick={removerEquipamentos}
        danger
        icon="pi pi-minus"
      />
    </div>
  );

  function selectProdutoPadrao(event) {
    if (event.value.id) {
      for (const equipamento of equipamentos) {
        equipamento.produto = event.value;
        equipamento._alterado = true;
      }
      setEquipamentos([...equipamentos]);
    }
    setParams((prevParams) => ({ ...prevParams, _produto: null }));
  }

  function changeValorPadrao(event) {
    for (const equipamento of equipamentos) {
      equipamento._valor = event.value;
      equipamento._alterado = true;
    }
    setEquipamentos([...equipamentos]);
  }

  function clearValorPadrao() {
    setParams((prevParams) => ({ ...prevParams, _valor: null }));
  }

  const equipamentoHeaderGroup = (
    <ColumnGroup>
      <Row>
        <Column colSpan={6} header={equipamentoControlSet} />
      </Row>
      <Row>
        <Column rowSpan={2} selectionMode="multiple" style={{ width: "3em" }} />
        <Column rowSpan={2} style={{ width: "20em" }} header="Nº do Chamado" />
        <Column rowSpan={2} style={{ width: "20em" }} header="Nº do Ativo" />
        <Column rowSpan={2} style={{ width: "25em" }} header="Nº de Série" />
        <Column header="Modelo" />
        <Column style={{ width: "12em" }} header="Valor" />
      </Row>
      <Row>
        <Column
          headerClassName="autocomplete-header"
          header={
            <ProdutoAutoComplete
              name="_produto"
              onSelect={selectProdutoPadrao}
              value={params._produto}
              onChange={handleChangeParams}
              col={12}
              label={null}
              reduced
            />
          }
        />
        <Column
          header={
            <InputCurrency
              onBlur={clearValorPadrao}
              name="_valor"
              value={params._valor}
              onChange={changeValorPadrao}
              col={12}
              label={null}
              reduced
            />
          }
        />
      </Row>
    </ColumnGroup>
  );

  const equipamentoFooterGroup = (
    <ColumnGroup>
      <Row>
        <Column colSpan={6} footer={equipamentoControlSet} />
      </Row>
    </ColumnGroup>
  );

  const serialTemplate = (e, c) => {
    return (
      <div className="grid grid-nogutter w-full align-items-center">
        {e.operacao ? (
          <div className="col-1">
            <i
              className={`text-2xl fa-solid ${
                e.operacao === "SUPORTE" ? "fa-headset" : "fa-road"
              }`}
            />
          </div>
        ) : null}

        <InputText
          col={11}
          index={c.rowIndex}
          name="serial"
          value={e.serial}
          onBlur={() => buscarEquipamento(c.rowIndex)}
          onChange={handleChangeEquipamento}
          reduced
        />
      </div>
    );
  };

  const handleGerarOS = () => {
    return showDialog(
      <DialogsOsTransferidas
        ordensDeServico={osExistentes}
        onConfirmar={gerarOrdensServico}
      />
    );
  };

  return (
    <Panel header="Ordens de Serviço por Nota Fiscal de Entrada">
      <BlockUI blocked={loading}>
        <TabView>
          <TabPanel header="Dados da Nota Fiscal">
            <PanelContent>
              <InputNumber
                invalid={messages.numero}
                required
                onBlur={() => {
                  messages.numero = isBiggerThanZero(
                    notaFiscal.numero,
                    "Campo obrigatório"
                  );
                  setMessages({ ...messages });
                }}
                col={3}
                label="Número"
                name="numero"
                value={notaFiscal.numero}
                onChange={handleChange}
              />
              <InputMask
                unmask
                mask="9999 9999 9999 9999 9999 9999 9999 9999 9999 9999 9999"
                invalid={messages.chave}
                required
                onBlur={() => {
                  messages.chave = isRequired(notaFiscal.chave);
                  setMessages({ ...messages });
                }}
                col={6}
                label="Chave"
                name="chave"
                value={notaFiscal.chave}
                onChange={handleChange}
              />
              <Calendar
                invalid={messages.data}
                required
                onBlur={() => {
                  messages.data = isRequired(notaFiscal.data);
                  setMessages({ ...messages });
                }}
                col={3}
                label="Data"
                name="data"
                value={notaFiscal.data}
                onChange={handleChange}
              />
              <InputText
                col={9}
                label="Descrição"
                name="descricao"
                value={notaFiscal.descricao}
                onChange={handleChange}
              />
              <InputCurrency
                invalid={messages.valor}
                required
                onBlur={() => {
                  messages.valor = isBiggerThanZero(notaFiscal.valor);
                  setMessages({ ...messages });
                }}
                col={3}
                label="Valor"
                name="valor"
                value={notaFiscal.valor}
                onChange={handleChange}
              />
              <InputText
                multiline
                style={{ height: "13em" }}
                col={12}
                label="Observações"
                name="observacoes"
                value={notaFiscal.observacoes}
                onChange={handleChange}
              />
            </PanelContent>
          </TabPanel>
          <TabPanel header="Dados para as Ordens de Serviço">
            <PanelContent>
              <ClienteAutoComplete
                required
                invalid={messagesParams.cliente}
                col={5}
                label="Cliente"
                name="cliente"
                value={params.cliente}
                onChange={handleChangeParams}
              />
              <Dropdown
                options={optionsContratos}
                col={3}
                name="contrato"
                value={params.contrato}
                onChange={handleChangeParams}
                label="Contrato"
              />
              <Calendar
                col={2}
                name="inicio"
                value={params.inicio}
                onChange={handleChangeParams}
                label="Data da Entrada"
                showTime
              />
              <PrateleiraAutoComplete
                label="Prateleira de Entrada"
                name="prateleira"
                value={params.prateleira}
                onChange={handleChangeParams}
                col={2}
              />
              <div className="col-12">
                <DataTable
                  header="Equipamentos"
                  value={equipamentos}
                  headerColumnGroup={equipamentoHeaderGroup}
                  footerColumnGroup={equipamentoFooterGroup}
                  footer={null}
                  selectionMode="checkbox"
                  selection={equipamentosSelecionados}
                  onSelectionChange={(e) =>
                    setEquipamentosSelecionados(e.value)
                  }
                >
                  <Column selectionMode="multiple" />
                  <Column
                    header="Modelo"
                    body={(e, c) => (
                      <InputText
                        label={null}
                        col={12}
                        value={e._numeroChamado}
                        name="_numeroChamado"
                        index={c.rowIndex}
                        onChange={handleChangeEquipamento}
                        reduced
                      />
                    )}
                  />
                  <Column
                    field="numeroAtivo"
                    style={{ width: "20em" }}
                    header="Nº do Ativo"
                    body={(e, c) => (
                      <InputText
                        index={c.rowIndex}
                        name="numeroAtivo"
                        value={e.numeroAtivo}
                        onChange={handleChangeEquipamento}
                        reduced
                      />
                    )}
                  />
                  <Column
                    field="serial"
                    style={{ width: "25em" }}
                    header="Nº de Série"
                    body={(e, c) => serialTemplate(e, c)}
                  />
                  <Column
                    header="Modelo"
                    body={(e, c) => (
                      <ProdutoAutoComplete
                        required
                        invalid={messagesEquipamentos?.[c.rowIndex]?.produto}
                        label={null}
                        col={12}
                        value={e.produto}
                        name="produto"
                        index={c.rowIndex}
                        onChange={handleChangeEquipamento}
                        reduced={e?.produto?.id}
                      />
                    )}
                  />
                  <Column
                    header="Valor"
                    style={{ width: "7em" }}
                    body={(e, c) => (
                      <InputCurrency
                        required
                        invalid={messagesEquipamentos?.[c.rowIndex]?._valor}
                        label={null}
                        col={12}
                        value={e._valor}
                        name="_valor"
                        index={c.rowIndex}
                        onChange={handleChangeEquipamento}
                        reduced={e._valor}
                      />
                    )}
                  />
                </DataTable>
              </div>
            </PanelContent>
          </TabPanel>
        </TabView>
        <PanelFooter>
          <Button
            autowidth={!isLargeDevice.toString()}
            icon="fa-solid fa-save"
            label={isLargeDevice ? "Gerar Ordens de Serviço" : null}
            success
            onClick={handleGerarOS}
          />
          <Button
            autowidth={!isLargeDevice.toString()}
            icon="fa-solid fa-arrow-left"
            label={isLargeDevice ? "Voltar" : null}
            secondary
            onClick={handleVoltar}
          />
        </PanelFooter>
      </BlockUI>
    </Panel>
  );
}
