import React, {useState} from 'react';
import {ConfirmDialog as PConfirmDialog} from 'primereact/confirmdialog';

export function ConfirmDialog({visible, onHide, accept, reject, message}) {

	return (
		<PConfirmDialog
			visible={visible}
			onHide={onHide}
			message={message}
			header="Confirmação"
			icon="pi pi-exclamation-triangle"
			accept={accept}
			reject={reject}
			acceptLabel="Sim"
			rejectLabel="Não"
			breakpoints={{"960px": "75vw", "640px": "100vw"}}
			rejectClassName="p-button-danger"
		/>
	);

}

export function ConfirmDialogV2({message, onYes, onNo, red}) {

	const [visible, setVisible] = useState(true);

	function handleHide() {
		setVisible(false);
	}

	return (
		<PConfirmDialog key={Math.random()}
		                message={message}
		                header="Confirmação"
		                acceptLabel="Sim"
		                rejectLabel="Não"
		                visible={visible}
		                accept={onYes}
		                reject={onNo}
		                icon="pi pi-exclamation-triangle"
		                breakpoints={{"960px": "75vw", "640px": "100vw"}}
		                acceptClassName="p-button-success"
		                rejectClassName="p-button-danger"
						className={red ? "red-confirmation-dialog" : ""}
		                onHide={handleHide}
		/>
	);

}
