import React, {useEffect, useState} from "react";
import { Dialog, DialogFooter } from "../../components/dialog";
import { PanelContent } from "../../components/panel";
import { Button } from "../../components/button";
import { InputText } from "../../components/inputtext";
import { Dropdown } from "../../components/dropdown";
import { SelectButton } from "../../components/selectbutton";
import moment from "moment/moment";
import { useAuth } from "../../context/AuthContext";
import {ordemServicoService} from "../../service/ordemServicoService";

export function DialogAprovacaoOrcamento({ordemServico, visible, setVisible, setOrdemServico, orcamentoIndex, itemIndex, contratoPeriodoConsumo}) {
    const {usuario} = useAuth();
    const [clientes, setClientes] = useState([]);
    const [localOrdemServico, setLocalOrdemServico] = useState(JSON.parse(JSON.stringify(ordemServico)));
    
    useEffect(() => {
        setLocalOrdemServico(JSON.parse(JSON.stringify(ordemServico)));
    }, [ordemServico])

    useEffect(() => {
        if(ordemServico?.clienteFaturamento?.id !== ordemServico?.cliente?.id){
            setClientes([ordemServico?.clienteFaturamento, ordemServico?.cliente])
        }else{
            setClientes([ordemServico?.cliente])
        }
    }, [ordemServico.cliente, ordemServico.clienteFaturamento])

    function handleClose() {
        setVisible(false);
    }

    function handleSalvar() {
        localOrdemServico.itens[itemIndex].orcamentos[orcamentoIndex].status = "APROVADO";
        localOrdemServico.itens[itemIndex].orcamentos[orcamentoIndex].dataConclusao = moment().format("YYYY-MM-DDTHH:mm:ss");
        localOrdemServico.itens[itemIndex].orcamentos[orcamentoIndex].concluidoPor = usuario;
        localOrdemServico.itens[itemIndex].orcamentos[orcamentoIndex].aprovadoOuRecusadoPor = usuario?.email;
        localOrdemServico.itens[itemIndex].orcamentos[orcamentoIndex].clienteFaturamento = localOrdemServico.clienteFaturamento.autoComplete;
        localOrdemServico.contratoPeriodoConsumo = contratoPeriodoConsumo;
        if (localOrdemServico.status === "LAUDO" && localOrdemServico.operacao === "LABORATORIO") {
            const etapa = localOrdemServico?.etapas?.find((etapa) => etapa.status === "MANUTENCAO");
            localOrdemServico.status = "MANUTENCAO";
            localOrdemServico.etapa = etapa;
            const acompanhamento = {
                ...ordemServicoService.criarAcompanhamento(),
                inicio: moment().format('YYYY-MM-DDTHH:mm:ss'),
                etapa: etapa,
                responsavel: null,
                atribuinte: {id: usuario.id},
                observacoes: "Orçamento aprovado.",
                ordemServico: {id: ordemServico.id}
            };
            localOrdemServico.responsavel = null;
            localOrdemServico.acompanhamentos.push(acompanhamento);
            localOrdemServico.ultimoAcompanhamento = acompanhamento;
            localOrdemServico.ultimaAlteracao = moment().format("YYYY-MM-DDTHH:mm:ss");
        }
        setOrdemServico({...localOrdemServico});
        handleClose();
    }

    const optionsFaturamento = [
        {tooltip: "teste", label: "Serviço", value: "SERVICO" , icon: "fa-wrench", color: '#E91E63' },
        {tooltip: "teste", label: "Produto", value: "PRODUTO", icon: "fa-boxes", color: '#2196F3' }
    ];

    const operacaoTemplate = (option, a, b) => {
        return (
            <strong className="align-items-center flex justify-content-center text-center w-full">
                <i 
                    style={{color: `${option.value === ordemServico.itens[itemIndex].orcamentos[orcamentoIndex].formaFaturamento ? '#ffffff' : option.color}`}} 
                    className={`fas fa-big ${option.icon} mr-2`}
                /> 
                {option.label}
            </strong>
        );
    }
    
    return (
        <Dialog visible={visible} onHide={handleClose} style={{width: "850px"}} header="Aprovar Orçamento">
            <PanelContent>
                {
                    clientes.length === 1 
                    ? 
                        <InputText
                            col={6}
                            label="Cliente Faturamento"
                            value={ordemServico.clienteFaturamento.razaoSocial}
                            readOnly
                        />                
                    :   <Dropdown
                            col={6}
                            label='Selecione qual cliente irá efetuar o pagamento'
                            name='clienteFaturamento'
                            dataKey="id" 
                            options={clientes} 
                            optionLabel="autoComplete"
                            value={localOrdemServico?.clienteFaturamento}
                            onChange={(e) => {
                                localOrdemServico.clienteFaturamento = e.value;
                                setLocalOrdemServico({...localOrdemServico});
                            }}
                        />
                }
                <SelectButton 
                    col={3} 
                    label={'Selecione a forma de faturamento'} 
                    options={optionsFaturamento}                     
                    value={localOrdemServico?.itens[itemIndex]?.orcamentos[orcamentoIndex]?.formaFaturamento} 
                    onChange={e => {
                        if(e.value === null) return;
                        localOrdemServico.itens[itemIndex].orcamentos[orcamentoIndex].formaFaturamento = e.value;
                        setLocalOrdemServico({...localOrdemServico});
                    }}
                    itemTemplate={operacaoTemplate}
                />
                <InputText
                    col={3}
                    label='Número da Ordem de Compra'
                    name='numeroPedidoCompra'
                    value={localOrdemServico?.numeroPedidoCompra}
                    onChange={(e) => {
                        localOrdemServico.numeroPedidoCompra = e.value
                        setLocalOrdemServico({...localOrdemServico})
                    }}
                />
            </PanelContent>
            <DialogFooter>                
                <Button success icon="fas fa-check" label="Aprovar" onClick={handleSalvar}/>
            </DialogFooter>
        </Dialog>
    );
}