import React from "react";
import {Dropdown} from "./index";
import {labelRestricao} from "../../service/ordemServicoService";

const optionsRestricoes = [
    {label: "Todas", value: ""},
    ...Object.keys(labelRestricao).map(r => ({label: labelRestricao[r], value: r}))
];

export function OrdemServicoRestricaoDropdown(props) {

    return <Dropdown {...props} options={optionsRestricoes} label="Restrição"/>;

}
