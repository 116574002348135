import React, {useMemo} from "react";
import {Panel} from "primereact/panel";
import {DataTable} from "../../../components/datatable";
import {Column} from "primereact/column";
import {useNavigate} from "react-router-dom";
import {useQueryClientQuestaoAvaliacao} from "../../../utils/query-clients/queryClients";
import {BlockUI} from "primereact/blockui";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {SearchButton} from "../../../components/button/SearchButton";
import {RefetchIndicator} from "../../../components/refetchindicator/RefetchIndicator";
import {headerTemplate} from "../../../utils/templates";
import {iconStatus} from "../../../service/avaliacaoService";
import {FiltrosOrdemServicoAvaliacaoQuestao} from "./FiltrosOrdemServicoAvaliacaoQuestao";

export function OrdemServicoAvaliacaoQuestoes() {
	const navigate = useNavigate();
	const {query, handleSearch} = useQueryClientQuestaoAvaliacao();
	function handleEdit(questao) {
		navigate(`/ordens-servico/avaliacoes/questoes/${questao.data.id}`);
	}
	const tabela = useMemo(() => (
		<DataTable
			value={query.data || []}
			onRowDoubleClick={handleEdit}
		>
			<Column field="status" header="Status" style={{width: "3.5rem", textAlign: "center"}} body={iconStatus}/>
			<Column field="questao" header="Questão"/>
		</DataTable>
	), [query.data]);
	return (
		<BlockUI blocked={query.isLoading}>
			<Panel headerTemplate={() => headerTemplate("Questões de Avaliação de Atendimento", "fa-question-circle")}>
				<PanelContent>
					<FiltrosOrdemServicoAvaliacaoQuestao/>
				</PanelContent>
				<PanelFooter>
					<SearchButton query={query} onSearch={handleSearch}/>
				</PanelFooter>
			</Panel>
			{tabela}
			<RefetchIndicator isRefetching={query.isRefetching}/>
		</BlockUI>
	);
}
