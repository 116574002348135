import React, {useEffect, useState} from 'react';
import {FormEdit} from "../../components/form/Edit";
import {useParams} from "react-router-dom";
import {InputText} from "../../components/inputtext";
import {buildValidator, isRequired} from "../../utils/fieldValidator";
import {useAuth} from '../../context/AuthContext';
import {tiposDeClausulaService} from '../../service/tiposDeClausula';

export function EditaClausulasTipos() {

	const {id} = useParams();
	const {roles} = useAuth();
	const [tipoDeClausula, setTipoDeClausula] = useState(tiposDeClausulaService.criar());
	const [messages, setMessages] = useState(buildValidator());

	function handleChange(event) {
		setTipoDeClausula({...tipoDeClausula, [event.name]: event.value});
	}

	useEffect(() => {
		if (id !== "new") {
			tiposDeClausulaService.buscar(id).then(setTipoDeClausula);
		}
	}, [id]);

	return (
		<FormEdit
			podeDeletar={!!roles.TDCD}
			podeSalvar={!!roles.TDCC}
			descricao="Tipo de Clausula"
			onValidate={setMessages}
			service={tiposDeClausulaService}
			value={tipoDeClausula}
			blocked={id !== "new" && !tipoDeClausula.id}
		>
			<InputText
				required
				onBlur={() => {
					messages.codigo = isRequired(tipoDeClausula.codigo);
					setMessages({...messages});
				}}
				invalid={messages.codigo}
				col={6}
				label="Código"
				name="codigo"
				value={tipoDeClausula.codigo}
				onChange={handleChange}
			/>
			<InputText
				required
				onBlur={() => {
					messages.descricao = isRequired(tipoDeClausula.descricao);
					setMessages({...messages});
				}}
				invalid={messages.descricao}
				col={6}
				label="Descrição"
				name="descricao"
				value={tipoDeClausula.descricao}
				onChange={handleChange}
			/>
		</FormEdit>
	);

}
