import React from "react";
import {Column} from "primereact/column";
import moment from "moment";
import {DataTable} from "../../components/datatable";

export function VisualizarMovimentosEstoque({movimentosEstoque}) {

    return (
        <DataTable value={movimentosEstoque}>
            <Column style={{width: "5em", textAlign: 'center'}} header="Número" field="numero" name="numero" />
            <Column style={{width: "13em"}} header="Data" alignHeader={"center"} field="data" body={osme => <div style={{textAlign: "center"}}>{osme.data && moment(osme.data).format("DD/MM/YYYY HH:mm")}</div>}/>
            <Column header="Produto" body={osme => osme.produto?.autoComplete}/>
            <Column style={{width: "20em", textAlign: "right"}} header="Estoque" alignHeader={"center"} body={osme => osme.estoque?.descricao}/>
            <Column style={{width: "10em", textAlign: "right"}} header="Quantidade" alignHeader={"center"} body={osme => osme.quantidade}/>
        </DataTable>
    );

}
