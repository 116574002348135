import React, {useEffect} from "react";
import {InputText} from "../../components/inputtext";
import {InputNumber} from "../../components/inputnumber";
import {Dropdown} from "../../components/dropdown";
import {OrdemServicoStatusMultiselect} from "../../components/multiselect/ordemServicoStatusMultiselect";
import {EtapaDropdown} from "../../components/autocomplete/EtapaDropdown";
import {Calendar} from "../../components/calendar";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {SelectButton} from "../../components/selectbutton";
import {OrcamentoStatusDropdown} from "../../components/dropdown/orcamentoStatusDropdown";
import {EquipamentoAutoComplete} from "../../components/autocomplete/equipamentoAutoComplete";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {
    filtrosNumeros,
    optionsFilterRestricoes,
    optionsFiltroEquipe, optionsStatusContrato,
    optionsTiposFiltered,
    restricoesButtons
} from "../../service/ordemServicoService";
import {CanceladaSelectButton} from "../../components/selectbutton/canceladaSelectButton";
import {optionsOperacao, optionsOperacaoSelecionadaTemplate, optionsOperacaoTemplate} from "../../utils/operacao";
import {VendedorAutoComplete} from "../../components/autocomplete/VendedorAutoComplete";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {useAuth} from "../../context/AuthContext";

export const optionsOperacaoComercial = [
    {label: "Todos", value: "TODOS", color: null, icon: "fas fa-asterisk"},
    ...optionsOperacao,
    {label: "Backoffice", value: "BACKOFFICE", color: "#9C27B0", icon: "fas fa-warehouse"},
    {label: "Backoffice + Laboratório", value: "LABORATORIO_E_BACKOFFICE", color: "#666666", icon: "fas fa-boxes"}
];

export const optionsFiltrosPecas = [
    {label: "Todos", value: null},
    {label: "Sim", value: "true"},
    {label: "Não", value: "false"}
];

export const filtrosData = [
    {label: "de Início", value: "INICIO"},
    {label: "de Previsão de Atendimento", value: "PREVISAO"},
    {label: "de Fim", value: "FIM"}
];

export const filtrosDataLaboratorio = [
    {label: "de Início", value: "INICIO"},
    {label: "de Fim", value: "FIM"}
];

export function FiltrosOrdemServicoComercial() {

    const {roles, usuario} = useAuth();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.Comercial);
    const optionsTipos = optionsTiposFiltered(roles);

    useEffect(() => {
        if (!roles.OSSL) {
            handleChange({name: "responsavel", value: usuario});
        }
    }, [roles, usuario]);

    return (
        <>
            {
                params.modoNumeros === 'CHAMADO'
                    ? <InputText label="Número" name="numeros" col={2} value={params.numeros} onChange={handleChange}/>
                    : <InputNumber label="Número" name="numeros" col={2} value={params.numeros} onChange={handleChange} />
            }
            <Dropdown col={2} options={filtrosNumeros} label="&nbsp;" name="modoNumeros" onChange={handleChange} value={params.modoNumeros}/>
            <Dropdown disabled={optionsTipos.length === 1} label="Tipo da OS" options={optionsTipos} name="tipo" col={2} value={params.tipo} onChange={handleChange}/>
            <OrdemServicoStatusMultiselect label="Status" name="status" col={6} value={params.status} onChange={handleChange}/>
            <Calendar disabled={params.equipamento?.id} label="Período" col={1} name="inicio" value={params.inicio} onChange={handleChange}/>
            <Calendar disabled={params.equipamento?.id} label="&nbsp;" col={1} name="fim" value={params.fim} onChange={handleChange}/>
            <Dropdown col={2} label="&nbsp;" name="modoData" options={filtrosData} onChange={handleChange} value={params.modoData}/>
            <ClienteAutoComplete forceSelection={false} name="cliente" col={4} value={params.cliente} onChange={handleChange}/>
            <SelectButton label="Contém Peças" col={2} name="pecas" value={params.pecas} onChange={handleChange} options={optionsFiltrosPecas}/>
            <OrcamentoStatusDropdown col={2} name="orcamentoStatus" value={params.orcamentoStatus} onChange={handleChange}/>
            <EquipamentoAutoComplete forceSelection={false} col={4} name="equipamento" value={params.equipamento} onChange={handleChange}/>
            <InputText label="Posição Física" name="prateleira" col={2} value={params.prateleira} onChange={handleChange}/>
            <UsuarioAutoComplete col={4} disabled={!roles.OSSL} value={params.responsavel} name="responsavel" onChange={handleChange} label="Responsável"/>
            <CanceladaSelectButton value={params.cancelada} onChange={handleChange}/>
            <SelectButton col={8} itemTemplate={restricoesButtons} label="Restrições" multiple options={optionsFilterRestricoes} name="restricoes" value={params.restricoes} onChange={handleChange}/>
            <Dropdown col={2} options={optionsOperacaoComercial} itemTemplate={optionsOperacaoTemplate} valueTemplate={optionsOperacaoSelecionadaTemplate} label="Operação" name="operacao" onChange={handleChange} value={params.operacao}/>
            <Dropdown col={2} label="Status Contrato" value={params.statusContrato} onChange={handleChange} name="statusContrato" options={optionsStatusContrato}/>
            <VendedorAutoComplete col={10} label="Consultor de Vendas" name="gerenteComercial" value={params.gerenteComercial} onChange={handleChange}/>
            <EtapaDropdown col={2} name="etapa" value={params.etapa} onChange={handleChange}/>
        </>
    );

}
