import React from "react";
import {Dropdown} from "./index";


export function TipoContratoDropdown(props) {


	return (
		<Dropdown 
            label="Tipo de Contrato" 
            options={props.tiposContrato} 
            optionLabel='autoComplete' 
            {...props}
        />
	);

}
