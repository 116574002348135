import React, {useContext, useEffect, useState} from "react";
import {Dialog, DialogFooter} from "../../../components/dialog";
import {PanelContent} from "../../../components/panel";
import {Button} from "../../../components/button";
import {DataTable} from "../../../components/datatable";
import moment from "moment";
import {Column} from "primereact/column";
import {DialogContext} from "../../../utils/dialogContext";
import {EditarPendencia} from "./EditarPendencia";
import {ordemServicoPendenciaService} from "../../../service/ordemServicoService";
import {ConfirmDialogV2} from "../../../components/confirmdialog";
import {ProgressDialog} from "../../../components/progressdialog/ProgressDialog";
import {BlockUI} from "primereact/blockui";
import {useAuth} from "../../../context/AuthContext";

export function Pendencias({ordemServico, onModalClose}) {

    const {usuario} = useAuth();
    const [loading, setLoading] = useState(true);
    const {showDialog} = useContext(DialogContext);
    const [visible, setVisible] = useState(true);
    const [pendencias, setPendencias] = useState([]);
    const [selecionados, setSelecionados] = useState([]);

    useEffect(() => {
        ordemServicoPendenciaService.listar(ordemServico.id).then(pendencias => {
            setPendencias(pendencias);
            setLoading(false);
        });
    }, [ordemServico.id]);

    async function handleSalvar() {
        if (pendencias.length) {
            showDialog(<ProgressDialog onProgress={async setProgress => {
                let progress = 0;
                for (const pendencia of pendencias) {
                    await ordemServicoPendenciaService.salvar(pendencia);
                    setProgress(++progress / pendencias.length);
                }
                if (onModalClose) {
                    onModalClose();
                }
                handleClose();
            }}/>);
        } else {
            handleClose();
        }
    }

    function handleClose() {
        setVisible(false);
    }

    function adicionarPendencia() {
        editarPendencia({
            ...ordemServicoPendenciaService.criar(),
            ordemServico: {id: ordemServico.id},
            responsavel: usuario
        });
    }

    function editarPendencia(pendencia, finalizar = false) {
        if (finalizar && !pendencia.fim) {
            pendencia.fim = moment().format("YYYY-MM-DDTHH:mm:ss");
        }
        showDialog(<EditarPendencia pendencia={pendencia} onChange={d => {
            setPendencias(prevPendencias => {
                const index = prevPendencias.findIndex(pd => (pd.id && pd.id === d.id) || (pd._key && pd._key === d._key));
                if (index > -1) {
                    prevPendencias[index] = d;
                } else {
                    prevPendencias.push(d);
                }
                return [...prevPendencias];
            });
        }}/>);
    }

    function removerPendencias() {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover pendências selecionadas?" onYes={async () => {
            showDialog(<ProgressDialog onProgress={async setProgress => {
                let progress = 0;
                for (const selecionado of selecionados) {
                    if (selecionado.id) {
                        await ordemServicoPendenciaService.delete(selecionado.id, ordemServico.id);
                    }
                    setProgress(++progress / selecionados.length);
                }
                setPendencias(prevPendencias => [...prevPendencias.filter(dd => (dd.id && !selecionados.some(ss => ss.id === dd.id)) || (dd._key && !selecionados.some(ss => ss._key === dd._key)))]);
                setSelecionados([]);
            }}/>);
        }}/>);
    }

    function resolverPendencias() {
        showDialog(<ConfirmDialogV2 message="As pendências de atendimento para as ordens de serviço selecionadas foram resolvidas?" onYes={async () => {
            await ordemServicoPendenciaService.resolver([ordemServico.id]);
            if (onModalClose) {
                onModalClose();
            }
            handleClose();
        }}/>);
    }

    const controlSet = (
        <div>
            <Button disabled={pendencias.some(dd => !dd.fim)} icon="fas fa-plus" onClick={adicionarPendencia}/>
            <Button disabled={!selecionados.length} icon="fas fa-minus" danger onClick={removerPendencias}/>
            <Button disabled={!pendencias.some(p => p.id && !p.fim)} icon="fas fa-car-crash" title="Pendência Resolvida" danger label="Pendências Resolvidas" style={{width: "auto"}} onClick={resolverPendencias}/>
        </div>
    );

    return (
        <Dialog header={`Registro de Pendências para a OS ${ordemServico.numero}`} visible={visible} style={{width: "600px"}} onHide={handleClose}>
            <BlockUI blocked={loading}>
                <PanelContent>
                    <div>
                        <div>{ordemServico.cliente?.autoComplete}</div>
                        <div>{ordemServico.cliente?.endereco}</div>
                    </div>
                    <div className="col-12">
                        <DataTable selection={selecionados}
                                   onSelectionChange={e => setSelecionados(e.value)}
                                   selectionMode="checkbox"
                                   header={controlSet}
                                   footer={controlSet}
                                   value={pendencias}>
                            <Column style={{textAlign: "center", width: "3.25em"}} selectionMode="multiple"/>
                            <Column style={{textAlign: "center", width: "3.25em"}} body={printStatusPendencia}/>
                            <Column style={{textAlign: "center", width: "10em"}} header="Data" field="inicio" body={d => moment(d.inicio).format("DD/MM/YYYY HH:mm")}/>
                            <Column header="Justificativa" field="observacoes"/>
                        </DataTable>
                    </div>
                </PanelContent>
                <DialogFooter>
                    <Button icon="fas fa-save" success label="Salvar" onClick={handleSalvar}/>
                    <Button icon="fas fa-times" secondary label="Fechar" onClick={handleClose}/>
                </DialogFooter>
            </BlockUI>
        </Dialog>
    );

}

function printStatusPendencia(pendencia) {
    if (pendencia.fim) {
        return <i className="fas fa-check-circle" title={`Marcado como resolvido por ${pendencia.resolvidoPor?.nome}`} style={{color: "#4CAF50"}}/>;
    } else {
        return <i className="fas fa-hourglass" title="Aguardando resolução" style={{color: "#FF9800"}}/>;
    }
}
