import React, {useState} from "react";
import {InputText} from "primereact/inputtext";
import {calcularTamanho} from "../service/guiHelper";
import classNames from "classnames";

export function InputButton(props) {

	const [loading, setLoading] = useState(false);

	const inputClasses = classNames({
		"p-invalid": props.invalid
	});

	function handleChange(event) {
		if (props.onChange) {
			props.onChange({name: props.name, value: event.target.value, index: props.index});
		}
	}

	async function handleButtonClick() {
		if (!props.buttonDisabled && props.onButtonClick) {
			setLoading(true);
			await props.onButtonClick();
			setLoading(false);
		}
	}

	return (
		<div className={calcularTamanho(props.col)}>
			<label>{props.label}</label>
			<div className="p-inputgroup">
				<InputText field="autoComplete" {...props} className={inputClasses} onChange={handleChange}/>
				<span className={`p-inputgroup-addon text-button ${props.buttonDisabled ? "disabled" : ""}`} onClick={handleButtonClick}>
					<i className={loading ? "pi pi-spin pi-spinner" : props.buttonIcon}/>
					{props.buttonLabel}
                </span>
			</div>
			{props.reduced ? null : (props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : props.required ? <small style={{color: props.value ? "transparent" : "#888"}} className="block">{props.multiple ? "Você precisa selecionar ao menos uma opção" : "Campo obrigatório"}</small> : <small>&nbsp;</small>)}
		</div>
	);

}
