import React, {useCallback, useContext, useEffect, useState} from "react";
import {TabPanel, TabView} from "primereact/tabview";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import {useNavigate, useParams} from "react-router-dom";
import {useBreakpoint} from "../../context/BreakpointContext";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {TipoMovimentoAutoComplete} from "../../components/autocomplete/TipoMovimentoAutoComplete";
import {CfopAutoComplete} from "../../components/autocomplete/CfopAutoComplete";
import {CondicaoPagamentoAutoComplete} from "../../components/autocomplete/CondicaoPagamentoAutoComplete";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import { InputCurrency, InputNumber} from "../../components/inputnumber";
import {faturamentoService} from "../../service/faturamentoService";
import {Dialog} from "primereact/dialog";
import {DialogFooter} from "../../components/dialog";
import {InputText} from "../../components/inputtext";
import {DialogContext, withDialog} from "../../utils/dialogContext";
import {BlockUI} from "primereact/blockui";
import { formatCurrency } from "../../utils/numberFormatter";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { TransportadoraAutoComplete } from "../../components/autocomplete/TransportadoraAutoComplete";
import { transportadoraService } from "../Comercial/services/transportadoraService";
import { Dropdown } from "../../components/dropdown";
import { formaPagamento, pagamentoFrete } from "../Comercial/services/pedidoVendaService";
import { EditarEventos } from "./EditarEventos";
import { EditarFaturamentoAnexo } from "./EditarFaturamentoAnexo";
import { buildValidator, isBiggerThanZero, isEmpty, isEntityRequired, isRequired } from "../../utils/fieldValidator";
import { Calendar } from "../../components/calendar";
import { ConfirmDialogV2 } from "../../components/confirmdialog";
import { toast } from "react-toastify";
import { InformationDialog } from "../../components/dialog/InformationDialog";

export const Key_FATURAMENTO_NEW = "X-Faturamento-EditarFaturamento-Select";

export const EditarFaturamento = withDialog(({showDialog}) => {

    const navigate = useNavigate();
    const {breakpoint} = useBreakpoint();
    const isLargeDevice = breakpoint === "lg" || breakpoint === "md";
    const {id} = useParams();
    const [faturamento, setFaturamento] = useState({
        itens: [
            {}
        ]
    });
    const [messages, setMessages] = useState({
        ...buildValidator(), 
        anexos: [], 
        retorno: {
            tipoMovimento: null,
            cfop: null,
            itens: [...Array(faturamento?.itens?.find(fat => fat.tipo === 'RETORNO')?.pedidoVenda?.itens?.length)].map((_, i) => ({
                codigoTributario: null,
            }))
        },
        produto: {
            tipoMovimento: null,
            cfop: null,
            condicaoPagamento: null,
            formaPagamento: null,
            itens: [...Array(faturamento?.itens?.find(fat => fat.tipo === 'PRODUTO')?.pedidoVenda?.itens?.length)].map((_, i) => ({
                codigoTributario: null,
            }))
        },
        servico: {
            tipoMovimento: null,
            cfop: null,
            condicaoPagamento: null,
            formaPagamento: null,
            itens: [...Array(faturamento?.itens?.find(fat => fat.tipo === 'SERVICO')?.pedidoVenda?.itens?.length)].map((_, i) => ({
                codigoTributario: null,
            }))
        },
    });
    const [loading, setLoading] = useState(0);

    

    useEffect(() => {
        if (!id) {
            setFaturamento(JSON.parse(window.sessionStorage.getItem(Key_FATURAMENTO_NEW)));
            window.sessionStorage.removeItem(Key_FATURAMENTO_NEW);
        } else {
            setLoading("buscar");
            faturamentoService.buscar(id).then(faturamento => {
                setFaturamento(faturamento);
                setLoading(0);
            });
        }
    }, [id]);

    const handleChange = useCallback((event) => {        
		setFaturamento({...faturamento, [event.name]: event.value});
	}, [faturamento, setFaturamento]);

    const handleConfirmar = () => {
        const messages = faturamentoService.validar(faturamento);
		
        if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;        
		}

        const semItens = verificarItens();
        if(semItens) return;

		showDialog(
            <ConfirmDialogV2 
                message="Tem certeza de que deseja CONFIRMAR esse faturamento? Esse processo não poderá ser desfeito!" 
                onYes={confirmarFaturamento}
            />
        );
	}

    const confirmarFaturamento = async () => {
        setLoading("salvar");

        const confirmarFaturamento = {
            ...faturamento,
            integrar: true,
            status: 'FECHADO'
        }

        toast.promise(
			faturamentoService.salvar(confirmarFaturamento).then(value => {
                navigate("/faturamentos");
            }).finally(() => setLoading(0)),
            {
                "pending": `Salvando faturamento ${faturamento.codigo}. Aguarde...`,
                "success": `Faturamento  ${faturamento.codigo} salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(0);
                        return `Falha ao salvar faturamento`;
                    }
                }
            }
        );
    }
    const verificarItens = () => {
        let semItem = false;
        let semItemRetorno = false;
        let semItemServico = false;
        let semItemProduto = false;

        if(faturamento.itens[0]) {
            faturamento.itens.forEach(el => {
                if(!el.pedidoVenda.itens[0]){
                    if(el.tipo === 'RETORNO'){
                        semItemRetorno = true;
                    }
                    if(el.tipo === 'SERVICO'){
                        semItemServico = true;
                    }
                    if(el.tipo === 'PRODUTO'){
                        semItemProduto = true;
                    }
                } 
            });
        }else{
            semItem = true;
        }

        if (semItem || semItemRetorno || semItemServico || semItemProduto) {

            let info;
            
            if(semItem){
                info = 'Nenhum item encontrado, por favor adicione itens antes salvar'
            }else{
                let abasSemItem = [];
                if(semItemRetorno) abasSemItem.push('Dados de Retorno')
                if(semItemServico) abasSemItem.push('Faturamento de Serviços')
                if(semItemProduto) abasSemItem.push('Faturamento de Produtos')

                if(abasSemItem.length === 1){
                    info = `Nenhum item encontrado na aba de ${abasSemItem[0]}.`
                }else{
                    info = `Nenhum item encontrado nas abas de ${abasSemItem.join(', ')}.`
                }
            }
			showDialog(<InformationDialog header="Informação" message={info}/>);
            return true;        
		}else{
            return false;
        }
    }
    async function handleSalvar() {
        const messages = faturamentoService.validar(faturamento);
        setMessages(messages);
        
        if (!messages.isEmpty()) {
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;        
		}

        const semItens = verificarItens();

        if(semItens) return;

        setLoading("salvar");

        toast.promise(
			faturamentoService.salvar(faturamento).then(value => {
                navigate("/faturamentos");
            }).finally(() => setLoading(0)),
            {
                "pending": `Salvando faturamento ${faturamento.codigo}. Aguarde...`,
                "success": `Faturamento  ${faturamento.codigo} salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(0);
                        return `Falha ao salvar faturamento`;
                    }
                }
            }
        );
    }

    function handleVoltar() {
        navigate(-1);
    }

    function headerTemplate(e) {
        const tab = tabs[e.index]
        
        let style = {};

        switch (tab.props.header) {
            case 'Dados de Retorno':
                if (messages?.retorno?.cfop ||messages?.retorno?.tipoMovimento || messages?.retorno?.itens?.some(mi => !isEmpty(mi))) {
                    style.color = "#F44336";
                }
                break;
            case 'Faturamento de Peças':
                if (messages?.produto?.cfop || messages?.produto?.tipoMovimento || messages?.produto?.condicaoPagamento || messages?.produto?.formaPagamento || messages?.produto?.itens?.some(mi => !isEmpty(mi))) {
                    style.color = "#F44336";
                }
                break;
            case 'Faturamento de Serviços':
                if (messages?.servico?.cfop || messages?.servico?.tipoMovimento || messages?.servico?.condicaoPagamento || messages?.servico?.formaPagamento || messages?.servico?.itens?.some(mi => !isEmpty(mi))) {
                    style.color = "#F44336";
                }
                break;
            case 'Eventos':
                
                break;
            case 'Anexos':
                
                break;
            default:
                break;
        }

		return (
			<div className={`${e?.className}`} aria-controls={`${e.ariaControls}`} onClick={e.onClick} onKeyDown={e.onKeyDown} style={style}>
				<i className={`${e?.leftIconElement?.props?.className}`}/>
				<div className={`${e?.titleClassName}`} >{e?.titleElement?.props?.children}</div>
			</div>
		);
	}
    

    const retorno = faturamento?.itens?.findIndex(fi => fi?.tipo === "RETORNO");
    const produto = faturamento?.itens?.findIndex(fi => fi?.tipo === "PRODUTO");
    const servico = faturamento?.itens?.findIndex(fi => fi?.tipo === "SERVICO");
    const tabs = [];

    if (retorno > -1) tabs.push(
        <TabPanel leftIcon="fas fa-truck" header="Dados de Retorno" headerTemplate={headerTemplate}>
            <CamposFaturamento 
                index={retorno} 
                faturamento={faturamento} 
                tipo="RETORNO"
                setFaturamento={setFaturamento}
                messages={messages} 
                setMessages={setMessages}
            />
        </TabPanel>
    );

    if (produto > -1) tabs.push(
        <TabPanel leftIcon="fas fa-boxes" header="Faturamento de Peças" headerTemplate={headerTemplate}>
            <CamposFaturamento 
                index={produto} 
                faturamento={faturamento} 
                tipo="PRODUTO" 
                setFaturamento={setFaturamento}
                messages={messages} 
                setMessages={setMessages}
            />
        </TabPanel>
    );

    if (servico > -1) tabs.push(
        <TabPanel leftIcon="fas fa-wrench" header="Faturamento de Serviços" headerTemplate={headerTemplate}>
            <CamposFaturamento 
                index={servico} 
                faturamento={faturamento} 
                tipo="SERVICO" 
                setFaturamento={setFaturamento}
                messages={messages} 
                setMessages={setMessages}
            />
        </TabPanel>
    );
    
    tabs.push(
        <TabPanel header="Eventos" leftIcon='fa-solid fa-file-lines'>
            <EditarEventos 
                faturamento={faturamento} 
                setFaturamento={setFaturamento} 
                messages={messages} 
                setMessages={setMessages}
            />
        </TabPanel>
    );

    tabs.push(
        <TabPanel header="Anexos" leftIcon='fa-solid fa-paperclip' >
            <PanelContent>
                <EditarFaturamentoAnexo 
                    faturamento={faturamento} 
                    setFaturamento={setFaturamento}
                    messages={messages} 
					setMessages={setMessages} 
                />
        </PanelContent>
      </TabPanel>
    ); 

    const headerStatus = useCallback(() => {
        const label = faturamento.status === 'ABERTO' ? 'Aberto' : 'Fechado'
        const icon = faturamento.status === 'ABERTO' ? 'fa-folder-open' : 'fa-folder-closed'
        const color = faturamento.status === 'ABERTO' ? '#FF9800' : '#000000'

		return(
            <div className="flex" title={label}>
                <i className={`fa-solid ${icon} fa-lg mr-2`} style={{color: color}}/>
                <strong>{label}</strong>
            </div>
        )
	}, [faturamento.status]);

    return (
        <BlockUI blocked={loading === "buscar" || loading === 'salvar'}>
            <Panel header="Faturamento" icons={headerStatus}>
                <PanelContent>
                    <Calendar 
					    col={2} 
					    disabled 
					    name="registro" 
					    showTime showSeconds 
					    label="Data de Inclusão" 
					    value={faturamento?.registro}
				    />
                    <InputText  
                        readOnly
                        col={1}  
                        label="Número" 
                        value={faturamento.codigo} 
                    />
                    
                    <InputText                         
                        col={3}  
                        label="Pedido de Compra" 
                        value={faturamento.pedidoCompra} 
                        onChange={handleChange}
                        name="pedidoCompra"
                    />

                    <div className="col-6"/>

                    <InputCurrency 
                        readOnly={true} 
                        col={2} 
                        value={faturamento?.valorTotalRetorno}
                        label="Valor Total Retorno" 
                        name="valorTotalRetorno"
                    />
                        
                    <InputCurrency 
                        readOnly={true} 
                        col={2} 
                        value={faturamento.valorTotalServico}
                        label="Valor Total Serviço" 
                        name="valorTotalServico"
                    />

                    <InputCurrency 
                        readOnly={true} 
                        col={2} 
                        value={faturamento.valorTotalProduto}
                        label="Valor Total Produto" 
                        name="valorTotalProduto"
                    />                    
    
				<div className="col-6"/>

                    <div className="col-12">
                        <TabView>{tabs}</TabView>
                    </div>
                </PanelContent>
                <PanelFooter>
                    {
                        faturamento.status === 'ABERTO' &&
                        <div style={{float: "left"}} key={'confirmar'}>
                            <Button label="Confirmar" icon="fas fa-check-circle" primary onClick={handleConfirmar} />			
                        </div>
                    }

                    {
                        faturamento.status === 'ABERTO' &&
                        <Button success disabled={loading} loading={loading === "salvar"} onClick={handleSalvar} label={isLargeDevice ? "Salvar" : null} icon="pi pi-save" autowidth={!isLargeDevice}/>
                    }
                    <Button secondary onClick={handleVoltar} label={isLargeDevice ? "Voltar" : null} icon="pi pi-arrow-left" autowidth={!isLargeDevice}/>
                </PanelFooter>
            </Panel>
        </BlockUI>
    );

})

function CamposFaturamento({index, faturamento, setFaturamento, tipo, messages, setMessages}) {

    const [blocked, setBlocked] = useState(false);
    
    const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);

	const handleSelectTransportadora = useCallback(async (event) => {
		if (event.originalEvent?.type === "blur") return;
		if (event.value.id) {
			setBlocked(true);
		  	try {
				const transportadora = await transportadoraService.buscar(event.value.id);
				setBlocked(false);
                faturamento.itens[index].pedidoVenda.transportadora = transportadora;
				setFaturamento((prevState) => ({
			  	...prevState,
			  	transportadora,
				}));
		  	} catch (error) {
				console.error("Erro ao buscar transportadora:", error);
				setBlocked(false);
		  	}
		}
	  }, [faturamento.itens, index, setFaturamento]);

    const {showDialog} = useContext(DialogContext);
    const [valorTotalGeral, setValorTotalGeral] = useState(formatCurrency(0));

    const calcularValorTotal = (faturamentoItens, tipo) => {
        let total = faturamentoItens.reduce((acc, fi) => {
          if (fi.tipo === tipo) {
            const valorTotal = fi.pedidoVenda.itens.reduce(
              (subtotal, pvi) => subtotal + pvi.valorTotal,
              0
            );
            return acc + valorTotal;
          }
          return acc;
        }, 0);
        
        return formatCurrency(total);
      };
      

    useEffect(() => {        
        const valorTotalGeral = calcularValorTotal(faturamento.itens, tipo);
        setValorTotalGeral(valorTotalGeral);
    }, [faturamento.itens, tipo]);


    if (index < 0 || !faturamento.itens[index]) {
        return "Nenhum item a ser exibido";
    }

    const  validarItem = (e, index, data) =>  {
        let erro;
        let itens = [];
        
		// switch (e?.target.name) {
        //     case 'quantidade':
        //         erro = isBiggerThanZero(data.quantidade)
        //         itens = [...messages.itens]
        //         itens[index] = {...messages.itens[index], quantidade: erro}
        //         break;
        //     case 'valorUnitario':
        //         erro = isBiggerThanZeroValor(data.valorUnitario)
        //         itens = [...messages.itens]
        //         itens[index] = {...messages.itens[index], valorUnitario: erro}
        //         break;
        //     case 'produto':
        //         erro = isEntityRequired(data.produto)
        //         itens = [...messages.itens]
        //         itens[index] = {...messages.itens[index], produto: erro}
        //         break;
        //     default:
        //         break;
        // }
        
        setMessages((messages) => {
            return {
                ...messages,
                itens 
            }
        })
    }


    function handleChange(event) {
        setFaturamento(faturamento => {
            faturamento.itens[index].pedidoVenda[event.name] = event.value;
            return {...faturamento};
        });
    }
    function handleChangeFaturamentoItem(event) {
        setFaturamento(faturamento => {
            faturamento.itens[index][event.name] = event.value;
            return {...faturamento};
        });
    }

    function handleChangeItem(event) {
        setFaturamento(faturamento => {
            faturamento.itens[index].pedidoVenda.itens[event.index][event.name] = event.value;
            return {...faturamento};
        });
    }

    const pedidoVenda = faturamento.itens[index].pedidoVenda;

    function editarPedidoVendaItem(i) {
        showDialog(<EditarDetalhesPedidoVendaItem value={pedidoVenda.itens[i]} onChange={item => {
            setFaturamento(faturamento => {
                faturamento.itens[index].pedidoVenda.itens[i] = item;
                return {...faturamento};
            })
        }}/>);
    }

    function excluir() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os itens selecionados?" onYes={() => {
            const itens = faturamento.itens[index].pedidoVenda.itens.filter(osi => !linhasSelecionadas.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
            
            handleChange({name: "itens", value: itens});

            handleChangeFaturamentoItem({name: "itensRemover", value: linhasSelecionadas});

            setLinhasSelecionadas([]);
        }}/>);
	}

    let headerGroup = (
	    <ColumnGroup>
            <Row>
            <Column colSpan={2} header={
					<div>
						{/* <Button icon="pi pi-plus" onClick={() => {}} disabled={faturamento.status === 'FECHADO'} /> */}
                        <Button 
                            danger
                            icon='fa-solid fa-x'
                            onClick={excluir}
                            disabled={linhasSelecionadas.length === 0 || faturamento.status === 'FECHADO'}
                        />
					</div>
				}/>
			    <Column header="Total Geral:" colSpan={6} alignHeader="right"/>
			    <Column header={valorTotalGeral} alignHeader="right" />
                <Column colSpan={2} />
		    </Row>
		    <Row>
                <Column selectionMode="multiple" headerStyle={{width: '1em', textAlign:'center'}} align='center' alignHeader={'center'}/>
                <Column header="Item" field="item" alignHeader={"center"} />
			    <Column header="Ordem Serviço" alignHeader={"center"}/>
			    <Column header="Nota Fiscal" alignHeader={"center"}/>
                <Column header="Contrato" alignHeader={"center"}/>
			    <Column header="Produto" />
			    <Column header="Valor Unitário" alignHeader={"right"}/>
			    <Column header="Quantidade" alignHeader={"center"} />
			    <Column header="Valor Total" alignHeader={"right"}  />
			    <Column header="Cód. Tributário"  alignHeader={"center"} />
			    <Column header="Ações" style={{width: "5em", textAlign: "center"}}/>
		    </Row>

	    </ColumnGroup>
    );

    let footerGroup = (
	    <ColumnGroup>
		    <Row>
                <Column footer="Total Geral:" colSpan={8} footerStyle={{textAlign: 'right'}}/>
			    <Column footer={valorTotalGeral} footerStyle={{textAlign: 'right'}} />
			    <Column colSpan={2} />
		    </Row>
	    </ColumnGroup>
    );

    function headerDataTable() {       
        return (
            <div style={{textAlign: 'right'}}>
                {`Total de registros encontrados: ${pedidoVenda.itens?.length}`}
            </div>
        );
    };

    return (
        <BlockUI blocked={blocked}>
            <PanelContent>
                <InputText
                    readOnly                         
                    col={2}  
                    label="Pedido de Venda" 
                    value={pedidoVenda.numero} 
                />
                <div className="col-10"/>
                <ClienteAutoComplete
                    readOnly
					col={4} 
					value={pedidoVenda?.cliente} 
					name="cliente" 
					onChange={handleChange} 
				/>

				<InputText 
					readOnly 
					col={4}  
					label="Razão Social" 
					value={pedidoVenda?.cliente?.razaoSocial || ""} 
				/>
				
				<InputText 
					readOnly 
					col={2} 
					label="CPF/CNPJ" 
					value={pedidoVenda?.cliente?.vwCliente?.cpfCnpjFormatado || ""}
				/>

                <TipoMovimentoAutoComplete 
                    name="tipoMovimento" 
                    value={pedidoVenda.tipoMovimento} 
                    onChange={handleChange} 
                    col={4}
                    invalid={messages[tipo.toLowerCase()]?.tipoMovimento}
                    onBlur={(e) => {
                        let mensagensAtualizadas = {
                            ...messages,
                        }

                        mensagensAtualizadas[tipo.toLowerCase()] = {
                            ...mensagensAtualizadas[tipo.toLowerCase()],
                            tipoMovimento: isEntityRequired(pedidoVenda.tipoMovimento)
                        } 

                        setMessages(mensagensAtualizadas)
                    }}
                />
                <CfopAutoComplete 
                    name="cfop" 
                    value={pedidoVenda.cfop} 
                    onChange={handleChange} 
                    col={4}
                    invalid={messages[tipo.toLowerCase()]?.cfop}
                    onBlur={(e) => {
                        let mensagensAtualizadas = {
                            ...messages,
                        }

                        mensagensAtualizadas[tipo.toLowerCase()] = {
                            ...mensagensAtualizadas[tipo.toLowerCase()],
                            cfop: isEntityRequired(pedidoVenda.cfop)
                        } 
                        
                        setMessages(mensagensAtualizadas)
                    }}
                />
                {
                    ["PRODUTO", "SERVICO"].includes(tipo) ? (
                        <>
                            <CondicaoPagamentoAutoComplete 
                                name="condicaoPagamento" 
                                value={pedidoVenda.condicaoPagamento} 
                                onChange={handleChange} 
                                col={4}
                                invalid={messages[tipo.toLowerCase()] !== 'retorno' && messages[tipo.toLowerCase()]?.condicaoPagamento}
                                onBlur={(e) => {
                                    let mensagensAtualizadas = {
                                        ...messages,
                                    }
            
                                    mensagensAtualizadas[tipo.toLowerCase()] = {
                                        ...mensagensAtualizadas[tipo.toLowerCase()],
                                        condicaoPagamento: isEntityRequired(pedidoVenda.condicaoPagamento)
                                    } 
                                    
                                    setMessages(mensagensAtualizadas)
                                }}
                            />
                            <Dropdown 
                                options={formaPagamento} 
                                col={4} 
                                name="formaPagamento" 
                                label="Forma de Pagamento" 
                                value={pedidoVenda.formaPagamento} 
                                onChange={handleChange} 
                                invalid={messages[tipo.toLowerCase()] !== 'retorno' && messages[tipo.toLowerCase()]?.formaPagamento}
                                onBlur={(e) => {
                                    let mensagensAtualizadas = {
                                        ...messages,
                                    }
            
                                    mensagensAtualizadas[tipo.toLowerCase()] = {
                                        ...mensagensAtualizadas[tipo.toLowerCase()],
                                        formaPagamento: isRequired(pedidoVenda.formaPagamento)
                                    } 
                                    
                                    setMessages(mensagensAtualizadas)
                                }}
                            />
                            {/* <VendedorAutoComplete name="vendedor" value={pedidoVenda.vendedor} onChange={handleChange} col={3}/> */}
                        </>
                    ) : null
                }
                
                {
                    
                    ["RETORNO"].includes(tipo) ? (
                        <>
                            <div className="col-2"/>
                            <TransportadoraAutoComplete
                                col={4} 
                                value={pedidoVenda?.transportadora} 
                                name="transportadora" 
                                onSelect={handleSelectTransportadora} 
                                onChange={handleChange}
                            />
                                                
                            <InputText 
                                readOnly 
                                col={4}  
                                label="Razão Social" 
                                value={pedidoVenda?.transportadora?.razaoSocial || ""} 
                            />

                            <InputText 
                                readOnly 
                                col={2} 
                                label="CPF/CNPJ" 
                                value={pedidoVenda?.transportadora?.vwTransportadora?.cpfCnpjFormatado || ""} 
                            />

                            <Dropdown
                                options={pagamentoFrete} 
                                col={4}  
                                name="frete" 
                                label="Pagamento do Frete" 
                                value={pedidoVenda?.frete} 
                                onChange={handleChange}
                            />		

                            <InputCurrency
                                col={2} 
                                value={pedidoVenda?.valorFrete} 
                                min={0} 
                                label="Valor Frete" 
                                name="valorFrete" 
                                onChange={handleChange}
                                
                            />
                    </>
                    ) : null
                }
                
                <div className="col-12">
                    <DataTable  value={pedidoVenda.itens} 
                                valuelength={pedidoVenda.itens?.length}
                                header={headerDataTable}
                                headerColumnGroup={headerGroup}
                                footerColumnGroup={footerGroup}
                                selectionMode="checkbox"
                                selection={linhasSelecionadas}
                                onSelectionChange={e => setLinhasSelecionadas(e.value)} 
                    >
                        <Column selectionMode="multiple" headerStyle={{width: '1em', textAlign: 'center'}} align='center' alignHeader={'centers'}/>
                        <Column style={{ width: '2em', textAlign: 'center'}} field="sequencia" name="sequencia"/>
                        <Column style={{ width: '8em', textAlign: 'center'}} field="ordemServicoNumero" name="ordemServicoNumero"/>                        
                        <Column style={{ width: '8em', textAlign: 'center'}} field="notaFiscalNumero" name="notaFiscalNumero"/>
                        <Column style={{ width: '8em', textAlign: 'center'}} field="contratoNumero" name="contratoNumero"/>
                        <Column style={{ width: '18em', textAlign: 'left'}} field="produto.autoComplete" name="produto"/>
                        <Column style={{ width: '8em', textAlign: 'right'}} field="valorUnitario" name="valorUnitario" body={r => formatCurrency(r.valorUnitario)}/>
                        <Column style={{ width: '6em', textAlign: 'center'}} field="quantidade" name="quantidade"/>
                        <Column style={{ width: '8em', textAlign: 'right'}} field="valorTotal" name="valorTotal" body={r => formatCurrency(r.valorTotal)}/>
                        <Column 
                            style={{width: "8em"}} 
                            field="codigoTributario" 
                            header="Cód. Tributário"  
                            alignHeader={"center"}  
                            body={(fi, c) => (
                                <InputNumber 
                                    reduced={!messages[tipo.toLowerCase()]?.itens?.[c.rowIndex]?.codigoTributario}
                                    value={fi.codigoTributario} 
                                    index={c.rowIndex} 
                                    name="codigoTributario" 
                                    onChange={handleChangeItem}
                                    invalid={messages[tipo.toLowerCase()]?.itens?.[c.rowIndex]?.codigoTributario}
                                    onBlur={(e) => {
                                        let erro = isBiggerThanZero(fi.codigoTributario)
                                        
                                        let mensagensAtualizadas = {
                                            ...messages,
                                        }

                                        mensagensAtualizadas[tipo.toLowerCase()] = {
                                            ...mensagensAtualizadas[tipo.toLowerCase()],
                                            itens: [
                                                ...mensagensAtualizadas[tipo.toLowerCase()]?.itens,
                                            ]
                                        }
                                        
                                        mensagensAtualizadas[tipo.toLowerCase()].itens[c.rowIndex] = {
                                            ...mensagensAtualizadas[tipo.toLowerCase()]?.itens[c.rowIndex],
                                            codigoTributario: erro
                                        }
                                        
                                        setMessages(mensagensAtualizadas)
                                        
                                    }}
                                />
                            )}
                        />
                        <Column style={{width: "4em", textAlign: "center"}} header="Ações" body={(fi, c) => (
                            <div>
                                <Button icon="fas fa-edit" onClick={() => editarPedidoVendaItem(c.rowIndex)}/>
                            </div>
                        )}/>
                    </DataTable>
                </div>
            </PanelContent>
        </BlockUI>
    );

}

function EditarDetalhesPedidoVendaItem({value, onChange}) {

    const [item, setItem] = useState(value);
    const [visible, setVisible] = useState(true);

    function handleClose() {
        setVisible(false);
    }

    function handleSalvar() {
        onChange(item);
        handleClose();
    }

    function handleChange(event) {
        setItem(prevItem => ({...prevItem, [event.name]: event.value}));
    }

    return (
        <Dialog visible={visible} onHide={handleClose} style={{width: "750px"}} header="Detalhes do Item">
            <PanelContent>
                <CfopAutoComplete col={6} name="cfop" value={item.cfop} onChange={handleChange}/>
                <TipoMovimentoAutoComplete col={6} name="tipoMovimento" value={item.tipoMovimento} onChange={handleChange}/>
                <InputText label="Complemento" multiline col={12} style={{height: "10em"}} name="complemento" value={item.complemento} onChange={handleChange}/>
            </PanelContent>
            <DialogFooter>                
                <Button success icon="fas fa-save" label="Salvar" onClick={handleSalvar}/>
                <Button secondary icon="fas fa-times" label="Fechar" onClick={handleClose}/>
            </DialogFooter>
        </Dialog>
    );

}
