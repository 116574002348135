import React, {useState} from "react";
import {AutoComplete} from "./index";
import {prateleiraService} from "../../service/prateleiraService";
import {calendarioService} from "../../service/calendarioService";

export function CalendarioAutoComplete(props) {

    const [calendarios, setCalendarios] = useState([]);
    const [emptyMessage, setEmptyMessage] = useState("");

    function completeMethod(event) {
        const query = [];
        if (event.query.length) {
            query.push(`descricao=lk=${encodeURIComponent(event.query)}`);
        }
        calendarioService.listar([`query=${query.join(";")}`]).then(calendarios => {
            setCalendarios(calendarios);
            if (!calendarios[0]) {
                setEmptyMessage("Nenhum registro encontrado");
            } else {
                setEmptyMessage("");
            }
        });
    }

    return (
        <AutoComplete col={3}
                      placeholder="pesquise por descrição"
                      label="Calendário"
                      icon="fa-solid fa-inbox"
                      name="calendario"
                      value={props.value}
                      onChange={props.onChange}
                      completeMethod={completeMethod}
                      suggestions={calendarios}
                      invalid={emptyMessage}
                      {...props}
        />
    );

}
