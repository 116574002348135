import {baseService} from "./baseService";
import moment from "moment";

const resourceUrl = "/eventos";

export const eventoService = {
	criar() {
		return {
			id: null,
			registro: moment().format("YYYY-MM-DDTHH:mm:ss"),
			_key: Math.random(),
			tipo: null,
			observacoes: ""
		};
	},
	...baseService(resourceUrl)
};
