import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {pedidoVendaService, status} from '../services/pedidoVendaService';
import {buildValidator, isEmpty} from '../../../utils/fieldValidator';
import {TabPanel} from 'primereact/tabview';
import {PanelContent} from '../../../components/panel';
import {EditarDadosPrincipais} from './EditarDadosPrincipais';
import {EditarPedidoVendaItens} from './EditarPedidoVendaItens';
import {FormTab} from '../../../components/formtab';
import {toast} from 'react-toastify';
import MotivoBloqueio from './MotivoBloqueio';
import { Button } from '../../../components/button';
import { EditarEventos } from './EditarEventos';
import {useAuth} from "../../../context/AuthContext";
import { withDialog } from '../../../utils/dialogContext'
import { InformationDialog } from '../../../components/dialog/InformationDialog';
import { EditarPedidoVendaAnexo } from './EditarPedidoVendaAnexo';

export const EditarPedidoVenda = withDialog(({showDialog}) => {

	const {id, sistema} = useParams();
	const navigate = useNavigate();
	const {roles} = useAuth();
	const [pedidoVenda, setPedidoVenda] = useState(pedidoVendaService.criar());
	const [messages, setMessages] = useState({...buildValidator(), itens: [], anexos: []});
	const [loading, setLoading] = useState(false);
	const [motivoBloqueio, setMotivoBloqueio] = useState(false);
	const disabled = pedidoVenda?.status === 'AGUARDANDO' || pedidoVenda?.status === 'PERDIDO' || pedidoVenda?.status === 'APROVADO';
	const disabledItens = !pedidoVenda.cliente?.id || !pedidoVenda?.cfop?.id || !pedidoVenda?.tipoMovimento?.id;

	useEffect(() => {		
		if (id !== "new") {
			if (sistema.toUpperCase() === 'TZM') {
				pedidoVendaService.buscar(id).then(setPedidoVenda);
			} else {
				pedidoVendaService.buscarvw(id).then(setPedidoVenda);
			}
		}		
	}, [id, loading, setPedidoVenda, sistema]);
	
    const headerStatus = () => {
        const value = status.filter(status => status?.value === pedidoVenda?.status)[0];
		return(
            <div className="flex" title={value?.label}>
                <i className={`fa-solid ${value?.icon} fa-lg mr-2`} style={{color: value?.color}}/>
                <strong>{value?.label}</strong>
            </div>
        )
	}

	const handleSalvar = useCallback(() => {

        const messages = pedidoVendaService.validar(pedidoVenda);
		
        if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;
        } else if (pedidoVenda.itens.length <= 0){
			showDialog(<InformationDialog header="Informação" message="Nenhum item adicionado. Por favor adicione pelo menos 1 item antes de continuar com essa operação."/>);
            return;
		}
	
        setLoading(true);
        
        toast.promise(
			pedidoVendaService.salvar(pedidoVenda).then(value => {
                navigate(-1)
            }).finally(() => setLoading(false)),
            {
                "pending": `Salvando pedido de venda. Aguarde...`,
                "success": `Pedido de venda salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar pedido de venda`;
                    }
                }
            }
        );
    }, [navigate, pedidoVenda, showDialog]);

	const handleConfirmar = useCallback(() => {

        const messages = pedidoVendaService.validar(pedidoVenda);
		
        if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;
        }

		if(pedidoVenda.itens.length <= 0){
			showDialog(<InformationDialog header="Informação" message="Nenhum item adicionado. Por favor adicione pelo menos 1 item antes de continuar com essa operação."/>);
            return;
		}
		pedidoVenda.status = 'AGUARDANDO';
		pedidoVenda.sistema = 'TZM';
        setLoading(true);
        
        toast.promise(
			pedidoVendaService.salvar(pedidoVenda).then(value => {
                navigate(-1)
            }).finally(() => setLoading(false)),
            {
                "pending": `Salvando pedido de venda. Aguarde...`,
                "success": `Pedido de venda salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar pedido de venda`;
                    }
                }
            }
        );
    }, [navigate, pedidoVenda, showDialog]);
	
	const confirmar = pedidoVenda?.status === 'RASCUNHO' || pedidoVenda?.status === 'ERRO';
	const bloqueado = pedidoVenda?.status === 'BLOQUEADO';

	const botoesAdicionais = [
		confirmar ?  <div style={{float: "left"}} key={'confirmar'}>
							<Button label="Confirmar" icon="fas fa-check-circle" primary onClick={() => handleConfirmar()} />			
						</div> : null,
		bloqueado ? <div style={{float: "left"}} key='bloqueado'>
		 					<Button label="Bloqueios" warning icon="fa-solid fa-shield" onClick={() => setMotivoBloqueio(true)} />
					</div> : null
	]; 

	const descricao = !pedidoVenda?.id ? 'Pedido de Venda' : `Pedido de Venda nº ${pedidoVenda?.numero}`;

	function headerTemplate(e) {
		let style = {};
		switch (e.index) {
			case 0:
				if (messages.dataPedido || messages.dataEntrega || messages.cliente || messages.tipoMovimento || messages.cfop || 
					messages.condicaoPagamento || messages.vendedor || messages.frete || messages.formaPagamento) {
					style.color = "#F44336";
				}
				break;
			case 1:
				if (messages.itens.some(mi => !isEmpty(mi))) {
					style.color = "#F44336";
				}
				break;
			case 2:
				break;
			case 3:
				if (messages.anexos.some(mi => !isEmpty(mi))) {
					style.color = "#F44336";
				}
				break;
			default:
				break;
		}
		return (
			<div className={`${e?.className}`} aria-controls={`${e.ariaControls}`} onClick={e.onClick} onKeyDown={e.onKeyDown} style={style}>
				<i className={`${e?.leftIconElement?.props?.className}`}/>
				<div className={`${e?.titleClassName}`} >{e?.titleElement?.props?.children}</div>
			</div>
		);
	}

	return (
		<>
			<FormTab
				podeDeletar={false}
				podeSalvar={!!roles.PDVC}
				descricao={descricao}
				service={pedidoVendaService}
				value={pedidoVenda}
				salvar={handleSalvar}
				lockSave={disabled || loading}
				blocked={id !== "new" && !pedidoVenda?.id}
				onValidate={setMessages}			
				header_right={headerStatus}
				botoes_adicionais={botoesAdicionais}
			>

				<TabPanel header="Dados Principais" leftIcon='fa-solid fa-database' headerTemplate={headerTemplate}>
					<EditarDadosPrincipais pedidoVenda={pedidoVenda} setPedidoVenda={setPedidoVenda} messages={messages} setMessages={setMessages} disabled={disabled}/>
				</TabPanel>

				<TabPanel header="Itens" leftIcon='fa-solid fa-list-ol' headerTemplate={headerTemplate} disabled={disabledItens}>
					<EditarPedidoVendaItens 
						pedidoVenda={pedidoVenda} 
						setPedidoVenda={setPedidoVenda} 
						messages={messages} 
						setMessages={setMessages} 
						disabled={disabled}
					/>
				</TabPanel>

				<TabPanel header="Eventos" leftIcon='fa-solid fa-file-lines' headerTemplate={headerTemplate}>
					<PanelContent>
						<EditarEventos 
							pedidoVenda={pedidoVenda} 
							setPedidoVenda={setPedidoVenda} 
							messages={messages} 
							setMessages={setMessages} 
							disabled={disabled}
						/>
					</PanelContent>
				</TabPanel>
				<TabPanel header="Anexos" leftIcon='fa-solid fa-paperclip' headerTemplate={headerTemplate}>
                    <PanelContent>
                        <EditarPedidoVendaAnexo 
							pedidoVenda={pedidoVenda} 
							setPedidoVenda={setPedidoVenda} 
							messages={messages} 
							setMessages={setMessages} 
						/>
                    </PanelContent>
                </TabPanel>
			</FormTab>

			<MotivoBloqueio
				visible={motivoBloqueio}
				setVisible={setMotivoBloqueio}
				bloqueios={pedidoVenda?.motivosBloqueio}
			/>
		</>
	);
});