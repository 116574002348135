import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useViaCepService } from "../../service/viaCepService";
import { InputText } from "../../components/inputtext";
import { InputMask } from "../../components/inputmask";
import {
  isBiggerThanZero,
  isCEP,
  isCNPJ,
  isEntityRequired,
  isRequired,
} from "../../utils/fieldValidator";
import { ToogleInputText } from "../../components/toogleinputtext";
import { SeletorDeFoto } from "../../components/seletorDeFoto";
import { toSrc } from "../../service/fotoUtils";
import { SelectButton } from "../../components/selectbutton";
import { If } from "../../components/conditional/If";
import { EmpresaAutoComplete } from "../../components/autocomplete/empresaAutoComplete";
import { ClienteAutoComplete } from "../../components/autocomplete/ClienteAutoComplete";
import { clienteService } from "../../service/clienteService";

export function EditarDadosPrincipais({
  empresa,
  setEmpresa,
  messages,
  setMessages,
}) {
  useEffect(() => {
    if (!empresa.numero) return;

    if (!isNaN(empresa.numero)) {
      setTemNumero(true);
    } else {
      setTemNumero(false);
    }
  }, [empresa.numero]);

  const viaCEPservice = useViaCepService();

  //fields
  const [temNumero, setTemNumero] = useState(true);

  //functions
  const handleCEP = () => {
    messages.cep = isCEP(empresa.cep);
    setMessages({ ...messages });

    toast.promise(
      viaCEPservice.find(empresa.cep).then((data) => {
        const atualizarInformacoesDeEndereco = {
          ...empresa,
          bairro: data.bairro,
          cidade: data.localidade,
          endereco: `${data.logradouro}${
            !data.complemento ? "" : ", " + data.complemento
          }`,
          uf: data.uf,
        };
        setEmpresa(atualizarInformacoesDeEndereco);
      }),
      {
        pending: `Carregando informações do CEP.`,
        success: `Informações carregadas com sucesso.`,
        error: "Erro ao tentar carregar informações do CEP informado.",
      }
    );
  };

  function handleChange(event) {
    if (event.name === "tipoEmpresa" && event.value === "MATRIZ") {
      return setEmpresa({
        ...empresa,
        [event.name]: event.value,
        matriz: null,
      });
    }
    setEmpresa({ ...empresa, [event.name]: event.value });
  }

  const customBase64Uploader = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function (e) {
      const data = e.target.result;

      const adicionarLogotipo = {
        ...empresa,
        logotipo: {
          fileName: file.name,
          contentType: file.type,
          conteudo: {
            conteudo: data.split(",")[1],
          },
        },
      };

      setEmpresa(adicionarLogotipo);
    };

    messages.logotipo = null;
    setMessages({ ...messages });
  };

  const optionsAcessoPortalEmpresa = [
    { label: <div>Sim</div>, value: true },
    { label: <div>Não</div>, value: false },
  ];
  const optionsTipoEmpresa = [
    {
      label: (
        <div className="flex w-full justify-content-center align-items-center">
          <i className="fa-solid fa-city mr-2 fa-lg" />
          Matriz
        </div>
      ),
      value: "MATRIZ",
    },
    {
      label: (
        <div className="flex w-full justify-content-center align-items-center">
          <i className="fa-solid fa-building mr-2 fa-lg" />
          Filial
        </div>
      ),
      value: "FILIAL",
    },
  ];

  const handleClienteSelect = (e) => {
    if (e.originalEvent._reactName === "onBlur") return;
    clienteService.buscar(e.value.id).then((cliente) => {
      setEmpresa({
        ...empresa,
        cliente: cliente,
        razaoSocial: cliente.razaoSocial,
        nomeFantasia: cliente.nomeFantasia,
        cnpj: cliente.cpfCnpj,
        cep: cliente.vwCliente.cep,
        endereco: cliente.vwCliente.endereco,
        bairro: cliente.vwCliente.bairro,
        uf: cliente.vwCliente.uf,
        email: cliente.vwCliente.email,
        telefone: cliente.vwCliente.telefones[0],
        cidade: cliente.vwCliente.municipio,
        inscricaoEstadual: cliente.vwCliente.inscricaoEstadual,
        complemento: cliente.vwCliente.complemento,
      });
    });
  };

  return (
    <>
      <div className="col-9 grid h-full">
        <ClienteAutoComplete
          col={12}
          value={empresa.cliente}
          onChange={handleChange}
          required
          onBlur={() => {
            messages.cliente = isEntityRequired(empresa.cliente);
            setMessages({ ...messages });
          }}
          onSelect={handleClienteSelect}
          invalid={messages.cliente}
          contratoAtivo
        />
        <InputText
          required
          onBlur={() => {
            messages.razaoSocial = isRequired(empresa.razaoSocial);
            setMessages({ ...messages });
          }}
          invalid={messages.razaoSocial}
          col={5}
          name="razaoSocial"
          label="Razão Social"
          value={empresa.razaoSocial}
          onChange={handleChange}
        />
        <InputText
          required
          onBlur={() => {
            messages.nomeFantasia = isRequired(empresa.nomeFantasia);
            setMessages({ ...messages });
          }}
          invalid={messages.nomeFantasia}
          col={4}
          name="nomeFantasia"
          label="Nome Fantasia"
          value={empresa.nomeFantasia}
          onChange={handleChange}
        />
        <InputMask
          required
          unmask
          onBlur={() => {
            messages.cnpj = isCNPJ(empresa.cnpj);
            setMessages({ ...messages });
          }}
          invalid={messages.cnpj}
          mask="99.999.999/9999-99"
          col={3}
          name="cnpj"
          label="CNPJ"
          value={empresa.cnpj}
          onChange={handleChange}
        />
        <InputMask
          required
          unmask
          onBlur={handleCEP}
          invalid={messages.cep}
          mask="99999-999"
          col={2}
          name="cep"
          label="CEP"
          value={empresa.cep}
          onChange={handleChange}
        />
        <InputText
          required
          onBlur={() => {
            messages.endereco = isRequired(empresa.endereco);
            setMessages({ ...messages });
          }}
          invalid={messages.endereco}
          col={5}
          readOnly
          name="endereco"
          label="Endereço"
          value={empresa.endereco}
        />
        {/* <ToogleInputText
          checked={temNumero}
          onChangeToggle={(e) => {
            if (!e.value) {
              const atualizarNumero = {
                ...empresa,
                numero: "NAO TEM NUMERO",
              };
              messages.numero = null;
              setEmpresa(atualizarNumero);
            }
            setTemNumero(e.value);
          }}
          col={2}
          onChange={handleChange}
          disabled={!temNumero}
          name="numero"
          label="Número"
          value={empresa.numero === "NAO TEM NUMERO" ? "" : empresa.numero}
          required={temNumero}
          type="number"
          onBlur={() => {
            if (!temNumero) return;
            messages.numero = isBiggerThanZero(empresa.numero);
            setMessages({ ...messages });
          }}
          invalid={messages.numero}
        /> */}
        <InputText
          required
          onBlur={() => {
            messages.complemento = isRequired(empresa.complemento);
            setMessages({ ...messages });
          }}
          invalid={messages.complemento}
          col={5}
          name="complemento"
          label="Complemento"
          value={empresa.complemento}
          onChange={handleChange}
        />
        <InputText
          readOnly
          col={5}
          name="bairro"
          label="Bairro"
          value={empresa.bairro}
        />
        <InputText
          readOnly
          col={3}
          name="cidade"
          label="Cidade"
          value={empresa.cidade}
        />
        <InputText readOnly col={2} name="uf" label="UF" value={empresa.uf} />
        <InputMask
          col={2}
          name="telefone"
          label="Telefone"
          value={empresa.telefone}
          keyfilter="int"
          unmask
          mask={"(99) 99999-999?9"}
          onChange={handleChange}
        />
        <InputText
          col={6}
          name="inscricaoEstadual"
          label="Inscrição Estadual"
          value={empresa.inscricaoEstadual}
          onChange={handleChange}
        />
        <InputText
          col={6}
          name="email"
          label="Email"
          value={empresa.email}
          type={"email"}
          onChange={handleChange}
        />
        <SelectButton
          label="Acesso ao Portal"
          value={empresa.acessoPortal}
          name="acessoPortal"
          options={optionsAcessoPortalEmpresa}
          onChange={handleChange}
          col={2}
        />
        <SelectButton
          label="Tipo"
          value={empresa.tipoEmpresa}
          name="tipoEmpresa"
          options={optionsTipoEmpresa}
          onChange={handleChange}
          col={3}
        />
      </div>
      <SeletorDeFoto
        uploader={customBase64Uploader}
        value={toSrc(empresa.logotipo)}
        key="logotipo"
        required
        invalid={messages.logotipo}
      />
    </>
  );
}
