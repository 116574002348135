import React, {useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {Button} from "../../components/button";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {EstoqueAutoComplete} from "../../components/autocomplete/EstoqueAutoComplete";
import {SelectEstoquePeca} from "../__Commons/SelectEstoquePeca";
import {resolverSaldo} from "../../service/produtoService";
import {useAuth} from "../../context/AuthContext";

export function ConfirmacaoEstoquePecaCampo({ordemServico, onConfirm}) {

	const {roles} = useAuth();
	const [visible, setVisible] = useState(true);
	const [localOrdemServico, setLocalOrdemServico] = useState(ordemServico);

	function handleClose() {
		setVisible(false);
	}

	function handleChangeItem(i, e) {
		setLocalOrdemServico(prevLocalOrdemServico => {
			if (e.name === "estoqueConsumo") {
				switch (prevLocalOrdemServico.itens[i].pecas[e.index].estoque) {
					case "NOVAS":
						prevLocalOrdemServico.itens[i].pecas[e.index].estoqueConsumoNovas = e.value;
						break;
					case "RECONDICIONADAS":
						prevLocalOrdemServico.itens[i].pecas[e.index].estoqueConsumoRecondicionadas = e.value;
						break;
				}
			} else {
				prevLocalOrdemServico.itens[i].pecas[e.index][e.name] = e.value;
			}
			return {...prevLocalOrdemServico};
		});
	}

	function handleConfirm() {
		localOrdemServico.itens.forEach(osi => osi.pecas.forEach(osip => osip.estoqueConsumoConfirmado = true));
		onConfirm(localOrdemServico);
		handleClose();
	}

	const canConfirm = localOrdemServico.itens?.every(osi => (
		osi.pecas?.every(osip => {
			if (osip.status === "CANCELADO") {
				return true;
			}
			const estoque = resolverEstoque(osip);
			return (
				estoque
				&& (osip.estoque === "RECONDICIONADAS" || resolverSaldo(estoque, osip.produto) >= osip.quantidade)
			);
		})
	));

	const pecas = localOrdemServico.itens?.map((osi, index) => (
		osi.pecas.map(osip => ({
			...osip,
			index,
			equipamento: osi.equipamento
		}))
	)).flat();

	return (
		<Dialog
			header="Confirmação de Consumo de Estoque"
			style={{width: "1100px"}}
			visible={visible}
			onHide={handleClose}
		>
			<PanelContent>
				<div className="col-12">
					<DataTable
						rowClassName={osip => osip.status === "CANCELADO" ? "hidden" : ""}
						value={pecas}
						rows={10}
						paginator
					>
						<Column style={{textAlign: "center"}} header="Status" body={printStatusConsumoPeca}/>
						<Column header="Peça" field="produto.autoComplete"/>
						<Column header="Qtde" field="quantidade"/>
						<Column header="Adicionado Por" field="adicionadoPor.nome"/>
						<Column
							header="Condição" field="estoque" body={(osip, c) => (
							<SelectEstoquePeca
								disabled={osip.status === "CANCELADO" || !roles.OSSY}
								estoqueNovas={true}
								index={c.rowIndex}
								name="estoque"
								value={osip.estoque}
								onChange={e => handleChangeItem(osip.index, e)}
							/>
						)}
						/>
						<Column
							header="Estoque a Consumir" field="estoque" body={(osip, c) => (
							<EstoqueAutoComplete
								disabled={osip.status === "CANCELADO" || !roles.OSSY}
								col={12}
								reduced
								onChange={e => handleChangeItem(osip.index, e)}
								index={c.rowIndex}
								name="estoqueConsumo"
								value={resolverEstoque(osip)}
								label={null}
							/>
						)}
						/>
						<Column header="Saldo" body={printSaldoConsumoPeca}/>
					</DataTable>
				</div>
			</PanelContent>
			<DialogFooter>
				<Button label="Confirmar" icon="fas fa-check" disabled={!canConfirm} success onClick={handleConfirm}/>
				<Button label="Cancelar" icon="fas fa-times" secondary onClick={handleClose}/>
			</DialogFooter>
		</Dialog>
	);

}

export function resolverEstoque(osip, operacao = "CAMPO") {
	switch (operacao) {
		case "LABORATORIO":
			switch (osip.estoque) {
				case "NOVAS":
					return {codigo: 2, autoComplete: "2 - Laboratório (peças novas)"};
				case "RECONDICIONADAS":
					return {codigo: 103, autoComplete: "103 - Peças Recondicionadas"};
				default:
					return null;
			}
		case "CAMPO":
			switch (osip.estoque) {
				case "NOVAS":
					return osip.estoqueConsumoNovas;
				case "RECONDICIONADAS":
					return osip.estoqueConsumoRecondicionadas;
				default:
					return null;
			}
	}
}

function printStatusConsumoPeca(osip) {
	if (osip.status === "CANCELADO") {
		return (
			<i
				className="fas fa-ban"
				style={{color: "#F44336"}}
				title="Peça cancelada"
			/>
		);
	}
	const estoque = resolverEstoque(osip);
	if (estoque && (osip.estoque === "RECONDICIONADAS" || resolverSaldo(estoque, osip.produto) >= osip.quantidade)) {
		return <i className="fas fa-check-circle" style={{color: "#4CAF50"}} title="Saldo disponível"/>;
	}
	return (
		<i
			className="fas fa-times-circle"
			style={{color: "#F44336"}}
			title="Saldo indisponível para o estoque selecionado!"
		/>
	);
}

function printSaldoConsumoPeca(osip) {
	const saldo = resolverSaldo(resolverEstoque(osip), osip.produto);
	return (
		<div style={{textAlign: "right", color: osip.quantidade > saldo ? "#F44336" : "#388E3C"}}>{saldo}</div>
	);
}
