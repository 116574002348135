import React from "react";
import { InputText } from "../../components/inputtext";
import {
  isRequired,
  isRequiredEmail,
  isRequiredNome,
} from "../../utils/fieldValidator";
import { InputMask } from "../../components/inputmask";
import { UsuarioAutoComplete } from "../../components/autocomplete/usuarioAutoComplete";
import { SelectButton } from "../../components/selectbutton";
import { If } from "../../components/conditional/If";

export const EditarEmpresaUsuariosModalDadosPrincipais = ({
  usuario,
  setUsuario,
  handleChange,
  messages,
  setMessages,
}) => {
  const optionsUsuarioTipo = [
    {
      label: (
        <div className="flex w-full justify-content-center align-items-center">
          {/* <i className="fa-solid fa-city mr-2 fa-lg" /> */}
          Operador
        </div>
      ),
      value: "PORTAL_OPERADOR",
    },
    {
      label: (
        <div className="flex w-full justify-content-center align-items-center">
          {/* <i className="fa-solid fa-building mr-2 fa-lg" /> */}
          Gestor
        </div>
      ),
      value: "PORTAL_GESTOR",
    },
  ];
  return (
    <>
      <InputText
        required
        onBlur={() => {
          messages.nome = isRequiredNome(usuario.nome);
          setMessages({ ...messages });
        }}
        invalid={messages.nome}
        col={4}
        name="nome"
        label="Nome"
        value={usuario.nome}
        onChange={handleChange}
      />

      <InputText
        required
        onBlur={() => {
          messages.email = isRequiredEmail(usuario.email);
          setMessages({ ...messages });
        }}
        invalid={messages.email}
        col={4}
        name="email"
        label="E-mail"
        value={usuario.email}
        onChange={handleChange}
      />

      <InputText
        invalid={messages.rg}
        col={2}
        name="rg"
        label="RG"
        value={usuario.rg}
        onChange={handleChange}
      />
      <InputMask
        invalid={messages.cpf}
        col={2}
        name="cpf"
        label="CPF"
        mask={"999-999-999-99"}
        value={usuario.cpf}
        onChange={handleChange}
        required={usuario?.rg?.length < 7}
        onBlur={() => {
          if (usuario?.rg?.length < 7) {
            messages.cpf = isRequired(usuario?.nome);
            setMessages({ ...messages });
          }
        }}
      />
      <InputText
        placeholder={usuario.id ? "********" : ""}
        required
        onBlur={() => {
          messages.senha = usuario.id ? null : isRequired(usuario.senha);
          setMessages({ ...messages });
        }}
        invalid={messages.senha}
        type="password"
        col={2}
        name="senha"
        label="Senha"
        value={usuario.senha}
        onChange={handleChange}
      />
      <InputText
        placeholder={usuario.id ? "********" : ""}
        required
        onBlur={() => {
          messages.senha2 = usuario.id ? null : isRequired(usuario.senha2);
          if (!messages.senha2) {
            if (usuario.senha !== usuario.senha2) {
              messages.senha2 = "As senhas digitadas não conferem";
            } else {
              messages.senha2 = null;
            }
          }
          setMessages({ ...messages });
        }}
        invalid={messages.senha2}
        type="password"
        col={2}
        name="senha2"
        label="Confirmação de senha"
        value={usuario.senha2}
        onChange={handleChange}
      />
      <SelectButton
        label="Tipo"
        value={usuario.tipo}
        name="tipo"
        options={optionsUsuarioTipo}
        onChange={handleChange}
        col={3}
      />
      <InputText
        col={5}
        name="departamento"
        label="Departamento"
        value={usuario.departamento}
        onChange={handleChange}
      />
      <If condition={usuario.tipo === "PORTAL_OPERADOR"}>
        <UsuarioAutoComplete
          multiple
          forceSelection
          col={12}
          name="gestores"
          value={usuario.gestores}
          onChange={handleChange}
          label="Gestores"
          tipo={"PORTAL_GESTOR"}
        />
      </If>
    </>
  );
};
