import React, {useMemo} from "react";
import {colors} from "../../utils/colors";

const numberFormat = Intl.NumberFormat("pt-BR", {minimumFractionDigits: 0, maximumFractionDigits: 0});

export function HorizontalBarChart({col, data, header}) {
	const bars = useMemo(() => (
		data?.map((d, i) => <Bar {...d} index={i}/>)
	), [data]);
	return (
		<div className={`border-1 border-black-alpha-20 border-round-md flex flex-column gap-2 bg-white col-${col || 12}`}>
			<div className="w-full p-2 font-bold p-2 border-bottom-1 border-black-alpha-20">{header}</div>
			{bars}
		</div>
	);
}

function Bar({index, value, label}) {
	return (
		<div className="w-full flex flex-column gap-1">
			<div
				className={`
					p-2
					align-items-center
					flex
					justify-content-center
					border-round-md
					font-bold
					text-white
					text-shadow-1
				`}
				style={{width: `${value}%`, backgroundColor: colors[index % colors.length]}}
			>
				{`${numberFormat.format(value)}%`}
			</div>
			<div className="text-sm">{label}</div>
		</div>
	);
}
