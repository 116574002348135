import React, {useEffect, useMemo, useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {useNavigate} from "react-router-dom";
import {useBreakpoint} from "../../context/BreakpointContext";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {InputNumber} from "../../components/inputnumber";
import {Dropdown} from "../../components/dropdown";
import {useAuth} from "../../context/AuthContext";
import {VendedorAutoComplete} from "../../components/autocomplete/VendedorAutoComplete";
import {formatCurrency} from "../../utils/numberFormatter";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {optionsOperacao, optionsOperacaoSelecionadaTemplate, optionsOperacaoTemplate} from "../../utils/operacao";
import {useQueryClientFaturamento} from "../../utils/query-clients/queryClients";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {BlockUI} from "primereact/blockui";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";

const optionsNumero = [
    {label: "do Faturamento", value: "FAT"},
    {label: "do Pedido de Compra", value: "PedidoCompra"},
    {label: "da OS", value: "OS"},
    {label: "da Nota Fiscal", value: "NF"},
    {label: "do Contrato", value: "Contrato"},
    {label: "do Pedido de Venda", value: "PedidoVenda"}
];

function printTipos(fat) {
    const tipos = [];
    if (fat.retorno) tipos.push( 
        <div className="flex border-black-alpha-10 border-bottom-1	w-full p-2 h-3rem align-items-center">
                <i key={0} style={{color: "#673AB7"}} className="fas fa-big fa-truck mr-3" title="Retorno"/> 
                <strong title="pedido de venda referente ao retorno">
                    {fat.pedidoVendaRetorno}
                </strong>
        </div> 
    );
    if (fat.servico) tipos.push(
        <div className="flex border-black-alpha-10 border-bottom-1	w-full p-2 h-3rem align-items-center">
            
                <i key={1} style={{color: "#E91E63"}} className="fas fa-big fa-wrench mr-3" title="Serviço"/>
                <strong title="pedido de venda referente ao serviço">
                    {fat.pedidoVendaServico}
                </strong>
        </div>
    );
    if (fat.produto) tipos.push(
        <div className="flex border-black-alpha-10 border-bottom-1	w-full p-2 h-3rem align-items-center">
            
            <i key={2} style={{color: "#2196F3"}} className="fas fa-big fa-boxes mr-3" title="Produto"/> 
            <strong title="pedido de venda referente ao produto">
                {fat.pedidoVendaProduto}
            </strong>
        </div>
    );
    return (
        <div 
            className="flex flex-column justify-content-center	align-items-center" 
            onDoubleClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
            }}
        >
            {tipos}
        </div>
    );
}

export function Faturamento() {

    const {roles} = useAuth();
    const navigate = useNavigate();
    const {isLargeDevice} = useBreakpoint();
    const {query, handleSearch} = useQueryClientFaturamento();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.Faturamento);
    const [valorTotalRetorno, setValorTotalRetorno] = useState(formatCurrency(0));
    const [valorTotalServico, setValorTotalServico] = useState(formatCurrency(0));
    const [valorTotalProduto, setValorTotalProduto] = useState(formatCurrency(0));

    useEffect(() => {
        if (query.data) {
            let totalRetorno = 0;
            let totalServico = 0;
            let totalProduto = 0;
            for (let faturamento of query.data) {
                totalRetorno = totalRetorno + faturamento.valorTotalRetorno;
                totalServico =  totalServico + faturamento.valorTotalServico;
                totalProduto = totalProduto + faturamento.valorTotalProduto;
            }
            setValorTotalRetorno(formatCurrency(totalRetorno));
            setValorTotalServico(formatCurrency(totalServico));
            setValorTotalProduto(formatCurrency(totalProduto));
        }
    }, [query.data]);

    function handleCriar() {
        navigate("/faturamentos/composicao");
    }

    function handleEditar(event) {        
        navigate(`/faturamentos/${event.data.id}`);
    }

    const totais = (rowData) => {
        return (
            <div className="flex flex-column justify-content-center	align-items-center">
                {
                    rowData.retorno &&
                    <div className="flex justify-content-between border-black-alpha-10 border-bottom-1	w-full p-2 h-3rem align-items-center">
                        <b>Retorno</b>
                        <div  style={{ textAlign: "right" }}>
                            {formatCurrency(rowData.valorTotalRetorno)}
                        </div>
                    </div>
                }
                {
                    rowData.servico &&
                    <div className="flex justify-content-between border-black-alpha-10 border-bottom-1	w-full p-2 h-3rem align-items-center">
                        <b>Serviço</b>
                        <div  style={{ textAlign: "right" }}>
                            {formatCurrency(rowData.valorTotalServico)}
                        </div>
                    </div>
                }
                {
                    rowData.produto &&
                    <div className="flex justify-content-between border-black-alpha-10 border-bottom-1	w-full p-2 h-3rem align-items-center">
                        <b>Produto</b>
                        <div  style={{ textAlign: "right" }}>
                            {formatCurrency(rowData.valorTotalProduto)}
                        </div>
                    </div>
                }
               
            </div>
        )
    }

    const totaisGeral = () => {
        return (
            <div>
                <div className="flex align-items-center	justify-content-between	">
                    <b>Retorno</b>
                    <div>
                        {formatCurrency(valorTotalRetorno)}
                    </div>
                </div>

                <div className="flex align-items-center	justify-content-between	">
                    <b>Serviço</b>
                    <div>
                        {formatCurrency(valorTotalServico)}
                    </div>
                </div>

                <div className="flex align-items-center	justify-content-between	">
                    <b>Produto</b>
                    <div>
                        {formatCurrency(valorTotalProduto)}
                    </div>
                </div>
            </div>
        )
    }

    let headerGroup = (
	    <ColumnGroup>
		    <Row>
			    <Column header="Total Geral:" colSpan={6} headerStyle={{textAlign: 'right'}}/>
			    <Column header={totaisGeral} headerStyle={{width: "16em"}} />
		    </Row>
		    <Row>
                <Column sortable style={{width: "5em", textAlign: "center"}} field="status" header="Status"/>
                <Column sortable style={{width: "5em", textAlign: "center"}} field="codigo" header="Código"/>
                <Column sortable field="cliente.autoComplete" header="Cliente"/>
                <Column sortable field="vendedor.autoComplete" header="Vendedor"/>
                <Column sortable field="operacao" header="Operação"  />
                <Column header=""/>
                <Column header="Totais" style={{width: "16em"}} body={totais}/>
		    </Row>
	    </ColumnGroup>
    );


    let footerGroup = (
	    <ColumnGroup>
		    <Row>
			    <Column footer="Total Geral:" colSpan={6} footerStyle={{textAlign: 'right'}}/>
			    <Column footer={totaisGeral} footerStyle={{textAlign: 'right', width: "16em"}} />
			    
		    </Row>
	    </ColumnGroup>
    );

    const statusTemplate = (data) => {
        return data.status === 'ABERTO' ? <i title='Aberto' className="fa-solid fa-big fa-folder-open" style={{color: '#FF9800' }}/> : <i style={{color: '#000000' }} title="Fechado" className="fa-solid fa-big fa-folder-closed"/>;
    }


    const optionsStautsFaturamento = [
        {label: "Aberto", value: "ABERTO", color: '#FF9800', icon: "fa-folder-open"},
        {label: "Fechado", value: "FECHADO", icon: "fa-folder-closed", color: '#000000'},
    ]

    const optionsStatusFaturamentoTemplate = ((option) => {
        return (
            <div className="flex align-items-center">
                <i style={{color: `${option.color}`}} className={`fa-lg	 fa-solid ${option.icon} mr-2`}/>
                <div>{option.label}</div>
            </div>
        );
    }); 
    
    const optionsStatusFaturamentoSelecionadoTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <i style={{color: `${option.color}`}} className={`fa-lg	 fa-solid ${option.icon} mr-2`}/>
                    <div>{option.label}</div>
                </div>
            );
        }
    
        return <span>{props.placeholder}</span>;
    };

    const operacaoTemplate = (data) => {
        const option = optionsOperacao.find((operacao) => operacao.value === data.operacao) 

        return (
            <div className="flex align-items-center justify-content-center	">
                <i style={{color: `${option.color}`}} className={`fa-lg	 fa-solid ${option.icon}`} title={option.label}/>
            </div>
        );
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel header="Faturamentos">
                <PanelContent>
                    <InputNumber col={2} label="Número" name="numero" value={params.numero} onChange={handleChange}/>
                    <Dropdown options={optionsNumero} col={2} label="&nbsp;" name="modoNumero" value={params.modoNumero} onChange={handleChange}/>
                    <ClienteAutoComplete col={4} name="cliente" value={params.cliente} onChange={handleChange}/>
                    <VendedorAutoComplete disabled={!roles.FATE} col={4} name="vendedor" value={params.vendedor} onChange={handleChange}/>

                    {/* <Dropdown 
                        col={2} 
                        options={optionsStautsFaturamento} 
                        itemTemplate={optionsStatusFaturamentoTemplate}
                        valueTemplate={optionsStatusFaturamentoSelecionadoTemplate}
                        label="Status" 
                        name="status" 
                        value={params.status} 
                        onChange={handleChange} 
                    /> */}
                     <Dropdown 
                        col={2} 
                        options={[{label: "Todos", value: "TODOS", color: null, icon: null}, ,...optionsOperacao]} 
                        itemTemplate={optionsOperacaoTemplate}
                        valueTemplate={optionsOperacaoSelecionadaTemplate}
                        label="Operação" 
                        name="operacao" 
                        value={params.operacao} 
                        onChange={handleChange} 
                    />
                </PanelContent>
                <PanelFooter>
                    <Button success disabled={query.isLoading} onClick={handleCriar} label={isLargeDevice ? "Novo" : null} icon="pi pi-file" autowidth={!isLargeDevice}/>
                    <Button info disabled={query.isLoading || query.isRefetching} loading={query.isLoading || query.isRefetching} onClick={handleSearch} label={isLargeDevice ? "Procurar" : null} icon="pi pi-search" autowidth={!isLargeDevice}/>
                </PanelFooter>
            </Panel>
            <DataTable onRowDoubleClick={handleEditar} value={useMemo(() => query.data || [], [query.data])} rows={50} headerColumnGroup={headerGroup} footerColumnGroup={footerGroup}>
                <Column sortable style={{width: "5em", textAlign: "center"}} field="status" header="Status" body={statusTemplate}/>
                <Column sortable style={{width: "5em", textAlign: "center"}} field="codigo" header="Código" />
                <Column sortable field="cliente.autoComplete" header="Cliente"/>
                <Column sortable field="vendedor.autoComplete" header="Vendedor"/>
                <Column sortable style={{width: "5em", textAlign: "center"}} field="operacao" header="Operação" body={operacaoTemplate} />
                <Column style={{width: "10em", textAlign: "center", padding: '0'}} header=""  body={printTipos}/>
                <Column header="Totais" style={{width: "16em", padding: '0'}} body={totais}/>
            </DataTable>
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
