import React, {useEffect, useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {Button} from "../../components/button";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {contratoService} from "../../service/contratoService";
import {formatCurrency} from "../../utils/numberFormatter";
import {
    atualizarValores,
    criarAtualizacaoValores,
    iconesElementoCusto,
    origensPreco
} from "../../service/ordemServicoService";
import {RadioButton} from "primereact/radiobutton";
import {If} from "../../components/conditional/If";

function hasAnyAlteracao(alteracaoValores) {
    for (const item of alteracaoValores.itens) {
        for (const produto of item.produtos) {
            if (produto.valorTabelaAnterior !== produto.valorTabelaPosterior) return true;
            if (produto.valorUnitarioAnterior !== produto.valorUnitarioPosterior) return true;
        }
        for (const servico of item.servicos) {
            if (servico.valorTabelaAnterior !== servico.valorTabelaPosterior) return true;
            if (servico.valorUnitarioAnterior !== servico.valorUnitarioPosterior) return true;
        }
    }
    return false;
}

export function TrocarContrato({ordemServico, novoContrato, onModalClose}) {

    const [visible, setVisible] = useState(true);
    const [itens, setItens] = useState([]);

    useEffect(() => {
        contratoService.buscarAtualizacaoValores(criarAtualizacaoValores(ordemServico, novoContrato)).then(atualizacaoValores => {
            if (hasAnyAlteracao(atualizacaoValores)) {
                setItens(
                    ...atualizacaoValores.itens.map(i => [
                        ...i.produtos.map(p => ({
                            tipo: "PRODUTO",
                            ...p,
                            _set: p.valorTabelaAnterior === p.valorTabelaPosterior && p.valorUnitarioAnterior === p.valorUnitarioPosterior ? "POSTERIOR" : null
                        })),
                        ...i.servicos.map(p => ({
                            tipo: "SERVICO",
                            ...p,
                            _set: p.valorTabelaAnterior === p.valorTabelaPosterior && p.valorUnitarioAnterior === p.valorUnitarioPosterior ? "POSTERIOR" : null
                        }))
                    ])
                );
            } else {
                ordemServico.contrato = novoContrato;
                if (onModalClose) {
                    onModalClose({...ordemServico});
                }
                handleClose();
            }
        });
    }, []);

    async function handleSave() {
        atualizarValores(ordemServico, itens);
        ordemServico.contrato = novoContrato;
        if (onModalClose) {
            onModalClose({...ordemServico});
        }
        handleClose();
    }

    function handleClose() {
        setVisible(false);
    }

    function changeValor(i, value) {
        setItens(prevItens => {
            prevItens[i]._set = value;
            return [...prevItens];
        });
    }

    function changeValores(value) {
        setItens(prevItens => {
            prevItens.forEach(i => i._set = value);
            return [...prevItens];
        });
    }

    const columnGroup = (
        <ColumnGroup>
            <Row>
                <Column style={{width: "3em"}} rowSpan={2} header="" field="tipo"/>
                <Column rowSpan={2} header="Item" field="produto"/>
                <Column style={{textAlign: "center"}} colSpan={2} header="Valores" field="valores"/>
            </Row>
            <Row>
                <Column style={{width: "22em"}} header={(
                    <div>
                        <RadioButton inputId="old-contract" style={{margin: "0 .5em 0 .125em"}} checked={itens.every(i => i._set === "ANTERIOR")} onChange={() => changeValores("ANTERIOR")}/>
                        <label style={{lineHeight: "22px"}} htmlFor="old-contract">{ordemServico.contrato ? `Contrato nº ${ordemServico.contrato.numero}` : "Avulso"}</label>
                    </div>
                )} field="valores"/>
                <Column style={{width: "22em"}} header={(
                    <div>
                        <RadioButton inputId="new-contract" style={{margin: "0 .5em 0 .125em"}} checked={itens.every(i => i._set === "POSTERIOR")} onChange={() => changeValores("POSTERIOR")}/>
                        <label style={{lineHeight: "22px"}} htmlFor="new-contract">{novoContrato ? `Contrato nº ${novoContrato.numero}` : "Avulso"}</label>
                    </div>
                )} field="valores"/>
            </Row>
        </ColumnGroup>
    );

    return (
        <Dialog header="Troca de Valores da Ordem de Serviço" style={{width: "980px"}} visible={visible} onHide={handleClose}>
            <PanelContent>
                <div style={{margin: "0 .5em"}}>
                    <If condition={ !itens?.length}>
                        Aguarde enquanto o Services confere se houve alteração nos preços...
                    </If>
                    <If condition={!!itens?.length}>
                        A alteração de componentes da ordem de serviço (peças, serviços, mau uso, etc.) ou do contrato, causam alteração nos valores de peças e serviços.<br/>
                        Por favor, confira os valores dos itens abaixo (em especial os com uma seta vermelha ao lado) e selecione qual deseja manter.
                    </If>
                </div>
                <If condition={!!itens?.length}>
                    <div className="col-12">
                        <DataTable rows={5} value={itens} headerColumnGroup={columnGroup}>
                            <Column style={{textAlign: "center"}} field="tipo" body={i => iconesElementoCusto[i.tipo]}/>
                            <Column field="produto" body={i => i.autoComplete}/>
                            <Column field="valoresAnteriores" body={(i, c) => (
                                <table className="tabela-itens-valores">
                                    <tbody>
                                    <tr>
                                        <td rowSpan={4}><RadioButton checked={i._set === "ANTERIOR"} onChange={() => changeValor(c.rowIndex, "ANTERIOR")}/></td>
                                    </tr>
                                    <tr>
                                        <td>Valor Tabela:</td>
                                        <td style={{textAlign: "right"}}>{formatCurrency(i.valorTabelaAnterior)}</td>
                                    </tr>
                                    <tr>
                                        <td>Valor Unitário:</td>
                                        <td style={{textAlign: "right"}}>{formatCurrency(i.valorUnitarioAnterior)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <div style={{margin: ".25em 0", borderTop: "1px solid rgba(0, 0, 0, .2)"}}/>
                                            {origensPreco[i.origemPrecoAnterior]}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            )}/>
                            <Column field="valoresPosteriores" body={(i, c) => (
                                <table className="tabela-itens-valores">
                                    <tbody>
                                    <tr>
                                        <td rowSpan={4}><RadioButton checked={i._set === "POSTERIOR"} onChange={() => changeValor(c.rowIndex, "POSTERIOR")}/></td>
                                    </tr>
                                    <tr>
                                        <td>Valor Tabela:</td>
                                        <td style={{textAlign: "right"}}>
                                            {formatCurrency(i.valorTabelaPosterior)}
                                            <i className="fas fa-long-arrow-left" style={{marginLeft: ".5em", color: i.valorTabelaAnterior !== i.valorTabelaPosterior ? "#F44336" : "transparent"}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Valor Unitário:</td>
                                        <td style={{textAlign: "right"}}>
                                            {formatCurrency(i.valorUnitarioPosterior)}
                                            <i className="fas fa-long-arrow-left" style={{marginLeft: ".5em", color: i.valorUnitarioAnterior !== i.valorUnitarioPosterior ? "#F44336" : "transparent"}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <div style={{margin: ".25em 0", borderTop: "1px solid rgba(0, 0, 0, .2)"}}/>
                                            {origensPreco[i.origemPrecoPosterior]}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            )}/>
                        </DataTable>
                    </div>
                </If>
            </PanelContent>
            <DialogFooter>
                <Button onClick={handleSave} disabled={!itens.length || itens.some(i => !i._set)} success label="Confirmar" icon="fas fa-save"/>
                <Button onClick={handleClose} disabled={!itens.length} secondary label="Cancelar" icon="fas fa-times"/>
            </DialogFooter>
        </Dialog>
    );

}
