import React from 'react';

import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";

export function RequireAuth() {
	let location = useLocation();
	const {token} = useAuth()
	if (!token) {
		return <Navigate to="/" state={{from: location}}/>;
	}
	return <Outlet/>
}