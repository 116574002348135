import React from "react";
import {useQueryParams} from "../../../utils/QueryParamContext";
import {InputText} from "../../../components/inputtext";

export function FiltrosOrdemServicoAvaliacaoQuestao() {
	const {params, handleChange} = useQueryParams("QuestaoAvaliacao");
	return (
		<InputText col={12} name="questao" value={params.questao} onChange={handleChange} label="Questão"/>
	);
}
