import React, {useEffect, useMemo, useState} from "react";

import {Panel} from "primereact/panel";
import moment from "moment";
import {headerTemplate} from "../../utils/templates";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {relatorioOrdemServicoEtapaService} from "../../service/RelatorioOrdemServicoEtapaService";
import {formatCurrency, formatPercentage} from "../../utils/numberFormatter";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Calendar} from "../../components/calendar";
import {Button} from "../../components/button";
import {BlockUI} from "primereact/blockui";

function orderEtapas(a) {
    switch (a.etapaDescricao) {
        case "Recepção": return 0;
        case "Laudo": return 1;
        case "Manutenção": return 2;
        case "Limpeza": return 3;
        case "Teste": return 4;
        case "Expedição": return 5;
        default: return 6;
    }
}

function sortsEtapas(a, b) {
    return orderEtapas(a) - orderEtapas(b);
}

export function RelatorioOrdemServicoEtapa() {

    const [valorDia, setValorDia] = useState([]);
    const [loading, setLoading] = useState(false);
    const [evolucoesDiarias, setEvolucoesDiarias] = useState([]);
    const [params, setParams] = useState({
        inicio: moment().subtract(1, "week").format("YYYY-MM-DD"),
        fim: moment().format("YYYY-MM-DD")
    });

    async function handleProcurar() {
        setLoading(true);
        setValorDia((await relatorioOrdemServicoEtapaService.listar()).sort(sortsEtapas));
        relatorioOrdemServicoEtapaService.listarRelatorioEvolucaoOrdensServico(params).then(evolucoesDiarias => {
            const ed = {};
            for (const evolucaoDiaria of evolucoesDiarias) {
                if (!ed[evolucaoDiaria.etapaDescricao]) {
                    ed[evolucaoDiaria.etapaDescricao] = {
                        etapaDescricao: evolucaoDiaria.etapaDescricao
                    };
                }
                ed[evolucaoDiaria.etapaDescricao][evolucaoDiaria.data] = {
                    quantidade: evolucaoDiaria.quantidade,
                    valor: evolucaoDiaria.valor
                }
            }
            setEvolucoesDiarias(Object.values(ed).sort(sortsEtapas));
            setLoading(false);
        });
    }

    useEffect(handleProcurar, []);

    const dias = [];
    const qtdeDias = moment(params.fim).diff(params.inicio, "day");
    for (let i = 0; i <= qtdeDias; ++i) {
        dias.push(moment(params.inicio).add(i, "day").format("YYYY-MM-DD"));
    }

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column sortable field="etapaDescricao" style={{textAlign: "center"}} header="Etapa"/>
                <Column sortable field="quantidade" style={{textAlign: "center"}} header="Quantidade"/>
                <Column sortable field="quantidade" style={{textAlign: "center"}} header="%"/>
                <Column sortable field="valor" style={{textAlign: "center"}} header="Valor"/>
                <Column sortable field="valor" style={{textAlign: "center"}} header="%"/>
            </Row>
        </ColumnGroup>
    );

    const footerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column/>
                <Column footer={<div style={{textAlign: "right"}}>{valorDia.map(dia => dia.quantidade).reduce((a, b) => a + b, 0)}</div>}/>
                <Column/>
                <Column footer={<div style={{textAlign: "right"}}>{formatCurrency(valorDia.map(dia => dia.valor).reduce((a, b) => a + b, 0))}</div>}/>
                <Column/>
            </Row>
        </ColumnGroup>
    );

    const tabelaEvolucao = useMemo(() => {
        const columnGroup = [];
        const columns = [];
        for (const dia of dias) {
            columnGroup.push(<Column sortable key={`${dia}-1`} style={{textAlign: "center", width: "8em"}} field={`${dia}.quantidade`} header="Quantidade"/>);
            columnGroup.push(<Column sortable key={`${dia}-2`} style={{textAlign: "center", width: "10em"}} field={`${dia}.valor`} header="Valor"/>);
            columns.push(<Column key={`${dia}-1`} style={{textAlign: "right", width: "8em"}} field="quantidade" body={ed => ed[dia]?.quantidade || 0}/>);
            columns.push(<Column key={`${dia}-2`} style={{textAlign: "right", width: "10em"}} field="valor" body={ed => formatCurrency(ed[dia]?.valor || 0)}/>);
        }
        const headerEvolucao = (
            <ColumnGroup>
                <Row>
                    <Column sortable rowSpan={2} field="etapaDescricao" style={{textAlign: "center"}} header="Etapa"/>
                    {dias.map(d => <Column key={d} colSpan={2} field="" style={{textAlign: "center"}} header={moment(d).format("DD/MM")}/>)}
                </Row>
                <Row>
                    {columnGroup}
                </Row>
            </ColumnGroup>
        );
        return (
            <DataTable paginator={false} footer={null} headerColumnGroup={headerEvolucao} value={evolucoesDiarias} valuelength={valorDia?.length}>
                <Column field="etapaDescricao"/>
                {columns}
            </DataTable>
        );
    }, [evolucoesDiarias]);

    function handleChange(event) {
        setParams(prevParams => ({...prevParams, [event.name]: event.value}));
    }

    return (
        <BlockUI blocked={loading}>
            <Panel headerTemplate={() => headerTemplate('Ordem de Serviço (Laboratório) - Etapa', 'fa-file-lines')} >
                <PanelContent>
                    <div className="col-12">
                        <DataTable paginator={false} footer={null} headerColumnGroup={headerColumnGroup} footerColumnGroup={footerColumnGroup} value={valorDia} valuelength={valorDia?.length}>
                            <Column frozen field="etapaDescricao"/>
                            <Column style={{textAlign: "right"}} field="quantidade"/>
                            <Column style={{textAlign: "right"}} field="quantidade" body={perc => formatPercentage(perc.quantidade *100 / valorDia.map(dia => dia.quantidade).reduce((a, b) => a + b, 0)) }/>
                            <Column style={{textAlign: "right"}} field="valor" body={dia => formatCurrency(dia.valor)}/>
                            <Column style={{textAlign: "right"}} field="valor" body={perc => formatPercentage(perc.valor * 100 / valorDia.map(dia => dia.valor).reduce((a, b) => a + b, 0))}/>
                        </DataTable>
                    </div>
                </PanelContent>
            </Panel>
            <div style={{height: "1em"}}/>
            <Panel headerTemplate={() => headerTemplate("Ordem de Serviço (Laboratório) - Evolução", "fa-file-lines")}>
                <PanelContent>
                    <Calendar label="Período" name="inicio" value={params.inicio} onChange={handleChange} col={2}/>
                    <Calendar label="&nbsp;" name="fim" value={params.fim} onChange={handleChange} col={2}/>
                </PanelContent>
                <PanelFooter>
                    <Button icon="fas fa-refresh" label="Atualizar" onClick={handleProcurar}/>
                </PanelFooter>
            </Panel>
            <div style={{overflowX: "scroll"}}>
                {tabelaEvolucao}
            </div>
        </BlockUI>
    );

}
