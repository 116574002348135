import React from 'react';
import { AppConfiguracoesProvider } from './AppConfiguracoesContext';
import { AuthProvider } from './AuthContext';
import { BreakpointProvider } from './BreakpointContext';

const AppProvider = ({ children }) => {
  return (
    <>
      <BreakpointProvider>
        <AppConfiguracoesProvider>
          <AuthProvider>
            {children}
          </AuthProvider> 
        </AppConfiguracoesProvider>
      </BreakpointProvider>
    </>
  );
};

export default AppProvider;
