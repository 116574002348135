import React, {useState} from "react";
import {withDialog} from "../../utils/dialogContext";
import {Dropdown} from "../../components/dropdown";
import {InputCurrency, InputNumber} from "../../components/inputnumber";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {ServicoAutoComplete} from "../../components/autocomplete/servicoAutoComplete";
import {EquipamentoAutoComplete} from "../../components/autocomplete/equipamentoAutoComplete";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Button} from "../../components/button";
import {contratoService, optionsModoPeriodicidade} from "../../service/contratoService";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {isBiggerThanZero, isEntityRequired, isRequired} from "../../utils/fieldValidator";
import {CadastrarEquipamentosLote} from "./CadastrarEquipamentosLote";

const headersTipo = {
	produtos: "Produto",
	servicos: "Serviço",
	equipamentos: "Equipamento"
};

function emptyParams() {
	return {
		modoPeriodicidade: null,
		periodicidade: null,
		quantidade: null,
		quantidadeMauUso: null,
		quantidadeEquipamento: null,
		valorCobertura: null,
		valorExcedente: null
	};
}

export const EditarContratoCobertura = withDialog(({showDialog, messages, setMessages, contrato, setContrato, tipo}) => {

	const [itensSelecionados, setItensSelecionados] = useState([]);
	const [params, setParams] = useState(emptyParams());

	function adicionarItem() {
		contrato[tipo].unshift(contratoService.criarItem());
		setContrato({...contrato});
	}

	function removerItens() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os itens selecionados?" onYes={() => {
			contrato[tipo] = contrato[tipo].filter(osi => !itensSelecionados.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
			setContrato({...contrato});
			setItensSelecionados([]);
		}}/>);
	}

	function handleChange(event) {
		contrato[tipo][event.index][event.name] = event.value;
		setContrato({...contrato});
	}

	function handleChangeParams(event) {
		setParams({...params, [event.name]: event.value});
		for (const item of contrato[tipo]) {
			item[event.name] = event.value;
		}
		setContrato({...contrato});
	}

	function onBlurParams() {
		setParams(emptyParams());
	}

	const controlSet = (
		<div>
			<Button icon="pi pi-plus" onClick={adicionarItem}/>
			<Button disabled={!itensSelecionados?.length} onClick={removerItens} danger icon="pi pi-minus"/>
		</div>
	);

	function handleCadastrarEquipamentosLote() {
		showDialog(<CadastrarEquipamentosLote onModalClose={equipamentos => {
			console.log(equipamentos);
			if (equipamentos?.length) {
				if (!contrato.equipamentos) {
					contrato.equipamentos = [];
				}
				contrato.equipamentos = contrato.equipamentos.filter(e => !equipamentos.some(x => x.id === e.id));
				for (const equipamento of equipamentos) {
					contrato.equipamentos.unshift({equipamento});
				}
				setContrato({...contrato});
			}
		}}/>);
	}

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={9} header={controlSet}/>
			</Row>
			<Row>
				<Column rowSpan={3} selectionMode="multiple" style={{width: "3em"}}/>
				<Column rowSpan={3} header={headersTipo[tipo]}/>
				<Column rowSpan={2} colSpan={2} header={<div style={{textAlign: "center"}}>Periodicidade</div>}/>
				<Column colSpan={3} header={<div style={{textAlign: "center"}}>Limites</div>}/>
				<Column colSpan={2} header={<div style={{textAlign: "center"}}>Valores</div>}/>
			</Row>
			<Row>
				<Column header="Total"/>
				<Column header={<span>Por<br/>Mau Uso</span>}/>
				<Column header={<span>Por<br/>Equipamento</span>}/>
				<Column header="Cobertura"/>
				<Column header="Excedente"/>
			</Row>
			<Row>
				<Column style={{width: "8em"}} header={<InputNumber reduced onBlur={onBlurParams} name="periodicidade" value={params.periodicidade} onChange={handleChangeParams}/>}/>
				<Column style={{width: "15em"}} header={<Dropdown reduced onBlur={onBlurParams} options={optionsModoPeriodicidade} name="modoPeriodicidade" value={params.modoPeriodicidade} onChange={handleChangeParams}/>}/>
				<Column style={{width: "8em"}} header={<InputNumber reduced onBlur={onBlurParams} name="quantidade" value={params.quantidade} onChange={handleChangeParams}/>}/>
				<Column style={{width: "8em"}} header={<InputNumber reduced onBlur={onBlurParams} name="quantidadeMauUso" value={params.quantidadeMauUso} onChange={handleChangeParams}/>}/>
				<Column style={{width: "8em"}} header={<InputNumber reduced onBlur={onBlurParams} name="quantidadeEquipamento" value={params.quantidadeEquipamento} onChange={handleChangeParams}/>}/>
				<Column style={{width: "13em"}} header={<InputCurrency reduced onBlur={onBlurParams} name="valorCobertura" value={params.valorCobertura} onChange={handleChangeParams}/>}/>
				<Column style={{width: "13em"}} header={<InputCurrency reduced onBlur={onBlurParams} name="valorExcedente" value={params.valorExcedente} onChange={handleChangeParams}/>}/>
			</Row>
		</ColumnGroup>
	);

	const footerGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={11} footer={controlSet}/>
			</Row>
		</ColumnGroup>
	);

	function validarItens() {


		if(tipo === 'produtos'){
			messages[tipo] = [...Array(contrato[tipo].length)].map((_, i) => ({
				periodicidade: contrato[tipo][i].modoPeriodicidade === "CONTRATO" ? null : isBiggerThanZero(contrato[tipo][i].periodicidade, "Obrigatório"),
				modoPeriodicidade: isRequired(contrato[tipo][i].modoPeriodicidade),
				quantidade: isBiggerThanZero(contrato[tipo][i].quantidade, "Obrigatório"),
				valorCobertura: contrato[tipo][i].valorCobertura != null ? null : "Obrigatório",
				produto: isEntityRequired(contrato[tipo][i].produto),
			}));
		}
		if(tipo === 'servicos'){
			messages[tipo] = [...Array(contrato[tipo].length)].map((_, i) => ({
				periodicidade: contrato[tipo][i].modoPeriodicidade === "CONTRATO" ? null : isBiggerThanZero(contrato[tipo][i].periodicidade, "Obrigatório"),
				modoPeriodicidade: isRequired(contrato[tipo][i].modoPeriodicidade),
				quantidade: isBiggerThanZero(contrato[tipo][i].quantidade, "Obrigatório"),
				valorCobertura: contrato[tipo][i].valorCobertura != null ? null : "Obrigatório",
				servico: isEntityRequired(contrato[tipo][i].servico),
			}));
		}
		
		if(tipo === 'equipamentos'){
			console.log('entrou aqui')
			messages[tipo] = [...Array(contrato[tipo].length)].map((_, i) => ({
				periodicidade: contrato[tipo][i].modoPeriodicidade === "CONTRATO" ? null : isBiggerThanZero(contrato[tipo][i].periodicidade, "Obrigatório"),
				modoPeriodicidade: isRequired(contrato[tipo][i].modoPeriodicidade),
				quantidade: isBiggerThanZero(contrato[tipo][i].quantidade, "Obrigatório"),
				valorCobertura: contrato[tipo][i].valorCobertura != null ? null : "Obrigatório",
				equipamento: isEntityRequired(contrato[tipo][i].equipamento),
			}));
		}
		setMessages({...messages});
	}

	return (
		<div className="col-12">
			<DataTable selectionMode="checkbox"
			           selection={itensSelecionados}
			           onSelectionChange={e => setItensSelecionados(e.value)}
			           footer={null}
			           headerColumnGroup={headerGroup}
			           footerColumnGroup={footerGroup}
			           value={contrato[tipo]}
			>
				<Column selectionMode="multiple"/>
				<Column style={{flexDirection: "column", alignItems: "start"}} field="tipo" header="" body={(cc, c) => {
					switch (tipo) {
						case "produtos":
							return <ProdutoAutoComplete invalid={messages[tipo]?.[c.rowIndex]?.produto} col={12} index={c.rowIndex} name="produto" onBlur={validarItens} value={cc.produto} onChange={handleChange} label={null}/>;
						case "servicos":
							return <ServicoAutoComplete invalid={messages[tipo]?.[c.rowIndex]?.servico} col={12} index={c.rowIndex} name="servico" onBlur={validarItens} value={cc.servico} onChange={handleChange} label={null}/>;
						case "equipamentos":
							return <EquipamentoAutoComplete invalid={messages[tipo]?.[c.rowIndex]?.equipamento} col={12} index={c.rowIndex} name="equipamento" onBlur={validarItens} value={cc.equipamento} onChange={handleChange} label={null}/>;
						default:
							return null;
					}
				}}/>
				<Column style={{flexDirection: "column", alignItems: "start", width: "8em"}} field="periodicidade" header="&nbsp;" body={(cc, c) => <InputNumber invalid={messages[tipo]?.[c.rowIndex]?.periodicidade} onBlur={validarItens} disabled={cc.modoPeriodicidade === "CONTRATO"} label={null} value={cc.modoPeriodicidade === "CONTRATO" ? "" : cc.periodicidade} index={c.rowIndex} name="periodicidade" onChange={handleChange}/>}/>
				<Column style={{flexDirection: "column", alignItems: "start"}} field="modoPeriodicidade" header="Periodicidade" body={(cc, c) => <Dropdown invalid={messages[tipo]?.[c.rowIndex]?.modoPeriodicidade} onBlur={validarItens} options={optionsModoPeriodicidade} label={null} value={cc.modoPeriodicidade} index={c.rowIndex} name="modoPeriodicidade" onChange={handleChange}/>}/>
				<Column style={{flexDirection: "column", alignItems: "start"}} field="quantidade" header="Total" body={(cc, c) => <InputNumber invalid={messages[tipo]?.[c.rowIndex]?.quantidade} onBlur={validarItens} label={null} value={cc.quantidade} index={c.rowIndex} name="quantidade" onChange={handleChange}/>}/>
				<Column style={{flexDirection: "column", alignItems: "start"}} field="quantidadeMauUso" header="Por Mau Uso" body={(cc, c) => <InputNumber invalid={messages[tipo]?.[c.rowIndex]?.quantidadeMauUso} onBlur={validarItens} label={null} value={cc.quantidadeMauUso} index={c.rowIndex} name="quantidadeMauUso" onChange={handleChange}/>}/>
				<Column style={{flexDirection: "column", alignItems: "start"}} field="quantidadeMauUso" header="Por Equipamento" body={(cc, c) => <InputNumber invalid={messages[tipo]?.[c.rowIndex]?.quantidadeEquipamento} onBlur={validarItens} label={null} value={cc.quantidadeEquipamento} index={c.rowIndex} name="quantidadeEquipamento" onChange={handleChange}/>}/>
				<Column style={{flexDirection: "column", alignItems: "start"}} field="valorCobertura" header="Valor Cobertura" body={(cc, c) => <InputCurrency invalid={messages[tipo]?.[c.rowIndex]?.valorCobertura} onBlur={validarItens} label={null} value={cc.valorCobertura} index={c.rowIndex} name="valorCobertura" onChange={handleChange}/>}/>
				<Column style={{flexDirection: "column", alignItems: "start"}} field="valorExcedente" header="Valor Excedente" body={(cc, c) => <InputCurrency invalid={messages[tipo]?.[c.rowIndex]?.valorExcedente} onBlur={validarItens} label={null} value={cc.valorExcedente} index={c.rowIndex} name="valorExcedente" onChange={handleChange}/>}/>
			</DataTable>
		</div>
	);

});
