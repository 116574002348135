import React from "react";
import {SelectButton} from 'primereact/selectbutton';
import {calcularTamanho} from "../../service/guiHelper";

export function SelectButtonLabel({label, options, optionLabel, value, onChange, ...props}) {
    
    function handleChange(event) {
        if (onChange) {
            onChange({name: props.name, value: event.target.value, index: props.index});
        }
    }
    const classes = calcularTamanho(props.col);

    return (
            <div className={classes}>
                <label>{label}</label>
                <SelectButton {...props} optionLabel={optionLabel} value={value} onChange={handleChange} options={options}/>
            </div>
    )
}
