import React from "react";

import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { ColumnGroup } from "primereact/columngroup";

import { DataTable } from "../../../../components/datatable";
import {  dataTemplate, documentoTemplate, icones, percentualComissaoTemplate, razaoSocialTemplate,  valorBaseCalculoComissao, valorComissao, valorFaturamento, valorNotaFiscalEditavelTemplate, valorNotaFiscalTemplate } from "./Templates";
import { formatarParaReal, formatarString, ordemServicoRowClassName } from "./utils";
import { Ripple } from "primereact/ripple";
import { Panel } from "primereact/panel";

export const TabelaEditarFechamentoExpandida = React.memo(({data, handleChange, todosTiposComissaoCompletos, loadingRow}) => {
    const itemHeader = (tipo) => {
        switch (tipo) {
            case "UNICA":
                return (
                    <ColumnGroup>
                        <Row>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="dataEmissao" header="Data de Emissão"/>
                            <Column style={{width: "10em"}} field="notaFiscalNumero" header="Documento" />
                            <Column sortable field="cliente.id" header="Cliente" />
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="valorFaturamento" header="Valor Faturamento"/>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="valorBaseCalculoComissao" header="Valor Base Cálculo Comissão"/>
                            <Column style={{textAlign: "center", width: "4em"}} header="% Comissão"/>
                            <Column style={{textAlign: "center", width: "5em"}} header="Comissão"/>
                            <Column style={{textAlign: "center", width: "5em"}}/>
                        </Row>
                    </ColumnGroup>
                );
            case "FAIXA_INDIVIDUAL":
                return (
                    <ColumnGroup>
                        <Row>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="dataEmissao" header="Data de Emissão"/>
                            <Column style={{width: "12em"}} field="notaFiscalNumero" header="Documento" />
                            <Column sortable field="cliente.id" header="Cliente" />
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="valorFaturamento" header="Valor Faturamento"/>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="valorBaseCalculoComissao" header="Valor Base Cálculo Comissão"/>
                            <Column style={{textAlign: "center", width: "4em"}} header="% Comissão"/>
                            <Column style={{textAlign: "center", width: "5em"}} header="Comissão"/>
                            <Column style={{textAlign: "center", width: "5em"}}/>
                        </Row>
                    </ColumnGroup>
                );
            default:
                return (
                    <ColumnGroup>
                        <Row>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="dataEmissao" header="Data de Emissão"/>
                            <Column style={{width: "12em"}} field="notaFiscalNumero" header="Documento"/>
                            <Column sortable field="cliente.id" header="Cliente"/>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="valorFaturamento" header="Valor Faturamento"/>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="valorBaseCalculoComissao" header="Valor Base Cálculo Comissão"/>
                            <Column style={{textAlign: "center", width: "5em"}}/>
                        </Row>
                    </ColumnGroup>
                );
            }
    }

    const itemFooter = (
        tipo, 
        somaValorFaturadoGeral, 
        somaValorBaseCalculoComissaGeral, 
        somaValorBaseCalculoComissaoAprovado, 
        comissaoTotalGeral,
        comissaoTotalAprovada, 
        faixaComtemplada, 
        somaFaixasAtingidas
    ) => {
        switch (tipo) {
            case 'UNICA':
                return (
                    <ColumnGroup>
                        <Row>
                            <Column colSpan={3}/>
                            <Column footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                         {formatarParaReal(somaValorFaturadoGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                        {formatarParaReal(somaValorBaseCalculoComissaGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column   />
                            <Column footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                        {formatarParaReal(comissaoTotalGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column  colSpan={3} />
                        </Row>
                        {
                            comissaoTotalAprovada > 0 &&
                            <Row>
                                <Column 
                                    colSpan={1} 
                                    footer={
                                        <div className="flex justify-content-center" >
                                            <strong className="">
                                                <Tooltip target=".custom-target-icon" />
                                                <i 
                                                    className="custom-target-icon fa-solid fa-circle-question mr-2"
                                                    data-pr-tooltip={'Valor aprovado é a soma dos valores faturados que foram aprovados pelo supervisor comercial para compor o cálculo das comissões'}
                                                    data-pr-at="left-0 bottom"
                                                    data-pr-my="center top-0"
                                                    style={{ fontSize: '1.4rem', cursor: 'pointer', top: '-0.5rem'}}
                                                />
                                                Aprovado 
                                            </strong>
                                        </div>
                                    }
                                    className="background-comissao cinza border-900 border-2 "
                                />
                                <Column className="background-comissao cinza border-900 border-2" colSpan={1} footer={
                                    <div className="flex justify-content-end"> 
                                        <strong className=""> 
                                            {`${formatarParaReal(somaValorBaseCalculoComissaoAprovado)}` }
                                        </strong>
                                    </div>
                                }/>
                                <Column  colSpan={8} />
                            </Row>
                        }
                        {  
                            comissaoTotalAprovada > 0 &&
                            <Row>
                                <Column colSpan={1} className="border-900 border-2 background-comissao azul" footer={
                                    <div className="flex justify-content-center"> 
                                        <strong className="text-white ">
                                            {`Total a receber`}
                                        </strong>
                                    </div>
                                }/>
                                <Column colSpan={1} className="border-900 border-2 background-comissao verde" footer={
                                    <div className="flex  justify-content-end"> 
                                        <strong className="">
                                            {`${formatarParaReal(comissaoTotalAprovada)}`}
                                        </strong>
                                    </div>
                                }/>
                                <Column  colSpan={8} />
                            </Row>
                        }
                    </ColumnGroup>
                );
            case 'FAIXA_INDIVIDUAL':
                return (
                    <ColumnGroup>
                        <Row>
                            <Column colSpan={3}/>
                            <Column footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                         {formatarParaReal(somaValorFaturadoGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                        {formatarParaReal(somaValorBaseCalculoComissaGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column   />
                            <Column footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                        {formatarParaReal(comissaoTotalGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column  colSpan={3} />
                        </Row>
                        {
                            somaFaixasAtingidas.length > 0 && 
                            somaFaixasAtingidas.map((item, index) => {
                                return (
                                    <Row>
                                        <Column 
                                            colSpan={1} 
                                            footer={
                                                <div className="flex" >
                                                    <strong>
                                                        {`${item?.percentual}% ${item?.acima ? 'acima de' : 'até'} ${formatarParaReal(item?.valorFaixa)}`}
                                                    </strong>
                                                </div>
                                            }
                                            className="border-900 border-2 "
                                        />
                                        <Column 
                                            colSpan={1} 
                                            footer={
                                                <div className="flex justify-content-end" >
                                                    <strong>
                                                        {`${formatarParaReal(item?.valorComissao)}`}
                                                    </strong>
                                                </div>
                                            }
                                            className="border-900 border-2 background-comissao verde"
                                        />
                                        <Column  colSpan={8} />
                                    </Row>
                                )
                            })
                        }
                        {  
                            comissaoTotalAprovada > 0 &&
                            <Row>
                                <Column colSpan={1} className="border-900 border-2 background-comissao azul" footer={
                                    <div className="flex justify-content-center"> 
                                        <strong className="text-white ">
                                            {`Total a receber`}
                                        </strong>
                                    </div>
                                }/>
                                <Column colSpan={1} className="border-900 border-2 background-comissao verde" footer={
                                    <div className="flex  justify-content-end"> 
                                        <strong className="">
                                            {`${formatarParaReal(comissaoTotalAprovada)}`}
                                        </strong>
                                    </div>
                                }/>
                                <Column  colSpan={8} />
                            </Row>
                        }
                    </ColumnGroup>
                );
            case 'FAIXA':
            default:
                return (
                    <ColumnGroup>
                        <Row>
                            <Column colSpan={3}/>
                            <Column colSpan={1} footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                        {formatarParaReal(somaValorFaturadoGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column colSpan={1} footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                        {formatarParaReal(somaValorBaseCalculoComissaGeral)}
                                    </strong>
                                </div>
                            }/>

                            <Column  colSpan={3} />
                        </Row>
                        {
                            comissaoTotalAprovada > 0 &&
                            <Row>
                                <Column 
                                    colSpan={1} 
                                    footer={
                                        <div className="flex justify-content-center" >
                                            <strong className="">
                                                <Tooltip target=".custom-target-icon" />
                                                <i 
                                                    className="custom-target-icon fa-solid fa-circle-question mr-2"
                                                    data-pr-tooltip={'Valor aprovado é a soma dos valores faturados que foram aprovados pelo supervisor comercial para compor o cálculo das comissões'}
                                                    data-pr-at="left-0 bottom"
                                                    data-pr-my="center top-0"
                                                    style={{ fontSize: '1.4rem', cursor: 'pointer', top: '-0.5rem'}}
                                                />
                                                Aprovado 
                                            </strong>
                                        </div>
                                    }
                                    className="background-comissao cinza border-900 border-2 "
                                />
                                <Column className="background-comissao cinza border-900 border-2" colSpan={1} footer={
                                    <div className="flex justify-content-end"> 
                                        <strong className=""> 
                                            {`${formatarParaReal(somaValorBaseCalculoComissaoAprovado)}`}
                                        </strong>
                                    </div>
                                }/>
                                <Column  colSpan={6} />
                            </Row>
                        }
                        {
                            comissaoTotalAprovada > 0 &&
                            <Row>
                                <Column 
                                    colSpan={1} 
                                    footer={
                                        <div className="flex justify-content-center" >
                                            <strong className="text-white ">
                                                {`Faixa contemplada`}
                                            </strong>
                                        </div>
                                    }
                                    className="border-900 border-2 background-comissao azul"
                                />
                                <Column 
                                    colSpan={1} 
                                    footer={
                                        <div className="flex justify-content-center" >
                                            <strong className="text-white ">
                                                {`Comissão`}
                                            </strong>
                                        </div>
                                    }
                                    className="background-comissao azul border-900 border-2 "
                                />
                                <Column  colSpan={6} />
                            </Row>
                        }
                        {
                            comissaoTotalAprovada > 0 &&
                            <Row>
                                <Column 
                                    colSpan={1} 
                                    footer={
                                        <div className="flex" >
                                            <strong>
                                                {`${faixaComtemplada.percentual}% ${faixaComtemplada.acima ? 'acima de' : 'até'} ${formatarParaReal(faixaComtemplada.valorFaixa)}`}
                                            </strong>
                                        </div>
                                    }
                                    className="border-900 border-2 "
                                />
                                <Column 
                                    colSpan={1} 
                                    footer={
                                        <div className="flex justify-content-end" >
                                            <strong>
                                                {`${formatarParaReal(comissaoTotalAprovada)}`}
                                            </strong>
                                        </div>
                                    }
                                    className="border-900 border-2 background-comissao verde"
                                />
                                <Column  colSpan={6} />
                            </Row>
                            
                        }
                    </ColumnGroup>
                );
        }
    }

    

    const dataTableComponents = data?.itens?.map((item, index) => {
        console.log('item', item)
        let comissaoTotalGeral = item.notas.reduce((acumulador, nota) => {
            return acumulador + nota?.valorComissao;
        }, 0)

       console.log(item.valorComissao)
        
        const somaValorBaseCalculoComissaGeral = item.notas.reduce((acumulador, nota) => {
            return acumulador + nota?.valorBaseCalculoComissao;
        }, 0)

        const servicoHeaderGroup = itemHeader(item?.tipo);
        const servicoFooterGroup = itemFooter(
            item?.tipo, 
            item?.valorFaturamento, 
            somaValorBaseCalculoComissaGeral, 
            item?.totalValorBaseCalculoComissao,
            comissaoTotalGeral, 
            item.valorComissao, 
            item.faixas[0], 
            item.faixas,
            
        );

        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} ml-2 text-primary`;
            const style = { fontSize: '1.25rem' };
    
            return (
                <div className={className}>
                    <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                        <span className={toggleIcon}></span>
                        <Ripple />
                    </button>
                    <span className={titleClassName} style={style}>
                        {`${formatarString(item.descricao)} - ${formatarParaReal(item.valorComissao)}`}
                    </span>
                </div>
            );
        };
        
        return (
            <Panel 
                headerTemplate={template} 
                toggleable 
                key={item} 
                className="border-2 border-round mb-3 acompanhamento-panel"
                collapsed
            >
                <DataTable
                    value={item?.notas}
                    footer={false}
                    headerColumnGroup={servicoHeaderGroup}
                    footerColumnGroup={servicoFooterGroup}
                    paginator={item.notas.length > 30}
                    rows={30}
                    dataKey={"id"}
                    loading={loadingRow}
                    rowClassName={ordemServicoRowClassName}
                >
                    <Column sortable field="dataEmissao" header="Data de Emissão" body={dataTemplate}/>
                    <Column header="Documento" body={documentoTemplate}/>
                    <Column field="cliente" header="Cliente" body={razaoSocialTemplate}/>
                    <Column  field="valorFaturamento" align={'right'} header="Valor Faturamento" body={valorFaturamento} sortable />
                    <Column  field="valorFaturamento" align={'right'} body={valorBaseCalculoComissao} sortable />
                    {(item?.tipo === 'FAIXA_INDIVIDUAL' || item?.tipo === 'UNICA') && <Column body={percentualComissaoTemplate}/>}
                    {(item?.tipo === 'FAIXA_INDIVIDUAL' || item?.tipo === 'UNICA') && <Column body={valorComissao}/>}
                    <Column body={(dataTableRowData) => icones(dataTableRowData)}/>
                </DataTable>
            </Panel>
        );
    });

    return (
        <div className="border-round">
            <div className="p-3 w-full border-2 border-round bg-green-900 mb-3 shadow-5 text-center	">
                <strong className="text-white text-2xl"> 
                    {`Total de comissão paga - ${formatarParaReal(data.valorComissao)}`}
                </strong> 
            </div>
            {dataTableComponents}
        </div>
    );
});