import {useCallback, useState} from "react";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useQueryParams} from "../QueryParamContext";

export function useDefaultQueryClient(key, queryFunction) {
    const {params} = useQueryParams(key);
    const queryClient = useQueryClient();
    const [queryParams, setQueryParams] = useState(JSON.stringify(params));
    const queryFn = useCallback(async () => await queryFunction(params), [params]);
    const query = useQuery({queryKey: [key, queryParams], queryFn});
    async function handleSearch() {
        const queryParams = JSON.stringify(params);
        setQueryParams(queryParams);
        await queryClient.invalidateQueries({queryKey: [key, queryParams]});
    }
    return {query, handleSearch};
}
