import React, {useCallback, useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {Card} from 'primereact/card';
import {toast} from 'react-toastify';

import {Button} from '../../components/button';
import {api} from '../../service/api';

import './styles.scss'

export function ErroConexao(props) {

    const [loading, setLoading] = useState(false);
    const [reconexoes, setReconexoes] = useState(0)

    useEffect(() => {
        props.setStaticMenuInactive(true)
    }, [props])

    const navigate = useNavigate()

    const reconectar = useCallback(() => {
        setReconexoes(oldState => oldState + 1)
        setLoading(true)
        toast.promise(
            api.get("/usuarios/eu").then(response => {
                if (response.status === 200) {
                    navigate(-(reconexoes + 1))
                }
            }).finally(r => setLoading(false)),
            {
                "pending": `Realizando reconexão. Aguarde...`,
                "success": `Reconexão concluida com sucesso!`,
                "error": {
                    render(e) {
                        return `Falha ao se reconectar com o servidor.`;
                    }
                }
            }
        );
    }, [navigate, reconexoes])

    return (
        <Card className='height flex flex-column align-items-center justify-content-center'>
            <div style={{textAlign: 'center'}}>
                <i className="fa-solid fa-plug-circle-xmark fa-10x"/>
                <h2 style={{marginTop: '0.50em'}}>{loading ? 'Tentando se reconectar com o servidor. Aguarde...' : 'Erro de comunicação com o servidor. Por favor tente novamente mais tarde...'}</h2>
                <Button
                    label={'Reconectar'}
                    loading={loading}
                    icon='fa-solid fa-rotate'
                    justIcon
                    secondary
                    large
                    onClick={reconectar}
                />
            </div>
        </Card>
    );

}