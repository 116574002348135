import React from "react";
import {InputText} from "../../components/inputtext";
import {FiltroEquipamentoEspecial} from "../../service/ordemServicoService";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";

export function FiltrosProduto() {

    const {params, handleChange} = useQueryParams(QueryParamsKeys.Produto);

    return (
        <>
            <InputText label="Descrição" placeholder="a descrição, código ou Part Number" col={9} value={params.descricao} name="descricao" onChange={handleChange}/>
            <FiltroEquipamentoEspecial value={params.equipamentoEspecial} col={3} onChange={handleChange}/>
        </>
    );

}
