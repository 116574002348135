import React, {useContext, useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {Button} from "../../components/button";
import {InputButton} from "../../components/inputbutton";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {equipamentoService} from "../../service/equipamentoService";
import {Calendar} from "../../components/calendar";
import {InputText} from "../../components/inputtext";
import {deepCopy} from "../../utils/objectUtils";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {DialogContext} from "../../utils/dialogContext";
import {If} from "../../components/conditional/If";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {SelecionarEquipamento} from "./SelecionarEquipamento";

export function EditarEquipamentoBackup({equipamentoBackup, onModalClose}) {

    const {showDialog} = useContext(DialogContext);
    const [visible, setVisible] = useState(true);
    const [backup, setBackup] = useState(deepCopy(equipamentoBackup || equipamentoService.criarBackup()));

    function handleConfirmar() {
        if (onModalClose) {
            if (backup.equipamento?.serial?.length && backup.equipamento?.produto?.id) {
                onModalClose(backup);
                setVisible(false);
            } else {
                showDialog(<ConfirmDialogV2 message="Os dados do equipamento não estão corretamente preenchidos. Deseja descartar o equipamento backup e fechar?" onYes={() => {
                    onModalClose(null);
                    setVisible(false);
                }}/>);
            }
        } else {
            setVisible(false);
        }
    }

    function handleClose() {
        setVisible(false);
    }

    function handleChange(event) {
        setBackup(prevBackup => {
            if (!prevBackup?.equipamento) {
                prevBackup.equipamento = equipamentoService.criar();
            }
            prevBackup[event.name] = event.value;
            prevBackup.equipamento.autoComplete = `${prevBackup.equipamento.produto?.autoComplete} - ${prevBackup.equipamento.serial}`;
            return {...prevBackup};
        });
    }

    function handleChangeEquipamento(event) {
        setBackup(prevBackup => {
            if (!prevBackup?.equipamento) {
                prevBackup.equipamento = equipamentoService.criar();
            }
            prevBackup.equipamento[event.name] = event.value;
            prevBackup.equipamento.autoComplete = `${prevBackup.equipamento.produto?.autoComplete} - ${prevBackup.equipamento.serial}`;
            prevBackup.equipamento._alterado = true;
            return {...prevBackup};
        });
    }

    async function buscarEquipamento() {
        const equipamentos = await equipamentoService.listar([`serial=${backup.equipamento?.serial}`]);
        switch (equipamentos.length) {
            case 0:
                showDialog(<InformationDialog message="Não foi encontrado nenhum equipamento com o número de série informado"/>);
                break;
            case 1:
                const e = await equipamentoService.buscar(equipamentos[0].id);
                setBackup(prevBackup => {
                    prevBackup.equipamento = e;
                    return {...prevBackup};
                });
                break;
            default:
                showDialog(<SelecionarEquipamento equipamentos={equipamentos} onModalClose={async e => {
                    e = await equipamentoService.buscar(e.id);
                    setBackup(prevBackup => {
                        prevBackup.equipamento = e;
                        return {...prevBackup};
                    });
                }}/>);
                break;
        }
    }

    function handleExcluir() {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover o equipamento de backup?" onYes={() => {
            if (onModalClose) {
                onModalClose(null);
            }
            setVisible(false);
        }}/>);
    }

    return (
        <Dialog onHide={handleClose} visible={visible} style={{width: "850px"}} header="Equipamento Backup">
            <PanelContent>
                <Calendar showTime col={3} label="Data de Envio" name="dataEnvio" value={backup?.dataEnvio} onChange={handleChange}/>
                <div className="col-6"/>
                <Calendar showTime col={3} label="Data do Retorno" name="dataRetorno" value={backup?.dataRetorno} onChange={handleChange}/>
                <InputButton buttonDisabled={!backup?.equipamento?.serial?.length} onButtonClick={buscarEquipamento} value={backup?.equipamento?.serial} buttonIcon="fas fa-search" buttonLabel="Buscar" required col={4} label="Nº de Série" name="serial" onChange={handleChangeEquipamento}/>
                <ProdutoAutoComplete label="Modelo do Equipamento" name="produto" col={8} onChange={handleChangeEquipamento} value={backup?.equipamento?.produto}/>
                <InputText label="Observações" col={12} style={{height: "8em"}} multiline name="observacoes" value={backup?.observacoes} onChange={handleChange}/>
            </PanelContent>
            <DialogFooter>
                <If condition={backup.id}>
                    <Button icon="fas fa-trash" danger label="Remover Backup" onClick={handleExcluir} style={{float: "left"}}/>
                </If>
                <Button icon="fas fa-check" success label="Confirmar" onClick={handleConfirmar}/>
                <Button icon="fas fa-times" secondary label="Cancelar" onClick={handleClose}/>
            </DialogFooter>
        </Dialog>
    );

}
