import {api} from "./api";

const resourceUrl = "/relatorios";

export const backlogOrcamentoService = {

    async listarBacklogGeral(params) {
        const queryParams = Object.keys(params).filter(k => params[k] && params[k].length).map(k => `${k}=${params[k]}`).join("&");
        return await api.get(`${resourceUrl}/backlog-orcamentos/geral?${queryParams}`).then(res => res.data);
    },

    async listarBacklog(params) {
        const queryParams = Object.keys(params).filter(k => params[k] && params[k].length).map(k => `${k}=${params[k]}`).join("&");
        return await api.get(`${resourceUrl}/backlog-orcamentos/individual?${queryParams}`).then(res => res.data);
    },

    async baixarRelatorio(params) {
        const queryParams = Object.keys(params).filter(k => params[k] && params[k].length).map(k => `${k}=${params[k]}`).join("&");
		return await api.get(`${resourceUrl}/backlog-orcamentos/imprimir?${queryParams}`, {responseType: "blob"}).then(response => response.data);
	},
}
