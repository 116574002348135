import React, { createContext, useCallback, useState, useContext, useMemo } from 'react';

import { useAppConfiguracoes } from './AppConfiguracoesContext';
import { api } from '../service/api';
import {vendedorService} from "../service/vendedorService";
import {usuarioService} from "../service/usuarioService";

export const AuthContext = createContext(null);

const AuthProvider = ({children}) => {

	const {changeTheme, setScale} = useAppConfiguracoes();
	const [carregando, setCarregando] = useState(false);

	const [usuario, setUsuario] = useState(() => {
		setCarregando(true);
		api.get("/usuarios/eu").then(response => {
			const data = {...response.data};
			setUsuario(data);
			setRoles(rolesObjeto(data));
			localStorage.setItem("Roles",JSON.stringify(rolesObjeto(data)));
			setScale(data.escalaWeb)
			changeTheme(data.tema, data.tema === 'lara-dark-blue' ? 'dark' : 'light');
		}).catch(() => {
			setUsuario(null);
			changeTheme('lara-light-blue' , 'light');
			setScale(12);
		}).finally(() => setCarregando(false));
	});

	const [vendedor, setVendedor] = useState(() => {
		vendedorService.findByUsuario().then(setVendedor);
	})

	const [token, setToken] = useState(() => {
		return localStorage.getItem("X-Auth-Credentials") || "";
	});

	const [roles, setRoles] = useState(() => {
		return JSON.parse(localStorage.getItem("Roles")) || null;
	});

	const rolesObjeto = (usuario) => {
		if (!usuario) return;
		let roles = usuario.roles;
		const rolesUnicas = [...new Set(roles)]
		return rolesUnicas.reduce((key, value) => ({ ...key, [value]: value}), {});
	}

	const salvarCredenciais = useCallback(async (response) => {
		localStorage.setItem("X-Auth-Credentials", response.headers.authorization);
		api.defaults.headers["Authorization"] = localStorage.getItem("X-Auth-Credentials") || "";
		setToken(response.headers.authorization)
		api.get("/usuarios/eu").then(response => {
			if (response.status === 200) {
				setUsuario(response.data);
				const roles = rolesObjeto(response.data)
				localStorage.setItem("Roles",JSON.stringify(roles));
				setRoles(roles)
				changeTheme(response.data.tema, response.data.tema === 'lara-dark-blue' ? 'dark' : 'light')
			}
		}).catch(error => console.log(error.message))
	}, [changeTheme]);

	const signIn = useCallback(async params => {
		setCarregando(true)
		await api.post("/login", {email: params.login, senha: params.senha}).then(response => {
			if (response.status === 200) {
				usuarioService.notificarLogin(params.login);
				salvarCredenciais(response);
			}
			else {
				const error = response.toJSON();
				if (error.message === 'Request failed with status code 403') {
					throw new Error("Login e/ou senha incorretos.")
				} else {
					throw new Error()
				}
			}
		}).finally(() => setCarregando(false));
	}, [salvarCredenciais]);

	const signOut = useCallback(() => {
		localStorage.clear()
		setUsuario(null);
		setToken('');
		changeTheme('lara-light-blue' , 'light');
	}, [changeTheme]);

	const dadosLogin = useMemo(() => ({signIn, signOut, usuario, setUsuario, token, roles, carregando, vendedor}), [carregando, roles, signIn, signOut, token, usuario, vendedor]);

	return (
		<AuthContext.Provider value={dadosLogin}>
			{children}
		</AuthContext.Provider>
	);

};

function useAuth() {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('UseAuth must be used within an AuthProvider');
	}
	return context;
}

export {AuthProvider, useAuth};
