import React from 'react';


import { Outlet } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
import { AppTopbar } from "../AppTopbar";
import { AppMenu } from "../AppMenu";
import { Spinner } from "../components/spinner";

export function Template(props) {
	const {carregando} = useAuth();

	const componente = carregando ? <Spinner basico/> : <Outlet/>

	return (
		<>
			<AppTopbar
				onToggleMenuClick={props.onToggleMenuClick}
				layoutColorMode={props.layoutColorMode}
				mobileTopbarMenuActive={props.mobileTopbarMenuActive}
				onMobileTopbarMenuClick={props.onMobileTopbarMenuClick}
				onMobileSubTopbarMenuClick={props.onMobileSubTopbarMenuClick}
			/>

			<div className="layout-sidebar" onClick={props.onSidebarClick}>
				<AppMenu model={props.menu} onMenuItemClick={props.onMenuItemClick}/>
			</div>

			{componente}
		</>
	)
}