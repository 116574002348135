import React, {useCallback, useState} from "react";
import {PanelFooter} from "../../components/panel";
import {Panel} from "primereact/panel";
import {Button} from "../../components/button";
import {useBreakpoint} from "../../context/BreakpointContext";
import {TabPanel, TabView} from "primereact/tabview";
import {useDialog} from "../../utils/dialogContext";
import {EditarOrdemServicoItemDadosPrincipais} from "./EditarOrdemServicoItemDadosPrincipais";
import {EditarOrdemServicoItemFoto} from "../OrdensServico/EditarOrdemServicoItemFoto";
import {EditarOrdemServicoItemComprovante} from "../OrdensServico/EditarOrdemServicoItemComprovante";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";
import {BlockUI} from "primereact/blockui";
import {InformationDialog} from "../../components/dialog/InformationDialog";

export function EditarOrdemServicoItem({item, setItem, ordemServico, onSalvar, messages, setMessages, index, readOnly, setOrdemServico, setLaudo}) {

	const {showDialog} = useDialog();
	const {breakpoint} = useBreakpoint();
	const [blocked, setBlocked] = useState(false);
	const isLargeDevice = breakpoint === "lg" || breakpoint === "md";

	async function handleSalvar() {
		const em = ordemServicoItemService.validar(item, ordemServico);
		if (!em.isEmpty()) {
			messages.itens[index] = em;
			setMessages(messages);
			showDialog(<InformationDialog message="Um ou mais campos obrigatórios não estão preenchidos corretamente."/>);
			return;
		}
		setBlocked(true);
		item.ordemServico = ordemServico;
		item = await ordemServicoItemService.salvarDependencias(item);
		setBlocked(false);
		return onSalvar && onSalvar(item);
	}

	const handleChange = useCallback(event => setItem({...item, [event.name]: event.value}), [item, setItem]);

	const pedidoVenda = () => {
		
		let header;
		if (ordemServico.pedidoVendaFaturamentoNumero) {
			header = "Pedido Venda Faturamento Nº " +  ordemServico.pedidoVendaFaturamentoNumero + " | ";
			header += "Pedido Venda Retorno Nº " +  ordemServico.pedidoVendaNumero + " | ";
		} else if (ordemServico.pedidoVendaNumero && !ordemServico.pedidoVendaFaturamentoNumero) {
			header = "Pedido Venda Nº " +  ordemServico.pedidoVendaNumero + " | ";
		}
		return header;
	}
	
	return (
		<BlockUI blocked={blocked}>
			<Panel header={`Detalhes do Item ${item.sequencia} da ${ordemServico.numero ? "" : "Nova"} Ordem de Serviço ${ordemServico.numero || ""}`}
					icons={<><b> {pedidoVenda}  Nº OS Omega {item.numero}</b></>}>
				<EditarOrdemServicoItemFields
					ordemServico={ordemServico}
					readOnly={readOnly}
					index={index}
					messages={messages}
					setMessages={setMessages}
					handleChange={handleChange}
					item={item}
					setItem={setItem}
					handleSalvar={handleSalvar}
					setOrdemServico={setOrdemServico}
					setLaudo={setLaudo}
				/>
				<PanelFooter>
					<Button icon="pi pi-save" success loading={blocked} label={isLargeDevice ? "Salvar" : null} onClick={handleSalvar} autowidth={!isLargeDevice.toString()}/>
					<Button icon="pi pi-arrow-left" secondary label={isLargeDevice ? "Voltar" : null} onClick={() => setItem(null)} autowidth={!isLargeDevice.toString()}/>
				</PanelFooter>
			</Panel>
		</BlockUI>
	);

}

export function EditarOrdemServicoItemFields({ordemServico, handleChange, item, messages, setMessages, index, readOnly, setOrdemServico}) {

	function headerTemplate(e) {
		let style = {};
		switch (e.index) {
			case 0:
				if (messages.itens?.[index]?.equipamento) style.color = "#F44336";
				break;
			case 1:
				if (messages.itens?.[index]?.pecas?.some(p => p.produto || p.quantidade || p.status)) style.color = "#F44336";
				break;
			case 2:
				if (messages.itens?.[index]?.servicos?.some(p => p.servico || p.quantidade)) style.color = "#F44336";
				break;
			case 4:
				break;
			case 5:
				if (messages.itens?.[index]?.movimentacoes?.some(p => p.movimento || p.prateleira)) style.color = "#F44336";
				break;
			default:
				break;
		}
		return (
			<div className={`${e?.className}`} aria-controls={`${e.ariaControls}`} onClick={e.onClick} onKeyDown={e.onKeyDown} style={style}>
				<i className={`${e?.leftIconElement?.props?.className}`}/>
				<div className={`${e?.titleClassName}`} >{e?.titleElement?.props?.children}</div>
			</div>
		);
	}

	return (
		<TabView>
			<TabPanel headerTemplate={headerTemplate} header="Equipamento & Laudo" leftIcon="fa-solid fa-database">
				<EditarOrdemServicoItemDadosPrincipais ordemServico={ordemServico} setOrdemServico={setOrdemServico} item={item} index={index} handleChange={handleChange} messages={messages} setMessages={setMessages}/>
			</TabPanel>
			<TabPanel headerTemplate={headerTemplate} header="Fotos e Imagens" leftIcon="fa-solid fa-camera">
				<EditarOrdemServicoItemFoto ordemServico={ordemServico} readOnly={readOnly} item={item} handleChange={handleChange}/>
			</TabPanel>
			<TabPanel headerTemplate={headerTemplate} header="Arquivos e Comprovantes" leftIcon="fa-solid fa-file">
				<EditarOrdemServicoItemComprovante item={item} handleChange={handleChange}/>
			</TabPanel>
		</TabView>
	);

}
