import React, {useMemo, useState} from 'react';
import {TabelaListaPreco} from './TabelaListaPreco';
import {useAuth} from "../../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {useBreakpoint} from "../../../context/BreakpointContext";
import {headerTemplate} from "../../../utils/templates";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {Button} from "../../../components/button";
import {Panel} from "primereact/panel";
import {BlockUI} from "primereact/blockui";
import {If} from "../../../components/conditional/If";
import {SearchButton} from "../../../components/button/SearchButton";
import {RefetchIndicator} from "../../../components/refetchindicator/RefetchIndicator";
import {useQueryClientListaPreco} from "../../../utils/query-clients/queryClients";
import {FiltrosListaPreco} from "./FiltrosListaPreco";
import { toast } from 'react-toastify';
import { listaPrecoService } from '../services/listaPrecoService';
import { withDialog } from '../../../utils/dialogContext';
import { InformationDialog } from '../../../components/dialog/InformationDialog';
import { ButtonIconSimpleFileUpload } from '../../../components/fileupload/ButtonIconSimpleFileUpload';
import saveAs from "file-saver";

export const ListaPreco = withDialog(({showDialog}) => {

    const {roles} = useAuth();
    const navigate = useNavigate();
    const {isLargeDevice} = useBreakpoint();
    const {query, handleSearch} = useQueryClientListaPreco();
    const [arquivo, setArquivo] = useState();
    const [loading, setLoading] = useState(false);
    function handleCriar() {
        navigate("/listas-preco/new");
    } 

    async function uploadCSV(e , id) {
        setLoading(true);
        console.log(e)
        let criterio = {listaPrecoId: id, conteudo: e?.value?.conteudo?.conteudo};        
        toast.promise( 
            listaPrecoService.uploadCSV(criterio)
            .then((res) => {
                if(res?.mensagem) {
                    showDialog(<InformationDialog 
                        header="Informação" 
                        message={res.mensagem}
                    />)
                }else{
                    navigate('/listas-preco/atualizacao/lote', { state: {listaPreco: res} })
                }
            })
            .finally(() => setLoading(0)),
            {
                "pending": `Fazendo upload do arquivo. Aguarde...`,
                "error": {
                    render(e) {
                        return `Falha ao fazer upload: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }

    async function downloadModeloCSV(id) {
        setLoading(true);
        toast.promise(
            listaPrecoService.downloadModeloCSV(id).then((data) => {                
                saveAs(new Blob([data], {type: "application/csv"}), `${"TemplateListaPreço"}.csv`);           
            })  .finally(() => setLoading(false)),
            {
                "pending": `Gerando relatório. Aguarde...`,
                "error": {
                    render(e) {
                        return `Falha ao gerar Template: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }
    return (
        <BlockUI blocked={query.isLoading || loading}>
            <Panel headerTemplate={() => headerTemplate("Lista de preço", "fa-file-invoice")}>
                <PanelContent>
                    <FiltrosListaPreco/>
                </PanelContent>
                <PanelFooter>
                    <div className='flex justify-content-between align-items-center'>
                        <If 
                            condition={roles.LTPA}
                            otherwise={(<div></div>)}
                        >
                            <div className='flex'>
                                <Button 
                                    success
                                    style={{textAlign: "left"}} 
                                    onClick={() => downloadModeloCSV()}
                                    title='Download do modelo CSV' 
                                    icon="fa-solid fa-file-arrow-down" 
                                />                                
                                <ButtonIconSimpleFileUpload
                                    accept='.csv'  
                                    name="arquivo"  
                                    sizeIcon='2xl'
                                    value={arquivo}  
                                    onChange={(e) => uploadCSV(e, null)}
                                    title='Fazer upload de uma nova lista de preço através de um arquivo CSV'
                                    loading={ loading } 
                                />
                            </div>
                        </If>
                        <div>
                            <If condition={roles.LTPA}>
                                <Button 
                                    success 
                                    onClick={handleCriar} 
                                    label={isLargeDevice ? "Novo" : null} 
                                    icon="pi pi-plus" 
                                    autowidth={!isLargeDevice}
                                />
                            </If>
                            <SearchButton query={query} onSearch={handleSearch}/>
                        </div>
                        

                    </div>
                </PanelFooter>
            </Panel>
            {useMemo(() => <TabelaListaPreco value={query.data || []}/>, [query.data])}
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

});