import React from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";

export const TabelaEquipamento = React.memo(({value}) => {

	const navigate = useNavigate();

	function handleEditar(event) {
		navigate(`/equipamentos/${event.data.id}`);
	}

	return (
		<DataTable value={value} onRowDoubleClick={handleEditar} valuelength={value?.length}>
			<Column header="Descrição" field="descricao"/>
			<Column header="Número de Série" field="serial"/>
			<Column header="Etiqueta" field="etiqueta"/>
			<Column header="Part Number" field="partNumber"/>
			<Column header="Cliente" field="clienteNomeFantasia"/>
			<Column header="Produto" body={(e) => <div>{`${e.produtoCodigo} - ${e.produtoDescricao}`}</div>}/>
		</DataTable>
	);
});