import React from "react";
import { TipoComissaoDropdown } from "../../../../components/dropdown/tipoComissaoDropdown";
import { InputCurrency, InputNumber } from "../../../../components/inputnumber";
import { formatarParaReal, ordenarFaixas } from "./utils";
import moment from "moment";
import { SelectButton } from "../../../../components/selectbutton";
import { restricoesButtons } from "../../../../service/ordemServicoService";
import { optionsComissaoAcompanhamento } from "../../services/comissaoAcompanhamentoService";
import "./Comissao.scss";
import { Calendar } from "../../../../components/calendar";
import ClienteRazaoSocialDTOTemplate from "../../../Templates/ClienteRazaoSocialDTOTemplate";

export const tipoComissaoTemplate = (dataTableRowData, dataTableRowInfos, tiposComissao, handleChange) => (
    <div className="comissao-column-width sz-15">
        <TipoComissaoDropdown
            value={dataTableRowData?.tipoComissao?.id}
            tiposComissao={tiposComissao}
            label=""
            name="tipoComissao"
            onChange={(onChangeValue) =>
                handleChange(onChangeValue, dataTableRowData, dataTableRowInfos)
            }
            col={12}
            reduced
            className='auto-width'
            disabled={dataTableRowData.fechada}
        />
    </div>
);

export const documentoTemplate = (data) => {
    const items = [];
  
    const addItem = (title, color, content, icon) => {
        items.push(
        <div title={title} className={`flex grid align-items-center `}>
            <div className="col-10 grid-nogutter">
                <i style={{ color }} className={`fa-solid ${icon} fa-lg col-2 mr-4`} />
                <span className="font-bold col-8">{content}</span>
            </div>
        </div>
        );
    };
    
    switch (data.tipo) {
        case "VENDA_DIRETA":
            addItem("Venda Direta", "#006992", data.pedidoNumero, 'fa-warehouse');
        break;    
        case "NOTA_DEBITO":
            addItem("Nota de Debito", "#4caf50", data.duplicataNumero, 'fa-money-bills');
        break;
    
        case "NOTA_FISCAL":
            addItem("Nota Fiscal", "#F31CB7", `${data.notaFiscalNumero} - ${data.notaFiscalItem}`, 'fa-file-invoice-dollar');
        break;    
        default:
        break;
    }

    if (data.pedidoNumero && data.origem === "PEDIDO_VENDA")  {
        addItem("Pedido de Venda", "rgb(48, 63, 159)", data.pedidoNumero, 'fa-file-invoice');
    }
    
    if (data.ordemServicoNumero) {
        addItem("Ordem de Servico", "#009688", data.ordemServicoNumero, 'fa-scroll');
    }
    
    if (data.contratoNumero) {
        addItem("Contrato", "#795548", data.contratoNumero, 'fa-file-signature');
    }

    if (data.info) {
        let descricao = "O pagamento da comissão será pago parcelado, a quantidade de parcelas é definida pela condição de pagamento do pedido de venda."
        addItem(descricao, "#8484e1", data.info, 'fa-circle-dollar-to-slot');        
    }
    
    return <div className="flex flex-column">{items}</div>;
};

export const razaoSocialTemplate = (dataTableRowData) => {
    return <ClienteRazaoSocialDTOTemplate data={dataTableRowData?.cliente} />
}
export const percentualFaixaUnica = (dataTableRowData, dataTableRowInfos, handleChange) => {
    return (
        <div className="comissao-column-width sz-6">
            <InputNumber
                disabled={(dataTableRowData?.comissaoPaga && dataTableRowData?.tipoComissao?.validaComissaoPaga) || dataTableRowData.fechada}
                col={12}
                value={dataTableRowData?.percentualComissao}
                onChange={(onChangeValue) =>  {
                    if(onChangeValue.value > 100) {
                        return handleChange({name: 'percentualComissao', value: 100}, dataTableRowData, dataTableRowInfos)
                    }
                    handleChange(onChangeValue, dataTableRowData, dataTableRowInfos)
                }}
                readOnly={dataTableRowData.fechada}
                name='percentualComissao'
                reduced
                suffix="%"
                minFractionDigits={2}
                maxFractionDigits={2}
                min={0}
                max={100}
            />
        </div>
    );
}

export const comissaoFaixaIndividual = (data, tipoComissao) => {
    const faixasOrdenadas = ordenarFaixas(tipoComissao.itens);
    const faixaComtemplada = faixasOrdenadas.find((faixa) => {
        if (faixa.acima && data.valorFaturamento >= faixa.valor) {
            return true;
        }

        if (data.valorFaturamento <= faixa.valor) {
            return true;
        }
    });

    const comissao = (faixaComtemplada.percentual / 100) * data.valorBaseCalculoComissao;

    return (
        <div style={{textAlign: "right"}}>
            {formatarParaReal(comissao)}
        </div>
    );
}

export const percentualFaixaIndividual = (data, tipoComissao) => {
    const faixasOrdenadas = ordenarFaixas(tipoComissao.itens);
    const faixaComtemplada = faixasOrdenadas.find((faixa) => {
        if (faixa.acima && data.valorFaturamento >= faixa.valor) {
            return true;
        }

        if (data.valorFaturamento <= faixa.valor) {
            return true;
        }
    });

    return (
        <div style={{textAlign: "right"}}>
            {`${faixaComtemplada.percentual} %`}
        </div>
    );
}
export const valorNotaFiscalEditavelTemplate = (dataTableRowData, dataTableRowInfos, handleChange) => {
    return (
        <div className="comissao-column-width sz-8">
            <InputCurrency
                disabled={dataTableRowData?.comissaoPaga && dataTableRowData?.tipoComissao?.validaComissaoPaga || dataTableRowData.fechada}
                col={12}
                name='valorBaseCalculoComissao'
                value={dataTableRowData.valorBaseCalculoComissao}
                onChange={(onChangeValue) => {
                    handleChange(onChangeValue, dataTableRowData, dataTableRowInfos)
                }}
                reduced
                readOnly={dataTableRowData.fechada}
            />
        </div>
    );
}
export const comissaoFaixaUnica = (dataTableRowData, dataTableRowInfos, handleChange) => {
    return (
        <div className="comissao-column-width sz-8">
            <InputCurrency
                disabled={(dataTableRowData?.comissaoPaga && dataTableRowData?.tipoComissao?.validaComissaoPaga) || dataTableRowData.fechada}
                col={12}
                name='valorComissao'
                value={dataTableRowData.valorComissao}
                onChange={(onChangeValue) => {
                    handleChange(onChangeValue, dataTableRowData, dataTableRowInfos)
                }}
                reduced
            />
        </div>
    );
}

export const valorNotaFiscalTemplate = (data) => {
    return (
        <div style={{textAlign: "right"}}>
            {formatarParaReal(data.valorBaseCalculoComissao)}
        </div>
    )
}

export const valorFaturamento = (data) => {
    return (
        <div style={{textAlign: "right"}}>
            {formatarParaReal(data.valorFaturamento)}
        </div>
    )
}

export const dataTemplate = (data) => {
    return(
        <div className="flex align-items-center justify-content-center">
            <i className="fa-solid fa-calendar-day mr-2"/>
            <span>{moment(data?.dataEmissao).format("DD/MM/YYYY")}</span>
        </div>
    )
}

export const dataTemplateEditavel = (dataTableRowData, dataTableRowInfos, handleChange) => {
    return (
        <div className="comissao-column-width sz-8">
            <Calendar
                reduced
                showButtonBar={false}
                label={null}
                col={12} 
                name="dataEmissao"
                readOnlyInput
                readOnly={dataTableRowData.fechada}
                value={dataTableRowData.dataEmissao} 
                onChange={(onChangeValue) => {
                    handleChange(onChangeValue, dataTableRowData, dataTableRowInfos)
                }}
            />
        </div>
    );
}

export function toggleStatus(dataTableRowData, dataTableRowInfos, handleChange) {
    return (
        <SelectButton 
            disabled={(dataTableRowData.comissaoPaga && dataTableRowData?.tipoComissao?.validaComissaoPaga) || dataTableRowData.fechada}
            pt={{button: ({context}) => ({className: context.selected ? dataTableRowData.status === "APROVADO" ? "sb-success" : "sb-unsuccess" : null})}}
            index={dataTableRowInfos.rowIndex}
            onChange={(onChangeValue) =>
                handleChange(onChangeValue, dataTableRowData, dataTableRowInfos)
            }
            itemTemplate={restricoesButtons}
            col={12}
            name="status"
            value={dataTableRowData?.status}
            options={optionsComissaoAcompanhamento}
            naoPodeDesselecionar
        />
    );
}

export const icones = (acompanhamento) => {
    let fechada = 'Este item ja faz parte de um fechamento e não pode mais ser editado.';
    let textoComissao = "A comissão para essa venda já foi paga anteriormente";
    let divisor = "O valor base de cálculo da comissão foi divido por " + acompanhamento.divisorParcela + " de acordo com a regra do divisor do contrato."

    let icones  = [];
    if (acompanhamento.contratoRenovado && acompanhamento?.tipoComissao?.renovacaoContrato) {
        icones.push(<i className={`fa-solid fa-calendar-plus fa-lg`} title={"Contrato renovado"} style={{color: "grey", marginRight: "3px"}}/>);
    }

    if (acompanhamento.comissaoPaga && acompanhamento?.tipoComissao?.validaComissaoPaga) {
        icones.push(<i className={`fa-solid fa-sack-dollar fa-lg`} title={textoComissao} style={{color: "red", marginRight: "3px"}}/>);
    }

    if (acompanhamento.fechada) {
        icones.push(<i className={`fa-solid fa-lock yellow-900 fa-lg`}  style={{ marginRight: "3px"}} title={fechada}/>);
    }

    if (acompanhamento.divisorParcela > 1 && acompanhamento?.tipoComissao?.aplicarDivisor) {
        icones.push(<i className={`fa-solid fa-divide fa-lg`} title={divisor} style={{color: "red", marginRight: "3px"}} /> );
    }    
    return <div className="flex justify-content-center" >{icones}</div>;
}