import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import React, {useEffect, useState} from "react";
import {ServicoAutoComplete} from "../../components/autocomplete/servicoAutoComplete";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {Button} from "../../components/button";
import {Calendar} from "../../components/calendar";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {DataTable} from "../../components/datatable";
import {PanelContent} from "../../components/panel";
import {useAuth} from "../../context/AuthContext";
import {isStacked} from "../../context/BreakpointContext";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";
import {servicoService} from "../../service/servicoService";
import {withDialog} from "../../utils/dialogContext";
import {InputText} from "../../components/inputtext";
import {codigosRessolda, isRessolda, optionsStatusRessolda, restricoesButtons} from "../../service/ordemServicoService";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {SelectButton} from "../../components/selectbutton";
import {If} from "../../components/conditional/If";

export const EditarOrdemServicoItemServico = withDialog(({ordemServico, messages, index, item, handleChange, validarItem, showDialog}) => {

	const {roles} = useAuth();
	const [servicosSelecionados, setServicosSelecionados] = useState([]);
	const [sequencia, setSequencia] = useState(0);

	useEffect(() => {
		if (item.servicos?.length) {
			setSequencia(Math.max(...item.servicos.map(osip => osip.sequencia)) || 0);
		}
	}, [item.id]);

	function corretivaServico(osis, column) {
		return (
			<>
				<Calendar reduced label="Início" showTime col={3} placeholder="Início" value={osis.inicio} name="inicio" index={column.rowIndex} onChange={handleChangeServico}/>
				<Calendar reduced label="Término" showTime col={3} placeholder="Término" value={osis.fim} name="fim" index={column.rowIndex} onChange={handleChangeServico}/>
				<UsuarioAutoComplete reduced label="Responsável" col={6} placeholder="Responsável" index={column.rowIndex} value={osis.responsavel} name="responsavel" onChange={handleChangeServico}/>
				<InputText maxLength={3800} style={{height: "6em"}} reduced label="Observações" multiline col={12} placeholder="Informações adicionais" value={osis.observacoes} name="observacoes" onChange={handleChangeServico}/>
			</>
		);
	}
	
	async function handleChangeServico(event) {
		const servico = item.servicos[event.index];
		servico[event.name] = event.value;
		switch (event.name) {
			case "servico":
				if (event.value?.id) {
					const resposta = await servicoService.buscarPreco({
						servico: event.value.codigo,
						cliente: ordemServico.cliente?.id,
						ordemServico: ordemServico.id,
						contrato: ordemServico.contrato?.id,
						equipamento: item.equipamento?.id,
						data: ordemServico.inicio,
						mauUso: item.flags?.includes("MAU_USO"),
						quantidade: servico.quantidade
					});
					servico.valorUnitario = resposta.valorUnitario;
					servico.valorTabela = resposta.valorTabela;
					servico.origemPreco = resposta.origem;
					
					if (resposta.listaPrecoItemId) {
						servico.listaPrecoItem = {id:resposta.listaPrecoItemId};
					}
					
					servico.valorTotal = servico.valorUnitario * servico.quantidade;
				}
				break;
				case "valorUnitario":
				if (event.value) {					
					servico.valorTotal = event.value * servico.quantidade;
					servico.origemPreco = "MANUAL";
				} else {
					servico.valorTotal = 0;
				}
				break;
			case "quantidade":
				if (event.value) {
					servico.valorTotal = servico.valorUnitario * event.value;
				}
				break;
			default:
				break;
		}
		handleChange({name: "servicos", value: item.servicos});
	}

	function adicionarServico() {
		const servico = ordemServicoItemService.criarServico();
		servico.sequencia = sequencia + 1;
		item.servicos.push(servico);
		setSequencia(sequencia + 1);
		handleChange({name: "servicos", value: item.servicos});
	}

	function removerServicos() {
		if (servicosSelecionados.some(ps => isRessolda(ps.servico?.codigo) && ps.status !== "PENDENTE")) {
			showDialog(<InformationDialog header="Aviso" message="Não é possível remover serviços de recuperação de placa concluídos."/>);
			return;
		}
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os serviços selecionados?" onYes={() => {
			item.servicos = item.servicos.filter(osi => !servicosSelecionados.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
			if (!item.id) {
				item.servicos.forEach((osi, i) => osi.sequencia = i + 1);
			}
			handleChange({name: "servicos", value: item.servicos});
			setServicosSelecionados([]);
			validarItem();
		}}/>);
	}

	const disableAlteracaoServicos = !roles.OSSU && item.orcamentos?.some(osio => osio.status === "APROVADO");

	const servicoControlSet = (
		<div>
			<Button disabled={disableAlteracaoServicos} icon="pi pi-plus" onClick={adicionarServico}/>
			<Button disabled={disableAlteracaoServicos || !servicosSelecionados?.length} onClick={removerServicos} danger icon="pi pi-minus"/>
		</div>
	);

	const servicoHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={3} header={servicoControlSet}/>
			</Row>
			<Row>
				<Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
				<Column style={{width: "6em"}} header="Item"/>
				<Column header="Dados Principais"/>
			</Row>
		</ColumnGroup>
	);

	function toggleStatus(osis, column) {
		return (
			<SelectButton pt={{button: ({context}) => ({className: context.selected ? osis.status === "CONCLUIDO" ? "sb-success" : "sb-unsuccess" : null})}}
						  index={column.rowIndex}
						  readOnly
						  itemTemplate={restricoesButtons}
						  label="&nbsp;"
						  col={2}
						  name="status"
						  value={osis.status}
						  options={optionsStatusRessolda}
			/>
		);
	}

	const servicoFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={3} footer={servicoControlSet}/>
			</Row>
		</ColumnGroup>
	);

	return (
		<PanelContent>
			<div className="col-12">
				<DataTable paginator
				           selection={servicosSelecionados}
				           onSelectionChange={e => setServicosSelecionados(e.value)}
				           value={item.servicos}
				           headerColumnGroup={servicoHeaderGroup}
				           footerColumnGroup={servicoFooterGroup}
				           selectionMode="checkbox"
				           header={isStacked() ? null : servicoControlSet}
				           footer={isStacked() ? null : servicoControlSet}
				>
					<Column selectionMode="multiple"/>
					<Column header="Item" field="sequencia"/>
					<Column header="Dados Principais" field="servico" style={{flexDirection: "column", alignItems: "start"}} body={(osis, column) => (
						<div className="grid">
							<ServicoAutoComplete disabled={disableAlteracaoServicos} onBlur={validarItem} required dropdown invalid={messages.itens?.[index]?.servicos?.[column.rowIndex]?.servico} label="Serviço" col={isRessolda(osis.servico?.codigo) ? 10 : 12} index={column.rowIndex} onChange={handleChangeServico} name="servico" value={osis.servico}/>
							<If condition={osis.status !== "PENDENTE" && isRessolda(osis.servico?.codigo)} children={toggleStatus(osis, column)}/>
							{ordemServico.tipo === "CORRETIVA" ? corretivaServico(osis, column) : null}
						</div>
					)}/>
				</DataTable>
			</div>
		</PanelContent>
	);

});
