import React, {useState} from "react";

import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {toast} from "react-toastify";
import moment from "moment";
import saveAs from "file-saver";

import {headerTemplate} from "../../utils/templates";
import {useBreakpoint} from "../../context/BreakpointContext";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {Calendar} from "../../components/calendar";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {useAuth} from "../../context/AuthContext";
import {relatoriosServicoService} from "../../service/relatoriosServicoService";

export function RelatorioOrcamento() {

    const {roles} = useAuth();
    const [loading, setLoading] = useState(false);    
    const [params, setParams] = useState({
        relatorio: 'RORC',
        descricao: "",
        cliente: null,
        textoBuscaCliente: "",
        inicio: moment().subtract(1, 'months').format("YYYY-MM-DD"),
        fim: moment().format("YYYY-MM-DD"),
    });

    const {breakpoint} = useBreakpoint()
    const isLargeDevice = breakpoint === 'lg' || breakpoint === 'md'

    function handleChange(event) {
        setParams({...params, [event.name]: event.value});
    }

    async function handleBaixar(params) {

        setLoading(true);
        const query = [];

        if (params.cliente?.id) query.push(`cliente=${params.cliente.id}`);
        if (params.inicio) query.push(`inicio=${params.inicio}`);
        if (params.fim) query.push(`fim=${params.fim}`);
        if (params.usuario?.id) query.push(`usuario=${params.usuario.id}`);
        if (params.textoBuscaCliente) query.push(`textoBuscaCliente=${params.textoBuscaCliente}`);

        toast.promise(
            relatoriosServicoService.baixarRelatorio('RORC' , query).then((data) => {
                console.log("data_antes",data);
                saveAs(new Blob([data], {type: "application/csv"}), `${moment().format("YYYYDDMMHHmmss")}.csv`);           
            }).finally(() => { setLoading(false) }),
            {
                "pending": `Gerando relatório. Aguarde...`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao procurar ordens de Orçamentos: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }
    
    return (
        <Panel headerTemplate={() => headerTemplate('Relatório de Orçamentos', 'fa-file-lines')} >
            <PanelContent>
                <ClienteAutoComplete forceSelection={false} name="cliente" col={6} value={params.cliente} onChange={handleChange}/>
                <InputText col={6} label="Pesquise por" placeholder='um CNPJ (somente nº), nome fantasia ou razão social' value={params.textoBuscaCliente} name="textoBuscaCliente" onChange={handleChange}/>
                <Calendar label="Período de Inclusão" col={2} name="inicio" value={params.inicio} onChange={handleChange}/>
                <Calendar label="&nbsp;" col={2} name="fim" value={params.fim} onChange={handleChange}/>
                <UsuarioAutoComplete col={5} disabled={!roles.OSSL} value={params.usuario} name="usuario" onChange={handleChange} label="Usuário"/>
            </PanelContent>
            <PanelFooter>        
                <Button 
                    info 
                    disabled={loading} 
                    loading={loading} 
                    onClick={() => handleBaixar(params)} 
                    label={isLargeDevice ? "Baixar" : null}
                    icon="fa-solid fa-file-arrow-down"
                    autowidth={!isLargeDevice}
                />
            </PanelFooter>
        </Panel>
    );

}
