import {api} from "./api";

export const relatorioOrdemServicoEtapaService = {

    async listar() {
        return await api.get("/relatorios/ordemservico-etapa/geral").then(res => res.data);
    },

    async listarRelatorioEvolucaoOrdensServico(params) {
        return await api.get(`/relatorios/ordemservico-etapa/evolucao?${Object.keys(params).filter(k => params[k]).map(k => `${k}=${params[k]}`).join("&")}`).then(res => res.data);
    }

}
