import { api } from "./api";

export function baseService(resourceUrl) {

	async function salvar(user) {
		return await api.post(resourceUrl, user).then(response => response.data);
	}

	async function buscar(id) {
		return await api.get(`${resourceUrl}/${id}`).then(response => response.data);
	}

	async function listar(params) {
		return await api.get(`${resourceUrl}${params ? `?${params.join("&")}` : ""}`).then(response => response.data);
	}

	async function autocomplete(params) {		
		if (params) {
			return await api.get(`${resourceUrl+'/autocomplete'}?${params.join("&")}`).then(response => response.data);
		}
		return [];
	}

	async function excluir(id) {
		return api.delete(`${resourceUrl}/${id}`);
	}

	return {
		buscar,
		salvar,
		excluir,
		listar,
		autocomplete
	}

}
