import React from "react";

import {SelectButton as PSelectButton} from "primereact/selectbutton";
import {calcularTamanho} from "../../service/guiHelper";

import { Tooltip } from "primereact/tooltip";

export function SelectButton(props) {
    const classes = `${calcularTamanho(props.col)} relative` ;
    function handleChange(event) {
        if (props.naoPodeDesselecionar && event.value === null) return;

        return props.onChange?.({name: props.name, index: props.index, value: event.value});

    }

    return (
        <>
            <div className={classes}>
                {props.label ? <label>{props.label}</label> : null}
                <PSelectButton {...props} onChange={handleChange}/>
                {
                    props.hint &&
                    <>   
                        <Tooltip target=".custom-target-icon" />
                        <i 
                            className="custom-target-icon fa-solid fa-circle-question absolute right-0	"
                            data-pr-tooltip={props.hint}
                            data-pr-position={'bottom'}
                            data-pr-at="left-0 bottom"
                            data-pr-my="center top-0"
                            style={{ fontSize: '1.4rem', cursor: 'pointer', top: '-0.5rem'}}
                        />
                    </>
                }
            </div>
        </>
    );

}
