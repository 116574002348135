import {buildValidator} from "../utils/fieldValidator";
import {api} from "./api";
import {baseService} from "./baseService";

const resourceUrl = "/servicos";

export const servicoService = {
    criar: () => {
        return {
            id: null,
            codigo: "",
            descricao: ""
        };
    },
    validar: servico => {
        return buildValidator();
    },
    verificarPath: async (path) => {
        return api.get(`${resourceUrl}/verificar-path`, {
            params: {path: path}
        }).then(res => res.data);
    },
    async buscarPreco(criterio) {
        return await api.post(`${resourceUrl}/preco`, criterio).then(response => response.data);
    },
    ...baseService(resourceUrl)
};
