import React from "react";
import {InputNumber} from "../../../components/inputnumber";
import {ClienteAutoComplete} from "../../../components/autocomplete/ClienteAutoComplete";
import {InputText} from "../../../components/inputtext";
import {useQueryParams} from "../../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../../utils/defaultQueryParams";

export function FiltrosListaPreco() {

    const {params, handleChange} = useQueryParams(QueryParamsKeys.ListaPreco);

    return (
        <>
            <InputNumber col={2} name="numero" label="Número" value={params.numero} onChange={handleChange} showButtons min={0} placeholder="o código da lista de preço."/>
            <ClienteAutoComplete forceSelection={false} col={5} value={params.cliente} name="cliente" onChange={handleChange}/>
            <InputText col={5} name="descricao" label="Descrição" value={params.descricao} onChange={handleChange}/>
        </>
    );

}
