import React, {useCallback, useEffect, useState} from "react";
import {InputText} from "../../components/inputtext";
import {PanelContent} from "../../components/panel";
import {equipamentoService} from "../../service/equipamentoService";
import {ClienteContratoDropdown} from "../../components/autocomplete/ClienteContratoDropdown";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {useDialog} from "../../utils/dialogContext";
import {ProgressDialog} from "../../components/progressdialog/ProgressDialog";

export const acessorios = [
	{name: "CABO_COMUNICACAO", label: "Cabo de Comunicação"},
	{name: "CABO_FORCA", label: "Cabo de Força"},
	{name: "ALCA", label: "Alça"},
	{name: "MANOPLA", label: "Manopla"},
	{name: "BATERIA", label: "Bateria"},
	{name: "ETIQUETA", label: "Etiqueta"},
	{name: "RIBBON", label: "Ribbon"},
	{name: "CARTAO", label: "Cartão"},
	{name: "FONTE", label: "Fonte"},
	{name: "CHIP", label: "Chip"},
	{name: "OUTRO", label: "Outros", col: 6}
];

export function EditarOrdemServicoItemDadosPrincipais({ordemServico, setOrdemServico, item, handleChange}) {

	const {showDialog} = useDialog();
	const [lock, setLock] = useState(1);
	const [serial, setSerial] = useState(null);

	useEffect(() => {
		setSerial(item.equipamento?.serial);
		setLock(!item.equipamento?.id ? 1 : 0);
	}, []);

	const handleChangeEquipamento = useCallback(event => {
		let {equipamento} = item;
		if (!equipamento) {
			equipamento = equipamentoService.criar();
		}
		equipamento[event.name] = event.value;
		equipamento._alterado = true;
		handleChange({name: "equipamento", value: equipamento});
	}, [handleChange, item]);

	function handleChangeOrdemServico(event) {
		setOrdemServico(prevOrdemServico => ({...prevOrdemServico, [event.name]: event.value}));
	}

	async function handleCheckEquipamento() {
		if (item.id || !item.equipamento?.serial?.length || item.equipamento.serial === serial) {
			return;
		}
		showDialog(
			<ProgressDialog mode="indeterminate" onProgress={async setProgresso => {
				const equipamento = await equipamentoService.buscarEquipamentoCliente(
					ordemServico.cliente?.id,
					item.equipamento?.serial
				);
				if (equipamento) {
					setOrdemServico(prevOrdemServico => {
						prevOrdemServico.itens[0].equipamento = equipamento;
						return {...prevOrdemServico};
					});
				} else {
					setOrdemServico(prevOrdemServico => {
						prevOrdemServico.itens[0].equipamento = {
							...equipamentoService.criar(),
							serial: item.equipamento.serial
						}
						return {...prevOrdemServico};
					});
				}
				setLock(0);
				setSerial(item.equipamento.serial);
				setProgresso(1);
			}}/>
		);
	}

	return (
		<PanelContent>
			<InputText
				readOnly={ordemServico.id}
				col={3}
				label="Nº de Série" name="serial" value={item.equipamento?.serial}
				onChange={handleChangeEquipamento}
				onBlur={handleCheckEquipamento}
				invalid={!item.equipamento?.serial?.length ? "Campo obrigatório" : ""}
			/>
			<ProdutoAutoComplete
				disabled={lock || item.equipamento.id}
				col={4}
				label="Modelo do Equipamento"
				name="produto"
				value={item.equipamento?.produto}
				onChange={handleChangeEquipamento}
				invalid={!item.equipamento?.produto?.id ? "Campo obrigatório" : ""}
			/>
			<InputText
				disabled={lock}
				col={2}
				name="numeroAtivo"
				label="Nº do Ativo"
				value={item.equipamento?.numeroAtivo}
				onChange={handleChangeEquipamento}
			/>
			<ClienteContratoDropdown
				disabled={ordemServico.id}
				autoSelect
				tipo={ordemServico.tipo}
				col={3}
				className="clienteContrato"
				serial={serial || "???"}
				cliente={ordemServico.cliente}
				value={ordemServico.contrato}
				onChange={handleChangeOrdemServico}
			/>
			<InputText
				disabled={lock}
				col={12}
				name="complemento"
				label="Complemento (Detalhamento do Equipamento)"
				value={item.complemento}
				onChange={handleChange}
			/>
			<InputText
				disabled={lock}
				maxLength={255}
				col={12}
				name="problemaTitulo"
				label="Título do Chamado"
				value={item.problemaTitulo}
				onChange={handleChange}
			/>
			<InputText
				disabled={lock}
				multiline
				style={{height: "6em"}}
				maxLength={3800}
				col={12}
				name="problemaIndicado"
				label="Problema Indicado"
				value={item.problemaIndicado}
				onChange={handleChange}
			/>
			<InputText
				disabled={lock}
				multiline
				style={{height: "10em"}}
				maxLength={3800}
				col={12}
				name="laudoExterno"
				label="Laudo Externo"
				value={item.laudoExterno}
				onChange={handleChange}
			/>
			<InputText
				disabled={lock}
				multiline
				style={{height: "13em"}}
				maxLength={3800}
				col={12}
				name="laudoInterno"
				label="Laudo Interno"
				value={item.laudoInterno}
				onChange={handleChange}
			/>
		</PanelContent>
	);

}
