import React, {useMemo, useState} from 'react';
import { FiltrosFechamento } from './FiltroFechamento';
import { TabelaFechamento } from './TabelaFechamento';

export function Fechamento() {

    const [fechamento, setFechamento] = useState([]);

    return (
        <div>
            <FiltrosFechamento setValue={useMemo(() => setFechamento, [])}/>
            <TabelaFechamento value={useMemo(() => fechamento, [fechamento])}/>
        </div>
    );
}