import React, { useCallback, useEffect, useState } from "react";

import { ColumnGroup } from "primereact/columngroup";
import { Column } from "primereact/column";
import { Row } from "primereact/row";

import { ateOuAcima, faixaOuUnica, tipoComissaoService } from "../../services/tipoComissaoService";
import { InputCurrency, InputNumber } from "../../../../components/inputnumber";
import { ConfirmDialogV2 } from "../../../../components/confirmdialog";
import { SelectButton } from "../../../../components/selectbutton";
import { InputText } from "../../../../components/inputtext";
import { DataTable } from "../../../../components/datatable";
import { Button } from "../../../../components/button";
import { withDialog } from "../../../../utils/dialogContext";
import { isNotNull, isRequired } from "../../../../utils/fieldValidator";
import { Checkbox } from "../../../../components/checkbox";

export const EditarDadosPrincipais =  withDialog(({tipoComissao, setTipoComissao, messages, setMessages, showDialog}) => {
    const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
    const [bloquear, setBloquear] = useState(false);

    useEffect(() => {
        if(tipoComissao.itens.find(item => item.acima === true )){
            setBloquear(true)
        }else{
            setBloquear(false)
        }
    }, [tipoComissao.itens])

    const  validarItem = useCallback((e, index, data) =>  {
        let erro;
        let itens = [];
        
		switch (e?.target.name) {
            case 'percentual':
                erro = isNotNull(data.percentual)
                itens = [...messages.itens]
                itens[index] = {...messages?.itens[index], percentual: erro}
                break;
            case 'valor':
                erro = isNotNull(data.valor)
                itens = [...messages.itens]
                itens[index] = {...messages?.itens[index], valor: erro}
                break;
            default:
                break;
        }

        setMessages((messages) => {
            return {
                ...messages,
                itens
            }
        })
	}, [messages.itens, setMessages])

    const handleChangeItem = useCallback((rowIndex, event) => {
        const  itens = tipoComissao.itens.map((faixa, index) => {
            if (rowIndex === index) {
                return {...faixa,  [event.name]: event.value};
            } else {
                return faixa;
            }
        });
        setTipoComissao((tipoComissaoAnterior) => ({...tipoComissaoAnterior, itens}))
    }, [setTipoComissao, tipoComissao])

    const handleChange = useCallback((event) => {

        setTipoComissao({...tipoComissao, [event.name]: event.value});
    }, [setTipoComissao, tipoComissao])

    const porcentagemTemplate = useCallback((colunmEvent1, colunmEvent2) => {
        return <InputNumber
            col={12}
            value={tipoComissao?.itens[colunmEvent2.rowIndex]?.percentual} 
            onChange={(e) => handleChangeItem(colunmEvent2.rowIndex, e)}
            name='percentual'
            onBlur={(e) => validarItem(e, colunmEvent2.rowIndex, colunmEvent1)}
            invalid={messages.itens?.[colunmEvent2.rowIndex]?.percentual}
            reduced
            prefix="%"
            minFractionDigits={2}
            maxFractionDigits={2}
            min={0} max={100}
            disabled={bloquear && tipoComissao.itens.findIndex(item => item.acima === true) !== colunmEvent2.rowIndex}
        />
    }, [bloquear, handleChangeItem, messages.itens, tipoComissao.itens, validarItem])

    const valorrTemplate = useCallback((colunmEvent1, colunmEvent2) => {
        return <InputCurrency
            col={12}
            value={tipoComissao?.itens[colunmEvent2.rowIndex]?.valor} 
            onChange={(e) => handleChangeItem(colunmEvent2.rowIndex, e)}
            name='valor'
            label={null}
            onBlur={(e) => validarItem(e, colunmEvent2.rowIndex, colunmEvent1)}
            invalid={messages.itens?.[colunmEvent2.rowIndex]?.valor}
            reduced={!messages.itens?.[colunmEvent2.rowIndex]?.valor}
            disabled={bloquear}
        />
    }, [bloquear, handleChangeItem, messages.itens, tipoComissao.itens, validarItem])
    
    const acimaTemplate = useCallback((colunmEvent1, colunmEvent2) => {
        return <SelectButton 
            options={ateOuAcima} 
            onChange={(e) => {
                const  itens = tipoComissao.itens.map((faixa, index) => {
                    if (colunmEvent2.rowIndex === index) {
                        return {
                            ...faixa,  
                            valor: tipoComissao.itens.reduce((max, item) => (item.valor > max ? item.valor : max), tipoComissao.itens[0].valor), 
                            acima: e.value
                        };
                    } else {
                        return faixa;
                    }
                });
                setTipoComissao((tipoComissaoAnterior) => ({...tipoComissaoAnterior, itens}))
            }}
            col={12} 
            name="acima" 
            value={tipoComissao?.itens[colunmEvent2.rowIndex]?.acima} 
            naoPodeDesselecionar
            disabled={bloquear && tipoComissao.itens.findIndex(item => item.acima === true) !== colunmEvent2.rowIndex}
    />
    }, [bloquear, setTipoComissao, tipoComissao.itens])

    const adicionarFaixa = useCallback(() => {
        const novaFaixa = tipoComissao.tipo === 'FAIXA' ? tipoComissaoService.criarFaixa(tipoComissao?.itens) : tipoComissaoService.criarFaixaUnica(tipoComissao?.itens)
        setTipoComissao({...tipoComissao, itens: [...tipoComissao.itens, novaFaixa]})
    }, [setTipoComissao, tipoComissao])

    const excluirFaixa = useCallback(() => {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os acompanhamentos selecionados?" onYes={() => {
	        const itens = tipoComissao.itens.filter(osi => !linhasSelecionadas.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
            if(itens.length === 0){
                setMessages(messages => {
                    return {...messages, itens: []}
                })
            }
	        setTipoComissao({...tipoComissao, itens})
	        setLinhasSelecionadas([]);
        }}/>);

    }, [linhasSelecionadas, setMessages, setTipoComissao, showDialog, tipoComissao])
    
    const servicoHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={12} header={
					<div>
						Faixas
					</div>
				}/>
			</Row>
			<Row>
				<Column colSpan={12} header={
					<div>
						<Button 
                            icon="pi pi-plus" 
                            onClick={adicionarFaixa}
                            disabled={bloquear || (tipoComissao.tipo === 'UNICA' && tipoComissao.itens.length === 1)}
                        />
                        <Button
                            danger
                            icon='fa-solid fa-x'
                            onClick={excluirFaixa}
                            disabled={linhasSelecionadas.length === 0}
                        />
					</div>
				}/>
			</Row>
            <Row>
                    <Column selectionMode="multiple" headerStyle={{width: '1em'}} alignHeader={'center'}/>
                    <Column header='Item' headerStyle={{width: '3em'}} alignHeader={'center'}/>
                    <Column header="% de Comissão"  />
                    {tipoComissao.tipo === 'FAIXA' && <Column header=" "  />}
                    {tipoComissao.tipo === 'FAIXA' && <Column header="Valor Máximo"/>}
			</Row>
		</ColumnGroup>
	);

	const servicoFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={12} footer={
					<div>
						<Button 
                            icon="pi pi-plus" 
                            onClick={adicionarFaixa}
                            disabled={bloquear || (tipoComissao.tipo === 'UNICA' && tipoComissao.itens.length === 1)}
                        />
                        <Button
                            danger
                            icon='fa-solid fa-x'
                            onClick={excluirFaixa}
                            disabled={linhasSelecionadas.length === 0}
                        />
					</div>
				}/>
			</Row>
		</ColumnGroup>
	);

    const handleFaixaUnica = (e) => {
        setTipoComissao((prevState) => ({
            ...prevState,
            [e.name]: e.value,
            itens: [],
        }));
    }

    return (
        <>  
            <InputText 
                col={9}
                label='Descrição'
                value={tipoComissao.descricao} 
                onChange={handleChange}
                name='descricao'
                invalid={messages.descricao}
                required
                onBlur={() => {
                    messages.descricao = isRequired(tipoComissao.descricao);
                    setMessages({...messages});
                }}
            />
       		<SelectButton 
                options={faixaOuUnica}  
                label="Tipo de Faixa" 
                hint={`
                    Individual : São permitidas múltiplas faixas, e a análise das comissões é feita por linha.
                    Somatória : São permitidas múltiplas faixas, e a analise das comissões é feita através de um somatório	dos itens.                     
                    Única: Apenas uma faixa de base é utilizada, a análise de comissão é feita individualmente e permite edição dos campos durante o fechamento.
                `}
                hintPosition={'bottom'}
                onChange={handleFaixaUnica} 
                col={3} 
                name="tipo" 
                value={tipoComissao.tipo}
            />

            <div className="col-2">
                <label>&nbsp;</label>
                <Checkbox label="Validar comissão paga" title="Se o flag estiver marcado, ativa a validação de comissão paga parametrizada no contrato." 
                            name="validaComissaoPaga" value={tipoComissao.validaComissaoPaga} onChange={handleChange}/>
            </div>
            <div className="col-2">
                <label>&nbsp;</label>
                <Checkbox label="Aplicar divisor"  title="Se o flag estiver marcado, aplica o divisor parametrizado no contrato." 
                            name="aplicarDivisor" value={tipoComissao.aplicarDivisor} onChange={handleChange}/>
            </div>
            <div className="col-2">
                <label>&nbsp;</label>
                <Checkbox label="Contrato renovado"  title="Se o flag estiver marcado, o ícone de Contrato Renovado será mostrado." 
                            name="renovacaoContrato" value={tipoComissao.renovacaoContrato} onChange={handleChange}/>
            </div>

            <div className='col-12 p-0'>
                <DataTable 
                    value={tipoComissao.itens}
                    emptyMessage='Nenhuma faixa foi adicionada.'
                    valuelength={tipoComissao.itens?.length}
                    rows={100}
                    paginator={false}
                    reorderableRows 
                    selectionMode="checkbox"
                    selection={linhasSelecionadas}
                    onSelectionChange={e => setLinhasSelecionadas(e.value)} 
                    headerColumnGroup={servicoHeaderGroup}
                    footerColumnGroup={servicoFooterGroup}
                    size='small'
                >
                    <Column selectionMode="multiple" headerStyle={{width: '1em', textAlign: 'center'}} />
                    <Column field='item' header='Item' style={{width: '3em', textAlign: 'center'}} />
                    <Column header="% de Comissão" body={porcentagemTemplate} style={{ width: `${tipoComissao.tipo !== 'UNICA' ? '15em' : '*'}`}} />
                    {tipoComissao.tipo !== 'UNICA' && <Column body={acimaTemplate} style={{ width: '15em'}} />}
                    {tipoComissao.tipo !== 'UNICA' && <Column header="Valor Máximo" body={valorrTemplate} style={{ width: '*'}} />}
                </DataTable>
                
            </div>
        </>
    );
});