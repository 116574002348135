import React, {useState} from 'react';
import {AutoComplete} from '.';
import {equipamentoService} from '../../service/equipamentoService';

export function EquipamentoAutoComplete(props) {

    const [equipamentos, setEquipamentos] = useState([]);
    const [emptyMessage, setEmptyMessage] = useState("");

    function completeMethod(event) {
        console.log(props.repetidos)
        const query = [];
        if (props?.repetidos?.length > 0) query.push(`repetidos=${props.repetidos}`);
        query.push(`serial=${encodeURIComponent(event.query)}`);
        equipamentoService.listar(query).then(equipamentos => {
            setEquipamentos(equipamentos);
            if (!equipamentos.length) {
                setEmptyMessage("Nenhum registro encontrado");
            } else {
                setEmptyMessage("");
            }
        });
    }

    return (
        <AutoComplete
            placeholder="descrição, número de série ou número do ativo"
            label="Equipamento"
            name="equipamento"
            value={props.value}
            onChange={props.onChange}
            completeMethod={completeMethod}
            suggestions={equipamentos}
            invalid={emptyMessage}
            icon="fa-solid fa-terminal"
            {...props}
        />
    );

}
