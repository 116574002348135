import { buildValidator, isEntityRequired} from "../utils/fieldValidator";
import { apiAnonymous } from "./api";

const resourceUrl = "/emailOrcamento";

export const emailOrcamentoService = {
	criar() {
		return {
			id: null,
			_key: Math.random(),
			equipamento: {
				serial: "",
				produto: null
			},
			flags: [],
			pecas: [],
			servicos: [],
			fotos: [],
			lacresRetirados: [],
			lacresAfixados: [],
			acessorios: [],
			movimentacoes: [],
			orcamentos: [],
			comprovantes: [],
			laudos: []
		};
	},

	validar: (emailOrcamento) => {
		const messages = buildValidator();
        messages.clienteFaturamento = isEntityRequired(emailOrcamento.clienteFaturamento);
        return messages;
	},

	salvarDtoAprovacaoOrcamento: async (orcamento) => {
		return await apiAnonymous.post(`public${resourceUrl}/dtoAprovacaoOrcamento`, orcamento).then(response => response.data);
	},

	buscarDtoAprovacaoOrcamento: async (id) => {
		return await apiAnonymous.get(`public${resourceUrl}/dtoAprovacaoOrcamento/${id}`).then(response => response.data);
	},
};