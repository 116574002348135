import React from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {labelStatus} from "../../service/seloIpemService";
import {formatNumeroSeloIpem} from "../../utils/fieldValidator";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";

export const TabelaSeloIpem = React.memo(({value, selosSelecionados, setSelosSelecionados}) => {

    const navigate = useNavigate();

    function handleEditar(event) {
        navigate(`/selos-ipem/${event.data.id}`);
    }

    const seloIpemHeaderGroup = (
        <ColumnGroup>
            <Row>
                <Column selectionMode="multiple" style={{width: "3em"}}/>
                <Column style={{width: "12em"}} header="Número"/>
                <Column style={{width: "12em"}} header="Status"/>
                <Column header="Responsável"/>
                <Column header="OS"/>
                <Column header="Equipamento"/>
            </Row>
        </ColumnGroup>
    );

    return (
        <DataTable selectionMode="checkbox" onSelectionChange={e => setSelosSelecionados(e.value)} selection={selosSelecionados} headerColumnGroup={seloIpemHeaderGroup} value={value} onRowDoubleClick={handleEditar} valuelength={value?.length}>
            <Column selectionMode="multiple"/>
            <Column sortable header="Número" field="numero" body={si => formatNumeroSeloIpem(si.numero)}/>
            <Column sortable header="Status" field="status" body={si => labelStatus[si.status]}/>
            <Column sortable header="Responsável" field="responsavel" body={si => si.responsavel?.nome}/>
            <Column sortable header="OS" field="os" body={si => si.ordemServico}/>
            <Column sortable header="Equipamento" field="equipamento" body={si => si.equipamento?.autoComplete}/>
        </DataTable>
    );

});
