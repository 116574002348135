import {QueryParamsKeys} from "../defaultQueryParams";
import {ordemServicoService} from "../../service/ordemServicoService";
import {useDefaultQueryClient} from "./defaultQueryClient";
import {useAuth} from "../../context/AuthContext";
import {useQueryParams} from "../QueryParamContext";
import {pedidoVendaService} from "../../pages/Comercial/services/pedidoVendaService";
import {backofficeEstoqueService} from "../../service/backofficeEstoqueService";
import {prateleiraService} from "../../service/prateleiraService";
import {contratoService} from "../../service/contratoService";
import {produtoService} from "../../service/produtoService";
import {ansService} from "../../service/ansService";
import {calendarioService} from "../../service/calendarioService";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";
import {ordemServicoFluxoService} from "../../service/ordemServicoFluxoService";
import {faturamentoService} from "../../service/faturamentoService";
import {tiposDeClausulaService} from "../../service/tiposDeClausula";
import {clienteService} from "../../service/clienteService";
import {usuarioService} from "../../service/usuarioService";
import {equipamentoService} from "../../service/equipamentoService";
import {seloIpemService} from "../../service/seloIpemService";
import {estoqueMovimentoService} from "../../service/estoqueMovimentoService";
import {empresaService} from "../../service/empresaService";
import {produtoTipoService} from "../../service/produtoTipoService";
import {listaPrecoService} from "../../pages/Comercial/services/listaPrecoService";
import {contaEmailService} from "../../service/contaEmailService";
import {perfilService} from "../../service/perfilService";
import {marcaService} from "../../service/marcaService";
import {avaliacaoService} from "../../service/avaliacaoService";

export function useQueryClientBackoffice() {
    return useDefaultQueryClient(QueryParamsKeys.Backoffice, ordemServicoService.listarOrdensServicoFluxoRecepcao);
}

export function useQueryClientLaboratorio() {
    return useDefaultQueryClient(QueryParamsKeys.Laboratorio, ordemServicoService.listarOrdensServicoFluxoLaboratorio);
}

export function useQueryClientCampo() {
    const {roles, usuario} = useAuth();
    const {params} = useQueryParams(QueryParamsKeys.Campo);
    function queryFunction() {
        return ordemServicoService.listarOrdensServicoFluxoCampo({...params, responsaveis: roles.OSSL ? params.responsaveis : [usuario]});
    }
    return useDefaultQueryClient(QueryParamsKeys.Campo, queryFunction);
}

export function useQueryClientCampoAgendamento() {
    const {roles, usuario} = useAuth();
    const {params} = useQueryParams(QueryParamsKeys.CampoAgendamento);
    function queryFunction() {
        return ordemServicoService.listarOrdensServicoFluxoCampo({...params, responsaveis: roles.OSSL ? params.responsaveis : [usuario]});
    }
    return useDefaultQueryClient(QueryParamsKeys.CampoAgendamento, queryFunction);
}

export function useQueryClientComercial() {
    const {roles, usuario} = useAuth();
    const {params} = useQueryParams(QueryParamsKeys.Comercial);
    function queryFunction() {
        return ordemServicoService.listarOrdensServico({...params, responsavel: roles.OSSL ? params.responsavel : usuario});
    }
    return useDefaultQueryClient(QueryParamsKeys.Comercial, queryFunction);
}

export function useQueryClientEstoqueCompra() {
    return useDefaultQueryClient(QueryParamsKeys.EstoqueCompra, ordemServicoService.listarEstoqueCompras);
}

export function useQueryClientPedidoVenda() {
    return useDefaultQueryClient(QueryParamsKeys.PedidoVenda, pedidoVendaService.listarPedidosVenda);
}

export function useQueryClientBackofficeEstoque() {
    return useDefaultQueryClient(QueryParamsKeys.BackofficeEstoque, backofficeEstoqueService.listar);
}

export function useQueryClientPrateleira() {
    return useDefaultQueryClient(QueryParamsKeys.Prateleira, prateleiraService.listarPrateleiras);
}

export function useQueryClientProjeto() {
    return useDefaultQueryClient(QueryParamsKeys.Projeto, contratoService.listarProjetos);
}

export function useQueryClientContrato() {
    return useDefaultQueryClient(QueryParamsKeys.Contrato, contratoService.listarContratos);
}

export function useQueryClientEstoqueRecondicionado() {
    return useDefaultQueryClient(QueryParamsKeys.EstoqueRecondicionado, produtoService.listarProduto);
}

export function useQueryClientAcordoNivelServico() {
    return useDefaultQueryClient(QueryParamsKeys.AcordoNivelServico, ansService.listarAns);
}

export function useQueryClientCalendario() {
    return useDefaultQueryClient(QueryParamsKeys.Calendario, calendarioService.listarCalendarios);
}

export function useQueryClientCompras() {
    return useDefaultQueryClient(QueryParamsKeys.Compras, ordemServicoItemService.buscarCompras);
}

export function useQueryClientFluxos() {
    return useDefaultQueryClient(QueryParamsKeys.Fluxos, ordemServicoFluxoService.listarFluxos);
}

export function useQueryClientFaturamento() {
    const {vendedor} = useAuth();
    const {params} = useQueryParams(QueryParamsKeys.Faturamento);
    function queryFunction() {
        return faturamentoService.listarFaturamentos({...params, vendedor});
    }
    return useDefaultQueryClient(QueryParamsKeys.Faturamento, queryFunction);
}

export function useQueryClientProduto() {
    return useDefaultQueryClient(QueryParamsKeys.Produto, produtoService.listarProduto);
}

export function useQueryClientTipoClausula() {
    return useDefaultQueryClient(QueryParamsKeys.TipoClausula, tiposDeClausulaService.listarTiposClausula);
}

export function useQueryClientCliente() {
    return useDefaultQueryClient(QueryParamsKeys.Cliente, clienteService.listarClientes);
}

export function useQueryClientUsuario() {
    return useDefaultQueryClient(QueryParamsKeys.Usuario, usuarioService.listarUsuarios);
}

export function useQueryClientEquipamento() {
    return useDefaultQueryClient(QueryParamsKeys.Equipamento, equipamentoService.listarEquipamentos);
}

export function useQueryClientSeloIpem() {
    return useDefaultQueryClient(QueryParamsKeys.SeloIpem, seloIpemService.listarSelosIpem);
}

export function useQueryClientMovimentoEstoque() {
    return useDefaultQueryClient(QueryParamsKeys.MovimentoEstoque, estoqueMovimentoService.listarEstoqueMovimentos);
}

export function useQueryClientEmpresa() {
    return useDefaultQueryClient(QueryParamsKeys.Empresa, empresaService.listarEmpresas);
}

export function useQueryClientMarcaProduto() {
    return useDefaultQueryClient(QueryParamsKeys.MarcaProduto, marcaService.listarMarcasProduto);
}

export function useQueryClientTipoProduto() {
    return useDefaultQueryClient(QueryParamsKeys.TipoProduto, produtoTipoService.listarTiposProduto);
}

export function useQueryClientListaPreco() {
    return useDefaultQueryClient(QueryParamsKeys.ListaPreco, listaPrecoService.listarListasPreco);
}

export function useQueryClientContaEmail() {
    return useDefaultQueryClient(QueryParamsKeys.ContaEmail, contaEmailService.listarContasEmail);
}

export function useQueryClientPerfil() {
    return useDefaultQueryClient(QueryParamsKeys.Perfil, perfilService.listarPerfis);
}

export function useQueryClientSuporte() {
    return useDefaultQueryClient(QueryParamsKeys.Suporte, ordemServicoService.listarOrdensServicoSuporte);
}

export function useQueryClientQuestaoAvaliacao() {
    return useDefaultQueryClient(QueryParamsKeys.QuestaoAvaliacao, avaliacaoService.listarQuestoes);
}
