import React from "react";
import {Button} from "./index";
import {useBreakpoint} from "../../context/BreakpointContext";

export function SearchButton({query, onSearch}) {

    const {isLargeDevice} = useBreakpoint();

    return (
        <Button
            loading={query.isLoading || query.isRefetching}
            disabled={query.isLoading || query.isRefetching}
            icon="pi pi-search"
            label={isLargeDevice ? "Procurar" : null}
            onClick={onSearch}
            autowidth={!isLargeDevice}
        />
    );

}
