import React, {useEffect, useState} from 'react';

import {useParams} from "react-router-dom";

import {useAuth} from '../../context/AuthContext';
import {optionsStatus, seloIpemService} from "../../service/seloIpemService";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {InputMask} from "../../components/inputmask";
import {FormEdit} from "../../components/form/Edit";
import {Dropdown} from "../../components/dropdown";
import {buildValidator, isSeloIpemValido} from "../../utils/fieldValidator";

export function EditarSeloIpem() {

	const {id} = useParams();
	const {roles} = useAuth();

	const [seloIpem, setSeloIpem] = useState(seloIpemService.criar());

	const [messages, setMessages] = useState(buildValidator());

	function handleChange(event) {
		setSeloIpem({...seloIpem, [event.name]: event.value});
	}

	useEffect(() => {
		if (id !== "new") {
			seloIpemService.buscar(id).then(setSeloIpem);
		}
	}, [id]);

	return (
		<FormEdit 
			podeDeletar={!!roles.SIPD}
			podeSalvar={!!roles.SIPC}
			descricao="Selo do IPEM"
			onValidate={setMessages}
			service={seloIpemService}
			value={seloIpem}
			blocked={id !== "new" && !seloIpem.id}
		>
			<Dropdown label="Status" col={2} name="status" value={seloIpem.status} onChange={handleChange} options={optionsStatus}/>
			<InputMask unmask mask="99.999.999-9" required onBlur={() => {
				messages.numero = isSeloIpemValido(seloIpem.numero);
				setMessages({...messages});
			}} invalid={messages.descricao} col={4} label="Número" name="numero" value={seloIpem.numero} onChange={handleChange}/>
			<UsuarioAutoComplete col={6} label="Responsável" value={seloIpem.responsavel} name="responsavel" onChange={handleChange}/>
		</FormEdit>
	);
}