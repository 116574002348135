import {api} from "./api";

export const relatorioOrdemServicoRestricaoService = {

    async listar() {
        return await api.get("/relatorios/ordemservico-restricao/geral").then(res => res.data);
    },

    async listarRelatorioEvolucao(params) {
        return await api.get(`/relatorios/ordemservico-restricao/evolucao?${Object.keys(params).filter(k => params[k]).map(k => `${k}=${params[k]}`).join("&")}`).then(res => res.data);
    }

}
