import moment from "moment";

async function imprimirEtiquetaZebra(printContent) {
	const newWindow = window.open();
	newWindow.document.open("text/plain");
	newWindow.document.write(printContent);
	newWindow.document.close();
	newWindow.focus();
	newWindow.print();
	newWindow.close();
}

const etiquetaOs = `
	^XA
	^FO210,40^A0N,160,120^FDOS
	^FS^FO56,170^A0N,160,120^FD--numero--
	^FS^FT40,400^B3N,N,86,N,N^FD--numero--
	^FS
	^XZ
`;

const etiquetaChaveUsuario = `
	^XA
	^XZ
`;

function substituirCamposEtiquetaOs(ordemServico) {
	return etiquetaOs
		.replace(/--numero--/g, ordemServico.numero);
}

function substituirCamposEtiquetaChaveUsuario(uid) {
	return etiquetaChaveUsuario
		.replace(/--uid--/g, uid);
}

export async function imprimirEtiquetaOsZebra(ordemServico) {
	await imprimirEtiquetaZebra(substituirCamposEtiquetaOs(ordemServico));
}

export async function imprimirEtiquetasOsZebra(ordensServico) {
	await imprimirEtiquetaZebra(ordensServico.map(substituirCamposEtiquetaOs).join(""));
}

const etiquetaLaudo = `
	^XA
	^PF0
	^FS^FT186,120^AAN,27,15^FD--data--
	^FS^FT170,170^AAN,27,15^FD--cliente--
	^FS^FT230,226^AAN,27,15^FD--marca--
	^FS^FT170,270^AAN,27,15^FD--modelo--
	^FS^FT98,322^AAN,27,15^FD--serial--
	^FS^FT96,416^AAN,27,15^FD--ativo--
	^FS^FT420,320^AAN,27,15^FH^FD--ativo--
	^FS^FT320,420^AAN,27,15^FD--notaFiscal--
	^FS^FT128,470^AAN,27,15^FD--responsavel--
	^FS^FT169,370^AAN,27,15^FH^FD--problemaIndicado--
	^FS^BY2,3.0^FS^FT380,1003^AAN,27,20^FD--numero--
	^FS^FT280,974^B3N,N,86,N,N^FD--numero--
	^FS
	^XZ
`;

function cleanString(string) {
	return string ? string.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9-]/g, " ") : "";
}

function substituirCamposEtiquetaLaudoDto(ordemServico) {
	return etiquetaLaudo
		.replace(/--data--/g, moment().format("DD-MM-YYYY"))
		.replace(/--numero--/g, ordemServico.numero)
		.replace(/--ativo--/g, cleanString(ordemServico.equipamento.numeroAtivo))
		.replace(/--cliente--/g, cleanString(ordemServico.cliente.razaoSocial))
		.replace(/--notaFiscal--/g, cleanString(String(ordemServico.notaFiscal?.numero || "")))
		.replace(/--numero--/g, ordemServico.numero)
		.replace(/--marca--/g, cleanString(ordemServico.equipamento.produto.marca?.descricao || ""))
		.replace(/--modelo--/g, cleanString(ordemServico.equipamento.produto.descricao))
		.replace(/--serial--/g, cleanString(ordemServico.equipamento.serial))
		.replace(/--problemaIndicado--/g, cleanString(ordemServico.problemaIndicado))
		.replace(/--responsavel--/g, cleanString(ordemServico.responsavel.nome))
		.replace(/[\r\n\t]/g, "");
}

function substituirCamposEtiquetaLaudo(ordemServico) {
	return etiquetaLaudo
		.replace(/--data--/g, moment().format("DD-MM-YYYY"))
		.replace(/--numero--/g, ordemServico.numero)
		.replace(/--ativo--/g, cleanString(ordemServico.itens[0].equipamento.numeroAtivo))
		.replace(/--cliente--/g, cleanString(ordemServico.cliente.razaoSocial))
		.replace(/--notaFiscal--/g, cleanString(String(ordemServico.notaFiscal?.numero || "")))
		.replace(/--numero--/g, ordemServico.numero)
		.replace(/--marca--/g, cleanString(ordemServico.itens[0].equipamento.produto.marca?.descricao || ""))
		.replace(/--modelo--/g, cleanString(ordemServico.itens[0].equipamento.produto.descricao))
		.replace(/--serial--/g, cleanString(ordemServico.itens[0].equipamento.serial))
		.replace(/--problemaIndicado--/g, cleanString(ordemServico.itens[0].problemaIndicado))
		.replace(/--responsavel--/g, cleanString(ordemServico.responsavel.nome))
		.replace(/[\r\n\t]/g, "");
}

export async function imprimirEtiquetaLaudoZebra(ordemServico) {
	await imprimirEtiquetaZebra(substituirCamposEtiquetaLaudo(ordemServico));
}

export async function imprimirEtiquetasLaudoZebra(ordensServico) {
	await imprimirEtiquetaZebra(ordensServico.map(substituirCamposEtiquetaLaudoDto).join(""));
}

export async function imprimirEtiquetaChaveUsuario(uid) {
	await imprimirEtiquetaZebra(substituirCamposEtiquetaChaveUsuario(uid));
}
