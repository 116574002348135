import React from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";

export const TabelaEstoques = React.memo(({value}) => {

    const navigate = useNavigate();

    function handleEditar(event) {
        navigate(`/estoques/${event.data.id}`);
    }

    return (
        <DataTable value={value} onRowDoubleClick={handleEditar} valuelength={value?.length}>
            <Column sortable header="Código" field="codigo"/>
            <Column sortable header="Descrição" field="descricao"/>
        </DataTable>
    );

});
