import React, { useCallback } from "react";

import { status } from "../../service/contratoService";
import { MultiSelect } from "../multiselect";

export function ContratoStatusSelect(props) {

	const contratoStatusOptionsTemplate = useCallback((option) => {
		return (
            <div className="flex align-items-center">
				<i style={{color: `${option.color}`}} className={`fa-lg	 fa-solid ${option.icon}`}/>
                <div className="ml-1">{option.label}</div>
            </div>
        );
	}, [])

	return <MultiSelect 
		label="Status" 
		showSelectAll={false} 
		options={status} 
		itemTemplate={contratoStatusOptionsTemplate}
		optionLabel={'label'}
		placeholderSelecionar=" status"
		display="chip"
		{...props}
	/>;
}