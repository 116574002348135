import React, { useEffect, useState } from "react";
import { usuarioService } from "../../service/usuarioService";
import { TabPanel, TabView } from "primereact/tabview";
import { PanelContent } from "../../components/panel";
import { EditarEmpresaUsuariosOrganogramas } from "./EditarEmpresaUsuariosOrganogramas";
import { EditarEmpresaUsuarioDadosPrincipais } from "./EditarEmpresaUsuarioDadosPrincipais";

export const EditarEmpresaUsuario = ({ empresa, usuarios, setUsuarios, atualizarUsuarios, setLoading, loading, filiais }) => {

  return (
    <div className="col-12">
      <TabView>
        <TabPanel
          header="Dados Principais"
          leftIcon="fa-solid fa-database mr-2"
        >
          <PanelContent>
            <EditarEmpresaUsuarioDadosPrincipais
              usuarios={usuarios}
              setUsuarios={setUsuarios}
              loading={loading}
              setLoading={setLoading}
              empresa={empresa}
              atualizarUsuarios={atualizarUsuarios}
              filiais={filiais}
            />
          </PanelContent>
        </TabPanel>
        <TabPanel header="Organogramas" leftIcon="fa-solid fa-database mr-2">
          <PanelContent>
            <EditarEmpresaUsuariosOrganogramas usuarios={usuarios} />
          </PanelContent>
        </TabPanel>
      </TabView>
    </div>
  );
};
