import React, {useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {EstoqueAutoComplete} from "../../components/autocomplete/EstoqueAutoComplete";
import {DataTable} from "../../components/datatable";
import {isStacked, useBreakpoint} from "../../context/BreakpointContext";
import {Column} from "primereact/column";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {InputNumber} from "../../components/inputnumber";
import {Button} from "../../components/button";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import {buildValidator, isBiggerThanZero, isEmpty, isEntityRequired} from "../../utils/fieldValidator";
import {estoqueMovimentoService} from "../../service/estoqueMovimentoService";
import {InputText} from "../../components/inputtext";
import {withDialog} from "../../utils/dialogContext";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import { InformationDialog } from "../../components/dialog/InformationDialog";

export const EstoqueMovimentoTransferencia = withDialog(({showDialog}) => {

	const navigate = useNavigate();
	const {breakpoint} = useBreakpoint();
	const isLargeDevice = breakpoint === "lg" || breakpoint === "md";
	const {usuario} = useAuth();

	const [params, setParams] = useState({
		estoqueEntrada: null,
		estoqueSaida: null,
		descricao: null
	});
	const [loading, setLoading] = useState(0);
	const [messages, setMessages] = useState(buildValidator());
	const [movimentosEstoque, setMovimentosEstoque] = useState([]);
	const [movimentosSelecionados, setMovimentosSelecionados] = useState([]);

	function adicionarMovimento() {
		movimentosEstoque.push({
			...estoqueMovimentoService.criar(),
			responsavel: usuario
		});
		setMovimentosEstoque([...movimentosEstoque]);
	}

	function removerMovimentos() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os itens selecionados?" onYes={() => {
			setMovimentosEstoque(movimentosEstoque.filter(em => !movimentosSelecionados.some(me => (me._key && me._key === em._key) || (me.id && me.id === em.id))));
		}}/>);
	}

	const movimentosControlSet = (
		<div>
			<Button icon="pi pi-plus" onClick={adicionarMovimento}/>
			<Button disabled={!movimentosSelecionados?.length} onClick={removerMovimentos} danger icon="fa-solid fa-minus"/>
		</div>
	);

	const movimentosHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={3} header={movimentosControlSet}/>
			</Row>
			<Row>
				<Column style={{width: "3em"}} selectionMode="multiple"/>
				<Column header="Produto"/>
				<Column header="Quantidade" style={{width: "12em"}}/>
			</Row>
		</ColumnGroup>
	);

	const movimentosFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={3} footer={movimentosControlSet}/>
			</Row>
		</ColumnGroup>
	);

	function validarMovimentos() {
		messages.movimentos = [...Array(movimentosEstoque.length)].map((_, i) => ({
			estoque: isEntityRequired(movimentosEstoque[i].estoque),
			produto: isEntityRequired(movimentosEstoque[i].produto),
			quantidade: isBiggerThanZero(movimentosEstoque[i].quantidade, "Campo obrigatório"),
			responsavel: isEntityRequired(movimentosEstoque[i].responsavel),
		}));
		const newMessages = {...messages};
		newMessages.isEmpty = () => isEmpty(newMessages);
		setMessages(newMessages);
	}

	function handleVoltar() {
		navigate(-1);
	}

	async function handleSalvar() {
		messages.estoqueEntrada = isEntityRequired(params.estoqueEntrada);
		messages.estoqueSaida = isEntityRequired(params.estoqueSaida);
		messages.movimentos = [];
		for (let i = 0; i < movimentosEstoque.length; ++i) {
			const message = estoqueMovimentoService.validar(movimentosEstoque[i]);
			message.estoque = null;
			messages.movimentos.push(message);
		}
		if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
			return;
		}
		showDialog(<ConfirmDialogV2 message="Deseja confirmar a transferência dos produtos em estoque?" onYes={async () => {
			setLoading(1);
			for (const movimentoEstoque of movimentosEstoque) {
				await estoqueMovimentoService.salvar({...movimentoEstoque, status: "MOVIMENTADO", descricao: params.descricao, quantidade: movimentoEstoque.quantidade, estoque: params.estoqueEntrada});
				await estoqueMovimentoService.salvar({...movimentoEstoque, status: "MOVIMENTADO", descricao: params.descricao, quantidade: movimentoEstoque.quantidade * -1, estoque: params.estoqueSaida});
			}
			setLoading(0);
			handleVoltar();
		}} />);
	}

	function handleChange(event) {
		setParams({...params, [event.name]: event.value});
	}

	function handleChangeMovimento(event) {
		movimentosEstoque[event.index][event.name] = event.value;
		setMovimentosEstoque([...movimentosEstoque]);
	}

	return (
		<Panel header="Registrar Transferência de Estoque">
			<PanelContent>
				<EstoqueAutoComplete onBlur={() => {
					messages.estoqueEntrada = isEntityRequired(params.estoqueEntrada);
					const newMessages = {...messages};
					newMessages.isEmpty = () => isEmpty(newMessages);
					setMessages(newMessages);
				}} required invalid={messages.estoqueEntrada} col={6} value={params.estoqueEntrada} name="estoqueEntrada" label="Estoque de Entrada" onChange={handleChange}/>
				<EstoqueAutoComplete onBlur={() => {
					messages.estoqueSaida = isEntityRequired(params.estoqueSaida);
					const newMessages = {...messages};
					newMessages.isEmpty = () => isEmpty(newMessages);
					setMessages(newMessages);
				}} required invalid={messages.estoqueSaida} col={6} value={params.estoqueSaida} name="estoqueSaida" label="Estoque de Saída" onChange={handleChange}/>
				<InputText col={12} value={params.descricao} onChange={handleChange} name="descricao" label="Identificação de Movimentação"/>
				<div className="col-12">
					<label>Produtos</label>
					<DataTable selectionMode="checkbox"
					           style={{marginTop: "-1em"}}
					           value={movimentosEstoque}
					           headerColumnGroup={movimentosHeaderGroup}
					           footerColumnGroup={movimentosFooterGroup}
					           selection={movimentosSelecionados}
					           onSelectionChange={e => setMovimentosSelecionados(e.value)}
					           header={isStacked() ? null : movimentosControlSet}
					           footer={isStacked() ? null : movimentosControlSet}
					>
						<Column selectionMode="multiple"/>
						<Column field="produto" header="Produto" body={(em, c) => <ProdutoAutoComplete disabled={em.status !== "REQUISITADO"} label={null} col={12} onBlur={validarMovimentos} index={c.rowIndex} name="produto" onChange={handleChangeMovimento} required invalid={messages.movimentos?.[c.rowIndex]?.produto} value={em.produto}/>}/>
						<Column field="quantidade" header="Quantidade" body={(em, c) => <InputNumber disabled={em.status !== "REQUISITADO"} label={null} col={12} onBlur={validarMovimentos} index={c.rowIndex} name="quantidade" onChange={handleChangeMovimento} required invalid={messages.movimentos?.[c.rowIndex]?.quantidade} value={em.quantidade}/>}/>
					</DataTable>
				</div>
			</PanelContent>
			<PanelFooter>
				<Button icon="pi pi-save" success loading={loading === 1} disabled={loading || !movimentosEstoque.some(em => em.status === "REQUISITADO" || em._salvar)} label={isLargeDevice ? "Salvar" : null} onClick={handleSalvar} autowidth={!isLargeDevice.toString()}/>
				<Button icon="pi pi-arrow-left" secondary disabled={loading} label={isLargeDevice ? "Voltar" : null} onClick={handleVoltar} autowidth={!isLargeDevice.toString()}/>
			</PanelFooter>
		</Panel>
	);

});
