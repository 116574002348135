import React, {useContext, useState} from "react";
import {AutoComplete} from "./index";
import {usuarioService} from "../../service/usuarioService";
import {DialogContext} from "../../utils/dialogContext";
import {Dialog, DialogFooter} from "../dialog";
import {PanelContent} from "../panel";
import {InputText} from "../inputtext";
import {Button} from "../button";
import {buildValidator, isRequired} from "../../utils/fieldValidator";
import {BlockUI} from "primereact/blockui";
import {If} from "../conditional/If";

export function ResponsavelAutoComplete(props) {

    const {showDialog} = useContext(DialogContext);
    const [responsaveis, setResponsaveis] = useState([]);

    async function listarResponsaveis(event) {
        const responsaveis = await usuarioService.listarResponsaveis(event.query);
        if (responsaveis.length) {
            setResponsaveis(responsaveis);
        } else {
            setResponsaveis([{id: -1, nome: event.query, autoComplete: `Cadastrar ${event.query}`}]);
        }
    }

    async function handleSelect(event) {
        if (event.value?.id === -1) {
            showDialog(<CadastrarUsuarioTerceirizado query={event.value.nome} onModalClose={value => {
                props.onChange({...event, value});
            }}/>);
        } else {
            props.onChange(event);
        }
    }

    function itemTemplate(usuario) {
        return (
            <div>
                <div><i className="fas fa-user"/> {usuario.autoComplete}</div>
                <If condition={props.mostrarDeslocamento}>
                    <div><i className="fas fa-road"/> <i>{usuario.distanciaPercorrida || 0}Km percorridos nos últimos 7 dias</i></div>
                </If>
            </div>
        );
    }

    return (
        <AutoComplete
            col={4}
            label="Responsável"
            name="responsavel"
            placeholder="nome ou e-mail"
            completeMethod={listarResponsaveis}
            suggestions={responsaveis}
            itemTemplate={itemTemplate}
            {...props}
            onChange={handleSelect}
        />
    );

}

function CadastrarUsuarioTerceirizado({query, onModalClose}) {

    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState(buildValidator());
    const [usuario, setUsuario] = useState({
        ...usuarioService.criar(),
        nome: query
    });

    function handleChange(event) {
        setUsuario(prevUsuario => ({...prevUsuario, [event.name]: event.value}));
    }

    async function handleSalvar() {
        const messages = buildValidator();
        messages.nome = isRequired(usuario.nome);
        messages.email = isRequired(usuario.email);
        if (messages.isEmpty()) {
            setLoading(true);
            const user = await usuarioService.salvar({...usuario, tipo: "TERCEIRIZADO", postBack: true});
            if (onModalClose) {
                onModalClose(user);
            }
            setLoading(false);
            handleClose();
        } else {
            setMessages(messages);
        }
    }

    function handleClose() {
        setVisible(false);
    }

    return (
        <Dialog visible={visible} onHide={handleClose} header="Cadastrar Responsável" style={{width: "550px"}}>
            <BlockUI blocked={loading}>
                <PanelContent>
                    <InputText invalid={messages.nome} label="Nome" col={12} name="nome" value={usuario.nome} onChange={handleChange}/>
                    <InputText invalid={messages.email} label="E-mail" col={6} name="email" value={usuario.email} onChange={handleChange}/>
                    <InputText invalid={messages.cpfCnpj} label="CPF/CNPJ" col={6} name="cpf" value={usuario.cpf} onChange={handleChange}/>
                </PanelContent>
                <DialogFooter>
                    <Button icon="fas fa-save" success onClick={handleSalvar} label="Salvar"/>
                    <Button icon="fas fa-times" dsecondary onClick={handleClose} label="Fechar"/>
                </DialogFooter>
            </BlockUI>
        </Dialog>
    );

}
