import React, { useMemo } from "react";
import { TabelaCliente } from "./TabelaCliente";
import { headerTemplate } from "../../utils/templates";
import { PanelContent, PanelFooter } from "../../components/panel";
import { InputText } from "../../components/inputtext";
import { TipoClienteSelect } from "../../components/autocomplete/tipoClienteSelect";
import { ToggleViewHidden } from "../../components/button/ViewHiddenButton";
import { SearchButton } from "../../components/button/SearchButton";
import { Panel } from "primereact/panel";
import { useQueryClientCliente } from "../../utils/query-clients/queryClients";
import { useQueryParams } from "../../utils/QueryParamContext";
import { QueryParamsKeys } from "../../utils/defaultQueryParams";
import { BlockUI } from "primereact/blockui";
import { RefetchIndicator } from "../../components/refetchindicator/RefetchIndicator";
import { VendedorAutoComplete } from "../../components/autocomplete/VendedorAutoComplete";
//import { ordemServicoService } from '../../service/ordemServicoService';

export function Clientes() {
  const { query, handleSearch } = useQueryClientCliente();
  const { params, handleChange } = useQueryParams(QueryParamsKeys.Cliente);

  return (
    <BlockUI blocked={query.isLoading}>
      <Panel headerTemplate={() => headerTemplate("Clientes", "fa-user-tie")}>
        <PanelContent>
          <InputText
            col={5}
            label="Pesquise por"
            placeholder="um CNPJ (somente nº), nome fantasia ou razão social"
            value={params.textoDeBusca}
            name="textoDeBusca"
            onChange={handleChange}
          />
          <TipoClienteSelect
            col={2}
            name="tipo"
            value={params.tipo}
            onChange={handleChange}
          />
          <VendedorAutoComplete
            col={5}
            name="vendedor"
            value={params.vendedor}
            onChange={handleChange}
          />
        </PanelContent>
        <PanelFooter>
          <div style={{ float: "left" }}>
            <ToggleViewHidden params={params} onChange={handleChange} />
          </div>
          <SearchButton query={query} onSearch={handleSearch} />
          {/* <SearchButton query={query} onSearch={() => {
                        ordemServicoService.testeOLote()
                    }}/> */}
        </PanelFooter>
      </Panel>
      {useMemo(
        () => (
          <TabelaCliente value={query.data || []} />
        ),
        [query.data]
      )}
      <RefetchIndicator isRefetching={query.isRefetching} />
    </BlockUI>
  );
}
