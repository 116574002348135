import { api } from "./api";

export const relatorioOrdemServicoSuporteService = {
  async listar(params) {
    const query = [];
    if (params.cliente?.id) query.push(`cliente=${params.cliente.id}`);
    if (params.inicio) query.push(`inicio=${params.inicio}`);
    if (params.inicioFim) query.push(`inicioFim=${params.inicioFim}`);
    if (params.status) query.push(`status=${params.status}`);

    return await api
      .get(`/suportes/relatorios/listar${query ? `?${query.join("&")}` : ""}`)
      .then((res) => res.data);
  },

  async csv(params) {
    const query = [];
    if (params.cliente?.id) query.push(`cliente=${params.cliente.id}`);
    if (params.inicio) query.push(`inicio=${params.inicio}`);
    if (params.inicioFim) query.push(`inicioFim=${params.inicioFim}`);
    if (params.status) query.push(`status=${params.status}`);

    return await api
      .get(
        `/suportes/relatorios/csv${query ? `?${query.join("&")}` : ""}`,
        {},
        { responseType: "blob" }
      )
      .then((response) => response.data);
  },
};
