import React, {useEffect, useMemo, useState} from "react";

const websocketPath = process.env.REACT_APP_WSC_URL;

export function ServicesWebSocket(props) {

    const [connected, setConnected] = useState(false);
    const [keepAlive, setKeepAlive] = useState(null);

    let socket;

    useEffect(() => {
        connect();
        setKeepAlive(
            setInterval(() => {
                if (connected) {
                    socket.send("0");
                }
            }, 60000)
        );
        return () => {
            clearTimeout(keepAlive);
            socket.onclose = null;
            socket.close();
        }
    }, [socket]);

    function onMessageReceived(message) {
        if (props.onMessage) {
            props.onMessage(JSON.parse(message.data));
        } else {
            console.log(message.data);
        }
    }

    function connect() {
        socket = new WebSocket(`${websocketPath}/websocket${props.path}/websocket${props.params != null ? "?" + Object.keys(props.params).map(k => `${k}=${props.params[k]}`).join("&") : ""}`);
        socket.onmessage = onMessageReceived;
        socket.onopen = () => {
            console.log("Websocket connected");
            if (props.onConnect) {
                props.onConnect(true);
            }
            setConnected(true);
        }
        socket.onerror = () => {
            console.log("Websocket disconnected");
            try {
                socket.close();
            } catch (e) {
                console.error(e);
            }
        }
        socket.onclose = () => {
            setConnected(false);
            if (props.onConnect) {
                props.onConnect(false);
            }
            setTimeout(connect, 2000);
            console.log("Websocket will retry connection");
        };
    }

    if (connected) {
        return null;
    }

    return (
        <div className="tzm-websocket-loading">
            <i className="fas fa-spin fa-spinner" style={{marginRight: "4px"}} /> Conectando
        </div>
    );

}
