import classNames from "classnames";

export function calcularTamanho(col) {
	return classNames({
		[`xl:col-${col}`]: true,
		[`lg:col-${col}`]: true,
		[`md:col-${Math.min(12, col * 2 > 9 ? 12 : col * 2)}`]: true,
		"sm:col-12": true,
		"xm": true,
	});
}
