import React, {useState} from 'react';
import {AutoComplete} from '.';
import { vendedoreService } from '../../pages/Comercial/services/vendedorService';

export function VendedorAutoComplete(props) {

	const [clientes, setClientes] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("");

	function completeMethod(event) {
		let query = []
		
		if (props.ativos) {
			query = ["status=ATIVO"]
		}
		if (event.query.length) query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);
		vendedoreService.autocomplete(query).then(res => {
			setClientes(res);
			if (!res[0]) {
				setEmptyMessage("Nenhum registro encontrado");
			} else {
				setEmptyMessage("");
			}
		});
	}

	return (
		<AutoComplete
			col={3}
			placeholder="Nome, código e e-mail"
			label="Consultor de Vendas"
			name="vendedor"
			value={props.value}
			onChange={props.onChange}
			completeMethod={completeMethod}
			suggestions={clientes}
			invalid={emptyMessage}
			icon="fa-solid fa-user-tag"
			{...props}
		/>
	);

}
