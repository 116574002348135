import React, {useEffect, useRef, useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent} from "../../components/panel";
import {InputText} from "primereact/inputtext";
import {BlockUI} from "primereact/blockui";

import "./Coletor.scss";
import preval from "preval.macro";

const apiUrl = process.env.REACT_APP_API_HOST || "";

const BUILD_VERSION = preval`
	const moment = require('moment');
	module.exports = moment().format('YYMMDD-HHmm');
`;

export function Coletor() {

	const [blocked, setBlocked] = useState(false);

	const [params, setParams] = useState({
		numero: "",
		movimento: "",
		prateleira: "",
		autenticacao: ""
	});

	const [messages, setMessages] = useState([]);
	const [authorization, setAuthorization] = useState(null);

	useEffect(() => {
		for (const element of document.getElementsByClassName("layout-footer")) {
			element.style.display = "none";
		}
	}, []);

	const refNumero = useRef(null);
	const refPrateleira = useRef(null);
	const refAutenticacao = useRef(null);

	function registrarMovimentacao() {
		if (!params.numero?.trim().length || !params.prateleira?.trim().length) {
			return;
		}
		setBlocked(true);
		fetch(`${apiUrl}/public/ordens-servico/movimentacoes?uid=${authorization}`, {
			method: "POST",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json"
			}
		}).then(response => {
			switch (response.status) {
				case 200:
				case 201:
				case 202:
					setParams(prevParams => ({...prevParams, numero: ""}));
					if (refNumero.current) {
						refNumero.current.focus();
					}
					setMessages(prevMessages => [`OS ${params.numero} movimentada!`, ...prevMessages]);
					break;
				default:
					response.text().then(message => {
						setMessages(prevMessages => [message, ...prevMessages]);
					});
					break;
			}
			setBlocked(false);
		});
	}

	function handleChange(event) {
		setParams(prevParams => ({...prevParams, [event.name]: event.value}));
	}

	async function handleAutenticacao(event) {
		switch (event.key) {
			case "Enter":
			case "NumpadEnter":
				await handleAuthorization();
				break;
			default:
				break;
		}
	}

	async function handleAuthorization() {
		const autenticacao = params.autenticacao;
		await fetch(`${apiUrl}/public/usuarios/${params.autenticacao}`).then(async response => {
			if (response.status === 200) {
				response.text().then(nome => {
					if (nome) {
						setAuthorization(autenticacao);
						setParams(prevParams => ({...prevParams, autenticacao: nome}));
					} else {
						setParams(prevParams => ({...prevParams, autenticacao: nome}));
					}
				});
				refPrateleira.current.focus();
			} else {
				setParams(prevParams => ({...prevParams, autenticacao: ""}));
				refAutenticacao.current.focus();
			}
		}).catch(() => {
			setParams(prevParams => ({...prevParams, autenticacao: ""}));
			refAutenticacao.current.focus();
		});
	}

	function clearAuthorization() {
		setAuthorization(null);
		setParams({...params, autenticacao: ""});
	}

	return (
		<div className="panel-login">
			<BlockUI blocked={blocked}>
				<Panel header={
					<div style={{display: "block", width: "calc(276px - 3em)"}}>
						<span style={{lineHeight: "1.5em"}}>Movimento de OS (v{BUILD_VERSION})</span>
					</div>
				} style={{width: "90%", maxWidth: "276px"}}>
					<PanelContent>
						<div className="col-12">
							<label>Autenticação</label>
							<InputText tabIndex={1} onBlur={handleAuthorization} onClick={clearAuthorization} readOnly={authorization?.length} autoFocus ref={refAutenticacao} name="autenticacao" label="Autenticação" value={params.autenticacao} onChange={e => handleChange({name: "autenticacao", value: e.target.value})} onKeyPress={handleAutenticacao}/>
						</div>
						<div className="col-12">
							<label>Prateleira</label>
							<InputText ref={refPrateleira} readOnly={!authorization?.length} tabIndex={2} label="Local" value={params.prateleira} name="prateleira" onChange={e => handleChange({name: "prateleira", value: e.target.value})}/>
						</div>
						<div className="col-12">
							<label>Nº da Ordem de Serviço</label>
							<InputText onBlur={registrarMovimentacao} readOnly={!authorization?.length} tabIndex={3} ref={refNumero} label="Ordem de Serviço" value={params.numero} onChange={e => handleChange({name: "numero", value: e.target.value})}/>
						</div>
						<div className="col-12">
							<input tabIndex={4} type="text" style={{padding: 0, border: 0, width: 0, height: 0, position: "absolute", bottom: 0, right: 0, outline: 0}}/>
						</div>
						<div className="coletor-history">{messages.map(m => <div>{m}</div>)}</div>
					</PanelContent>
				</Panel>
			</BlockUI>
		</div>
	);

}
