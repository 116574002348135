import React, {useState} from "react";
import {AutoComplete} from "./index";
import {perfilService} from "../../service/perfilService";

export function AutoCompletePerfilMultiple(props) {

    const [value, setValue] = useState("");

    const [perfis, setPerfis] = useState([]);
    const [emptyMessage, setEmptyMessage] = useState('')

    function handleChange(event) {
        setValue(event.value);
    }

    function handleSelect(event) {
        if (props.onChange) {
            props.onChange({name: props.name, value: [...props.value, event.value], index: props.index});
            setValue("");
        }
    }

    function handleUnselect(event) {
        if (props.onChange) {
            const value = props.value?.filter(v => v.id !== event.value?.id);
            props.onChange({name: props.name, value, index: props.index});
            setValue("");
        }
    }

    function completeMethod(event) {
        const query = []
        if (event.query.length) query.push(`descricao=${encodeURIComponent(event.query)}`);
        
        perfilService.autocomplete(query).then(res => {
            setPerfis(res)
            if(!res[0]){
                setEmptyMessage('Nenhum registro encontrado')
            }else{
                setEmptyMessage('')
            }
        });
    }

    return <AutoComplete
        value={value}
        {...props}
        label={props.label || "Perfis"}
        onChange={handleChange}
        suggestions={perfis}
        completeMethod={completeMethod}
        field="descricao"
        multiple
        onSelect={handleSelect}
        onUnselect={handleUnselect}
        icon="fa-solid fa-th"
        placeholder='Descrição'
        invalid={emptyMessage}
    />;

}
