import React from "react";
import {baseService} from "./baseService";
import {
	buildValidator,
	isBiggerThanZero,
	isEmpty,
	isEntityRequired,
	isRequired,
} from "../utils/fieldValidator";
import moment from "moment";
import {api} from "./api";
import {ordemServicoItemService} from "./ordemServicoItemService";
import {equipamentoService} from "./equipamentoService";
import {ordemServicoFluxoService} from "./ordemServicoFluxoService";
import {Tooltip} from "primereact/tooltip";
import {produtoService} from "./produtoService";
import {SelectButton} from "../components/selectbutton";

const resourceUrl = "/ordens-servico";

export const statusConsumo = [
	"EXECUTADA",
	"FATURAMENTO",
	"EXPEDICAO",
	"FECHADA"
];

export const ordemServicoService = {
	criar() {
		return {
			id: null,
			_key: Math.random(),
			status: "LANCADA",
			tipo: null,
			cliente: null,
			clienteFaturamento: null,
			itens: [],
			acompanhamentos: [],
			etapas: [],
			movimentosEstoque: [],
			inicio: moment().format("YYYY-MM-DDTHH:mm:ss"),
			fim: null,
			fluxo: "",
			assinatura: null,
			ultimoAvanco: null,
			previsoesAtendimento: [],
			laudos: [],
			observacao: null,
			operacao: "LABORATORIO",
			notaFiscal: {
				id: null,
				chave: null,
				numero: null,
				descricao: null,
				valor: null,
				observacoes: null,
			},
		};
	},
	async listarEstoqueCompras(params) {
		const query = ["produtos"];
		if (params.inicio?.length) query.push(`inicio=${params.inicio}`);
		if (params.fim?.length) query.push(`fim=${params.fim}`);
		if (params.numero) query.push(`numero=${params.numero}`);
		if (params.cliente?.id) {
			query.push(`cliente=${params.cliente?.id}`);
		} else if (params.cliente?.length) {
			query.push(`clienteStr=${params.cliente}`);
		}
		if (params.statusPecas?.length)
			query.push(`statusPecas=${params.statusPecas}`);
		if (params.responsavel?.id)
			query.push(`responsavel=${params.responsavel.id}`);
		return await ordemServicoService.listar(query);
	},
	criarAcompanhamento() {
		return {
			id: null,
			_key: Math.random(),
			inicio: moment().format("YYYY-MM-DDTHH:mm:ss"),
			fim: null,
			responsavel: null,
			etapa: null,
			eventos: [],
			flags: [],
			tipo: "AVANCO",
			observacoes: "",
		};
	},
	async verificarDisponibilidade(criterio) {
		return await api
			.post(`${resourceUrl}/agendas/verificar-disponibilidade`, criterio)
			.then((res) => res.data);
	},
	async testeOLote() {
		return await api.post(`${resourceUrl}/testeOLote`);
	},
	validar(ordemServico) {
		const messages = buildValidator();
		messages.etapa = isEntityRequired(ordemServico.etapa);
		messages.tipo = isRequired(ordemServico.tipo);
		messages.cliente = isEntityRequired(ordemServico.cliente);
		messages.clienteFaturamento = isEntityRequired(
			ordemServico.clienteFaturamento
		);
		messages.itens = [...Array(ordemServico.itens.length)].map(() => ({}));
		for (let i = 0; i < ordemServico.itens.length; ++i) {
			messages.itens[i] = ordemServicoItemService.validar(
				ordemServico.itens[i],
				ordemServico
			);
		}
		if (ordemServico.operacao === "SUPORTE") {
		  if (!ordemServico.emailContato?.trim().length) messages.emailContato = "Campo obrigatório";
		  if (!ordemServico.solicitanteNome?.trim().length) messages.solicitanteNome = "Campo obrigatório";
		  if (!ordemServico.responsavel?.id) messages.responsavel = "Campo obrigatório";
    }
		if (ordemServico.operacao === "LABORATORIO" && ordemServico.notaFiscal?.numero) {
			messages.notaFiscal = {
				chave: isRequired(ordemServico.notaFiscal.chave),
				data: isRequired(ordemServico.notaFiscal.data),
				valor:
					ordemServico.notaFiscal.valor == null ? "Campo obrigatório" : null,
				isEmpty: () => isEmpty(messages.notaFiscal),
			};
			if (
				ordemServico.notaFiscal.itens &&
				ordemServico.notaFiscal.itens.length > 0
			) {
				messages.notaFiscal.itens = [
					...Array(ordemServico.notaFiscal.itens).map((v, i) => ({
						equipamento: isEntityRequired(
							ordemServico.notaFiscal.itens[i].equipamento
						),
						valor:
							ordemServico.notaFiscal.itens[i] == null
								? "Campo obrigatório"
								: null,
					})),
				];
			}
		}
		return messages;
	},
	validarBackofficeRecepcao(ordemServico) {
		const messages = buildValidator();
		messages.inicio = isRequired(ordemServico.inicio);
		messages.cliente = isEntityRequired(ordemServico.cliente);
		messages.equipamento = {
			serial: ordemServico.id
				? isRequired(ordemServico.itens[0].equipamento.serial)
				: null,
			produto: isEntityRequired(ordemServico.itens[0].equipamento.produto),
		};
		return messages;
	},
	validarBackofficeExpedicao(ordemServico) {
		const messages = buildValidator();
		messages.previsaoDespacho = isRequired(ordemServico.previsaoDespacho);
		return messages;
	},
	async enviarOrcamentos(criterio) {
		return await api
			.post(`/ordens-servico/orcamentos/email`, criterio, {
				responseType: "blob",
			})
			.then((response) => response.data);
	},

	async ultimoEmailOrcamento(id) {
		return await api
			.get(`/ordens-servico/orcamentos/email/${id}`)
			.then((response) => response.data);
	},

	async listarMovimentosEstoque(id) {
		return await api
			.get(`/estoques/movimentos/os/${id}`)
			.then((response) => response.data);
	},
	async findAllPecasByOrdemServico(id) {
		return await api
			.get(`/ordens-servico/pecas/${id}`)
			.then((response) => response.data);
	},
	async salvarAcompanhamento(acompanhamento) {
		return await api
			.post("/ordens-servico/acompanhamentos", acompanhamento)
			.then((response) => response.data);
	},
	async salvarLaudo(laudo) {
		return await api
			.post("/ordens-servico/laudos", laudo)
			.then((response) => response.data);
	},
	criarComprovante() {
		return {
			id: null,
			_key: Math.random(),
			descricao: "",
			arquivo: null,
		};
	},
	async gerarOrcamento(os, osi) {
		return await api
			.post(`${resourceUrl}/orcamentos?ordemServicoItem=${osi}`, os)
			.then((response) => response.data);
	},
	criarLaudo() {
		return {
			id: null,
			_key: Math.random(),
			responsavel: null,
			registro: null,
			assinatura: null,
		};
	},
	async reatribuir(criterio, pendente = false) {
		await api.patch(
			`/ordens-servico/reatribuir?pendente=${pendente}`,
			criterio
		);
	},
	baixarEtiquetaOS: async (id) =>
		await api
			.get(`/ordens-servico/imprimirEtiquetaOs/${id}`, {responseType: "blob"})
			.then((response) => response.data),
	imprimirLaudoCorretiva: async (params) =>
		await api
			.get(`/ordens-servico/imprimirLaudoCorretiva?${params.join("&")}`, {
				responseType: "blob",
			})
			.then((response) => response.data),
	imprimirLaudoPreventiva: async (params) =>
		await api
			.get(`/ordens-servico/imprimirLaudoPreventiva?${params.join("&")}`, {
				responseType: "blob",
			})
			.then((response) => response.data),
	imprimirOSCampoPreventiva: async (params) =>
		await api
			.get(`/ordens-servico/imprimirOSCampoPreventiva?${params.join("&")}`, {
				responseType: "blob",
			})
			.then((response) => response.data),
	imprimirOSCapaCampo: async (params) =>
		await api
			.get(`/ordens-servico/imprimirOSCapaCampo?${params.join("&")}`, {
				responseType: "blob",
			})
			.then((response) => response.data),
	imprimirLaudoInterno: async (params) =>
		await api
			.get(`/ordens-servico/imprimirLaudoInterno?${params.join("&")}`, {
				responseType: "blob",
			})
			.then((response) => response.data),
	imprimirOrcamentoOS: async (params) =>
		await api
			.get(`/ordens-servico/imprimirOrcamentoOS?${params.join("&")}`, {
				responseType: "blob",
			})
			.then((response) => response.data),
	...baseService(resourceUrl),
	async buscarOrdemServico(param) {
		if (isNaN(param)) {
			return await api
				.get(`${resourceUrl}/${param}`)
				.then((response) => response?.data);
		} else {
			return await api
				.get(`${resourceUrl}/numero/${param}`)
				.then((response) => response?.data);
		}
	},
	async listarOrdensServicoFluxoCampo(params) {
		const query = [
			"operacao=CAMPO",
			"status=LANCADA,ABERTA,LAUDO,ESTOQUE,ORCAMENTO,COMPRAS,SUSPENSA,CANCELADA,FATURAMENTO,EXECUTADA,MANUTENCAO,LIMPEZA,TESTE,EXPEDICAO,RECEPCAO,ATENDIMENTO,TRANSFERENCIA",
		];
		if (params.tecnicoCampo) query.push("tecnicoCampo");
		if (params.tipo?.length) query.push(`tipo=${params.tipo}`);
		if (params.numero) {
			switch (params.modoNumero) {
				case "OS":
					query.push(`numero=${params.numero}`);
					break;
				case "CHAMADO":
					query.push(`numeroChamado=${params.numero}`);
					break;
				case "NF":
					query.push(`notaFiscal=${params.numero}`);
					break;
				case "CONTRATO":
					query.push(`contrato=${params.numero}`);
					break;
				case "PROJETO":
					query.push(`projeto=${params.numero}`);
					break;
				case "ORCAMENTO":
					query.push(`orcamento=${params.numero}`);
					break;
				case "PV":
					query.push(`pedidoVendaNumero=${params.numero}`);
					break;
				case "PC":
					query.push(`numeroPedidoCompra=${params.numero}`);
					break;
				case "CLIENTE":
					query.push(`numeroPedidoCliente=${params.numero}`);
					break;
				case "NFRET":
					query.push(`notaFiscalRetorno=${params.numero}`);
					break;
				default:
					break;
			}
		}
		if (params.statusContrato?.length) query.push(`statusContrato=${params.statusContrato}`);
		if (params.cancelada?.length) query.push(`cancelada=${params.cancelada}`);
		if (params.cores?.length) query.push(`cores=${params.cores.join(",")}`);
		if (params.etapa?.length) query.push(`etapa=${params.etapa}`);
		if (params.formaDespacho?.length)
			query.push(`formaDespacho=${params.formaDespacho}`);
		if (params.cliente?.id) {
			query.push(`cliente=${params.cliente.id}`);
		} else if (params.cliente?.length > 0) {
			query.push(`clienteStr=${params.cliente}`);
		}
		if (params.fornecedor?.id) {
			query.push(`fornecedor=${params.fornecedor.id}`);
		} else if (params.fornecedor?.length > 0) {
			query.push(`fornecedorStr=${params.fornecedor}`);
		}
		if (params.equipamento?.id) {
			query.push(`equipamento=${params.equipamento.id}`);
		} else if (params.equipamento?.length) {
			query.push(
				`equipamentoStr=${params.equipamento.replace(/[^0-9a-zA-Z]/g, " ")}`
			);
		} else {
			if (params.responsavel?.id)
				query.push(`responsavel=${params.responsavel.id}`);
			if (params.inicio?.length) query.push(`inicio=${params.inicio}`);
			if (params.fim?.length) query.push(`fim=${params.fim}`);
			if (params.modoData?.length) query.push(`periodo=${params.modoData}`);
		}
		if (params.equipamentoEspecial?.length)
			query.push(`equipamentoEspecial=${params.equipamentoEspecial}`);
		if (params.pecas?.length) query.push(`pecas=${params.pecas}`);
		if (params.orcamentoStatus?.length)
			query.push(`orcamentoStatus=${params.orcamentoStatus}`);
		if (params.prateleira?.length)
			query.push(`prateleira=${params.prateleira}`);
		if (params.restricoes?.length) {
			const aplicaveis = params.restricoes
				.filter((r) =>
					optionsFilterRestricoes.some(
						(o) => o.value === r && o.setores.includes("CAMPO")
					)
				)
				.join(",");
			if (aplicaveis.length) {
				query.push(`restricoes=${aplicaveis}`);
			}
		}
		if (params.faixaSLA?.length) query.push(`faixaSLA=${params.faixaSLA}`);
		if (params.responsaveis?.length)
			query.push(
				`responsaveis=${params.responsaveis.map((u) => u.id).join(",")}`
			);
		if (params.gerenteComercial?.id)
			query.push(`gerenteComercial=${params.gerenteComercial.id}`);
		if (params.statusAgendamento?.length)
			query.push(`statusAgendamento=${params.statusAgendamento}`);
		if (params.statusAtendimento?.length)
			query.push(`statusAtendimento=${params.statusAtendimento}`);

		{
			/* Esse bloco precisa ser comentado */
		}
		// if (params.agruparFaturamento) query.push(`agruparFaturamento=${params.agruparFaturamento}`);

		return await ordemServicoService.listar(query);
	},
	async listarOrdensServicoFluxoRecepcao(params) {
		return await ordemServicoService.listarOrdensServicoFluxoLaboratorio(
			params,
			false
		);
	},
	async listarOrdensServicoFluxoLaboratorio(params, laboratorio = true) {
		const query = ["tipo=CORRETIVA"];
		if (laboratorio) {
			query.push("operacao=LABORATORIO");
		} else {
			query.push("operacao=BACKOFFICE");
		}
		if (params.numero) {
			switch (params.modoNumero) {
				case "OS":
					query.push(`numero=${params.numero}`);
					break;
				case "CHAMADO":
					query.push(`numeroChamado=${params.numero}`);
					break;
				case "NF":
					query.push(`notaFiscal=${params.numero}`);
					break;
				case "CONTRATO":
					query.push(`contrato=${params.numero}`);
					break;
				case "PROJETO":
					query.push(`projeto=${params.numero}`);
					break;
				case "ORCAMENTO":
					query.push(`orcamento=${params.numero}`);
					break;
				case "PV":
					query.push(`pedidoVendaNumero=${params.numero}`);
					break;
				case "PC":
					query.push(`numeroPedidoCompra=${params.numero}`);
					break;
				case "CLIENTE":
					query.push(`numeroPedidoCliente=${params.numero}`);
					break;
				case "NFRET":
					query.push(`notaFiscalRetorno=${params.numero}`);
					break;
				default:
					break;
			}
		} else {
			const status = [];
			if (params.cores?.length) query.push(`cores=${params.cores.join(",")}`);
			switch (params.status) {
				case "ABERTAS":
					if (laboratorio) {
						status.push("LAUDO", "MANUTENCAO", "LIMPEZA", "TESTE");
					} else {
						status.push("RECEPCAO", "EXPEDICAO");
					}
					break;
				case "FECHADAS":
					status.push("FECHADA");
					break;
				default:
					if (laboratorio) {
						status.push("LAUDO", "MANUTENCAO", "LIMPEZA", "TESTE", "FECHADA");
					} else {
						status.push("RECEPCAO", "EXPEDICAO", "FECHADA");
					}
					break;
			}
			query.push(`status=${status.join(",")}`);
			if (params.cancelada?.length) query.push(`cancelada=${params.cancelada}`);
		}
		if (params.formaDespacho?.length)
			query.push(`formaDespacho=${params.formaDespacho}`);
		if (params.cliente?.id) {
			query.push(`cliente=${params.cliente.id}`);
		} else if (params.cliente?.length > 0) {
			query.push(`clienteStr=${params.cliente}`);
		}
		if (params.equipamento?.id) {
			query.push(`equipamento=${params.equipamento.id}`);
		} else if (params.equipamento?.length) {
			query.push(
				`equipamentoStr=${params.equipamento.replace(/[^0-9a-zA-Z ]/g, " ")}`
			);
		} else {
			if (params.responsavel?.id)
				query.push(`responsavel=${params.responsavel.id}`);
			if (params.inicio?.length) query.push(`inicio=${params.inicio}`);
			if (params.fim?.length) query.push(`fim=${params.fim}`);
			if (params.modoData?.length) query.push(`periodo=${params.modoData}`);
		}
		if (params.statusContrato?.length) query.push(`statusContrato=${params.statusContrato}`);
		if (params.equipamentoEspecial?.length)
			query.push(`equipamentoEspecial=${params.equipamentoEspecial}`);
		if (params.pecas?.length) query.push(`pecas=${params.pecas}`);
		if (params.orcamentoStatus?.length)
			query.push(`orcamentoStatus=${params.orcamentoStatus}`);
		if (params.prateleira?.length)
			query.push(`prateleira=${params.prateleira}`);
		if (params.restricoes?.length) {
			const aplicaveis = params.restricoes
				.filter((r) =>
					optionsFilterRestricoes.some(
						(o) =>
							o.value === r &&
							o.setores.includes(laboratorio ? "LABORATORIO" : "BACKOFFICE")
					)
				)
				.join(",");
			if (aplicaveis.length) {
				query.push(`restricoes=${aplicaveis}`);
			}
		}
		if (params.faixaSLA?.length) query.push(`faixaSLA=${params.faixaSLA}`);
		if (params.gerenteComercial?.id)
			query.push(`gerenteComercial=${params.gerenteComercial.id}`);

		{
			/* Esse bloco precisa ser comentado */
		}
		// if (params.agruparFaturamento) query.push(`agruparFaturamento=${params.agruparFaturamento}`);

		return await ordemServicoService.listar(query);
	},
	async listarOrdensServicoSuporte(params) {
		const query = [];
		if (params.status) {
			query.push("tipo=SUPORTE");
			switch (params.status) {
				case "SEM_RESPONSAVEL":
					query.push("semResponsavel=true");
					break;
				case "CLIENTE_RETORNOU":
					query.push("clienteRetornou=true");
					break;
			}
		}
		if (
			params.numeros && (params.modoNumeros === "OS" || params.modoNumeros === "OS_OMEGA")
		) {
			switch (params.modoNumeros) {
				case "OS":
					query.push(`numero=${params.numeros}`);
					break;
				case "OS_OMEGA":
					query.push(`numeroOmega=${params.numeros}`);
					break;
				default:
					break;
			}
		} else {
			if (params.status?.length) {
				switch (params.status) {
					case "ABERTAS":
						query.push(
							`status=${ordemServicoStatus
								.filter((s) => s !== "FECHADA")
								.join(",")}`
						);
						break;
					case "FECHADAS":
						query.push("status=FECHADA");
						break;
					case "TRANSFERIDAS":
						query.push("iniciadoPorSuporte=1");
						break;
				}
			} else {
				query.push("iniciadoPorSuporte=2");
			}
			if (params.cliente?.id) {
				query.push(`cliente=${params.cliente.id}`);
			} else if (params.cliente?.length) {
				query.push(`clienteStr=${params.cliente}`);
			}
			if (params.operacao?.length && params.operacao !== "TODOS")
				query.push(`operacao=${params.operacao}`);
			if (params.pecas?.length) query.push(`pecas=${params.pecas}`);
			if (params.equipamento?.id) {
				query.push(`equipamento=${params.equipamento.id}`);
			} else if (params.equipamento?.length) {
				query.push(`equipamentoStr=${params.equipamento}`);
			} else {
				if (params.responsavel?.id)
					query.push(`responsavel=${params.responsavel.id}`);
				if (params.inicio?.length) query.push(`inicio=${params.inicio}`);
				if (params.fim?.length) query.push(`fim=${params.fim}`);
				if (params.modoData?.length) query.push(`periodo=${params.modoData}`);
			}
			if (params.fluxo?.id) query.push(`fluxo=${params.fluxo.id}`);
			if (params.prateleira?.length)
				query.push(`prateleira=${params.prateleira}`);
			if (params.etapa?.length) query.push(`etapa=${params.etapa}`);
			if (params.orcamentoStatus?.length)
				query.push(`orcamentoStatus=${params.orcamentoStatus}`);
			if (params.gestor?.length) query.push(`gestor=${params.gestor}`);
			if (params.restricoes?.length)
				query.push(`restricoes=${params.restricoes.join(",")}`);
			if (params.gerenteComercial?.id)
				query.push(`gerenteComercial=${params.gerenteComercial.id}`);
			if (params.cores?.length) query.push(`cores=${params.cores.join(",")}`);
			if (params.faixaSLA?.length) query.push(`faixaSLA=${params.faixaSLA}`);
			if (params.numeros) {
				switch (params.modoNumeros) {
					case "CHAMADO":
						query.push(`numeroChamado=${params.numeros}`);
						break;
					case "NF":
						query.push(`notaFiscal=${params.numeros}`);
						break;
					case "CONTRATO":
						query.push(`contrato=${params.numeros}`);
						break;
					case "ORCAMENTO":
						query.push(`orcamento=${params.numeros}`);
						break;
					case "PV":
						query.push(`pedidoVendaNumero=${params.numeros}`);
						break;
					case "PC":
						query.push(`numeroPedidoCompra=${params.numeros}`);
						break;
					case "CLIENTE":
						query.push(`numeroPedidoCliente=${params.numeros}`);
						break;
					default:
						break;
				}
			}
			if (params.cancelada?.length) query.push(`cancelada=${params.cancelada}`);
		}
		return await ordemServicoService.listar(query);
	},
	async listarOrdensServico(params) {
		const query = [];
		if (
			params.numeros &&
			(params.modoNumeros === "OS" || params.modoNumeros === "OS_OMEGA")
		) {
			switch (params.modoNumeros) {
				case "OS":
					query.push(`numero=${params.numeros}`);
					break;
				case "OS_OMEGA":
					query.push(`numeroOmega=${params.numeros}`);
					break;
				default:
					break;
			}
		} else {
			if (params.status?.length)
				query.push(`status=${params.status.join(",")}`);
			if (params.cliente?.id) {
				query.push(`cliente=${params.cliente.id}`);
			} else if (params.cliente?.length) {
				query.push(`clienteStr=${params.cliente}`);
			}
			if (params.tipo?.length) query.push(`tipo=${params.tipo}`);
			if (params.operacao?.length && params.operacao !== "TODOS")
				query.push(`operacao=${params.operacao}`);
			if (params.pecas?.length) query.push(`pecas=${params.pecas}`);
			if (params.statusContrato?.length) query.push(`statusContrato=${params.statusContrato}`);
			if (params.equipamento?.id) {
				query.push(`equipamento=${params.equipamento.id}`);
			} else if (params.equipamento?.length) {
				query.push(
					`equipamentoStr=${params.equipamento.replace(/[^0-9a-zA-Z]/g, " ")}`
				);
			} else {
				if (params.responsavel?.id)
					query.push(`responsavel=${params.responsavel.id}`);
				if (params.inicio?.length) query.push(`inicio=${params.inicio}`);
				if (params.fim?.length) query.push(`fim=${params.fim}`);
				if (params.modoData?.length) query.push(`periodo=${params.modoData}`);
			}
			if (params.fluxo?.id) query.push(`fluxo=${params.fluxo.id}`);
			if (params.prateleira?.length)
				query.push(`prateleira=${params.prateleira}`);
			if (params.etapa?.length) query.push(`etapa=${params.etapa}`);
			if (params.orcamentoStatus?.length)
				query.push(`orcamentoStatus=${params.orcamentoStatus}`);
			if (params.gestor?.length) query.push(`gestor=${params.gestor}`);
			if (params.restricoes?.length)
				query.push(`restricoes=${params.restricoes.join(",")}`);
			if (params.gerenteComercial?.id)
				query.push(`gerenteComercial=${params.gerenteComercial.id}`);
			if (params.numeros) {
				switch (params.modoNumeros) {
					case "CHAMADO":
						query.push(`numeroChamado=${params.numeros}`);
						break;
					case "NF":
						query.push(`notaFiscal=${params.numeros}`);
						break;
					case "CONTRATO":
						query.push(`contrato=${params.numeros}`);
						break;
					case "PROJETO":
						query.push(`projeto=${params.numeros}`);
						break;
					case "ORCAMENTO":
						query.push(`orcamento=${params.numeros}`);
						break;
					case "PV":
						query.push(`pedidoVendaNumero=${params.numeros}`);
						break;
					case "PC":
						query.push(`numeroPedidoCompra=${params.numeros}`);
						break;
					case "CLIENTE":
						query.push(`numeroPedidoCliente=${params.numeros}`);
						break;
					case "NFRET":
						query.push(`notaFiscalRetorno=${params.numeros}`);
						break;
					default:
						break;
				}
			}
			if (params.cancelada?.length) query.push(`cancelada=${params.cancelada}`);
		}
		return await ordemServicoService.listar(query);
	},
	async atualizarValoresOrdemServico(id) {
		return await api
			.get(resourceUrl + `/atualizar/${id}`)
			.then((response) => response.data);
	},
	async buscarPorNumero(numero) {
		return await api
			.get(resourceUrl + `/numero/${numero}`)
			.then((response) => response.data);
	},
	async salvarAcompanhamentoEvento(acev) {
		return await api
			.post(resourceUrl + "/acompanhamentos/eventos", acev)
			.then((response) => response.data);
	},
	async reenviarOrcamento(orcamento) {
		return await api
			.post(resourceUrl + "/orcamentos/email/reenviar", orcamento)
			.then((response) => response.data);
	},
	async salvarOrdemServico(ordemServico, usuario) {
		if (!ordemServico.notaFiscal?.id) {
			ordemServico.notaFiscal = null;
		}
		for (const foto of ordemServico.itens[0].fotos) {
			if (!foto.id) {
				foto.id = (
					await ordemServicoService.salvarArquivo({...foto, postBack: true})
				).id;
			}
		}
		if (!ordemServico.id) {
			ordemServico.tipo = "CORRETIVA";
			ordemServico.status = "RECEPCAO";
			const fluxo = await ordemServicoFluxoService.buscarFluxoLaboratorio();
			ordemServico.fluxo = `${fluxo.descricao} (v${fluxo.versao})`;
			ordemServico.etapa = fluxo.etapas[0];
			ordemServico.etapas = fluxo.etapas;
			ordemServico.responsavel = usuario;
			ordemServico.clienteFaturamento = ordemServico.cliente;
			const acompanhamento = ordemServicoService.criarAcompanhamento();
			acompanhamento.etapa = ordemServico.etapa;
			acompanhamento.observacoes = "Ordem de serviço iniciada";
			acompanhamento.atribuinte = usuario;
			acompanhamento.responsavel = usuario;
			ordemServico.acompanhamentos = [acompanhamento];
		}
		const {id, tipo, numero} = await ordemServicoService.salvar({
			...ordemServico,
			postBack: true,
		});
		ordemServico.itens[0].ordemServico = {id, tipo};
		if (ordemServico.itens[0].equipamento._alterado) {
			if (!ordemServico.itens[0].equipamento.serial?.length) {
				ordemServico.itens[0].equipamento.serial = `OS-${numero}`;
			}
			ordemServico.itens[0].equipamento = await equipamentoService.salvar({
				...ordemServico.itens[0].equipamento,
				postBack: true,
			});
		}
		if (ordemServico.itens[0].equipamento.produto?._alterado) {
			ordemServico.itens[0].equipamento.produto = await produtoService.salvar({
				...ordemServico.itens[0].equipamento.produto,
				postBack: true,
			});
		}
		if (ordemServico.itens[0].equipamentoBackup?.equipamento?._alterado) {
			ordemServico.itens[0].equipamentoBackup.equipamento =
				await equipamentoService.salvar({
					...ordemServico.itens[0].equipamentoBackup.equipamento,
					postBack: true,
				});
		}
		if (!ordemServico.itens[0].id) {
			ordemServico.itens[0].sequencia = 1;
		}
		await ordemServicoItemService.salvar(ordemServico.itens[0]);
		await ordemServicoService.atualizarValoresOrdemServico(id);
		return {id, tipo, numero};
	},
	validarLaboratorioLaudo(ordemServico) {
		const messages =
			ordemServicoService.validarBackofficeRecepcao(ordemServico);
		messages.pecas = [];
		messages.servicos = [];
		for (const peca of ordemServico.itens[0].pecas) {
			messages.pecas.push({
				produto: isEntityRequired(peca.produto),
				quantidade: isBiggerThanZero(peca.quantidade),
			});
		}
		for (const servico of ordemServico.itens[0].servicos) {
			messages.servicos.push({
				servico: isEntityRequired(servico.servico),
				quantidade: isBiggerThanZero(servico.quantidade),
			});
		}
		console.log(messages);
		return messages;
	},
	async salvarArquivo(arquivo) {
		return await api
			.post(`${resourceUrl}/arquivos`, arquivo)
			.then((response) => response.data);
	},
	async findEtapaLaboratorioForStatus(status) {
		return await api
			.get(`${resourceUrl}/laboratorio/etapa/${status}`)
			.then((response) => response.data);
	},
	async registrarMovimentacao(usuario, criterio) {
		return await api
			.post(`/ordens-servico/movimentacoes?uid=${usuario.id}`, criterio, {
				headers: {"Content-Type": "application/json"},
			})
			.then((response) => response.data);
	},
	async atualizarDadosDespacho(ordemServico) {
		return await api.post(`${resourceUrl}/despacho`, ordemServico);
	},
	async retroceder(ordemServico, criterio) {
		return await api.post(
			`${resourceUrl}/retroceder/${ordemServico.id}`,
			criterio
		);
	},
	async contarAtribuicoesPendentes() {
		return await api
			.get(`${resourceUrl}/reatribuicoes-pendentes`)
			.then((response) => response.data);
	},
	async atualizarRestricoes() {
		return await api.get(`${resourceUrl}/aux/restricoes`);
	},
	async recusarReatribuicao(id) {
		return await api.post(`${resourceUrl}/recusar-reatribuicao?id=${id}`);
	},
	async concluirLaudos(ordemServico) {
		return await api.post(
			`${resourceUrl}/laudo/concluir?id=${ordemServico.id}`
		);
	},
	async concluirRessoldas(criterio) {
		return await api.post(`${resourceUrl}/ressolda/concluir`, criterio);
	},
	async concluirManutencaoEquipamentoEspecial(
		ordemServico,
		status,
		observacoes
	) {
		return await api.post(
			`${resourceUrl}/manutencao/especial/concluir?id=${ordemServico.id}&status=${status}`,
			{observacoes}
		);
	},
	async reprocessarOrdensServicoContrato(id) {
		return await api.post(
			`/ordens-servico/contratos/reprocessar?contrato=${id}`
		);
	},
	async buscarOrdensServicoContrato(id) {
		return await api
			.get(`${resourceUrl}/contratos?contrato=${id}`)
			.then((response) => response.data);
	},
	async listarAcompanhamentosRestricao(id) {
		return await api
			.get(`${resourceUrl}/acompanhamentos-restricao/${id}`)
			.then((response) => response.data);
	},
	async encaminharParaLaboratorio(id) {
		return await api.get(`${resourceUrl}/encaminhar-para-laboratorio/${id}`);
	},
	async buscarOrdensServicoSuporte(cliente, equipamento) {
		return await api
			.get(
				`${resourceUrl}/transferencia-para-laboratorio?cliente=${cliente}&equipamento=${equipamento}`
			)
			.then((res) => res.data);
	},
};

export const ordemServicoDeslocamentoService = {
	criar() {
		return {
			id: null,
			_key: Math.random(),
			dataInicial: moment().format("YYYY-MM-DDTHH:mm:ss"),
			odometroInicial: null,
			dataFinal: null,
			odometroFinal: null,
			observacoes: null,
		};
	},
	async salvar(deslocamento) {
		return await api
			.post(`${resourceUrl}/deslocamentos`, deslocamento)
			.then((response) => response.data);
	},
	async listar(id) {
		return await api
			.get(`${resourceUrl}/deslocamentos?ordemServico=${id}`)
			.then((response) => response.data);
	},
	validar(deslocamento) {
		const messages = buildValidator();
		messages.dataInicial = isRequired(deslocamento.dataInicial);
		messages.odometroInicial = isBiggerThanZero(deslocamento.odometroInicial);
		if (
			deslocamento.dataInicial &&
			deslocamento.dataFinal &&
			moment(deslocamento.dataInicial).isAfter(deslocamento.dataFinal)
		) {
			messages.dataFinal = "Data incorreta";
		}
		if (
			deslocamento.odometroInicial &&
			deslocamento.odometroFinal &&
			deslocamento.odometroInicial > deslocamento.odometroFinal
		) {
			messages.odometroFinal = "Valor incorreto";
		}
		return messages;
	},
	async delete(id, osid) {
		return await api.delete(
			`${resourceUrl}/deslocamentos/${id}?ordemServico=${osid}`
		);
	},
};

export const ordemServicoTempoParadoService = {
	criar() {
		return {
			id: null,
			_key: Math.random(),
			inicio: moment().format("YYYY-MM-DDTHH:mm:ss"),
			fim: null,
			observacoes: null,
		};
	},
	async salvar(deslocamento) {
		return await api.post(`${resourceUrl}/tempos-parados`, deslocamento);
	},
	async listar(id) {
		return await api
			.get(`${resourceUrl}/tempos-parados?ordemServico=${id}`)
			.then((response) => response.data);
	},
	async delete(id, osid) {
		return await api.delete(
			`${resourceUrl}/tempos-parados/${id}?ordemServico=${osid}`
		);
	},
	validar(tempoParado) {
		const messages = buildValidator();
		messages.inicio = isRequired(tempoParado.inicio);
		if (
			tempoParado.inicio &&
			tempoParado.fim &&
			moment(tempoParado.inicio).isAfter(tempoParado.fim)
		) {
			messages.fim = "Data incorreta";
		}
		return messages;
	},
};

export const ordemServicoPendenciaService = {
	criar() {
		return {
			id: null,
			_key: Math.random(),
			acao: "NENHUMA",
			inicio: moment().format("YYYY-MM-DDTHH:mm:ss"),
			fim: null,
			observacoes: null,
		};
	},
	async salvar(pendencia) {
		return await api.post(`${resourceUrl}/pendencias`, pendencia);
	},
	async listar(id) {
		return await api
			.get(`${resourceUrl}/pendencias?ordemServico=${id}`)
			.then((response) => response.data);
	},
	async delete(id, osid) {
		return await api.delete(
			`${resourceUrl}/pendencias/${id}?ordemServico=${osid}`
		);
	},
	async resolver(ordensServico) {
		return await api.post(`${resourceUrl}/pendencias/resolver`, ordensServico);
	},
};

export const optionsStatus = [
	{
		label: "Lançada",
		value: "LANCADA",
		icon: "fa-solid fa-folder-plus",
		color: "#607D8B",
	},
	{
		label: "Aberta",
		value: "ABERTA",
		icon: "fa-solid fa-folder-open",
		color: "#4CAF50",
	},
	{
		label: "Laudo",
		value: "LAUDO",
		icon: "fa-solid fa-wrench",
		color: "#888888",
	},
	{
		label: "Estoque",
		value: "ESTOQUE",
		icon: "fa-solid fa-boxes",
		color: "#9C27B0",
	},
	{
		label: "Orçamento",
		value: "ORCAMENTO",
		icon: "fa-solid fa-receipt",
		color: "#795548",
	},
	{
		label: "Compras",
		value: "COMPRAS",
		icon: "fa-solid fa-shopping-cart",
		color: "#E91E63",
	},
	{
		label: "Suspensa",
		value: "SUSPENSA",
		icon: "fa-solid fa-folder-minus",
		color: "#FF9800",
	},
	{
		label: "Fechada",
		value: "FECHADA",
		icon: "fa-solid fa-folder-closed",
		color: "#F44336",
	},
	{
		label: "Faturamento",
		value: "FATURAMENTO",
		icon: "fa-solid fa-wallet",
		color: "#009688",
	},
	{
		label: "Executada",
		value: "EXECUTADA",
		icon: "fa-solid fa-check-circle",
		color: "#666666",
	},
	{
		label: "Manutenção",
		value: "MANUTENCAO",
		icon: "fa-solid fa-screwdriver-wrench",
		color: "#FF9800",
	},
	{
		label: "Limpeza",
		value: "LIMPEZA",
		icon: "fa-solid fa-broom",
		color: "#CDDC39",
	},
	{
		label: "Teste",
		value: "TESTE",
		icon: "fa-solid fa-bolt",
		color: "#00BCD4",
	},
	{
		label: "Expedição",
		value: "EXPEDICAO",
		icon: "fa-solid fa-truck",
		color: "#5E35B1",
	},
	{
		label: "Recepção",
		value: "RECEPCAO",
		icon: "fa-solid fa-boxes",
		color: "#2196F3",
	},
	{
		label: "Atendimento",
		value: "ATENDIMENTO",
		icon: "fa-solid fa-comments",
		color: "#4CAF50",
	},
	{
		label: "Transferência",
		value: "TRANSFERENCIA",
		icon: "fa-solid fa-right-left",
		color: "#9E9D24",
	},
	{label: "Transferência", value: "TRANSFERENCIA", icon: "fa-solid fa-arrows", color: "#2196F3"},
].sort((a, b) => a.value.localeCompare(b.value));

export const optionsStatusIndicativoConsumo = [
	{label: <>Lançada</>, value: "LANCADA"},
	{label: <>Aberta</>, value: "ABERTA"},
	{label: <>Laudo</>, value: "LAUDO"},
	{label: <>Estoque</>, value: "ESTOQUE"},
	{label: <>Orçamento</>, value: "ORCAMENTO"},
	{label: <>Compras</>, value: "COMPRAS"},
	{label: <>Suspensa</>, value: "SUSPENSA"},
	{label: <><i className="fa-solid fa-database"/> Fechada</>, value: "FECHADA"},
	{label: <><i className="fa-solid fa-database"/> Faturamento</>, value: "FATURAMENTO"},
	{label: <>Executada</>, value: "EXECUTADA"},
	{label: <>Manutenção</>, value: "MANUTENCAO"},
	{label: <>Limpeza</>, value: "LIMPEZA"},
	{label: <>Teste</>, value: "TESTE"},
	{label: <><i className="fa-solid fa-database"/> Expedição</>, value: "EXPEDICAO"},
	{label: <>Recepção</>, value: "RECEPCAO"},
	{label: <>Transferência</>, value: "TRANSFERENCIA"}
];

export const optionsTipos = [
	{label: "Preventiva", value: "PREVENTIVA"},
	{label: "Corretiva", value: "CORRETIVA"},
	{label: "Projeto", value: "PROJETO"},
	{label: "Suporte", value: "SUPORTE"},
];

export function optionsTiposFiltered(roles) {
	const options = [];
	if (!!roles.OSSM) options.push({label: "Preventiva", value: "PREVENTIVA"});
	if (!!roles.OSSN) options.push({label: "Corretiva", value: "CORRETIVA"});
	if (!!roles.OSSX) options.push({label: "Projeto", value: "PROJETO"});
	if (!!roles.OSSY) options.push({label: "Suporte", value: "SUPORTE"});
	return options;
}

export const ordemServicoStatus = [
	"LANCADA",
	"ABERTA",
	"SUSPENSA",
	"FECHADA",
	"CANCELADA",
	"ESTOQUE",
	"ORCAMENTO",
	"COMPRAS",
	"LAUDO",
	"FATURAMENTO",
	"EXECUTADA",
	"MANUTENCAO",
	"LIMPEZA",
	"TESTE",
	"EXPEDICAO",
	"RECEPCAO",
	"ATENDIMENTO",
	"TRANSFERENCIA"
];

export const labelStatus = {
	LANCADA: "Lançada",
	ABERTA: "Aberta",
	SUSPENSA: "Suspensa",
	FECHADA: "Fechada",
	CANCELADA: "Cancelada",
	ESTOQUE: "Estoque",
	ORCAMENTO: "Orçamento",
	COMPRAS: "Compras",
	LAUDO: "Laudo",
	FATURAMENTO: "Faturamento",
	EXECUTADA: "Executada",
	MANUTENCAO: "Manutenção",
	LIMPEZA: "Limpeza",
	TESTE: "Teste",
	EXPEDICAO: "Expedição",
	RECEPCAO: "Recepção",
	ATENDIMENTO: "Atendimento",
	TRANSFERENCIA: "Transferência",
};

export const labelTipos = {
	PREVENTIVA: "Preventiva",
	CORRETIVA: "Corretiva",
	PROJETO: "Projeto",
	SUPORTE: "Suporte",
};

export const iconStatus = {
	LANCADA: (
		<i className="fa-solid fa-folder-plus" style={{color: "#607D8B"}}/>
	),
	ABERTA: (
		<i className="fa-solid fa-folder-open" style={{color: "#4CAF50"}}/>
	),
	LAUDO: <i className="fa-solid fa-wrench" style={{color: "#888888"}}/>,
	SUSPENSA: (
		<i className="fa-solid fa-folder-minus" style={{color: "#FF9800"}}/>
	),
	FECHADA: (
		<i className="fa-solid fa-folder-closed" style={{color: "#F44336"}}/>
	),
	CANCELADA: <i className="fa-solid fa-cancel" style={{color: "#F44336"}}/>,
	ESTOQUE: <i className="fa-solid fa-boxes" style={{color: "#9C27B0"}}/>,
	ORCAMENTO: <i className="fa-solid fa-receipt" style={{color: "#795548"}}/>,
	COMPRAS: (
		<i className="fa-solid fa-shopping-cart" style={{color: "#E91E63"}}/>
	),
	EXECUTADA: (
		<i className="fa-solid fa-check-circle" style={{color: "#666666"}}/>
	),
	FATURAMENTO: (
		<i className="fa-solid fa-wallet" style={{color: "#009688"}}/>
	),
	MANUTENCAO: (
		<i
			className="fa-solid fa-screwdriver-wrench"
			style={{color: "#FF9800"}}
		/>
	),
	LIMPEZA: <i className="fa-solid fa-broom" style={{color: "#CDDC39"}}/>,
	TESTE: <i className="fa-solid fa-bolt" style={{color: "#00BCD4"}}/>,
	EXPEDICAO: <i className="fa-solid fa-truck" style={{color: "#5E35B1"}}/>,
	RECEPCAO: (
		<i
			className="fa-solid fa-arrow-left"
			style={{transform: "rotate(-45deg)", color: "#2196F3"}}
		/>
	),
	ATENDIMENTO: (
		<i className="fa-solid fa-comments" style={{color: "#4CAF50"}}/>
	),
	TRANSFERENCIA: (
		<i className="fa-solid fa-right-left" style={{color: "#9E9D24"}}/>
	),
};

export const iconStatusBig = {
	LANCADA: (
		<i
			className="fa-solid fa-big fa-folder-plus"
			style={{color: "#607D8B"}}
		/>
	),
	ABERTA: (
		<i
			className="fa-solid fa-big fa-folder-open"
			style={{color: "#4CAF50"}}
		/>
	),
	LAUDO: (
		<i className="fa-solid fa-big fa-wrench" style={{color: "#888888"}}/>
	),
	SUSPENSA: (
		<i
			className="fa-solid fa-big fa-folder-minus"
			style={{color: "#FF9800"}}
		/>
	),
	FECHADA: (
		<i
			className="fa-solid fa-big fa-folder-closed"
			style={{color: "#F44336"}}
		/>
	),
	CANCELADA: (
		<i className="fa-solid fa-big fa-cancel" style={{color: "#F44336"}}/>
	),
	ESTOQUE: (
		<i className="fa-solid fa-big fa-boxes" style={{color: "#9C27B0"}}/>
	),
	ORCAMENTO: (
		<i className="fa-solid fa-big fa-receipt" style={{color: "#795548"}}/>
	),
	COMPRAS: (
		<i
			className="fa-solid fa-big fa-shopping-cart"
			style={{color: "#E91E63"}}
		/>
	),
	EXECUTADA: (
		<i
			className="fa-solid fa-big fa-check-circle"
			style={{color: "#666666"}}
		/>
	),
	FATURAMENTO: (
		<i className="fa-solid fa-big fa-wallet" style={{color: "#009688"}}/>
	),
	MANUTENCAO: (
		<i
			className="fa-solid fa-big fa-screwdriver-wrench"
			style={{color: "#FF9800"}}
		/>
	),
	LIMPEZA: (
		<i className="fa-solid fa-big fa-broom" style={{color: "#CDDC39"}}/>
	),
	TESTE: <i className="fa-solid fa-big fa-bolt" style={{color: "#00BCD4"}}/>,
	EXPEDICAO: (
		<i className="fa-solid fa-big fa-truck" style={{color: "#5E35B1"}}/>
	),
	RECEPCAO: (
		<i
			className="fa-solid fa-big fa-arrow-left"
			style={{transform: "rotate(-45deg)", color: "#2196F3"}}
		/>
	),
	ATENDIMENTO: (
		<i className="fa-solid fa-big fa-comments" style={{color: "#4CAF50"}}/>
	),
	TRANSFERENCIA: (
		<i className="fa-solid fa-big fa-right-left" style={{color: "#9E9D24"}}/>
	),
};

export const labelOrcamentoStatus = {
	GERADO: (
		<i
			title="Gerado"
			className="fa-solid fa-file-pdf fa-lg	"
			style={{color: "#FF9800"}}
		/>
	),
	ENVIADO: (
		<i
			title="Enviado"
			className="fa-solid fa-envelope fa-lg	"
			style={{color: "#2196F3"}}
		/>
	),
	APROVADO: (
		<i
			title="Aprovado"
			className="fa-solid fa-check fa-lg	"
			style={{color: "#4CAF50"}}
		/>
	),
	RECUSADO: (
		<i
			title="Recusado"
			className="fa-solid fa-times fa-lg	"
			style={{color: "#E91E63"}}
		/>
	),
	CANCELADO: (
		<i
			title="Cancelado"
			className="fa-solid fa-cancel fa-lg	"
			style={{color: "#F44336"}}
		/>
	),
};

export const labelOrcamentoFormaFaturamento = {
	PRODUTO: (
		<i
			title="Produto"
			className="fa-solid fa-boxes fa-lg	"
			style={{color: "#2196F3"}}
		/>
	),
	SERVICO: (
		<i
			title="Serviço"
			className="fa-solid fa-wrench fa-lg	"
			style={{color: "#E91E63"}}
		/>
	),
};

export const optionsOrcamentoStatus = [
	{label: "Sem Orçamento", value: "SEM_ORCAMENTO"},
	{label: "Gerado", value: "GERADO"},
	{label: "Enviado", value: "ENVIADO"},
	{label: "Aprovado", value: "APROVADO"},
	{label: "Recusado", value: "RECUSADO"},
];

export const acompanhamentoTipos = {
	AVANCO: (
		<i className="fa-solid fa-person-walking" style={{color: "#4CAF50"}}/>
	),
	CORRECAO: <i className="fa-solid fa-warning" style={{color: "#F44336"}}/>,
	RETROCESSO: (
		<i
			className="fa-solid fa-arrow-alt-circle-left"
			style={{color: "#FF9800"}}
		/>
	),
};

export const acompanhamentoOperacoes = {
	LABORATORIO: <i className="fa-solid fa-house-flag" title="Laboratório"/>,
	CAMPO: <i className="fa-solid fa-road" title="Campo"/>,
	SUPORTE: <i className="fa-solid fa-headset" title="Suporte"/>
}

export const optionsFiltroEquipe = [
	{label: <i className="fa-solid fa-user"/>, value: "EU"},
	{label: <i className="fa-solid fa-users"/>, value: "GESTOR"},
];

export const labelStatusLaboratorio = {
	LANCADA: "Lançada",
	ABERTA: "Aberta",
	RECEPCAO: "Recepção",
	LAUDO: "Laudo",
	ESTOQUE: "Estoque",
	ORCAMENTO: "Orçamento",
	COMPRAS: "Compras",
	SUSPENSA: "Suspensa",
	FATURAMENTO: "Faturamento",
	EXECUTADA: "Executada",
	MANUTENCAO: "Manutenção",
	LIMPEZA: "Limpeza",
	TESTE: "Teste",
	EXPEDICAO: "Expedição",
	CANCELADA: "Cancelada",
	FECHADA: "Fechada",
};

export const nextStatusLaboratorio = {
	LANCADA: "LAUDO",
	ABERTA: "LAUDO",
	RECEPCAO: "LAUDO",
	LAUDO: "MANUTENCAO",
	ESTOQUE: "MANUTENCAO",
	ORCAMENTO: "MANUTENCAO",
	COMPRAS: "MANUTENCAO",
	SUSPENSA: "MANUTENCAO",
	FATURAMENTO: "EXPEDICAO",
	EXECUTADA: "EXPEDICAO",
	MANUTENCAO: "LIMPEZA",
	LIMPEZA: "TESTE",
	TESTE: "EXPEDICAO",
	EXPEDICAO: "FECHADA",
};

export const optionsDespachoForma = [
	{label: "Cliente retira", value: "CLIENTE_RETIRA"},
	{label: "Correios", value: "CORREIOS"},
	{label: "Nosso carro", value: "NOSSO_CARRO"},
	{label: "Transportadora", value: "TRANSPORTADORA"},
];

export const optionsStatusLaboratorio = [
	{
		label: (
			<>
				<i
					className="fas fa-arrow-right"
					style={{transform: "rotate(-45deg)"}}
				/>{" "}
				Expedição
			</>
		),
		value: "EXPEDICAO",
	},
	{
		label: (
			<>
				<i className="fas fa-bolt"/> Teste
			</>
		),
		value: "TESTE",
	},
	{
		label: (
			<>
				<i className="fas fa-broom"/> Limpeza
			</>
		),
		value: "LIMPEZA",
	},
	{
		label: (
			<>
				<i className="fas fa-screwdriver-wrench"/> Manutenção
			</>
		),
		value: "MANUTENCAO",
	},
	{
		label: (
			<>
				<i className="fas fa-user-md"/> Laudo
			</>
		),
		value: "LAUDO",
	},
	{
		label: (
			<>
				<i
					className="fas fa-arrow-left"
					style={{transform: "rotate(-45deg)"}}
				/>{" "}
				Recepção
			</>
		),
		value: "RECEPCAO",
	},
];

export const labelRestricao = {
	ESTOQUE: "Aguardando peças em estoque",
	COMPRAS: "Aguardando compra de peças",
	ORCAMENTO: "Aguardando aprovação de orçamento",
	ORCAMENTO_AUSENTE: "Sem orçamento",
	TRANSPORTE: "Aguardando NF para transporte",
	PRECO: "Aguardando definição de preços",
	FATURAMENTO: "Aguardando faturamento",
	LAUDO: "Aguardando laudo técnico",
	RESSOLDA: "Aguardando recuperação de placa",
	TERCEIRIZACAO: "Aguardando serviço terceirizado",
	PENDENCIA: "Pendência de atendimento",
};

export const optionsFilterStatus = [
	{
		label: (
			<>
				<i className="fas fa-folder-open"/> Abertas
			</>
		),
		value: "ABERTAS",
	},
	{
		label: (
			<>
				<i className="fas fa-folder-closed"/> Fechadas
			</>
		),
		value: "FECHADAS",
	},
	{
		label: (
			<>
				<i className="fas fa-asterisk"/> Todas
			</>
		),
		value: null,
	},
];

export const optionsFilterStatusSuporte = [
	{label: <div style={{color: "#66BB6A"}} className="white-space-nowrap text-sm flex flex-column gap-1"><i className="fas fa-folder-open"/> Abertas</div>, value: "ABERTAS"},
	{label: <div style={{color: "#AB47BC"}} className="white-space-nowrap text-sm flex flex-column gap-1"><i className="fas fa-folder-closed"/> Fechadas</div>, value: "FECHADAS"},
	{label: <div style={{color: "#42A5F5"}} className="white-space-nowrap text-sm flex flex-column gap-1"><i className="fas fa-arrows-left-right"/> Transferidas</div>, value: "TRANSFERIDAS"},
	{label: <div style={{color: "#EF5350"}} className="white-space-nowrap text-sm flex flex-column gap-1"><i className="fas fa-user-times"/> Sem Responsável</div>, value: "SEM_RESPONSAVEL"},
	{label: <div style={{color: "#FFA726"}} className="white-space-nowrap text-sm flex flex-column gap-1"><i className="fas fa-user-check"/> Alterado por Cliente</div>, value: "CLIENTE_RETORNOU"},
	{label: <div className="text-sm flex flex-column gap-1"><i className="fas fa-asterisk"/> Todas</div>, value: null}
];

export const optionsFilterRestricoes = [
	{
		setores: ["LABORATORIO", "CAMPO", "BACKOFFICE"],
		icon: "fas fa-check-circle",
		color: "#827717",
		tooltip: "Sem Restrições",
		value: "__SEM_RESTRICOES",
	},
	{
		setores: ["LABORATORIO"],
		icon: "fas fa-user-md",
		color: "#666666",
		tooltip: "Aguardando laudo técnico",
		value: "LAUDO",
	},
	{
		setores: ["LABORATORIO"],
		icon: "fas fa-eyedropper",
		color: "#009688",
		tooltip: "Aguardando recuperação de placa",
		value: "RESSOLDA",
	},
	// {setores: ["LABORATORIO"], icon: "fas fa-handshake", color: "#00BCD4", tooltip: "Aguardando serviço terceirizado", value: "TERCEIRIZACAO"},
	{
		setores: ["LABORATORIO", "CAMPO"],
		icon: "fas fa-dollar-sign",
		color: "#FFC107",
		tooltip: "Aguardando definição de preços",
		value: "PRECO",
	},
	{
		setores: ["LABORATORIO", "CAMPO"],
		icon: "fas fa-boxes",
		color: "#3F51B5",
		tooltip: "Aguardando peças em estoque",
		value: "ESTOQUE",
	},
	{
		setores: ["LABORATORIO", "CAMPO"],
		icon: "fas fa-shopping-cart",
		color: "#673AB7",
		tooltip: "Aguardando compra de peças",
		value: "COMPRAS",
	},
	{
		setores: ["LABORATORIO", "CAMPO"],
		icon: "fas fa-thumbtack",
		color: "#607D8B",
		tooltip: "Sem orçamento",
		value: "ORCAMENTO_AUSENTE",
	},
	{
		setores: ["LABORATORIO", "CAMPO"],
		icon: "fas fa-business-time",
		color: "#FF5722",
		tooltip: "Orçamento aguardando aprovação",
		value: "ORCAMENTO",
	},
	{
		setores: ["LABORATORIO", "CAMPO"],
		icon: "fas fa-thumbs-up",
		color: "#4CAF50",
		tooltip: "Orçamento aprovado",
		value: "__ORCAMENTO_APROVADO",
	},
	{
		setores: ["LABORATORIO", "CAMPO"],
		icon: "fas fa-thumbs-down",
		color: "#F44336",
		tooltip: "Orçamento reprovado",
		value: "__ORCAMENTO_REPROVADO",
	},
	{
		setores: ["BACKOFFICE"],
		icon: "fas fa-wallet",
		color: "#E91E63",
		tooltip: "Aguardando faturamento",
		value: "FATURAMENTO",
	},
	{
		setores: ["BACKOFFICE"],
		icon: "fas fa-file-pdf",
		color: "#9C27B0",
		tooltip: "Aguardando NF para transporte",
		value: "TRANSPORTE",
	},
	{
		setores: ["CAMPO"],
		icon: "fas fa-car-crash",
		color: "#E91E63",
		tooltip: "Pendência de atendimento",
		value: "PENDENCIA",
	},
];

export const optionsFilterRestricoesLaboratorio = [
	{
		icon: "fas fa-check-circle",
		color: "#827717",
		tooltip: "Sem Restrições",
		value: 13,
	},
	{
		icon: "fas fa-user-md",
		color: "#666666",
		tooltip: "Aguardando laudo técnico",
		value: 9,
	},
	{
		icon: "fas fa-eyedropper",
		color: "#009688",
		tooltip: "Aguardando recuperação de placa",
		value: 10,
	},
	// {icon: "fas fa-handshake", color: "#00BCD4", tooltip: "Aguardando serviço terceirizado", value: 11},
	{
		icon: "fas fa-dollar-sign",
		color: "#FFC107",
		tooltip: "Aguardando definição de preços",
		value: 2,
	},
	{
		icon: "fas fa-boxes",
		color: "#3F51B5",
		tooltip: "Aguardando peças em estoque",
		value: 0,
	},
	{
		icon: "fas fa-shopping-cart",
		color: "#673AB7",
		tooltip: "Aguardando compra de peças",
		value: 1,
	},
	{
		icon: "fas fa-thumbtack",
		color: "#607D8B",
		tooltip: "Sem orçamento",
		value: 5,
	},
	{
		icon: "fas fa-business-time",
		color: "#FF5722",
		tooltip: "Orçamento aguardando aprovação",
		value: 4,
	},
];

export function restricoesButtons(restricao, contagem) {
	return (
		<div style={{width: "100%", textAlign: "center"}}>
			<Tooltip target={`.filter-${restricao.value}`} position="top"/>
			<i
				data-pr-tooltip={restricao.tooltip}
				className={`fas fas-bgcolor fa-${restricao.icon} filter-${restricao.value}`}
				style={{backgroundColor: restricao.color}}
			/>
			{/*<span className="contador-restricoes" style={{backgroundColor: restricao.color}}>{contagem || "0"}</span>*/}
		</div>
	);
}

export const iconesRestricoes = {
	PRECO: (
		<i
			style={{display: "inline-block", width: "16px", textAlign: "center"}}
			className="fas fa-dollar-sign"
		/>
	),
	ESTOQUE: (
		<i
			style={{display: "inline-block", width: "16px", textAlign: "center"}}
			className="fas fa-boxes"
		/>
	),
	FATURAMENTO: (
		<i
			style={{display: "inline-block", width: "16px", textAlign: "center"}}
			className="fas fa-wallet"
		/>
	),
	TRANSPORTE: (
		<i
			style={{display: "inline-block", width: "16px", textAlign: "center"}}
			className="fas fa-file-pdf"
		/>
	),
	ORCAMENTO: (
		<i
			style={{display: "inline-block", width: "16px", textAlign: "center"}}
			className="fas fa-file-contract"
		/>
	),
	ORCAMENTO_AUSENTE: (
		<i
			style={{display: "inline-block", width: "16px", textAlign: "center"}}
			className="fas fa-thumb-tack"
		/>
	),
	COMPRAS: (
		<i
			style={{display: "inline-block", width: "16px", textAlign: "center"}}
			className="fas fa-shopping-cart"
		/>
	),
	LAUDO: (
		<i
			style={{display: "inline-block", width: "16px", textAlign: "center"}}
			className="fas fa-user-md"
		/>
	),
	RESSOLDA: (
		<i
			style={{display: "inline-block", width: "16px", textAlign: "center"}}
			className="fas fa-eyedropper"
		/>
	),
	TERCEIRIZACAO: (
		<i
			style={{display: "inline-block", width: "16px", textAlign: "center"}}
			className="fas  fa-handshake"
		/>
	),
	PENDENCIA: (
		<i
			style={{display: "inline-block", width: "16px", textAlign: "center"}}
			className="fas  fa-car-crash"
		/>
	),
};

export const optionsStatusRessolda = [
	{
		icon: "fas fa-thumbs-up",
		value: "CONCLUIDO",
		color: "#4CAF50",
		tooltip: "Sucesso",
	},
	{
		icon: "fas fa-thumbs-down",
		value: "MALSUCEDIDO",
		color: "#FF5722",
		tooltip: "Malsucedido",
	},
];

const codigosRessolda = [
	"RESSOLDA",
	"RECUPERACAO",
	"RECUPERACAO1  ",
	"RECUPERACAO2  ",
	"RECUPERACAO3  ",
];

export function isRessolda(codigo) {
	return (
		codigo && (codigosRessolda.includes(codigo) || codigo.startsWith("REC_"))
	);
}

export const codigosTerceirizacao = ["TERCEIRIZACAO"];

const optionsFilterEquipamentoEspecial = [
	{label: "Todos", value: ""},
	{label: "Sim", value: "true"},
	{label: "Não", value: "false"},
];

export function FiltroEquipamentoEspecial(props) {
	return (
		<SelectButton
			label="Equipamento Especial"
			name="equipamentoEspecial"
			col={2}
			options={optionsFilterEquipamentoEspecial}
			{...props}
		/>
	);
}

export const yesNoOptions = [
	{label: "Sim", value: true},
	{label: "Não", value: false},
];

export const filtrosNumeros = [
	{setores: ["LABORATORIO", "BACKOFFICE"], label: "da OS", value: "OS"},
	{setores: ["LABORATORIO", "BACKOFFICE"], label: "da OS no Omega", value: "OS_OMEGA"},
	{setores: ["LABORATORIO", "BACKOFFICE"], label: "do Chamado", value: "CHAMADO"},
	{setores: ["LABORATORIO", "BACKOFFICE"], label: "da Nota Fiscal de Entrada", value: "NF"},
	{setores: ["LABORATORIO", "BACKOFFICE"], label: "do Contrato", value: "CONTRATO"},
	{setores: ["LABORATORIO", "BACKOFFICE"], label: "do Projeto", value: "PROJETO"},
	{setores: ["LABORATORIO", "BACKOFFICE"], label: "do Orçamento", value: "ORCAMENTO"},
	{setores: ["LABORATORIO", "BACKOFFICE"], label: "do Pedido de Venda", value: "PV"},
	{setores: ["LABORATORIO", "BACKOFFICE"], label: "do Pedido de Compra", value: "PC"},
	{setores: ["LABORATORIO", "BACKOFFICE"], label: "do Pedido do Cliente", value: "CLIENTE"},
	{setores: ["BACKOFFICE"], label: "da Nota Fiscal de Retorno", value: "NFRET"},
];

export const origensPreco = {
	LISTA_PADRAO: (
		<>
			<i style={{color: "#FF9800"}} className="fas fa-list"/> Lista de preço
			padrão
		</>
	),
	LISTA_CLIENTE: (
		<>
			<i style={{color: "#03A9F4"}} className="fas fa-list-alt"/> Lista de
			preço do cliente
		</>
	),
	FRANQUIA: (
		<>
			<i style={{color: "#673AB7"}} className="fas fa-address-book"/>{" "}
			Franquia
		</>
	),
	COBERTURA: (
		<>
			<i style={{color: "#4CAF50"}} className="fas fa-file-signature"/>{" "}
			Cobertura contratual
		</>
	),
	EXCEDENTE: (
		<>
			<i style={{color: "#009688"}} className="fas fa-boxes"/> Excedente de
			cobertura
		</>
	),
	NENHUM: (
		<>
			<i style={{color: "#F44336"}} className="fas fa-question-circle"/> Sem
			preço definido
		</>
	),
	MANUAL: (
		<>
			<i style={{color: "#9E9D24"}} className="fas fa-edit"/> Manual
		</>
	),
};

export const iconesElementoCusto = {
	PRODUTO: <i style={{color: "#3F51B5"}} className="fas fa-box"/>,
	SERVICO: (
		<i style={{color: "#009688"}} className="fas fa-screwdriver-wrench"/>
	),
};

export function criarAtualizacaoValores(ordemServico, novoContrato) {
	const atualizacaoValores = {
		id: ordemServico.id,
		data: ordemServico.inicio,
		cliente: ordemServico.cliente?.id,
		contrato: novoContrato?.id,
	};
	const itens = [];
	for (const item of ordemServico.itens) {
		itens.push({
			equipamento: item.equipamento?.id,
			mauUso: item.flags?.includes("MAU_USO"),
			produtos: item.pecas.map((osip) => ({
				id: osip.id,
				_key: osip._key,
				produto: osip.produto?.id,
				autoComplete: osip.produto?.autoComplete,
				quantidade: osip.quantidade,
				valorTabelaAnterior: osip.valorTabela,
				valorUnitarioAnterior: osip.valorUnitario,
				origemPrecoAnterior: osip.origemPreco,
			})),
			servicos: item.servicos.map((osis) => ({
				id: osis.id,
				_key: osis._key,
				servico: osis.servico?.codigo,
				autoComplete: osis.servico?.autoComplete,
				quantidade: osis.quantidade,
				valorTabelaAnterior: osis.valorTabela,
				valorUnitarioAnterior: osis.valorUnitario,
				origemPrecoAnterior: osis.origemPreco,
			})),
		});
	}
	return {...atualizacaoValores, itens};
}

export function atualizarValores(ordemServico, itens) {
	for (const item of ordemServico.itens) {
		for (const peca of item.pecas) {
			const itemValue = itens.filter(
				(i) =>
					(peca.id && i.id === peca.id) || (peca._key && i._key === peca._key)
			)[0];
			switch (itemValue._set) {
				case "ANTERIOR":
					peca.valorTabela = itemValue.valorTabelaAnterior;
					peca.valorUnitario = itemValue.valorUnitarioAnterior;
					peca.origemPreco = itemValue.origemPrecoAnterior;
					break;
				case "POSTERIOR":
					peca.valorTabela = itemValue.valorTabelaPosterior;
					peca.valorUnitario = itemValue.valorUnitarioPosterior;
					peca.origemPreco = itemValue.origemPrecoPosterior;
					break;
			}
			peca.valorTotal = peca.quantidade * peca.valorUnitario;
			peca.valorFranquia = peca.quantidade * peca.valorTabela;
		}
		for (const servico of item.servicos) {
			const itemValue = itens.filter(
				(i) =>
					(servico.id && i.id === servico.id) ||
					(servico._key && i._key === servico._key)
			)[0];
			switch (itemValue._set) {
				case "ANTERIOR":
					servico.valorTabela = itemValue.valorTabelaAnterior;
					servico.valorUnitario = itemValue.valorUnitarioAnterior;
					servico.origemPreco = itemValue.origemPrecoAnterior;
					break;
				case "POSTERIOR":
					servico.valorTabela = itemValue.valorTabelaPosterior;
					servico.valorUnitario = itemValue.valorUnitarioPosterior;
					servico.origemPreco = itemValue.origemPrecoPosterior;
					break;
			}
			servico.valorTotal = servico.quantidade * servico.valorUnitario;
			servico.valorFranquia = servico.quantidade * servico.valorTabela;
		}
	}
}

export const optionsStatusContrato = [
	{label: <><i className="fas fa-asterisk"/> Todos</>, value: ""},
	{label: <><i className="fas fa-file-circle-xmark"/> Avulso</>, value: "AVULSO"},
	{label: <><i className="fas fa-star"/> Com Contrato</>, value: "CONTRATO"},
];

export const captionOperacao = {
	"LABORATORIO": "laboratório",
	"CAMPO": "campo",
	"SUPORTE": "suporte"
};
