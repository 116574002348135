import React from "react";
import {PanelContent} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Calendar} from "../../components/calendar";

export function EditarOrdemServicoEncaminhamentoLaboratorio({ordemServico, setOrdemServico}) {

	function handleChange(event) {
		setOrdemServico(prevOrdemServico => ({...prevOrdemServico, [event.name]: event.value}));
	}

	return (
		<PanelContent>
			<InputText col={3} label="Código de Postagem" name="codigoPostagem" value={ordemServico.codigoPostagem} onChange={handleChange}/>
			<Calendar col={3} label="Data da Emissão da Postagem" name="dataGeracaoPostagem" value={ordemServico.dataGeracaoPostagem} onChange={handleChange}/>
			<Calendar col={3} label="Data da Postagem" name="dataPostagem" value={ordemServico.dataPostagem} onChange={handleChange}/>
			<InputText col={3} label="Número da NF de Remessa" name="numeroNotaFiscalRemessa" value={ordemServico.numeroNotaFiscalRemessa} onChange={handleChange}/>
			<InputText col={3} label="Código de Rastreio" name="codigoRastreio" value={ordemServico.codigoRastreio} onChange={handleChange}/>
			<Calendar col={3} label="Data de Previsão de Entrega" name="dataPrevisaoEntrega" value={ordemServico.dataPrevisaoEntrega} onChange={handleChange}/>
		</PanelContent>
	);

}
