import React, {useCallback} from 'react';

import {ClienteAutoComplete} from '../../components/autocomplete/ClienteAutoComplete';
import {InputText} from "../../components/inputtext";
import {Calendar} from '../../components/calendar';
import {isEntityRequired, isRequired} from '../../utils/fieldValidator';
import {clienteService} from '../../service/clienteService';
import {AcordoNivelServicoAutoComplete} from "../../components/autocomplete/AcordoNivelServicoAutoComplete";
import {TipoContratoDropdown} from '../../components/dropdown/tipoContratoDropdown';
import { VendedorAutoComplete } from '../../components/autocomplete/VendedorAutoComplete';

export function EditarDadosPrincipais({contrato, setContrato, messages, setMessages, tiposContrato}) {    
    const handleChange = useCallback((event) => {
		setContrato({...contrato, [event.name]: event.value});
        setMessages(messages => {
            return {...messages, [event.name]: null}
        });
	}, [contrato, setContrato, setMessages]) 

    const handleSelectCliente = useCallback((event) => {
		if (event.originalEvent?.type === "blur") return;
		if (event.value.id) {
			clienteService.buscar(event.value.id).then(cliente => setContrato({...contrato, cliente}));
		}
    }, [contrato, setContrato]);

    return (
        <>  
            <ClienteAutoComplete  
                col={4} 
                value={contrato.cliente} 
                name="cliente" 
                onChange={handleChange} 
                invalid={messages.cliente}
                required
                onBlur={() => { 
                    messages.cliente = isEntityRequired(contrato.cliente);
                    setMessages({...messages});
                }}
                onSelect={handleSelectCliente}
            />

            <InputText readOnly col={3}  label="Razão Social" value={contrato.cliente?.razaoSocial || ""} />
            <InputText readOnly col={2}  label="Nome Fantasia" value={contrato.cliente?.nomeFantasia || ""} />
			<InputText readOnly col={1} label="Status" value={contrato.cliente?.vwCliente?.status || ""} />
			<InputText readOnly col={2} label="CPF/CNPJ" value={contrato.cliente?.vwCliente?.cpfCnpjFormatado || ""} />

            <VendedorAutoComplete
                dropdown 
                col={6}
                value={contrato.vendedor} 
				name="vendedor" 
				onChange={handleChange} 
            />

            <TipoContratoDropdown
                col={2}  
                value={contrato.tipoContrato}
                onChange={handleChange}
                name='tipoContrato'
                invalid={messages.tipoContrato}
                required
                onBlur={() => {
                    messages.tipoContrato = isEntityRequired(contrato.tipoContrato);
                    setMessages({...messages});
                }}
                // disabled={contrato?.status !== 'PENDENTE'}
                dataKey='id'
                tiposContrato={tiposContrato}
            />

            <Calendar 
                col={2}
                name="inicio" 
                label="Inicio" 
                value={contrato.inicio} 
                onChange={handleChange}
                invalid={messages.inicio}
                onBlur={() => {
                    messages.inicio = isRequired(contrato.inicio);
                    setMessages({...messages});
                }}
                required
            />

			<Calendar 
                col={2}
                name="fim"
                label='Fim'
                value={contrato.fim} 
                onChange={handleChange}
                invalid={messages.fim}
                required
                onBlur={() => {
                    messages.fim = isRequired(contrato.fim);
                    setMessages({...messages});
                }}
            />

	        <AcordoNivelServicoAutoComplete col={6} label="SLA de Laboratório" value={contrato.acordoNivelServico} onChange={handleChange}/>
            <AcordoNivelServicoAutoComplete col={6} label="SLA de Suporte" name="acordoNivelServicoSuporte" value={contrato.acordoNivelServicoSuporte} onChange={handleChange}/>
            <InputText col={12} label="Objeto" maxLength={250} value={contrato.objeto} onChange={handleChange} name="objeto"/>

        </>
    );

}
