import React, {useMemo, useState} from 'react';
import {FiltrosEstoques} from './FiltrosEstoques';
import {TabelaEstoques} from './TabelaEstoques';

export function Estoque() {

	const [tipos, setTipos] = useState([]);

	return (
		<div>
			<FiltrosEstoques setValue={useMemo(() => setTipos, [])}/>
			<TabelaEstoques value={useMemo(() => tipos, [tipos])}/>
		</div>
	);

}