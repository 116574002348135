import { Tooltip } from 'primereact/tooltip';
import React from 'react';

const MatrizDetails = ({ data }) => (
  <>
    <div className="mt-2"> Dados da Matriz: </div>
    <div className="mt-2">{`Razão Social: ${data.grupoRazaoSocial}`}</div>
    <div className="mt-2">{`Nome Fantasia: ${data.grupoNomeFantasia}`}</div>
    <div className="mt-2">{`${data.grupoCpfCnpj.length === 14 ? 'CNPJ' : 'CPF' }: ${data.grupoCpfCnpj}`}</div>
  </>
);

const renderIcon = (data) => {
  if (data.tipo === 'FILIAL') {
    return <i className={`fa-solid fa-store tooltip-${data.id}`} style={{textAlign:'center' , color:'rgb(48, 63, 159)', fontSize: '1.2rem'}} />;
  } else if (data.tipo === 'UNICO') {
    return <i className={`fa-solid fa-shop tooltip-${data.id}`} style={{textAlign:'center' ,color:'rgb(96, 125, 139)', fontSize: '1.2rem'}} />;
  } else {
    return <i className={`fa-solid fa-building tooltip-${data.id}`} style={{textAlign:'center' ,color:'rgb(244, 67, 54)', fontSize: '1.2rem'}} />;
  }
};

const renderDetails = (data) => {
  if (data.tipo === 'FILIAL') {
    return <MatrizDetails data={data} />;
  }
  return null;
};

const ClienteRazaoSocialDTOTemplate = ({ data }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const icon = renderIcon(data);
  const details = renderDetails(data);

  return (
    <>
      <Tooltip target={`.tooltip-${data.id}`} position='left' tooltipOptions={{ showDelay: 2000, hideDelay: 300 }}>
        <div className="flex align-items-center flex-column justify-content-center p-2">
          <div>{capitalizeFirstLetter(data.tipo.toLowerCase())}</div>
          {details}
        </div>
      </Tooltip>
      <div> {icon} {data.nomeFantasia}</div>
    </>
  );
};

export default ClienteRazaoSocialDTOTemplate;