import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "../../components/datatable";
import { UsuarioPortalAutoComplete } from "../../components/autocomplete/UsuarioPortalAutoComplete";
import { ColumnGroup } from "primereact/columngroup";
import { useCallback, useMemo, useState } from "react";
import { Button } from "../../components/button";
import { usuarioService } from "../../service/usuarioService";
import { EditarEmpresaUsuarioModal } from "./EditarEmpresaUsuariosModal";
import { useDialog } from "../../utils/dialogContext";
import { buildValidator } from "../../utils/fieldValidator";
import { ConfirmDialogV2 } from "../../components/confirmdialog";
import { toast } from "react-toastify";
import { Row } from "primereact/row";
import { Toolbar } from "primereact/toolbar";

export const EditarEmpresaUsuarioDadosPrincipais = ({
  usuarios,
  setUsuarios,
  loading,
  setLoading,
  empresa,
  atualizarUsuarios,
  filiais
}) => {
  const { showDialog } = useDialog();

  const [usuario, setUsuario] = useState();
  const [usuariosSelecionados, setUsuariosSelecionados] = useState([]);


  const adicionarModal = useCallback(
    (usuario) => {
      atualizarUsuarios()
      setUsuario(null)
    },
    [atualizarUsuarios]
  );

  const handleExcluir = useCallback(
    async (e) => {
      setLoading(true)
      await Promise.all(
        usuariosSelecionados.map(async (usuario) => {
          const usuarioCompleto = await usuarioService.buscar(usuario.id);
          usuarioService.salvar({
            ...usuarioCompleto,
            status: "DESATIVADO",
            empresasPortal: [],
            portal: false
          })
          
        })
      );
      const usuariosAtualizados = usuarios.filter(
        (usuario) =>
          !usuariosSelecionados.some(
            (usuarioSelecionado) =>
              (usuarioSelecionado._key &&
                usuario._key === usuarioSelecionado._key) ||
              (usuarioSelecionado.id && usuario.id === usuarioSelecionado.id)
          )
      );
      setUsuarios(usuariosAtualizados);
      setUsuariosSelecionados([]);
      setLoading(false)
    },
    [setLoading, setUsuarios, usuarios, usuariosSelecionados]
  );

  const handleDesvincular = useCallback(
    async (e) => {
      setLoading(true)
      await Promise.all(
        usuariosSelecionados.map(async (usuario) => {
          const usuarioCompleto = await usuarioService.buscar(usuario.id);
          usuarioService.salvar({
            ...usuarioCompleto,
            empresasPortal: usuarioCompleto.empresasPortal.filter(
              (e) => e.id !== empresa.id
            ),
          })
          
        })
      );

      const usuariosAtualizados = usuarios.filter(
        (usuario) =>
          !usuariosSelecionados.some(
            (usuarioSelecionado) =>
              (usuarioSelecionado._key &&
                usuario._key === usuarioSelecionado._key) ||
              (usuarioSelecionado.id && usuario.id === usuarioSelecionado.id)
          )
      );
      setUsuarios(usuariosAtualizados);
      setUsuariosSelecionados([]);
      setLoading(false)
    },
    [empresa.id, setLoading, setUsuarios, usuarios, usuariosSelecionados]
  );

  const excluir = useCallback(() => {
    showDialog(
      <ConfirmDialogV2
        message="Tem certeza de que deseja desativar os usuários selecionados?"
        onYes={handleExcluir}
      />
    );
  }, [handleExcluir, showDialog]);

  const desvincular = useCallback(() => {
    showDialog(
      <ConfirmDialogV2
        message="Tem certeza de que deseja desvincular os usuários selecionados?"
        onYes={handleDesvincular}
      />
    );
  }, [handleDesvincular, showDialog]);

  let headerGroup = useMemo(
    () => (
      <ColumnGroup>
        <Row>
          <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
          <Column header="Status" name="status" />
          <Column sortable header="Nome" field="nome" />
          <Column sortable header="E-mail" field="email" />
          <Column sortable header="Departamento" field="departamento" />
        </Row>
      </ColumnGroup>
    ),
    []
  );

  const atualizarUsuario = (e) => {
    if (!e) return;

    const usuariosAtualizados = usuarios.map((usuario) => {
      if (usuario.id === e.id) {
        return e;
      } else {
        return usuario;
      }
    });

    setUsuarios(usuariosAtualizados);
  };
  const handleEditar = async (e) => {
    setLoading(true)
    let usuarioCompleto = e.data;
    
    if (!e?.data?._editado) {
      usuarioCompleto = await usuarioService.buscar(e.data.id);
    }
    
    setLoading(false)
    return showDialog(
      <EditarEmpresaUsuarioModal
        value={usuarioCompleto}
        onModalClose={atualizarUsuario}
        filiais={filiais}
        empresa={empresa}
      />
    );
  };


  async function handleSelect(event) {
    if (
      event.value?.id === -1 &&
      event?.originalEvent._reactName === "onClick"
    ) {
      showDialog(
        <EditarEmpresaUsuarioModal
          value={{
            ...usuarioService.criarUsuarioPortal(),
            email: event.value.nome,
          }}
          filiais={filiais}
          empresa={empresa}
          onModalClose={(value) => {
            if (value) {
              return adicionarModal(value);
            }

          }}
        />
      );
    } else {
      if (event?.originalEvent._reactName !== "onClick") return;
      usuarioService.buscar(event.value.id).then((res) => {
        showDialog(
          <EditarEmpresaUsuarioModal
            value={{
              ...res,
              tipo: "PORTAL_OPERADOR",
            }}
            filiais={filiais}
            empresa={empresa}
            onModalClose={(value) => {
              if (value) {
                return adicionarModal(value);
              }
            }}
          />
        );
      });
    }
  }

  const leftToolbarTemplate = () => {
    return (
      <UsuarioPortalAutoComplete
        col={12}
        value={usuario}
        label="Adcionar usuário"
        onChange={(e) => setUsuario(e.value)}
        onSelect={handleSelect}
      />
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label="Desvincular"
          warning
          icon="fa-solid fa-triangle-exclamation"
          onClick={desvincular}
          disabled={!usuariosSelecionados.length > 0}
        />
        <Button
          label="Desativar"
          danger
          icon="fa-solid fa-x"
          onClick={excluir}
          disabled={!usuariosSelecionados.length > 0}
        />
      </>
    );
  };

  function templateStatus(data) {
    if (data?.status === "ATIVO") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            title="Ativado"
            className="fa-solid fa-user fa-lg"
            style={{ color: "#00940a" }}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            title="Desativado"
            className="fa-solid fa-user fa-lg"
            style={{ color: "#d10404" }}
          />
        </div>
      );
    }
  }
  return (
    <div className="col-12 grid-nogutter">
      <div className="col-12">
        <Toolbar
          className="mb-0 p-1 col-12 grid p-fluid"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
      </div>
      <div className="col-12 pt-0">
        <DataTable
          emptyMessage="Nenhum usuário adicionado."
          paginator={true}
          rows={50}
          dataKey={"id"}
          loading={loading}
          headerColumnGroup={headerGroup}
          selectionMode="checkbox"
          selection={usuariosSelecionados}
          onSelectionChange={(e) => setUsuariosSelecionados(e.value)}
          value={usuarios}
          valuelength={usuarios?.length}
          onRowDoubleClick={handleEditar}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "1em" }} />
          <Column
            style={{ width: "2em" }}
            sortable
            header="Status"
            body={(e) => templateStatus(e)}
          />
          <Column sortable header="Nome" field="nome" />
          <Column sortable header="E-mail" field="email" />
          <Column sortable header="Departamento" field="departamento" />
        </DataTable>
      </div>
    </div>
  );
};
