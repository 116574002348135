import React, {useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {Button} from "../../../components/button";
import {BlockUI} from "primereact/blockui";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../context/AuthContext";
import {If} from "../../../components/conditional/If";
import {imprimirEtiquetasLaudoZebra, imprimirEtiquetasOsZebra} from "../../../service/etiquetaService";
import {RefetchIndicator} from "../../../components/refetchindicator/RefetchIndicator";
import {useQueryClientBackoffice} from "../../../utils/query-clients/queryClients";
import {FiltrosOrdemServicoBackoffice} from "../../__Commons/FiltrosOrdemServicoBackoffice";
import {SearchButton} from "../../../components/button/SearchButton";
import {usePagesAndTabs} from "../../../utils/PageAndTabContext";
import {QueryParamsKeys} from "../../../utils/defaultQueryParams";
import {GuiasBackoffice} from "../../__Commons-OrdemServico/GuiasBackoffice";

export function BackOfficeRecepcao() {

	const {roles} = useAuth();
	const navigate = useNavigate();
	const {query, handleSearch} = useQueryClientBackoffice();
	const [selecionados, setSelecionados] = useState([]);
	const {pageAndTab, setScroll} = usePagesAndTabs(QueryParamsKeys.Laboratorio);

	useEffect(() => {
		if (pageAndTab.scroll) {
			setTimeout(() => {
				window.scrollTo(0, pageAndTab.scroll);
				setScroll(0);
			}, 50);
		}
	}, []);

	useEffect(() => setSelecionados([]), [query.data]);

	function handleNotaFiscal() {
		navigate("/backoffice/recepcao/nota-fiscal");
	}

	function handleCriar() {
		navigate("/backoffice/recepcao/new");
	}

	return (
		<If condition={roles.BOFB || roles.BOFA}>
			<BlockUI blocked={query.isLoading}>
				<Panel header="BackOffice - Recepção/Expedição">
					<PanelContent>
						<FiltrosOrdemServicoBackoffice/>
					</PanelContent>
					<PanelFooter>
						<Button title="Imprimir etiqueta da ordem de serviço (azul)" onClick={() => imprimirEtiquetasOsZebra(selecionados)} disabled={!selecionados?.length} icon="fas fa-barcode"/>
						<Button title="Imprimir etiqueta do laudo da ordem de serviço (branca)" onClick={() => imprimirEtiquetasLaudoZebra(selecionados)} disabled={!selecionados?.length} icon="fas fa-barcode" clear/>
						<i className="fa-solid fa-circle" style={{lineHeight: "36px", color: "rgba(0, 0, 0, .1)", margin: "0 1em"}}/>
						<If condition={roles.OSSA} children={<Button title="Registrar entrada de equipamentos por nota fiscal de entrada" success icon="fas fa-file-pdf" label="Registrar Entrada" onClick={handleNotaFiscal}/>}/>
						<If condition={roles.OSSA} children={<Button title="Registrar nova ordem de serviço (sem nota fiscal de entrada)" success icon="fas fa-plus" onClick={handleCriar}/>}/>
						<SearchButton query={query} onSearch={handleSearch}/>
					</PanelFooter>
				</Panel>
				<GuiasBackoffice value={query.data || []} onChangeSelecionados={setSelecionados}/>
				<RefetchIndicator isRefetching={query.isRefetching}/>
			</BlockUI>
		</If>
	);

}
