import React from 'react';
import {InputMask as PInputMask} from 'primereact/inputmask';
import classNames from "classnames";
import {calcularTamanho} from "../../service/guiHelper";

export function InputMask(props) {

    function handleChange(event) {
        if (props.onChange) {
            props.onChange({name: props.name, value: event.value, index: props.index});
        }
    }

    const classes = calcularTamanho(props.col);

    const inputClasses = classNames({
        "p-invalid block": props.invalid
    });

    const newLocal = props.required ? <small style={{color: props.value?.length ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : <small>&nbsp;</small>;

    return (
        <div className={classes}>
            <label>{props.label}</label>
            <PInputMask {...props} className={inputClasses} onChange={handleChange} mask={props.mask} placeholder={!!props.placeholder  ? `Digite ${props.placeholder}` : null}/>
            {props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : newLocal}
        </div>
    );

}
