import React, {useCallback, useState} from 'react';

import {Column} from "primereact/column";
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';

import {useAuth} from '../../context/AuthContext';
import {DataTable} from '../../components/datatable';
import {InputTextarea} from '../../components/inputtextarea';
import {Button} from '../../components/button';
import {contratoService} from '../../service/contratoService';
import {UsuarioAutoComplete} from '../../components/autocomplete/usuarioAutoComplete';
import moment from 'moment';
import {withDialog} from "../../utils/dialogContext";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import { isEntityRequired, isRequired } from '../../utils/fieldValidator';

export const EditarAcompanhamento = withDialog(({contrato, setContrato, messages, setMessages, showDialog}) => {

    const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);

    const {usuario} = useAuth()

    const handleChange = useCallback((rowIndex, event) => {
        const  acompanhamentos = contrato.acompanhamentos.map((acompanhamento, index) => {
            if (rowIndex === index) {
                return {...acompanhamento,  [event.name]: event.value};
            } else {
                return acompanhamento;
            }
        });
        setContrato({...contrato, acompanhamentos})
    }, [contrato, setContrato])

    const  validarItem = useCallback((e, index, data) =>  {
        let erro;
        let acompanhamentos = [];
        
		switch (e?.target.name) {
            case 'responsavel':
                erro = isEntityRequired(data.responsavel)
                acompanhamentos = [...messages.acompanhamentos]
                acompanhamentos[index] = {...messages.acompanhamentos[index], responsavel: erro}
                break;
            case 'acompanhamento':
                erro = isRequired(data.acompanhamento)
                acompanhamentos = [...messages.acompanhamentos]
                acompanhamentos[index] = {...messages.acompanhamentos[index], acompanhamento: erro}
                break;
            default:
                break;
        }

        setMessages((messages) => {
            return {
                ...messages,
                acompanhamentos
            }
        })
	}, [messages.acompanhamentos, setMessages])

    const acompanhamentoTemplate = useCallback((colunmEvent1, colunmEvent2) => {
        return <InputTextarea
            col={12}
            placeholder='as informações do acompanhamento aqui.'
            value={contrato?.acompanhamentos[colunmEvent2.rowIndex]?.acompanhamento} 
            onChange={(e) => handleChange(colunmEvent2.rowIndex, e)}
            name='acompanhamento'
            onBlur={(e) => validarItem(e, colunmEvent2.rowIndex, colunmEvent1)}
            invalid={messages.acompanhamentos?.[colunmEvent2.rowIndex]?.acompanhamento}
        />
    }, [contrato.acompanhamentos, handleChange, messages.acompanhamentos, validarItem])

    const responsalvelTempalte = useCallback((colunmEvent1, colunmEvent2) => {
        return <UsuarioAutoComplete
            col={12}
            value={contrato?.acompanhamentos[colunmEvent2.rowIndex]?.responsavel} 
            onChange={(e) => handleChange(colunmEvent2.rowIndex, e)}
            name='responsavel'
            label={null}
            onBlur={(e) => validarItem(e, colunmEvent2.rowIndex, colunmEvent1)}
            invalid={messages.acompanhamentos?.[colunmEvent2.rowIndex]?.responsavel}
            reduced={!messages.acompanhamentos?.[colunmEvent2.rowIndex]?.responsavel}
        />
    }, [contrato.acompanhamentos, handleChange, messages.acompanhamentos, validarItem])

    const adicionarAcompanhamento = useCallback(() => {
        
        const novoAcompanhamento = {
            ...contratoService.criarContratoAcompanhamento(contrato.acompanhamentos),
            atribuidor: {
                nome: usuario.nome,
                id: usuario.id
            }
        }

        setContrato({...contrato, acompanhamentos: [...contrato.acompanhamentos, novoAcompanhamento]})
    }, [contrato, setContrato, usuario])

    const excluirAcompanhamento = useCallback(() => {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os acompanhamentos selecionados?" onYes={() => {
	        const acompanhamentos = contrato.acompanhamentos.filter(osi => !linhasSelecionadas.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
            if(acompanhamentos.length === 0){
                setMessages(messages => {
                    return {...messages, acompanhamentos: []}
                })
            }
	        setContrato({...contrato, acompanhamentos})
	        setLinhasSelecionadas([]);
        }}/>);


    }, [contrato, linhasSelecionadas, setContrato, setMessages, showDialog])

    const servicoHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={12} header={
					<div>
						<Button icon="pi pi-plus" onClick={adicionarAcompanhamento}/>
                        <Button
                            danger
                            icon='fa-solid fa-x'
                            onClick={excluirAcompanhamento}
                             disabled={linhasSelecionadas.length === 0}
                        />
					</div>
				}/>
			</Row>
            <Row>
                    <Column selectionMode="multiple" headerStyle={{width: '1em'}}/>
                    <Column header='Item' />
                    <Column header="Data/hora" />
                    <Column header="Atribuidor" />
                    <Column header="Responsável"  />
                    <Column header="Acompanhamento"/>
			</Row>
		</ColumnGroup>
	);

	const servicoFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={12} footer={
					<div>
						<Button icon="pi pi-plus" onClick={adicionarAcompanhamento}/>
                        <Button
                            danger
                            icon='fa-solid fa-x'
                            onClick={excluirAcompanhamento}
                             disabled={linhasSelecionadas.length === 0}
                        />
					</div>
				}/>
			</Row>
		</ColumnGroup>
	);

    return (
        <>
            <div className='col-12 p-0'>
                <DataTable 
                    value={contrato.acompanhamentos}
                    emptyMessage='Nenhuma acompanhamento foi adicionado.'
                    valuelength={contrato.acompanhamentos?.length}
                    rows={100}
                    paginator={false}
                    reorderableRows 
                    selectionMode="checkbox"
                    selection={linhasSelecionadas}
                    onSelectionChange={e => setLinhasSelecionadas(e.value)} 
                    headerColumnGroup={servicoHeaderGroup}
                    footerColumnGroup={servicoFooterGroup}
                    size='small'
                >
                    <Column selectionMode="multiple" headerStyle={{width: '1em'}}/>
                    <Column field='item' header='Item' style={{width: '3em', textAlign: 'center'}} />
                    <Column 
                        header="Data/hora" 
                        body={(e) => <div>{moment(e.data).format('DD/MM/YYYY HH:mm:ss')} </div>}
                        style={{ width: '15em'}} 
                    />
                    <Column header="Atribuidor" field='atribuidor.nome' style={{ width: '15em'}} />
                    <Column header="Responsável" body={responsalvelTempalte} style={{ width: '30em'}} />
                    <Column header="Acompanhamento" body={acompanhamentoTemplate} style={{ width: '*'}} />
                </DataTable>
            </div>
        </>
    );

});
