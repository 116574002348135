import React, {useEffect, useState} from 'react';
import {FormEdit} from "../../components/form/Edit";
import {useParams} from "react-router-dom";
import {InputText} from "../../components/inputtext";
import {produtoService} from '../../service/produtoService';
import {useAuth} from "../../context/AuthContext";
import {yesNoOptions} from "../../service/ordemServicoService";
import {SelectButton} from "../../components/selectbutton";
import { DataTable } from '../../components/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

export function EditarProduto() {

    const {id} = useParams();
    const {roles} = useAuth();
    const [produto, setProduto] = useState(produtoService.criar());

    function handleChange(event) {
        setProduto({...produto, [event.name]: event.value});
    }

    useEffect(() => {
        if (id !== "new") {
            produtoService.buscar(id).then(setProduto);
        }
    }, [id]);

    const saldoDisponivel = () => {
        let total = 0;
        
        if (produto.vwProduto) {
            for (let estoque of produto.vwProduto.estoques) {
                total = total + estoque.saldoDisponivel;
            }
        }
    
    return (
            <div>
                <div className="flex align-items-center	justify-content-between	">
                    <div>
                        {total}
                    </div>
                </div>
            </div>
        )
    }

    let footerGroup = (
	    <ColumnGroup>
		    <Row>
			    <Column footer="Saldo Geral:" colSpan={2} footerStyle={{textAlign: 'right'}}/>
			    <Column footer={saldoDisponivel} footerStyle={{textAlign: 'right', width: "16em"}} />
                <Column colSpan={2}/>
		    </Row>
	    </ColumnGroup>
    );

    return (
        <FormEdit podeDeletar={false} podeSalvar={!!roles.PROC} descricao="Produto" service={produtoService} value={produto} blocked={id !== "new" && !produto.id}>
            <InputText col={2} label="Produto" name="produto" value={produto?.vwProduto?.produtoId} readOnly/>
            <InputText col={6} label="Descrição" name="descricao" value={produto?.vwProduto?.descricao} readOnly/>
            <InputText col={2} label="NCM" name="ncm" value={produto?.vwProduto?.ncm} readOnly/>
            <InputText col={2} label="Unidade" name="unidade" value={produto?.vwProduto?.unidade} readOnly/>
            <InputText col={4} label="Part Number" name="partNumber" value={produto?.vwProduto?.partNumber} readOnly/>
            {/* <InputText col={2} label="Saldo Disponível Geral" name="saldoDisponivel" value={produto?.vwProduto?.saldoDisponivel} readOnly/>
            <InputText col={2} label="Saldo Disponível Laboratório" name="saldoDisponivelLaboratorio" value={produto?.vwProduto?.saldoDisponivelLaboratorio} readOnly/>
            <InputText col={2} label="Saldo Disponível Técnico" name="saldoDisponivelTecnico" value={produto?.vwProduto?.saldoDisponivelTecnico} readOnly/> */}
            <SelectButton name="equipamentoEspecial" label="Equipamento Especial" options={yesNoOptions} col={2} value={produto.equipamentoEspecial} onChange={handleChange}/>
            
            <div className='col-12 pt-0'>
                <DataTable value={produto?.vwProduto?.estoques} col={12} footerColumnGroup={footerGroup}>
                    <Column header="Código" field="estoqueCodigo" />
                    <Column sortable header="Depósito" field="estoqueNome" />
                    <Column header="Saldo Disponível " field="saldoDisponivel" />
                    <Column header="Sistema" field="sistema" />
                    <Column header="Tipo do Estoque" field="tipo" />
                </DataTable>
            </div>
           
        </FormEdit>
    );

}
