import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {useAuth} from "../../context/AuthContext";
import {Column} from "primereact/column";
import {formatDateTime} from "../../utils/numberFormatter";
import {iconStatus, labelStatus, ordemServicoService} from "../../service/ordemServicoService";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Button} from "../../components/button";
import {withDialog} from "../../utils/dialogContext";
import {BlockUI} from "primereact/blockui";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {ProgressDialog} from "../../components/progressdialog/ProgressDialog";
import {optionsOperacaoComercial} from "../__Commons/FiltrosOrdemServicoComercial";
import {usePagesAndTabs} from "../../utils/PageAndTabContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {ReatribuirOrdemServico} from "../OrdensServico/ReatribuirOrdemServico";
import {EditarAcompanhamento} from "../OrdensServico/EditarAcompanhamento";
import {CardCliente} from "../../components/cards/CardCliente";
import {If} from "../../components/conditional/If";
import {CardSLA} from "../../components/cardsla";
import {OrdemServicoChatDialog} from "../../components/ordemservico-chat";
import {ordemServicoChatService} from "../../service/ordemServicoChatService";
import "./Suporte.scss";
import {useQueryClientSuporte} from "../../utils/query-clients/queryClients";
import {formatNumber} from "chart.js/helpers";
import {avaliacaoTemplate} from "../../utils/templates";

export const TabelaOrdemServico = withDialog(({value, setHideFilters, showDialog}) => {

    const {roles} = useAuth();
    const navigate = useNavigate();

    const [ordemServico, setOrdemServico] = useState(null);
    const [acompanhamento, setAcompanhamento] = useState(null);
    const [servicosSelecionados, setServicosSelecionados] = useState([]);
    const [loadingAvancarEtapas, setLoadingAvancarEtapas] = useState(false);
    const {pageAndTab, setPage, setScroll} = usePagesAndTabs(QueryParamsKeys.Suporte);
    const [lastChats, setLastChats] = useState({});
    const {handleSearch} = useQueryClientSuporte();

    function handleEditar(event) {
        if (!roles.OSSC) return;
        setScroll();
        navigate(`/suporte/${event.data.id}`);
    }

    // function refreshChatBubble(value) {
    //     if (value?.length) {
    //         ordemServicoChatService.lastChats(value.map(v => v.id)).then(result => {
    //             const lastChats = {};
    //             for (const lastChat of result) {
    //                 lastChats[lastChat.id] = lastChat.tipo;
    //             }
    //             setLastChats(lastChats);
    //         });
    //     }
    // }

    // useEffect(() => {
    //     refreshChatBubble(value);
    //     const chatRefreshInterval = setInterval(() => refreshChatBubble(value), 30000);
    //     return () => {
    //         clearInterval(chatRefreshInterval);
    //     };
    // }, [value]);

    function avancarEtapas() {
        const ordensServico = [...servicosSelecionados];
        if (ordensServico.length) {
            const ordemServico = ordensServico[0];
            if (ordensServico.some(os => os.fluxo !== ordemServico.fluxo || os.etapa?.id !== ordemServico.etapa?.id)) {
                showDialog(<InformationDialog header="Informação" message="Não é possível avançar ordens de serviço em etapas ou fluxos em versões diferentes"/>);
            } else {
                setLoadingAvancarEtapas(true);
                ordemServicoService.buscar(ordemServico.id).then(ordemServico => {
                    setHideFilters(true);
                    setOrdemServico(ordemServico);
                    setAcompanhamento(ordemServicoService.criarAcompanhamento());
                    setLoadingAvancarEtapas(false);
                });
            }
        }
    }

    function reatribuirOrdensServico() {
        showDialog(<ReatribuirOrdemServico key={Math.random()} ordensServico={[...servicosSelecionados]} onModalClose={async () => {
            setServicosSelecionados([]);
            await handleSearch();
        }}/>);
    }

    const servicoControlSet = (
        <div style={{position: "relative"}}>
            <Button style={{whiteSpace: "nowrap"}} icon="fa-solid fa-arrow-right" loading={loadingAvancarEtapas} disabled={!servicosSelecionados?.length || loadingAvancarEtapas} onClick={avancarEtapas} label="Avançar Etapas"/>
            <Button style={{whiteSpace: "nowrap"}} icon="fa-solid fa-users" disabled={!servicosSelecionados?.length} label="Reatribuir OS" onClick={reatribuirOrdensServico}/>
            <div style={{position: "absolute", top: ".25em", right: ".25em"}}>
                Quantidade: {value?.length || 0}
            </div>
        </div>
    );

    const servicoHeaderGroup = (
        <ColumnGroup>
            <Row>
                <Column header={servicoControlSet} colSpan={7}/>
            </Row>
            <Row>
                <Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
                <Column sortable field="status" headerStyle={{width: "6em", textAlign: "center"}} header="Dados do Suporte"/>
                <Column sortable field="cliente.nomeFantasia" header="Cliente"/>
                <Column sortable field="equipamento.serial" header="Equipamento"/>
                <Column sortable field="responsavelPortal.nome" header="Aberto Por"/>
                <Column sortable field="responsavel.nome" header="Atualizado Por"/>
                {/*<Column style={{width: "5em"}} header="Ações"/>*/}
            </Row>
        </ColumnGroup>
    );

    async function salvarAcompanhamento(acompanhamento) {
        showDialog(<ProgressDialog onProgress={async setProgresso => {
            let progresso = 0;
            for (const ordemServico of servicosSelecionados) {
                await ordemServicoService.buscar(ordemServico.id).then(async ordemServico => {
                    ordemServico.acompanhamentos.push(acompanhamento);
                    ordemServico.etapa = acompanhamento.etapa;
                    ordemServico.responsavel = acompanhamento.responsavel;
                    if (acompanhamento.etapa?.status) {
                        ordemServico.status = acompanhamento.etapa.status;
                    }
                    await ordemServicoService.salvar(ordemServico);
                });
                ++progresso;
                setProgresso(progresso / servicosSelecionados.length);
            }
            voltarAcompanhamento();
        }}/>);
    }

    function voltarAcompanhamento() {
        setOrdemServico(null);
        setAcompanhamento(null);
        setServicosSelecionados([]);
        setHideFilters(false);
    }

    if (acompanhamento && ordemServico) {
        return (
            <EditarAcompanhamento
                multiple
                ordemServico={ordemServico}
                acompanhamento={acompanhamento}
                setAcompanhamento={setAcompanhamento}
                onVoltar={voltarAcompanhamento}
                onSalvar={salvarAcompanhamento}
            />
        );
    }

    const onSelectionChange = (e, e2) => {
        setServicosSelecionados(e.value)
    }

    const numeroTemplate = (data) => {
        return (
            <div>
                <div><i style={{color: "#E91E63"}} className="fas fa-headset"/> {formatNumber(data.numero)}</div>
                <div>{iconStatus[data.status]} {data.etapa?.descricao || ""}</div>
            </div>
        );
    }

    function abrirChat(os) {
        setLastChats(prevLastChats => {
            prevLastChats[os.id] = null;
            return prevLastChats;
        })
        showDialog(<OrdemServicoChatDialog ordemServico={os} onClose={handleSearch}/>);
    }

    return (
        <BlockUI blocked={loadingAvancarEtapas}>
            <DataTable
                value={value}
                onRowDoubleClick={handleEditar}
                valuelength={value?.length}
                selection={servicosSelecionados}
                onSelectionChange={onSelectionChange}
                selectionMode="checkbox"
                first={20 * pageAndTab.page}
                onPage={e => setPage(e.page)}
                headerColumnGroup={servicoHeaderGroup}
            >
                <Column selectionMode="multiple" style={{cursor: "default"}}/>
                <Column sortable field="numero" style={{whiteSpace: "nowrap"}} body={numeroTemplate}/>
                <Column sortable field="cliente.autoComplete" style={{position: "relative"}} header="Contrato/Cliente" body={os => (
                    <div>
                        <CardCliente cliente={os.cliente} ordemServico={os}/>
                        <If condition={os.contrato?.acordoNivelServicoSuporte?.id && os.previsaoFimSuporte && os.inicio}>
                            <CardSLA ordemServico={os}/>
                        </If>
                    </div>
                )}/>
                <Column sortable field="equipamento.produto.descricao" style={{cursor: "default"}} body={os => (
                    <div>
                        <div>{os.equipamento?.produto?.descricao}</div>
                        <div>N/S: {os.equipamento?.serial}</div>
                    </div>
                )}/>
                <Column sortable field="responsavelPortal.nome" body={os => (
                    <div>
                        <If condition={!os.responsavel?.id}>
                            <div className="bg-red-400 text-white p-2 border-round-md">
                                Nenhum responsável atribuído!
                            </div>
                        </If>
                        <div>{os.responsavelPortal?.nome}</div>
                        <div>{os.responsavelPortal?.email}{os.responsavelPortal?.telefone?.length ? ` (${os.responsavelPortal?.telefone})` : ""}</div>
                        <div>{formatDateTime(os.inicio)}</div>
                        {os.avaliacao ? <div title="Avaliação do Atendimento" className="flex justify-content-center p-1">{avaliacaoTemplate(os.avaliacao)}</div> : null}
                    </div>
                )}/>
                <Column sortable field="responsavel.nome" body={os => {
                    const isUltimaAtualizacaoCliente = ["PORTAL_GESTOR", "PORTAL_OPERADOR"].includes(os.ultimaAlteracaoPor?.tipo);
                    return (
                      <div className={`relative ${isUltimaAtualizacaoCliente ? "bg-orange-400 border-round-md p-2 text-white" : ""}`}>
                          <If condition={isUltimaAtualizacaoCliente}>
                              <i className="absolute right-0 top-0 m-2 fas fa-asterisk"/>
                          </If>
                          <div>{os.ultimaAlteracaoPor?.nome}</div>
                          <div>{os.ultimaAlteracaoPor?.email}</div>
                          <div>{os.ultimaAlteracao && formatDateTime(os.ultimaAlteracao)}</div>
                      </div>
                    );
                }}/>
                {/*<Column body={os => (*/}
                {/*    <div style={{textAlign: "center", position: "relative"}}>*/}
                {/*        <Button icon="fas fa-headset" title="Abrir Chat de Atendimento" onClick={() => abrirChat(os)}/>*/}
                {/*        {lastChats[os.id] === "CLIENTE" ? <i title="Contém mensagem(ns) não lida(s)" className="fas fa-circle chat-notification"/> : null}*/}
                {/*    </div>*/}
                {/*)}/>*/}
            </DataTable>
        </BlockUI>
    );

});
