import React from "react";

import { useNavigate } from "react-router-dom";
import { Column } from "primereact/column";

import { DataTable } from "../../../../components/datatable";

export const TabelaTipoComissao = React.memo(({value}) => {
    const navigate = useNavigate();

    function handleEditar(event) {
        navigate(`/comercial/comissao/tipoComissao/${event.data.id}`);
    }

    return (
        <DataTable
            value={value}
            onRowDoubleClick={handleEditar}
            valuelength={value?.length}
        >
            <Column sortable field="descricao" header="Descrição" style={{textAlign: "left", width: "*"}}/>
        </DataTable>
    );
});