import React from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import ClienteRazaoSocialTemplate from "../Templates/ClienteRazaoSocialDTOTemplate";

export const TabelaCliente = React.memo(({value}) => {

    const navigate = useNavigate();

    function handleEditar(event) {
        navigate(`/clientes/${event.data.id}`);
    }

    const clienteTemplate = (data) => {
        return <ClienteRazaoSocialTemplate data={data} />
    }

    const templateStatus = (data) => {
        if(data.status === 'ATIVO'){
            return (
                <div style={{display: 'flex', alignItems: "center", justifyContent:"center"}}>
                    <i title="Ativado" className="fa-solid fa-user-tie fa-lg" style={{color:'#00940a'}}/>
                </div>
            )
        }else{
            return (
                <div style={{display: 'flex', alignItems: "center", justifyContent:"center"}}>
                    <i title="Desativado" className="fa-solid fa-user-tie fa-lg" style={{color:'#d10404'}}/>
                </div>
            )
        }
    }

    return (
        <DataTable value={value} onRowDoubleClick={handleEditar} valuelength={value?.length}>
            <Column style={{ width: '2em' }} sortable header="Status" body={e => templateStatus(e)}/>
            <Column sortable field="clienteId" header="Cliente"  style={{ width: '*' }} />
            <Column sortable field="nomeFantasia" header="Nome Fantasia"  style={{ width: '*' }} body={clienteTemplate}/>
            <Column sortable field="razaoSocial" header="Razão Social"  style={{ width: '*' }} />
            <Column sortable field="cpfCnpj" header="CNPJ"  style={{ width: '*'}} />
            <Column sortable field="municipio" header="Município"  style={{ width: '*' }} />
            <Column sortable field="municipio" header="Município"  style={{ width: '*' }} />
            <Column sortable field="uf" header="UF"  style={{ width: '*', textAlign: 'center' }} />
            <Column sortable field="inscricaoEstadual" header="Inscrição Estadual"  style={{ width: '*' }} />
            <Column sortable field="autoCompleteCondicaoPagamento" header="Condição de Pagamento"  style={{ width: '*' }} />
        </DataTable>
    );
});