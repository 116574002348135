import React, {useEffect, useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {ordemServicoService} from "../../service/ordemServicoService";
import {CsvInput} from "../../components/chips/CsvInput";
import { InputTextarea } from "../../components/inputtextarea";

export function OrdemServicoOrcamentoEmailReenvio({ordemServicoItemOrcamento}) {

	console.log('ordemServicoItemOrcamento: ', ordemServicoItemOrcamento)

	const [ultimoEmailOrcamento, setUltimoEmailOrcamento] = useState({
		id: null,
		cliente: null,
		destinatarios: [],
	})

	useEffect(() => {
		ordemServicoService.ultimoEmailOrcamento(ordemServicoItemOrcamento.id).then((res) => {
			console.log('res: ', res)
			setUltimoEmailOrcamento(res)
		})
	}, [ordemServicoItemOrcamento.id])

	let titulo =`Orçamento para a Ordem de Serviço`;

	const [params, setParams] = useState({
		titulo,
		conteudo: "",
	});

	const [visible, setVisible] = useState(true);
	const [blocked, setBlocked] = useState(0);

	function handleFechar() {
		setVisible(false);
	}

	function handleChange(event) {
		setParams({...params, [event.name]: event.value});
	}

	function handleChangeEmailOrcamento(event) {
		setUltimoEmailOrcamento({...ultimoEmailOrcamento, [event.name]: event.value});
	}

	function enviarOrcamentos() {
		setBlocked(2);
		const criterio = {
			...params,
			dtoEmailOrcamento: {
				...ultimoEmailOrcamento,
				destinatarios: ultimoEmailOrcamento.destinatarios.filter(des => des.length > 0),
			},

			ordemServicoItemOrcamento,

		}

		console.log(criterio)

		ordemServicoService.reenviarOrcamento({...criterio}).then(() => {
			setBlocked(0);
			handleFechar();
		});
	}

	return (
		<Dialog style={{maxWidth: "1200px", width: "90%"}} header={`Reenviando Orçamentos para OS `} visible={visible} onHide={handleFechar}>
			<PanelContent>
				<CsvInput disabled={blocked} col={12} name="destinatarios" value={ultimoEmailOrcamento.destinatarios} onChange={handleChangeEmailOrcamento} label={<span>Destinatários <i>(ao final de cada endereço, pressione Enter)</i></span>}/>
				<InputText disabled={blocked} label="Título" name="titulo" value={params.titulo} onChange={handleChange} col={12}/>
				<InputTextarea  disabled={blocked} label="Conteúdo" name="conteudo" value={params.conteudo} onChange={handleChange} col={12} style={{height: "13em"}}/>
			</PanelContent>
			<DialogFooter>
				<Button loading={blocked === 2} disabled={blocked} label="Enviar" success onClick={enviarOrcamentos} icon="fa-solid fa-envelope"/>
				<Button label="Cancelar" secondary onClick={handleFechar} icon="fa-solid fa-times"/>
			</DialogFooter>
		</Dialog>
	);
}
