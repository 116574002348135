import React from "react";
import {Calendar as PCalendar} from "primereact/calendar";
import classNames from "classnames";
import moment from "moment";
import {InputText} from "../inputtext";
import {formatDate} from "../../utils/numberFormatter";
import {calcularTamanho} from "../../service/guiHelper";

export function Calendar(props) {

    function handleChange(event) {
        if (props.readOnly) return;
        let value;
        const pattern = props.showTime ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DD";
        if (props.selectionMode === "range") {
            value = event.value?.map(data => data ? moment(data).format(pattern) : null);
        } else {
            value = event.value ? moment(event.value).format(pattern) : null;
        }
        if (props.onChange) {
            props.onChange({name: props.name, value, index: props.index});
        }
    }

    const classes = calcularTamanho(props.col);

    const inputClasses = classNames({
        "p-invalid block": props.invalid
    });

    const value = props.selectionMode === "range" ? props.value?.map(v => v ? moment(v).toDate() : null) : props.value ? moment(props.value).toDate() : null;

    if (props.readOnly) {
        return <InputText {...props} value={formatDate(props.value)}/>;
    }

    const newLocal = props.required ? <small style={{color: props.value?.length ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : <small>&nbsp;</small>;
    
    return (
        <div className={classes}>
            <label>{props.label}</label>
            <PCalendar
                dateFormat="dd/mm/yy"                
                yearRange="2000:2040"
                showButtonBar
                readOnlyInput={props.readOnly}
                {...props}
                value={value}
                className={inputClasses}
                onChange={handleChange}
            />
            {props.reduced ? null : (props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : newLocal)}
        </div>
    );

}

export function SimpleCalendar(props) {

    function handleChange(event) {
        if (props.readOnly) return;
        let value;
        const pattern = props.showTime ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DD";
        if (props.selectionMode === "range") {
            value = event.value?.map(data => data ? moment(data).format(pattern) : null);
        } else {
            value = event.value ? moment(event.value).format(pattern) : null;
        }
        if (props.onChange) {
            props.onChange({name: props.name, value, index: props.index});
        }
    }

    const inputClasses = classNames({
        "p-invalid block": props.invalid
    });

    const value = props.selectionMode === "range" ? props.value?.map(v => v ? moment(v).toDate() : null) : props.value ? moment(props.value).toDate() : null;

    if (props.readOnly) {
        return <InputText {...props} value={formatDate(props.value)}/>;
    }

    return (
        <PCalendar dateFormat="dd/mm/yy" yearRange="2000:2040" showButtonBar readOnlyInput={props.readOnly} {...props} value={value} className={inputClasses} onChange={handleChange}/>
    );

}
