import moment from 'moment';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import React from 'react';
import {Button} from '../../../components/button';
import {ConfirmDialogV2} from '../../../components/confirmdialog';
import {DataTable} from '../../../components/datatable';
import {InputText} from '../../../components/inputtext';
import {useAuth} from '../../../context/AuthContext';
import {withDialog} from '../../../utils/dialogContext';
import {pedidoVendaService} from '../services/pedidoVendaService';

export const EditarEventos = withDialog(({pedidoVenda, setPedidoVenda, messages, disabled, showDialog}) => {

    const {usuario} = useAuth();

    const onCellEditComplete = (e) => {
        const  eventos = pedidoVenda.eventos.map((evento, index) => {
            if (e.rowIndex === index) {
                return {...evento,  [e.field]: e.newValue};
            } else {
                return evento;
            }
        });
        setPedidoVenda({...pedidoVenda, eventos})
    }

    const descricaoTempalte = (colunmEvent) => {
        if(colunmEvent.rowData?.forma === 'AUTOMATICO') return;

        return <InputText
            styles={{margin: 0}}
            col={12}
            placeholder='as informações do acompanhamento aqui.'
            value={pedidoVenda?.eventos[colunmEvent.rowIndex]?.acompanhamento} 
            onChange={(e) => colunmEvent.editorCallback(e.value)}
            name='acompanhamento'
            reduced
            disabled={disabled}
        />
    }

    const adicionarEvento = () => {
        const novoEvento = {
            ...pedidoVendaService.criarEvento(pedidoVenda.eventos),
            usuario: usuario.email,
        }
        
        setPedidoVenda({...pedidoVenda, eventos: [...pedidoVenda.eventos, novoEvento]})
    }

    const excluirEvento = (data, rowData) => {
        showDialog(
            <ConfirmDialogV2 
                message="Tem certeza de que deseja remover os eventos selecionados?" 
                onYes={() => {
	                const eventos = pedidoVenda.eventos.filter((item, posicao) => rowData.rowIndex !== posicao);
                    setPedidoVenda({...pedidoVenda, eventos});
                }}
            />
        );
    }
 
    const servicoHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={5} header={
					<div>
						<Button icon="pi pi-plus" onClick={adicionarEvento} disabled={disabled}/>
                        {/* <Button
                            title='Excluir clausula de contrato'
                            danger='true'
                            icon='fa-solid fa-x'
                            onClick={excluirEvento}
                            disabled={linhasSelecionadas.length === 0}
                        /> */}
					</div>
				}/>
			</Row>
            <Row>
                    <Column header='Data' />
                    <Column header="Usuario" />
                    <Column header='Forma' />
                    <Column header="Evento"/>
                    <Column header="Ações"/>
			</Row>
		</ColumnGroup>
	);

	const servicoFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={5} footer={
					<div>
						<Button icon="pi pi-plus" onClick={adicionarEvento} disabled={disabled}/>
                        {/* <Button
                            title='Excluir clausula de contrato'
                            danger='true'
                            icon='fa-solid fa-x'
                            onClick={excluirEvento}
                            disabled={linhasSelecionadas.length === 0}
                        /> */}
					</div>
				}/>
			</Row>
		</ColumnGroup>
	);

    const acoesTemplate = (data, rowData) => {  
        return (
            <div className='flex align-items-center justify-content-around'>
                {
                    data?.forma === 'MANUAL' && !disabled &&
                    <div className='p-2 cursor-pointer' title='Excluir envento' onClick={() => excluirEvento(data, rowData)}> 
                        <i className='fa-solid fa-trash-can fa-lg text-color-secondary'/>
                    </div>
                }
            </div>
        )
    }

    return (
        <>
            <div className='col-12 p-0'>
                <DataTable 
                    value={pedidoVenda.eventos}
                    emptyMessage='Nenhum evento encontrado.'
                    valuelength={pedidoVenda?.eventos?.length}
                    rows={100}
                    paginator={false}
                    headerColumnGroup={servicoHeaderGroup}
                    footerColumnGroup={servicoFooterGroup}
                    size='small'
                    selectionMode={null}
                    responsiveLayout="scroll"
                >   
                    <Column
                        header="Data" 
                        field="registro" 
                        style={{width: '15em'}}
                        headerStyle={{width: '1em'}} 
                        body={(e) => <div>{moment(e.registro).format('DD/MM/YYYY HH:mm:ss')} </div>}
                    />
                    <Column header="Usuario" field="usuario" style={{width: '20em'}}/>
                    <Column header="Forma" field="forma" style={{width: '10em'}}/>
                    <Column 
                        key={'observacoes'} 
                        field={'observacoes'}
                        editor={descricaoTempalte} 
                        onCellEditComplete={onCellEditComplete} 
                    />
                    <Column 
                        key={'acoes'} 
                        body={acoesTemplate}
                        style={{width: '5em'}}
                    />

                </DataTable>
            </div>
        </>
    );
})