import React, {useEffect, useMemo, useState} from 'react';
import { FiltrosAcompanhamentoIndividual } from './FiltrosAcompanhamentoIndividual';
import { TabelaAcompanhamentoIndividual } from './TabelaAcompanhamentoIndividual';
import { tipoComissaoService } from '../../services/tipoComissaoService';
import { usuarioService } from '../../../../service/usuarioService';
import { useAuth } from '../../../../context/AuthContext';
import { BlockUI } from 'primereact/blockui';
import { Fechamento } from './Fechamento';


export function AcompanhamentoIndividual() {

    const {usuario} = useAuth()
    const [acompanhamento, setAcompanhamento] = useState([]);
    const [vendedor, setVendedor] = useState();
    const [loading, setLoading] = useState(false);
    const [tiposComissao, setTiposComissao] = useState([]);
    const [fechamento, setFechamento] = useState();


    useEffect(async () => {

        setLoading(true)
        await usuarioService.buscar(usuario?.id).then((data) => setVendedor(data?.vendedor));
        await tipoComissaoService.listarCompleto([`query=${[].join(";")}`]).then((data) => setTiposComissao(data));
        setLoading(false)
        
    }, [usuario.id])

    return (
        <BlockUI blocked={loading}>
            <FiltrosAcompanhamentoIndividual 
                tiposComissao={useMemo(() => tiposComissao, [tiposComissao])} 
                setValue={useMemo(() => setAcompanhamento, [])}
                vendedor={useMemo(() => vendedor, [vendedor])}
                fechamento={useMemo(() => fechamento, [fechamento])}
                setFechamento={useMemo(() => setFechamento, [])} 
            />
             {
                fechamento?.id && <Fechamento fechamento={fechamento} />
            }
            <TabelaAcompanhamentoIndividual 
                tiposComissao={useMemo(() => tiposComissao, [tiposComissao])} 
                setAcompanhamento={useMemo(() => setAcompanhamento, [])} 
                acompanhamento={useMemo(() => acompanhamento, [acompanhamento])}
            />
        </BlockUI>
    );
}