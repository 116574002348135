import { buildValidator, isBiggerThanZero, isEntityRequired, isRequired } from "../utils/fieldValidator";
import { baseService } from "./baseService";
import {api} from "./api";

const resourceUrl = "/ordens-servico/notas-fiscais";

export const ordemServicoNotaFiscalService = {
	criar: () => {
		return {
			id: null,
			_key: Math.random(),
			numero: null,
			chave: "",
			valor: null,
			data: null,
			descricao: "",
			observacoes: "",
			itens: []
		};
	},
	criarItem: () => {
		return {
			id: null,
			_key: Math.random(),
			equipamento: null,
			valor: null
		};
	},
	validar(notaFiscal) {
		const messages = buildValidator();
		messages.numero = isBiggerThanZero(notaFiscal.numero);
		messages.chave = isRequired(notaFiscal.chave);
		messages.data = isRequired(notaFiscal.data);
		messages.valor = isBiggerThanZero(notaFiscal.valor);
		return messages;
	},
	validarParams(params) {
		const messages = buildValidator();
		messages.cliente = isEntityRequired(params.cliente);
		messages.tipo = isRequired(params.tipo);
		messages.fluxo = isEntityRequired(params.fluxo);
		messages.etapa = isEntityRequired(params.etapa);
		messages.responsavel = isEntityRequired(params.responsavel);
		return messages;
	},
	async buscarPorNumero(numero, cpfCnpj) {
		return await api.get(`${resourceUrl}/alt?numero=${numero}&cpfCnpj=${cpfCnpj}`).then(response => response.data);
	},
	...baseService(resourceUrl),
	async vincularNotaFiscal(id, os) {
		return await api.post(`${resourceUrl}/vincular/${id}`, os);
	}
};
