export const statusPadrao = {
    sucesso: {
        icon: "fa-check",
        color: "#4CAF50"
    },
    warning: {
        icon: "fa-question",
        color: "#FF9800"
    },
    cancelado: {
        icon: "fa-x",
        color: "#F44336"
    },
    renovado: {
        icon: "fa-arrows-rotate",
        color: "#2196F3"
    }
}
