import React, {useState} from 'react';
import {AutoComplete} from '.';
import { indiceService } from '../../pages/Comercial/services/indiceService';

export function IndiceAutoComplete(props) {

	const [indices, setIndices] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("")

	function completeMethod(event) {
		const query = [];
		if (event.query.length) query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);
		indiceService.autocomplete(query).then(res => {
			setIndices(res);
			if (!res[0]) {
				setEmptyMessage("Nenhum registro encontrado");
			} else {
				setEmptyMessage("");
			}
		});
	}

	return (
		<AutoComplete
			col={3}
			placeholder="Código ou descrição"
			label="Índice"
			name="indice"
			value={props.value}
			completeMethod={completeMethod}
			suggestions={indices}
			invalid={emptyMessage}
			icon="fa-solid fa-money-bill-transfer"		
			{...props}
		/>
	)
}
