import React, {useEffect, useRef, useState} from "react";
import SignatureCanvas from "react-signature-canvas";

import "./index.scss";
import {Button} from "../button";
import {toSrc} from "../../service/fotoUtils";

export function Signature(props) {

	const containerRef = useRef(null);
	const signatureRef = useRef(null);

	const [width, setWidth] = useState(0);

	useEffect(() => {
		if (containerRef.current) {
			setWidth(containerRef.current.getBoundingClientRect().width - 14);
		}
		if (containerRef.current && signatureRef.current) {
			const observerFunction = () => {
				if (containerRef.current) {
					setWidth(containerRef.current.getBoundingClientRect().width - 14);
				}
			};
			const resizeObserver = new ResizeObserver(observerFunction);
			resizeObserver.observe(containerRef.current);
			return () => {
				resizeObserver.disconnect();
			};
		}
	}, []);

	useEffect(() => {
		if (signatureRef.current) {
			signatureRef.current.fromDataURL(!props.value || props.value.startsWith("data") ? props.value : `data:image/png;base64,${props.value}`);
		}
	}, [width]);

	function handleStroke() {
		if (props.onChange) {
			props.onChange({name: props.name, index: props.index, value: signatureRef.current.getCanvas().toDataURL().split(",")[1]});
		}
	}

	function limparCanvas() {
		if (signatureRef.current) {
			signatureRef.current.clear();
		}
		if (props.onChange) {
			props.onChange({name: props.name, index: props.index, value: null});
		}
	}

	return (
		<div style={{position: "relative"}} ref={containerRef} className={`col-${props.col}`}>
			<label className="label">{props.label}</label>
			{props.image ? <img style={{width: `${width}px`}} src={toSrc({id: props.image})} alt=""/> : <SignatureCanvas canvasProps={{width, height: props.height, className: "signature-canvas"}} {...props} onEnd={handleStroke} ref={signatureRef}/>}
			{(props.value || props.image) && !props.readOnly ? <Button style={{position: "absolute", bottom: "13px", right: "8px"}} icon="pi pi-times" danger onClick={limparCanvas}/> : null}
		</div>
	);

}
