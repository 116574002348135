import React from "react";
import {Checkbox as PCheckbox} from "primereact/checkbox";
import classNames from "classnames";
import "./index.scss";
import {calcularTamanho} from "../../service/guiHelper";

export function Checkbox(props) {

    function handleChange(event) {
        if (props.onChange && !props.disabled) {
            props.onChange({name: props.name, value: event.checked, index: props.index});
        }
    }

    const classes = calcularTamanho(props.col);

    const inputClasses = classNames({
        "p-invalid block": props.invalid
    });

    const newLocal = props.required ? <small style={{color: props.value?.length ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : null;

    return (
        <div className={classes}>
            <PCheckbox {...props} checked={props.value} className={inputClasses} onChange={handleChange}/>
            <label className="checkbox-label" onClick={() => handleChange({name: props.name, index: props.index, checked: !props.value})}>{props.label}</label>
            {props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : newLocal}
        </div>
    );

}
