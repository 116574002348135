import React from "react";
import { MultiSelect } from "../multiselect";

const tipoClienteOptions = [
	//{label: "Todos", value: "", color: '', icon: '',},
	{label: "Unico", value: "UNICO", color: 'rgb(96, 125, 139)', icon: 'fa-shop',},
	{label: "Matriz", value: "MATRIZ", color: 'rgb(244, 67, 54)', icon: 'fa-building',},
	{label: "Filial", value: "FILIAL", color: 'rgb(48, 63, 159)', icon: 'fa-store',}
];

export function TipoClienteSelect(props) {

	const tipoClienteOptionsTemplate = (option) => {
		return (
            <div className="flex align-items-center">
				<i style={{color: `${option.color}`}} className={`fa-lg	 fa-solid ${option.icon}`}/>
                <div className="ml-1">{option.label}</div>
            </div>
        );
	}
	

	return <MultiSelect 
		label="Tipo" 
		showSelectAll={false} 
		options={tipoClienteOptions} 
		itemTemplate={tipoClienteOptionsTemplate}
		optionLabel={'label'}
		placeholderSelecionar="tipo"
		display="chip"
		{...props}
	/>;
}
