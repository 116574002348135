import React, {useCallback, useState} from 'react';
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";

import {TipoClausulaAutoComplete} from '../../components/autocomplete/TipoClausulaAutoComplete';
import {Button} from '../../components/button';
import {DataTable} from '../../components/datatable';
import {InputTextarea} from '../../components/inputtextarea';
import {withDialog} from "../../utils/dialogContext";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import { isEntityRequired, isRequired } from '../../utils/fieldValidator';

export const EditarClausulasContratuais = withDialog(({contrato, setContrato, messages, setMessages, showDialog}) => {

    const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);

    const handleChange = useCallback((event1, event2) => {
        const  clausulas = contrato.clausulas.map((clausula, index) => {
            if (index === event1.item) {
                return {...clausula,  [event2.name]: event2.value};
            } else {
                return clausula;
            }
        });
        setContrato({...contrato, clausulas});
    }, [contrato, setContrato]);

    const  validarItem = (e, index, data) =>  {
        let erro;
        let clausulas = [];
        
		switch (e?.target.name) {
            case 'tipo':
                erro = isEntityRequired(data.tipo)
                clausulas = [...messages.clausulas]
                clausulas[index] = {...messages.clausulas[index], tipo: erro}
                break;
            case 'texto':
                erro = isRequired(data.texto)
                clausulas = [...messages.clausulas]
                clausulas[index] = {...messages.clausulas[index], texto: erro}
                break;
            default:
                break;
        }

        setMessages((messages) => {
            return {
                ...messages,
                clausulas
            }
        })
	}

    const textoTemplate = (event1, rowEvent) => {

        return <InputTextarea
            col={12}
            placeholder='as informações da clausula aqui.'
            value={contrato?.clausulas[event1.item]?.texto} 
            onChange={(event2) => handleChange(event1, event2)}
            name='texto'
            onBlur={(e) => validarItem(e, rowEvent.rowIndex, event1)}
            invalid={messages.clausulas?.[rowEvent.rowIndex]?.texto}
        />
    }

    const tipoTemplate = (event1, rowEvent) => {
        return <TipoClausulaAutoComplete 
            col={12} 
            value={contrato?.clausulas[event1.item]?.tipo} 
            name="tipo" 
            onChange={(event2) => handleChange(event1, event2)}
            reduced={!messages.clausulas?.[rowEvent.rowIndex]?.tipo}
            onBlur={(e) => validarItem(e, rowEvent.rowIndex, event1)}
            invalid={messages.clausulas?.[rowEvent.rowIndex]?.tipo}
        />
    }

    const adicionarClausula = useCallback(() => {
        const novaClausula = {
            id: null,
            texto: '',
            tipo: null,
            item: contrato.clausulas.length,
            _key: Math.random()
        }
        
        setContrato({...contrato, clausulas: [...contrato.clausulas, novaClausula]})
        
    }, [contrato, setContrato])

    const excluirClausula = useCallback(() => {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover as clausulas selecionadas?" onYes={() => {
	        const clausulas = contrato.clausulas.filter(osi => !linhasSelecionadas.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
            if(clausulas.length === 0){
                setMessages(messages => {
                    return {...messages, clausulas: []}
                })
            }
            setContrato({...contrato, clausulas})
	        setLinhasSelecionadas([]);
        }}/>);
        
    }, [contrato, linhasSelecionadas, setContrato, setMessages, showDialog])

    const onRowReorder = useCallback((event) => {
        const  clausulas = event.value.map((clausula, index) => {
            return {...clausula,  item: index}
        });
    
        setContrato({...contrato, clausulas})
    }, [contrato, setContrato])
    
	const servicoHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={5} header={
					<div>
						<Button icon="pi pi-plus" onClick={adicionarClausula}/>
                        <Button
                            title='Excluir clausula de contrato'
                            danger='true'
                            icon='fa-solid fa-x'
                            onClick={excluirClausula}
                            disabled={linhasSelecionadas.length === 0}
                        />
					</div>
				}/>
			</Row>
            <Row>
                    <Column rowReorder  />
                    <Column selectionMode="multiple" headerStyle={{width: '1em'}}/>
                    <Column header='Item' />
                    <Column header="Tipo de Clausula"/>
                    <Column header="Texto" />
			</Row>
		</ColumnGroup>
	);

	const servicoFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={5} footer={
					<div>
						<Button icon="pi pi-plus" onClick={adicionarClausula}/>
                        <Button
                            title='Excluir clausula de contrato'
                            danger='true'
                            icon='fa-solid fa-x'
                            onClick={excluirClausula}
                            disabled={linhasSelecionadas.length === 0}
                        />
					</div>
				}/>
			</Row>
		</ColumnGroup>
	);



    return (
        <>
            <div className='col-12 p-0'>
                <DataTable 
                    value={contrato.clausulas}
                    emptyMessage='Nenhuma clausula foi adicionada.'
                    valuelength={contrato.clausulas?.length}
                    rows={100}
                    paginator={false}
                    reorderableRows 
                    onRowReorder={onRowReorder}
                    headerColumnGroup={servicoHeaderGroup}
                    footerColumnGroup={servicoFooterGroup}
                    selectionMode="checkbox"
                    selection={linhasSelecionadas}
                    onSelectionChange={e => setLinhasSelecionadas(e.value)} 
                    size='small'
                >
                    <Column field='item' rowReorder style={{width: '3em'}} />
                    <Column selectionMode="multiple" headerStyle={{width: '1em'}}/>
                    <Column body={(e) => <div>{e.item + 1}</div>} header='Item' style={{width: '3em'}} />
                    <Column header="Tipo de Clausula" body={tipoTemplate} style={{ width: '25em'}} />
                    <Column header="Texto" body={textoTemplate} style={{ width: '*'}} />
                </DataTable>
            </div>
        </>
    );

});