import React, {useState} from 'react';
import {AutoComplete} from '.';
import {ansService} from "../../service/ansService";

export function AcordoNivelServicoAutoComplete(props) {

	const [ans, setAns] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("");

	function completeMethod(event) {
		const query = [];
		if (event.query.length) query.push(`query=${encodeURIComponent(event.query)}`);
		ansService.autocomplete(query).then(response => {
			setAns(response);
			if (!response[0]) {
				setEmptyMessage("Nenhum registro encontrado");
			} else {
				setEmptyMessage("");
			}
		});
	}

	return (
		<AutoComplete
			col={3}
			placeholder="descrição"
			label="SLA"
			name="acordoNivelServico"
			value={props.value}
			forceSelection
			completeMethod={completeMethod}
			suggestions={ans}
			invalid={emptyMessage}			
			{...props}
		/>
	);

}
