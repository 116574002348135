import {api} from "./api";
import React from "react";
import {baseService} from "./baseService";
import moment from "moment";
import { buildValidator, isBiggerThanZero, isEntityRequired, isRequired } from "../utils/fieldValidator";
import {vendedorService} from "./vendedorService";

export const faturamentoService = {

    async criar(criterio) {
        return await api.post("/faturamentos/criar", criterio).then(response => response.data);
    },

    async listarOrdensServico(params) {
        return await api.get(`/faturamentos/ordens-servico?${params.join("&")}`).then(response => response.data);
    },
    criarEvento: (eventos) => {
		return {
			id: null,
			forma: 'MANUAL',
			observacoes: '',
			registro: moment().format('YYYY-MM-DDTHH:mm:ss'),
			usuario: null,
			item: eventos?.length +1,
            _key: Math.random() * 1000,
			tipo: 'REGISTRO_ACOMPANHAMENTO',
		}
	},
	validar: (faturamento) => {
		const messages = buildValidator();
		console.log(faturamento)
		
		//RETORNO
		if (!messages.retorno && faturamento.itens.some(fat => fat.tipo === 'RETORNO')) {
			messages.retorno = {
				tipoMovimento: null,
				cfop: null,
				itens: [...Array(faturamento.itens.find(fat => fat.tipo === 'RETORNO').pedidoVenda.itens.length)].map((_, i) => ({
					codigoTributario: null,
				}))
			}
		}
			
		//SERVICO
		if (!messages.servico && faturamento.itens.some(fat => fat.tipo === 'SERVICO')) {
			messages.servico = {
				tipoMovimento: null,
				cfop: null,
				condicaoPagamento: null,
				formaPagamento: null,
				itens: [...Array(faturamento.itens.find(fat => fat.tipo === 'SERVICO').pedidoVenda.itens.length)].map((_, i) => ({
					codigoTributario: null,
				}))
			}
		}

		//PRODUTO
		if (!messages.produto && faturamento.itens.some(fat => fat.tipo === 'PRODUTO')) {
			messages.produto = {
				tipoMovimento: null,
				cfop: null,
				condicaoPagamento: null,
				formaPagamento: null,
				itens: [...Array(faturamento.itens.find(fat => fat.tipo === 'PRODUTO').pedidoVenda.itens.length)].map((_, i) => ({
					codigoTributario: null,
				}))
			}
		}

		faturamento.itens.forEach((item) => {
			switch (item.tipo) {
				case 'RETORNO':
					messages.retorno.tipoMovimento = isEntityRequired(item.pedidoVenda?.tipoMovimento);
					messages.retorno.cfop = isEntityRequired(item.pedidoVenda?.cfop);
					for (let i = 0; i < item.pedidoVenda.itens.length; ++i) {
						messages.retorno.itens[i].codigoTributario = isBiggerThanZero(item.pedidoVenda.itens[i].codigoTributario);
					}
					break;
				case 'SERVICO':
					messages.servico.tipoMovimento = isEntityRequired(item.pedidoVenda?.tipoMovimento);
					messages.servico.cfop = isEntityRequired(item.pedidoVenda?.cfop);
					messages.servico.condicaoPagamento = isEntityRequired(item.pedidoVenda?.condicaoPagamento);
					messages.servico.formaPagamento = isRequired(item.pedidoVenda?.formaPagamento);
					for (let i = 0; i < item.pedidoVenda.itens.length; ++i) {
						messages.servico.itens[i].codigoTributario = isBiggerThanZero(item.pedidoVenda.itens[i].codigoTributario);
					}
					break;
				case 'PRODUTO':
					messages.produto.tipoMovimento = isEntityRequired(item.pedidoVenda?.tipoMovimento);
					messages.produto.cfop = isEntityRequired(item.pedidoVenda?.cfop);
					messages.produto.condicaoPagamento = isEntityRequired(item.pedidoVenda?.condicaoPagamento);
					messages.produto.formaPagamento = isRequired(item.pedidoVenda?.formaPagamento);

					for (let i = 0; i < item.pedidoVenda.itens.length; ++i) {
						messages.produto.itens[i].codigoTributario = isBiggerThanZero(item.pedidoVenda.itens[i].codigoTributario);
					}
					break;
			
				default:
					break;
			}
		});

		return messages;
	},
	criarAnexo() {
		return {
			id: null,
			_key: Math.random(),
			descricao: "",
			arquivo: null
		};
	},
    ...baseService("/faturamentos"),
	async listarFaturamentos(params) {
		const query = [];
		if (params.numero) {
			switch (params.modoNumero) {
				case "FAT":
					query.push(`codigo=${params.numero}`);
					break;
				case "PedidoCompra":
					query.push(`pedidoCompra=${params.numero}`);
					break;
				case "OS":
					query.push(`ordemServico=${params.numero}`);
					break;
				case "NF":
					query.push(`notaFiscal=${params.numero}`);
					break;
				case "Contrato":
					query.push(`contrato=${params.numero}`);
					break;
				case "PedidoVenda":
					query.push(`pedidoVenda=${params.numero}`);
					break;
				default:
					break;
			}
		}
		if (params.cliente?.id) query.push(`cliente=${params.cliente.id}`);
		if (params.vendedor?.id) query.push(`vendedor=${params.vendedor.id}`);
		if (params.status) query.push(`status=${params.status}`);
		if (params.operacao && params.operacao !== "TODOS") query.push(`operacao=${params.operacao}`);
		return await faturamentoService.listar(query);
	}
}

export const optionsFilterStatus = [
	{label: <><i className="fas fa-folder-open"/> Abertas</>, value: "ABERTAS"},
	{label: <><i className="fas fa-ban"/> Canceladas</>, value: "CANCELADAS"},
	{label: <><i className="fas fa-asterisk"/> Todas</>, value: null}
];

export const formaFaturamentoStatus = {
    PRODUTO: <><i title="Faturar como produto" style={{color: "#2196F3"}} className="fas fa-big fa-boxes"/> Produto</>,
    SERVICO: <><i title="Faturar como serviço" style={{color: "#E91E63"}} className="fas fa-big fa-wrench"/> Serviço</>
};
