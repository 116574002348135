import React, {useMemo, useState} from 'react';
import { TabelaClausulasTipos } from './TabelaClausulasTipos';
import {PanelContent, PanelFooter} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {BlockUI} from "primereact/blockui";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import {useBreakpoint} from "../../context/BreakpointContext";
import {SearchButton} from "../../components/button/SearchButton";
import {useQueryClientTipoClausula} from "../../utils/query-clients/queryClients";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {If} from "../../components/conditional/If";

export function ClausulasTipos() {

	const {roles} = useAuth();
	const navigate = useNavigate();
	const {isLargeDevice} = useBreakpoint();
	const {query, handleSearch} = useQueryClientTipoClausula();
	const {params, handleChange} = useQueryParams(QueryParamsKeys.TipoClausula);

	function handleCriar() {
		navigate("/tipos-de-clausulas/new");
	}

	return (
		<BlockUI blocked={query.isLoading}>
			<PanelContent>
				<InputText col={12} label="Pesquise por" placeholder='um código ou descrição do tipo de cláusula' value={params.textoDeBusca} name="textoDeBusca" onChange={handleChange}/>
			</PanelContent>
			<PanelFooter>
				<If condition={roles.TDCA}>
					<Button success onClick={handleCriar} label={isLargeDevice ? "Novo" : null} icon="pi pi-plus" autoWidth={!isLargeDevice}/>
				</If>
				<SearchButton query={query} onSearch={handleSearch}/>
			</PanelFooter>
			{useMemo(() => <TabelaClausulasTipos value={query.data || []}/>, [query.data])}
		</BlockUI>
	);

}
