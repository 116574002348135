import React, {useCallback, useState} from "react";
import {PanelFooter} from "../../components/panel";
import {Panel} from "primereact/panel";
import {Button} from "../../components/button";
import {useBreakpoint} from "../../context/BreakpointContext";
import {TabPanel, TabView} from "primereact/tabview";
import {isBiggerThanZero, isEmpty, isEntityRequired, isRequired, isSeloIpemValido} from "../../utils/fieldValidator";
import {withDialog} from "../../utils/dialogContext";
import {EditarOrdemServicoItemDadosPrincipais} from "./EditarOrdemServicoItemDadosPrincipais";
import {EditarOrdemServicoItemPeca} from "./EditarOrdemServicoItemPeca";
import {EditarOrdemServicoItemServico} from "./EditarOrdemServicoItemServico";
import {EditarOrdemServicoItemFoto} from "./EditarOrdemServicoItemFoto";
import {EditarOrdemServicoItemComprovante} from "./EditarOrdemServicoItemComprovante";
import {useAuth} from "../../context/AuthContext";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";
import {BlockUI} from "primereact/blockui";
import {InformationDialog} from "../../components/dialog/InformationDialog";

export const EditarOrdemServicoItem = withDialog(({item, setItem, ordemServico, onSalvar, messages, setMessages, index, readOnly, setOrdemServico, setLaudo, showDialog}) => {

	const {breakpoint} = useBreakpoint();
	const [blocked, setBlocked] = useState(false);
	const isLargeDevice = breakpoint === "lg" || breakpoint === "md";

	async function handleSalvar() {
		const em = ordemServicoItemService.validar(item, ordemServico);
		if (!em.isEmpty()) {
			messages.itens[index] = em;
			setMessages(messages);
			showDialog(<InformationDialog message="Um ou mais campos obrigatórios não estão preenchidos corretamente."/>);
			return;
		}
		setBlocked(true);
		item.ordemServico = ordemServico;
		item = await ordemServicoItemService.salvarDependencias(item);
		setBlocked(false);
		return onSalvar && onSalvar(item);
	}

	const handleChange = useCallback(event => setItem({...item, [event.name]: event.value}), [item, setItem]);

	const pedidoVenda = () => {
		
		let header;
		if (ordemServico.pedidoVendaFaturamentoNumero) {
			header = "Pedido Venda Faturamento Nº " +  ordemServico.pedidoVendaFaturamentoNumero + " | ";
			header += "Pedido Venda Retorno Nº " +  ordemServico.pedidoVendaNumero + " | ";
		} else if (ordemServico.pedidoVendaNumero && !ordemServico.pedidoVendaFaturamentoNumero) {
			header = "Pedido Venda Nº " +  ordemServico.pedidoVendaNumero + " | ";
		}
		return header;
	}
	
	return (
		<BlockUI blocked={blocked}>
			<Panel header={`Detalhes do Item ${item.sequencia} da ${ordemServico.numero ? "" : "Nova"} Ordem de Serviço ${ordemServico.numero || ""}`}
					icons={<><b> {pedidoVenda}  Nº OS Omega {item.numero}</b></>}>
				<EditarOrdemServicoItemFields ordemServico={ordemServico}
				                              readOnly={readOnly}
				                              index={index}
				                              messages={messages}
				                              setMessages={setMessages}
				                              handleChange={handleChange}
				                              item={item}
				                              setItem={setItem}
				                              handleSalvar={handleSalvar}
				                              setOrdemServico={setOrdemServico}
				                              setLaudo={setLaudo}
				/>
				<PanelFooter>
					<Button icon="pi pi-save" success loading={blocked} label={isLargeDevice ? "Salvar" : null} onClick={handleSalvar} autowidth={!isLargeDevice.toString()}/>
					<Button icon="pi pi-arrow-left" secondary label={isLargeDevice ? "Voltar" : null} onClick={() => setItem(null)} autowidth={!isLargeDevice.toString()}/>
				</PanelFooter>
			</Panel>
		</BlockUI>
	);

});

export const EditarOrdemServicoItemFields = withDialog(({ordemServico, handleChange, item, setItem, messages, setMessages, index, readOnly, handleSalvar, setOrdemServico, setLaudo, hideOrcamentos}) => {

	const {roles} = useAuth();

	function validarItem() {
		if (!messages.itens[index]) {
			const itens = [];
			for (let i = 0; i <= index; ++i) {
				itens.push(messages.itens[i] || {});
			}
			messages.itens = itens;
		}
		messages.itens[index].seloIpem = item.seloIpem?.length ? isSeloIpemValido(item.seloIpem) : null;
		messages.itens[index].pecas = [...Array(item.pecas.length)].map((_, i) => ({
			produto: isEntityRequired(item.pecas[i].produto),
			quantidade: isBiggerThanZero(item.pecas[i].quantidade, "Campo obrigatório"),
		}));
		messages.itens[index].servicos = [...Array(item.servicos.length)].map((_, i) => ({
			servico: isEntityRequired(item.servicos[i].servico),
			quantidade: isBiggerThanZero(item.servicos[i].quantidade, "Campo obrigatório")
		}));
		messages.itens[index].movimentacoes = [...Array(item.movimentacoes.length)].map((_, i) => ({
			responsavel: isEntityRequired(item.movimentacoes[i].responsavel),
			prateleira: item.movimentacoes[i].movimento === "ENTRADA" ? isRequired(item.movimentacoes[i].prateleira) : null
		}));
		messages = {...messages};
		messages.isEmpty = isEmpty(messages);
		setMessages(messages);
	}

	function headerTemplate(e) {
		let style = {};
		switch (e.index) {
			case 0:
				if (messages.itens?.[index]?.equipamento) style.color = "#F44336";
				break;
			case 1:
				if (messages.itens?.[index]?.pecas?.some(p => p.produto || p.quantidade || p.status)) style.color = "#F44336";
				break;
			case 2:
				if (messages.itens?.[index]?.servicos?.some(p => p.servico || p.quantidade)) style.color = "#F44336";
				break;
			case 4:
				break;
			case 5:
				if (messages.itens?.[index]?.movimentacoes?.some(p => p.responsavel || p.movimento || p.prateleira)) style.color = "#F44336";
				break;
			default:
				break;
		}
		return (
			<div className={`${e?.className}`} aria-controls={`${e.ariaControls}`} onClick={e.onClick} onKeyDown={e.onKeyDown} style={style}>
				<i className={`${e?.leftIconElement?.props?.className}`}/>
				<div className={`${e?.titleClassName}`} >{e?.titleElement?.props?.children}</div>
			</div>
		);
	}

	return (
		<TabView>
			<TabPanel headerTemplate={headerTemplate} header="Equipamento & Laudo" leftIcon="fa-solid fa-database">
				<EditarOrdemServicoItemDadosPrincipais ordemServico={ordemServico} item={item} index={index} handleChange={handleChange} messages={messages} setMessages={setMessages}/>
			</TabPanel>
			<TabPanel headerTemplate={headerTemplate} header="Peças Substituídas" leftIcon="fa-solid fa-cube">
				<EditarOrdemServicoItemPeca ordemServico={ordemServico} index={index} item={item} handleChange={handleChange} messages={messages} validarItem={validarItem}/>
			</TabPanel>
			<TabPanel headerTemplate={headerTemplate} header="Serviços Executados" leftIcon="fa-solid fa-wrench">
				<EditarOrdemServicoItemServico validarItem={validarItem} index={index} messages={messages} ordemServico={ordemServico} item={item} handleChange={handleChange}/>
			</TabPanel>
			<TabPanel headerTemplate={headerTemplate} header="Fotos e Imagens" leftIcon="fa-solid fa-camera">
				<EditarOrdemServicoItemFoto ordemServico={ordemServico} readOnly={readOnly} item={item} handleChange={handleChange}/>
			</TabPanel>
			<TabPanel headerTemplate={headerTemplate} header="Arquivos e Comprovantes" leftIcon="fa-solid fa-file">
				<EditarOrdemServicoItemComprovante item={item} handleChange={handleChange}/>
			</TabPanel>
		</TabView>
	);

});
