import React, {useEffect, useMemo, useState} from 'react';
import {TabelaOrdemServico} from "./TabelaOrdemServico";
import {useAuth} from "../../context/AuthContext";
import {useBreakpoint} from "../../context/BreakpointContext";
import {Panel} from "primereact/panel";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import {BlockUI} from "primereact/blockui";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {useNavigate} from "react-router-dom";
import {If} from "../../components/conditional/If";
import {FiltrosOrdemServicoCampo} from "../__Commons/FiltrosOrdemServicoCampo";
import {useQueryClientCampo} from "../../utils/query-clients/queryClients";
import {SearchButton} from "../../components/button/SearchButton";
import {usePagesAndTabs} from "../../utils/PageAndTabContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";

export function CampoOrdensServico() {

    const {roles} = useAuth();
    const navigate = useNavigate();
    const {query, handleSearch} = useQueryClientCampo();
    const [hideFilters, setHideFilters] = useState(false);
    const {isLargeDevice} = useBreakpoint();
    const {pageAndTab, setScroll} = usePagesAndTabs(QueryParamsKeys.Campo);

    useEffect(() => {
        if (pageAndTab.scroll) {
            setTimeout(() => {
                window.scrollTo(0, pageAndTab.scroll);
                setScroll(0);
            }, 50);
        }
    }, []);

    function handleCriar() {
        navigate("/campo/new");
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <If condition={!hideFilters}>
                <Panel headerTemplate={() => headerTemplate("Campo", "fa-scroll")}>
                    <PanelContent>
                        <FiltrosOrdemServicoCampo/>
                    </PanelContent>
                    <PanelFooter>
                        {roles.OSSA && <Button success onClick={handleCriar} label={isLargeDevice ? "Novo" : null} icon="pi pi-plus" autowidth={!isLargeDevice}/>}
                        <SearchButton query={query} onSearch={handleSearch}/>
                    </PanelFooter>
                </Panel>
            </If>
            <TabelaOrdemServico setHideFilters={setHideFilters} value={useMemo(() => query.data || [], [query.data])} handleProcurar={handleSearch}/>
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
