import { api } from "../../../service/api";
import { baseService } from "../../../service/baseService";
import { buildValidator, isBiggerThanZeroValor, isEntityRequired, isRequired } from "../../../utils/fieldValidator";
import {toast} from "react-toastify";

const resourceUrl = "/listas-preco";

export const listaPrecoService = {
	criar: () => {
		return {
			id: null,
			itens:[]
		};
	},

	validar: listaPreco => {
		
		let messages = buildValidator();

		messages.descricao = isRequired(listaPreco.descricao)

		if (!messages.itens) {
			messages.itens = [...Array(listaPreco.itens.length)].map((_, i) => ({
				produto: null,
				preco: null,
			}));
		}

		for (let i = 0; i < listaPreco.itens.length; ++i) {
			messages.itens[i].preco = isBiggerThanZeroValor(listaPreco.itens[i].preco)
			messages.itens[i].produto = isEntityRequired(listaPreco.itens[i].produto)
		}
		
		return messages;
	},
	async desvincularCliente(cliente) {        
        return await api.post(resourceUrl, cliente.id).then(response => response.data);
    },

	validarDuplicidade: listaPreco => {
		// Verificar se há produtos duplicados na lista
		const produtos = new Set();
		const produtosDuplicados = new Set();
		
		listaPreco.itens.forEach((item) => {
			if (produtos.has(item.produto?.codigo)) {
				produtosDuplicados.add(item.produto.codigo);
		  	} else {
				produtos.add(item.produto?.codigo);
		  	}
		});
	
		return produtosDuplicados
	},
	async downloadCSV(id) {		
		return await api.get(`${resourceUrl}/download-csv/${id}`, {}, {responseType: "blob"}).then(response => response.data);
	},
	async downloadModeloCSV() {		
		return await api.get(`${resourceUrl}/download-modelo-csv`, {}, {responseType: "blob"}).then(response => response.data);
	},
	async uploadCSV(criterio) {		
		return await api.post(`${resourceUrl}/upload-csv`, criterio).then(response => response.data);
	},
	...baseService(resourceUrl),
    async listarListasPreco(params) {
		const query = [];
		if (params.cliente?.id) {
			query.push(`cliente=${params.cliente.id}`);
		} else if (params.cliente?.length) {
			query.push(`clienteStr=${params.cliente}`);
		}
		if (params.numero) query.push(`numero=${params.numero}`);
		if (params.status[0]) query.push(`status=${params.status}`);
		if (params.descricao?.length) query.push(`descricao=${params.descricao}`);
		return await listaPrecoService.listar(query);
    }
};

export const status = [
	{label: "Rascunho", value: "RASCUNHO" , color: '#FF9800', icon: 'fa-shopping-cart'},
	{label: "Erro", value: "ERRO" , color: '#F44336', icon: 'fa-times'},
	{label: "Aguardando", value: "AGUARDANDO", color: '#2196F3', icon: 'fa-clock'},
	{label: "Confirmado", value: "CONFIRMADO", color: 'rgb(96, 125, 139)', icon: 'fa-lock'},
	{label: "Bloqueado", value: "BLOQUEADO", color: '#F44336', icon: 'fa-lock'},
	{label: "Carteira", value: "CARTEIRA", color: '#607D8B', icon: 'fa-wallet'},
	{label: "Faturado", value: "FATURADO", color: '#4CAF50', icon: 'fa-check'}
];
