import React, {useState} from "react";
import {DataTable} from "../../components/datatable";
import {isStacked} from "../../context/BreakpointContext";
import {Column} from "primereact/column";
import moment from "moment";
import {InputText} from "../../components/inputtext";
import {SimpleFileUpload} from "../../components/fileupload/SimpleFileUpload";
import {Button} from "../../components/button";
import {PanelContent} from "../../components/panel";
import {ordemServicoService} from "../../service/ordemServicoService";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {arquivoService} from "../../service/arquivoService";
import saveAs from "file-saver";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {withDialog} from "../../utils/dialogContext";

export const EditarOrdemServicoItemComprovante = withDialog(({item, handleChange, showDialog}) => {

	const [blocked, setBlocked] = useState(0);
	const [comprovantesSelecionados, setComprovantesSelecionados] = useState([]);

	function adicionarComprovante() {
		item.comprovantes.push(ordemServicoService.criarComprovante());
		handleChange({name: "comprovantes", value: item.comprovantes});
	}

	function removerComprovantes() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja excluir os comprovantes selecionados?" onYes={() => {
			item.comprovantes = item.comprovantes.filter(osi => !comprovantesSelecionados.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
			handleChange({name: "comprovantes", value: item.comprovantes});
			setComprovantesSelecionados([]);
		}}/>);
	}

	const comprovanteControlSet = (
		<div>
			<Button icon="pi pi-plus" onClick={adicionarComprovante}/>
			<Button disabled={!comprovantesSelecionados?.length} onClick={removerComprovantes} danger icon="pi pi-minus"/>
		</div>
	);

	const comprovanteHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={5} header={comprovanteControlSet}/>
			</Row>
			<Row>
				<Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
				<Column style={{width: "13em"}} header="Registro"/>
				<Column header="Descrição"/>
				<Column header="Arquivo"/>
				<Column header="Download" style={{width: "5em", textAlign: "center"}}/>
			</Row>
		</ColumnGroup>
	);

	const comprovanteFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={5} footer={comprovanteControlSet}/>
			</Row>
		</ColumnGroup>
	);

	function handleChangeComprovante(event) {
		item.comprovantes[event.index][event.name] = event.value;
		handleChange({name: "comprovantes", value: item.comprovantes});
	}

	function baixarComprovante(index) {
		setBlocked(index + 1);
		arquivoService.baixar(item.comprovantes[index].arquivo).then(data => {
			setBlocked(0);
			saveAs(new Blob([data], {type: item.comprovantes[index].arquivo.contentType}), item.comprovantes[index].arquivo.fileName);
		});
	}

	return (
		<PanelContent>
			<div className="col-12">
				<DataTable value={item.comprovantes}
				           headerColumnGroup={comprovanteHeaderGroup}
				           footerColumnGroup={comprovanteFooterGroup}
				           selection={comprovantesSelecionados}
				           onSelectionChange={e => setComprovantesSelecionados(e.value)}
				           header={isStacked() ? null : comprovanteControlSet}
				           footer={isStacked() ? null : comprovanteControlSet}
				>
					<Column selectionMode="multiple"/>
					<Column field="registro" body={osic => <div style={{textAlign: "center"}}>{moment(osic.registro).format("DD/MM/YYYY HH:mm")}</div>}/>
					<Column field="descricao" body={(osic, c) => <InputText disabled={osic.id} reduced index={c.rowIndex} name="descricao" value={osic.descricao} onChange={handleChangeComprovante}/>}/>
					<Column body={(osic, c) => <SimpleFileUpload disabled={osic.id} index={c.rowIndex} name="arquivo" value={osic.arquivo} onChange={handleChangeComprovante}/>}/>
					<Column style={{textAlign: "center"}} body={(osic, c) => <Button loading={blocked === c.rowIndex + 1} icon="fa-solid fa-download" onClick={() => baixarComprovante(c.rowIndex)}/>}/>
				</DataTable>
			</div>
		</PanelContent>
	);

});
