import React, {useContext, useEffect, useState} from "react";
import {InputCurrency, InputNumber} from "../../components/inputnumber";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {Calendar} from "../../components/calendar";
import {PanelContent} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {DialogContext} from "../../utils/dialogContext";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import moment from "moment";
import {formatCurrency} from "../../utils/numberFormatter";
import {contratoService} from "../../service/contratoService";
import {BlockUI} from "primereact/blockui";

export function EditarCoberturaFranquia({contrato, setContrato}) {

    const {showDialog} = useContext(DialogContext);
    const [blocked, setBlocked] = useState(false);
    const [recalculo, setRecalculo] = useState(0);
    const [periodosExpandidos, setPeriodosExpandidos] = useState([]);

    function handleChange(event) {
        setContrato(prevContrato => ({...prevContrato, [event.name]: event.value}));
    }

    useEffect(() => {
        if (contrato.periodosConsumo?.length) {
            contratoService.listarConsumoPeriodos(contrato.id).then(consumos => {
                const {periodosConsumo} = contrato;
                for (const periodoConsumo of periodosConsumo) {
                    periodoConsumo.consumo = consumos.filter(cc => cc.id === periodoConsumo.id)[0] || {
                        contagem: 0,
                        consumo: 0
                    };
                }
                setContrato(prevContrato => ({...prevContrato, periodosConsumo}));
            });
        }
    }, [contrato.id, recalculo]);

    function handleChangePeriodo(event) {
        const {periodosConsumo} = contrato;
        periodosConsumo[event.index][event.name] = event.value;
        setContrato(prevContrato => ({...prevContrato, periodosConsumo}));
    }

    function gerarPeriodos() {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja gerar os períodos da franquia? Depois de gerados, os períodos não podem ser alterados até a próxima renovação do contrato." onYes={() => {
            const periodosConsumo = [];
            let refInicio = moment(contrato.inicio).set("date", contrato.referenciaPeriodoConsumo);
            while (refInicio.isBefore(contrato.fim)) {
                periodosConsumo.push({
                    inicio: refInicio.format("YYYY-MM-DD"),
                    fim: refInicio.add(1, "months").add(-1, "days").format("YYYY-MM-DD"),
                    valorFranquia: contrato.valorFranquia
                });
                refInicio.add(1, "days");
            }
            setContrato(prevContrato => ({...prevContrato, periodosConsumo}));
        }}/>);
    }

    async function expandirPeriodos(event) {
        event.data.ordensServico = await contratoService.buscarOrdensPeriodo(event.data.id);
        setPeriodosExpandidos([event.data]);
    }

    function templatePeriodo(periodo) {
        const totalConsumido = periodo.ordensServico?.map(os => os.valorFranquia).reduce((a, b) => a + b, 0);
        return (
            <DataTable value={periodo.ordensServico}>
                <Column style={{width: "10em"}} header="Nº" field="numero"/>
                <Column style={{width: "12em"}} header="Status" field="status"/>
                <Column style={{width: "12em"}} header="Início" field="inicio" body={os => moment(os.inicio).format("DD/MM/YYYY HH:mm:ss")}/>
                <Column style={{width: "*"}} header="Etapa" field="etapa.descricao"/>
                <Column style={{width: "12em"}} header={
                    <div>
                        Total Consumido<br/>
                        (Total Restante)
                    </div>
                } field="valorFranquia" footer={
                    <div>
                        {formatCurrency(totalConsumido)}<br/>
                        ({formatCurrency(periodo.valorFranquia - totalConsumido)})
                    </div>
                } body={os => formatCurrency(os.valorFranquia)}/>
            </DataTable>
        );
    }

    async function estenderPeriodos() {
        const {periodosConsumo} = contrato;
        const lastCoveredDate = moment(periodosConsumo.map(pc => pc.fim).sort((a, b) => b.localeCompare(a))[0]);
        lastCoveredDate.add(1, "days");
        while (lastCoveredDate.isBefore(contrato.fim)) {
            periodosConsumo.push({
                inicio: lastCoveredDate.format("YYYY-MM-DD"),
                fim: lastCoveredDate.add(1, "months").add(-1, "days").format("YYYY-MM-DD"),
                valorFranquia: contrato.valorFranquia
            });
            lastCoveredDate.add(1, "days");
        }
        setContrato(prevContrato => ({...prevContrato, periodosConsumo}));
    }

    async function recalcularConsumos() {
        setBlocked(true);
        await contratoService.recalcularConsumos(contrato);
        setRecalculo(prevRecalculo => ++prevRecalculo);
        setBlocked(false);
    }

    return (
        <BlockUI blocked={blocked}>
            <PanelContent>
                <InputCurrency col={3} label="Cobertura Mensal" value={contrato.valorFranquia} name="valorFranquia" onChange={handleChange}/>
                <InputNumber readOnly={contrato.periodosConsumo?.length || !contrato.inicio || !contrato.fim} col={2} label="Dia de Referência" max={31} min={1} value={contrato.referenciaPeriodoConsumo} name="referenciaPeriodoConsumo" onChange={handleChange}/>
                {contrato.periodosConsumo?.length || !contrato.inicio || !contrato.fim || !contrato.referenciaPeriodoConsumo ? null : <Button label="Gerar Períodos" onClick={gerarPeriodos}/>}
                <div className="col-12">
                    <DataTable expandedRows={periodosExpandidos}
                               onRowExpand={expandirPeriodos}
                               emptyMessage="Períodos de consumo não registrados"
                               value={contrato.periodosConsumo}
                               rowExpansionTemplate={templatePeriodo}
                               paginatorLeft={
                                   <div style={{display: "flex"}}>
                                       <Button icon="fas fa-refresh" label="Estender Períodos" onClick={estenderPeriodos}/>
                                       <Button icon="fas fa-refresh" label="Recalcular Consumos" onClick={recalcularConsumos}/>
                                   </div>
                               }
                    >
                        <Column style={{width: "3em"}} expander/>
                        <Column header="Ref." field="" body={(cpc, c) => c.rowIndex + 1}/>
                        <Column style={{width: "3em", textAlign: "center"}} header="Status" field="" body={cpc => printStatus(contrato, cpc)}/>
                        <Column header="Início" field="inicio" body={(cpc, c) => <Calendar onChange={handleChangePeriodo} readOnly index={c.rowIndex} col={12} name="inicio" value={cpc.inicio}/>}/>
                        <Column header="Fim" field="fim" body={(cpc, c) => <Calendar onChange={handleChangePeriodo} readOnly index={c.rowIndex} col={12} name="fim" value={cpc.fim}/>}/>
                        <Column header="OS" field="os" body={(cpc) => cpc.consumo?.contagem}/>
                        <Column header={
                            <span style={{whiteSpace: "nowrap"}}>
                            Total Consumido<br/>
                            (Total Restante)
                        </span>
                        } field="consumo" body={(cpc) => (
                            <>
                                {formatCurrency(cpc.consumo?.consumo)}<br/>
                                ({formatCurrency(cpc.valorFranquia - cpc.consumo?.consumo)})
                            </>
                        )}/>
                        <Column header={
                            <>Cobertura no Período</>
                        } field="valorFranquia" body={(cpc, c) => <InputCurrency index={c.rowIndex} value={cpc.valorFranquia} name="valorFranquia" col={12} onChange={handleChangePeriodo}/>}/>
                        <Column header="Nº do Pedido de Compra" field="" body={(cpc, c) => <InputText onChange={handleChangePeriodo} index={c.rowIndex} col={12} name="pedidoCompra" value={cpc.pedidoCompra}/>}/>
                    </DataTable>
                </div>
            </PanelContent>
        </BlockUI>
    );

}

function printStatus(contrato, cpc) {
    const now = moment();
    if (moment(cpc.fim).isBefore(now)) {
        return <i title="Período encerrado" style={{color: "#F44336"}} className="fas fa-minus-circle"/>;
    }
    if (contrato.valorFranquia * .8 < cpc.consumo?.consumo) {
        return <i title="Próximo do limite (mais de 80% utilizado)" style={{color: "#FFA000"}} className="fas fa-exclamation-triangle"/>;
    }
    if (contrato.valorFranquia * .5 < cpc.consumo?.consumo) {
        return <i title="Período ativo e utilizado" style={{color: "#4CAF50"}} className="fas fa-cubes"/>;
    }
    if (moment(cpc.inicio).isBefore(now) && moment(cpc.fim).isAfter(now)) {
        return <i title="Período ativo com pouca utilização" style={{color: "#4CAF50"}} className="fas fa-check"/>;
    }
    return <i title="Período futuro" style={{color: "#607D8B"}} className="fas fa-hourglass"/>;
}
