import React from "react";
import {Button as PButton} from "primereact/button";
import classNames from "classnames";
import "./Button.scss";

export function Button(props) {

    const classes = classNames({          
        "p-button-success": props.success,      
        "p-button-danger": props.danger,
        "p-button-warning": props.warning,
        "p-button-info": props.info,
        "p-button-secondary": props.secondary,
        "p-button-rounded": props.justIcon,
        "p-button-lg": props.large,
        "auto-width": props.autowidth,
        "p-button-outlined": props.outlined,
        "p-button-marginright": props.marginRight,
        "p-button-white": props.clear
    });

    return <PButton {...props} className={`${props.className} ${classes}`}/>;

}
