import React, {useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../../../components/panel";
import {Button} from "../../../../components/button";
import {relatorioKpiService} from "../../../../service/relatorioKpiService";
import {BlockUI} from "primereact/blockui";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {formatMinutes} from "../../../../utils/numberFormatter";
import {Calendar} from "../../../../components/calendar";
import {ClienteAutoComplete} from "../../../../components/autocomplete/ClienteAutoComplete";
import moment from "moment";
import {labelRestricao} from "../../../../service/ordemServicoService";
import {OrdemServicoRestricaoDropdown} from "../../../../components/dropdown/ordemServicoRestricaoDropdown";
import {TabPanel, TabView} from "primereact/tabview";
import {operadoresExpander, processarRelatorioKpi, restricoesExpander} from "./kpiAuxFunctions";

export function RelatorioKPILaboratorio() {

    const [kpis, setKpis] = useState([]);
    const [rpis, setRpis] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [params, setParams] = useState({
        inicio: moment().add(-1, "month").format("YYYY-MM-DD"),
        fim: moment().format("YYYY-MM-DD")
    });

    function handleProcurar() {
        const query = [];
        if (params.inicio?.length) query.push(`inicio=${params.inicio}`);
        if (params.fim?.length) query.push(`fim=${params.fim}`);
        if (params.cliente?.id) {
            query.push(`cliente=${params.cliente.id}`);
        } else if (params.cliente?.length) {
            query.push(`cliente=${params.cliente}`);
        }
        if (params.restricao?.length) query.push(`restricao=${params.restricao}`);
        setLoading(true);
        relatorioKpiService.listarRelatorioLaboratorio(query).then(items => {
            const {kpis, rpis} = processarRelatorioKpi(items);
            setKpis(kpis);
            setRpis(rpis);
            setLoading(false);
        });
    }

    function handleChange(event) {
        setParams(prevParams => ({...prevParams, [event.name]: event.value}));
    }

    return (
        <BlockUI blocked={loading}>
            <Panel header="Relatório de Produtividade (KPI)">
                <PanelContent>
                    <Calendar col={2} name="inicio" onChange={handleChange} value={params.inicio} label="Período"/>
                    <Calendar col={2} name="fim" onChange={handleChange} value={params.fim} label="&nbsp;"/>
                    <ClienteAutoComplete forceSelection={false} col={5} name="cliente" onChange={handleChange} value={params.cliente}/>
                    <OrdemServicoRestricaoDropdown label="Restrição" col={3} name="restricao" onChange={handleChange} value={params.restricao}/>
                </PanelContent>
                <PanelFooter>
                    <Button label="Procurar" loading={loading} icon="fas fa-search" onClick={handleProcurar}/>
                </PanelFooter>
            </Panel>
            <div style={{height: "1em"}}/>
            <TabView>
                <TabPanel header={<span><i className="fas fa-screwdriver-wrench"/> Por Atividade</span>}>
                    <DataTable
                        stripedRows 
                        expandedRows={expandedRows}
                        onRowExpand={e => setExpandedRows(prevExpandedRows => [...prevExpandedRows, e.data])}
                        onRowCollapse={e => setExpandedRows(prevExpandedRows => prevExpandedRows.filter(er => er !== e.data))}
                        rowExpansionTemplate={operadoresExpander}
                        emptyMessage="Nenhum registro encontrado"
                        paginator
                        rows={100}
                        value={rpis}
                    >
                        <Column style={{width: "3em"}} expander/>
                        <Column sortable header="Restrição" field="restricao" body={rpi => labelRestricao[rpi.restricao]}/>
                        <Column sortable style={{width: "12em", textAlign: "right"}} header="Atendimentos" field="totalActivities"/>
                        <Column sortable style={{width: "12em", textAlign: "right"}} header={<span title="Somatória de Tempo em Atendimento">ΣΔt</span>} field="totalMinutes" body={kpi => formatMinutes(kpi.totalMinutes)}/>
                        <Column sortable style={{width: "12em", textAlign: "right"}} header={<span title="Tempo Médio de Atendimento" style={{textDecoration: "overline"}}>Δt</span>} field="averageMinutes" body={kpi => formatMinutes(kpi.averageMinutes)}/>
                    </DataTable>
                </TabPanel>
                <TabPanel header={<span><i className="fas fa-user"/> Por Operador</span>}>
                    <DataTable
                        stripedRows 
                        expandedRows={expandedRows}
                        onRowExpand={e => setExpandedRows(prevExpandedRows => [...prevExpandedRows, e.data])}
                        onRowCollapse={e => setExpandedRows(prevExpandedRows => prevExpandedRows.filter(er => er !== e.data))}
                        rowExpansionTemplate={restricoesExpander}
                        emptyMessage="Nenhum registro encontrado"
                        paginator
                        rows={100}
                        value={kpis}
                    >
                        <Column style={{width: "3em"}} expander/>
                        <Column sortable header="Operador" field="usuario.nome"/>
                        <Column sortable style={{width: "12em", textAlign: "right"}} header="Atendimentos" field="totalActivities"/>
                        <Column sortable style={{width: "12em", textAlign: "right"}} header={<span title="Somatória de Tempo em Atendimento">ΣΔt</span>} field="totalMinutes" body={kpi => formatMinutes(kpi.totalMinutes)}/>
                        <Column sortable style={{width: "12em", textAlign: "right"}} header={<span title="Tempo Médio de Atendimento" style={{textDecoration: "overline"}}>Δt</span>} field="averageMinutes" body={kpi => formatMinutes(kpi.averageMinutes)}/>
                    </DataTable>
                </TabPanel>
            </TabView>
        </BlockUI>
    );

}
