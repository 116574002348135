import React, {useContext, useEffect, useState} from "react";
import {Column} from "primereact/column";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";
import {ServicoAutoComplete} from "../../components/autocomplete/servicoAutoComplete";
import {DataTable} from "../../components/datatable";
import {useAuth} from "../../context/AuthContext";
import {Button} from "../../components/button";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {DialogContext} from "../../utils/dialogContext";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {servicoService} from "../../service/servicoService";
import {BlockUI} from "primereact/blockui";
import {InputText} from "../../components/inputtext";
import {Calendar} from "../../components/calendar";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {If} from "../../components/conditional/If";
import {SelectButton} from "../../components/selectbutton";
import {
	codigosTerceirizacao,
	isRessolda,
	optionsStatusRessolda,
	yesNoOptions
} from "../../service/ordemServicoService";
import { Tooltip } from "primereact/tooltip";

export function EditarServicos({ordemServico, setOrdemServico, messages}) {

	const {roles, usuario} = useAuth();
	const {showDialog} = useContext(DialogContext);
	const [blocked, setBlocked] = useState(false);
	const [sequencia, setSequencia] = useState(0);

	useEffect(() => {
		if (ordemServico.itens[0].servicos?.length) {
			setSequencia(Math.max(...ordemServico.itens[0].servicos.map(osip => osip.sequencia)) || 0);
		}
	}, [ordemServico.itens[0].id]);

	function bloquearEdicao(osis) {
		return (
			ordemServico.operacao === "LABORATORIO"
			&& (
				(
					!roles.OSSU
					&& ordemServico.itens[0].orcamentos?.some(osio => osio.status === "APROVADO")
				)
				|| ordemServico.faturamentoConfirmado
				|| (
					ordemServico.status !== "LAUDO"
					&& osis.id
					&& isRessolda(osis.servico?.codigo)
				)
				|| (
					isRessolda(osis.servico?.codigo)
					&& osis.placaCondenada
				)
			)
		);
	}

	const [servicosSelecionados, setServicosSelecionados] = useState([]);

	const servicoControlSet = (
		<div>
			<Button disabled={!roles.OSSU && ordemServico.itens[0].orcamentos?.some(osio => osio.status === "APROVADO")} icon="pi pi-plus" onClick={adicionarServico}/>
			<Button disabled={(!roles.OSSU && ordemServico.itens[0].orcamentos?.some(osio => osio.status === "APROVADO")) || !servicosSelecionados?.length} onClick={removerServicos} danger icon="pi pi-minus"/>
		</div>
	);

	function adicionarServico() {
		setOrdemServico(prevOrdemServico => {
			const servico = ordemServicoItemService.criarServico();
			servico.solicitante = usuario;
			servico.sequencia = sequencia + 1;
			prevOrdemServico.itens[0].servicos.push(servico);
			return {...prevOrdemServico, _alterado: true};
		});
		setSequencia(sequencia + 1);
	}

	function removerServicosSelecionadas() {
		if (servicosSelecionados.some(pc => ["MOVIMENTADO", "CANCELADO"].includes(pc.status))) {
			showDialog(<InformationDialog header="Informação" message="Não é possível remover itens com movimentação de estoque"/>);
			return;
		}
		setOrdemServico(prevOrdemServico => {
			prevOrdemServico.itens[0].servicos = prevOrdemServico.itens[0].servicos.filter(osi => !servicosSelecionados.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
			if (!prevOrdemServico.itens[0].id) {
				prevOrdemServico.itens[0].servicos.forEach((osi, i) => osi.sequencia = i + 1);
			}
			return {...prevOrdemServico, _alterado: true};
		});
		setServicosSelecionados([]);
	}

	function removerServicos() {
		if (!roles.LABG && servicosSelecionados.some(ps => isRessolda(ps.servico?.codigo) && ps.status !== "PENDENTE")) {
			showDialog(<InformationDialog header="Aviso" message="Não é possível remover serviços de recuperação de placa concluídos."/>);
			return;
		}
		if (servicosSelecionados.some(ps => ps.servico || ps.quantidade || ps.valorUnitario)) {
			showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os serviços selecionados?" onYes={() => {
				removerServicosSelecionadas();
			}}/>);
		} else {
			removerServicosSelecionadas();
		}
	}

	const servicoHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={3} header={servicoControlSet}/>
			</Row>
			<Row>
				<Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
				<Column style={{width: "1em"}} header="Item" />
				<Column header="Dados Principais"/>
			</Row>
		</ColumnGroup>
	);

	const servicoFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={3} footer={servicoControlSet}/>
			</Row>
		</ColumnGroup>
	);

	async function handleChangeServico(event) {
		switch (event.name) {
			case "servico":
				if (event.value?.id) {
					setBlocked(true);
					const servico = await servicoService.buscar(event.value?.id);
					const resposta = await servicoService.buscarPreco({
						servico: event.value.codigo,
						cliente: ordemServico.cliente?.id,
						ordemServico: ordemServico.id,
						contrato: ordemServico.contrato?.id,
						equipamento: ordemServico.itens[0].equipamento?.id,
						data: ordemServico.inicio,
						mauUso: ordemServico.itens[0].flags?.includes("MAU_USO"),
						quantidade: ordemServico.itens[0].servicos[event.index].quantidade
					});
					setOrdemServico(prevOrdemServico => {
						try {
							prevOrdemServico.itens[0].servicos[event.index].valorUnitario = resposta.valorUnitario;
							prevOrdemServico.itens[0].servicos[event.index].valorTabela = resposta.valorTabela;
							prevOrdemServico.itens[0].servicos[event.index].origemPreco = resposta.origem;
							if (resposta.listaPrecoItemId) {
								prevOrdemServico.itens[0].servicos[event.index].listaPrecoItem = {id: resposta.listaPrecoItemId};
							}
							
							prevOrdemServico.itens[0].servicos[event.index].valorTotal = prevOrdemServico.itens[0].servicos[event.index].valorUnitario * prevOrdemServico.itens[0].servicos[event.index].quantidade;
							prevOrdemServico.itens[0].servicos[event.index].valorFranquia = prevOrdemServico.itens[0].servicos[event.index].valorTabela * prevOrdemServico.itens[0].servicos[event.index].quantidade;
							prevOrdemServico.itens[0].servicos[event.index].servico = servico;
							if (prevOrdemServico.operacao === "CAMPO" && prevOrdemServico.itens[0].servicos[event.index].status === "SOLICITADO") {
								if (prevOrdemServico.itens[0].servicos[event.index].quantidade <= servico.vwServico.saldoDisponivelTecnico) {
									prevOrdemServico.itens[0].servicos[event.index].status = "MOVIMENTADO";
								} else if (prevOrdemServico.itens[0].servicos[event.index].quantidade > servico.vwServico.saldoDisponivel) {
									prevOrdemServico.itens[0].servicos[event.index].status = "COMPRAS";
								}
							}
						} catch (error) {
							console.error(error);
						} finally {
							setBlocked(false);
						}
						return {...prevOrdemServico, _alterado: true};
					});
				} else {
					setOrdemServico(prevOrdemServico => {
						prevOrdemServico.itens[0].servicos[event.index].servico = event.value;
						return {...prevOrdemServico, _alterado: true};
					});
				}
				break;
			case "quantidade":
				setOrdemServico(prevOrdemServico => {
					prevOrdemServico.itens[0].servicos[event.index].quantidade = event.value;
					if (event.value) {
						prevOrdemServico.itens[0].servicos[event.index].valorTotal = prevOrdemServico.itens[0].servicos[event.index].valorUnitario * event.value;
					}
					return {...prevOrdemServico, _alterado: true};
				});
				break;
			default:
				setOrdemServico(prevOrdemServico => {
					prevOrdemServico.itens[0].servicos[event.index][event.name] = event.value;
					return {...prevOrdemServico, _alterado: true};
				});
				break;
		}
	}

	
	function restricoesButtons(restricao) {
		return (
			<div style={{width: "100%", textAlign: "center"}}>
				<Tooltip target={`.filter-${restricao.value}`} position="top"/>
				<i 
					data-pr-tooltip={restricao.tooltip} 
					className={`fas fas-bgcolor fa-${restricao.icon} filter-${restricao.value}`} 
					style={{backgroundColor: restricao.color}}
				/>
			</div>
		);
	}

	function toggleStatus(osis, column) {
		return (
			<SelectButton pt={{button: ({context}) => ({className: context.selected ? osis.status === "CONCLUIDO" ? "sb-success" : "sb-unsuccess" : null})}}
						  index={column.rowIndex}
						  onChange={handleChangeServico}
						  itemTemplate={restricoesButtons}
						  label="&nbsp;"
						  col={2}
						  name="status"
						  value={osis.status}
						  options={optionsStatusRessolda}
						  disabled={bloquearEdicao(osis)}
			/>
		);
	}

	return (
		<div className="col-12">
			<BlockUI blocked={blocked}>
				<DataTable paginator
				           selection={servicosSelecionados}
				           onSelectionChange={e => setServicosSelecionados(e.value)}
				           value={ordemServico.itens[0].servicos}
				           headerColumnGroup={servicoHeaderGroup}
				           footerColumnGroup={servicoFooterGroup}
				           selectionMode="checkbox"
				           header="Serviços"
				>
					<Column selectionMode="multiple"/>
					<Column header="Item" field="sequencia" style={{textAlign: "center"}}/>
					<Column header="Dados Principais" style={{flexDirection: "column", alignItems: "start"}} field="servico" body={(osis, column) => (
						<div className="grid">
							<ServicoAutoComplete notRecover={!osis.id && ordemServico.status !== "LAUDO"} disabled={bloquearEdicao(osis)} onlyRecover={isRessolda(osis.servico?.codigo) && osis.id} required invalid={messages?.servicos?.[column.rowIndex]?.servico} dropdown label="Serviço" col={osis.status !== "PENDENTE" && isRessolda(osis.servico?.codigo) ? 10 : 12} index={column.rowIndex} onChange={handleChangeServico} name="servico" value={osis.servico}/>
							<If condition={osis.status !== "PENDENTE" && (isRessolda(osis.servico?.codigo) || codigosTerceirizacao.includes(osis.servico?.codigo))} children={toggleStatus(osis, column)}/>
							<Calendar readOnly={bloquearEdicao(osis)} reduced label="Início" showTime col={2} placeholder="Início" value={osis.inicio} name="inicio" index={column.rowIndex} onChange={handleChangeServico}/>
							<Calendar readOnly={bloquearEdicao(osis)} reduced label="Término" showTime col={2} placeholder="Término" value={osis.fim} name="fim" index={column.rowIndex} onChange={handleChangeServico}/>
							<If condition={codigosTerceirizacao.includes(!osis.servico?.codigo)}>
								<UsuarioAutoComplete readOnly={bloquearEdicao(osis) || !roles.LABG} reduced label="Responsável" col={8} placeholder="Responsável" index={column.rowIndex} value={osis.responsavel} name="responsavel" onChange={handleChangeServico}/>
							</If>
							<If condition={codigosTerceirizacao.includes(osis.servico?.codigo)}>
								<SelectButton index={column.rowIndex} label="Enviar equipamento inteiro" col={3} options={yesNoOptions} name="terceirizacaoEquipamentoInteiro" value={osis.terceirizacaoEquipamentoInteiro} onChange={handleChangeServico}/>
								<If condition={!osis.terceirizacaoEquipamentoInteiro}>
									<InputText label="Peças para enviar" disabled value={osis.remessa?.map(osisr => osisr.produto?.autoComplete) || "Nenhuma peça adicionada"} col={12}/>
								</If>
							</If>
							<InputText maxLength={3800} readOnly={bloquearEdicao(osis)} style={{height: "6em"}} reduced label="Observações" multiline col={12} index={column.rowIndex} placeholder="Informações adicionais" value={osis.observacoes} name="observacoes" onChange={handleChangeServico}/>
							<If condition={osis.placaCondenada}>
								<div style={{borderRadius: "6px", padding: ".5em", margin: ".5em", fontWeight: "bold", backgroundColor: "#F44336", color: "#FFFFFF"}}>O técnico informou que não é possível recuperar esta placa (placa condenada)</div>
							</If>
						</div>
					)}/>
				</DataTable>
			</BlockUI>
		</div>
	);

}
