import React, { useEffect, useState } from "react";
import { usuarioService } from "../../service/usuarioService";
import { toast } from "react-toastify";
import { buildValidator } from "../../utils/fieldValidator";
import { Button } from "../../components/button";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import { PanelContent } from "../../components/panel";
import { EditarEmpresaUsuariosModalDadosPrincipais } from "./EditarEmpresaUsuariosModalDadosPrincipais";
import { EditarEmpresaUsuariosModalEmpresas } from "./EditarEmpresaUsuariosModalEmpresas";
import { BlockUI } from "primereact/blockui";

export const EditarEmpresaUsuarioModal = ({ value, onModalClose, filiais, empresa }) => {
  const [visible, setVisible] = useState(true);
  const [usuario, setUsuario] = useState({...value, autoComplete: value.nome + " - " + value.email, portal: true});
  const [loading, setLoading] = useState(false)
  const handleAdicionar = () => {
    const messages = usuarioService.validarUsuarioPortal(usuario);

    if (!messages.isEmpty()) {
      return setMessages(messages);
    }

    setLoading(true);

    toast.promise(
      usuarioService.salvar({ ...usuario, postBack: true }).then((usuario) => {
        onModalClose(usuario);
        setVisible(false);
        setLoading(false);
      }),
      {
        pending: `Salvando ${usuario.nome}. Aguarde...`,
        success: `${usuario.nome} salvo(a) com sucesso!`,
        error: {
          render(e) {
            return `Falha ao salvar ${usuario.nome}: ${e.data?.response?.data}`;
          },
        },
      }
    );
  };

  const [messages, setMessages] = useState(buildValidator());

  const footerContent = (
    <div>
      <Button
        label="Salvar"
        onClick={handleAdicionar}
        success
        icon="fa-solid fa-floppy-disk"
      />
    </div>
  );

  const handleChange = (event) => {
    setUsuario({ ...usuario, [event.name]: event.value });
  };

  const handleClose = (event) => {
    if (!visible) return;
    onModalClose(null);
    setVisible(false);
  };

  return (
    <Dialog
      header={usuario.id ? usuario.nome :"Novo Usuário"}
      visible={visible}
      style={{ width: "80vw" }}
      onHide={handleClose}
      footer={footerContent}
      closable={!loading}
    >
      <BlockUI blocked={loading}>
        <TabView>
          <TabPanel
            header="Dados Principais"
            leftIcon="fa-solid fa-database mr-2"
          >
            <PanelContent>
              <EditarEmpresaUsuariosModalDadosPrincipais
                usuario={usuario}
                setUsuario={setUsuario}
                handleChange={handleChange}
                messages={messages}
                setMessages={setMessages}
              />
            </PanelContent>
          </TabPanel>
          <TabPanel
            header="Empresas"
            leftIcon="fa-solid fa-city mr-2"
          >
            <PanelContent>
              <EditarEmpresaUsuariosModalEmpresas
                usuario={usuario}
                setUsuario={setUsuario}
                handleChange={handleChange}
                messages={messages}
                setMessages={setMessages}
                setLoading={setLoading}
                filiais={filiais}
                empresa={empresa}
              />
            </PanelContent>
          </TabPanel>
        </TabView>
      </BlockUI>
    </Dialog>
  );
};
