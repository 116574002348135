import React, {useState} from "react";
import {optionsStatus} from "../../service/seloIpemService";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {MultiSelect} from "../../components/multiselect";
import {InputMask} from "../../components/inputmask";
import {buildValidator, isSeloIpemValido} from "../../utils/fieldValidator";
import {InputNumber} from "../../components/inputnumber";
import {EquipamentoAutoComplete} from "../../components/autocomplete/equipamentoAutoComplete";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";

function statusTemplate(option) {
    return (
        <div className="flex align-items-center">
            <i style={{color: `${option.color}`}} className={`fa-lg	 fa-solid ${option.icon}`}/>
            <div className="ml-1">{option.label}</div>
        </div>
    );
}

export function FiltrosSeloIpem() {

    const [messages, setMessages] = useState(buildValidator());
    const {params, handleChange} = useQueryParams(QueryParamsKeys.SeloIpem);

    return (
        <>
            <MultiSelect placeholder="Todos" display="chip" itemTemplate={statusTemplate} options={optionsStatus} col={2} name="status" label="Status" value={params.status} onChange={handleChange}/>
            <InputMask invalid={messages.inicio} onBlur={() => {
                messages.inicio = isSeloIpemValido(params.inicio);
                setMessages(messages);
            }} unmask mask="99.999.999-9" placeholder="o número inicial" col={2} label="Intervalo" value={params.inicio} name="inicio" onChange={handleChange}/>
            <InputMask invalid={messages.fim} onBlur={() => {
                messages.fim = isSeloIpemValido(params.fim);
                setMessages(messages);
            }} unmask mask="99.999.999-9" placeholder="o número final" col={2} label="&nbsp;" value={params.fim} name="fim" onChange={handleChange}/>
            <UsuarioAutoComplete col={4} name="responsavel" value={params.responsavel} onChange={handleChange} label="Responsável"/>
            <InputNumber label="OS" name="os" value={params.os} onChange={handleChange} col={2}/>
            <EquipamentoAutoComplete col={4} label="Equipamento" name="equipamento" value={params.equipamento} onChange={handleChange}/>
        </>
    );

}
