import React from "react";
import {SelectButton} from "./index";

const canceladaOptions = [
    {label: "Todos", value: ""},
    {label: "Sim", value: "true"},
    {label: "Não", value: "false"}
];

export function CanceladaSelectButton(props) {

    return <SelectButton label="OS Cancelada" name="cancelada" col={2} options={canceladaOptions} {...props}/>;

}
