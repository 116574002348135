import React, {useMemo} from "react";
import {TabelaEstoqueMovimento} from "./TabelaEstoqueMovimento";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import {useBreakpoint} from "../../context/BreakpointContext";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {InputNumber} from "../../components/inputnumber";
import {Calendar} from "../../components/calendar";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {EstoqueAutoComplete} from "../../components/autocomplete/EstoqueAutoComplete";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {Panel} from "primereact/panel";
import {BlockUI} from "primereact/blockui";
import {useQueryClientMovimentoEstoque} from "../../utils/query-clients/queryClients";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {If} from "../../components/conditional/If";
import {SearchButton} from "../../components/button/SearchButton";

export function EstoqueMovimentos() {

	const {roles} = useAuth();
	const navigate = useNavigate();
	const {isLargeDevice} = useBreakpoint();
	const {query, handleSearch} = useQueryClientMovimentoEstoque();
	const {params, handleChange} = useQueryParams(QueryParamsKeys.MovimentoEstoque);

	function handleCriarLote() {
		navigate("/estoques/movimentos/new")
	}

	function handleCriarTransferencia() {
		navigate("/estoques/movimentos/transferencia");
	}

	return (
		<BlockUI blocked={query.isLoading}>
			<Panel headerTemplate={() => headerTemplate("Movimentos de Estoque", "fa-tag")}>
				<PanelContent>
					<InputNumber value={params.numero} onChange={handleChange} col={2} label="Nº do Movimento" name="numero"/>
					<Calendar value={params.inicio} onChange={handleChange} col={2} label="Período" name="inicio"/>
					<Calendar value={params.fim} onChange={handleChange} col={2} label="&nbsp;" name="fim"/>
					<UsuarioAutoComplete value={params.responsavel} onChange={handleChange} col={6} label="Responsável" name="responsavel"/>
					<EstoqueAutoComplete value={params.estoque} onChange={handleChange} col={4} label="Estoque" name="estoque"/>
					<ProdutoAutoComplete value={params.produto} onChange={handleChange} col={4} label="Produto" name="produto"/>
					<InputText value={params.notaFiscalNumero} onChange={handleChange} col={2} label="Nº da NF" name="notaFiscalNumero"/>
					<InputText value={params.notaFiscalSerie} onChange={handleChange} col={2} label="Série de NF" name="notaFiscalSerie"/>
					<InputText value={params.descricao} onChange={handleChange} col={12} label="Identificação de Movimentação" name="descricao"/>
				</PanelContent>
				<PanelFooter>
					<If condition={!!roles.EMVA}>
						<Button success onClick={handleCriarLote} label={isLargeDevice ? "Registrar" : null} icon="pi pi-plus" autowidth={!isLargeDevice}/>
						<Button success onClick={handleCriarTransferencia} label={isLargeDevice ? "Transferir" : null} icon="fa-solid fa-people-arrows" autowidth={!isLargeDevice}/>
					</If>
					<SearchButton query={query} onSearch={handleSearch}/>
				</PanelFooter>
			</Panel>
			{useMemo(() => <TabelaEstoqueMovimento value={query.data || []}/>, [query.data])}
		</BlockUI>
	);

}
