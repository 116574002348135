import React, {useCallback, useEffect, useState} from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { TabPanel } from 'primereact/tabview';
import { toast } from 'react-toastify';

import { EditarDadosPrincipais } from './EditarDadosPrincipais';
import { tipoComissaoService } from '../../services/tipoComissaoService';
import { InformationDialog } from '../../../../components/dialog/InformationDialog';
import { PanelContent } from '../../../../components/panel';
import { FormTab } from '../../../../components/formtab';
import {buildValidator, isEmpty} from '../../../../utils/fieldValidator';
import { withDialog } from '../../../../utils/dialogContext';

export const EditarTipoComissao = withDialog(({showDialog}) => {
	const {id} = useParams();
	const navigate = useNavigate();

	const [tipoComissao, setTipoComissao] = useState(tipoComissaoService.criar());
	const [messages, setMessages] = useState({...buildValidator(), itens: []});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (id !== "new") {
			tipoComissaoService.buscar(id).then(setTipoComissao);
		}
	}, [id]);

	function headerTemplate(e) {
		let style = {};

		if (e.index === 0 && (messages.descricao || messages.itens.some(mi => !isEmpty(mi)))) {
			style.color = "#F44336";
		}

		return (
			<div
				className={`${e?.className}`}
				aria-controls={`${e.ariaControls}`}
				onClick={e.onClick}
				onKeyDown={e.onKeyDown}
				style={style}
			>
				<i className={`${e?.leftIconElement?.props?.className}`} />
				<div className={`${e?.titleClassName}`}>{e?.titleElement?.props?.children}</div>
			</div>
		);
	}

	const handleSalvar = useCallback(() => {
        const messages = tipoComissaoService.validar(tipoComissao);		

		if(tipoComissao.itens.length < 1 && tipoComissao.tipo === 'UNICA'){
			showDialog(<InformationDialog header="Informação" message="Para a faixa única, é obrigatório ter pelo menos uma porcentagem de comissão. Por favor, adione-a."/>);
			return;
		}

		if(tipoComissao.itens.length < 2 && tipoComissao.tipo === 'FAIXA'){
			showDialog(<InformationDialog header="Informação" message="É obrigatório adicionar pelo menos duas faixas. Por favor, adicione-as."/>);
			return;
		}

        if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;
        }
		
        setLoading(true);
        
        toast.promise(
			tipoComissaoService.salvar(tipoComissao).then(value => {
                navigate(-1)
            }).finally(() => setLoading(false)),
            {
                "pending": `Salvando tipo de comissão. Aguarde...`,
                "success": `Tipo de comissão salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar tipo de comissão`;
                    }
                }
            }
        );
    }, [tipoComissao, navigate, showDialog]);
	
	return (
		<FormTab
			podeDeletar={false}
			podeSalvar={true}
			descricao={"Tipo de Comissão"}
			service={tipoComissaoService}
			value={tipoComissao}
			salvar={handleSalvar}
			blocked={(id !== "new" && !tipoComissao.id) || loading}
			onValidate={setMessages}
			carregando={loading}
		>
			<TabPanel header="Dados Principais" leftIcon='fa-solid fa-database' headerTemplate={headerTemplate} invalid={messages?.dadosPrincipais?.some(element => element)}> 
                <PanelContent>
					<EditarDadosPrincipais tipoComissao={tipoComissao} setTipoComissao={setTipoComissao} messages={messages} setMessages={setMessages} setLoading={setLoading}/>
                </PanelContent>
            </TabPanel>
		</FormTab>
	);
});