import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../../components/datatable";
import {useAuth} from "../../../context/AuthContext";
import {Column} from "primereact/column";
import {formatCurrency, formatDate} from "../../../utils/numberFormatter";
import {pedidoVendaService, status,  tiposOrcamento} from "../services/pedidoVendaService";
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {toast} from "react-toastify";
import saveAs from "file-saver";
import MotivoBloqueio from "./MotivoBloqueio";
import IntegracaoPedidoVenda from "./IntegracaoPedidoVenda";

export const statusIcone = (s) => {
	const value = status.filter(status => status.value === s.status)[0];
	return (
		<div className="flex justify-content-center" title={value.label}>
			<i className={`fa-solid ${value.icon} fa-lg`} style={{color: value.color}}/>
		</div>
	);
}

export const tipoOrcamentoIcone = (s) => {
	const value = tiposOrcamento.filter(tipoOrcamento => tipoOrcamento.value === s.tipoOrcamento)[0];
	return (
		<div className="flex justify-content-center" title={value.label}>
			<i className={`fa-solid ${value.icon} fa-lg`} style={{color: value.color}}/>
		</div>
	);
}

export const TabelaPedidoVenda = React.memo(({value}) => {

    const navigate = useNavigate();
    const {roles} = useAuth();
    const [mostrarCriticas, setMostrasCriticas] = useState(false);
    const [criticas, setCriticas] = useState(false);
    const [motivoBloqueio, setMotivoBloqueio] = useState(false);
    const [motivosBloqueio, setMotivosBloqueio] = useState(false);
    const [valorTotalGeral, setValorTotalGeral] = useState(formatCurrency(0));

    function handleEditar(event) {
        if(!roles.PDVC) return;
        let sistema = event.data.sistema.toLowerCase();        
        if (event.data.status === 'ORCAMENTO' || event.data.status === 'PERDIDO' || event.data?.tipoOrcamento === 'DISTRIBUIDOR') {
            navigate(`/pedidos-venda/${sistema}/orcamento/${event.data.id}`);
        } else {
            navigate(`/pedidos-venda/${sistema}/${event.data.id}`);
        }
    }

    useEffect(() => {        
        let total = 0;
        for(let pedido of value) {
            total += pedido.valorTotal;
        }
        setValorTotalGeral(formatCurrency(total));
    }, [valorTotalGeral, value]);

    const valorTotal = (rowData) => {
        if (rowData.sistema === 'TZM' && rowData.indiceDescricao) {
            let texto = 'Índice utilizado: '+ rowData.indiceDescricao +  ' | Valor de conversão ' + rowData.valorCotacao
            return (                
                <div style={{color: "#F44336", fontWeight: "bold"}} title={texto}>
                    {formatCurrency(rowData.valorTotal)}
		        </div>
            );
        } else {
            return (
                <>
                    {formatCurrency(rowData.valorTotal)}
                </>
            );
        }
    }

    const bloqueios = (pedidoVenda) => {
        setMotivoBloqueio(true);
        setMotivosBloqueio(pedidoVenda.motivosBloqueio);
	}

    const consultarCriticas = (pedidoVenda) => {
        pedidoVendaService.criticas(pedidoVenda.numero).then(data => {
            setMostrasCriticas(true);
            setCriticas(data);
        });
	}

    const acoes = (pedido) => {
        let botoes = [];
        if (pedido.status === 'ERRO') {
        	botoes.push(<span key={0} title="Visualizar Críticas de Integração" className="fas fa-database tzm-icon"  onClick={() => consultarCriticas(pedido)} />);
        }
        if (pedido.status === "BLOQUEADO") {
        	botoes.push(<span key={1} className= "fas fa-shield-alt tzm-icon" title="Motivos de Bloqueio" onClick={() => bloqueios(pedido)} />)
        }
        if (pedido.sistema === "TZM" && pedido.status !== "ERRO" ) {
            botoes.push(<span key={5} className="fa-solid fa-file-invoice-dollar tzm-icon" title="Imprimir Orçamento" onClick={() => orcamento(pedido)} />)
        }
        return <div style={{ textAlign: "center" }}>{botoes}</div>;
    }

    const orcamento = (pedidoVenda) => {
		toast.promise(
			pedidoVendaService.orcamento(pedidoVenda.id).then(data => {
				saveAs(new Blob([data], {type: "application/pdf"}), `Orçamento-${pedidoVenda.numero}.pdf`);
			}),
			{
				"pending": `Fazendo download do pdf. Aguarde...`,
				"success": `Download realizado com sucesso.`,
				"error": {
					render(e) {
						return `Falha ao realizar downlaod.`;
					}
				}
			}
		);
	}

    let headerGroup = (
	    <ColumnGroup>
		    <Row>
			    <Column header="Total Geral:" colSpan={7} alignHeader="right"/>
			    <Column header={valorTotalGeral} alignHeader="right" />
			    <Column />
		    </Row>
		    <Row>
                <Column sortable header="Tipo" field="tipoOrcamento" />
			    <Column sortable header="Número" field="numero" />
			    <Column sortable header="Vendedor" field="vendedorNome" />
			    <Column sortable header="Cliente" field="clienteNome" />
			    <Column sortable header="Inclusão" field="dataInclusao" alignHeader="center" />
			    <Column sortable header="Entrega" field="dataEntrega" alignHeader="center" />
			    <Column sortable header="Status" field="status" alignHeader="center"  />
			    <Column sortable header="Valor" field="valorTotal" alignHeader="right" />
			    <Column header="Ações" style={{width: "5em", textAlign: "center"}}/>
		    </Row>
	    </ColumnGroup>
    );

    let footerGroup = (
	    <ColumnGroup>
		    <Row>
			    <Column footer="Total Geral:" colSpan={7} footerStyle={{textAlign: 'right'}}/>
			    <Column footer={valorTotalGeral} footerStyle={{textAlign: 'right'}} />
			    <Column />
		    </Row>
	    </ColumnGroup>
    );

    return (
        <>
            <DataTable
                value={value}
                onRowDoubleClick={handleEditar}
                valuelength={value?.length}
                headerColumnGroup={headerGroup}
                footerColumnGroup={footerGroup}
            >
                <Column field="tipoOrcamento" name="tipoOrcamento" style={{textAlign: "center", width: "2em"}} body={tipoOrcamentoIcone} />
                <Column field="numero" name="numero" style={{textAlign: "left", width: "7em"}}/>
                <Column field="vendedorNome" name="vendedorNome" style={{textAlign: "left"}}/>
                <Column field="clienteNome" name="clienteNome" style={{textAlign: "left"}}/>
                <Column field="dataInclusao" name="dataInclusao" style={{textAlign: "center",  width: "8em"}} body={r => formatDate(r.dataInclusao)}/>
                <Column field="dataEntrega" name="dataEntrega" style={{textAlign: "center", width: "8em"}} body={ r => formatDate(r.dataEntrega)}/>
                <Column field="status" name="status" style={{textAlign: "center", width: "2em"}} body={statusIcone}/>
                <Column field="valorTotal" name="valorTotal" style={{textAlign: "right", width: "12em"}} body={valorTotal}/>
                <Column style={{textAlign: "center"}} body={acoes}/>
            </DataTable>
            <MotivoBloqueio visible={motivoBloqueio} setVisible={setMotivoBloqueio} bloqueios={motivosBloqueio}/>
            <IntegracaoPedidoVenda visible={mostrarCriticas} setVisible={setMostrasCriticas} criticas={criticas}/>
        </>
    );

});
