import React, {useCallback, useState} from 'react';
import {  isBiggerThanZero,  isNotNull} from '../../../utils/fieldValidator';
import { BlockUI } from 'primereact/blockui';
import { PanelContent } from '../../../components/panel';
import { InputPercentual } from '../../../components/inputnumber';
import { Dropdown } from '../../../components/dropdown';
import { optionsUf } from '../services/icmsService';

export function EditarDadosPrincipais({icms, setIcms, messages, setMessages, disabled}) {

	const [blocked, setBlocked] = useState(false);
    
    const handleChange = useCallback((event) => {
		setIcms({...icms, [event.name]: event.value});
	}, [icms, setIcms]);

    return (
		<BlockUI blocked={blocked}>
        	<PanelContent>
				<Dropdown
                    options={optionsUf}
					value={icms.origem}
					col={4} 
                    name="origem" 
                    label={"Origem"}
                    onChange={handleChange}
                    invalid={messages.origem}
					onBlur={() => {
						messages.origem = isNotNull(icms.origem);
						setMessages({...messages});
					}}	
				/>
				<Dropdown
                    options={optionsUf}
					value={icms.destino}
					col={4} 
                    name="destino" 
                    label={"Destino"}
                    onChange={handleChange}
                    invalid={messages.destino}
					onBlur={() => {
						messages.destino = isNotNull(icms.destino);
						setMessages({...messages});
					}}	
				/>
				<InputPercentual					
					col={4} 
					value={icms.aliquota} 
					min={0} 
					label="% Alíquota" 
					name="aliquota" 
					onChange={handleChange}
					onBlur={() => {
						messages.aliquota = isBiggerThanZero(icms.aliquota);
						setMessages({...messages});
					}}	
				/>
			</PanelContent>
		</BlockUI>
    );
}