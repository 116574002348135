import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { InputText } from "../../components/inputtext";
import { Password } from "../../components/password";
import "./index.css";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '../../assets/layout/layout.scss'
import '../../App.scss';
import { buildValidator, isRequired } from "../../utils/fieldValidator";
import {toast} from "react-toastify";


export function Login() {
    const [params, setParams] = useState({
        login: "",
        senha: ""
    });

    function handleChange(event) {
        setParams({...params, [event.name]: event.value});
    }

    const navigate = useNavigate()
    const {signIn, token} = useAuth()
    const [messages, setMessages] = useState(buildValidator());

    useEffect(() => {
        if(!!token){
            return navigate("/clientes")    
        }
    }, [navigate, token])

    async function handleLogin() {
        let messagesLogin = {...messages}
        messagesLogin.login = isRequired(params.login);
        messagesLogin.senha = isRequired(params.senha)  
        
        if(!!messagesLogin.login || !!messagesLogin.senha){
            return
        }
        
        toast.promise(
            signIn(params).then(() =>  navigate("/clientes")),
            {
                "pending": `Realizando login. Aguarde...`,
                "success": `Login realizado com sucesso!`,
                "error": {
                    render(e) {
                        if(e?.data?.message === "Login e/ou senha incorretos."){
                            return e.data.message
                        }
                        return `Erro ao realizar login.`;
                    }
                }
            }
        );
    }

    const handleKeypress = e => {
        if (e.charCode === 13) {
            handleLogin();
        }
    };

    return (
        <div className="panel-login">
            <Panel header="Autenticação">
                <PanelContent onKeyPress={handleKeypress}>
                    <InputText
                        type="text"
                        placeholder='o seu login'
                        value={params.login}
                        onChange={handleChange}
                        name='login'
                        label='Usuário'
                        invalid={messages.login}
                        onBlur={() => {
                            messages.login = isRequired(params.login);
                            setMessages({...messages});
                        }}
                        autoFocus
                        
                    />
                    <Password
                        label='Senha'
                        placeholder='a sua senha'
                        value={params.senha}
                        onChange={handleChange}
                        name='senha'
                        feedback={false}
                        invalid={messages.senha}
                        onBlur={() => {
                            messages.senha = isRequired(params.senha);
                            setMessages({...messages});
                        }}
                    />
                </PanelContent>
                <PanelFooter>
                    <div style={{float: "left"}}>
                        <Button
                            icon="pi pi-refresh"
                            severity="secondary"
                            onClick={() => window.location.reload(true)}
                            title="Atualizar página"
                        />
                    </div>
                    <Button 
                        type="submit" 
                        label="Entrar" 
                        onClick={handleLogin} 
                    />
                </PanelFooter>
            </Panel>
        </div>
    );
}
