import React from "react";
import {api} from "./api";

export const avaliacaoService = {
	async listarQuestoes(params) {
		const query = [];
		if (params.questao?.length) {
			query.push(`questao=lk=${params.questao}`);
		}
		return await api.get(`/ordens-servico/avaliacoes/questoes?query=${query.join(";")}`).then(res => res.data);
	},
	criarQuestao() {
		return {
			id: null,
			_key: Math.random(),
			registro: null,
			questao: ""
		};
	},
	async buscarQuestao(id) {
		return await api.get(`/ordens-servico/avaliacoes/questoes/${id}`).then(res => res.data);
	},
	async salvarQuestao(questao) {
		return await api.post("/ordens-servico/avaliacoes/questoes", questao).then(res => res.data);
	},
	async excluirQuestao(questao) {
		questao.status = "DESATIVADO";
		return await this.salvarQuestao(questao);
	},
	async restaurarQuestao(questao) {
		questao.status = "ATIVO";
		return await this.salvarQuestao(questao);
	},
	async listarRelatorio(params) {
		const query = [];
		if (params.cliente?.id) query.push(`cliente=${params.cliente.id}`);
		if (params.responsavel?.id) query.push(`responsavel=${params.responsavel.id}`);
		return await api.get(`/relatorios/ordens-servico/avaliacoes?${query.join("&")}`).then(res => res.data);
	}
}

export function iconStatus(questao) {
	switch (questao.status) {
		case "ATIVO":
			return <i className="fas fa-check-circle text-green-400"/>;
		case "DESATIVADO":
			return <i className="fas fa-times-circle text-red-400"/>;
	}
}
