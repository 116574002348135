import React from "react";

import {InputNumber as PInputNumber} from "primereact/inputnumber";
import classNames from "classnames";

import './styles.scss'
import {calcularTamanho} from "../../service/guiHelper";

export function InputNumber(props) {

    function handleChange(event) {
        if (props.onChange) {
            props.onChange({name: props.name, value: event.value, index: props.index});
        }
    }

    const handleBlur = (event) => {
        if (!event?.target?.value) {
            props.onChange({name: props.name, value: null, index: props.index});
        }
    }

    const classes = calcularTamanho(props.col);

    const inputClasses = classNames({
        "p-invalid": props.invalid
    });

    const newLocal = props.required ? <small style={{color: props.value ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : <small>&nbsp;</small>;

    return (
        <div className={classes}>
            <label>{props.label}</label>
            <PInputNumber 
                onBlur={handleBlur} 
                locale="pt-BR" 
                {...props} 
                placeholder={props.placeholder ? `Digite ${props.placeholder}`: null} 
                className={inputClasses} 
                onChange={handleChange}
            />
            {props.reduced ? null : (props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : newLocal)}
        </div>
    );

}

export function InputCurrency(props) {
    return <InputNumber mode="currency" currency={'BRL'} {...props}/>;
}

export function InputPercentual(props) {
    return <InputNumber max={100} {...props}/>;
}


export function InputPercentual2(props) {

    function handleChange(event) {
        if (props.onChange) {
            props.onChange({name: props.name, value: event.value, index: props.index});
        }
    }
    return <PInputNumber 
        inputStyle={{padding: '0 0.3rem', height: '1rem', background: 'transparent'}} 
        locale="pt-BR" 
        max={100} 
        inputClassName='border-transparent w-10 active:border-indigo-50 text-base text-color text-right border-round-xs' 
        {...props}  
        onChange={handleChange}
        useGrouping={false}
        maxFractionDigits={2}
    />;
}
