import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import {calendarioService} from "../../service/calendarioService";
import {BlockUI} from "primereact/blockui";
import {InputText} from "../../components/inputtext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Calendar} from "../../components/calendar";
import {Checkbox} from "../../components/checkbox";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {DialogContext} from "../../utils/dialogContext";
import {ConfirmDialogV2} from "../../components/confirmdialog";

export function EditarCalendario() {

    const {id} = useParams();
    const navigate = useNavigate();
    const {showDialog} = useContext(DialogContext);
    const [blocked, setBlocked] = useState(false);
    const [calendario, setCalendario] = useState(calendarioService.criar());
    const [feriadosSelecionados, setFeriadosSelecionados] = useState([]);

    useEffect(() => {
        if (id !== "new") {
            setBlocked(true);
            calendarioService.buscar(id).then(calendario => {
                setCalendario(calendario);
                setBlocked(false);
            });
        }
    }, []);

    function handleSalvar() {
        setBlocked(true);
        calendarioService.salvar(calendario).then(handleVoltar);
    }

    function handleVoltar() {
        navigate(-1);
    }

    function handleChange(event) {
        setCalendario(prevCalendario => ({...prevCalendario, [event.name]: event.value}));
    }

    function handleChangeFeriado(event) {
        calendario.feriados[event.index][event.name] = event.value;
        setCalendario({...calendario});
    }

    function adicionarFeriado() {
        calendario.feriados.push(calendarioService.criarFeriado());
        setCalendario({...calendario});
    }

    function removerFeriados() {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os feriados selecionados?" onYes={() => {
            calendario.feriados = calendario.feriados.filter(f => !((f._key && feriadosSelecionados.some(fs => fs._key === f._key)) || (f.id && feriadosSelecionados.some(fs => fs.id === f.id))));
            setCalendario({...calendario});
        }}/>);
    }

    const feriadosControlSet = (
        <div>
            <Button icon="fas fa-plus" onClick={adicionarFeriado}/>
            <Button icon="fas fa-minus" danger disabled={!feriadosSelecionados.length} onClick={removerFeriados}/>
        </div>
    );

    const feriadosHeaderGroup = (
        <ColumnGroup>
            <Row>
                <Column colSpan={4} header={feriadosControlSet}/>
            </Row>
            <Row>
                <Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
                <Column header="Nome"/>
                <Column header="Data"/>
                <Column header="Anual"/>
            </Row>
        </ColumnGroup>
    );

    return (
        <BlockUI blocked={blocked}>
            <Panel header="Calendário">
                <PanelContent>
                    <InputText label="Descrição" col={12} name="descricao" value={calendario.descricao} onChange={handleChange}/>
                    <div className="col-12">
                        <DataTable headerColumnGroup={feriadosHeaderGroup}
                                   header="Feriados"
                                   emptyMessage="Nenhum feriado adicionado"
                                   value={calendario.feriados}
                                   selection={feriadosSelecionados}
                                   selectionMode="checkbox"
                                   onSelectionChange={e => setFeriadosSelecionados(e.value)}
                        >
                            <Column selectionMode="multiple"/>
                            <Column header="Nome" field="nome" body={(f, c) => <InputText reduced index={c.rowIndex} name="nome" value={f.nome} onChange={handleChangeFeriado}/>}/>
                            <Column style={{width: "14em"}} header="Data" field="referencia" body={(f, c) => <Calendar reduced index={c.rowIndex} name="referencia" value={f.referencia} onChange={handleChangeFeriado}/>}/>
                            <Column style={{width: "7em", textAlign: "center"}} header="Anual" field="anual" body={(f, c) => <Checkbox index={c.rowIndex} name="anual" value={f.anual} onChange={handleChangeFeriado}/>}/>
                        </DataTable>
                    </div>
                </PanelContent>
                <PanelFooter>
                    <Button icon="fas fa-save" success onClick={handleSalvar} label="Salvar"/>
                    <Button icon="fas fa-arrow-left" secondary onClick={handleVoltar} label="Voltar"/>
                </PanelFooter>
            </Panel>
        </BlockUI>
    );

}
