import React, {useEffect, useMemo, useState} from 'react';
import { FiltrosAcompanhamento } from './FiltrosAcompanhamento';
import { TabelaAcompanhamento } from './TabelaAcompanhamento';
import { tipoComissaoService } from '../../services/tipoComissaoService';
import {BlockUI} from "primereact/blockui";
import { Fechamento } from './Fechamento';

export function Acompanhamento() {

    const [acompanhamento, setAcompanhamento] = useState([]);
    const [fechamento, setFechamento] = useState();
    const [tiposComissao, setTiposComissao] = useState([]);
    const [blocked, setBlocked] = useState(false);
    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [modalConsultor, setModalConsultor] = useState(false)
	const [modalTipoComissao, setModalTipoComissao] = useState(false)
    
    useEffect(() => {
        tipoComissaoService.listarCompleto([`query=${[].join(";")}`]).then((data) => setTiposComissao(data));
    }, [])

    return (
        <BlockUI blocked={blocked}>
            <FiltrosAcompanhamento 
                tiposComissao={useMemo(() => tiposComissao, [tiposComissao])} 
                setValue={useMemo(() => setAcompanhamento, [])} 
                acompanhamento={useMemo(() => acompanhamento, [acompanhamento])}
                fechamento={useMemo(() => fechamento, [fechamento])}
                setFechamento={useMemo(() => setFechamento, [])} 
                setBlocked={useMemo(() => setBlocked, [])}
                itensSelecionados={useMemo(() => itensSelecionados, [itensSelecionados])}
                setModalConsultor={useMemo(() => setModalConsultor, [])}
                setModalTipoComissao={useMemo(() => setModalTipoComissao, [])}
            />
            {
                fechamento?.id && <Fechamento fechamento={fechamento} />
            }
            <TabelaAcompanhamento 
                tiposComissao={useMemo(() => tiposComissao, [tiposComissao])} 
                setAcompanhamento={useMemo(() => setAcompanhamento, [])} 
                acompanhamento={useMemo(() => acompanhamento, [acompanhamento])}
                setItensSelecionados={useMemo(() => setItensSelecionados, [])}
                modalConsultor={useMemo(() => modalConsultor, [modalConsultor])}
                modalTipoComissao={useMemo(() => modalTipoComissao, [modalTipoComissao])}
                itensSelecionados={useMemo(() => itensSelecionados, [itensSelecionados])}
                setModalConsultor={useMemo(() => setModalConsultor, [])}
                setModalTipoComissao={useMemo(() => setModalTipoComissao, [])}
                fechamento={useMemo(() => fechamento, [fechamento])}
            />
        </BlockUI>
    );
}