import React, {useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {Button} from "../../../components/button";
import {BlockUI} from "primereact/blockui";
import {DataTable} from "../../../components/datatable";
import {Column} from "primereact/column";
import {avaliacaoService} from "../../../service/avaliacaoService";
import {avaliacaoTemplate} from "../../../utils/templates";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {ClienteAutoComplete} from "../../../components/autocomplete/ClienteAutoComplete";
import {ResponsavelAutoComplete} from "../../../components/autocomplete/responsavelAutoComplete";
import {byValor, byValorDesc} from "../../../utils/sortUtils";
import {HorizontalBarChart} from "../../../components/HorizontalBarChart";

const numberFormat = Intl.NumberFormat("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2});

export function RelatorioAvaliacao() {
	const [params, setParams] = useState({
		cliente: null,
		responsavel: null
	});
	const [loading, setLoading] = useState(false);
	const [avaliacoes, setAvaliacoes] = useState([]);
	const [satisfeitos, setSatisfeitos] = useState([]);
	const [insatisfeitos, setInsatisfeitos] = useState([]);
	useEffect(() => {
		handleImprimir();
		avaliacaoService.listarRelatorio({porCliente: true}).then(avaliacoes => {
			setSatisfeitos(avaliacoes.sort(byValorDesc).slice(0, Math.min(avaliacoes.length, 5)).map(a => ({label: a.cliente.autoComplete, value: a.valor * 20})));
			setInsatisfeitos(avaliacoes.sort(byValor).slice(0, Math.min(avaliacoes.length, 5)).map(a => ({label: a.cliente.autoComplete, value: a.valor * 20})));
		});
	}, []);
	function handleImprimir() {
		setLoading(true);
		avaliacaoService.listarRelatorio(params).then(avaliacoes => {
			setAvaliacoes(avaliacoes);
			setLoading(false);
		});
	}
	function handleChange(e) {
		setParams(prevParams => ({...prevParams, [e.name]: e.value}));
	}
	return (
		<BlockUI blocked={loading}>
			<Panel header="Relatório de Avaliações">
				<PanelContent>
					<ClienteAutoComplete col={6} name="cliente" value={params.cliente} onChange={handleChange}/>
					<ResponsavelAutoComplete col={6} name="responsavel" value={params.responsavel} onChange={handleChange}/>
				</PanelContent>
				<PanelFooter>
					<Button loading={loading} label="Atualizar" icon="fas fa-refresh" onClick={handleImprimir}/>
				</PanelFooter>
			</Panel>
			<div className="h-1rem"/>
			<Panel header="Estatísticas">
				<PanelContent className="flex gap-2">
					<HorizontalBarChart header="Clientes Mais Satisfeitos" col={6} data={satisfeitos}/>
					<HorizontalBarChart header="Clientes Menos Satisfeitos" col={6} data={insatisfeitos}/>
				</PanelContent>
			</Panel>
			<DataTable header="Consolidado de Avaliações de Ordens de Serviço" value={avaliacoes}>
				<Column sortable header="Cliente" field="cliente.autoComplete"/>
				<Column sortable header="Responsável" field="responsavel.autoComplete"/>
				<Column
					style={{width: "12rem", textAlign: "center"}}
					sortable
					header="Média"
					field="valor"
					body={a => <div>{avaliacaoTemplate(a.valor)} ({numberFormat.format(a.valor)})</div>}
				/>
			</DataTable>
		</BlockUI>
	);
}
