import React, {useContext, useState} from "react";
import {Panel} from "primereact/panel";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {Button} from "../../components/button";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import {useBreakpoint} from "../../context/BreakpointContext";
import {ordemServicoService} from "../../service/ordemServicoService";
import {Calendar} from "../../components/calendar";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {Dropdown} from "../../components/dropdown";
import {InputNumber} from "../../components/inputnumber";
import {ParamContext} from "../../utils/ParamStore";
import "./OrdemServicoMovimentoEstoque.scss";
import {
	labelOrdemServicoItemPecaStatus,
	optionsOrdemServicoItemPecaStatus
} from "../../service/ordemServicoItemService";

export const EstoqueComprasOSDefaultParams = {
	inicio: null,
	fim: null,
	responsavel: null,
	cliente: null,
	numero: null,
	statusPecas: "SOLICITADO"
};

export function OrdemServicoEstoqueMovimento() {

	const {roles} = useAuth();
	const navigate = useNavigate();
	const {isLargeDevice} = useBreakpoint();
	const [loading, setLoading] = useState(0);
	const [estoqueCompras, setEstoqueCompras] = useState([]);
	const [params, setParams] = useState(EstoqueComprasOSDefaultParams);

	function handleChange(event) {
		setParams(prevParams => ({...prevParams, [event.name]: event.value}));
	}

	async function handleProcurar() {
		const query = [
			"produtos",
			"operacao=CAMPO"
		];
		if (params.inicio?.length) query.push(`inicio=${params.inicio}`);
		if (params.fim?.length) query.push(`fim=${params.fim}`);
		if (params.numero) query.push(`numero=${params.numero}`);
		if (params.cliente?.id) {
			query.push(`cliente=${params.cliente?.id}`);
		} else if (params.cliente?.length) {
			query.push(`clienteStr=${params.cliente}`);
		}
		if (params.statusPecas?.length) query.push(`statusPecas=${params.statusPecas}`);
		if (params.responsavel?.id) query.push(`responsavel=${params.responsavel.id}`);
		setLoading(1);
		await ordemServicoService.listar(query).then(setEstoqueCompras);
		setLoading(0);
	}

	function handleEditar(event) {
		navigate(`/estoques/movimentos/ordem-servico?numero=${event.data.numero}`);
	}

	if (!roles.OSSB) {
		return null;
	}

	return (
		<div>
			<Panel headerTemplate={() => headerTemplate("Solicitação de Peças em Campo", "fa-scroll")}>
				<PanelContent>
					<InputNumber col={2} label="Número da OS" name="numero" value={params.numero} onChange={handleChange}/>
					<Calendar col={2} label="Período" name="inicio" value={params.inicio} onChange={handleChange}/>
					<Calendar col={2} label="&nbsp;" name="fim" value={params.fim} onChange={handleChange}/>
					<ClienteAutoComplete forceSelection={false} name="cliente" col={6} value={params.cliente} onChange={handleChange}/>
					<UsuarioAutoComplete col={9} label="Responsável" value={params.responsavel} name="responsavel" onChange={handleChange}/>
					<Dropdown col={3} label="Status da Peça" name="statusPecas" value={params.statusPecas} onChange={handleChange} options={optionsOrdemServicoItemPecaStatus}/>
				</PanelContent>
				<PanelFooter>
					<Button info disabled={loading} loading={loading} onClick={() => handleProcurar(params)} label={isLargeDevice ? "Procurar" : null} icon="pi pi-search" autowidth={!isLargeDevice}/>
				</PanelFooter>
			</Panel>
			<DataTable value={estoqueCompras} onRowDoubleClick={handleEditar}>
				<Column sortable header="Nº" field="numero" body={os => <div style={{textAlign: "right"}}>{os.numero}</div>}/>
				<Column sortable field="cliente.razaoSocial" header="Cliente" body={os => os.cliente?.razaoSocial}/>
				<Column sortable field="responsavel.nome" header="Responsável" body={os => os.responsavel?.nome}/>
				<Column sortable field="" header="Peças Solicitadas" body={os => {
					return (
						<table className="osem-table">
							{
								os.produtos.filter(osp => !params.statusPecas || params.statusPecas === osp.statusPeca).map(osp => (
									<tr>
										<td style={{width: "5em", textAlign: "right"}}>{osp.quantidade}</td>
										<td>{osp.autoComplete}</td>
										<td style={{width: "12em"}}>{labelOrdemServicoItemPecaStatus[osp.statusPeca]}</td>
									</tr>
								))
							}
						</table>
					);
				}}/>
			</DataTable>
		</div>
	);

}
