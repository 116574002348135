import React, {useEffect, useState} from "react";
import {Dropdown} from "../dropdown";
import {ordemServicoEtapaService} from "../../service/ordemServicoEtapaService";

export function EtapaDropdown(props) {

	const [optionsEtapas, setOptionsEtapas] = useState([]);

	useEffect(() => {
		const params = [];
		if (props.fluxo?.length) params.push(`fluxo=${props.fluxo}`);
		if (props.operacao?.length) params.push(`operacao=${props.operacao}`);
		ordemServicoEtapaService.listarDescricoes(params).then(etapas => {
			etapas = [...new Set(etapas.sort((a, b) => a.localeCompare(b)).map(e => e.replace(/\s\(v[0-9]+\)/g, "").trim()))].map(e => ({label: e, value: e}));
			setOptionsEtapas([{label: "<Qualquer>", value: null}, ...etapas]);
		});
	}, []);

	return <Dropdown options={optionsEtapas} label="Etapa" {...props}/>;

}
