import { api } from "../../../service/api";
import { baseService } from "../../../service/baseService";
import { buildValidator, isBiggerThanZero, isBiggerThanZeroValor, isRequired } from "../../../utils/fieldValidator";

const resourceUrl = "/tipos-comissao";

export const tipoComissaoService = {
	criar: () => {
		return {
			id: null,
			descricao: null,
			tipo: 'FAIXA_INDIVIDUAL',
			itens: [],
		};
	},
	criarFaixa: (faixas) => ({
		id: null,
		percentual: 0,
		acima: false,
		valor: 0.00,
		item: (faixas?.length || 0) + 1,
		_key: Math.random() * 1000,
	}),
	criarFaixaUnica: (faixas) => ({
		id: null,
		percentual: 0,
		acima: true,
		valor: 0,
		item: (faixas?.length || 0) + 1,
		_key: Math.random() * 1000,
	}),

	validar: tipoComissao => {
		let messages = buildValidator();

		messages.descricao = isRequired(tipoComissao.descricao)
		
        //ITENS
        if (!messages.itens) {
			messages.itens = [...Array(tipoComissao.itens.length)].map((_, i) => ({
				percentual: null,
				valor: null,
			}));
		}
		for (let i = 0; i < tipoComissao.itens.length; ++i) {
			messages.itens[i].percentual = isBiggerThanZero(tipoComissao.itens[i].percentual);
			if(tipoComissao.tipo === 'FAIXA'){
				messages.itens[i].valor = isBiggerThanZeroValor(tipoComissao.itens[i].valor)
			}
		}

		return messages;
	},

	listarCompleto: async (params) => {	
		let listar;	
		if (params) {
			listar = await api.get(`${resourceUrl}/listarCompleto?${params.join("&")}`).then(response => response.data);
		} else {
			listar = await api.get(`${resourceUrl}/listarCompleto`).then(response => response.data);
		}
		return listar;
	},

	...baseService(resourceUrl)
};

export const ateOuAcima = [
	{label: "Até", value: false},
	{label: "Acima", value: true}
];

export const faixaOuUnica = [
	{label: "Individual", value: 'FAIXA_INDIVIDUAL'},
	{label: "Somatória", value: 'FAIXA_SOMA'},
	{label: "Única", value: 'UNICA'},
];

