import React, {useState} from 'react';
import {AutoComplete} from '.';
import { condicaoPagamentoService } from '../../pages/Comercial/services/condicaoPagamentoService';

export function CondicaoPagamentoAutoComplete(props) {

	const [condicoesPagamento, setCondicoesPagamento] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("")

	function completeMethod(event) {
		const query = [];
		query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);
		query.push(`status=ATIVO`);
		if (event.query.length) query.push(query);
		condicaoPagamentoService.autocomplete(query).then(res => {
			setCondicoesPagamento(res);
			if (!res[0]) {
				setEmptyMessage("Nenhum registro encontrado");
			} else {
				setEmptyMessage("");
			}
		});
	}

	return (
		<AutoComplete
			col={3}
			minLength={1}
			placeholder="Código ou descrição"
			label="Condição de Pagamento"
			name="condicaoPagamento"
			value={props.value}
			onChange={props.onChange}
			completeMethod={completeMethod}
			suggestions={condicoesPagamento}
			invalid={emptyMessage}
			icon="fa-solid fa-credit-card"
			{...props}
		/>
	)
}
