export const mimeTypesImages = [
	"image/png",
	"image/bmp",
	"image/jpeg",
	"video/x-msvideo",
	"video/mp4",
	"video/mpeg",
	"video/ogg",
	"video/webm",
	"video/3gpp",
	"video/3gpp2",
	"video/x-ms-asf",
	"video/x-ms-wmv"
];

export const mimeTypesIcons = {
	"image/png": "fas fa-file-image",
	"image/bmp": "fas fa-file-image",
	"image/jpeg": "fas fa-file-image",
	"application/pdf": "fas fa-file-pdf text-red-400",
	"application/msword": "fas fa-file-alt text-blue-400",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document": "fas fa-file-alt text-blue-400",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.template": "fas fa-file-alt text-blue-400",
	"application/vnd.ms-word.document.macroEnabled.12": "fas fa-file-alt text-blue-400",
	"application/vnd.ms-word.template.macroEnabled.12": "fas fa-file-alt text-blue-400",
	"application/vnd.ms-excel": "fas fa-file-excel text-green-400",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "fas fa-file-excel text-green-400",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.template": "fas fa-file-excel text-green-400",
	"application/vnd.ms-excel.sheet.macroEnabled.12": "fas fa-file-excel text-green-400",
	"application/vnd.ms-excel.template.macroEnabled.12": "fas fa-file-excel text-green-400",
	"application/vnd.ms-excel.addin.macroEnabled.12": "fas fa-file-excel text-green-400",
	"application/vnd.ms-excel.sheet.binary.macroEnabled.12": "fas fa-file-excel text-green-400",
	"application/vnd.ms-powerpoint": "fas fa-file-powerpoint text-orange-400",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation": "fas fa-file-powerpoint text-orange-400",
	"application/vnd.openxmlformats-officedocument.presentationml.template": "fas fa-file-powerpoint text-orange-400",
	"application/vnd.openxmlformats-officedocument.presentationml.slideshow": "fas fa-file-powerpoint text-orange-400",
	"application/vnd.ms-powerpoint.addin.macroEnabled.12": "fas fa-file-powerpoint text-orange-400",
	"application/vnd.ms-powerpoint.presentation.macroEnabled.12": "fas fa-file-powerpoint text-orange-400",
	"application/vnd.ms-powerpoint.template.macroEnabled.12": "fas fa-file-powerpoint text-orange-400",
	"application/vnd.ms-powerpoint.slideshow.macroEnabled.12": "fas fa-file-powerpoint text-orange-400",
};
