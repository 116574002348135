import React, {useEffect, useState} from "react";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {acompanhamentoOperacoes, acompanhamentoTipos, ordemServicoService} from "../../service/ordemServicoService";
import moment from "moment";
import {Row} from "primereact/row";
import {ColumnGroup} from "primereact/columngroup";
import {printRestricao} from "../../components/cards/CardOrdemServico";
import {BlockUI} from "primereact/blockui";

const acompanhamentoHeaderGroup = (
	<ColumnGroup>
		<Row>
			<Column style={{width: "3em"}}/>
			<Column style={{width: "3em"}}/>
			<Column style={{width: "3em"}}/>
			<Column style={{width: "3em"}}/>
			<Column style={{width: "13em"}} header="Início"/>
			<Column style={{width: "13em"}} header="Fim"/>
			<Column header="Etapa"/>			
			<Column header="Responsável"/>
			<Column header="Observações"/>
		</Row>
	</ColumnGroup>
);

function templateAcompanhamento(acompanhamento) {
	return (
		<div>
			{
				acompanhamento.eventos.map(e => (
					<div style={{margin: ".5em"}}>
						<div style={{fontWeight: "bold"}}>{moment(e.registro).format("DD/MM/YYYY HH:mm:ss")}</div>
						<div style={{fontStyle: "italic"}}>Registrado por {e.usuario}</div>
						<div>{e.observacoes}</div>
					</div>
				))
			}
		</div>
	);
}

export function Acompanhamento({ordemServico}) {

	const [expanded, setExpanded] = useState([]);
	const [loading, setLoading] = useState(false);
	const [acompanhamentos, setAcompanhamentos] = useState([]);

	useEffect(() => {
		setLoading(true);
		const acompanhamentos = [...ordemServico.acompanhamentos];
		ordemServicoService.listarAcompanhamentosRestricao(ordemServico.id).then(restricoes => {
			for (const acompanhamento of acompanhamentos) {
				acompanhamento.restricoes = [];
				for (const restricao of restricoes) {
					if (moment(restricao.registro).isBetween(moment(acompanhamento.inicio), moment(acompanhamento.fim))) {
						acompanhamento.restricoes.push(restricao);
					}
				}
			}
			setAcompanhamentos(acompanhamentos.sort((a, b) => b.inicio?.localeCompare(a.inicio)));
			setLoading(false);
		});
	}, [ordemServico.acompanhamentos]);

	function expandRow(event) {
		setExpanded(prevExpanded => {
			prevExpanded.push(event.data);
			return [...prevExpanded];
		});
	}

	function collapseRow(event) {
		setExpanded(prevExpanded => {
			prevExpanded = prevExpanded.filter(e => e.id !== event.data.id);
			return [...prevExpanded];
		});
	}

	return (
		<BlockUI blocked={loading}>
			<DataTable value={acompanhamentos} headerColumnGroup={acompanhamentoHeaderGroup} emptyMessage="Nenhum item adicionado" expandedRows={expanded} onRowExpand={expandRow} onRowCollapse={collapseRow} rowExpansionTemplate={templateAcompanhamento}>
				<Column body={osa => <div className="evento-balloon" style={{backgroundColor: osa.eventos?.length ? "#e24c4c" : "#dddddd"}}>{osa.eventos?.length}</div>}/>
				<Column expander/>
				<Column header="&nbsp;" style={{textAlign: "center"}} field="tipo" body={osa => acompanhamentoTipos[osa.tipo]}/>
				<Column header="&nbsp;" style={{textAlign: "center"}} field="operacao" body={osa => acompanhamentoOperacoes[osa.operacao]}/>
				<Column header="Início" field="inicio" body={osa => osa.inicio && moment(osa.inicio).format("DD/MM/YYYY HH:mm:ss")}/>
				<Column header="Fim" field="fim" body={osa => osa.fim && moment(osa.fim).format("DD/MM/YYYY HH:mm:ss")}/>
				<Column header="Etapa" field="etapa" body={osa => (
					<div>
						{osa.etapa?.descricao}
						<div>{osa.restricoes?.map(r => printRestricao(ordemServico, r.restricao))}</div>
					</div>
				)}/>
				<Column header="Responsável" field="responsavel" body={osa => osa.responsavel?.nome || osa.atribuinte?.nome}/>
				<Column header="Observações" field="observacoes" body={osa => osa.observacoes}/>
			</DataTable>
		</BlockUI>
	);

}
