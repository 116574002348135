import React, {useState} from "react";
import {Dialog, DialogFooter} from "../../../components/dialog";
import {PanelContent} from "../../../components/panel";
import {Button} from "../../../components/button";
import {InputText} from "../../../components/inputtext";

export function EditarPendencia({pendencia, onChange}) {

    const [visible, setVisible] = useState(true);
    const [entidade, setEntidade] = useState({...pendencia});

    function handleSalvar() {
        if (onChange) {
            onChange(entidade);
        }
        handleClose();
    }

    function handleChange(event) {
        setEntidade(prevEntidade => ({...prevEntidade, [event.name]: event.value}));
    }

    function handleClose() {
        setVisible(false);
    }

    return (
        <Dialog header="Pendência" visible={visible} style={{width: "550px"}} onHide={handleClose}>
            <PanelContent>
                <InputText multiline style={{height: "10em"}} col={12} label="Justificativa" name="observacoes" value={entidade.observacoes} onChange={handleChange}/>
            </PanelContent>
            <DialogFooter>
                <Button icon="fas fa-save" success label="Salvar" onClick={handleSalvar}/>
                <Button icon="fas fa-times" secondary label="Fechar" onClick={handleClose}/>
            </DialogFooter>
        </Dialog>
    );

}
