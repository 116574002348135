export const TIPO_COMISSAO_NAO_IDENTIFICADO = ' Não Identificado';

export function desagruparDados(grupos) {
    const dadosOriginais = [];
    grupos.forEach(grupo => {
        const { tiposComissao } = grupo;
        Object.keys(tiposComissao).forEach(chaveTipoComissao => {
            const { dados } = tiposComissao[chaveTipoComissao];
            dadosOriginais.push(...dados.map(item => {
                return {
                    ...item
                };
            }));
        });
    });
    return dadosOriginais;
}

export const agrupar = (dados) => {
    return dados.sort(function(a, b) {
        
        let tipoComissaoA = a.tipoComissao?.descricao?.toLowerCase();
        let tipoComissaoB = b.tipoComissao?.descricao?.toLowerCase();
    
        let tipoComissaoCompare = tipoComissaoA.localeCompare(tipoComissaoB);
    
        if (tipoComissaoCompare !== 0) {
            return tipoComissaoCompare;
        }
    
        // Ordena por dataEmissao
        let dataA = a.dataEmissao;
        let dataB = b.dataEmissao;
    
        if (dataA < dataB) return -1;
        if (dataA > dataB) return 1;
    
        // Se as datas e descrições são iguais, ordena por cliente usando localeCompare
        let clienteA = a.cliente?.razaoSocial?.toLowerCase();
        let clienteB = b.cliente?.razaoSocial?.toLowerCase();
    
        return clienteA.localeCompare(clienteB, undefined, { sensitivity: 'base' });
        })       
        .reduce((grupos, item) => {
            const { vendedor, tipoComissao, ...rest } = item;
            const { nome, codigo } = vendedor;
            const chaveVendedor = `${codigo}-${nome}`;
            const chaveTipoComissao = tipoComissao ? tipoComissao.descricao : 'naoAtribuidos';
            
            let existingGroup = grupos.find(group => group.chaveVendedor === chaveVendedor);
            if (!existingGroup) {
                existingGroup = {
                    chaveVendedor,
                    vendedorCodigo: codigo,
                    vendedorNome: nome,
                    tiposComissao: {}
                };
                grupos.push(existingGroup);
            }
        
            if (!existingGroup.tiposComissao[chaveTipoComissao]) {
                existingGroup.tiposComissao[chaveTipoComissao] = {
                    id: tipoComissao ? tipoComissao.id : null,
                    dados: []
                };
            }
        
            existingGroup.tiposComissao[chaveTipoComissao].dados.push({ 
                ...rest, 
                tipoComissao,
                vendedor,	
            });
        
            return grupos;
        }, [])
        .sort((a, b) => a.vendedorNome.localeCompare(b.vendedorNome));
}

export const formatarParaReal = (valor) => {
    return valor.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
}

export function ordenarFaixas(faixas) {
    faixas.sort((a, b) => {
        if (a.valor === b.valor) {
            return a.acima ? 1 : -1; // Prioriza 'acima' igual a false
        }
        return a.valor - b.valor;
    });

    return faixas;
}

export function calcularComissao(somaValorBaseCalculoComissaoAprovado, somaValorFaturado, faixasComissao) {
    const faixasOrdenadas = ordenarFaixas(faixasComissao);
    const faixaComtemplada = faixasOrdenadas.find((faixa) => {
        if (faixa.acima && somaValorFaturado >= faixa.valor) {
            return true;
        }

        if (somaValorFaturado <= faixa.valor) {
            return true;
        }
    });

    const comissao = (faixaComtemplada.percentual / 100) * somaValorBaseCalculoComissaoAprovado;
    return { comissao, faixaComtemplada };
}

export function encontrarChaveParaObjeto(objetoParaEncontrar, tiposComissao) {
    for (const chave in tiposComissao) {
        const dados = tiposComissao[chave]?.dados;
        if (dados?.some(objeto => objeto.id === objetoParaEncontrar.id)) {
            return chave;
        }
    }
    return null;
}

export function formatarString(str) {
    if (!str || str.trim() === '') {
        return str;
    }

    const isCamelCase = str !== str.toLowerCase() && str !== str.toUpperCase();

    if (isCamelCase) {
        const palavrasSeparadas = str.replace(/([a-z])([A-Z])/g, '$1 $2');
        return palavrasSeparadas[0].toUpperCase() + palavrasSeparadas.slice(1).toLowerCase();
    }

    return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function manterChavesExclusivas(objeto1, objeto2) {
    const chavesExclusivas = Object.keys(objeto1).filter(chave => !objeto2.hasOwnProperty(chave));
    const novoObjeto = {};

    chavesExclusivas.forEach(chave => {
        novoObjeto[chave] = objeto1[chave];
    });

    return novoObjeto;
}

export const calcularComissaoUnica = (nota, name, value, tipoComissaoCompleto) => {
    if(name === 'valorBaseCalculoComissao'){
        const percentualDecimal = nota.percentualComissao / 100;
        const valorComissao = value * percentualDecimal;
        return {
            ...nota,
            valorBaseCalculoComissao: value,
            valorComissao
        };
    }

    if(name === 'percentualComissao'){            
        const percentualDecimal = value / 100;
        const valorComissao = nota.valorBaseCalculoComissao * percentualDecimal;
        return {
            ...nota,
            percentualComissao: value,
            valorComissao
        };
    }
    
    if(name === 'tipoComissao'){
        const percentualComissao = tipoComissaoCompleto?.itens[0]?.percentual;
        const percentualDecimal = percentualComissao / 100;
        const valorComissao = nota.valorBaseCalculoComissao * percentualDecimal;
        return {    
            ...nota,
            tipoComissao: tipoComissaoCompleto,
            percentualComissao,
            valorComissao
        }
    }
}

export function ordemServicoRowClassName(comissaoAcompanhamento) {
    const verde = comissaoAcompanhamento.status === "APROVADO";
    return {
        "tabela-os": true,
        verde,
        "vermelho": comissaoAcompanhamento.status === "REPROVADO"
    };
}