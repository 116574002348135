import {baseService} from "./baseService";
import {buildValidator, isArrayNotEmpty, isRequired} from "../utils/fieldValidator";

export const perfilService = {
    criar: () => {
        return {
            id: null,
            descricao: "",
            roles: []
        };
    },
    validar: (perfil) => {
        const messages = buildValidator();
        messages.descricao = isRequired(perfil.descricao);
        messages.roles = isArrayNotEmpty(perfil.roles);
        return messages;
    },
    ...baseService("/perfis"),
    async listarPerfis(params) {
        const query = [];
        if (params.descricao?.length) query.push(`descricao=lk='${params.descricao}'`);
        if (!params.visualizarDesativados) {
            query.push("status==ATIVO");
        }
        return perfilService.listar([`query=${query.join(";")}`]);
    }
};
