import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {InputText} from "../../components/inputtext";
import {FormEdit} from "../../components/form/Edit";
import {perfilService} from "../../service/perfilService";
import {RoleCheckboxes} from "./RoleCheckboxes";
import {buildValidator, isArrayNotEmpty, isRequired} from "../../utils/fieldValidator";
import {useAuth} from '../../context/AuthContext';

export function EditarPerfil() {

    const {id} = useParams();
    const {roles} = useAuth()
    const [perfil, setPerfil] = useState(perfilService.criar());

    const [messages, setMessages] = useState(buildValidator());

    function handleChange(event) {
        setPerfil({...perfil, [event.name]: event.value});
    }

    function handleChangeRoles(event) {
        let roles = perfil.roles;
        if (event.value) {
            roles.push(event.name);
        } else {
            roles = roles.filter(r => r !== event.name);
        }
        setPerfil({...perfil, roles});
        messages.roles = isArrayNotEmpty(perfil.roles);
        setMessages({...messages});
    }

    function handleChangeAllRoles(event) {
        let roles = perfil.roles;
        roles = roles.filter(r => !r.startsWith(event.name));
        if (event.value) {
            roles.push(`${event.name}A`, `${event.name}B`, `${event.name}C`, `${event.name}D`);
        }
        setPerfil({...perfil, roles});
        messages.roles = isArrayNotEmpty(perfil.roles);
        setMessages({...messages});
    }

    useEffect(() => {
        if (id !== "new") {
            perfilService.buscar(id).then(setPerfil);
        }
    }, [id]);

    return (
        <FormEdit podeDeletar={!!roles.PRFD}
                  podeSalvar={!!roles.PRFC}
                  descricao="Perfil"
                  onValidate={setMessages}
                  service={perfilService}
                  value={perfil}
                  blocked={id !== "new" && !perfil.id}
        >
            <InputText required onBlur={() => {
                messages.descricao = isRequired(perfil.descricao);
                setMessages({...messages});
            }} invalid={messages.descricao} col={12} label="Descrição" name="descricao" value={perfil.descricao} onChange={handleChange}/>
            <RoleCheckboxes required label="Opções" invalid={messages.roles} perfil={perfil} handleChangeRoles={handleChangeRoles} handleChangeAllRoles={handleChangeAllRoles}/>
        </FormEdit>
    );

}
