import React, {useState} from "react";
import {Dialog, DialogFooter} from "../../../components/dialog";
import {PanelContent} from "../../../components/panel";
import {Button} from "../../../components/button";
import {InputNumber} from "../../../components/inputnumber";
import {Calendar} from "../../../components/calendar";
import {InputText} from "../../../components/inputtext";
import moment from "moment";
import {ordemServicoDeslocamentoService, ordemServicoTempoParadoService} from "../../../service/ordemServicoService";
import {buildValidator} from "../../../utils/fieldValidator";

export function EditarTempoParado({tempoParado, onChange}) {

    const [visible, setVisible] = useState(true);
    const [messages, setMessages] = useState(buildValidator());
    const [entidade, setEntidade] = useState({...tempoParado});

    function handleSalvar() {
        const messages = ordemServicoTempoParadoService.validar(entidade);
        if (messages.isEmpty()) {
            if (onChange) {
                onChange(entidade);
            }
            handleClose();
        } else {
            setMessages({...messages});
        }
    }

    function handleChange(event) {
        setEntidade(prevEntidade => ({...prevEntidade, [event.name]: event.value}));
    }

    function handleClose() {
        setVisible(false);
    }

    return (
        <Dialog header="Tempo de Espera" visible={visible} style={{width: "550px"}} onHide={handleClose}>
            <PanelContent>
                <Calendar invalid={messages.inicio} showTime label="Início" col={6} name="inicio" value={entidade.inicio} onChange={handleChange}/>
                <Calendar invalid={messages.fim} showTime minDate={moment(entidade.inicio).toDate()} label="Fim" col={6} name="fim" value={entidade.fim} onChange={handleChange}/>
                <InputText multiline style={{height: "10em"}} col={12} label="Observações" name="observacoes" value={entidade.observacoes} onChange={handleChange}/>
            </PanelContent>
            <DialogFooter>
                <Button icon="fas fa-save" success label="Salvar" onClick={handleSalvar}/>
                <Button icon="fas fa-times" secondary label="Fechar" onClick={handleClose}/>
            </DialogFooter>
        </Dialog>
    );

}
