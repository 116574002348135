import {labelStatus} from "../../service/ordemServicoService";
import {formatNumber} from "chart.js/helpers";
import React from "react";
import {findRestricoesBloqueantes} from "../../utils/ordemServicoUtils";
import {SelectButton} from "primereact/selectbutton";

const tabDetails = {
    recepcao: {icon: <i style={{transform: "rotate(-45deg)"}} className="fas fa-arrow-left"/>, label: "Recepção"},
    laudo: {icon: <i className="fas fa-user-md"/>, label: "Laudo"},
    manutencao: {icon: <i className="fas fa-screwdriver-wrench"/>, label: "Manutenção"},
    limpeza: {icon: <i className="fas fa-broom"/>, label: "Limpeza"},
    teste: {icon: <i className="fas fa-bolt"/>, label: "Teste"},
    expedicao: {icon: <i style={{transform: "rotate(-45deg)"}} className="fas fa-arrow-right"/>, label: "Expedição"},
    fechada: {icon: <i className="fas fa-times"/>, label: "Fechada"}
};

export function tabHeader(aba, value) {
    return (
        <span className="blob-counter">
            <b style={{backgroundColor: "#3B82F6"}}>{formatNumber(value[aba].length)}</b>{tabDetails[aba].icon} {tabDetails[aba].label}
        </span>
    );
}

export function tabelaTotais(count, status, aguardando = false) {
    return (
        <>
            <div style={{float: "right"}}>
                <table>
                    <tbody>
                    <tr>
                        <td>Total de registros {aguardando ? "aguardando confirmação" : `em ${labelStatus[status]}`}:</td>
                        <td style={{minWidth: "8em", textAlign: "right"}}>{formatNumber(count)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div style={{clear: "both"}}/>
        </>
    );
}

export function resolvePath(status) {
    switch (status) {
        case "LAUDO":
        case "MANUTENCAO":
        case "LIMPEZA":
        case "TESTE":
            return `/laboratorio/${status.toLowerCase()}`;
        case "RECEPCAO":
        case "EXPEDICAO":
            return `/backoffice/${status.toLowerCase()}`;
        default:
            return "/ordens-servico";
    }
}

export function disabledButtons(status, selecionados) {
    const recebido = (
        !selecionados.length
        || selecionados.some(os => (os.status === "LAUDO" && os.confirmadoLaudo) || (os.status === "EXPEDICAO" && os.confirmadoExpedicao))
    );
    const responsavel = (
        !selecionados.length
        || selecionados.some(os => (os.status === "LAUDO" && !os.confirmadoLaudo) || (os.status === "EXPEDICAO" && !os.confirmadoExpedicao))
    );
    const concluido = (
        !selecionados.length
        || selecionados.some(os => ((findRestricoesBloqueantes(os, {excetoLaudo: true}).length && !os.cancelada) || !os.responsavel?.id))
        || selecionados.some(os => (os.status === "LAUDO" && (!os.confirmadoLaudo || (os.laudoConcluido && (!os.countOrcamentoAprovado && !os.cancelada)))) || (os.status === "EXPEDICAO" && !os.confirmadoExpedicao))
    );
    const prateleira = (
        !selecionados.length
        || (["RECEPCAO", "EXPEDICAO"].includes(status) && selecionados.some(os => os.status === "LAUDO"))
    );
    return {recebido, responsavel, concluido, prateleira};
}

export function TabelaTotais({value}) {
    return (
        <div className="tabela-os-totais">
            <table>
                <tbody>
                <tr>
                    <td>Total de Registros:</td>
                    <td>{formatNumber(value.length)}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

const optionsAguardandoRecebimento = [
    {label: <><i className="fas fa-asterisk"/> Todos</>, value: false},
    {label: <><i className="fas fa-clipboard"/> Aguardando Confirmação</>, value: true}
];

export function FiltroRecebimento({name, value, onChange}) {

    function handleChange(event) {
        onChange({name, value: event.value});
    }

    return <SelectButton style={{display: "inline-block", marginRight: ".125em"}} options={optionsAguardandoRecebimento} value={value} onChange={handleChange}/>;

}
