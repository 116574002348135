import React, {useCallback, useRef} from "react";
import {Button} from "../button";
import "./index.scss";

function isVideo(contentType) {
	switch (contentType) {
		case "video/mpeg":
		case "video/mp4":
		case "video/webm":
		case "video/x-ms-wmv":
		case "video/x-msvideo":
		case "video/x-ms-asf":
			return true;
		default:
			return false;
	}
}

function isImage(contentType) {
	switch (contentType) {
		case "image/bmp":
		case "image/png":
		case "image/jpeg":
			return true;
		default:
			return false;
	}
}

export function Foto({onChange, accept, value, onDelete, readOnly, thumb, contentType}) {

	const fileInputRef = useRef(null);

	const carregarArquivo = useCallback(e => {
		e.stopPropagation();
		fileInputRef.current.click();
	}, []);

	const handleUpload = useCallback(e => {
		const file = e.target.files[0];
		if (!file) return;
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = e => {
			const content = e.target.result.split(/[:;,]/g);
			onChange({fileName: file.name, contentType: content[1], content: content[3]});
		}
	}, [onChange]);

	async function handleClick() {
		window.open(value, "_blank");
	}

	return (
		<div className="foto-img">
			{
				readOnly || value
					? null
					: (
						<span onClick={carregarArquivo} className="foto-camera">
							Clique aqui para tirar uma foto ou carregar de um arquivo
						</span>
					)
			}
			<ImageComponent value={value} contentType={contentType} thumb={thumb}/>
			<div className="foto-button">
				{value && isVideo(contentType) ? <Button style={{display: "inline-block"}} icon="pi pi-download" success onClick={handleClick}/> : null}
				{value && !readOnly ? <Button style={{display: "inline-block"}} icon="pi pi-times" danger onClick={onDelete}/> : null}
			</div>
			<input className="foto-upload" type="file" ref={fileInputRef} accept={accept} onChange={handleUpload}/>
		</div>
	);

}


export function ImageComponent(props) {
	async function handleClick() {
		window.open(props.value, "_blank");
	}
	if (isVideo(props.contentType)) {
		return <video controls style={{cursor: "pointer", width: "100%", height: "100%", border: "1px solid rgba(0, 0, 0, .2)", ...props.style}} src={props.value}/>;
	} else if (isImage(props.contentType)) {
		return <img onClick={props.handleClick || handleClick} alt="" style={{cursor: "pointer", width: "100%", height: "100%", border: "1px solid rgba(0, 0, 0, .2)", ...props.style}} src={`${props.value}${props.thumb && !props.value?.startsWith("data") ? "?thumb=true" : ""}`}/>;
	}
	return (
		<div onClick={props.handleClick || handleClick} style={{fontWeight: "bold", cursor: "pointer"}}>
			<i className="fas fa-file-pdf"/> {props.fileName}
		</div>
	);
}
