import React, {useCallback, useState} from "react";

import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { Panel } from "primereact/panel";

import { useBreakpoint } from "../../../../context/BreakpointContext";
import { useAuth } from "../../../../context/AuthContext";
import { headerTemplate } from "../../../../utils/templates";
import { PanelContent, PanelFooter } from "../../../../components/panel";
import { Button } from "../../../../components/button";
import { withDialog } from "../../../../utils/dialogContext";
import { InformationDialog } from "../../../../components/dialog/InformationDialog";
import { Calendar } from "../../../../components/calendar";
import { comissaoFechamentoService } from "../../services/comissaoFechamentoService";

export const FiltrosFechamento =  withDialog(React.memo(({setValue, showDialog}) => {
    
    const {breakpoint} = useBreakpoint()
    const navigate = useNavigate();
    const isLargeDevice = breakpoint === 'lg' || breakpoint === 'md'
    const [loading, setLoading] = useState(0);
    const [params, setParams] = useState({
		inicio: moment().format("YYYY-MM"),
		fim: moment().format("YYYY-MM"),
        tipoComissao: null,
		status: null
	});

    const {roles} = useAuth();

    const handleChange = useCallback((event) => {
		setParams({...params, [event.name]: event.value});
	}, [params])
   
	const handleProcurar = useCallback(() => {
		const query = [];
		if(!params?.inicio || !params?.fim) {
			return showDialog(<InformationDialog 
				header="Informação" 
				message="A data não pode ser nula. Por favor informe uma data."
			/>);
		}

		query.push(`inicio=${moment(params.inicio).startOf('month').format("YYYY-MM-DD")}`);
		query.push(`fim=${moment(params.fim).endOf('month').format("YYYY-MM-DD")}`);
		
		setLoading("Procurar");
		toast.promise(
			comissaoFechamentoService.listar(query).then((value) => {
				setValue(value);
			}).then(() => setLoading(0)),
			{
				"pending": `Procurando fechamento. Aguarde...`,
				"error": {
					render(e) {
						setLoading(0);
						return `Falha ao procurar fechamento: ${e.data?.response?.data}`;
					}
				}
			}
		);
	}, [params, setValue, showDialog])

    const handleCriar = useCallback(() => {
		navigate("/comercial/comissao/fechamento/new");
	}, [navigate])

    return (
        <Panel headerTemplate={() => headerTemplate('Fechamento de Comissão', 'fa-lock')}>
            <PanelContent>
				<Calendar 
					label="Período de fechamento" 
					col={2} 
					name="inicio" 
					value={params.inicio} 
					onChange={handleChange}
					view="month"
					dateFormat="mm/yy"
					showButtonBar={false}
				/>
				<Calendar 
					label="&nbsp;"
					col={2} 
					name="fim" 
					value={params.fim} 
					onChange={handleChange}
					view="month"
					dateFormat="mm/yy"
					showButtonBar={false}
				/>
            </PanelContent>
            <PanelFooter>
                <Button 
                    info 
                    loading={loading === "Procurar"}
                    onClick={handleProcurar}
                    label={isLargeDevice ? "Procurar" : null}
                    icon="pi pi-search"
                    autowidth={!isLargeDevice}
                />
            </PanelFooter>
        </Panel>
    );

}));