import React, { useEffect, useState } from "react";

import { Column } from "primereact/column";

import { DataTable } from "../../../../components/datatable";
import { agrupar, calcularComissaoUnica, desagruparDados, encontrarChaveParaObjeto, manterChavesExclusivas } from "./utils";
import { TabelaAcompanhamentoExpandidaIndividual } from "./TabelaAcompanhamentoExpandidaIndividual";

export const TabelaAcompanhamentoIndividual = React.memo(({acompanhamento, tiposComissao, setAcompanhamento}) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [loadingRow, setLoadingRow] = useState(false);
    
    const expandAll = () => {
        let _expandedRows = {};
    
        acompanhamento.forEach((p) => (_expandedRows[`${p.vendedorCodigo}`] = true));
    
        setExpandedRows(_expandedRows);
    };

    useEffect(() => {
        expandAll() 
    }, [acompanhamento])
    const handleChange = async (onChangeValue, dataTableRowData, dataTableRowInfos) => {
        setLoadingRow(true);
        

        let valorCompleto;
        let tipoComissaoCompleto;
        
        if(onChangeValue.name === 'tipoComissao'){
            tipoComissaoCompleto = tiposComissao.find(tipoComissao => tipoComissao.id === onChangeValue.value);
            valorCompleto = tipoComissaoCompleto;
        }else{
            tipoComissaoCompleto = tiposComissao.find(tipoComissao => tipoComissao.id === dataTableRowData?.tipoComissao?.id);
        }

        const alteracoes = acompanhamento.map(vendedor => {

            if (vendedor.vendedorCodigo !== dataTableRowData.vendedor?.codigo) {
                return vendedor;
            }

            const acompanhamentoCopia = { ...vendedor };

            const chaveEncontrada = encontrarChaveParaObjeto(dataTableRowData, vendedor.tiposComissao);

            if (!chaveEncontrada || !vendedor.tiposComissao[chaveEncontrada]) {
                return vendedor;
            }

            const tiposComissaoCopia = { ...vendedor.tiposComissao[chaveEncontrada] };
            const dadosCopia = tiposComissaoCopia.dados?.map((nota, index) => {
                if (nota.id === dataTableRowData.id) {
                    let alteracaoNota;

                    if(onChangeValue.name === 'valorBaseCalculoComissao' || 
                        onChangeValue.name === 'percentualComissao' ||
                        (onChangeValue.name === 'tipoComissao' && tipoComissaoCompleto?.tipo === 'UNICA')
                    ){
                        const notaAtualizada = calcularComissaoUnica(nota,  onChangeValue.name,  onChangeValue.value, tipoComissaoCompleto);
                        alteracaoNota = {
                            ...notaAtualizada,
                            modificado: true,
                        }
                    }

                    if(!alteracaoNota){
                        alteracaoNota = {
                            ...nota,
                            modificado: true,
                            [onChangeValue.name]: valorCompleto || onChangeValue.value,
                        };
                    }

                    
                    return alteracaoNota;
                } else {
                    return nota;
                }
            });

            const tiposComissaoAlterados = {
                ...tiposComissaoCopia,
                dados: dadosCopia,
            };
            const vendedorAlterado = {
                ...acompanhamentoCopia,
                tiposComissao: {
                    ...acompanhamentoCopia.tiposComissao,
                    [chaveEncontrada]: tiposComissaoAlterados,
                },
            };
            return vendedorAlterado;
        });

        const desagrupar = desagruparDados(alteracoes);
        const reagrupar = agrupar(desagrupar);
        setAcompanhamento(reagrupar);
        setLoadingRow(false);
    }
    
    const rowExpansionTemplate = (data) => (
        <TabelaAcompanhamentoExpandidaIndividual 
            data={data} 
            handleChange={handleChange} 
            todosTiposComissaoCompletos={tiposComissao}
            loadingRow={loadingRow}
        />
    )


    return (
        <DataTable
            value={acompanhamento}
            valuelength={acompanhamento?.length}
            expandedRows={expandedRows} 
            onRowToggle={(e) => {             
                const expandRow = manterChavesExclusivas(e.data, expandedRows)                
                setExpandedRows(expandRow)
            }}
            paginator={false}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="vendedorCodigo"
        >
            <Column expander style={{ width: '5rem' }} />
            <Column 
                sortable 
                field="vendedorNome" 
                header="Consultor de Vendas" 
                style={{textAlign: "left", width: "*"}}
                body={(data) => (
                    <div className="font-bold">
                        {data.vendedorNome}
                    </div>
                )}
            />
        </DataTable>
    );
});