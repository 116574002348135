import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {buildValidator, isEmpty} from '../../../utils/fieldValidator';
import {TabPanel} from 'primereact/tabview';
import {PanelContent} from '../../../components/panel';
import {EditarDadosPrincipais} from './EditarDadosPrincipais';
import {FormTab} from '../../../components/formtab';
import {toast} from 'react-toastify';
import {listaPrecoService} from '../services/listaPrecoService';
import {useAuth} from "../../../context/AuthContext";
import { InformationDialog } from '../../../components/dialog/InformationDialog';
import { withDialog } from '../../../utils/dialogContext';
import { EditarListaPrecoClientes } from './EditarListaPrecoClientes';

export const EditarListaPreco = withDialog(({showDialog}) => {

	const {id} = useParams();	
	const {roles} = useAuth();
	const [listaPreco, setListaPreco] = useState(listaPrecoService.criar());
	const [messages, setMessages] = useState({...buildValidator(), itens: []});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (id !== "new") {
			listaPrecoService.buscar(id).then(setListaPreco);
		}
	}, [id]);

	function headerTemplate(e) {
		let style = {};

        switch (e.index) {
			case 0:
				if (messages.descricao || messages.itens.some(mi => !isEmpty(mi)) ) {
					style.color = "#F44336";
				}
				break;
			default:
				break;
		}

		return (
			<div className={`${e?.className}`} aria-controls={`${e.ariaControls}`} onClick={e.onClick} onKeyDown={e.onKeyDown} style={style}>
				<i className={`${e?.leftIconElement?.props?.className}`}/>
				<div className={`${e?.titleClassName}`} >{e?.titleElement?.props?.children}</div>
			</div>
		);
	}
	const handleSalvar = useCallback(() => {
        const messages = listaPrecoService.validar(listaPreco);		
        if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;
        }
		const produtosDuplicados = listaPrecoService.validarDuplicidade(listaPreco);
		if (produtosDuplicados && produtosDuplicados.size > 0) {
			const produtos = Array.from(produtosDuplicados).join(", ");
			showDialog(<InformationDialog header="Informação" message={"A lista contém os seguintes produtos duplicados. " + produtos}  />);
			return;
		}
        setLoading(true);
		const itens = listaPreco.itens.filter(lpi => lpi.alterado);
		console.log('alterados',itens);
        toast.promise(
			listaPrecoService.salvar({...listaPreco, itens}).then(async () => {
				await listaPrecoService.buscar(listaPreco.id).then(setListaPreco);
			}).finally(() => setLoading(false)),
            {
                "pending": `Salvando lista de preço. Aguarde...`,
                "success": `Lista de preço salva com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar lista de preço`;
                    }
                }
            }
        );
    }, [listaPreco, showDialog]);
	
	return (
		<FormTab
			podeDeletar={false}
			podeSalvar={!!roles.LTPC}
			descricao={"Lista de Preço"}
			service={listaPrecoService}
			value={listaPreco}
			salvar={handleSalvar}
			blocked={(id !== "new" && !listaPreco.id) || loading}
			onValidate={setMessages}
			carregando={loading}
		>
			<TabPanel header="Dados Principais" leftIcon='fa-solid fa-file-invoice-dollar' headerTemplate={headerTemplate} invalid={messages?.dadosPrincipais?.some(element => element)}> 
                <PanelContent>
					<EditarDadosPrincipais onSalvar={handleSalvar} listaPreco={listaPreco} setListaPreco={setListaPreco} messages={messages} setMessages={setMessages} setLoading={setLoading}/>
                </PanelContent>
            </TabPanel>
			<TabPanel header="Clientes" leftIcon='fa-solid fa-user-tie' headerTemplate={headerTemplate} invalid={messages?.dadosPrincipais?.some(element => element)}> 
				<PanelContent>
					<EditarListaPrecoClientes listaPreco={listaPreco} setListaPreco={setListaPreco} messages={messages} setMessages={setMessages} setLoading={setLoading}/>
				</PanelContent>
			</TabPanel>
		</FormTab>
	);

});
