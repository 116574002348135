import React, {useState} from 'react';
import {AutoComplete} from '.';
import {seloIpemService} from '../../service/seloIpemService';
import {useAuth} from "../../context/AuthContext";

export function SeloIpemAutoComplete(props) {

	const [selosIpem, setSelosIpem] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("");
	const {usuario, roles} = useAuth();

	function completeMethod(event) {
		const params = [
			"status=LIVRE",
			`numero=${encodeURIComponent(event.query)}`
		];
		if (!roles.SIPE) {
			params.push(`responsavel=${usuario.id}`);
		}
		seloIpemService.listar(params).then(selosIpem => {
			setSelosIpem(selosIpem);
			if (!selosIpem.length) {
				setEmptyMessage("Nenhum registro encontrado");
			} else {
				setEmptyMessage("");
			}
		});
	}

	function handleBlur() {
		if (!props.multiple && !props.value?.id) {
			props.onChange({name: props.name, index: props.index, value: null});
		}
	}

	return (
		<AutoComplete
			placeholder="número"
			label="SeloIpem"
			name="seloIpem"
			value={props.value}
			onChange={props.onChange}
			completeMethod={completeMethod}
			suggestions={selosIpem}
			invalid={emptyMessage}
			icon="fa-solid fa-tag"
			onBlur={handleBlur}
			{...props}
		/>
	);

}
