import React, {useCallback, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useBreakpoint } from "../../../../context/BreakpointContext";
import { useAuth } from "../../../../context/AuthContext";
import { toast } from "react-toastify";
import { Panel } from "primereact/panel";
import { headerTemplate } from "../../../../utils/templates";
import { PanelContent, PanelFooter } from "../../../../components/panel";
import { Button } from "../../../../components/button";
import { InputText } from "../../../../components/inputtext";
import { tipoComissaoService } from "../../services/tipoComissaoService";

export const FiltrosTipoComissao = React.memo(({setValue}) => {
    const navigate = useNavigate();

    const {breakpoint} = useBreakpoint()
    const isLargeDevice = breakpoint === 'lg' || breakpoint === 'md'

    const [loading, setLoading] = useState(false);

    const [params, setParams] = useState({
		descricao: null,
	});

    const {roles} = useAuth();

    const handleChange = useCallback((event) => {
		setParams({...params, [event.name]: event.value});
	}, [params])

    function handleCriar() {
        navigate("/comercial/comissao/tipoComissao/new");
    }

   
	const handleProcurar = useCallback(() => {
		const query = [];

		if (params?.descricao?.length) query.push(`descricao=${params.descricao}`);
		setLoading(true);

		toast.promise(
			tipoComissaoService.listar([`query=${query.join(";")}`]).then(setValue).then(() => setLoading(false)),
			{
				"pending": `Procurando tipos de comissão. Aguarde...`,
				"error": {
					render(e) {
						setLoading(false);
						return `Falha ao procurar tipos de comissão: ${e.data?.response?.data}`;
					}
				}
			}
		);
	}, [params.descricao, setValue])

    return (
        <Panel headerTemplate={() => headerTemplate('Tipo de Comissao', 'fa-percentage')}>
            <PanelContent>
                    <InputText
                        col={12}
                        value={params.descricao}
                        name='descricao'
                        onChange={handleChange}
                        label='Descrição'
                    />
            </PanelContent>
            <PanelFooter>
                {true && 
                    <Button 
                        success
                        onClick={handleCriar} 
                        label={isLargeDevice ? "Novo" : null} 
                        icon="pi pi-plus"
                        autowidth={!isLargeDevice}
                    />
                }
                <Button 
                    info 
                    disabled={loading}
                    loading={loading} 
                    onClick={() => handleProcurar(params)} 
                    label={isLargeDevice ? "Procurar" : null}
                    icon="pi pi-search"
                    autowidth={!isLargeDevice}
                />
            </PanelFooter>
        </Panel>
    );
});