import React, {useState} from 'react';

import {AutoComplete} from '.';
import {usuarioService} from '../../service/usuarioService';
import {useAuth} from "../../context/AuthContext";

export function UsuarioAutoComplete(props) {

    const {usuario} = useAuth();
    const [usuarios, setUsuarios] = useState([]);
    const [emptyMessage, setEmptyMessage] = useState("");

    function completeMethod(event) {
        const query = [
            "status==ATIVO",
            props.tipo ? `tipo==${props.tipo}` : "tipo==NORMAL"
        ];
        if (event.query.length) {
            query.push(`nome,email=lk=${encodeURIComponent(event.query)}`);
        }
        if (props.gestor) {
            query.push(`gestores.id==${usuario.id}`);
        }
        usuarioService[props.dummy ? "listarDummy" : "listar"]([`query=${query.join(";")}`]).then(res => {
            setUsuarios(res);
            if (!res[0]) {
                setEmptyMessage("Nenhum registro encontrado");
            } else {
                setEmptyMessage("");
            }
        });
    }

    return (
        <AutoComplete col={3} placeholder="email" label="Usuario" icon="fa-solid fa-user" name="usuario"
                      value={props.value} onChange={props.onChange} completeMethod={completeMethod}
                      suggestions={usuarios} invalid={emptyMessage} {...props}/>
    );

}
