import React, {useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {Button} from "../../../components/button";
import {InputText} from "../../../components/inputtext";
import {ClienteAutoComplete} from "../../../components/autocomplete/ClienteAutoComplete";
import {DataTable} from "../../../components/datatable";
import {Column} from "primereact/column";
import {TabPanel, TabView} from "primereact/tabview";
import {useNavigate, useParams} from "react-router-dom";
import {contratoService} from "../../../service/contratoService";
import {BlockUI} from "primereact/blockui";
import {ConfirmDialogV2} from "../../../components/confirmdialog";
import {useDialog} from "../../../utils/dialogContext";
import {Calendar} from "../../../components/calendar";
import {OrdemServicoProjetoEquipamentos} from "./OrdemServicoProjetoEquipamentos";
import {clienteService} from "../../../service/clienteService";
import {EquipamentoAutoComplete} from "../../../components/autocomplete/equipamentoAutoComplete";
import {equipamentoService} from "../../../service/equipamentoService";
import saveAs from "file-saver";
import {InformationDialog} from "../../../components/dialog/InformationDialog";
import {useAuth} from "../../../context/AuthContext";
import {InputNumber} from "../../../components/inputnumber";
import {AbrirOrdensServico} from "./AbrirOrdensServico";
import {ProgressDialog} from "../../../components/progressdialog/ProgressDialog";

function PrintStatus({cliente}) {
	if (cliente?._filial) {
		return <i className="fas fa-chain" title="Pertencente ao grupo do cliente principal"/>;
	}
	return <i className="fas fa-store" title="Adicionada manualmente"/>;
}

export function EditarProjeto() {

	const {roles} = useAuth();
	const {id} = useParams();
	const navigate = useNavigate();
	const {showDialog} = useDialog();
	const [blocked, setBlocked] = useState(false);
	const [filiais, setFiliais] = useState([]);
	const [refreshProgresso, setRefreshProgresso] = useState(0);
	const [projeto, setProjeto] = useState({
		...contratoService.criar(),
		status: "ATIVO",
		projeto: true,
		emissao: "INDEFINIDO",
		periodicidadeParcela: "INDEFINIDO",
		_alterado: false
	});
	const [clientesSelecionados, setClientesSelecionados] = useState([]);
	const [equipamentosSelecionados, setEquipamentosSelecionados] = useState([]);
	const [projetoItensSelecionados, setProjetoItensSelecionados] = useState([]);

	useEffect(() => {
		if (id !== "new") {
			setBlocked(true);
			contratoService.buscar(id).then(projeto => {
				setProjeto(projeto);
				setBlocked(false);
			});
		}
	}, [id]);

	useEffect(() => {
		if (projeto.cliente?.id) {
			clienteService.listar(["status=ATIVO", `matriz=${projeto.cliente.codigo || projeto.cliente.codigoOmega}`]).then(filiais => {
				filiais.forEach(f => f._filial = true);
				setFiliais(filiais);
			});
		} else {
			setFiliais([]);
		}
	}, [projeto.cliente?.id]);

	function handleChange(event) {
		setProjeto(prevProjeto => ({...prevProjeto, [event.name]: event.value, _alterado: true}));
	}

	async function handleSalvar() {
		setBlocked(true);
		projeto.equipamentos = projeto.equipamentos.filter(e => e.equipamento?.id);
		contratoService.salvar(projeto).then(handleVoltar);
	}

	function handleVoltar() {
		navigate(-1);
	}

	function handleChangeClientes(event) {
		setProjeto(prevProjeto => {
			prevProjeto.clientes[event.index] = event.value;
			return {...prevProjeto, _alterado: true};
		});
	}

	function adicionarCliente() {
		setProjeto(prevProjeto => {
			prevProjeto.clientes.push(null);
			return {...prevProjeto, _alterado: true};
		});
	}

	function removerClientes() {
		showDialog(<ConfirmDialogV2
			message="Tem certeza de que deseja remover os clientes selecionados?" onYes={() => {
			setProjeto(prevProjeto => {
				prevProjeto.clientes = prevProjeto.clientes.filter(c => c && !clientesSelecionados.some(cs => cs?.id === c?.id));
				return {...prevProjeto, _alterado: true};
			});
			setClientesSelecionados([]);
		}}
		/>);
	}

	function adicionarEquipamento() {
		setProjeto(prevProjeto => {
			prevProjeto.equipamentos.unshift({modoPeriodicidade: "CONTRATO", periodicidade: 1, equipamento: null});
			return {...prevProjeto, _alterado: true};
		});
	}

	function removerEquipamentos() {
		const filter = c => c && !equipamentosSelecionados.some(cs => cs?.id === c?.id);
		showDialog(<ConfirmDialogV2
			message="Tem certeza de que deseja remover os equipamentos selecionados?" onYes={() => {
			setProjeto(prevProjeto => {
				prevProjeto.equipamentos = prevProjeto.equipamentos.filter(filter);
				return {...prevProjeto, _alterado: true};
			});
			setEquipamentosSelecionados([]);
		}}
		/>);
	}

	const clienteControlSet = (
		<div>
			<Button icon="fas fa-plus" onClick={adicionarCliente}/>
			<Button
				icon="fas fa-minus"
				danger
				disabled={!clientesSelecionados.length || clientesSelecionados.some(cc => cc?._filial)}
				onClick={removerClientes}
			/>
		</div>
	);

	function gerarModeloCsvImportacao() {
		equipamentoService.gerarModeloCsvImportacao().then(data => {
			saveAs(new Blob([data], {type: "text/csv"}), "Importar-Equipamentos.csv");
		});
	}

	function importarEquipamentos() {
		const inputFile = document.createElement("input");
		inputFile.type = "file";
		inputFile.addEventListener("change", event => {
			const selectedFile = event.target.files[0];
			if (selectedFile) {
				const fileReader = new FileReader();
				fileReader.onloadend = event => {
					if (event.target.readyState === 2) {
						const linhas = event.target.result.split("\r\n").filter(l => l.trim().length);
						showDialog(
							<ConfirmDialogV2
								message={`Confirma a importação de ${linhas.length - 1} equipamentos do arquivo para o projeto?`}
								onYes={() => {
									const criterio = {
										cliente: projeto.cliente,
										linhas
									};
									showDialog(
										<ProgressDialog mode="indeterminate" onProgress={setProgress => {
											equipamentoService.importarEquipamentos(criterio).then(async resposta => {
												if (resposta.respostas?.length) {
													showDialog(
														<InformationDialog
															header="Aviso"
															message={(
																<div>
																	<div>
																		<b>Por favor, verifique as mensagens abaixo e tente novamente:</b>
																	</div>
																	{resposta.respostas.map((r, i) => <div key={i}>{r}</div>)}
																</div>
															)}
														/>
													);
													setProgress(1);
													return;
												}
												for (const equipamento of resposta.equipamentos) {
													if (!projeto.equipamentos.some(ec => ec.equipamento.id === equipamento.id)) {
														projeto.equipamentos.unshift({
															modoPeriodicidade: "CONTRATO",
															periodicidade: 1,
															equipamento
														});
													}
												}
												projeto.postBack = true;
												const p = await contratoService.salvar(projeto);
												setProgress(1);
												if (id === "new") {
													navigate(`/projetos/${p.id}`);
												} else {
													setProjeto({...p, _alterado: false});
												}
											});
										}}/>
									);
								}}
							/>
						)
					}
				}
				fileReader.readAsText(selectedFile);
			}
		});
		inputFile.click();
	}

	function abrirOrdensServico() {
		showDialog(
			<AbrirOrdensServico
				projeto={projeto}
				equipamentos={equipamentosSelecionados.map(e => e.equipamento)}
				onClose={() => setRefreshProgresso(prevValue => ++prevValue)}
			/>
		);
	}

	const equipamentoControlSet = (
		<div>
			<Button icon="fas fa-plus" onClick={adicionarEquipamento}/>
			<Button
				icon="fas fa-minus"
				danger
				disabled={!equipamentosSelecionados.length || equipamentosSelecionados.some(cc => cc?._filial)}
				onClick={removerEquipamentos}
			/>
			<Button icon="fas fa-download" warning label="Modelo de Importação" onClick={gerarModeloCsvImportacao}/>
			<Button icon="fas fa-upload" success label="Importar Equipamentos" onClick={importarEquipamentos}/>
			<Button
				icon="fas fa-star"
				danger
				disabled={!equipamentosSelecionados.length || equipamentosSelecionados.some(cc => cc?._filial)}
				label="Abrir Ordens de Serviço"
				onClick={abrirOrdensServico}
			/>
		</div>
	);

	function handleChangeEquipamento(event) {
		setProjeto(prevProjeto => {
			prevProjeto.equipamentos[event.index][event.name] = event.value;
			return {...prevProjeto, _alterado: true};
		});
	}

	function handleChangeProjetoItem(event) {
		setProjeto(prevProjeto => {
			prevProjeto.projetoItens[event.index][event.name] = event.value;
			return {...prevProjeto, _alterado: true};
		});
	}

	function adicionarProjetoItem() {
		setProjeto(prevProjeto => {
			prevProjeto.projetoItens.unshift({_key: Math.random(), quantidade: 0, descricao: ""});
			return {...prevProjeto, _alterado: true};
		});
	}

	function removerProjetoItens() {
		const filter = c => c && !projetoItensSelecionados.some(cs => (cs._key && cs._key === c._key) || (cs?.id === c?.id));
		showDialog(<ConfirmDialogV2
			message="Tem certeza de que deseja remover os itens de projeto selecionados?" onYes={() => {
			setProjeto(prevProjeto => {
				prevProjeto.projetoItens = prevProjeto.projetoItens.filter(filter);
				return {...prevProjeto, _alterado: true};
			});
			setProjetoItensSelecionados([]);
		}}
		/>);
	}

	const projetoItemControlSet = (
		<div>
			<Button icon="fas fa-plus" onClick={adicionarProjetoItem}/>
			<Button icon="fas fa-minus" disabled={!projetoItensSelecionados.length} danger onClick={removerProjetoItens}/>
		</div>
	);

	const tabs = [
		<TabPanel key={0} header="Dados Principais" leftIcon="fas fa-database">
			<PanelContent>
				<InputText label="Número" value={projeto.numero} readOnly col={2}/>
				<Calendar col={2} label="Início" value={projeto.inicio} name="inicio" onChange={handleChange}/>
				<Calendar col={2} label="Fim" value={projeto.fim} name="fim" onChange={handleChange}/>
				<ClienteAutoComplete
					offerNew={roles.CLIC}
					label="Cliente"
					value={projeto.cliente}
					col={6}
					name="cliente"
					onChange={handleChange}
				/>
				<InputText label="Descrição" value={projeto.objeto} col={12} name="objeto" onChange={handleChange}/>
				<div className="col-12">
					<label>Quantidade de Equipamentos</label>
					<DataTable
						paginator={false}
						className="no-space-table"
						value={projeto.projetoItens}
						header={projetoItemControlSet}
						footer={projetoItemControlSet}
						selection={projetoItensSelecionados}
						onSelectionChange={e => setProjetoItensSelecionados(e.value)}
						selectionMode="checkbox"
					>
						<Column selectionMode="multiple" style={{width: "3em"}}/>
						<Column
							style={{width: "10em"}}
							header="Quantidade"
							field="quantidade"
							body={(pi, c) => <InputNumber
								col={12}
								reduced
								name="quantidade"
								value={pi.quantidade}
								index={c.rowIndex}
								onChange={handleChangeProjetoItem}
							/>}
						/>
						<Column
							header="Descrição (Modelo do Equipamento)"
							field="descricao"
							body={(pi, c) => <InputText
								col={12}
								reduced
								name="descricao"
								value={pi.descricao}
								index={c.rowIndex}
								onChange={handleChangeProjetoItem}
							/>}
						/>
					</DataTable>
				</div>
				<InputText
					label="Observações"
					name="observacoes"
					onChange={handleChange}
					col={12}
					multiline
					style={{height: "20em"}}
					value={projeto.observacoes}
				/>
			</PanelContent>
		</TabPanel>,
		<TabPanel key={3} header="Unidades" leftIcon="fas fa-users">
			<DataTable
				rows={10}
				className="no-space-table"
				header={clienteControlSet}
				footer={clienteControlSet}
				selection={clientesSelecionados}
				onSelectionChange={e => setClientesSelecionados(e.value)}
				selectionMode="checkbox"
				value={[...projeto.clientes, ...filiais]}
			>
				<Column style={{width: "3em", textAlign: "center"}} selectionMode="multiple"/>
				<Column style={{width: "3em", textAlign: "center"}} body={c => <PrintStatus cliente={c}/>}/>
				<Column
					field="cliente"
					body={(c, i) => <ClienteAutoComplete
						offerNew
						matriz={projeto.cliente}
						disabled={c?._filial}
						reduced
						label={null}
						col={12}
						index={i.rowIndex}
						name="clientes"
						value={c}
						onChange={handleChangeClientes}
					/>}
				/>
			</DataTable>
		</TabPanel>,
		<TabPanel disabled={!projeto.cliente?.id} key={4} header="Equipamentos" leftIcon="fas fa-computer">
			<DataTable
				rows={10}
				className="no-space-table"
				header={equipamentoControlSet}
				footer={equipamentoControlSet}
				selection={equipamentosSelecionados}
				onSelectionChange={e => setEquipamentosSelecionados(e.value)}
				selectionMode="checkbox"
				emptyMessage="Nenhum equipamento adicionado"
				value={projeto.equipamentos}
			>
				<Column style={{width: "3em", textAlign: "center"}} selectionMode="multiple"/>
				<Column
					header="Equipamento"
					field="autoComplete"
					body={(e, c) => <EquipamentoAutoComplete
						reduced
						label={null}
						col={12}
						index={c.rowIndex}
						name="equipamento"
						value={e.equipamento}
						onChange={handleChangeEquipamento}
					/>}
				/>
				<Column header="Cliente" field="equipamento.cliente.autoComplete"/>
			</DataTable>
		</TabPanel>
	];

	if (projeto.id) {
		tabs.push(
			<TabPanel key={2} header="Progresso" leftIcon="fas fa-calendar">
				<PanelContent>
					<OrdemServicoProjetoEquipamentos refresh={refreshProgresso} projeto={projeto}/>
				</PanelContent>
			</TabPanel>
		);
	}

	return (
		<BlockUI blocked={blocked}>
			<Panel header={`Projeto${projeto.numero ? ` nº ${projeto.numero}` : ""}`}>
				<TabView>
					{tabs}
				</TabView>
				<PanelFooter>
					<Button
						disabled={blocked || !projeto._alterado}
						icon="fas fa-save"
						success
						label="Salvar"
						onClick={handleSalvar}
					/>
					<Button icon="fas fa-arrow-left" secondary label="Voltar" onClick={handleVoltar}/>
				</PanelFooter>
			</Panel>
		</BlockUI>
	);

}
