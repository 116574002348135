import React, { useCallback, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { TabPanel } from "primereact/tabview";
import { toast } from "react-toastify";

import { EditarDadosPrincipais } from "./EditarDadosPrincipais";
import { useAuth } from "../../context/AuthContext";
import { empresaService } from "../../service/empresaService";
import { FormTab } from "../../components/formtab";
import { PanelContent } from "../../components/panel";
import { buildValidator } from "../../utils/fieldValidator";
import { arquivoService } from "../../service/arquivoService";
import { EditarEmpresaFiliais } from "./EditarEmpresaFiliais";
import { EditarEmpresaUsuario } from "./EditarEmpresaUsuarios";
import { EditarEmpresaContratos } from "./EditarEmpresaContratos";
import { contratoService } from "../../service/contratoService";
import { usuarioService } from "../../service/usuarioService";

export function EditarEmpresa() {
  let { id } = useParams();
  const { roles } = useAuth();
  const navigate = useNavigate();

  //fields
  const [empresa, setEmpresa] = useState(empresaService.criar);
  const [filiais, setFiliais] = useState([]);
  const [contratos, setContratos] = useState([]);
  const [messages, setMessages] = useState(buildValidator());
  const [loading, setLoading] = useState(false);
  const [usuarios, setUsuarios] = useState([]);

  //useEffects
  useEffect(() => {
    if (!id ||id === "new") return;

    empresaService.buscar(id).then((empresa) => {
      if (!empresa) return;
      setEmpresa(empresa);
    });
  }, [id, setEmpresa]);

  const atualizarFiliais = useCallback(() => {
    setLoading(true);
    empresaService
      .listarFiliais(empresa.id)
      .then((filiaisExistentes) =>
        setFiliais(filiaisExistentes)
      )
      .finally(() => setLoading(false));
  }, [empresa.id])

  useEffect(() => {
    if (!id ||id === "new" || !empresa.id) return;
    atualizarFiliais()
  }, [atualizarFiliais, empresa.id, id, setFiliais]);

  useEffect(() => {
    if (!id ||id === "new" || !empresa.id) return;

    setLoading(true);
      contratoService
        .listarContratos({
          cliente: empresa.cliente,
          ativo: true,
          suporte: true,
        })
        .then((res) => setContratos(res))
        .finally(() => setLoading(false));
    }, [empresa.cliente, empresa.id, id, setContratos]);


  const atualizarUsuarios = useCallback(() => {
    setLoading(true);
    usuarioService
      .listarUsuariosPorEmpresa(empresa.id)
      .then(setUsuarios)
      .finally(() => setLoading(false));
  }, [empresa.id])

  useEffect(() => {
    if (!id ||id === "new" || !empresa.id) return;
    
    atualizarUsuarios();
  }, [atualizarUsuarios, empresa.id, id]);
  
  

  async function handleSalvar() {
    let salvarEmpresa = { ...empresa };

    const messages = empresaService.validar(empresa);
    if (!messages.isEmpty()) {
      setMessages(messages);
      return;
    }

    if (!!empresa.logotipo && empresa.logotipo.conteudo) {
      salvarEmpresa.logotipo.id = (
        await arquivoService.salvar({ ...empresa.logotipo, postBack: true })
      ).id;
    }

    setLoading(true);

    toast.promise(
      empresaService
        .salvar(salvarEmpresa)
        .then((value) => {
          navigate(-1);
        })
        .finally(() => setLoading(false)),
      {
        pending: `Salvando ${empresa.razaoSocial}. Aguarde...`,
        success: `${empresa.razaoSocial} salvo com sucesso!`,
        error: {
          render(e) {
            setLoading(false);
            return `Falha ao salvar ${empresa.razaoSocial}: ${e.data?.response?.data}`;
          },
        },
      }
    );
  }

  return (
    <FormTab
      descricao="Empresa"
      service={empresaService}
      value={empresa}
      blocked={(id !== "new" && !empresa.id) || loading}
      onValidate={setMessages}
      carregando={loading}
      salvar={handleSalvar}
      podeDeletar={!!roles.EMPD}
      podeSalvar={!!roles.EMPC && !!empresa?.cliente?.id}
    >
      <TabPanel header="Dados Principais" leftIcon="fa-solid fa-database">
        <PanelContent>
          <EditarDadosPrincipais
            empresa={empresa}
            setEmpresa={setEmpresa}
            messages={messages}
            setMessages={setMessages}
          />
        </PanelContent>
      </TabPanel>
      <TabPanel
        header="Contratos"
        leftIcon="fa-solid fa-file-signature"
        disabled={empresa?.tipoEmpresa === "FILIAL" || !empresa.id}
      >
        <PanelContent>
          <EditarEmpresaContratos
            empresa={empresa}
            setEmpresa={setEmpresa}
            contratos={contratos}
            setContratos={setContratos}
            setLoading={setLoading}
          />
        </PanelContent>
      </TabPanel>
      <TabPanel
        header="Filiais"
        leftIcon="fa-solid fa-building"
        disabled={empresa?.tipoEmpresa === "FILIAL" || !empresa.id}
      >
        <PanelContent>
          <EditarEmpresaFiliais
            empresa={empresa}
            setEmpresa={setEmpresa}
            messages={messages}
            setMessages={setMessages}
            filiais={filiais}
            setFiliais={setFiliais}
            setLoading={setLoading}
            atualizarFiliais={atualizarFiliais}
          />
        </PanelContent>
      </TabPanel>
      <TabPanel
        header="Usuários"
        leftIcon="fa-solid fa-user"
        disabled={!empresa?.acessoPortal || !empresa.id}
      >
        <PanelContent>
          <EditarEmpresaUsuario
            empresa={empresa}
            usuarios={usuarios}
            setUsuarios={setUsuarios}
            atualizarUsuarios={atualizarUsuarios}
            setLoading={setLoading}
            loading={loading}
            filiais={filiais}
          />
        </PanelContent>
      </TabPanel>
    </FormTab>
  );
}
