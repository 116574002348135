import React, {useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Fieldset} from "primereact/fieldset";
import {InputText} from "../../components/inputtext";
import moment from "moment";
import {Signature} from "../../components/signature";
import {Button} from "../../components/button";
import {arquivoService} from "../../service/arquivoService";
import {ordemServicoService} from "../../service/ordemServicoService";
import {withDialog} from "../../utils/dialogContext";
import {BlockUI} from "primereact/blockui";
import { InformationDialog } from "../../components/dialog/InformationDialog";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";

export const EditarLaudo = withDialog(({ordemServico, setOrdemServico, laudo, setLaudo, showDialog}) => {

	const [blocked, setBlocked] = useState(false);
	const [newLaudo, setNewLaudo] = useState(laudo);

	function handleChangeAssinatura(event) {
		if (!newLaudo.assinatura) {
			newLaudo.assinatura = {
				fileName: `Assinatura-${moment().format("YYYY-MM-DD HH:mm:ss")}`,
				contentType: "image/png",
				conteudo: {
					conteudo: ""
				}
			};
		}
		const {assinatura} = newLaudo;
		if (!event.value) {
			assinatura.id = null;
			assinatura.conteudo = {
				conteudo: ""
			}
		} else {
			assinatura.conteudo[event.name] = event.value;
		}
		setNewLaudo({...newLaudo});
	}

	function salvarLaudo() {
		setBlocked(true);
		if (newLaudo.assinatura) {
			arquivoService.salvar({...newLaudo.assinatura, postBack: true}).then(assinatura => {
				newLaudo.assinatura = assinatura;
				ordemServicoService.salvarLaudo({ordemServico, laudo: newLaudo}).then(laudo => {
					ordemServico.laudos.unshift(laudo);
					setOrdemServico({...ordemServico});
					setLaudo(null);
					setBlocked(false);
					showDialog(<InformationDialog message="Laudo gerado com sucesso!"/>);
				});
			});
		} else {
			ordemServicoService.salvarLaudo({ordemServico, laudo: newLaudo}).then(laudo => {
				ordemServico.laudos.unshift(laudo);
				setOrdemServico({...ordemServico});
				setLaudo(null);
				setBlocked(false);
				showDialog(<InformationDialog message="Laudo gerado com sucesso!"/>);
			});
		}
	}

	function voltarLaudo() {
		setLaudo(null);
	}

	return (
		<BlockUI blocked={blocked}>
			<Panel header={`Laudo Técnico para a Ordem de Serviço ${ordemServico.numero || ""}`}>
				<Fieldset legend="Dados do Cliente">
					<PanelContent>
						<InputText readOnly label="Razão Social" col={6} value={ordemServico.cliente?.razaoSocial}/>
						<InputText readOnly label="Endereço" col={6} value={ordemServico.cliente?.vwCliente?.logradouro}/>
						<InputText readOnly label="Bairro" col={4} value={ordemServico.cliente?.vwCliente?.bairro}/>
						<InputText readOnly label="Cidade" col={4} value={ordemServico.cliente?.vwCliente?.municipio}/>
						<InputText readOnly label="CNPJ" col={4} value={ordemServico.cliente?.vwCliente?.cpfCnpjFormatado}/>
						<InputText readOnly label="Inscrição Estadual" col={4} value={ordemServico.cliente?.vwCliente?.inscricaoEstadual}/>
						<InputText readOnly label="Contato" col={4} value={ordemServico.cliente?.contato}/>
						<InputText readOnly label="E-mail" col={4} value={ordemServico.cliente?.vwCliente?.email}/>
					</PanelContent>
				</Fieldset>
				<div style={{height: "1.5em"}}/>
				<Fieldset legend="Descrição dos Instrumentos">
					<DataTable paginator={false} rows={null} footer={null} value={ordemServico.itens}>
						<Column style={{width: "7em"}} header="Item" field="sequencia"/>
						<Column header="Código" field="equipamento.produto.codigo"/>
						<Column header="Produto" field="equipamento.produto.descricao"/>
						<Column header="Nº de Série" field="equipamento.serial"/>
						<Column header="Selo IPEM" field="seloIpem.numero"/>
						<Column header="Lacres Retirados" field="lacresRetirados"/>
						<Column header="Lacres Afixados" field="lacresAfixados"/>
					</DataTable>
				</Fieldset>
				<div style={{height: "1.5em"}}/>
				<Fieldset legend="Discriminação dos Itens e Serviços">
					<DataTable paginator={false} rows={null} footer={null} value={ordemServico.itens}>
						<Column style={{width: "7em"}} header="Item" field="sequencia"/>
						<Column header="Peças" field="pecas" body={osi => !osi.pecas?.length ? <i>Nada registrado</i> : osi.pecas.map(osip => <div className="col-12">{osip.quantidade} × {osip.produto?.autoComplete}</div>)}/>
						<Column header="Serviços" field="servicos" body={osi => !osi.servicos?.length ? <i>Nada registrado</i> : osi.servicos.map(osip => <div className="col-12">{osip.servico?.autoComplete}</div>)}/>
					</DataTable>
				</Fieldset>
				<div style={{height: "1.5em"}}/>
				<Fieldset legend="Observações">
					<DataTable paginator={false} rows={null} footer={null} value={ordemServico.itens}>
						<Column style={{width: "7em"}} header="Item" field="sequencia"/>
						<Column header="Observações" field="laudoExterno" body={osi => osi.laudoExterno || <i>Nada registrado</i>}/>
					</DataTable>
				</Fieldset>
				<div style={{height: "1.5em"}}/>
				<Fieldset legend="Responsável Técnico">
					<PanelContent>
						<InputText readOnly label="Nome" col={5} value={newLaudo.responsavel?.nome}/>
						<InputText readOnly label="RG" col={2} value={newLaudo.responsavel?.rg}/>
						<InputText readOnly label="CPF" col={3} value={newLaudo.responsavel?.cpf}/>
						<InputText readOnly label="Data" col={2} value={moment(newLaudo.data).format("DD/MM/YYYY")}/>
					</PanelContent>
				</Fieldset>
				<div style={{height: "1.5em"}}/>
				<Signature label="Assinatura" image={newLaudo.assinatura?.id} height={250} col={12} name="conteudo" onChange={handleChangeAssinatura} value={newLaudo.assinatura?.conteudo?.conteudo}/>
				<div className="col-12" style={{textAlign: "center"}}>{ordemServico?.responsavelInstrumentos}</div>
				<div style={{height: "1.5em"}}/>
				<PanelFooter>
					<Button label="Salvar" success icon="fa-solid fa-save" onClick={salvarLaudo}/>
					<Button label="Voltar" secondary icon="fa-solid fa-arrow-left" onClick={voltarLaudo}/>
				</PanelFooter>
			</Panel>
		</BlockUI>
	);

});
