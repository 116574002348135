import React, {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {Button} from "../../../components/button";
import {toast} from "react-toastify";
import { useAuth } from "../../../context/AuthContext";
import { headerTemplate } from "../../../utils/templates";
import { useBreakpoint } from "../../../context/BreakpointContext";
import { icmsService, optionsUf } from "../services/icmsService";
import { Dropdown } from "../../../components/dropdown";

export const FiltrosIcms = React.memo(({setValue}) => {

    const navigate = useNavigate();

    const {breakpoint} = useBreakpoint()
    const isLargeDevice = breakpoint === 'lg' || breakpoint === 'md'

    const [loading, setLoading] = useState(false);

    const [params, setParams] = useState({
		origem: null,
		destino: null
	});

    const {roles} = useAuth();

    const handleChange = useCallback((event) => {
		setParams({...params, [event.name]: event.value});
	}, [params])

    function handleCriar() {
        navigate("/icms/new");
    }
   
	const handleProcurar = useCallback(() => {

		const query = [];

		if (params.origem) query.push(`origem=${params.origem}`);
		if (params.destino) query.push(`destino=${params.destino}`);		

		setLoading(true);

		toast.promise(
			icmsService.listar(query).then(setValue).then(() => setLoading(false)),
			{
				"pending": `Procurando alíquotas de ICMS. Aguarde...`,
				"error": {
					render(e) {
						setLoading(false);
						return `Falha ao procurar alíquotas de ICMS: ${e.data?.response?.data}`;
					}
				}
			}
		);
	}, [params.destino, params.origem, setValue])

    return (
        <Panel headerTemplate={() => headerTemplate('Alíquotas de ICMS', 'fa-money-bill-transfer')}>
            <PanelContent>
                <Dropdown
                    options={optionsUf}
					value={params.origem}
					col={3} 
                    name="origem" 
                    label={"Origem"}
                    onChange={handleChange}
				/>
				<Dropdown
                    options={optionsUf}
					value={params.destino}
					col={3} 
                    name="destino" 
                    label={"Destino"}
                    onChange={handleChange}
				/>
            </PanelContent>
            <PanelFooter >
                {roles.PDVA && 
                    <Button 
                        success
                        onClick={handleCriar} 
                        label={isLargeDevice ? "Novo" : null} 
                        icon="pi pi-plus"
                        autowidth={!isLargeDevice}
                    />
                }
                <Button 
                    info 
                    disabled={loading}
                    loading={loading} 
                    onClick={() => handleProcurar(params)} 
                    label={isLargeDevice ? "Procurar" : null}
                    icon="pi pi-search"
                    autowidth={!isLargeDevice}
                />
            </PanelFooter>
        </Panel>
    );

});
