import React, { useCallback } from "react";
import { DataTable } from "../../components/datatable";
import { Column } from "primereact/column";
import { ConfirmDialogV2 } from "../../components/confirmdialog";
import { withDialog } from "../../utils/dialogContext";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Button } from "../../components/button";
import { clienteService } from "../../service/clienteService";
import { useAuth } from "../../context/AuthContext";
import { InputText } from "../../components/inputtext";
import { InputMask } from "../../components/inputmask";
import { isNotNull } from "../../utils/fieldValidator";

export const EditarContatos = withDialog(({ cliente, setCliente, messages, setMessages, showDialog }) => {
  
	const {usuario} = useAuth();
   
    const adicionar = () => {
        const contato = {
            ...clienteService.criarContato(cliente.eventos),
            usuario: usuario.email,
        }
        setCliente({...cliente, contatos: [...cliente.contatos, contato]})
    }

    const excluir = (data, rowData) => {
        showDialog(
            <ConfirmDialogV2 
                message="Tem certeza de que deseja remover o contato selecionados?" 
                onYes={() => {
	                const contatos = cliente.contatos.filter((item, posicao) => rowData.rowIndex !== posicao);
                    setCliente({...cliente, contatos});
                }}
            />
        );
    }
 
    const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={6} header={
					<div>
						<Button icon="pi pi-plus" onClick={adicionar} />
					</div>
				}/>
			</Row>
            <Row>
                <Column header='Nome' />
                <Column header="E-mail" />
                <Column header='Telefone 1' />
                <Column header='Telefone 2' />
                <Column header="Departamento"/>
                <Column header="Ações"/>
			</Row>
		</ColumnGroup>
	);

	const footerGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={6} footer={
					<div>
						<Button icon="pi pi-plus" onClick={adicionar} />
					</div>
				}/>
			</Row>
		</ColumnGroup>
	);

    const acoesTemplate = (data, rowData) => {  
        return (
            <div className='flex align-items-center justify-content-around'>
                <div className='p-2 cursor-pointer' title='Excluir contato' onClick={() => excluir(data, rowData)}> 
                    <i className='fa-solid fa-trash-can fa-lg text-color-secondary'/>
                </div>
            </div>
        )
    }

    const handleChange = useCallback((event) => {   
        
        const contatos = cliente.contatos.map((item, index) => {
            if (event.index === index) {
                item[event.name] = event.value;  
                return {...item} ;
            } else {
                return item;
            }
        });   
        setCliente({...cliente, contatos});
    }, [cliente, setCliente]);

    const  validarItem = (e, index, data) =>  {
        let erro;
        let contatos = [];        
		switch (e?.target.name) {
            
            case 'nome':
                erro = isNotNull(data.codigoTributario)
                contatos = [...messages.contatos]
                contatos[index] = {...messages.contatos[index], nome: erro}
                break;
            default:
                break;
        }
        setMessages((messages) => {
            return {
                ...messages,
                contatos
            }
        });
	}

    return (
        <div className='col-12 p-0'>
            <DataTable 
                value={cliente.contatos}
                emptyMessage='Nenhum contato encontrado.'
                valuelength={cliente?.contatos?.length}
                rows={100}
                paginator={false}
                headerColumnGroup={headerGroup}
                footerColumnGroup={footerGroup}
                size='small'
                selectionMode={null}
                responsiveLayout="scroll"
            >
                <Column sortable field="nome" header="Nome" name="nome" style={{ width: '*' }}  body={(osip, column) => (
                    <InputText reduced index={column.rowIndex} value={osip.nome} onChange={handleChange} name="nome" 
                    invalid={messages.contatos?.[column.rowIndex]?.nome}   onBlur={(e) => validarItem(e, column.rowIndex, osip)}/>
                )}/>
                <Column sortable field="email" name="email" header="E-mail"  style={{ width: '*' }} body={(osip, column) => (
                    <InputText reduced index={column.rowIndex} value={osip.email} onChange={handleChange} name="email"/>
                )}/>
                <Column sortable field="telefone1" header="Telefone 1" name="telefone1"  style={{ width: '*' }} body={(osip, column) => (
                    <InputMask name="telefone1"  label="&nbsp;" index={column.rowIndex} value={osip.telefone1} keyfilter="int" unmask mask={'(99) 99999-999?9' }
                    onChange={handleChange}
                />
                )}/>
                <Column sortable field="telefone2" header="Telefone 2" name="telefone2"  style={{ width: '*' }} body={(osip, column) => (
                    <InputMask name="telefone2"  label="&nbsp;" index={column.rowIndex} value={osip.telefone2} keyfilter="int" unmask mask={'(99) 99999-999?9' }
                    onChange={handleChange}
                />
                )}/>
                <Column sortable field="departamento" header="Departamento" name="departamento" style={{ width: '*' }} body={(osip, column) => (
                    <InputText reduced index={column.rowIndex} value={osip.departamento} onChange={handleChange} name="departamento"/>
                )}/>
                <Column 
                    key={'acoes'} 
                    body={acoesTemplate}
                    style={{width: '5em'}}
                />
            </DataTable>
    </div>
    );
});
