import React from "react";
import {InputText} from "../inputtext";

import "./CsvInput.scss";

export function CsvInput(props) {

	function handleChange(event) {
		if (props.onChange) {
			props.onChange({name: props.name, index: props.index, value: event.value.split(",").map(v => v.trim())});
		}
	}

	function handleKeyPress(event) {
		if (event.code === "Backspace" && props.value?.join(",").endsWith(",")) {
			const value = props.value;
			--value.length;
			event.preventDefault();
			event.stopPropagation();
			handleChange({name: props.name, index: props.index, value: value.join(", ")});
			return false;
		} else if (event.code === "Enter") {
			handleChange({name: props.name, index: props.index, value: [...props.value, ""].join(", ")});
		}
	}

	function addComma() {
		handleChange({name: props.name, index: props.index, value: [...props.value, ""].join(", ")});
	}

	return (
		<InputText {...props} value={props.value?.join(", ")} onKeyDown={handleKeyPress} onChange={handleChange}>
			<i className="csvinput-button fa-solid fa-level-down-alt" onClick={addComma} style={{transform: "rotate(90deg)"}}/>
		</InputText>
	);

}
