import React, {useEffect, useState} from 'react';

import {useParams} from "react-router-dom";

import { useAuth } from '../../context/AuthContext';
import {contaEmailService} from "../../service/contaEmailService";
import {FormEdit} from "../../components/form/Edit";
import {InputText} from "../../components/inputtext";
import { SelectButtonLabel } from '../../components/selectbuttonlabel';
import {buildValidator, isBiggerThanZero, isRequired} from "../../utils/fieldValidator";

export function EditarContaEmail() {

    const {id} = useParams();
    const {roles} = useAuth();

    const [contaEmail, setContaEmail] = useState(contaEmailService.criar());
    const [messages, setMessages] = useState(buildValidator());

    function handleChange(event) {
        setContaEmail({...contaEmail, [event.name]: event.value});
    }

    useEffect(() => {
        if (id !== "new") {
            contaEmailService.buscar(id).then(setContaEmail);
        }
    }, [id]);

    const options = [
        {icon: 'fa-solid fa-check', value: true},
        {icon: 'fa-solid fa-x', value: false},
    ];

    const itemTemplate = (option) => {
        return <i className={option.icon}></i>;
    }

    return (
        <FormEdit 
            podeDeletar={!!roles.EMLD}
            podeSalvar={!!roles.EMLC}
            descricao="Conta de e-mail"
            onValidate={setMessages}
            service={contaEmailService}
            value={contaEmail}
            blocked={id !== "new" && !contaEmail.id}
        >
            <InputText 
                required 
                onBlur={() => {
                    messages.descricao = isRequired(contaEmail.descricao);
                    setMessages({...messages});
                }} 
                invalid={messages.descricao} 
                value={contaEmail.descricao} 
                col={7} 
                label="Descrição" 
                name="descricao" 
                onChange={handleChange}
            />
            <InputText 
                required 
                onBlur={() => {
                    messages.nome = isRequired(contaEmail.nome);
                    setMessages({...messages});
                }} 
                invalid={messages.nome} 
                col={5} 
                label="Nome" 
                name="nome"
                value={contaEmail.nome}
                onChange={handleChange}
            />
            <InputText 
                required 
                onBlur={() => {
                    messages.usuario = isRequired(contaEmail.usuario);
                    setMessages({...messages});
                }} 
                invalid={messages.usuario} 
                col={4} 
                label="Usuário"
                name="usuario"
                value={contaEmail.usuario} 
                onChange={handleChange}
            />
            <InputText 
                required 
                onBlur={() => {
                    messages.senha = isRequired(contaEmail.senha);
                    setMessages({...messages});
                }} 
                invalid={messages.senha} 
                type="password" 
                col={2} 
                label="Senha" 
                name="senha" 
                value={contaEmail.senha} 
                onChange={handleChange}
            />
            <InputText 
                required 
                onBlur={() => {
                    messages.host = isRequired(contaEmail.host);
                    setMessages({...messages});
                }} 
                invalid={messages.host} 
                col={2} 
                label="Servidor" 
                name="host" 
                value={contaEmail.host}
                onChange={handleChange}
            />
            <InputText 
                required onBlur={() => { messages.porta = isBiggerThanZero(contaEmail.porta); 
                setMessages({...messages}); }} 
                invalid={messages.porta} 
                col={2} type="number" 
                label="Porta" 
                name="porta" 
                value={contaEmail.porta}
                onChange={handleChange} 
            />
            <SelectButtonLabel
                col={1}
                label="SSL" 
                options={options}
                value={contaEmail.ssl}
                onChange={handleChange}
                itemTemplate={itemTemplate}
                name='ssl'
            />
        </FormEdit>
    );
}                      