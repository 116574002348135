import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import {addLocale, locale} from "primereact/api";

export const AppConfiguracoesContext = createContext(null);

const AppConfiguracoesProvider= ({ children }) => {

    //tema
    const [theme, setTheme] = useState('lara-light-blue')
    const [layoutColorMode, setLayoutColorMode] = useState('light')

    //escala
    const [scale, setScale] = useState(12);
    const [scales] = useState([12,13,14,15,16]);

    const changeTheme = (theme, scheme) => {
        
        if(scheme === undefined){
            setLayoutColorMode('light')
        }else{
            setLayoutColorMode(scheme)
        }
        if(theme === undefined){
            setTheme('lara-light-blue')
        }else{
            setTheme(theme);
        }
    }
    
    addLocale('pt-BR', {
        firstDayOfWeek: 0,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        today: 'Hoje',
        clear: 'Limpar',
        closeText: 'Fechar',
        prevText: 'Anterior',
        nextText: 'Próximo',
        currentText: 'Começo',
        weekHeader: 'Semana',
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        timeOnlyTitle: 'Só Horas',
        timeText: 'Tempo',
        hourText: 'Hora',
        minuteText: 'Minuto',
        secondText: 'Segundo',
        ampm: false,
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        allDayText: 'Todo Dia',
    });
    
    locale('pt-BR');

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    const replaceLink = useCallback((linkElement, href, callback) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);

            if (callback) {
                callback();
            }
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) {
                    callback();
                }
            });
        }
    },[])

    useEffect(() => {
        let themeElement = document.getElementById('theme-link');
        const themeHref = 'assets/themes/' + theme + '/theme.css';
        replaceLink(themeElement, themeHref);
    },[theme,replaceLink])


 

    useEffect(() => {
        document.documentElement.style.fontSize = scale + 'px';
    }, [scale])

    const decrementScale = () => {
        setScale((prevState) => --prevState);
    }

    const incrementScale = () => {
        setScale((prevState) => ++prevState);
    }

    return (
        <AppConfiguracoesContext.Provider value={{
            setTheme,
            theme,
            scale,
            setScale,
            scales,
            decrementScale,
            incrementScale,
            layoutColorMode,
            setLayoutColorMode,
            changeTheme
        }}>
        {children}
        </AppConfiguracoesContext.Provider>
    );
};

function useAppConfiguracoes() {
  const context = useContext(AppConfiguracoesContext);

  if (!context) {
    throw new Error('UseAuth must be used within an AuthProvider');
  }

  return context;
}

export { AppConfiguracoesProvider, useAppConfiguracoes};

