import React, {useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {Button} from "../../components/button";

export function DialogHintOutras() {

    const [visible, setVisible] = useState(true);

    function handleClose() {
        setVisible(false);
    }

    return (
        <Dialog modal style={{width: "750px"}} visible={visible} onHide={handleClose} header="Outras">
            <PanelContent>
                <div className="col-12">
                    Esta coluna contabiliza ordens de serviço que não precisam de orçamento.<br/>
                    Existem duas condições onde isso acontece:<br/>
                </div>
                <div className="col-12">
                    <ol>
                        <li>O laudo ainda não foi concluído (a ordem de serviço está em recepção ou com laudo pendente de conclusão).</li>
                        <li>A ordem de serviço tem cobertura total de peças e serviços e não precisa de orçamento para continuar.</li>
                    </ol>
                </div>
            </PanelContent>
            <DialogFooter>
                <Button label="Fechar" secondary onClick={handleClose}/>
            </DialogFooter>
        </Dialog>
    );

}
