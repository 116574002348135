import React, {useState} from "react";
import {BlockUI} from "primereact/blockui";
import {PanelContent} from "../../components/panel";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import moment from "moment";
import {Button} from "../../components/button";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {ordemServicoService} from "../../service/ordemServicoService";
import {useAuth} from "../../context/AuthContext";
import {arquivoService} from "../../service/arquivoService";
import saveAs from "file-saver";
import {isStacked} from "../../context/BreakpointContext";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";

export function EditarOrdemServicoLaudo({laudos, setLaudo, ordemServico}) {

	const {usuario} = useAuth();
	const [blocked, setBlocked] = useState(false);

	async function gerarLaudo() {
		setBlocked(-1);
		if (["PREVENTIVA", "PROJETO"].includes(ordemServico.tipo)) {
			ordemServico.itens = await ordemServicoItemService.listarItensLaudo(ordemServico.id);
		}
		setLaudo({
			...ordemServicoService.criarLaudo(),
			ordemServico,
			registro: moment().format("YYYY-MM-DDTHH:mm:ss"),
			responsavel: usuario
		});
		setBlocked(0);
	}

	function baixarLaudo(index) {
		setBlocked(index + 1);
		arquivoService.baixar(laudos[index].laudo).then(data => {
			setBlocked(0);
			saveAs(new Blob([data], {type: laudos[index].laudo.contentType}), laudos[index].laudo.fileName);
		});
	}

	const laudoControlSet = (
		<div>
			<Button onClick={gerarLaudo} style={{width: "auto", whiteSpace: "nowrap"}} label="Gerar Laudo" icon="fa-solid fa-diagnoses"/>
		</div>
	);

	const laudoColumnGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={6} header={laudoControlSet}/>
			</Row>
			<Row>
				<Column header="Nº" style={{width: "13em"}}/>
				<Column header="Gerado Em" style={{width: "13em"}}/>
				<Column header="Responsável"/>
				<Column style={{textAlign: "center", width: "5em"}} header="Ações"/>
			</Row>
		</ColumnGroup>
	);

	const laudoFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={6} header={laudoControlSet}/>
			</Row>
		</ColumnGroup>
	);

	return (
		<BlockUI blocked={blocked === -1}>
			<PanelContent>
				<div className="col-12">
					<DataTable headerColumnGroup={laudoColumnGroup}
					           footerColumnGroup={laudoFooterGroup}
					           header={isStacked() ? null : laudoControlSet}
					           footer={isStacked() ? null : laudoControlSet}
					           value={laudos}
					>
						<Column header="Nº" style={{width: "13em"}} field="numero" body={osl => <div style={{textAlign: "right"}}>{osl.numero}</div>}/>
						<Column header="Data" style={{width: "13em"}} field="registro" body={osl => moment(osl.registro).format("DD/MM/YYYY HH:mm")}/>
						<Column header="Responsável" field="responsavel" body={osl => osl.responsavel?.nome}/>
						<Column header="Ações" style={{width: "5em", textAlign: "center"}} field="registro" body={(osl, c) => (
							<div>
								<Button loading={blocked === c.rowIndex + 1} icon="fa-solid fa-download" onClick={() => baixarLaudo(c.rowIndex)}/>
							</div>
						)}/>
					</DataTable>
				</div>
			</PanelContent>
		</BlockUI>
	);

}
