import React, {useCallback} from 'react';

import {InputTextERadioButton} from '../../components/inputgroup/inputTextERadioButton';
import {InputNumberERadioButton} from '../../components/inputgroup/inputNumberERadioButton';
import {isBiggerThanZero, isRequired} from '../../utils/fieldValidator';
import {InputNumber} from "../../components/inputnumber";

export function EditarParametros({contrato, setContrato, messages, setMessages}) {
    
    const handleChange = useCallback((event) => {
		setContrato((contratoAtualizado) => {
            return {...contratoAtualizado, contratoParametros: {...contratoAtualizado.contratoParametros, [event.name]: event.value}}
        });
	}, [setContrato])
    
    const handleCheckbox = (nameCheckbox, value) => {
        setMessages(messages => {
            return {...messages, [nameCheckbox]: null}
        });
		setContrato((contratoAtualizado) => { 
            return {...contratoAtualizado, contratoParametros: {...contratoAtualizado.contratoParametros, [nameCheckbox]: !value}}
        });
	}

    return (
        <>  
            <InputTextERadioButton
                col={12}
                label="Cobertura de mão de obra"
                value={contrato.contratoParametros?.cobeturaMODescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.cobeturaMO}
                setChecked={handleCheckbox}
                name='cobeturaMODescricao'
                nameCheckbox='cobeturaMO'
                invalid = {messages.cobeturaMO}
                required={contrato.contratoParametros?.cobeturaMO}
                onBlur={() => {
                    if(contrato.contratoParametros?.cobeturaMO){
                        messages.cobeturaMO = isRequired(contrato.contratoParametros?.cobeturaMODescricao);
                        setMessages({...messages});
                    }else{
                        messages.cobeturaMO = isRequired(contrato.contratoParametros?.cobeturaMODescricao);
                        setMessages({...messages});
                    }
                }}
            />

            <InputTextERadioButton
                col={12}
                label="Cobre mau uso"
                value={contrato.contratoParametros?.mauUsoDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.mauUso}
                setChecked={handleCheckbox}
                name='mauUsoDescricao'
                nameCheckbox='mauUso'
                invalid = {messages.mauUso}
                required={contrato.contratoParametros?.mauUso}
                onBlur={() => {
                    if(contrato.contratoParametros?.mauUso){
                        messages.mauUso = isRequired(contrato.contratoParametros?.mauUsoDescricao);
                        setMessages({...messages});
                    }else{
                        messages.mauUso = isRequired(contrato.contratoParametros?.mauUsoDescricao);
                        setMessages({...messages});
                    }
                }}
            />

            <InputTextERadioButton
                col={12}
                label="Baseline"
                value={contrato.contratoParametros?.baselineDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.baseline}
                setChecked={handleCheckbox}
                name='baselineDescricao'
                nameCheckbox='baseline'
                invalid = {messages.baseline}
                required={contrato.contratoParametros?.baseline}
                onBlur={() => {
                    if(contrato.contratoParametros?.baseline){
                        messages.baseline = isRequired(contrato.contratoParametros?.baselineDescricao);
                        setMessages({...messages});
                    }else{
                        messages.baseline = isRequired(contrato.contratoParametros?.baselineDescricao);
                        setMessages({...messages});
                    }
                }}
            />

            <InputTextERadioButton
                col={12}
                label="Recuperação de módulos"
                value={contrato.contratoParametros?.recuperacoModulosDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.recuperacoModulos}
                setChecked={handleCheckbox}
                name='recuperacoModulosDescricao'
                nameCheckbox='recuperacoModulos'
                invalid = {messages.recuperacoModulos}
                required={contrato.contratoParametros?.recuperacoModulos}
                onBlur={() => {
                    if(contrato.contratoParametros?.recuperacoModulos){
                        messages.recuperacoModulos = isRequired(contrato.contratoParametros?.recuperacoModulosDescricao);
                        setMessages({...messages});
                    }else{
                        messages.recuperacoModulos = isRequired(contrato.contratoParametros?.recuperacoModulosDescricao);
                        setMessages({...messages});
                    }
                }}
            />

            <InputTextERadioButton
                col={12}
                label="Backup em contrato"
                value={contrato.contratoParametros?.backupContratoDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.backupContrato}
                setChecked={handleCheckbox}
                name='backupContratoDescricao'
                nameCheckbox='backupContrato'
                invalid = {messages.backupContrato}
                required={contrato.contratoParametros?.backupContrato}
                onBlur={() => {
                    if(contrato.contratoParametros?.backupContrato){
                        messages.backupContrato = isRequired(contrato.contratoParametros?.backupContratoDescricao);
                        setMessages({...messages});
                    }else{
                        messages.backupContrato = isRequired(contrato.contratoParametros?.backupContratoDescricao);
                        setMessages({...messages});
                    }
                }}
            />

            <InputNumber reduced label="Quantidade de Backups Contratados" value={contrato.backupsContratados} name="backupsContratados" col={4}/>
            <InputNumber reduced readOnly label="Quantidade de Backups Enviados" value={contrato.backupsEnviados} col={4}/>
            <InputNumber reduced readOnly label="Quantidade de Backups Devolvidos" value={contrato.backupsDevolvidos} col={4}/>

            <InputTextERadioButton
                col={12}
                label="Backup com o cliente"
                value={contrato.contratoParametros?.backupClienteDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.backupCliente}
                setChecked={handleCheckbox}
                name='backupClienteDescricao'
                nameCheckbox='backupCliente'
                invalid = {messages.backupCliente}
                required={contrato.contratoParametros?.backupCliente}
                onBlur={() => {
                    if(contrato.contratoParametros?.backupCliente){
                        messages.backupCliente = isRequired(contrato.contratoParametros?.backupClienteDescricao);
                        setMessages({...messages});
                    }else{
                        messages.backupCliente = isRequired(contrato.contratoParametros?.backupClienteDescricao);
                        setMessages({...messages});
                    }
                }}
            />

            <InputTextERadioButton
                col={12}
                label="Preventiva"
                value={contrato.contratoParametros?.preventivaDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.preventiva}
                setChecked={handleCheckbox}
                name='preventivaDescricao'
                nameCheckbox='preventiva'
                invalid = {messages.preventiva}
                required={contrato.contratoParametros?.preventiva}
                onBlur={() => {
                    if(contrato.contratoParametros?.preventiva){
                        messages.preventiva = isRequired(contrato.contratoParametros?.preventivaDescricao);
                        setMessages({...messages});
                    }else{
                        messages.preventiva = isRequired(contrato.contratoParametros?.preventivaDescricao);
                        setMessages({...messages});
                    }
                }}
            />

            <InputNumberERadioButton
                col={12}
                label="Desconto em peça"
                value={contrato.contratoParametros?.descontoPecaDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.descontoPeca}
                setChecked={handleCheckbox}
                name='descontoPecaDescricao'
                nameCheckbox='descontoPeca'
                min={0}
                prefix="%"
                invalid = {messages.descontoPeca}
                required={contrato.contratoParametros?.descontoPeca}
                onBlur={() => {
                    if(contrato.contratoParametros?.descontoPeca){
                        messages.descontoPeca = isBiggerThanZero(contrato.contratoParametros?.descontoPecaDescricao);
                        setMessages({...messages});
                    }else{
                        messages.descontoPeca = isBiggerThanZero(contrato.contratoParametros?.descontoPecaDescricao);
                        setMessages({...messages});
                    }
                }}
            />
           
            <InputTextERadioButton
                col={12}
                label="SLA em laboratório"
                value={contrato.contratoParametros?.slaLaboratorioDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.slaLaboratorio}
                setChecked={handleCheckbox}
                name='slaLaboratorioDescricao'
                nameCheckbox='slaLaboratorio'
                invalid = {messages.slaLaboratorio}
                required={contrato.contratoParametros?.slaLaboratorio}
                onBlur={() => {
                    if(contrato.contratoParametros?.slaLaboratorio){
                        messages.slaLaboratorio = isRequired(contrato.contratoParametros?.slaLaboratorioDescricao);
                        setMessages({...messages});
                    }else{
                        messages.slaLaboratorio = isRequired(contrato.contratoParametros?.slaLaboratorioDescricao);
                        setMessages({...messages});
                    }
                }}
            />

            <InputTextERadioButton
                col={12}
                label="Logística por conta da Zecode"
                value={contrato.contratoParametros?.logisticaZecodeDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.logisticaZecode}
                setChecked={handleCheckbox}
                name='logisticaZecodeDescricao'
                nameCheckbox='logisticaZecode'
                invalid = {messages.logisticaZecode}
                required={contrato.contratoParametros?.logisticaZecode}
                onBlur={() => {
                    if(contrato.contratoParametros?.logisticaZecode){
                        messages.logisticaZecode = isRequired(contrato.contratoParametros?.logisticaZecodeDescricao);
                        setMessages({...messages});
                    }else{
                        messages.logisticaZecode = isRequired(contrato.contratoParametros?.logisticaZecodeDescricao);
                        setMessages({...messages});
                    }
                }}
            />
           
            <InputTextERadioButton
                col={12}
                label="Suporte"
                value={contrato.contratoParametros?.suporteDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.suporte}
                setChecked={handleCheckbox}
                name='suporteDescricao'
                nameCheckbox='suporte'
                invalid = {messages.suporte}
                required={contrato.contratoParametros?.suporte}
                onBlur={() => {
                    if(contrato.contratoParametros?.suporte){
                        messages.suporte = isRequired(contrato.contratoParametros?.suporteDescricao);
                        setMessages({...messages});
                    }else{
                        messages.suporte = isRequired(contrato.contratoParametros?.suporteDescricao);
                        setMessages({...messages});
                    }
                }}
            />

            <InputTextERadioButton
                col={12}
                label="Condenação do equipamento"
                value={contrato.contratoParametros?.condenacaoDoEquipamentoDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.condenacaoDoEquipamento}
                setChecked={handleCheckbox}
                name='condenacaoDoEquipamentoDescricao'
                nameCheckbox='condenacaoDoEquipamento'
                invalid = {messages.condenacaoDoEquipamento}
                required={contrato.contratoParametros?.condenacaoDoEquipamento}
                onBlur={() => {
                    if(contrato.contratoParametros?.condenacaoDoEquipamento){
                        messages.condenacaoDoEquipamento = isRequired(contrato.contratoParametros?.condenacaoDoEquipamentoDescricao);
                        setMessages({...messages});
                    }else{
                        messages.condenacaoDoEquipamento = isRequired(contrato.contratoParametros?.condenacaoDoEquipamentoDescricao);
                        setMessages({...messages});
                    }
                }}
            />

            <InputTextERadioButton
                col={12}
                label="Treinamento operacional"
                value={contrato.contratoParametros?.treinamentoOperacionalDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.treinamentoOperacional}
                setChecked={handleCheckbox}
                name='treinamentoOperacionalDescricao'
                nameCheckbox='treinamentoOperacional'
                invalid = {messages.treinamentoOperacional}
                required={contrato.contratoParametros?.treinamentoOperacional}
                onBlur={() => {
                    if(contrato.contratoParametros?.treinamentoOperacional){
                        messages.treinamentoOperacional = isRequired(contrato.contratoParametros?.treinamentoOperacionalDescricao);
                        setMessages({...messages});
                    }else{
                        messages.treinamentoOperacional = isRequired(contrato.contratoParametros?.treinamentoOperacionalDescricao);
                        setMessages({...messages});
                    }
                }}
            />

            <InputTextERadioButton
                col={12}
                label="Localidade"
                value={contrato.contratoParametros?.localidadeDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.localidade}
                setChecked={handleCheckbox}
                name='localidadeDescricao'
                nameCheckbox='localidade'
                invalid = {messages.localidade}
                required={contrato.contratoParametros?.localidade}
                onBlur={() => {
                    if(contrato.contratoParametros?.localidade){
                        messages.localidade = isRequired(contrato.contratoParametros?.localidadeDescricao);
                        setMessages({...messages});
                    }else{
                        messages.localidade = isRequired(contrato.contratoParametros?.localidadeDescricao);
                        setMessages({...messages});
                    }
                }}
            />

            <InputTextERadioButton
                col={12}
                label="Manutenção Corretiva"
                value={contrato.contratoParametros?.manutencaoCorretivaDescricao} 
                onChange={handleChange}
                checked={contrato.contratoParametros?.manutencaoCorretiva}
                setChecked={handleCheckbox}
                name='manutencaoCorretivaDescricao'
                nameCheckbox='manutencaoCorretiva'
                invalid = {messages.manutencaoCorretiva}
                required={contrato.contratoParametros?.manutencaoCorretiva}
                onBlur={() => {
                    if(contrato.contratoParametros?.manutencaoCorretiva){
                        messages.manutencaoCorretiva = isRequired(contrato.contratoParametros?.manutencaoCorretivaDescricao);
                        setMessages({...messages});
                    }else{
                        messages.manutencaoCorretiva = isRequired(contrato.contratoParametros?.manutencaoCorretivaDescricao);
                        setMessages({...messages});
                    }
                }}
            />
        </>
    );
}