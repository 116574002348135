import React from "react";
import { MultiSelect as  PMultiSelect} from 'primereact/multiselect';
import classNames from "classnames";
import {calcularTamanho} from "../../service/guiHelper";

export function MultiSelect(props) {

	function handleChange(event) {
		if (props.onChange) {
			props.onChange({name: props.name, value: event.target.value, index: props.index});
		}
	}

	const classes = calcularTamanho(props.col);

	const inputClasses = classNames({
        "multiselect-custom": true,
		"p-invalid block": props.invalid
	});

	const newLocal = props.required ? <small style={{color: props.value ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : <small>&nbsp;</small>;

	return (
		<div className={classes}>
			<label>{props.label}</label>
			<PMultiSelect {...props} placeholder={props.placeholder ? props.placeholder: (!!props.placeholderSelecionar ? `Selecionar ${props.placeholderSelecionar}` : null) }  className={inputClasses} onChange={handleChange}/>
			{props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : newLocal}
		</div>
	);

}
