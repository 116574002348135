import React, {useEffect, useState} from 'react';
import {FormEdit} from "../../components/form/Edit";
import {useParams} from "react-router-dom";
import {InputText} from "../../components/inputtext";
import {buildValidator, isRequired} from "../../utils/fieldValidator";
import {useAuth} from '../../context/AuthContext';
import {produtoTipoService} from '../../service/produtoTipoService';

export function EditarProdutoTipo() {

	const {id} = useParams();
	const {roles} = useAuth();

	const [tipo, setMarca] = useState(produtoTipoService.criar());

	const [messages, setMessages] = useState(buildValidator());

	function handleChange(event) {
		setMarca({...tipo, [event.name]: event.value});
	}

	useEffect(() => {
		if (id !== "new") {
			produtoTipoService.buscar(id).then(setMarca);
		}
	}, [id]);

	return (
		<FormEdit podeDeletar={!!roles.PRTD}
		          podeSalvar={!!roles.PRTC}
		          descricao="Tipo de Produto"
		          onValidate={setMessages}
		          service={produtoTipoService}
		          value={tipo}
		          blocked={id !== "new" && !tipo.id}
		>
			<InputText
				required
				onBlur={() => {
					messages.descricao = isRequired(tipo.descricao);
					setMessages({...messages});
				}}
				invalid={messages.descricao}
				col={12}
				label="Descrição"
				name="descricao"
				value={tipo.descricao}
				onChange={handleChange}
			/>
		</FormEdit>
	);

}
                            