import React, { useCallback } from "react";
import { MultiSelect } from ".";
import { status } from "../../pages/Comercial/services/pedidoVendaService";

export function PedidoStatusSelect(props) {

	const pedidoStatusOptionsTemplate = useCallback((option) => {
		return (
            <div className="flex align-items-center">
				<i style={{color: `${option.color}`}} className={`fa-lg	 fa-solid ${option.icon}`}/>
                <div className="ml-1">{option.label}</div>
            </div>
        );
	}, []); 

	return <MultiSelect 
		label="Status" 
		showSelectAll={false} 
		options={status} 
		itemTemplate={pedidoStatusOptionsTemplate}
		optionLabel={'label'}
		placeholderSelecionar=" status"
		display="chip"
		{...props}
	/>;
}