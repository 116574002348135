import React, { useCallback, useState } from "react"
import { Column } from "primereact/column"
import { DataTable } from "../../components/datatable"
import { EmpresaAutoComplete } from "../../components/autocomplete/empresaAutoComplete";
import { Button } from "../../components/button";
import { ConfirmDialogV2 } from "../../components/confirmdialog";
import { useDialog } from "../../utils/dialogContext";
import { empresaService } from "../../service/empresaService";

export const EditarEmpresaUsuariosModalEmpresas = ({usuario, setUsuario, setLoading, filiais, empresa}) => {
	const { showDialog } = useDialog();
	

	const [empresasSelecionados, setEmpresasSelecionados] = useState([]);
	const [cliente, setCliente] = useState(null);
	const templateStatus = (data) => {
		if (data.status === "ATIVO") {
			return (
			<div
				style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				}}
			>
				<i
				title="Ativado"
				className="fa-solid fa-city fa-lg	"
				style={{ color: "#00940a" }}
				/>
			</div>
			);
		} else {
			return (
			<div
				style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				}}
			>
				<i
				title="Desativado"
				className="fa-solid fa-city fa-lg	"
				style={{ color: "#d10404" }}
				/>
			</div>
			);
		}
	};

	const handleAdicionar = async (e) => {
		if(!e || !e.value || !e.value.id) return;

		setLoading(true);

		empresaService.buscar(e.value.id).then((res) => {
			setUsuario({...usuario, empresasPortal: [...usuario.empresasPortal, res]})
			setCliente(null);
		}).finally(() => setLoading(false));
	};

	const handleAdicionarTodasEmpresas = useCallback(() => {
		const idsB = new Set(usuario.empresasPortal.map(objB => objB.id));
		const adicionarFiliais = filiais.filter(objA => !idsB.has(objA.id));		

		if (!idsB.has(empresa.id)) {
			adicionarFiliais.push(empresa);
		}
		
		setUsuario(res => ({...res, empresasPortal: [...res.empresasPortal, ...adicionarFiliais]}));
	},[empresa, filiais, setUsuario, usuario.empresasPortal]);
	
	const handleDesvincular = useCallback(
	async (e) => {	
		const empresasAtualizadas = usuario.empresasPortal.filter(
		(usuario) =>
			!empresasSelecionados.some(
			(usuarioSelecionado) =>
				(usuarioSelecionado._key &&
				usuario._key === usuarioSelecionado._key) ||
				(usuarioSelecionado.id && usuario.id === usuarioSelecionado.id)
			)
		);
		setUsuario(user => ({...user, empresasPortal: empresasAtualizadas}));
	},
	[empresasSelecionados, setUsuario, usuario.empresasPortal]
	);

	const adicionarTodasEmpresas = useCallback(() => {
		showDialog(
		  <ConfirmDialogV2
			message="Tem certeza de que adicionar todas empresas a este usuário?"
			onYes={handleAdicionarTodasEmpresas}
		  />
		);
	  }, [handleAdicionarTodasEmpresas, showDialog]);
	
	  const desvincular = useCallback(() => {
		showDialog(
		  <ConfirmDialogV2
			message="Tem certeza de que deseja desvincular as empresas selecionadas?"
			onYes={handleDesvincular}
		  />
		);
	  }, [handleDesvincular, showDialog]);

	return (
		<>
			<EmpresaAutoComplete
				col={8}
				value={cliente}
				onChange={(e) => setCliente(e.value)}
				onSelect={handleAdicionar}
				repetidos={usuario?.empresasPortal?.map((item) => item.id)}
			/>
			<div className="col-2 flex align-items-center">
				<Button
					label="Adicionar todas empresas "
					className="h-3rem"
					icon="fa-solid fa-plus"
					onClick={adicionarTodasEmpresas}
				/>
			</div>
			<div className="col-2 flex align-items-center">
				<Button
					label="Desvincular"
					warning
					className="h-3rem"
					icon="fa-solid fa-triangle-exclamation"
					onClick={desvincular}
					disabled={!empresasSelecionados.length > 0}
				/>
			</div>
			<div className="col-12">
				<DataTable
					value={usuario?.empresasPortal}
					valuelength={usuario?.empresasPortal?.length}
					selectionMode="checkbox"
					selection={empresasSelecionados}
					onSelectionChange={(e) => setEmpresasSelecionados(e.value)}
				>
					<Column selectionMode="multiple" headerStyle={{ width: "1em" }} />
					<Column
						style={{ width: "2em" }}
						sortable
						header="Status"
						body={(e) => templateStatus(e)}
					/>
					<Column
					sortable
					field="razaoSocial"
					header="Razão Social"
					style={{ width: "*" }}
					/>
					<Column
					sortable
					field="nomeFantasia"
					header="Nome Fantasia"
					style={{ width: "*" }}
					/>
					<Column
					sortable
					field="cnpjFormatado"
					header="CNPJ"
					style={{ width: "*" }}
					/>
					<Column
					sortable
					field="portalAtivadoPor"
					header="Contrato"
					style={{ width: "*" }}
					/>
      			</DataTable>
			</div>
		</>
	)
}