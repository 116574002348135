import React from "react";
import "./RefetchIndicator.scss";

export function RefetchIndicator({isRefetching}) {

    if (!isRefetching) {
        return null;
    }

    return (
        <div className="refetch-indicator">
            <i style={{display: "inline-block"}} className="fas fa-spinner fa-spin"/> Atualizando
        </div>
    )

}
