import React, {useState} from 'react';
import {AutoComplete} from '.';
import {produtoService} from '../../service/produtoService';

export function ProdutoAutoComplete(props) {

	const [produtos, setProdutos] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("");

	function completeMethod(event) {
		const query = [];
		query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);
		query.push(`status=ATIVO`);
		query.push(`page=0`);
		query.push(`size=100`);
		if (event.query.length) query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);
		produtoService.autocomplete(query).then(res => {
			setProdutos(res);
			if (!res[0]) {
				setEmptyMessage("Nenhum registro encontrado");
			} else {
				setEmptyMessage("");
			}
		});
	}

	return (
		<AutoComplete
			col={3}
			placeholder="código ou descrição"
			label="Produto"
			name="produto"
			completeMethod={completeMethod}
			suggestions={produtos}
			invalid={emptyMessage}
			icon="fa-solid fa-boxes"
			forceSelection
			{...props}
		/>
	);

}
