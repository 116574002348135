import React, {useCallback, useState} from 'react';
import {withDialog} from '../../../../utils/dialogContext';
import {produtoService} from '../../../../service/produtoService';
import {InputCurrency, InputNumber, InputPercentual} from '../../../../components/inputnumber';
import {ProdutoAutoComplete} from '../../../../components/autocomplete/produtoAutoComplete';
import {ConfirmDialogV2} from '../../../../components/confirmdialog';
import {formatCurrency, formatCurrencyMoeda, formatPercentage, simpleFormatPercentage} from '../../../../utils/numberFormatter';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {Column} from 'primereact/column';
import {Button} from '../../../../components/button';
import {DataTable} from '../../../../components/datatable';
import {isBiggerThanZero, isBiggerThanZeroValor, isEntityRequired} from '../../../../utils/fieldValidator';
import {BlockUI} from 'primereact/blockui';
import {PanelContent} from '../../../../components/panel';

export const EditarOrcamentoPedidoVendaItens = withDialog(({pedidoVenda, setPedidoVenda, messages, disabled, showDialog, setMessages}) => {

    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [blocked, setBlocked] = useState(false);
   
    const  validarItem = (e, index, data) =>  {
        let erro;
        let itens = [];
        
		switch (e?.target.name) {
            case 'quantidade':
                erro = isBiggerThanZero(data.quantidade)
                itens = [...messages.itens]
                itens[index] = {...messages.itens[index], quantidade: erro}
                break;
            case 'valorUnitario':
                erro = isBiggerThanZeroValor(data.valorUnitario)
                itens = [...messages.itens]
                itens[index] = {...messages.itens[index], valorUnitario: erro}
                break;
            case 'produto':
                erro = isEntityRequired(data.produto)
                itens = [...messages.itens]
                itens[index] = {...messages.itens[index], produto: erro}
                break;
            default:
                break;
        }
        
        setMessages((messages) => {
            return {
                ...messages,
                itens 
            }
        })
    }

    const handleChange = useCallback((rowIndex, event) => {        
        const itens = pedidoVenda.itens.map((item, index) => {            
            if(rowIndex === index) { 
                item[event.name] = event.value;
                switch (event.name) {
                    case "quantidade":
                    case "valorUnitario":
                        item.valorTotal = item.quantidade * item.valorUnitario;
                        item.valorTotalItem = item.quantidade * item.valorUnitario;
                        break;
                    default:
                        break;
                }
                return {...item} ;
            } else {
                return item;
            }
        });        
        setPedidoVenda({...pedidoVenda, itens})
    }, [pedidoVenda, setPedidoVenda]);

    const handleSelectProduto = useCallback((rowIndex, event) => {
        let item = pedidoVenda.itens[rowIndex];
        if (event.originalEvent?.type === "blur") return;
		if (event.value.id) {
            setBlocked(true);
            let origem = null;
            let destino = null;
            if (pedidoVenda.orcamento.tipo === "DISTRIBUIDOR") {
                origem = pedidoVenda.orcamento.distribuidor.vwCliente.uf;
                destino = pedidoVenda.cliente.vwCliente.uf;
            }
            produtoService.buscar(event.value?.id).then(produto => {
                produtoService.calcularImpostos({
                    tipo: pedidoVenda.orcamento.tipo,
                    ufOrigem: origem,
                    ufDestino: destino,
                    procedencia: produto.vwProduto.procedencia,
                    percentualIpi: produto.vwProduto.percentualIpi,
                    percentualIcms: pedidoVenda.cliente.vwCliente.percentualIcms,
                }).then(impostos => {
                    produtoService.buscarPreco({cliente: pedidoVenda.cliente?.id, produto:event.value?.id}).then(preco => {
                        item.produto = produto;
                        item.unidade = produto?.vwProduto?.unidade;
                        item.valorUnitario = preco.valorUnitario;
                        if (preco.listaPrecoItemId) {
                            item.listaPrecoItem = {id: preco.listaPrecoItemId}
                        }
                        item.percentualCofins = impostos.percentualCofins;
                        item.percentualPis = impostos.percentualPis;
                        item.percentualIpi = impostos.percentualIpi;
                        item.percentualIcms = impostos.percentualIcms;
                        pedidoVenda.itens.filter(i => i.sequencia === item.sequencia).forEach(I => Object.assign(I, item));  
                        setBlocked(false);
                        setPedidoVenda({...pedidoVenda});
                    });
                });
            });
		}
    }, [pedidoVenda, setPedidoVenda]);

    const body = (rowData, props) => {           
        switch (props.field) {
            case 'quantidade':
                return <InputNumber 
                    disabled={disabled} 
                    value={rowData.quantidade} 
                    min={0} name="quantidade" 
                    onChange={(e) => handleChange(props.rowIndex, e)}
                    invalid={messages.itens?.[props.rowIndex]?.quantidade}
                    onBlur={(e) => validarItem(e, props.rowIndex, rowData)}
                    reduced
                />;
            case 'valorUnitario':
                return <InputCurrency 
                    currency={pedidoVenda.orcamento.indice ? pedidoVenda.orcamento.indice.moeda : 'BRL'}
                    disabled={disabled}  
                    value={rowData.valorUnitario} 
                    min={0} 
                    name="valorUnitario" 
                    onChange={(e) => handleChange(props.rowIndex, e)} 
                    invalid={messages.itens?.[props.rowIndex]?.valorUnitario}
                    onBlur={(e) => validarItem(e, props.rowIndex, rowData)}
                    reduced
                />;
            case 'produto':
                return <ProdutoAutoComplete 
                    disabled={disabled}  
                    col={12} 
                    value={rowData.produto} 
                    onChange={(e) => handleChange(props.rowIndex, e)} 
                    onSelect={(e) => handleSelectProduto(props.rowIndex, e)} 
                    name="produto" 
                    label={null} 
                    invalid={messages.itens?.[props.rowIndex]?.produto}
                    onBlur={(e) => validarItem(e, props.rowIndex, rowData)}
                    reduced
                />;
            default:
                return null;
        }
    };

    const impostos = (rowData, column) => {
		if (pedidoVenda.sistema !== 'OMEGA'){
            if(pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR'){

                switch (column?.column?.props?.name) {
                    case 'percentualIpi':
                        return <div className="campo-impostos">
                           <div  style={{ textAlign: "right" }}>
                                <InputPercentual
                                    maxFractionDigits={2} 
                                    value={Number(rowData["percentualIpi"]) || 0}
                                    onChange={(e) => handleChange(column.rowIndex, e)}
                                    name='percentualIpi'
                                    reduced
                                    inputClassName='text-right ' 
                                /> 
                            </div>
                        </div>
                    case 'percentualIcms':
                        return <div className="campo-impostos">
                            <div  style={{ textAlign: "right" }}>
                                <InputPercentual
                                    maxFractionDigits={2} 
                                    value={Number(rowData["percentualIcms"]) || 0}
                                    onChange={(e) => handleChange(column.rowIndex, e)}
                                    name='percentualIcms'
                                    reduced
                                    inputClassName='text-right ' 
                                />  
                            </div>
                        </div>
                    case 'percentualPis':
                        return <div className="campo-impostos">
                           <div  style={{ textAlign: "right", width: '3rem' }}>
                                <InputPercentual
                                    maxFractionDigits={2} 
                                    value={Number(rowData["percentualPis"]) || 0}
                                    onChange={(e) => handleChange(column.rowIndex, e)}
                                    name='percentualPis'
                                    reduced
                                    inputClassName='text-right ' 
                                />
                            </div> 
                        </div>
                    case 'percentualCofins':
                        return <div className="campo-impostos">
                            <div  style={{ textAlign: "right", width: '3rem' }} className='w-10'>
                                <InputPercentual
                                    maxFractionDigits={2} 
                                    value={Number(rowData["percentualCofins"]) || 0}
                                    onChange={(e) => handleChange(column.rowIndex, e)}
                                    name='percentualCofins'
                                    inputClassName='text-right ' 
                                    reduced
                                />
                            </div>
                        </div>
                    default:
                        return null;
                }   
            }else{
                return <div className="campo-impostos">
                    <div>
                        <b>IPI</b>
                    </div>
                    <div  style={{ textAlign: "right" }}>
                        {simpleFormatPercentage(rowData?.percentualIpi)}
                    </div>       
                    
                    <div><b>ICMS</b></div>
                    
                    <div  style={{ textAlign: "right" }}>
                        {simpleFormatPercentage(rowData?.percentualIcms)}
                    </div>
                    
                    <div><b>PIS</b></div>
                    
                    <div  style={{ textAlign: "right" }}>
                        {simpleFormatPercentage(rowData?.percentualPis)}
                    </div>
                    
                    <div><b>COFINS</b></div>
                    
                    <div  style={{ textAlign: "right" }}>
                        {simpleFormatPercentage(rowData?.percentualCofins)}
                    </div>  
                    
				</div>
            }
        }
	}
    const impostos2 = (rowData, column) => {
		if (pedidoVenda.sistema !== 'OMEGA'){
			return ( 
				<div className="campo-impostos">
                        

				</div>
			);
            
		} else {
			return(
				<div className="campo-impostos">
					<div>Valor IPI</div><div title={`Percentual: ` + Number(rowData["percentualIpi"]) + `%`} style={{ textAlign: "right" }}>
						{formatCurrency(rowData["valorIpi"])}
					</div>					
					<div>ICMS %</div><div style={{ textAlign: "right" }}>{formatPercentage(rowData.percentualIcms)}</div>					
				</div>
			);
		}
	}

    function adicionar() {
        
        const adicionarItens = [            
            {                       
                sequencia: pedidoVenda.itens?.reduce((maior, objeto) => (objeto.sequencia > maior ? objeto.sequencia : maior), 0) + 1,
                produto:null,
                cfop: null,
                tipoMovimento:null,
                valorUnitario: 0,
                valorTotal: 0,
                quantidade: 0,
                percentualIcms:0,    
                percentualIpi:0,
                valorIcms:0, 
                valorIpi:0,
                valorTotalItem: 0,
                _key: Math.random() * 1000,
                
            },...pedidoVenda.itens 
        ];
        setPedidoVenda({...pedidoVenda, itens: adicionarItens});
        setMessages(messages => {
            return {
                ...messages,
                itens: [{quantidade:null, produto:null, codigosTributarios: null, valorUnitario: null}, ...messages.itens]
            }
        })
    }

    function excluir() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os itens selecionados?" onYes={() => {
            pedidoVenda.itens = pedidoVenda.itens.filter(osi => !itensSelecionados.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
            if (!pedidoVenda.id) {
                pedidoVenda.itens.forEach((osi, i) => osi.sequencia = i + 1);
            }
            handleChange({name: "itens", value: pedidoVenda.itens});
            setItensSelecionados([]);
        }}/>);
	}

    const valorTotalGeral = () => {
        let total = 0;
        for(let item of pedidoVenda?.itens) {
            total += item.valorTotal;
        }
        return formatCurrency(total, pedidoVenda.orcamento?.indice?.moeda);
    }

    const headerGroup = (
    
        <ColumnGroup>
            <Row>
                <Column rowSpan={pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' ? 2 : 1}  colSpan={1}  selectionMode="multiple" headerStyle={{width: '1em'}}/>
                <Column rowSpan={pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' ? 2 : 1} colSpan={1}  header="Item" alignHeader={'center'} style={{ width: '2em', textAlign: 'center'}}  field="sequencia" name="sequencia"/>
                <Column rowSpan={pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' ? 2 : 1} colSpan={1}  header="Produto"  style={{ width: '*'}}  field="produto" name="produto" body={body}/>
                <Column rowSpan={pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' ? 2 : 1} colSpan={1}  header="Unidade"  alignHeader={'center'} style={{ width: '3em',textAlign: 'center'}} field="unidade" name="unidade"/>
                <Column rowSpan={pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' ? 2 : 1} colSpan={1}  header="Procedência" alignHeader={'center'} style={{ width: '2em', textAlign: 'center'}} name="procedencia" body={p => p.produto?.vwProduto?.procedencia}/>
                <Column rowSpan={pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' ? 2 : 1} colSpan={1}  header="Qtde"  alignHeader={'center'} style={{ width: '10em'}} field="quantidade" name="quantidade" body={body}/>
                <Column rowSpan={pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' ? 2 : 1} colSpan={1}  header="Valor Unitário"  alignHeader={'center'}  style={{ width: '12em'}} field="valorUnitario" name="valorUnitario" body={body}/>
                <Column colSpan={pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' ? 4 : 1}  header="Impostos %"  alignHeader={'center'} style={{ width: '15em'}} name="impostos" body={impostos}/>
                <Column rowSpan={pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' ? 2 : 1} colSpan={1}  header="Valor Total" alignHeader={'center'} style={{ width: '12em', textAlign: 'right'}} field="valorTotal" name="valorTotal" 
                    body={item => formatCurrencyMoeda(item.valorTotal, pedidoVenda.orcamento?.indice?.moeda)}/>
            </Row>
            {pedidoVenda.sistema !== 'OMEGA' && pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' &&
                <Row>
                    <Column colSpan={1}  header="IPI"  alignHeader={'center'}/>
                    <Column colSpan={1}  header="ICMS"  alignHeader={'center'}/>
                    <Column colSpan={1}  header="PIS"  alignHeader={'center'}/>
                    <Column colSpan={1}  header="COFINS"  alignHeader={'center'}/>
                </Row>
            }
        </ColumnGroup>
    );

    let footerGroup =   <ColumnGroup>
                            <Row>
                                <Column footer="Total " colSpan={pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' ? 11 : 8} footerStyle={{textAlign: 'right'}}/>                            
                                <Column footer={valorTotalGeral} footerStyle={{textAlign: 'right'}} />
                            </Row>
                            <Row>
                                <Column colSpan={pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' ? 12 : 9} footer={
                                    <div>
                                        <Button
                                            title='Adicionar item'
                                            success
                                            disabled={disabled}
                                            icon='fa-solid fa-plus'
                                            onClick={adicionar}
                                        />
                                        <Button
                                            title='Excluir item'
                                            danger='true'
                                            icon='fa-solid fa-x'
                                            onClick={excluir}
                                            disabled={disabled || itensSelecionados.length === 0}
                                        />
                                    </div>
                                }/>
                            </Row>                            
                        </ColumnGroup>;                      

    return (
        <BlockUI blocked={blocked}>
            <>
                <PanelContent>
                    <div className='col-12 p-0'>
                        <DataTable
                            emptyMessage='Nenhum item adicionado.' 
                            paginator={true}                        
                            rows={10}
                            footerColumnGroup={footerGroup}
                            selectionMode="checkbox"
                            selection={itensSelecionados}
                            onSelectionChange={e => setItensSelecionados(e.value)}                    
                            value={pedidoVenda?.itens} 
                            valuelength={pedidoVenda?.itens?.length}   
                            headerColumnGroup={headerGroup}                     
                        >
                            <Column selectionMode="multiple" headerStyle={{width: '1em'}}/>
                            <Column header="Item" alignHeader={'center'} style={{ width: '2em', textAlign: 'center'}}  field="sequencia" name="sequencia"/>
                            <Column header="Produto"  style={{ width: '*'}}  field="produto" name="produto" body={body}/>
                            <Column header="Unidade"  alignHeader={'center'} style={{ width: '3em',textAlign: 'center'}} field="unidade" name="unidade"/>                            
                            <Column header="Procedência" alignHeader={'center'} style={{ width: '2em', textAlign: 'center'}} name="procedencia" body={p => p.produto?.vwProduto?.procedencia}/>
                            <Column header="Qtdde"  alignHeader={'center'} style={{ width: '5em'}} field="quantidade" name="quantidade" body={body}/>
                            <Column header="Valor Unitário"  alignHeader={'center'}  style={{ width: '12em'}} field="valorUnitario" name="valorUnitario" body={body}/>
                            <Column style={{ width: '7em'}} name="percentualIpi" body={impostos}/>
                            { pedidoVenda.sistema !== 'OMEGA' && pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' &&
                                <Column style={{ width: '7em'}} name="percentualIcms" body={impostos}/>        
                            }
                            { pedidoVenda.sistema !== 'OMEGA' && pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' &&
                                <Column style={{ width: '7em'}} name="percentualCofins" body={impostos}/>
                            }
                            { pedidoVenda.sistema !== 'OMEGA' && pedidoVenda?.orcamento?.tipo === 'DISTRIBUIDOR' &&
                                <Column style={{ width: '7em'}} name="percentualPis" body={impostos}/>
                            }
                            <Column header="Valor Total" alignHeader={'center'} style={{ width: '12em', textAlign: 'right'}} field="valorTotal" name="valorTotal" 
                                    body={item => formatCurrencyMoeda(item.valorTotal, pedidoVenda.orcamento?.indice?.moeda)}/>
                        </DataTable>
                    </div>
                </PanelContent>
            </>
        </BlockUI>
    );
});
