import React from "react";
import {SelectButton} from "./index";
import "./ordemServicoCorSelectButton.scss";
import {Tooltip} from "primereact/tooltip";

export const ordemServicoCores = [
    {value: "AZUL", tooltip: "Novas"},
    {value: "AMARELO", tooltip: "Aguardando"},
    {value: "VERDE", tooltip: "Em Execução"},
    {value: "ROXO", tooltip: "Finalizadas Tecnicamente"},
    {value: "VERMELHO", tooltip: "Encerradas"}
];

export function ordemServicoCorItemTemplate(item) {
    return (
        <div className={`ordem-servico-cor-item ${item.value.toLowerCase()}`}>
            <Tooltip target={`.filter-${item.value}`} position="top"/>
            <i data-pr-tooltip={item.tooltip} className={`fas fa-circle filter-${item.value}`}/>
        </div>
    );
}

export function OrdemServicoCorSelectButton(props) {

    return <SelectButton multiple className="ordem-servico-cor-selectbutton" options={ordemServicoCores} itemTemplate={ordemServicoCorItemTemplate} label="Cor" {...props}/>;

}
