import moment from "moment";
import React from "react";
export function Fechamento({fechamento}) {
    return (
        <div className=" 2-full mt-3 bg-white border-round border-2  p-4 flex align-items-center justify-content-center">
            <strong className="text-2xl">
                {`
                    Período fechado por ${fechamento.fechadoPor} em ${moment(fechamento.fechadoEm).format("DD/MM/YYYY HH:mm:ss")}.
                   
                `}
            </strong>
        </div>
    )
}