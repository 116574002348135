import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import React  from "react";
import { DataTable } from "../../../components/datatable";

export default function IntegracaoPedidoVenda({visible, setVisible, criticas}) {

    return (
        
        <Dialog header="Críticas de Integração Pedido de Venda " visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
            
            <div className='col-12 p-0'>
                <DataTable
                    footerColumnGroup={null}
                    value={criticas} 
                    paginator={null}
                    selectionMode={null}
                >
                    <Column header="Status"  style={{ width: '4em', textAlign: 'center'}}  field="statusReg" name="statusReg" />
                    <Column header="Mensagem Erro"  style={{ width: '*'}} field="msgErro" name="msgErro" />
                </DataTable>
            </div>
            
        </Dialog>
        
    )
}