import React, {useContext, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import moment from "moment";
import {formatNumber} from "chart.js/helpers";
import {formatMinutes} from "../../utils/numberFormatter";
import {Button} from "../../components/button";
import {EditarDeslocamento} from "../Campo/Agendas/EditarDeslocamento";
import {ordemServicoDeslocamentoService} from "../../service/ordemServicoService";
import {DialogContext} from "../../utils/dialogContext";
import {useAuth} from "../../context/AuthContext";
import {BlockUI} from "primereact/blockui";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {ProgressDialog} from "../../components/progressdialog/ProgressDialog";
import {isStacked} from "../../context/BreakpointContext";

export function OrdemServicoDeslocamentos({ordemServico, deslocamentos, setDeslocamentos}) {

    const {usuario} = useAuth();
    const [loading, setLoading] = useState(false);
    const {showDialog} = useContext(DialogContext);
    const [selection, setSelection] = useState([]);

    function adicionarDeslocamento() {
        editarDeslocamento({
            ...ordemServicoDeslocamentoService.criar(),
            ordemServico: {id: ordemServico.id},
            responsavel: usuario
        });
    }

    function editarDeslocamento(deslocamento) {
        showDialog(<EditarDeslocamento deslocamento={deslocamento} onChange={async d => {
            setLoading(true);
            const index = deslocamentos.findIndex(pd => (pd.id && pd.id === d.id) || (pd._key && pd._key === d._key));
            const deslocamento = await ordemServicoDeslocamentoService.salvar(d);
            setDeslocamentos(prevDeslocamentos => {
                if (index > -1) {
                    prevDeslocamentos[index] = deslocamento;
                } else {
                    prevDeslocamentos.push(deslocamento);
                }
                return [...prevDeslocamentos];
            });
            setLoading(false);
        }}/>);
    }

    function handleDoubleClick(event) {
        editarDeslocamento(event.data);
    }

    function removerDeslocamentos() {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja excluir os deslocamentos selecionados?" onYes={async () => {
            showDialog(<ProgressDialog onProgress={async setProgress => {
                let progress = 0;
                for (const selected of selection) {
                    await ordemServicoDeslocamentoService.delete(selected.id, ordemServico.id);
                    setProgress(++progress / selection.length);
                }
                setDeslocamentos(prevDeslocamentos => {
                    return prevDeslocamentos.filter(pd => !selection.some(ss => ss.id === pd.id));
                });
                setSelection([]);
            }}/>);
        }}/>);
    }

    const controlSet = (
        <div>
            <Button disabled={deslocamentos?.some(d => !d.dataFinal)} icon="fas fa-plus" onClick={adicionarDeslocamento}/>
            <Button disabled={!selection.length} icon="fas fa-minus" danger onClick={removerDeslocamentos}/>
        </div>
    );

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column colSpan={8} header={controlSet}/>
            </Row>
            <Row>
                <Column rowSpan={2} selectionMode="multiple" style={{width: "3em"}}/>
                <Column rowSpan={2} header="Técnico"/>
                <Column style={{textAlign: "center"}} colSpan={2} header="Início"/>
                <Column style={{textAlign: "center"}} colSpan={2} header="Término"/>
                <Column style={{textAlign: "center"}} colSpan={2} header="Percorrido"/>
            </Row>
            <Row>
                <Column style={{textAlign: "center"}} header="Data"/>
                <Column style={{textAlign: "center"}} header="Odômetro"/>
                <Column style={{textAlign: "center"}} header="Data"/>
                <Column style={{textAlign: "center"}} header="Odômetro"/>
                <Column style={{textAlign: "center"}} header="Tempo"/>
                <Column style={{textAlign: "center"}} header="Distância"/>
            </Row>
        </ColumnGroup>
    );

    const footerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column colSpan={8} footer={controlSet}/>
            </Row>
        </ColumnGroup>
    );

    return (
        <BlockUI blocked={loading}>
            <DataTable selection={selection}
                       onSelectionChange={e => setSelection(e.value)}
                       headerColumnGroup={headerColumnGroup}
                       footerColumnGroup={footerColumnGroup}
                       value={deslocamentos}
                       onRowDoubleClick={handleDoubleClick}
                       header={isStacked() ? null : controlSet}
                       footer={isStacked() ? null : controlSet}
            >
                <Column selectionMode="multiple"/>
                <Column field="responsavel" body={d => d.responsavel?.nome}/>
                <Column style={{textAlign: "center"}} field="dataInicial" body={d => moment(d.dataInicial).format("DD/MM/YYYY HH:mm")}/>
                <Column style={{textAlign: "center"}} field="odometroInicial" body={d => formatNumber(d.odometroInicial)}/>
                <Column style={{textAlign: "center"}} field="dataFinal" body={d => d.dataFinal ? moment(d.dataFinal).format("DD/MM/YYYY HH:mm") : ""}/>
                <Column style={{textAlign: "center"}} field="odometroFinal" body={d => d.odometroFinal && formatNumber(d.odometroFinal)}/>
                <Column style={{textAlign: "center"}} field="tempoDeslocamentoEmMinutos" body={printTempoDeslocamento}/>
                <Column style={{textAlign: "center"}} field="distanciaPercorrida" body={printDistanciaPercorrida}/>
            </DataTable>
        </BlockUI>
    );

}

function printTempoDeslocamento(d) {
    if (d.dataInicial && d.dataFinal) {
        return formatMinutes(moment(d.dataFinal).diff(d.dataInicial, "minutes"));
    }
    return "";
}

function printDistanciaPercorrida(d) {
    if (d.odometroInicial && d.odometroFinal) {
        return `${d.odometroFinal - d.odometroInicial}Km`;
    }
    return "";
}
