import React, {useEffect} from 'react';
import {Card} from 'primereact/card';
import "./styles.scss";

export function Feedback(props) {

    useEffect(() => {
        props.setStaticMenuInactive(true)
    }, [props])

    return (
        <Card className='height flex flex-column align-items-center justify-content-center'>
            <div style={{textAlign: 'center'}}>
                <i className="fa-solid fa-envelope fa-10x"/>
                <h2 style={{marginTop: '0.50em'}}>{'Obrigado, seus orçamentos foram salvos e um e-mail de confirmação será enviado a você.'}</h2>
            </div>
        </Card>
    );

}
