import React, { useEffect, useState } from "react";
import { OrganizationChart } from "primereact/organizationchart";
import { If } from "../../components/conditional/If";
export const EditarEmpresaUsuariosOrganogramas = ({ usuarios }) => {
  const [funcionarios, setFuncionarios] = useState([]);

  useEffect(() => {
    const agruparPorGestor = (usuarios) => {
      const agrupados = {};
      const usuariosSemGestor = {
        id: "sem-gestor",
        nome: "Usuários sem gestor",
        children: [],
        className: "bg-gray-500 text-white",
        style: { borderRadius: "12px" },
        expanded: true,
      };

      // 1. Adiciona os usuários que não possuem gestores ao grupo "Usuários sem gestor"
      usuarios.forEach((usuario) => {
        console.log("usuario", usuario);
        if (
          usuario?.gestores.length < 1 &&
          usuario?.tipo === "PORTAL_OPERADOR"
        ) {
          const usuarioSemGestor = {
            ...usuario,
            className: "bg-indigo-500 text-white", // Estilo para usuários sem gestores
            style: { borderRadius: "12px" },
          };
          usuariosSemGestor.children.push(usuarioSemGestor);
        }
      });

      // Adiciona o grupo "Usuários sem gestor" ao agrupamento se houver algum usuário sem gestor
      if (usuariosSemGestor.children.length > 0) {
        agrupados["semGestor"] = usuariosSemGestor;
      }

      // 2. Agora lida com os usuários que possuem gestores, agrupando-os sob seus gestores
      usuarios.forEach((usuario) => {
        if (usuario?.gestores && usuario?.gestores?.length > 0) {
          const usuarioComProps = {
            ...usuario,
            className: "bg-teal-500 text-white", // Estilo para usuários com gestores
            style: { borderRadius: "12px" },
          };

          usuario.gestores.forEach((gestor) => {
            if (!agrupados[gestor.id]) {
              agrupados[gestor.id] = {
                ...gestor,
                children: [],
                className: "bg-indigo-500 text-white", // Estilo para gestores
                style: { borderRadius: "12px" },
                expanded: true, // Para manter este nó expandido
              };
            }

            agrupados[gestor.id].children.push(usuarioComProps);
          });
        }
      });

      // 3. Retorna os valores dos objetos agrupados como uma lista
      return Object.values(agrupados);
    };

    // Chama a função de agrupamento e define os funcionários no estado
    const usuariosAgrupados = agruparPorGestor(usuarios);

    setFuncionarios(usuariosAgrupados);
  }, [usuarios]);

  const nodeTemplate = (node) => {
    return (
      <div className="flex flex-column">
        <div className="flex flex-column align-items-center">
          <div>
            <i className="fa-solid fa-user mr-2"></i>
            <span className="font-bold mb-2">{node.nome}</span>
          </div>
          {node.email ? (
            <div>
              <i className="fa-solid fa-envelope mr-2" />
              <span>{node.email}</span>
            </div>
          ) : null}

          <div>
            <If otherwise={node.departamento}>
              <span>{node.departamento}</span>
            </If>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {funcionarios?.map((gestor) => (
        <div className="col-12 justify-content-center align-items-center">
          <OrganizationChart value={[gestor]} nodeTemplate={nodeTemplate} />
        </div>
      ))}
    </>
  );
};
