import React, {useEffect, useState} from 'react';
import {InputText} from "../../components/inputtext";
import {AutoCompletePerfilMultiple} from "../../components/autocomplete/PerfilMultiple";
import {FormEdit} from "../../components/form/Edit";
import {optionsUsuarioTipo, usuarioService} from "../../service/usuarioService";
import {useParams} from "react-router-dom";
import {buildValidator, isArrayNotEmpty, isEntityRequired, isRequired, isRequiredEmail, isRequiredNome} from "../../utils/fieldValidator";
import { useAuth } from '../../context/AuthContext';
import { ClienteAutoComplete } from '../../components/autocomplete/ClienteAutoComplete';
import { clienteService } from '../../service/clienteService';
import { DataTable } from '../../components/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Button } from '../../components/button';
import { EmpresaAutoComplete } from '../../components/autocomplete/empresaAutoComplete';
import { InputMask } from '../../components/inputmask';
import { UsuarioAutoComplete } from '../../components/autocomplete/usuarioAutoComplete';
import {InputText as PInputText} from "primereact/inputtext";
import {imprimirEtiquetaChaveUsuario} from "../../service/etiquetaService";
import {VendedorAutoComplete} from "../../components/autocomplete/VendedorAutoComplete";
import {Dropdown} from "../../components/dropdown";
import {Checkbox} from "../../components/checkbox";
import {EstoqueAutoComplete} from "../../components/autocomplete/EstoqueAutoComplete";

export function EditarUsuario() {

    const {id} = useParams();
    const {roles} = useAuth()
    const [usuario, setUsuario] = useState(usuarioService.criar());

    const [cliente, setCliente] = useState()
    const [clienteSelecionado, setClienteSelecionado] = useState()

    const [messages, setMessages] = useState(buildValidator());

    function handleChange(event) {
        setUsuario({...usuario, [event.name]: event.value});
    }

    useEffect(() => {
        if (id !== "new") {
            usuarioService.buscar(id).then(setUsuario);
        }
    }, [id]);

    const addCliente = () => {
        if (!cliente?.id) return;
        const clienteRepitido = usuario.clientes.filter(cli => cli.id === cliente.id);
        if (!!clienteRepitido[0]) {
            messages.cliente = "Cliente ja inserido";
            setMessages({...messages});
            return;
        }
        messages.cliente = null;
        setMessages({...messages});
        clienteService.buscar(cliente.id).then(cliente => {
            if (!cliente) return;
            setUsuario({...usuario, clientes: [...usuario.clientes, cliente]});
        });
        setCliente(null);
    }

    const razaoSocialTemplate = (data) => {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        const filial = <i className={`fa-solid fa-store tooltip-${data.id}`} style={{color:'rgb(48, 63, 159)', fontSize: '1.2rem'}}/>
        const unico  = <i className={`fa-solid fa-shop tooltip-${data.id}`} style={{color:'rgb(96, 125, 139)', fontSize: '1.2rem'}}/>
        const matriz  = <i className={`fa-solid fa-building tooltip-${data.id}`} style={{color:'rgb(244, 67, 54)', fontSize: '1.2rem'}}/>
        const icon = data.vwCliente.tipo === 'FILIAL' ?  filial : ( data.tipo === 'UNICO' ? unico : matriz);
        return (
            <>
                <Tooltip target={`.tooltip-${data.id}`} position='left' mouseTrack > 
                    <div className="flex align-items-center flex-column justify-content-center p-2">
                        <div>{capitalizeFirstLetter(data.vwCliente.tipo.toLowerCase())}</div>
                        {
                            data.vwCliente.tipo === 'FILIAL' && (
                                <>
                                    <div className="mt-2"> Dados da Matriz: </div>
                                    <div className="mt-2">{`Razão Social: ${data.vwCliente.grupo.razaoSocial}`}</div>
                                    <div className="mt-2">{`Nome Fantasia: ${data.vwCliente.grupo.nomeFantasia}`}</div>
                                    <div className="mt-2">{`${data.vwCliente.grupo.cpfCnpj.length === 14 ? 'CNPJ' : 'CPF' }: ${data.vwCliente.grupo.cpfCnpj}`}</div>
                                </>
                            )
                        }
                    </div>
                </Tooltip>
                <div> {icon} {data.razaoSocial}</div>
            </>
        );
    }

    const templateStatus = (data) => {
        if(data.vwCliente.status === 'ATIVO'){
            return (
                <div style={{display: 'flex', alignItems: "center", justifyContent:"center"}}>
                    <i title="Ativado" className="fa-solid fa-user-tie fa-lg" style={{color:'#00940a'}}/>
                </div>
            )
        }else{
            return (
                <div style={{display: 'flex', alignItems: "center", justifyContent:"center"}}>
                    <i title="Desativado" className="fa-solid fa-user-tie fa-lg" style={{color:'#d10404'}}/>
                </div>
            )
        }
    }

    const excluirCLiente = (e) => {
        const clientes = usuario.clientes.filter(cliente => cliente.id !== clienteSelecionado.id)
        setUsuario({...usuario, clientes})
        setClienteSelecionado(null)
    }

    async function imprimirChave() {
        await imprimirEtiquetaChaveUsuario(usuario.id);
    }

    return (
        <FormEdit
            podeDeletar={!!roles.USRD}
            podeSalvar={!!roles.USRC}
            descricao="Usuário"
            service={usuarioService}
            value={usuario}
            blocked={id !== "new" && !usuario.id}
            lockDelete={usuario.login === "admin"}
            onValidate={setMessages}
        >
            <InputText 
                required 
                onBlur={() => {
                    messages.nome = isRequiredNome(usuario.nome);
                    setMessages({...messages});
                }} 
                invalid={messages.nome} 
                col={4} 
                name="nome"
                label="Nome" 
                value={usuario.nome} 
                onChange={handleChange}
            />
            
            <InputText required onBlur={() => {
                messages.email = isRequiredEmail(usuario.email);
                setMessages({...messages});
            }} invalid={messages.email} col={4} name="email" label="E-mail" value={usuario.email} onChange={handleChange}/>
            <div className="col-4" style={{paddingTop: "0"}}>
                <label>Chave (Coletor)</label>
                <div className="p-inputgroup">
                    <PInputText readOnly value={usuario.id}/>
                    <Button icon="fa-solid fa-print" onClick={imprimirChave}/>
                </div>
            </div>
            <InputText invalid={messages.rg} col={2} name="rg" label="RG" value={usuario.rg} onChange={handleChange}/>
            <InputMask invalid={messages.cpf} col={2} name="cpf" label="CPF" mask={'999-999-999-99'} value={usuario.cpf} onChange={handleChange} required={usuario?.rg?.length < 7}
                onBlur={() => {
                    if(usuario?.rg?.length < 7){
                        messages.cpf = isRequired(usuario?.nome);
                        setMessages({...messages});
                    }
                }}
            />
            <InputMask col={2} name="telefone" unmask mask={usuario.telefone?.length < 11 ? "(99) 9999-9999?9" : "(99) 99999-9999"} value={usuario.telefone} onChange={handleChange} label="Telefone"/>
            <InputText placeholder={usuario.id ? "********" : ""} required onBlur={() => {
                messages.senha = usuario.id ? null : isRequired(usuario.senha);
                setMessages({...messages});
            }} invalid={messages.senha} type="password" col={2} name="senha" label="Senha" value={usuario.senha} onChange={handleChange}/>
            <InputText placeholder={usuario.id ? "********" : ""} required onBlur={() => {
                messages.senha2 = usuario.id ? null : isRequired(usuario.senha2);
                if (!messages.senha2) {
                    if (usuario.senha !== usuario.senha2) {
                        messages.senha2 = "As senhas digitadas não conferem";
                    } else {
                        messages.senha2 = null;
                    }
                }
                setMessages({...messages});
            }} invalid={messages.senha2} type="password" col={2} name="senha2" label="Confirmação de senha" value={usuario.senha2} onChange={handleChange}/>
            <div className="col-2">
                <Checkbox col={12} label="Usuário Services" name="services" value={usuario.services} onChange={handleChange}/>
                <Checkbox col={12} label="Usuário Portal" name="portal" value={usuario.portal} onChange={handleChange}/>
            </div>
            <EmpresaAutoComplete invalid={messages.empresa} col={6} value={usuario.empresa} name="empresa" onChange={handleChange} />
            <VendedorAutoComplete col={6} name="vendedor" value={usuario.vendedor} onChange={handleChange} label="Vendedor" ativos={true}/>
            <EstoqueAutoComplete label="Estoque de Peças Novas" col={6} name="estoqueNovas" value={usuario.estoqueNovas} onChange={handleChange}/>
            <EstoqueAutoComplete label="Estoque de Peças Recondicionadas" col={6} name="estoqueRecondicionadas" value={usuario.estoqueRecondicionadas} onChange={handleChange}/>
            <AutoCompletePerfilMultiple required onBlur={() => {
                    messages.perfis = isArrayNotEmpty(usuario.perfis)
                    setMessages({...messages});
                }}
                invalid={messages.perfis} col={12} name="perfis" value={usuario.perfis} onChange={handleChange}
            />
            <UsuarioAutoComplete multiple forceSelection col={12} name="gestores" value={usuario.gestores} onChange={handleChange} label="Gestores"/>
            <div className='col-12'>
                <DataTable 
                    value={usuario.clientes} 
                    valuelength={usuario.clientes?.length} 
                    paginator={false}
                    selectionMode="single" 
                    selection={clienteSelecionado} 
                    onSelectionChange={e => setClienteSelecionado(e.value)}
                    header={
                        <div className="flex p-2 gap-2">
                            <div className="flex-grow-1">
                                <ClienteAutoComplete
                                  className="w-full"
                                  col={12}
                                  reduced
                                  invalid={messages.cliente}
                                  value={cliente}
                                  name="Adicionar cliente"
                                  onChange={e => setCliente(e.value)}
                                  onBlur={addCliente}
                                />
                            </div>
                            <div className="flex-row flex align-items-center">
                                <Button
                                  label='Cliente'
                                  success
                                  icon='fa-solid fa-plus'
                                  onClick={addCliente}
                                  disabled={!cliente?.id}
                                />
                                <Button
                                  label='Excluir'
                                  danger
                                  icon='fa-solid fa-x'
                                  onClick={excluirCLiente}
                                  disabled={!clienteSelecionado}
                                />
                            </div>
                        </div>
                    }
                >
                    <Column style={{ width: '2em' }} sortable header="Status" body={e => templateStatus(e)}/>
                    <Column sortable field="vwCliente.clienteId" header="Cliente"  style={{ width: '*' }} />
                    <Column sortable field="razaoSocial" header="Razão Social"  style={{ width: '*' }} body={razaoSocialTemplate}/>
                    <Column sortable field="nomeFantasia" header="Nome Fantasia"  style={{ width: '*' }} />
                    <Column sortable field="cpfCnpj" header="CNPJ"  style={{ width: '*'}} />
                    <Column sortable field="vwCliente.municipio" header="Município"  style={{ width: '*' }} />
                    <Column sortable field="vwCliente.uf" header="UF"  style={{ width: '*', textAlign: 'center' }} />
                </DataTable>
            </div>
        </FormEdit>
    );

}
