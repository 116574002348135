import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {useAuth} from "../../context/AuthContext";
import {Column} from "primereact/column";
import {contagemExtensa, formatCurrency, formatDate, formatMinutes} from "../../utils/numberFormatter";
import {
    iconStatus,
    labelTipos,
    ordemServicoPendenciaService,
    ordemServicoService
} from "../../service/ordemServicoService";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Button} from "../../components/button";
import moment from "moment";
import {withDialog} from "../../utils/dialogContext";
import {BlockUI} from "primereact/blockui";
import {imprimirEtiquetasLaudoZebra, imprimirEtiquetasOsZebra} from "../../service/etiquetaService";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {ReatribuirOrdemServico} from "./ReatribuirOrdemServico";
import {ParamContext} from "../../utils/ParamStore";
import {ProgressDialog} from "../../components/progressdialog/ProgressDialog";
import {findRestricoesBloqueantes} from "../../utils/ordemServicoUtils";
import {printRestricao} from "../../components/cards/CardOrdemServico";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {If} from "../../components/conditional/If";
import {usePagesAndTabs} from "../../utils/PageAndTabContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {EditarAcompanhamento} from "../OrdensServico/EditarAcompanhamento";

export function PrintTipoUsuario({usuario}) {
    switch (usuario.tipo) {
        case "TERCEIRIZADO":
            return <i className="fas fa-people-arrows" style={{color: "#F44336"}} title="Técnico Terceirizado"/>;
        default:
            return <i className="fas fa-user" style={{color: "#2196F3"}} title="Responsável"/>;
    }
}

export const TabelaOrdemServico = withDialog(({value, setHideFilters, showDialog, handleProcurar, blocked}) => {

    const {roles} = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const {pageAndTab, setScroll, setPage} = usePagesAndTabs(QueryParamsKeys.Campo);
    const [ordemServico, setOrdemServico] = useState(null);
    const [acompanhamento, setAcompanhamento] = useState(null);
    const [servicosSelecionados, setServicosSelecionados] = useState([]);
    const {globalParams, handleResults} = useContext(ParamContext);

    useEffect(() => setServicosSelecionados([]), [value]);

    function handleEditar(event) {
        if (!roles.OSSC) return;
        setScroll();
        navigate(`/campo/${event.data.id}`);
    }

    function avancarEtapas() {
        const ordensServico = [...servicosSelecionados];
        if (ordensServico.length) {
            const ordemServico = ordensServico[0];
            if (ordensServico.some(os => os.fluxo !== ordemServico.fluxo || os.etapa?.id !== ordemServico.etapa?.id)) {
                showDialog(<InformationDialog header="Informação" message="Não é possível avançar ordens de serviço em etapas ou fluxos em versões diferentes"/>);
            } else {
                setLoading(true);
                ordemServicoService.buscar(ordemServico.id).then(ordemServico => {
                    setHideFilters(true);
                    setOrdemServico(ordemServico);
                    setAcompanhamento(ordemServicoService.criarAcompanhamento());
                    setLoading(false);
                });
            }
        }
    }

    async function imprimirEtiquetasOs() {
        await imprimirEtiquetasOsZebra(servicosSelecionados);
    }

    async function imprimirEtiquetasLaudo() {
        await imprimirEtiquetasLaudoZebra(servicosSelecionados);
    }

    function reatribuirOrdensServico() {
        showDialog(<ReatribuirOrdemServico key={Math.random()} ordensServico={[...servicosSelecionados]} onModalClose={() => setServicosSelecionados([])}/>);
    }

    function resolverPendencia() {
        showDialog(<ConfirmDialogV2 message="As pendências de atendimento para as ordens de serviço selecionadas foram resolvidas?" onYes={async () => {
            const ordensServico = servicosSelecionados.map(os => os.id);
            showDialog(<ProgressDialog onProgress={async setProgress => {
                let progress = 0;
                for (let ordemServico of ordensServico) {
                    await ordemServicoPendenciaService.resolver([ordemServico]);
                    setProgress(++progress / ordensServico.length);
                }
                if (handleProcurar) {
                    handleProcurar();
                }
            }}/>);
        }}/>);
    }

    const servicoControlSet = (
        <div>
            <Button style={{whiteSpace: "nowrap"}} icon="fa-solid fa-arrow-right" loading={loading} disabled={!servicosSelecionados?.length || loading} onClick={avancarEtapas} label="Avançar Etapas"/>
            <Button style={{whiteSpace: "nowrap"}} icon="fa-solid fa-users" disabled={!servicosSelecionados?.length} label="Reatribuir OS" onClick={reatribuirOrdensServico}/>
            <Button style={{whiteSpace: "nowrap"}} icon="fa-solid fa-car-crash" danger disabled={!servicosSelecionados.length || !servicosSelecionados.every(os => os.restricoes?.includes("PENDENCIA"))} label="Pendência Resolvida" onClick={resolverPendencia}/>
            <div style={{float: "right"}}>
                {!!roles.OSSR ? <Button style={{whiteSpace: "nowrap"}} icon="fa-solid fa-barcode" disabled={!servicosSelecionados?.length} onClick={imprimirEtiquetasOs} label="Etiqueta OS"/> : null}
                {!!roles.OSSR ? <Button clear style={{whiteSpace: "nowrap"}} icon="fa-solid fa-barcode" disabled={!servicosSelecionados?.length} onClick={imprimirEtiquetasLaudo} label="Etiqueta Laudo"/> : null}
            </div>
        </div>
    );

    const servicoHeaderGroup = (
        <ColumnGroup>
            <Row>
                <Column header={servicoControlSet} colSpan={8}/>
                <Column headerStyle={{textAlign: "right"}} header={"Quantidade: " + value.length}/>
                {roles.OSSO ? <Column headerStyle={{textAlign: "right"}} header={formatCurrency(value.map(os => os.valorTotal || 0).reduce((a, b) => a + b, 0))}/> : null}
            </Row>
            <Row>
                <Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
                <Column sortable field="status" headerStyle={{width: "3em", textAlign: "center"}} header=""/>
                <Column sortable field="numero" header="Nº"/>
                <Column sortable field="inicio" header="Inicio" alignHeader='center'/>
                <Column sortable field="previsaoAtendimento" header="Previsão" alignHeader='center'/>
                <Column sortable field="etapa.descricao" header="Etapa"/>
                <Column sortable field="tipo" header="Tipo"/>
                <Column sortable field="cliente.nomeFantasia" header="Cliente"/>
                <Column sortable field="equipamento.serial" header="Equipamento"/>
                {roles.OSSO ? <Column sortable field="valorTotal" header="Valor Total"/> : null}
            </Row>
        </ColumnGroup>
    );

    const servicoFooterGroup = (
        <ColumnGroup>
            <Row>
                <Column colSpan={9}/>
                {roles.OSSO ? <Column footerStyle={{textAlign: "right"}} footer={formatCurrency(value.map(os => os.valorTotal || 0).reduce((a, b) => a + b, 0))}/> : null}
            </Row>
        </ColumnGroup>
    );

    async function salvarAcompanhamento(acompanhamento) {
        showDialog(<ProgressDialog onProgress={async setProgresso => {
            let progresso = 0;
            for (const ordemServico of servicosSelecionados) {
                await ordemServicoService.buscar(ordemServico.id).then(async ordemServico => {
                    ordemServico.acompanhamentos.push(acompanhamento);
                    ordemServico.etapa = acompanhamento.etapa;
                    ordemServico.responsavel = acompanhamento.responsavel;
                    if (acompanhamento.etapa?.status) {
                        ordemServico.status = acompanhamento.etapa.status;
                    }
                    await ordemServicoService.salvar(ordemServico);
                });
                ++progresso;
                setProgresso(progresso / servicosSelecionados.length);
            }
            voltarAcompanhamento();
            ordemServicoService.listarOrdensServico(globalParams.OrdemServico).then(ordensServico => handleResults("OrdemServico", ordensServico));
        }}/>);
    }

    function voltarAcompanhamento() {
        setOrdemServico(null);
        setAcompanhamento(null);
        setServicosSelecionados([]);
        setHideFilters(false);
    }

    if (acompanhamento && ordemServico) {
        return (
            <EditarAcompanhamento multiple
                                  ordemServico={ordemServico}
                                  setOrdemServico={setOrdemServico}
                                  acompanhamento={acompanhamento}
                                  setAcompanhamento={setAcompanhamento}
                                  onVoltar={voltarAcompanhamento}
                                  onSalvar={salvarAcompanhamento}
            />
        );
    }

    const onSelectionChange = (e, e2) => {
        setServicosSelecionados(e.value)
    }

    return (
        <BlockUI blocked={loading || blocked}>
            <DataTable
                value={value}
                onRowDoubleClick={handleEditar}
                valuelength={value?.length}
                selection={servicosSelecionados}
                onSelectionChange={onSelectionChange}
                selectionMode="checkbox"
                headerColumnGroup={servicoHeaderGroup}
                footerColumnGroup={servicoFooterGroup}
                first={20 * pageAndTab.page}
                onPage={e => setPage(e.page)}
                rowClassName={os => ({"tabela-os": true, [os.cor?.toLowerCase()]: true})}
            >
                <Column selectionMode="multiple" style={{cursor: "default"}}/>
                <Column sortable field="status" style={{textAlign: "center", cursor: "default"}} body={os => iconStatus[os.status]}/>
                <Column sortable field="numero" style={{textAlign: "right", cursor: "default"}} />
                <Column sortable field="inicio" style={{textAlign: "center", cursor: "default"}} body={r => formatDate(r.inicio)}/>
                <Column sortable field="previsaoAtendimento" style={{textAlign: "center", cursor: "default"}} body={r => r.previsaoAtendimento && moment(r.previsaoAtendimento).format("DD/MM/YYYY HH:mm")}/>
                <Column sortable field="etapa.descricao" style={{cursor: "default"}} body={os => (
                    <div>
                        <a id={os.id}/>
                        <div>{os.etapa?.descricao + " " + ((os.ultimoAcompanhamento && `(${formatMinutes(Math.abs(moment().diff(os.ultimoAcompanhamento.inicio, "minutes")))})`) || "")}</div>
                        <div>
                            <div><PrintTipoUsuario usuario={os.responsavel}/> {os.responsavel.nome}</div>
                        </div>
                        <div>{os.ultimoAcompanhamento ? moment(os.ultimoAcompanhamento.inicio).format("DD/MM/YYYY HH:mm:ss") : null}</div>
                        <div>{os.ultimoAcompanhamento ? os.ultimoAcompanhamento.observacoes : null}</div>
                        <If condition={!roles.CAMT}>
                            {findRestricoesBloqueantes(os).map(r => printRestricao(os, r))}
                        </If>
                        <div>
                            <span style={{marginRight: "1em"}} title="Orçamentos Gerados"><i style={{color: "#FF9800"}} className="fa-solid fa-file-pdf"/> {os.countOrcamentoGerado}</span>
                            <span style={{marginRight: "1em"}} title="Orçamentos Enviados"><i style={{color: "#2196F3"}} className="fa-solid fa-envelope"/> {os.countOrcamentoEnviado}</span>
                            <span style={{marginRight: "1em"}} title="Orçamentos Aprovados"><i style={{color: "#4CAF50"}} className="fa-solid fa-check"/> {os.countOrcamentoAprovado}</span>
                            <span style={{marginRight: "1em"}} title="Orçamentos Recusados"><i style={{color: "#E91E63"}} className="fa-solid fa-times"/> {os.countOrcamentoRecusado}</span>
                        </div>
                        <div>
                            <i className="fas fa-road" style={{color: "#2196F3"}}/> <span title="Distância percorrida">{os.distanciaPercorrida || 0}Km</span> (<span title="Tempo em deslocamento">{formatMinutes(os.tempoDeslocamentoEmMinutos || 0)}</span>)
                            &nbsp;
                            <i className="fas fa-hourglass" style={{color: "#FF9800"}}/> <span title="Tempo em espera">{formatMinutes(os.tempoParadoEmMinutos || 0)}</span>
                        </div>
                    </div>
                )}/>
                <Column sortable style={{cursor: "default"}} field="tipo" body={os => labelTipos[os.tipo]}/>
                <Column sortable style={{cursor: "default"}} field="cliente.nomeFantasia" body={os => (
                    <div>
                        <div style={{fontWeight: "bold"}}>{os.cliente.nomeFantasia}</div>
                        <div>{os.cliente.endereco}</div>
                    </div>
                )}/>
                <Column sortable field="equipamento.produto.descricao" style={{cursor: "default"}} body={os => (
                    os.tipo === "CORRETIVA" ? (
                        <div>
                            <div>{os.equipamento?.produto?.descricao}</div>
                            <div>N/S: {os.equipamento?.serial}</div>
                        </div>
                    ) : (
                        <div style={{fontStyle: "italic"}}>{contagemExtensa(os.equipamentos, "equipamento")}</div>
                    )
                )}/>
                {roles.OSSO ? <Column sortable field="valorTotal" style={{textAlign: "right" , cursor: "default"}} body={os => formatCurrency(os.valorTotal)}/> : null}
            </DataTable>
        </BlockUI>
    );

});
