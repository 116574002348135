import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { clienteService } from "../../service/clienteService";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import { Tooltip, } from 'primereact/tooltip';

export const TabelaFiliais = ({matriz}) => {

    
    
    useEffect(() => {
        const query = [`matriz=${matriz}`, `tipo=${['FILIAL']}`];
        toast.promise(
            clienteService.listar(query).then(setValue),
            {
              pending: `Realizando busca...`,
              success: `Busca concluida com sucesso.`,
              Erro: `Erro ao tentar buscar filiais.`
            }
        )
    }, [matriz])

    const [value, setValue] = useState([])
  
    const razaoSocialTemplate = (data) => {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        const filial = <i className={`fa-solid fa-store tooltip-${data.id}`} style={{color:'rgb(48, 63, 159)', fontSize: '1.2rem'}}/>
        const unico  = <i className={`fa-solid fa-shop tooltip-${data.id}`} style={{color:'rgb(96, 125, 139)', fontSize: '1.2rem'}}/>
        const matriz  = <i className={`fa-solid fa-building tooltip-${data.id}`} style={{color:'rgb(244, 67, 54)', fontSize: '1.2rem'}}/>
        const tipo = data.tipo === 'UNICO' ? unico : matriz;
        const icon = data.tipo === 'FILIAL' ?  filial : tipo;
          
        return (
            <>
                <Tooltip target={`.tooltip-${data.id}`} position='left' mouseTrack > 
                    <div className="flex align-items-center flex-column justify-content-center p-2">
                        <div>{capitalizeFirstLetter(data.tipo.toLowerCase())}</div>
                        {
                            data.tipo === 'FILIAL' && (
                                <>
                                    <div className="mt-2"> Dados da Matriz: </div>
                                    <div className="mt-2">{`Razão Social: ${data.grupoRazaoSocial}`}</div>
                                    <div className="mt-2">{`Nome Fantasia: ${data.grupoNomeFantasia}`}</div>
                                    <div className="mt-2">{`${data.grupoCpfCnpj.length >= 14 ? 'CNPJ' : 'CPF' }: ${data.grupoCpfCnpj}`}</div>
                                </>
                            )
                        }
                    </div>
                </Tooltip>
                <div> {icon} {data.razaoSocial}</div>
            </>
        )
    }

    return (
        <div className='col-12'>
            <DataTable value={value}  valuelength={value?.length}  selectionMode={null}  >
                <Column sortable field="clienteId" header="Cliente"  style={{ width: '*' }} />
                <Column sortable field="razaoSocial" header="Razão Social"  style={{ width: '*' }} body={razaoSocialTemplate}/>
                <Column sortable field="nomeFantasia" header="Nome Fantasia"  style={{ width: '*' }} />
                <Column sortable field="cpfCnpj" header="CNPJ"  style={{ width: '*' }} />
                <Column sortable field="municipio" header="Município"  style={{ width: '*' }} />
                <Column sortable field="uf" header="UF"  style={{ width: '*' }} />
            </DataTable>
        </div>
    );

};
