import React from "react";
import {If} from "../conditional/If";

export function CardEquipamento({equipamento, ordemServico}) {

	if (!equipamento) {
		return null;
	}

	let dadosOrdemServico = null;

	if (ordemServico?.id) {
		let prateleira = ordemServico.ultimaMovimentacao?.prateleira;
		if (prateleira?.id) {
			prateleira = (
				<div style={{fontSize: ".9em"}}>
					<i className={`fas fa-${prateleira.tipo === "BANCADA" ? "table" : "inbox"}`}/>&nbsp;
					{prateleira.tipo === "BANCADA" ? "Bancada" : "Prateleira"}: {prateleira.autoComplete}
				</div>
			);
		} else {
			prateleira = (
				<div style={{fontSize: ".9em", color: "#F44336"}}>
					<i className="fas fa-times"/> Sem prateleira/bancada atribuída
				</div>
			);
		}
		dadosOrdemServico = (
			<div>
				{prateleira}
				<div style={{fontSize: ".9em"}}>
					<i className="fas fa-user"/> Responsável: {ordemServico.responsavel?.nome || <span style={{color: "#F44336"}}>Nenhum responsável atribuído</span>}
					{ordemServico.solicitante?.id ? <><br/><i className="fas fa-user"/> Solicitante: {ordemServico.solicitante.nome}</> : null}
				</div>
			</div>
		);
	}

	return (
		<table>
			<tr>
				<td style={{width: "3em", textAlign: "center"}}>
					<i className="fas fa-big fa-terminal" style={{color: "#607D8B"}}/>
				</td>
				<td>
					<div><b>{equipamento.produto.codigo} - {equipamento.produto.descricao}</b></div>
					<div style={{fontSize: ".9em"}}>N/S: {equipamento.serial}</div>
					<If condition={equipamento.produto.equipamentoEspecial}>
						<div style={{fontSize: ".9em"}}>
							<i className="fas fa-star"/> Equipamento Especial
						</div>
					</If>
					{dadosOrdemServico}
				</td>
			</tr>
		</table>
	);

}
