import {buildValidator, isRequired} from "../utils/fieldValidator";
import {baseService} from "./baseService";

const resourceUrl = "/produtos/tipos";

export const produtoTipoService = {
	criar: () => {
		return {
			id: null,
			descricao: "",
		};
	},
	validar: (tipo) => {
		const messages = buildValidator();
		messages.descricao = isRequired(tipo.descricao);
		return messages;
	},
	...baseService(resourceUrl),
	async listarTiposProduto(params) {
		const query = [];
		if (params.descricao?.length) query.push(`descricao=lk=${params.descricao}`);
		return await produtoTipoService.listar([`query=${query.join(";")}`]);
	}
};
