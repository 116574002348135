import {viaCEP} from './api'

export const useViaCepService = () => {

    const find =  async (cep) => {
        const response = await viaCEP.get(`${cep}/json/`);
        return response.data;
    }

    return{
        find,
    }
}
