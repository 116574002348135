import React from "react";
import {buildValidator, isRequired} from '../utils/fieldValidator';
import {baseService} from './baseService';

const resourceUrl = "/selos-ipem";

export const seloIpemService = {
	criar: () => {
		return {
			id: null,
			_key: Math.random(),
			numero: "",
		};
	},
	validar: (seloIpem) => {
		const messages = buildValidator();
		messages.numero = isRequired(seloIpem.numero);
		return messages;
	},
	...baseService(resourceUrl),
    async listarSelosIpem(params) {
		const query = [];
		if (params.inicio?.length) query.push(`inicio=${params.inicio}`);
		if (params.fim?.length) query.push(`fim=${params.fim}`);
		if (params.responsavel?.id) query.push(`responsavel=${params.responsavel.id}`);
		if (params.status?.length) query.push(`status=${params.status.join(",")}`);
		if (params.os) query.push(`os=${params.os}`);
		if (params.equipamento?.id) query.push(`equipamento=${params.equipamento.id}`);
		return await seloIpemService.listar(query);
    }
};

export const optionsStatus = [
	{label: "Livre", value: "LIVRE", color: "#4CAF50", icon: "fa-solid fa-folder-open"},
	{label: "Utilizado", value: "UTILIZADO", color: "#03A9F4", icon: "fa-solid fa-certificate"},
	{label: "Perdido", value: "PERDIDO", color: "#607D8B", icon: "fa-solid fa-search-minus"},
	{label: "Inutilizado", value: "INUTILIZADO", color: "#F44336", icon: "fa-solid fa-trash-alt"}
];

export const labelStatus = {
	LIVRE: <><i style={{color: "#4CAF50"}} className="fa-solid fa-folder-open"/> Livre</>,
	UTILIZADO: <><i style={{color: "#03A9F4"}} className="fa-solid fa-certificate"/> Utilizado</>,
	PERDIDO: <><i style={{color: "#607D8B"}} className="fa-solid fa-search-minus"/> Perdido</>,
	INUTILIZADO: <><i style={{color: "#F44336"}} className="fa-solid fa-trash-alt"/> Inutilizado</>
};
