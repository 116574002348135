import React, { useMemo } from "react";
import { EmpresaTable } from "./EmpresaTable";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useBreakpoint } from "../../context/BreakpointContext";
import { headerTemplate } from "../../utils/templates";
import { PanelContent, PanelFooter } from "../../components/panel";
import { InputText } from "../../components/inputtext";
import { Button } from "../../components/button";
import { Panel } from "primereact/panel";
import { SearchButton } from "../../components/button/SearchButton";
import { If } from "../../components/conditional/If";
import { ToggleViewHidden } from "../../components/button/ViewHiddenButton";
import { BlockUI } from "primereact/blockui";
import { QueryParamsKeys } from "../../utils/defaultQueryParams";
import { useQueryClientEmpresa } from "../../utils/query-clients/queryClients";
import { useQueryParams } from "../../utils/QueryParamContext";
import { RefetchIndicator } from "../../components/refetchindicator/RefetchIndicator";
import { SelectButton } from "../../components/selectbutton";

export function Empresas() {
  const { roles } = useAuth();
  const navigate = useNavigate();
  const { isLargeDevice } = useBreakpoint();
  const { query, handleSearch } = useQueryClientEmpresa();
  const { params, handleChange } = useQueryParams(QueryParamsKeys.Empresa);

  function handleCriar() {
    navigate("/empresas/new");
  }

  const optionsTipoEmpresa = [
    {
      label: (
        <div className="flex w-full justify-content-center align-items-center">
          <i className="fa-solid fa-city mr-2 fa-lg" />
          Matriz
        </div>
      ),
      value: "MATRIZ",
    },
    {
      label: (
        <div className="flex w-full justify-content-center align-items-center">
          <i className="fa-solid fa-building mr-2 fa-lg" />
          Filial
        </div>
      ),
      value: "FILIAL",
    },
  ];

  return (
    <BlockUI blocked={query.isLoading}>
      <Panel headerTemplate={() => headerTemplate("Empresas", "fa-city")}>
        <PanelContent>
          <InputText
            col={9}
            label="Pesquise por"
            placeholder="um CNPJ (somente nº), nome fantasia ou razão social"
            value={params.textoDeBusca}
            name="textoDeBusca"
            onChange={handleChange}
          />
          <SelectButton
            label="Tipo"
            value={params.tipoEmpresa}
            name="tipoEmpresa"
            options={optionsTipoEmpresa}
            onChange={handleChange}
            col={3}
          />
        </PanelContent>
        <PanelFooter>
          <div style={{ float: "left" }}>
            <ToggleViewHidden params={params} onChange={handleChange} />
          </div>
          <If condition={roles.EMPA}>
            <Button
              success
              onClick={handleCriar}
              label={isLargeDevice ? "Novo" : null}
              icon="pi pi-plus"
              autowidth={!isLargeDevice}
            />
          </If>
          <SearchButton query={query} onSearch={handleSearch} />
        </PanelFooter>
      </Panel>
      {useMemo(
        () => (
          <EmpresaTable value={query.data || []} />
        ),
        [query.data]
      )}
      <RefetchIndicator isRefetching={query.isRefetching} />
    </BlockUI>
  );
}
