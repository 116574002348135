import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import React  from "react";
import { DataTable } from "../../../components/datatable";

export default function MotivoBloqueio({visible, setVisible, bloqueios}) {
   
    const body = (rowData) => {                  
        return (
            <div key="icone">
                <span style={{ textAlign: "center" ,padding: "5px", fontSize: "16px", color: "#5c5d5e" }} className={`fas fa-${rowData.icone}`}  />
            </div>
        );
    };

    return (
        <Dialog header="Motivos de Bloqueio" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
            
            <div className='col-12 p-0'>
                <DataTable
                    footerColumnGroup={null}
                    value={bloqueios} 
                    paginator={null}
                    selectionMode={null}
                >
                    <Column style={{ width: '4em', textAlign: 'center'}} field="icone" name="icone" body={body} />
                    <Column header="Sigla"  style={{ width: '4em', textAlign: 'center'}}  field="sigla" name="sigla" />
                    <Column header="Motivo"  style={{ width: '*'}} field="descricao" name="descricao" />
                </DataTable>
            </div>
            
        </Dialog>
        
    )
}