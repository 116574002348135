import React, {useMemo, useState} from "react";
import {TabelaSeloIpem} from "./TabelaSeloIpem";
import {CadastroLoteSeloIpem} from "./CadastroLoteSeleIpem";
import {TrocarResponsavel} from "./TrocarResponsável";
import {useDialog} from "../../utils/dialogContext";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import {Panel} from "primereact/panel";
import {BlockUI} from "primereact/blockui";
import {If} from "../../components/conditional/If";
import {useBreakpoint} from "../../context/BreakpointContext";
import {SearchButton} from "../../components/button/SearchButton";
import {useQueryClientSeloIpem} from "../../utils/query-clients/queryClients";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {FiltrosSeloIpem} from "./FiltrosSeloIpem";

export function SelosIpem() {

	const {roles} = useAuth();
	const navigate = useNavigate();
	const {showDialog} = useDialog();
	const {isLargeDevice} = useBreakpoint();
	const {query, handleSearch} = useQueryClientSeloIpem();
	const [selosSelecionados, setSelosSelecionados] = useState([]);

	function handleCriar() {
		navigate("/selos-ipem/new");
	}

	function cadastrarLote() {
		showDialog(<CadastroLoteSeloIpem/>);
	}

	function trocarResponsavel() {
		if (selosSelecionados.length) {
			showDialog(<TrocarResponsavel selosIpem={selosSelecionados} onModalClose={handleSearch}/>);
		} else {
			showDialog(<InformationDialog header="Informação" message="Você precisa selecionar pelo menos um selo para trocar o responsável"/>);
		}
	}

	return (
		<BlockUI blocked={query.isLoading}>
			<Panel headerTemplate={() => headerTemplate("Selos do IPEM", "fa-tag")}>
				<PanelContent>
					<FiltrosSeloIpem/>
				</PanelContent>
				<PanelFooter>
					<div style={{float: "left"}}>
						<If condition={!!roles.SIPC}>
							<Button label="Trocar Responsável" icon="pi pi-users" onClick={trocarResponsavel}/>
						</If>
					</div>
					<If condition={!!roles.SIPA}>
						<Button label="Cadastrar Lote" icon="pi pi-plus" success='true' onClick={cadastrarLote}/>
						<Button success='true' onClick={handleCriar} label={isLargeDevice ? "Novo" : null} icon="pi pi-plus" autowidth={!isLargeDevice}/>
					</If>
					<SearchButton query={query} onSearch={handleSearch}/>
				</PanelFooter>
			</Panel>
			{useMemo(() => <TabelaSeloIpem selosSelecionados={selosSelecionados} setSelosSelecionados={setSelosSelecionados} value={query.data || []}/>, [query.data, selosSelecionados])}
			<RefetchIndicator isRefetching={query.isRefetching}/>
		</BlockUI>
	);

}
