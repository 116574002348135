import moment from "moment";

export const formatCurrency = (value, currency) => {
    return (value || 0).toLocaleString("pt-BR", {style: "currency", currency: `${currency ? currency : 'BRL'}`});
}



export const formatCurrencyMoeda = (value, moeda) => {
    let currency;
    if (moeda && moeda !== 'null'){    
        currency = moeda;
    } else {
        currency = 'BRL';
    }
    return (value || 0).toLocaleString("pt-BR", {style: "currency", currency: currency});
}

export function formatPercentage(value, decimals = 2) {
    return (value || 0).toLocaleString("pt-BR", {minimumFractionDigits: decimals, maximumFractionDigits: decimals}) + "%";
}

export function simpleFormatPercentage(value, decimals = 2) {
    return (value || 0).toLocaleString("pt-BR", {minimumFractionDigits: decimals, maximumFractionDigits: decimals});
}

export function formatDate(value) {
    return value ? moment(value).format("DD/MM/YYYY") : "";
}

export function formatTime(value) {
    return value ? moment(`1970-01-01T${value}`).format("HH:mm:ss") : null;
}

export function formatDateTime(value) {
    return moment(value).format("DD/MM/YYYY HH:mm:ss");
}

export function formatMinutes(value) {
    let signal = "";
    if (value < 0) signal = "-";
    value = Math.abs(value);
    let printable = Math.floor((value / 60) % 24).toString().padStart(2, "0") + "h" + (value % 60).toString().padStart(2, "0");
    if (value > 1440) {
        printable = Math.floor(value / 1440) + "d " + printable;
    }
    return signal + printable;
}

export function contagemExtensa(value, singular, plural = singular + "s", gender = "") {
    switch (value) {
        case 0:
            return `Nenhum${gender} ${singular}`;
        case 1:
            return `1 ${singular}`;
        default:
            return `${value} ${plural}`;
    }
}
