import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {buildValidator} from '../../utils/fieldValidator';
import {FormTab} from '../../components/formtab';
import {PanelContent} from '../../components/panel';
import {TabPanel} from 'primereact/tabview';
import {toast} from 'react-toastify';
import {clienteService} from '../../service/clienteService';
import {Matriz} from './Matriz';
import {TabelaFiliais} from './TabelasFiliais';
import {EditarDadosPrincipais} from './EditarDadosPrincipais';
import {useAuth} from "../../context/AuthContext";
import { EditarEventos } from './EditarEventos';
import { EditarContatos } from './EditarContatos';
import { InformationDialog } from '../../components/dialog/InformationDialog';
import { withDialog } from '../../utils/dialogContext';


export const EditarCliente = withDialog(({showDialog}) => {

    let {id} = useParams();
    const {roles} = useAuth();
    const navigate = useNavigate();
    const [cliente, setCliente] = useState(clienteService.criar);
    const [messages, setMessages] = useState({...buildValidator(), contatos: []});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id && id !== "new") {
            clienteService.buscar(id).then(setCliente);
        }
    }, [id, setCliente]);

    function handleSalvar() {
        let salvarCliente = {...cliente};        
        const messages = clienteService.validar(cliente);
        console.log(messages);
	
        if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;
		}

        setLoading(true);
        toast.promise(
            clienteService.salvar(salvarCliente).then(value => {
                navigate(-1)
            }).finally(() => setLoading(false)),
            {
                "pending": `Salvando ${cliente.razaoSocial}. Aguarde...`,
                "success": `${cliente.razaoSocial} salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar ${cliente.razaoSocial}: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }

    return(
        <FormTab
            descricao="Cliente"
            service={clienteService}
            value={cliente}
            blocked={id !== "new" && !cliente.id}
            onValidate={setMessages}
            carregando={loading}
            salvar={handleSalvar}
            podeSalvar={!!roles.CLIC}
            podeDeletar={false}
        >
            <TabPanel header="Dados Principais" leftIcon='fa-solid fa-database'  >
                <PanelContent>
                    <EditarDadosPrincipais cliente={cliente} setCliente={setCliente} messages={messages} setMessages={setMessages}/>                 
                </PanelContent>
            </TabPanel>
            <TabPanel header="Contatos" leftIcon='fa-solid fa-users' >
                <PanelContent>
                    <EditarContatos cliente={cliente} setCliente={setCliente} messages={messages} setMessages={setMessages}/>   
                </PanelContent>
            </TabPanel>
            {
                cliente?.vwCliente?.tipo === 'FILIAL' &&
                <TabPanel header="Dados da Matriz" leftIcon='fa-solid fa-building'>
                    <PanelContent>
                        <Matriz matriz={cliente?.vwCliente?.grupo} />
                    </PanelContent>
                </TabPanel>
            }
            {
                cliente?.vwCliente?.tipo === 'MATRIZ' &&
                <TabPanel header="Filiais" leftIcon='fa-solid fa-store'>
                    <PanelContent>
                        <TabelaFiliais matriz={cliente?.vwCliente?.grupo?.clienteId} />
                    </PanelContent>
                </TabPanel>
            }

            <TabPanel header="Eventos" leftIcon='fa-solid fa-file-lines' >
                <PanelContent>
                    <EditarEventos 
                        cliente={cliente} 
                        setCliente={setCliente} 
                        messages={messages} 
                        setMessages={setMessages} 
                    />
                </PanelContent>
            </TabPanel>
        </FormTab>
    );

});
