import React, { useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "../../../../components/datatable";
import { agrupar, calcularComissaoUnica, desagruparDados, encontrarChaveParaObjeto, manterChavesExclusivas } from "./utils";
import { TabelaAcompanhamentoExpandida } from "./TabelaAcompanhamentoExpandida";

export const TabelaAcompanhamento = React.memo(({acompanhamento, tiposComissao, setAcompanhamento, 
    itensSelecionados, setItensSelecionados, modalConsultor, modalTipoComissao, setModalConsultor, setModalTipoComissao, fechamento
}) => {

    const [expandedRows, setExpandedRows] = useState([]);
    const [loadingRow, setLoadingRow] = useState(false);

    const handleChange = async (onChangeValue, dataTableRowData, dataTableRowInfos) => {

        setLoadingRow(true);
        setAcompanhamento((acompanhamentoAtualizado) => {
            let valorCompleto;
            let tipoComissaoCompleto;
            
            if(onChangeValue.name === 'tipoComissao') {         
                tipoComissaoCompleto = tiposComissao.find(tipoComissao => tipoComissao.id === onChangeValue.value);
                valorCompleto = tipoComissaoCompleto;
            } else {
                tipoComissaoCompleto = tiposComissao.find(tipoComissao => tipoComissao.id === dataTableRowData?.tipoComissao?.id);
            }
    
            const alteracoes = acompanhamentoAtualizado.map(vendedor => {
                
                if (vendedor.vendedorCodigo !== dataTableRowData.vendedor?.codigo) {
                    return vendedor;
                }

                const acompanhamentoCopia = { ...vendedor };
    
                const chaveEncontrada = encontrarChaveParaObjeto(dataTableRowData, vendedor.tiposComissao);
    
                if (!chaveEncontrada || !vendedor.tiposComissao[chaveEncontrada]) {
                    return vendedor;
                }
    
                const tiposComissaoCopia = { ...vendedor.tiposComissao[chaveEncontrada] };
                const dadosCopia = tiposComissaoCopia.dados?.map((nota, index) => {
                    if (nota.id === dataTableRowData.id) {
                        
                        let alteracaoNota = {...nota};
    
                        if(onChangeValue.name === 'valorBaseCalculoComissao' || 
                            onChangeValue.name === 'percentualComissao' ||
                            (onChangeValue.name === 'tipoComissao' && tipoComissaoCompleto?.tipo === 'UNICA')) {
                            let notaAtualizada = calcularComissaoUnica(nota, onChangeValue.name, onChangeValue.value, tipoComissaoCompleto);
                            notaAtualizada.modificado = true;
                            return notaAtualizada;
                            // return alteracaoNota = {
                            //     ...notaAtualizada,
                            //     modificado: true,
                            // }
                        }

                        if(onChangeValue.name === 'tipoComissao' && nota.comissaoPaga && tipoComissaoCompleto?.validaComissaoPaga) {
                            alteracaoNota.status = 'REPROVADO';
                            // alteracaoNota = {
                            //     ...alteracaoNota,
                            //     status: 'REPROVADO',
                            // }
                        }
    
                        if(onChangeValue.name === 'tipoComissao' && !tipoComissaoCompleto?.aplicarDivisor) {
                        //if(onChangeValue.name === 'tipoComissao' && tipoComissaoCompleto?.descricao === 'AVULSO') {     
                            alteracaoNota.valorBaseCalculoComissao = nota.valorFaturamento;
                            // alteracaoNota = {
                            //     ...alteracaoNota,
                            //     valorBaseCalculoComissao: nota.valorFaturamento,
                            // }
                        }
                        //if(onChangeValue.name === 'tipoComissao' && tipoComissaoCompleto?.descricao !== 'AVULSO' && dataTableRowData.divisorParcela > 1){                            
                            if(onChangeValue.name === 'tipoComissao' && tipoComissaoCompleto?.aplicarDivisor && dataTableRowData.divisorParcela > 1){                            
                                alteracaoNota.valorBaseCalculoComissao = nota.valorFaturamento / dataTableRowData.divisorParcela;
                                // alteracaoNota = {
                                // ...alteracaoNota,
                                // valorBaseCalculoComissao: nota.valorFaturamento / dataTableRowData.divisorParcela,
                            // }
                        }
                        alteracaoNota.modificado = true; 
                        alteracaoNota[onChangeValue.name] = valorCompleto || onChangeValue.value;
                        // alteracaoNota = {
                        //     ...alteracaoNota,
                        //     modificado: true,
                        //     [onChangeValue.name]: valorCompleto || onChangeValue.value,
                        // };

                        return alteracaoNota;
                    } else {
                        return nota;
                    }
                });
    
                const tiposComissaoAlterados = {
                    ...tiposComissaoCopia,
                    dados: dadosCopia,
                };
                const vendedorAlterado = {
                    ...acompanhamentoCopia,
                    tiposComissao: {
                        ...acompanhamentoCopia.tiposComissao,
                        [chaveEncontrada]: tiposComissaoAlterados,
                    },
                };
                return vendedorAlterado;
            });
    
            const desagrupar = desagruparDados(alteracoes);
            const reagrupar = agrupar(desagrupar);                       
            return reagrupar
        });
        setLoadingRow(false);
    }
    
    const rowExpansionTemplate = (data) => (
        <TabelaAcompanhamentoExpandida 
            data={data} 
            handleChange={handleChange} 
            todosTiposComissaoCompletos={tiposComissao}
            loadingRow={loadingRow}
            setAcompanhamento={setAcompanhamento}
            acompanhamento={acompanhamento}
            setItensSelecionados={setItensSelecionados} 
            modalConsultor={modalConsultor} 
            modalTipoComissao={modalTipoComissao}
            itensSelecionados={itensSelecionados}
            setModalConsultor={setModalConsultor}
            setModalTipoComissao={setModalTipoComissao}
            setLoadingRow={setLoadingRow}
            fechamento={fechamento}
        />
    )

    return (
        <DataTable
            value={acompanhamento}
            valuelength={acompanhamento?.length}
            expandedRows={expandedRows} 
            onRowToggle={(e) => {                
                const expandRow = manterChavesExclusivas(e.data, expandedRows)                
                setExpandedRows(expandRow)
            }}
            paginator={false}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="vendedorCodigo"
        >
            <Column expander style={{ width: '5rem' }} />
            <Column 
                sortable 
                field="vendedorNome" 
                header="Consultor de Vendas" 
                style={{textAlign: "left", width: "*"}}
                body={(data) => (
                    <div className="font-bold">
                        {data.vendedorNome}
                    </div>
                )}
            />
        </DataTable>
    );
});