import React from 'react';
import {Column} from "primereact/column";
import { DataTable } from '../../components/datatable';

export function VisualizarEventos({eventos}) {

    return (
        <>
            <div className='col-12 p-0'>
                <DataTable 
                    value={eventos}
                    emptyMessage='Nenhum evento encontrado.'
                    valuelength={eventos?.length}
                    rows={100}
                    paginator={false}
                    reorderableRows
                >
                    <Column header="Data" field="registro"/>
                    <Column header="Usuario" field="usuario.nome"/>
                    <Column header="Evento" field="descricao"/>
                </DataTable>
            </div>
        </>
    );
}