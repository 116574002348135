import React from "react"

export const headerTemplate = (title, icon) => {
    return (
        <div className="p-panel-header">
            <div className="flex align-items-center">
                <i className={`fa-solid ${icon} mr-2`} />
                <div className="font-bold">{title}</div>
            </div>
        </div>
    );
}

const star = <i className="fas fa-star text-orange-400"/>;
const starEmpty = <i className="fas fa-star text-gray-200"/>;

export function avaliacaoTemplate(valor) {
  const stars = [];
  for (let i = 1; i <= 5; ++i) {
    if (i <= valor) {
      stars.push(star);
    } else {
      stars.push(starEmpty);
    }
  }
  return stars;
}