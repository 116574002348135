import React, { useCallback, useState } from "react";

import { Column } from "primereact/column";

import { DataTable } from "../../../../components/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { agrupar, calcularComissao, desagruparDados, encontrarChaveParaObjeto, formatarParaReal, formatarString, ordemServicoRowClassName, ordenarFaixas } from "./utils";
import { comissaoFaixaIndividual, comissaoFaixaUnica,  dataTemplate, dataTemplateEditavel, documentoTemplate, icones,  percentualFaixaIndividual, percentualFaixaUnica, razaoSocialTemplate, tipoComissaoTemplate, toggleStatus, valorFaturamento, valorNotaFiscalEditavelTemplate, valorNotaFiscalTemplate } from "./Templates";
import { Tooltip } from "primereact/tooltip";
import { SelectButton } from "../../../../components/selectbutton";
import { optionsComissaoAcompanhamento } from "../../services/comissaoAcompanhamentoService";
import { restricoesButtons } from "../../../../service/ordemServicoService";
import { Dialog } from "../../../../components/dialog";
import { TipoComissaoDropdown } from "../../../../components/dropdown/tipoComissaoDropdown";
import { VendedorAutoComplete } from "../../../../components/autocomplete/VendedorAutoComplete";
import { PanelContent } from "../../../../components/panel";
import { Panel } from "primereact/panel";
import { Ripple } from 'primereact/ripple';

import './Comissao.scss'
import { TIPO_COMISSAO_NAO_IDENTIFICADO } from "../AcompanhamentoIndividual/utils";

export const TabelaAcompanhamentoExpandida = React.memo(({acompanhamento ,data, handleChange, todosTiposComissaoCompletos, loadingRow, 
    setAcompanhamento, itensSelecionados, setItensSelecionados, modalConsultor, modalTipoComissao, setModalConsultor, setModalTipoComissao,
    setLoadingRow, fechamento
}) => {    
    
    const somas = useCallback((itens) => {
        if (itens[0].tipoComissao.descricao === TIPO_COMISSAO_NAO_IDENTIFICADO) {
            return {
                somaValorFaturadoAprovado: 0,
                somaValorBaseCalculoComissaoAprovado: 0,
                somaValorFaturadoGeral: 0,
                somaValorBaseCalculoComissaGeral: 0,
            };
        }
        const somaValorFaturadoAprovado = itens.reduce(function(acumulador, valorAtual) {
            if (valorAtual.status === 'APROVADO' && valorAtual.valorFaturamento) {            
                return acumulador + valorAtual.valorFaturamento;
            }
            return acumulador;
        }, 0);
        
        const somaValorFaturadoGeral = itens.reduce(function(acumulador, valorAtual) {
            return acumulador + valorAtual.valorFaturamento;
        }, 0);

        const somaValorBaseCalculoComissaoAprovado = itens.reduce(function(acumulador, valorAtual) {
            if (valorAtual.status === 'APROVADO' && valorAtual.valorBaseCalculoComissao) {            
                return acumulador + valorAtual.valorBaseCalculoComissao;
            }
            return acumulador;
        }, 0);

        const somaValorBaseCalculoComissaGeral = itens.reduce(function(acumulador, valorAtual) {
            return acumulador + valorAtual.valorBaseCalculoComissao;
        }, 0);
        

        return {
            somaValorFaturadoAprovado,
            somaValorBaseCalculoComissaoAprovado,
            somaValorFaturadoGeral,
            somaValorBaseCalculoComissaGeral,
        };
    }, [])

    const [tipoComissaoNovo, setTipoComissaoNovo] = useState(null);
    const [consultorNovo, setConsultorNovo] = useState(null);
    const acharTipoComissao = (data) => {
        return todosTiposComissaoCompletos?.find(tipoComissao => tipoComissao.id === data[0].tipoComissao?.id)
    }

    const calculoComissaoFaixaUnica = (itens) => {
        const comissaoTotalAprovada = itens.reduce(function(acumulador, valorAtual) {
            if (valorAtual.status === 'APROVADO' && valorAtual.valorComissao) {
                return acumulador + valorAtual.valorComissao;
            }
            return acumulador;
        }, 0);

        const comissaoTotalGeral = itens.reduce(function(acumulador, valorAtual) {
            if (valorAtual.valorComissao) {
                return acumulador + valorAtual.valorComissao;
            }
            return acumulador;
        }, 0);

        const comissaoUnica = {
            comissaoTotalAprovada,
            comissaoTotalGeral,
        }
        
        return comissaoUnica;
    }
    const calculoComissaoFaixaIndividual = (itens, tipoComissao) => {
        let faixas = [];
        let comissaoTotalGeral = 0;
        let comissaoTotalAprovado = 0;

        itens.forEach(item => {
            const faixasOrdenadas = ordenarFaixas(tipoComissao.itens);
            const faixaComtemplada = faixasOrdenadas.find((faixa) => {
                if (faixa.acima && item.valorFaturamento >= faixa.valor) {
                    return true;
                }
        
                if (item.valorFaturamento <= faixa.valor) {
                    return true;
                }
            });
            const comissao = (faixaComtemplada.percentual / 100) * item.valorBaseCalculoComissao;
            comissaoTotalGeral += comissao; 
            
            if(item.status === 'APROVADO') {
                comissaoTotalAprovado += comissao;
                let exists;

                faixas = faixas.map(faixa => {
                    if(faixa?.id === faixaComtemplada?.id){
                        const soma = comissao + faixa.comissao;

                        exists = true;
                        return {
                            ...faixa,
                            comissao: soma
                        }
                    }else{
                        return faixa;
                    }
                })

                if(!exists){
                    faixas.push({
                        ...faixaComtemplada,
                        comissao
                    })
                }

                exists = false;
            }
        })

        return {
            faixas,
            comissaoTotalGeral,
            comissaoTotalAprovado
        }
    }

    const calculoComissaoFaixaSomatoria = (faixas, somaValorBaseCalculoComissaoAprovado, somaValorFaturadoAprovado) => {
        const {comissao, faixaComtemplada} = calcularComissao(somaValorBaseCalculoComissaoAprovado, somaValorFaturadoAprovado, faixas);
    
        const comissaoSomatoria = {
            comissao,
            faixaComtemplada
        }
        
        return comissaoSomatoria;
    }

    const handleAprovarTodos = (event, rowData) => {
        const vendasAtualizadas = rowData?.props?.value?.map(venda => {
            if((venda?.comissaoPaga && venda?.tipoComissao?.validaComissaoPaga) || venda.fechada){
                return venda;
            }

            return {
                ...venda,
                status: event.value,
                modificado: true,
            }
        });        

        const alteracoes = acompanhamento.map(vendedor => {

            if (vendedor.vendedorCodigo !== rowData?.props?.value[0].vendedor?.codigo) {
                return vendedor;
            }
            
            const acompanhamentoCopia = { ...vendedor };

            const chaveEncontrada = encontrarChaveParaObjeto(rowData?.props?.value[0], vendedor.tiposComissao);

            if (!chaveEncontrada || !vendedor.tiposComissao[chaveEncontrada]) {
                return vendedor;
            }

            const tiposComissaoCopia = { ...vendedor.tiposComissao[chaveEncontrada] };
            const dadosCopia = vendasAtualizadas;

            const tiposComissaoAlterados = {
                ...tiposComissaoCopia,
                dados: dadosCopia,
            };
            const vendedorAlterado = {
                ...acompanhamentoCopia,
                tiposComissao: {
                    ...acompanhamentoCopia.tiposComissao,
                    [chaveEncontrada]: tiposComissaoAlterados,
                },
            };
            return vendedorAlterado;
        });

        const desagrupar = desagruparDados(alteracoes);
        const reagrupar = agrupar(desagrupar);
        setAcompanhamento(reagrupar);
    }
    const headerStatusTemplate = (dataTableRowData, dataTableRowInfos) => {
        return (
            <div>
                <div className="mb-2">
                    Aprovar/Reprovar
                </div>
                <SelectButton 
                    pt={{button: ({context}) => ({className: context.selected ? dataTableRowData.status === "APROVADO" ? "sb-success" : "sb-unsuccess" : null})}}
                    onChange={(onChangeValue) =>
                        handleAprovarTodos(onChangeValue, dataTableRowData, dataTableRowInfos)
                    }
                    itemTemplate={restricoesButtons}
                    col={12}
                    name="status"
                    options={optionsComissaoAcompanhamento}
                    naoPodeDesselecionar
                />
            </div>
        )
    }
    const itemHeader = (tipo) => {
        switch (tipo) {
            case "UNICA":
                
                return (
                    <ColumnGroup>
                        <Row>
                            <Column selectionMode="multiple" headerStyle={{ width: "3em", textAlign: "center" }} alignHeader={'center'}/>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="dataEmissao" header="Data de Emissão"/>
                            <Column style={{width: "12em"}} field="notaFiscalNumero" header="Documento" />
                            <Column sortable field="cliente.id" header="Cliente" />
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="valorFaturamento" header="Valor Faturamento"/>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="valorBaseCalculoComissao" header="Valor Base Cálculo Comissão"/>
                            <Column style={{textAlign: "center", width: "4em"}} header="% Comissão"/>
                            <Column style={{textAlign: "center", width: "5em"}} header="Comissão"/>
                            <Column style={{textAlign: "center", width: "5em"}} field="tipoComissao" header="Tipo de Comissão"/>*
                            {/* <Column style={{textAlign: "center", width: "5em"}}/> */}
                            <Column style={{textAlign: "center", width: "13em"}} header={headerStatusTemplate}/>
                        </Row>
                    </ColumnGroup>
                );
                case "FAIXA_INDIVIDUAL":
                return (
                    <ColumnGroup>
                        <Row>
                            <Column selectionMode="multiple" headerStyle={{ width: "3em", textAlign: "center" }} alignHeader={'center'}/>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="dataEmissao" header="Data de Emissão"/>
                            <Column style={{width: "12em"}} field="notaFiscalNumero" header="Documento" />
                            <Column sortable field="cliente.id" header="Cliente" />
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="valorFaturamento" header="Valor Faturamento"/>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="valorBaseCalculoComissao" header="Valor Base Cálculo Comissão"/>
                            <Column style={{textAlign: "center", width: "4em"}} header="% Comissão"/>
                            <Column style={{textAlign: "center", width: "5em"}} header="Comissão"/>
                            <Column style={{textAlign: "center", width: "5em"}} field="tipoComissao" header="Tipo de Comissão"/>
                            <Column style={{textAlign: "center", width: "5em"}}/>
                            <Column style={{textAlign: "center", width: "13em"}} header={headerStatusTemplate}/>
                        </Row>
                    </ColumnGroup>
                );
            default:
                return (
                    <ColumnGroup>
                        <Row>
                            <Column selectionMode="multiple" headerStyle={{ width: "3em", textAlign: "center" }} alignHeader={'center'}/>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="dataEmissao" header="Data de Emissão"/>
                            <Column style={{width: "12em"}} field="notaFiscalNumero" header="Documento"/>
                            <Column sortable field="cliente.id" header="Cliente"/>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="valorFaturamento" header="Valor Faturamento"/>
                            <Column sortable style={{textAlign: "center", width: "5em"}} field="valorBaseCalculoComissao" header="Valor Base Cálculo Comissão"/>
                            <Column style={{textAlign: "center", width: "5em"}} field="tipoComissao" header="Tipo de Comissão"/>
                            <Column style={{textAlign: "center", width: "5em"}}/>
                            <Column style={{textAlign: "center", width: "13em"}} header={headerStatusTemplate}/>
                        </Row>
                    </ColumnGroup>
                );
            }
    }

    const itemFooter = (
        tipo, 
        somaValorFaturadoGeral, 
        somaValorFaturadoAprovado, 
        somaValorBaseCalculoComissaGeral, 
        somaValorBaseCalculoComissaoAprovado, 
        comissaoTotalGeral,
        comissaoTotalAprovada, 
        faixaComtemplada, 
        somaFaixasAtingidas
    ) => {
        switch (tipo) {
            case 'UNICA':
                return (
                    <ColumnGroup>
                        <Row>
                            <Column colSpan={4}/>
                            <Column footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                         {formatarParaReal(somaValorFaturadoGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                        {formatarParaReal(somaValorBaseCalculoComissaGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column   />
                            <Column footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                        {formatarParaReal(comissaoTotalGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column  colSpan={3} />
                        </Row>
                        {
                            comissaoTotalAprovada > 0 &&
                            <Row>
                                <Column 
                                    colSpan={2} 
                                    footer={
                                        <div className="flex justify-content-center" >
                                            <strong className="">
                                                <Tooltip target=".custom-target-icon" />
                                                <i 
                                                    className="custom-target-icon fa-solid fa-circle-question mr-2"
                                                    data-pr-tooltip={'Valor aprovado é a soma dos valores faturados que foram aprovados pelo supervisor comercial para compor o cálculo das comissões'}
                                                    data-pr-at="left-0 bottom"
                                                    data-pr-my="center top-0"
                                                    style={{ fontSize: '1.4rem', cursor: 'pointer', top: '-0.5rem'}}
                                                />
                                                Aprovado 
                                            </strong>
                                        </div>
                                    }
                                    className="background-comissao cinza border-900 border-2 "
                                />
                                <Column className="background-comissao cinza border-900 border-2" colSpan={1} footer={
                                    <div className="flex justify-content-end"> 
                                        <strong className=""> 
                                            {`${formatarParaReal(somaValorBaseCalculoComissaoAprovado)}` }
                                        </strong>
                                    </div>
                                }/>
                                <Column  colSpan={7} />
                            </Row>
                        }
                        {  
                            comissaoTotalAprovada > 0 &&
                            <Row>
                                <Column colSpan={2} className="border-900 border-2 background-comissao azul" footer={
                                    <div className="flex justify-content-center"> 
                                        <strong className="text-white ">
                                            {`Total a receber`}
                                        </strong>
                                    </div>
                                }/>
                                <Column colSpan={1} className="border-900 border-2 background-comissao verde" footer={
                                    <div className="flex  justify-content-end"> 
                                        <strong className="">
                                            {`${formatarParaReal(comissaoTotalAprovada)}`}
                                        </strong>
                                    </div>
                                }/>
                                <Column  colSpan={7} />
                            </Row>
                        }
                    </ColumnGroup>
                );
            case 'FAIXA_INDIVIDUAL':
                return (
                    <ColumnGroup>
                        <Row>
                            <Column colSpan={4}/>
                            <Column footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                         {formatarParaReal(somaValorFaturadoGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                        {formatarParaReal(somaValorBaseCalculoComissaGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column   />
                            <Column footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                        {formatarParaReal(comissaoTotalGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column  colSpan={3} />
                        </Row>
                        {
                            somaFaixasAtingidas.length > 0 && 
                            somaFaixasAtingidas.map((item, index) => {
                                return (
                                    <Row>
                                        <Column 
                                            colSpan={2} 
                                            footer={
                                                <div className="flex" >
                                                    <strong>
                                                        {`${item?.percentual}% ${item?.acima ? 'acima de' : 'até'} ${formatarParaReal(item?.valor)}`}
                                                    </strong>
                                                </div>
                                            }
                                            className="border-900 border-2 "
                                        />
                                        <Column 
                                            colSpan={1} 
                                            footer={
                                                <div className="flex justify-content-end" >
                                                    <strong>
                                                        {`${formatarParaReal(item?.comissao)}`}
                                                    </strong>
                                                </div>
                                            }
                                            className="border-900 border-2 background-comissao verde"
                                        />
                                        <Column  colSpan={7} />
                                    </Row>
                                )
                            })
                        }
                        {  
                            comissaoTotalAprovada > 0 &&
                            <Row>
                                <Column colSpan={2} className="border-900 border-2 background-comissao azul" footer={
                                    <div className="flex justify-content-center"> 
                                        <strong className="text-white ">
                                            {`Total a receber`}
                                        </strong>
                                    </div>
                                }/>
                                <Column colSpan={1} className="border-900 border-2 background-comissao verde" footer={
                                    <div className="flex  justify-content-end"> 
                                        <strong className="">
                                            {`${formatarParaReal(comissaoTotalAprovada)}`}
                                        </strong>
                                    </div>
                                }/>
                                <Column  colSpan={7} />
                            </Row>
                        }
                    </ColumnGroup>
                );
            case 'FAIXA':
            default:
                return (
                    <ColumnGroup>
                        <Row>
                            <Column colSpan={4}/>
                            <Column colSpan={1} footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                        {formatarParaReal(somaValorFaturadoGeral)}
                                    </strong>
                                </div>
                            }/>
                            <Column colSpan={1} footer={
                                <div className="flex justify-content-end"> 
                                    <strong>
                                        {formatarParaReal(somaValorBaseCalculoComissaGeral)}
                                    </strong>
                                </div>
                            }/>

                            <Column  colSpan={3} />
                        </Row>
                        {
                            comissaoTotalAprovada > 0 &&
                            <Row>
                                <Column 
                                    colSpan={2} 
                                    footer={
                                        <div className="flex justify-content-center" >
                                            <strong className="">
                                                <Tooltip target=".custom-target-icon" />
                                                <i 
                                                    className="custom-target-icon fa-solid fa-circle-question mr-2"
                                                    data-pr-tooltip={'Valor aprovado é a soma dos valores faturados que foram aprovados pelo supervisor comercial para compor o cálculo das comissões'}
                                                    data-pr-at="left-0 bottom"
                                                    data-pr-my="center top-0"
                                                    style={{ fontSize: '1.4rem', cursor: 'pointer', top: '-0.5rem'}}
                                                />
                                                Aprovado 
                                            </strong>
                                        </div>
                                    }
                                    className="background-comissao cinza border-900 border-2 "
                                />
                                <Column className="background-comissao cinza border-900 border-2" colSpan={1} footer={
                                    <div className="flex justify-content-end"> 
                                        <strong className=""> 
                                            {`${formatarParaReal(somaValorBaseCalculoComissaoAprovado)}`}
                                        </strong>
                                    </div>
                                }/>
                                <Column  colSpan={5} />
                            </Row>
                        }
                        {
                            comissaoTotalAprovada > 0 &&
                            <Row>
                                <Column 
                                    colSpan={2} 
                                    footer={
                                        <div className="flex justify-content-center" >
                                            <strong className="text-white ">
                                                {`Faixa contemplada`}
                                            </strong>
                                        </div>
                                    }
                                    className="border-900 border-2 background-comissao azul"
                                />
                                <Column 
                                    colSpan={1} 
                                    footer={
                                        <div className="flex justify-content-center" >
                                            <strong className="text-white ">
                                                {`Comissão`}
                                            </strong>
                                        </div>
                                    }
                                    className="background-comissao azul border-900 border-2 "
                                />
                                <Column  colSpan={5} />
                            </Row>
                        }
                        {
                            comissaoTotalAprovada > 0 &&
                            <Row>
                                <Column 
                                    colSpan={2} 
                                    footer={
                                        <div className="flex" >
                                            <strong>
                                                {`${faixaComtemplada.percentual}% ${faixaComtemplada.acima ? 'acima de' : 'até'} ${formatarParaReal(faixaComtemplada.valor)}`}
                                            </strong>
                                        </div>
                                    }
                                    className="border-900 border-2 "
                                />
                                <Column 
                                    colSpan={1} 
                                    footer={
                                        <div className="flex justify-content-end" >
                                            <strong>
                                                {`${formatarParaReal(comissaoTotalAprovada)}`}
                                            </strong>
                                        </div>
                                    }
                                    className="border-900 border-2 background-comissao verde"
                                />
                                <Column  colSpan={5} />
                            </Row>
                        }
                    </ColumnGroup>
                );
        }
    }

    const tiposComissao = data.tiposComissao;
    if (!tiposComissao) {
        return null; // Retorna nulo se tiposComissao for indefinido ou nulo
    }
    let comissaoGeralTotal = 0;

    const handleUpdateConsultor = () => {
        setLoadingRow(true)
        const desagrupar = desagruparDados([...acompanhamento]);
        
        const acompanhamentoAtualizado = desagrupar.map(elemento => {
            if(itensSelecionados.find(item => item.id === elemento.id)){
                return {
                    ...elemento,
                    vendedor: consultorNovo,
                    modificado: true,
                }
            }else{
                return elemento;
            }
        })

        const reagrupar = agrupar(acompanhamentoAtualizado);
        setAcompanhamento(reagrupar);
        setConsultorNovo(null)
        setModalConsultor(false);
        setItensSelecionados([]);
        setLoadingRow(false)
    }
    const handleUpdateTipoComissao = async () => {
        setLoadingRow(true)

        await itensSelecionados.forEach(item => {            
            handleChange({name: 'tipoComissao', value: tipoComissaoNovo} , item)
        })

        setTipoComissaoNovo(null)
        setModalTipoComissao(false);
        setItensSelecionados([]);
        setLoadingRow(false)
    }
    const handleCloseModalConsultor = () => {
        setModalConsultor(false)
        setConsultorNovo(null)
    }
    const handleCloseModalTipoComissao = () => {
        setModalTipoComissao(false)
        setTipoComissaoNovo(null)
    }
    const dataTableComponents = Object.keys(tiposComissao).map((tipoComissao, index) => {
        let tipoComissaoData = tiposComissao[tipoComissao]?.dados || [];

        const {
            somaValorBaseCalculoComissaoAprovado,
            somaValorFaturadoAprovado,
            somaValorBaseCalculoComissaGeral,
            somaValorFaturadoGeral,
        } = somas(tipoComissaoData);

        const tipoComissaoAtual = acharTipoComissao(tipoComissaoData);

        let comissaoTotalGeral = 0;
        let comissaoTotalAprovada = 0;
        let faixaComtemplada = null;
        let somaFaixasAtingidas = null;

        if(tipoComissaoAtual?.tipo === 'UNICA'){
            const comissaoUnica = calculoComissaoFaixaUnica(tipoComissaoData);

            comissaoTotalAprovada = comissaoUnica.comissaoTotalAprovada;
            comissaoTotalGeral = comissaoUnica.comissaoTotalGeral;
        }

        if(tipoComissaoAtual?.tipo === 'FAIXA_INDIVIDUAL'){
            const comissaoIndividual = calculoComissaoFaixaIndividual(tipoComissaoData, tipoComissaoAtual);
            comissaoTotalGeral = comissaoIndividual.comissaoTotalGeral;
            comissaoTotalAprovada = comissaoIndividual.comissaoTotalAprovado;
            somaFaixasAtingidas = comissaoIndividual.faixas;
        }

        if(tipoComissaoAtual?.tipo === 'FAIXA_SOMA'){
            const comissaoSoma = calculoComissaoFaixaSomatoria(tipoComissaoAtual.itens, somaValorBaseCalculoComissaoAprovado, somaValorFaturadoAprovado);

            comissaoTotalAprovada = comissaoSoma.comissao;
            faixaComtemplada = comissaoSoma.faixaComtemplada;
        }

        comissaoGeralTotal += comissaoTotalAprovada;

        const servicoHeaderGroup = itemHeader(tipoComissaoAtual?.tipo);
        const servicoFooterGroup = itemFooter(
            tipoComissaoAtual?.tipo, 
            somaValorFaturadoGeral, 
            somaValorFaturadoAprovado, 
            somaValorBaseCalculoComissaGeral, 
            somaValorBaseCalculoComissaoAprovado,
            comissaoTotalGeral, 
            comissaoTotalAprovada, 
            faixaComtemplada, 
            somaFaixasAtingidas,
        );

        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} ml-2 text-primary`;
            const style = { fontSize: '1.25rem' };
    
            return (
                <div className={className}>
                    <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                        <span className={toggleIcon}></span>
                        <Ripple />
                    </button>
                    <span className={titleClassName} style={style}>
                        {`${formatarString(tipoComissao.trim())} - ${formatarParaReal(comissaoTotalAprovada)}`}
                    </span>
                </div>
            );
        };
 
        return (
            <Panel 
                headerTemplate={template} 
                toggleable 
                key={tipoComissao} 
                className="border-2 border-round mb-3 acompanhamento-panel"
                collapsed
            >
                {/* <div key={tipoComissao} className="border-2 border-round mb-3"> */}
                {/* <div className="flex justify-content-between pt-3 pl-3 pr-3 m-0 surface-700">
                    <h5 className="text-white font-bold text-xl line-height-1">{`${formatarString(tipoComissao)} - ${formatarParaReal(comissaoTotalAprovada)}`}</h5>
                </div> */}
                <DataTable
                    value={tipoComissaoData}
                    footer={false}
                    headerColumnGroup={servicoHeaderGroup}
                    footerColumnGroup={servicoFooterGroup}
                    paginator={tipoComissaoData.length > 30}
                    rows={30}
                    dataKey={"id"}
                    loading={loadingRow}
                    rowClassName={ordemServicoRowClassName}
                    selectionMode="checkbox"
                    selection={itensSelecionados}
                    onSelectionChange={e => setItensSelecionados(e.value)}
                    showSelectAll={!fechamento?.id}

                >   
                    {
                        !fechamento?.id ?
                        <Column 
                            selectionMode="multiple" 
                            headerStyle={{ width: '3em', textAlign: 'center' }} 
                            alignHeader={'center'}
                        
                        /> :
                    
                        <Column 
                            headerStyle={{ width: '3em', textAlign: 'center' }} 
                            alignHeader={'center'}
                        />
                    }

                    {
                        tipoComissaoAtual?.tipo === 'UNICA' ?
                            <Column sortable field="dataEmissao" header="Data de Emissão" body={(dataTableRowData, dataTableRowInfos) => dataTemplateEditavel(dataTableRowData, dataTableRowInfos, handleChange)}/>
                        :   <Column sortable field="dataEmissao" header="Data de Emissão" body={dataTemplate}/>
                    }
                    <Column header="Documento" body={documentoTemplate}/>
                    <Column field="cliente" header="Cliente" body={razaoSocialTemplate}/>
                    <Column  field="valorFaturamento" align={'right'} header="Valor Faturamento" body={valorFaturamento} sortable />
                    <Column
                        field="valorBaseCalculoComissao"
                        header="Valor Base Cálculo Comissão"
                        body={(dataTableRowData, dataTableRowInfos) => valorNotaFiscalEditavelTemplate(dataTableRowData, dataTableRowInfos, handleChange) }
                        sortable
                    />
                    {tipoComissaoAtual?.tipo === 'UNICA' && <Column body={(dataTableRowData, dataTableRowInfos) => percentualFaixaUnica(dataTableRowData, dataTableRowInfos, handleChange)}/>}
                    {tipoComissaoAtual?.tipo === 'UNICA' && <Column body={(dataTableRowData, dataTableRowInfos) => comissaoFaixaUnica(dataTableRowData, dataTableRowInfos, handleChange)}/>}
                    {tipoComissaoAtual?.tipo === 'FAIXA_INDIVIDUAL' && <Column body={(dataTableRowData) => percentualFaixaIndividual(dataTableRowData, tipoComissaoAtual)}/>}
                    {tipoComissaoAtual?.tipo === 'FAIXA_INDIVIDUAL' && <Column body={(dataTableRowData, dataTableRowInfos) => comissaoFaixaIndividual(dataTableRowData, tipoComissaoAtual)}/>}
                    <Column body={(dataTableRowData, dataTableRowInfos) => tipoComissaoTemplate(dataTableRowData, dataTableRowInfos, todosTiposComissaoCompletos ,handleChange)}/>
                    <Column body={(dataTableRowData) => icones(dataTableRowData)}/>
                    <Column body={(dataTableRowData, dataTableRowInfos) => toggleStatus(dataTableRowData, dataTableRowInfos, handleChange)}/>
                </DataTable>
            {/* </div> */}
            </Panel>
        );
    });

    
    return (
        <div className="border-round">
            <div className="p-3 w-full border-2 border-round bg-green-900 mb-3 shadow-5 text-center	">
                <strong className="text-white text-2xl"> 
                    {`Total de comissão a receber - ${formatarParaReal(comissaoGeralTotal)}`}
                </strong> 
            </div>
            {dataTableComponents}
            <Dialog
                onHide={handleCloseModalConsultor}
                visible={modalConsultor}
                style={{ width: '50vw' }}
                header='Trocar consultor de venda'
                footer='escolha'
                rejectLabel='Fechar'
                acceptLabel='Confirmar'	
                onYes={handleUpdateConsultor}
                onNo={handleCloseModalConsultor}
            >
                <VendedorAutoComplete 
                    col={12} 
                    value={consultorNovo} 
                    name="vendedor" 
                    onChange={(e) => setConsultorNovo(e.value)} 
                    label="Consultor de Vendas"
                />

            </Dialog>    
            <Dialog
                onHide={handleCloseModalTipoComissao}
                visible={modalTipoComissao}
                style={{ width: '50vw' }}
                header='Trocar tipo de comissão'
                footer='escolha'
                rejectLabel='Fechar'
                acceptLabel='Confirmar'	
                onYes={handleUpdateTipoComissao}
                onNo={handleCloseModalTipoComissao}
            >
                <PanelContent>
                    <TipoComissaoDropdown
                        value={tipoComissaoNovo}
                        tiposComissao={todosTiposComissaoCompletos}
                        label="Tipo de comissão"
                        name="tipoComissao"
                        onChange={(e) => {
                            setTipoComissaoNovo(e.value)
                        }}
                        col={12}
                    />
                </PanelContent>
            </Dialog>    
        </div>
    );
});