import { api } from "./api";
import {baseService} from "./baseService";

const resourceUrl = '/relatorios-servicos';

export const relatoriosServicoService = {
    
    async baixarRelatorio(relatorio,criterio) {		
		return await api.get(`${resourceUrl}/${relatorio}?${criterio.join("&")}`, {}, {responseType: "blob"}).then(response => response.data);
	},
	...baseService(resourceUrl)
};

export const modelosRelatorio = [
	{label: "Relatório de Ordens de Serviço", value: "ROS"},
];

