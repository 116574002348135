import React, {useContext, useEffect, useState} from "react";
import {Dialog, DialogFooter} from "../../../components/dialog";
import {PanelContent} from "../../../components/panel";
import {Button} from "../../../components/button";
import {DataTable} from "../../../components/datatable";
import moment from "moment";
import {Column} from "primereact/column";
import {DialogContext} from "../../../utils/dialogContext";
import {EditarTempoParado} from "./EditarTempoParado";
import {ordemServicoPendenciaService, ordemServicoTempoParadoService} from "../../../service/ordemServicoService";
import {ConfirmDialogV2} from "../../../components/confirmdialog";
import {If} from "../../../components/conditional/If";
import {ProgressDialog} from "../../../components/progressdialog/ProgressDialog";
import {BlockUI} from "primereact/blockui";
import {formatMinutes} from "../../../utils/numberFormatter";
import {useAuth} from "../../../context/AuthContext";

export function TemposParados({ordemServico, onModalClose}) {

    const {usuario} = useAuth();
    const [loading, setLoading] = useState(true);
    const {showDialog} = useContext(DialogContext);
    const [visible, setVisible] = useState(true);
    const [temposParados, setTemposParados] = useState([]);
    const [selecionados, setSelecionados] = useState([]);

    useEffect(() => {
        ordemServicoTempoParadoService.listar(ordemServico.id).then(temposParados => {
            setTemposParados(temposParados);
            setLoading(false);
        });
    }, [ordemServico.id]);

    async function handleSalvar() {
        if (temposParados.length) {
            showDialog(<ProgressDialog onProgress={async setProgress => {
                let progress = 0;
                for (const tempoParado of temposParados) {
                    await ordemServicoTempoParadoService.salvar(tempoParado);
                    setProgress(++progress / temposParados.length);
                }
                if (onModalClose) {
                    onModalClose();
                }
                handleClose();
            }}/>);
        } else {
            handleClose();
        }
    }

    function handleClose() {
        setVisible(false);
    }

    function adicionarTempoParado() {
        editarTempoParado({
            ...ordemServicoTempoParadoService.criar(),
            ordemServico: {id: ordemServico.id},
            responsavel: usuario
        });
    }

    function editarTempoParado(tempoParado, finalizar = false) {
        if (finalizar && !tempoParado.fim) {
            tempoParado.fim = moment().format("YYYY-MM-DDTHH:mm:ss");
        }
        showDialog(<EditarTempoParado tempoParado={tempoParado} onChange={d => {
            setTemposParados(prevTemposParados => {
                const index = prevTemposParados.findIndex(pd => (pd.id && pd.id === d.id) || (pd._key && pd._key === d._key));
                if (index > -1) {
                    prevTemposParados[index] = d;
                } else {
                    prevTemposParados.push(d);
                }
                return [...prevTemposParados];
            });
        }}/>);
    }

    function removerTemposParados() {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover as esperas selecionadas?" onYes={async () => {
            showDialog(<ProgressDialog onProgress={async setProgress => {
                let progress = 0;
                for (const selecionado of selecionados) {
                    if (selecionado.id) {
                        await ordemServicoTempoParadoService.delete(selecionado.id, ordemServico.id);
                    }
                    setProgress(++progress / selecionados.length);
                }
            }}/>);
            setTemposParados(prevTemposParados => [...prevTemposParados.filter(dd => (dd.id && !selecionados.some(ss => ss.id === dd.id)) || (dd._key && !selecionados.some(ss => ss._key === dd._key)))]);
            setSelecionados([]);
        }}/>);
    }

    const controlSet = (
        <div>
            <Button disabled={temposParados.some(dd => !dd.fim)} icon="fas fa-plus" onClick={adicionarTempoParado}/>
            <Button disabled={!selecionados.length} icon="fas fa-minus" danger onClick={removerTemposParados}/>
        </div>
    );

    return (
        <Dialog header={`Registro de Esperas para a OS ${ordemServico.numero}`} visible={visible} style={{width: "600px"}} onHide={handleClose}>
            <BlockUI blocked={loading}>
                <PanelContent>
                    <div>
                        <div>{ordemServico.cliente?.autoComplete}</div>
                        <div>{ordemServico.cliente?.endereco}</div>
                    </div>
                    <div className="col-12">
                        <DataTable selection={selecionados}
                                   onSelectionChange={e => setSelecionados(e.value)}
                                   selectionMode="checkbox"
                                   header={controlSet}
                                   footer={controlSet}
                                   value={temposParados}>
                            <Column style={{width: "3em"}} selectionMode="multiple"/>
                            <Column style={{width: "33%"}} header="Início" field="inicio" body={d => (
                                <div>
                                    <div><i className="fas fa-calendar"/> {moment(d.inicio).format("DD/MM/YYYY HH:mm")}</div>
                                </div>
                            )}/>
                            <Column style={{width: "33%"}} header="Fim" field="fim" body={d => (
                                <If condition={d.fim}>
                                    <div><i className="fas fa-calendar"/> {moment(d.fim).format("DD/MM/YYYY HH:mm")}</div>
                                </If>
                            )}/>
                            <Column style={{width: "33%"}} header="Tempo" field="fim" body={d => (
                                <If condition={d.fim}>
                                    <div><i className="fas fa-clock"/> {formatMinutes(moment(d.fim).diff(moment(d.inicio), "minutes"))}</div>
                                </If>
                            )}/>
                            <Column header="Ações" style={{width: "5em", whiteSpace: "nowrap"}} body={d => (
                                <Button icon="fas fa-edit" onClick={() => editarTempoParado(d, true)}/>
                            )}/>
                        </DataTable>
                    </div>
                </PanelContent>
                <DialogFooter>
                    <Button icon="fas fa-save" success label="Salvar" onClick={handleSalvar}/>
                    <Button icon="fas fa-times" secondary label="Fechar" onClick={handleClose}/>
                </DialogFooter>
            </BlockUI>
        </Dialog>
    );

}
