export function findRestricoesBloqueantes(ordemServico, options = {}) {
    let restricoes = [];
    if (ordemServico.cancelada) {
        return restricoes;
    }
    if (ordemServico?.restricoes?.length) {
        for (const restricao of ordemServico.restricoes) {
            if (["LAUDO", "RESSOLDA"].includes(restricao) && options.excetoLaudo) {
                continue;
            }
            switch (restricao) {
                case "ESTOQUE":
                case "COMPRAS":
                    if (ordemServico.status !== "LAUDO") {
                        restricoes.push(restricao);
                    }
                    break;
                case "__ORCAMENTO_APROVADO":
                case "__ORCAMENTO_REPROVADO":
                    break;
                default:
                    restricoes.push(restricao);
                    break;
            }
        }
    }
    return restricoes;
}
