import React, {useMemo} from "react";
import {BlockUI} from "primereact/blockui";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {useQueryClientAcordoNivelServico} from "../../utils/query-clients/queryClients";
import {TabelaANS} from "./TabelaANS";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {If} from "../../components/conditional/If";
import {Button} from "../../components/button";
import {Panel} from "primereact/panel";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";

export function ANS() {

	const {roles} = useAuth();
	const navigate = useNavigate();
	const {query, handleSearch} = useQueryClientAcordoNivelServico();
	const {params, handleChange} = useQueryParams(QueryParamsKeys.AcordoNivelServico);

	function handleCriar() {
		navigate("/sla/modelos/new");
	}

	return (
		<BlockUI blocked={query.isLoading}>
			<Panel headerTemplate={() => headerTemplate("Modelos de SLA", "fa-signature")}>
				<PanelContent>
					<InputText placeholder="a descrição do SLA" col={12} label="Descrição" value={params.descricao} name="descricao" onChange={handleChange}/>
				</PanelContent>
				<PanelFooter>
					<If condition={!!roles.ANSA}>
						<Button success onClick={handleCriar} label="Novo" icon="pi pi-plus"/>
					</If>
					<Button info label="Procurar" disabled={query.isLoading || query.isRefetching} loading={query.isLoading || query.isRefetching} onClick={handleSearch} icon="pi pi-search"/>
				</PanelFooter>
			</Panel>
			{useMemo(() => <TabelaANS value={query.data || []}/>, [query.data])}
			<RefetchIndicator isRefetching={query.isRefetching}/>
		</BlockUI>
	);

}
