import { api } from "../../../service/api";
import { baseService } from "../../../service/baseService";

const resourceUrl = "/indices";

export const indiceService = {   
    verificarPath: async (path) => {
        return api.get(`${resourceUrl}/verificar-path`, {
            params: {path: path}
        }).then(res => res.data);
    },

    async cotacao(params) {
		return await api.get(`${resourceUrl+'/cotacao'}?${params.join("&")}`).then(response => response.data);		
    },
    ...baseService(resourceUrl)
};
