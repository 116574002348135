import { debounce } from 'lodash';

import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import React, { useCallback, useState, useMemo } from 'react';
import { ProdutoAutoComplete } from '../../../components/autocomplete/produtoAutoComplete';
import { Button } from '../../../components/button';
import { Calendar } from "../../../components/calendar";
import { DataTable } from '../../../components/datatable';
import { InputCurrency } from '../../../components/inputnumber';
import { InputText } from '../../../components/inputtext';
import { withDialog } from "../../../utils/dialogContext";
import { ConfirmDialogV2 } from "../../../components/confirmdialog";
import { FilterMatchMode } from 'primereact/api';
import { isBiggerThanZeroValor, isEntityRequired, isRequired } from '../../../utils/fieldValidator';
import moment from 'moment';
import { useAuth } from '../../../context/AuthContext';
import { Dialog } from '../../../components/dialog';
import { InputTextAreaFull } from '../../../components/inputtextarea/InputTextAreaFull';

const { v4: uuidv4 } = require('uuid');

export const EditarDadosPrincipais = withDialog(({ listaPreco, setListaPreco, messages, setMessages, showDialog, onSalvar }) => {

    const {roles} = useAuth();

    const validarItem = useCallback((e, index, data) => {
        let erro;
        let itens = [];

        switch (e?.target.name) {
            case 'produto':                
                erro = isEntityRequired(data.produto)
                itens = [...messages.itens]
                itens[index] = { ...messages.itens[index], produto: erro }
                break;
            case 'preco':
                erro = isBiggerThanZeroValor(data.preco)
                itens = [...messages.itens]
                itens[index] = { ...messages.itens[index], preco: erro }
                break;
            default:
                break;
        }

        setMessages((messages) => {
            return {
                ...messages,
                itens
            }
        })
    }, [messages.itens, setMessages])

    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalObservacao, setModalObservacao] = useState(false);
    const [modalData, setModalData] = useState(null);

    const debouncedGlobalFilterChange = useCallback(
        debounce((value) => {
            const _filters = { ...filters };
            _filters['global'].value = value;
            setFilters(_filters);
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 200); // Atraso de 200 milissegundos
        }, 500), // Debounce de 500 milissegundos
        [filters]
    );

    const onGlobalFilterChange = useCallback((e) => {
        const value = e.value;
        setGlobalFilterValue(value);
        debouncedGlobalFilterChange(value);
    }, [debouncedGlobalFilterChange]);

    const handleChange = useCallback((event) => {
        setListaPreco((prevListaPreco) => ({ ...prevListaPreco, [event.name]: event.value }));
    }, [setListaPreco]);

    const handleChangeItem = useCallback((rowData, event) => {
        listaPreco.itens.filter(lpi => (lpi.id && lpi.id === rowData.id)).forEach(lpi => {
            lpi[event.name] = event.value;
            // lpi.ultimaAtualizacao = moment().format('YYYY-MM-DDTHH:mm:ss'); 
            lpi.alterado = true;           
        });
        setListaPreco({ ...listaPreco });
    }, [listaPreco, setListaPreco]);

    const body = useMemo(() => (rowData, props) => {
        switch (props.field) {
            case 'preco':
                return <InputCurrency
                    value={rowData.preco}
                    min={0}
                    name="preco"
                    onChange={(e) => handleChangeItem(rowData, e)}
                    onBlur={(e) => validarItem(e, props.rowIndex, rowData)}
                    invalid={messages.itens?.[props.rowIndex]?.preco}
                    reduced={!messages.itens?.[props.rowIndex]?.preco}
                />;
            case 'produto':
                return <ProdutoAutoComplete
                    col={12}
                    value={rowData.produto}
                    onChange={(e) => handleChangeItem(rowData, e)}
                    name="produto"
                    label={null}
                    onBlur={(e) => validarItem(e, props.rowIndex, rowData)}
                    invalid={messages.itens?.[props.rowIndex]?.produto}
                    reduced={!messages.itens?.[props.rowIndex]?.produto}
                />;
            case 'ultimaAtualizacao':
                if(rowData.status === 'ERRO' || !rowData?.id || !rowData?.ultimaAtualizacao) return;
                return (
                    <div className="w-full flex flex-column justify-content-center align-content-center">
                        <strong className="text-center">{moment(rowData.ultimaAtualizacao).format("DD/MM/YYYY HH:mm")}</strong>
                        {rowData?.alteradoPor && <div className="text-sm font-italic text-center">{`Alterado por: ${rowData.alteradoPor}`}</div>}
                    </div>
                )
            case 'incluidoPor':                
                if(!rowData?.id  || !rowData?.registro) return;
                return (
                    <div className="w-full flex flex-column justify-content-center align-content-center">
                        <strong className="text-center">{moment(rowData.registro).format("DD/MM/YYYY HH:mm")}</strong>
                        {rowData.incluidoPor && <div className="text-sm font-italic text-center">{`Adicionado por: ${rowData.incluidoPor}`}</div>}
                    </div>
                )
            case 'observacao':
                return (
                    <div 
                        className="w-full y-full flex justify-content-center align-content-center "
                    >
                        <div
                            onClick={() =>{ 
                                setModalData(rowData)
                                setModalObservacao(true)
                            }}
                            title={`${rowData.observacao?.length > 0 ? 'Observação' : 'Sem Observações'}`}
                            className="cursor-pointer"
                        >
                            <i 
                                className={`fa-solid fa-comment${rowData.observacao?.length > 0 ? '' : '-slash'} fa-xl `}
                                style={{color: `${rowData.observacao?.length > 0 ? '#575454' : '#F44336'}`}}
                            />
                        </div>
                    </div>
                )
            default:
                return null;
        }
    }, [handleChangeItem, messages.itens, validarItem]);

    const handleChangeModalData = useCallback((event) => {
        setModalData((prevListaPreco) => ({ ...prevListaPreco, [event.name]: event.value }));
    }, []);

    const adicionar = useCallback(() => {
        const adicionarItens = [
            {
                id: uuidv4(),
                produto: null,
                preco: 0,
                alterado: true
                // ultimaAtualizacao: moment().format('YYYY-MM-DDTHH:mm:ss')
            },
            ...listaPreco.itens
        ];        
        setListaPreco({ ...listaPreco, itens: adicionarItens });
    }, [listaPreco, setListaPreco]);

    const excluir = useCallback(() => {
        showDialog(
            <ConfirmDialogV2
                message="Tem certeza de que deseja remover os itens selecionados?"
                onYes={() => {
                    let itensRemover = [];
                    if (Array.isArray(listaPreco.itensRemover)) {
                        itensRemover = [...listaPreco.itensRemover];
                    }
    
                    if (Array.isArray(itensSelecionados)) {
                        itensRemover.push(...itensSelecionados);
                    }
    
                    const itens = listaPreco.itens.filter((osi) =>
                        !itensSelecionados.some((i) => i.id && osi.id === i.id)
                    );
    
                    if (!listaPreco.id) {
                        itens.forEach((osi, i) => osi.item = i + 1);
                    }
    
                    handleChange({ name: "itens", value: itens });
                    handleChange({ name: "itensRemover", value: itensRemover });
                    setItensSelecionados([]);
                }}
            />
        );
    }, [showDialog, listaPreco, itensSelecionados, handleChange]);

    const itemControlSet = useMemo(() => (
        <div>
            <Button icon="pi pi-plus" onClick={adicionar} disabled={!roles.LTPC}/>
            <Button disabled={!itensSelecionados?.length || !roles.LTPD} onClick={excluir} danger icon="pi pi-minus" />
            <Button icon="pi pi-save" style={{float: "right", width: "auto", minWidth: "100px"}} disabled={loading || !roles.LTPC} loading={loading} success label="Salvar" onClick={onSalvar}/>
        </div>
    ), [adicionar, excluir, itensSelecionados.length, loading, onSalvar, roles.LTPC, roles.LTPD]);

    let headerGroup = useMemo(() => (
        <ColumnGroup>
            <Row>
                <Column colSpan={6} header={itemControlSet} />
            </Row>
            <Row>
                <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
                <Column header="Produto" name="produto" />
                <Column sortable header="Preço" field="preco" />
                <Column sortable header="Última Atualização" field="ultimaAtualizacao" />
                <Column sortable header="Adicionado em" field="alteradoPor" />
                <Column header='Observação' headerStyle={{ width: "10em" }} />
            </Row>
        </ColumnGroup>
    ), [itemControlSet]);

    return (
        <>
            <Calendar col={2} disabled name="registro" showTime showSeconds label="Data de Inclusão" value={listaPreco.registro} onChange={handleChange} />
            <InputText
                col={10}
                name="descricao"
                label="Descrição"
                value={listaPreco.descricao}
                onChange={handleChange}
                invalid={messages.descricao}
                onBlur={() => {
                    messages.descricao = isRequired(listaPreco.descricao);
                    setMessages({ ...messages });
                }}
            />

            <InputText
                col={12}
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Código ou descrição"
                reduced
                label='Filtro'
            />

            <div className='col-12 pt-0'>
                  <DataTable
                    emptyMessage='Nenhum item adicionado.'
                    paginator={true}
                    rows={50}
                    dataKey={"id"}
                    filters={filters}
                    loading={loading}
                    globalFilterFields={['preco', 'produto.descricao', 'produto.codigo', 'produto.autoComplete']}
                    headerColumnGroup={headerGroup}
                    selectionMode="checkbox"
                    selection={itensSelecionados}
                    onSelectionChange={e => setItensSelecionados(e.value)}
                    value={listaPreco.itens}
                    valuelength={listaPreco.itens?.length}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '1em' }}></Column>
                    <Column header="Produto" style={{ width: '*' }} field="produto" name="produto" body={body} />
                    <Column header="Preço" style={{ width: '12em' }} field="preco" name="preco" body={body} />
                    <Column header="Ultima Atualização"  style={{ width: '12em'}} field="ultimaAtualizacao" name="ultimaAtualizacao"  body={body}/>
                    <Column 
                        header="Adicionado em"  
                        style={{ width: '12em'}} 
                        field="incluidoPor" 
                        name="incluidoPor"  
                        body={body}
                    />
                    <Column 
                        name='observacao'
                        field='observacao'
                        body={body}
                    />
                </DataTable>
                <Dialog
                    onHide={() => setModalObservacao(false)}
                    visible={modalObservacao}
                    style={{ width: '50vw' }}
                    header='Observação'
                    footer='information'
                    closeLabel='Fechar'
                >
                    <InputTextAreaFull
                        col={12}
                        value={modalData?.observacao}
                        name="observacao"
                        onChange={(e) => {
                            handleChangeModalData(e)        
                            handleChangeItem(modalData, e)
                        }}
                        label={null}
                        reduced
                        maxLength={255}
                    />
                </Dialog>    
            </div>
        </>
    );
});