import { api } from "../../../service/api";
import { baseService } from "../../../service/baseService";

const resourceUrl = "/cfops";

export const cfopService = {  
    verificarPath: async (path) => {
        return api.get(`${resourceUrl}/verificar-path`, {
            params: {path: path}
        }).then(res => res.data);
    },
    ...baseService(resourceUrl)
};
