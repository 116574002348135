import { Tooltip } from 'primereact/tooltip';
import React from 'react';

const MatrizDetails = ({ cliente }) => (
  <>
    <div className="mt-2"> Dados da Matriz: </div>
    <div className="mt-2">{`Razão Social: ${cliente.vwCliente.grupo.razaoSocial}`}</div>
    <div className="mt-2">{`Nome Fantasia: ${cliente.vwCliente.grupo.nomeFantasia}`}</div>
    <div className="mt-2">{`${cliente.vwCliente.grupo.cpfCnpj.length === 14 ? 'CNPJ' : 'CPF' }: ${cliente.vwCliente.grupo.cpfCnpj}`}</div>
  </>
);

const renderIcon = (cliente) => {
  if (cliente?.vwCliente?.tipo === 'FILIAL') {
    return <i className={`fa-solid fa-store tooltip-${cliente?.id}`} style={{textAlign:'center' , color:'rgb(48, 63, 159)', fontSize: '1.2rem'}} />;
  } else if (cliente?.vwCliente?.tipo === 'UNICO') {
    return <i className={`fa-solid fa-shop tooltip-${cliente?.id}`} style={{textAlign:'center' ,color:'rgb(96, 125, 139)', fontSize: '1.2rem'}} />;
  } else {
    return <i className={`fa-solid fa-building tooltip-${cliente?.id}`} style={{textAlign:'center' ,color:'rgb(244, 67, 54)', fontSize: '1.2rem'}} />;
  }
};

const renderDetails = (cliente) => {
  if (cliente?.vwCliente?.tipo === 'FILIAL') {
    return <MatrizDetails cliente={cliente} />;
  }
  return null;
};

const ClienteRazaoSocialTemplate = ({ cliente }) => {  
  function capitalizeFirstLetter(string) {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);        
    }
    return null;
  }

  const icon = renderIcon(cliente);
  const details = renderDetails(cliente);

  return (
    <>
      <Tooltip target={`.tooltip-${cliente?.id}`} position='left' > 
        <div className="flex align-items-center flex-column justify-content-center p-2">
          <div>{capitalizeFirstLetter(cliente?.vwCliente?.tipo?.toLowerCase())}</div>
          {details}
        </div>
      </Tooltip>
      <div> {icon} {cliente?.nomeFantasia}</div>
    </>
  );
};

export default ClienteRazaoSocialTemplate;