import React, {useState} from "react";

import {InputText} from "primereact/inputtext";
import {Checkbox} from "primereact/checkbox";
import classNames from "classnames";

import './styles.scss'
import {calcularTamanho} from "../../service/guiHelper";

export function InputTextERadioButton(props) {

    function handleChange(event) {
        if (props.onChange) {
            props.onChange({name: props.name, value: event.target.value, index: props.index});
        }
    }

    const [historico, setHistorico] = useState('')

    const classes = calcularTamanho(props.col);

    const handleCheckbox = (e) => {
        props.setChecked(props.nameCheckbox, props.checked)
        
        if(!props.checked === false){
            setHistorico(props.value)
            props.onChange({name: props.name, value: '', index: props.index});
        }else{
            props.onChange({name: props.name, value: historico, index: props.index});
        }
    }


    const inputClasses = classNames({
        "p-invalid": props.invalid
    });

    const newLocal = props.required ? <small style={{color: props.value?.length ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : null;

    return (
        <div className={classes}>
            <label>{props.label}</label>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                    <Checkbox 
                        onChange={handleCheckbox}  
                        className={inputClasses} 
                        checked={props.checked}
                    />
                </span>
                <InputText
                    maxLength={250}
                    {...props}
                    onChange={handleChange} 
                    placeholder={props.placeholder ? `Digite ${props.placeholder}` : null} 
                    className={inputClasses} 
                    disabled={!props.checked}
                    value={props.value}
                />
            </div>
            {props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : newLocal}
        </div>
    );

}