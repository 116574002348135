import React from 'react';
import { Password as PPassword } from 'primereact/password';
import classNames from "classnames";
import {calcularTamanho} from "../../service/guiHelper";

export function Password(props) {
    function handleChange(event) {
        if (props.onChange) {
            props.onChange({name: props.name, value: event.target.value, index: props.index});
        }
    }

    const classes = calcularTamanho(props.col);

    const inputClasses = classNames({
        "p-invalid block": props.invalid
    });

    return (
        <div className={classes}>
            <label>{props.label}</label>
            <PPassword {...props} className={inputClasses} onChange={handleChange} toggleMask placeholder={!!props.placeholder  ? `Digite ${props.placeholder}` : null}/>
            {props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : null}
        </div>
    );
}
