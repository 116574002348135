import React, {useEffect, useState} from 'react';
import {FormEdit} from "../../components/form/Edit";
import {useParams} from "react-router-dom";
import {buildValidator, isBiggerThanZero, isRequired} from "../../utils/fieldValidator";
import {useAuth} from '../../context/AuthContext';
import {ansService, optionsContagem, optionsDiasSemana, optionsFlags} from "../../service/ansService";
import {InputText} from "../../components/inputtext";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {InputMask} from "../../components/inputmask";
import {MultiSelect} from "../../components/multiselect";
import {Button} from "../../components/button";
import {TabPanel, TabView} from "primereact/tabview";
import {PanelContent} from "../../components/panel";
import {Dropdown} from "../../components/dropdown";
import {InputNumber} from "../../components/inputnumber";
import {optionsStatus} from "../../service/ordemServicoService";
import {withDialog} from "../../utils/dialogContext";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {CalendarioAutoComplete} from "../../components/autocomplete/CalendarioAutoComplete";

export const EditarANS = withDialog(({showDialog}) => {

	const {id} = useParams();
	const {roles} = useAuth();

	const [ans, setAns] = useState(ansService.criar());
	const [messages, setMessages] = useState(buildValidator());
	const [expedientesSelecionados, setExpedientesSelecionados] = useState([]);
	const [itensSelecionados, setItensSelecionados] = useState([]);

	function handleChange(event) {
		setAns({...ans, [event.name]: event.value});
	}

	useEffect(() => {
		if (id !== "new") {
			ansService.buscar(id).then(ans => {
				for (const item of ans.itens) {
					if (item.horasConclusao % 24 === 0) {
						item._unidadeTempo = "d";
						item.horasConclusao = item.horasConclusao / 24;
					} else {
						item._unidadeTempo = "h";
					}
				}
				setAns({...ans});
			});
		}
	}, [id]);

	function adicionarExpediente() {
		ans.expedientes.push(ansService.criarExpediente());
		setAns({...ans});
	}

	function removerExpedientes() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os horários selecionados?" onYes={() => {
			ans.expedientes = ans.expedientes.filter(anse => !expedientesSelecionados.some(e => (e._key && anse._key === e._key) || (e.id && anse.id === e.id)));
			setAns({...ans});
		}}/>);
	}

	const expedienteControlSet = (
		<div>
			<Button icon="pi pi-plus" onClick={adicionarExpediente}/>
			<Button disabled={!expedientesSelecionados?.length} onClick={removerExpedientes} danger icon="pi pi-minus"/>
		</div>
	);

	const expedienteHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={4} header={expedienteControlSet}/>
			</Row>
			<Row>
				<Column selectionMode="multiple" style={{width: "3em"}}/>
				<Column header="Dias da Semana"/>
				<Column style={{width: "10em"}} header="Início"/>
				<Column style={{width: "10em"}} header="Fim"/>
			</Row>
		</ColumnGroup>
	);

	const expedienteFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={4} footer={expedienteControlSet}/>
			</Row>
		</ColumnGroup>
	);

	function adicionarItem() {
		ans.itens.push(ansService.criarItem());
		setAns({...ans});
	}

	function removerItens() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os itens selecionados?" onYes={() => {
			ans.itens = ans.itens.filter(anse => !itensSelecionados.some(i => (i._key && anse._key === i._key) || (i.id && anse.id === i.id)));
			setAns({...ans});
		}}/>);
	}

	const itemControlSet = (
		<div>
			<Button icon="pi pi-plus" onClick={adicionarItem}/>
			<Button disabled={!itensSelecionados?.length} onClick={removerItens} danger icon="pi pi-minus"/>
		</div>
	);

	const itemHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={7} header={itemControlSet}/>
			</Row>
			<Row>
				<Column selectionMode="multiple" style={{width: "3em"}}/>
				<Column header="Nome do Termo"/>
				<Column style={{width: "12em"}} header="Tempo"/>
				<Column header="Contagem de Tempo"/>
				<Column header="Status Inicial"/>
				<Column header="Status Finais"/>
				<Column header="Opções"/>
			</Row>
		</ColumnGroup>
	);

	const itemFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={7} footer={itemControlSet}/>
			</Row>
		</ColumnGroup>
	);

	function handleChangeExpediente(event) {
		ans.expedientes[event.index][event.name] = event.value;
		setAns({...ans});
	}

	function handleChangeItem(event) {
		ans.itens[event.index][event.name] = event.value;
		setAns({...ans});
	}

	function validarItens() {
		messages.itens = [...Array(ans.itens.length)].map((_, i) => ({
			tempoContagem: isBiggerThanZero(ans.itens[i].tempoContagem),
			statusInicial: isRequired(ans.itens[i].statusInicial),
			modoContagem: isRequired(ans.itens[i].modoContagem)
		}));
		setMessages({...messages});
	}

	return (
		<FormEdit podeDeletar={!!roles.ANSD}
		          podeSalvar={!!roles.ANSC}
		          descricao="Modelo de SLA"
		          onValidate={setMessages}
		          service={ansService}
		          value={ans}
		          blocked={id !== "new" && !ans.id}
		>
			<InputText onBlur={() => {
				messages.descricao = isRequired(ans.descricao);
				setMessages({...messages});
			}} invalid={messages.descricao} required label="Descrição" value={ans.descricao} name="descricao" onChange={handleChange} col={8}/>
			<CalendarioAutoComplete value={ans.calendario} onChange={handleChange} col={4}/>
			<TabView className="col-12">
				<TabPanel header={<><i className="fa-solid fa-clock"/> Expediente</>}>
					<PanelContent>
						<div className="col-12">
							<DataTable headerColumnGroup={expedienteHeaderGroup}
							           footerColumnGroup={expedienteFooterGroup}
							           onSelectionChange={e => setExpedientesSelecionados(e.value)}
							           selection={expedientesSelecionados}
							           emptyMessage="Nenhum horário adicionado"
							           value={ans.expedientes}>
								<Column selectionMode="multiple"/>
								<Column style={{flexDirection: "column", alignItems: "start"}} field="diasSemana" header="Dias da Semana" body={(anse, c) => <MultiSelect index={c.rowIndex} options={optionsDiasSemana} name="diasSemana" value={anse.diasSemana} onChange={handleChangeExpediente}/>}/>
								<Column style={{flexDirection: "column", alignItems: "start"}} field="inicio" header="Início" body={(anse, c) => <InputMask mask="99:99" index={c.rowIndex} name="inicio" value={anse.inicio} onChange={handleChangeExpediente}/>}/>
								<Column style={{flexDirection: "column", alignItems: "start"}} field="fim" header="Fim" body={(anse, c) => <InputMask mask="99:99" index={c.rowIndex} name="fim" value={anse.fim} onChange={handleChangeExpediente}/>}/>
							</DataTable>
						</div>
					</PanelContent>
				</TabPanel>
				<TabPanel header={<><i className="fa-solid fa-list-alt"/> Prazos de Atendimento</>}>
					<PanelContent>
						<div className="col-12">
							<DataTable headerColumnGroup={itemHeaderGroup}
							           footerColumnGroup={itemFooterGroup}
							           selection={itensSelecionados}
							           onSelectionChange={e => setItensSelecionados(e.value)}
							           emptyMessage="Nenhuma condição adicionada"
							           value={ans.itens}>
								<Column selectionMode="multiple"/>
								<Column style={{flexDirection: "column", alignItems: "start"}} field="descricao" header="Descrição" body={(ansi, c) => <InputText required invalid={messages.itens?.[c.rowIndex]?.descricao} onBlur={validarItens} index={c.rowIndex} name="descricao" value={ansi.descricao} onChange={handleChangeItem}/>}/>
								<Column style={{flexDirection: "column", alignItems: "start"}} field="tempoContagem" header="Limite de Tempo" body={(ansi, c) => <InputNumber required invalid={messages.itens?.[c.rowIndex]?.tempoContagem} onBlur={validarItens} index={c.rowIndex} name="tempoContagem" value={ansi.tempoContagem} onChange={handleChangeItem}/>}/>
								<Column style={{flexDirection: "column", alignItems: "start"}} field="contagem" header="Modo de Contagem" body={(ansi, c) => <Dropdown onBlur={validarItens} options={optionsContagem} index={c.rowIndex} name="modoContagem" value={ansi.modoContagem} onChange={handleChangeItem}/>}/>
								<Column style={{flexDirection: "column", alignItems: "start"}} field="statusInicial" header="Status Inicial" body={(ansi, c) => <Dropdown onBlur={validarItens} options={optionsStatus} index={c.rowIndex} name="statusInicial" value={ansi.statusInicial} onChange={handleChangeItem}/>}/>
								<Column style={{flexDirection: "column", alignItems: "start"}} field="statusFinais" header="Status Finais" body={(ansi, c) => <MultiSelect onBlur={validarItens} options={optionsStatus} index={c.rowIndex} name="statusFinais" value={ansi.statusFinais} onChange={handleChangeItem}/>}/>
								<Column style={{flexDirection: "column", alignItems: "start"}} field="flags" header="Opções" body={(ansi, c) => <MultiSelect onChange={handleChangeItem} onBlur={validarItens} name="flags" value={ansi.flags} index={c.rowIndex} options={optionsFlags}/>}/>
							</DataTable>
						</div>
					</PanelContent>
				</TabPanel>
			</TabView>
		</FormEdit>
	);

});
