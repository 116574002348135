import React, { useState } from "react";

import { Panel } from "primereact/panel";
import { PanelContent, PanelFooter } from "../../components/panel";
import { Button } from "../../components/button";
import { toast } from "react-toastify";
import moment from "moment";
import saveAs from "file-saver";

import { headerTemplate } from "../../utils/templates";
import { useBreakpoint } from "../../context/BreakpointContext";
import { UsuarioAutoComplete } from "../../components/autocomplete/usuarioAutoComplete";
import { useAuth } from "../../context/AuthContext";
import { relatoriosServicoService } from "../../service/relatoriosServicoService";
import { ProdutoAutoComplete } from "../../components/autocomplete/produtoAutoComplete";
import { EstoqueAutoComplete } from "../../components/autocomplete/EstoqueAutoComplete";
import { SelectButton } from "../../components/selectbutton";

export function RelatorioEstoque() {
  const { roles } = useAuth();
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    relatorio: "ROEP",
    descricao: "",
    cliente: null,
    textoBuscaCliente: "",
    origem: null,
    tipo: null,
    saldo: null,
    inicio: moment().subtract(1, "months").format("YYYY-MM-DD"),
    fim: moment().format("YYYY-MM-DD"),
  });

  const { breakpoint } = useBreakpoint();
  const isLargeDevice = breakpoint === "lg" || breakpoint === "md";

  function handleChange(event) {
    setParams({ ...params, [event.name]: event.value });
  }

  const optionsOrigem = [
    {
      label: (
        <div
          title="Todas as peças"
          className="w-full h-full flex align-items-center justify-content-center"
        >
          <i className="fa-solid fa-asterisk" />
        </div>
      ),
      value: null,
    },
    {
      label: (
        <div
          title="Peças novas"
          className="w-full h-full flex align-items-center justify-content-center"
        >
          <i className="fa-solid fa-microchip" />
        </div>
      ),
      value: "OMEGA",
    },
    {
      label: (
        <div
          title="Peças recondicionadas"
          className="w-full h-full flex align-items-center justify-content-center"
        >
          <i className="fa-solid fa-recycle" />
        </div>
      ),
      value: "SERVICES",
    },
  ];
  const optionsTipo = [
    {
      label: (
        <div
          title="Todos os estoques"
          className="w-full h-full flex align-items-center justify-content-center"
        >
          <i className="fa-solid fa-asterisk" />
        </div>
      ),
      value: null,
    },
    {
      label: (
        <div
          title="Estoque interno (almoxarifado e laboratório)"
          className="w-full h-full flex align-items-center justify-content-center"
        >
          <i className="fa-solid fa-house-flag" />
        </div>
      ),
      value: "INTERNO",
    },
    {
      label: (
        <div
          title="Estoque externo (técnicos)"
          className="w-full h-full flex align-items-center justify-content-center"
        >
          <i className="fa-solid fa-road" />
        </div>
      ),
      value: "EXTERNO",
    },
  ];

  const optionsSaldo = [
    {
      label: (
        <div
          title="Todos"
          className="w-full h-full flex align-items-center justify-content-center"
        >
          Todos
        </div>
      ),
      value: null,
    },
    {
      label: (
        <div className="w-full h-full flex align-items-center justify-content-center">
          Disponível
        </div>
      ),
      value: "disponivel",
    },
    {
      label: (
        <div className="w-full h-full flex align-items-center justify-content-center">
          Sem saldo
        </div>
      ),
      value: "esgotado",
    },
    {
      label: (
        <div className="w-full h-full flex align-items-center justify-content-center">
          Negativo
        </div>
      ),
      value: "negativo",
    },
  ];

  async function handleBaixar(params) {
    setLoading(true);

    const query = [];
    if (params.tecnico?.id) query.push(`tecnico=${params.tecnico.id}`);
    if (params.estoque?.id) query.push(`estoque=${params.estoque.id}`);
    if (params.produto?.id) query.push(`produto=${params.produto.id}`);
    if (params.saldo) query.push(`saldo=${params.saldo}`);
    if (params.origem) query.push(`origem=${params.origem}`);
    if (params.tipo) query.push(`tipo=${params.tipo}`);

    toast.promise(
      relatoriosServicoService
        .baixarRelatorio("ROEP", query)
        .then((data) => {
          console.log("data_antes", data);
          saveAs(
            new Blob([data], { type: "application/csv" }),
            `${moment().format("YYYYDDMMHHmmss")}.csv`
          );
        })
        .finally(() => {
          setLoading(false);
        }),
      {
        pending: `Gerando relatório. Aguarde...`,
        error: {
          render(e) {
            setLoading(false);
            return `Falha ao gerar o relatório: ${e.data?.response?.data}`;
          },
        },
      }
    );
  }

  return (
    <Panel
      headerTemplate={() =>
        headerTemplate("Relatório de Estoque", "fa-boxes-packing")
      }
    >
      <PanelContent>
        <ProdutoAutoComplete
          col={4}
          name="produto"
          value={params.produto}
          onChange={handleChange}
        />
        <UsuarioAutoComplete
          col={4}
          disabled={!roles.OSSL}
          value={params.tecnico}
          name="tecnico"
          onChange={handleChange}
          label="Técnico"
        />
        <EstoqueAutoComplete
          col={4}
          label="Estoque"
          name="estoque"
          value={params.estoque}
          onChange={handleChange}
        />
        <SelectButton
          col={2}
          options={optionsOrigem}
          label="Origem"
          name="origem"
          value={params.origem}
          onChange={handleChange}
        />
        <SelectButton
          col={2}
          options={optionsTipo}
          label="Estoque"
          name="tipo"
          value={params.tipo}
          onChange={handleChange}
        />
        <SelectButton
          col={3}
          options={optionsSaldo}
          label="Saldo"
          name="saldo"
          value={params.saldo}
          onChange={handleChange}
        />
      </PanelContent>
      <PanelFooter>
        <Button
          info
          disabled={loading}
          loading={loading}
          onClick={() => handleBaixar(params)}
          label={isLargeDevice ? "Gerar CSV" : null}
          icon="fa-solid fa-file-arrow-down"
          autowidth={!isLargeDevice}
        />
      </PanelFooter>
    </Panel>
  );
}
