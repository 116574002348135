import React, {useState} from 'react';
import {AutoComplete} from '.';
import { cfopService } from '../../pages/Comercial/services/cfopService';

export function CfopAutoComplete(props) {

	const [cfops, setCfops] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("")

	function completeMethod(event) {
		const query = [];
		query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);
		query.push(`status=ATIVO`);
		if (event.query.length) query.push(query);
		cfopService.autocomplete(query).then(res => {
			setCfops(res);
			if (!res[0]) {
				setEmptyMessage("Nenhum registro encontrado");
			} else {
				setEmptyMessage("");
			}
		});
	}

	return (
		<AutoComplete
			col={3}
			placeholder="Código ou descrição"
			label="CFOP"
			name="cfop"
			value={props.value}
			onChange={props.onChange}
			completeMethod={completeMethod}
			suggestions={cfops}
			invalid={emptyMessage}
			icon="fa-solid fa-receipt"
			{...props}
		/>
	)
}
