import React, {useEffect, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {BlockUI} from "primereact/blockui";
import {Column} from "primereact/column";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {InputNumber} from "../../components/inputnumber";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";
import {SelectButton} from "../../components/selectbutton";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import {ordemServicoService} from "../../service/ordemServicoService";
import {EditarAcompanhamento} from "./EditarAcompanhamento";
import {SelectEstoquePeca} from "../__Commons/SelectEstoquePeca";
import {produtoService, resolverSaldo} from "../../service/produtoService";
import {useAuth} from "../../context/AuthContext";
import moment from "moment";
import {dateTimeNow} from "../../utils/dateUtils";
import {resolverEstoque} from "../__Commons-OrdemServico/ConfirmacaoEstoquePecaCampo";
import {bySequencia} from "../../utils/sortUtils";

const optionsStatusPeca = [
    {label: "Solicitado", value: "SOLICITADO"},
    {label: "Compras", value: "COMPRAS"},
    {label: "Liberado", value: "LIBERADO"},
    {label: "Entregue", value: "MOVIMENTADO"},
    {label: "Cancelado", value: "CANCELADO"}
];

export function EditarOrdemServicoMovimentoEstoque() {

    const {usuario} = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const numero = searchParams.get("numero");
    const [itens, setItens] = useState([]);
    const [ordemServico, setOrdemServico] = useState();
    const [blocked, setBlocked] = useState(true);

    useEffect(() => {
        ordemServicoItemService.listarPecas(numero).then(pecas => {
            const itens = {};
            for (const peca of pecas) {
                if (!itens[peca.id]) {
                    itens[peca.id] = {
                        id: peca.id,
                        equipamento: peca.equipamento,
                        _alterado: false,
                        pecas: []
                    };
                }
                if (peca.status === "SOLICITADO") {
                    peca.estoque = null;
                }
                itens[peca.id].pecas.push(peca);
                itens[peca.id].pecas = itens[peca.id].pecas.sort(bySequencia);
            }
            setItens(Object.keys(itens).map(k => itens[k]));
            setBlocked(false);
        });
    }, [numero]);

    function mostrarPecas(item, index) {
        async function handleChange(event) {
            if (event.name === "status" && !event.value) {
                return;
            }
            if (event.name === "produto" && event.value?.id) {
                event.value = await produtoService.buscar(event.value.id);
            }
            setItens(prevItens => {
                prevItens[index]._alterado = true;
                prevItens[index].pecas[event.index][event.name] = event.value;
                return [...prevItens];
            });
        }
        return (
            <DataTable paginator={false} footer={null} key={item.id} header={`Peças para o equipamento ${item.equipamento.autoComplete}`} value={item.pecas}>
                <Column style={{width: "5em"}} header="Item" field="sequencia"/>
                <Column header="Produto" field="produto" body={(osip, c) => {
                    const estoque = resolverEstoque(osip);
                    return (
                        <>
                            <ProdutoAutoComplete
                                reduced
                                readOnly={osip.status !== "SOLICITADO"}
                                index={c.rowIndex}
                                name="produto"
                                value={osip.produto}
                                col={12}
                                label={null}
                                onChange={handleChange}
                            />
                            {
                                osip.adicionadoPor
                                    ? (
                                        <div className="col-12" style={{fontSize: ".8em", fontStyle: "italic"}}>
                                            Adicionada por: {osip.adicionadoPor?.nome} às {moment(osip.registro).format("DD/MM/YY HH:mm")} - {estoque ? `(Estoque: ${estoque?.autoComplete})` : "Estoque não selecionado"}
                                        </div>
                                    )
                                    : null
                            }
                        </>
                    );
                }}/>
                <Column style={{width: "10em"}} header="Quantidade" field="quantidade" body={(osip, c) => (
                    <InputNumber
                        reduced
                        readOnly={osip.status !== "SOLICITADO"}
                        index={c.rowIndex}
                        name="quantidade"
                        value={osip.quantidade}
                        col={12}
                        label={null}
                        onChange={handleChange}
                    />
                )}/>
                <Column style={{width: "10em"}} header="Estoque" field="estoque" body={(osip, c) => {
                    const saldoNovas = osip.estoqueConsumoNovas ? resolverSaldo(osip.estoqueConsumoNovas, osip.produto) : "?";
                    const saldoRecondicionadas = osip.estoqueConsumoRecondicionadas ? resolverSaldo(osip.estoqueConsumoRecondicionadas, osip.produto) : "?";
                    return (
                        <SelectEstoquePeca
                            estoqueNovas={true}
                            disabled={osip.status !== "SOLICITADO"}
                            onChange={handleChange}
                            name="estoque"
                            value={osip.estoque}
                            index={c.rowIndex}
                            labelNovas={(
                                <>
                                <span style={{minWidth: "4em"}}>
                                    <i className={`fas mr-1 fa-circle ${saldoNovas >= osip.quantidade ? "text-green-400" : "text-orange-400"}`}/>
                                    {saldoNovas}
                                </span>
                                    <span>Nova</span>
                                </>
                            )}
                            labelRecondicionadas={(
                                <>
                                <span style={{minWidth: "4em"}}>
                                    <i className={`fas mr-1 fa-circle ${saldoRecondicionadas >= osip.quantidade ? "text-green-400" : "text-orange-400"}`}/>
                                    {saldoRecondicionadas}
                                </span>
                                    <span>Recondicionada</span>
                                </>
                            )}
                        />
                    );
                }}/>
                <Column style={{width: "32em"}} header="Status" field="status" body={(osip, c) => (
                    <>
                        <SelectButton
                            optionDisabled={o => (osip.status === "MOVIMENTADO" && o.value !== "CANCELADO") || o.value === "MOVIMENTADO"}
                            name="status"
                            disabled={!osip.estoque}
                            index={c.rowIndex}
                            value={osip.status}
                            onChange={handleChange}
                            options={optionsStatusPeca}
                        />
                        <div>
                            <i className="mx-2 text-sm">
                                {
                                    osip.liberadoPor
                                        ? `Liberado por ${osip.liberadoPor.nome} às ${moment(osip.liberadoEm).format("DD/MM/YY HH:mm")}`
                                        : null
                                }
                            </i>
                        </div>
                        <div>
                            <i className="mx-2 text-sm">
                                {
                                    osip.movimentadoPor
                                        ? `Entregue para ${osip.movimentadoPor.nome} às ${moment(osip.movimentadoEm).format("DD/MM/YY HH:mm")}`
                                        : null
                                }
                            </i>
                        </div>
                    </>
                )}/>
            </DataTable>
        );
    }

    async function handleSalvar() {
        setBlocked(true);
        for (const item of itens) {
            item._alterado = false;
            for (const peca of item.pecas) {
                if (peca.status === "LIBERADO" && !peca.liberadoPor) {
                    peca.liberadoPor = usuario;
                    peca.liberadoEm = dateTimeNow();
                }
                if (!peca.estoque) {
                    peca.estoque = "NOVAS";
                }
            }
            item.pecas = (await ordemServicoItemService.salvarPecas(numero, item.pecas)).sort(bySequencia);
            for (const peca of item.pecas) {
                if (peca.status === "SOLICITADO") {
                    peca.estoque = null;
                }
            }
        }
        setItens(prevItens => [...prevItens]);
        setBlocked(false);
    }

    function handleVoltar() {
        navigate("/ordens-servico/estoques/movimentos");
    }

    function handleAvancarEtapa() {
        setBlocked(true);
        ordemServicoService.buscarPorNumero(numero).then(ordemServico => {
            setOrdemServico(ordemServico);
            setBlocked(false);
        });
    }

    if (ordemServico) {
        return <EditarAcompanhamento ordemServico={ordemServico}/>;
    }

    return (
        <BlockUI blocked={blocked}>
            <Panel header={`Movimentação de Estoque dos Itens da Ordem de Serviço nº${numero}`}>
                <PanelContent>
                    <div className="col-12">{itens.length ? itens.map(mostrarPecas) : "Nenhuma peça adicionada a essa ordem de serviço"}</div>
                </PanelContent>
                <PanelFooter>
                     {ordemServico?.operacao !== "LABORATORIO" ? <Button disabled={itens.some(i => i._alterado)} icon="fas fa-arrow-right" label="Avançar Etapa" onClick={handleAvancarEtapa}/> : null}
                    <Button success disabled={!itens.some(i => i._alterado) || itens.some(i => i.pecas?.some(ip => !ip.produto?.id || ip.quantidade == null))} icon="fas fa-save" label="Salvar" onClick={handleSalvar}/>
                    <Button icon="fas fa-arrow-left" secondary label="Voltar" onClick={handleVoltar}/>
                </PanelFooter>
            </Panel>
        </BlockUI>
    );

}
