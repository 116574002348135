import {InformationDialog} from "../../components/dialog/InformationDialog";
import {ConcluirLaudo} from "../Laboratorio/Laudo/ConcluirLaudo";
import {ConcluirManutencaoEquipamentoEspecial} from "../Laboratorio/Manutencao/ConcluirManutencaoEquipamentoEspecial";
import {AvancarEtapa} from "../__Commons/AvancarEtapa";
import {TrocarPrateleira} from "../__Commons/TrocarPrateleira";
import {TrocarResponsavel} from "../__Commons/TrocarResponsavel";
import {resolvePath} from "./utils";
import React, {useContext} from "react";
import {useDialog} from "../../utils/dialogContext";
import {useNavigate} from "react-router-dom";
import {useQueryClientBackoffice, useQueryClientLaboratorio} from "../../utils/query-clients/queryClients";
import {usePagesAndTabs} from "../../utils/PageAndTabContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";

const OrdemServicoActionContext = React.createContext();

export function OrdemServicoActionProvider({children, handleSearch}) {

    const navigate = useNavigate();
    const {showDialog} = useDialog();
    const {setScroll} = usePagesAndTabs(QueryParamsKeys.Laboratorio);

    async function handleConclusao(status, ordensServico) {
        switch (status) {
            case "LAUDO":
                if (ordensServico.some(os => os.restricoes.includes("RESSOLDA"))) {
                    showDialog(<InformationDialog header="Aviso" message="O processo de recuperação de placa precisa ser concluído na tela de edição da ordem de serviço."/>);
                    return;
                }
                if (ordensServico.every(os => os.restricoes.includes("LAUDO"))) {
                    showDialog(<ConcluirLaudo ordensServico={ordensServico} onModalClose={handleSearch}/>);
                    return;
                }
                break;
            case "MANUTENCAO":
                if (ordensServico.some(os => os.equipamento?.produto?.equipamentoEspecial)) {
                    showDialog(<ConcluirManutencaoEquipamentoEspecial ordensServico={ordensServico} onModalClose={handleSearch}/>);
                    return;
                }
                break;
        }
        showDialog(<AvancarEtapa status={status} ordensServico={ordensServico} onModalClose={handleSearch}/>);
    }

    async function handleResponsavel(status, ordensServico) {
        showDialog(<TrocarResponsavel status={status} ordensServico={ordensServico} onModalClose={handleSearch}/>);
    }

    async function handlePrateleira(ordensServico) {
        showDialog(<TrocarPrateleira ordensServico={ordensServico} onModalClose={handleSearch}/>);
    }

    async function handleEditar(status, event) {
        setScroll();
        navigate(`${resolvePath(status)}/${event.data.id}`);
    }

    const actions = {
        handleEditar,
        handlePrateleira,
        handleResponsavel,
        handleConclusao
    };

    return (
        <OrdemServicoActionContext.Provider value={actions}>
            {children}
        </OrdemServicoActionContext.Provider>
    );

}

export function LaboratorioActionProvider({children}) {
    const {handleSearch} = useQueryClientLaboratorio();
    return <OrdemServicoActionProvider handleSearch={handleSearch} children={children}/>
}

export function BackofficeActionProvider({children}) {
    const {handleSearch} = useQueryClientBackoffice();
    return <OrdemServicoActionProvider handleSearch={handleSearch} children={children}/>
}

export function useOrdemServicoActions() {
    return useContext(OrdemServicoActionContext);
}
