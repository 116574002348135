import moment from "moment";
import { api } from "../../../service/api";
import {baseService} from "../../../service/baseService";
import {buildValidator, isBiggerThanZero, isBiggerThanZeroValor, isEntityRequired, isNotNull, isRequired} from "../../../utils/fieldValidator";

const resourceUrl = "/pedidos-venda";

export const pedidoVendaService = {
	criar: () => {
		return {
			id: null,
			cliente: null,
			sistema: 'TZM',
			origem: 'PEDIDO_VENDA',
			status: 'RASCUNHO',
			transportadora: null,
			vendedor: null,
			cfop: null,
			tipoMovimento: null,
			condicaoPagamento: null,
			dataEntrega: null,
			dataPedido: null,
			valorFrete: 0,
			itens:[],
			anexos: [],
			eventos: []			
		};
	},
	criarOrcamento: () => {
		return {
			id: null,
			cliente: null,
			sistema: 'TZM',
			origem: 'PEDIDO_VENDA',
			status: 'ORCAMENTO',
			transportadora: null,
			vendedor: null,
			condicaoPagamento: null,
			valorFrete: 0,
			anexos: [],
			eventos: [],
			itens:[],
			_key: Math.random() * 1000,
			orcamento:{
				prazoEntrega: 90,
				condicaoPagamento: null,				
				valorCotacao: 0,
				responsavel:null,			
				valorProdutos: 0,
				valorIpi: 0,
				valorSt: 0,
				valorIcms: 0,
				valorFrete: 0,
				valorTotal: 0,
				data: moment().format('YYYY-MM-DD'),
				validade: moment().add(3, "days").format("YYYY-MM-DD"),
				distribuidor: null,
				tipo: 'PEDIDO_VENDA',
				observcao: null,
				indice: null,
				dataEnvioDistribuidor: null,
				observacaoEnvio: null,
			}
		};
	},
	criarEvento: (eventos) => {
		return {
			id: null,
			forma: 'MANUAL',
			observacoes: '',
			registro: moment().format('YYYY-MM-DDTHH:mm:ss'),
			usuario: null,
			item: eventos?.length +1,
            _key: Math.random() * 1000,
			tipo: 'REGISTRO_ACOMPANHAMENTO',
		}
	},
	criarAnexo() {
		return {
			id: null,
			_key: Math.random(),
			descricao: "",
			arquivo: null
		};
	},
	buscarvw: async id => {
		return await api.get(`${resourceUrl}/buscar-vw/${id}`).then(response => response.data);
	},
	orcamento: async id =>  {
		return await api.get(`/relatorios-comercial/orcamento/${id}`, {responseType: "blob"}).then(response => response.data);
	},
	criticas: async numero =>  {
		return await api.get(`/integracao-pedidos-venda/criticas/${numero}`).then(response => response.data);
	},
	validar: pedidoVenda => {
		
		const messages = buildValidator();
        messages.cliente = isRequired(pedidoVenda.cliente?.id);
		messages.tipoMovimento = isRequired(pedidoVenda.tipoMovimento?.id);
		messages.cfop = isRequired(pedidoVenda.cfop?.id);
		
		if (pedidoVenda.tipoMovimento?.vwTipoMovimento?.emissao !== 'N') {
			messages.condicaoPagamento = isRequired(pedidoVenda.condicaoPagamento?.id);
		}
		
		messages.vendedor = isRequired(pedidoVenda.vendedor?.id);
        messages.dataPedido = isRequired(pedidoVenda.dataPedido);
		messages.formaPagamento = isRequired(pedidoVenda.formaPagamento);
        messages.dataEntrega = isRequired(pedidoVenda.dataEntrega);
		messages.frete = isRequired(pedidoVenda.frete);		
		

		if (!messages.itens) {
			messages.itens = [...Array(pedidoVenda.itens.length)].map((_, i) => ({
				produto: null,
				quantidade: null,
				valorUnitario: null
			}))
		}
	
		for (let i = 0; i < pedidoVenda.itens.length; ++i) {
			messages.itens[i].produto = isEntityRequired(pedidoVenda.itens[i].produto);
			messages.itens[i].quantidade = isBiggerThanZero(pedidoVenda.itens[i].quantidade);
			messages.itens[i].valorUnitario = isBiggerThanZeroValor(pedidoVenda.itens[i].valorUnitario);
			messages.itens[i].codigoTributario = isNotNull(pedidoVenda.itens[i].codigoTributario);
		}	

		//ANEXOS
        if (!messages.anexos) {
			messages.anexos = [...Array(pedidoVenda.anexos.length)].map((_, i) => ({
				descricao: null,
				arquivo: null,
			}));
		}

		for (let i = 0; i < pedidoVenda.anexos.length; ++i) {
			messages.anexos[i].descricao = isRequired(pedidoVenda.anexos[i].descricao);
            if(!pedidoVenda.anexos[i]?.arquivo?.id){
                messages.anexos[i].arquivo = isRequired(pedidoVenda.anexos[i].arquivo?.conteudo?.conteudo)
            }
		}

		return messages;
	},
	validarOrcamento: pedidoVenda => {
		
		const messages = buildValidator();
        messages.cliente = isEntityRequired(pedidoVenda.cliente);

		if(pedidoVenda.orcamento.tipo === 'DISTRIBUIDOR'){
			messages.distribuidor = isEntityRequired(pedidoVenda.orcamento.distribuidor);
		}else{
			messages.distribuidor = null;
		}

		messages.condicaoPagamento = isEntityRequired(pedidoVenda.orcamento.condicaoPagamento);
		messages.vendedor = isEntityRequired(pedidoVenda.orcamento.vendedor);
		messages.formaPagamento = isRequired(pedidoVenda.formaPagamento);
		messages.validade = isRequired(pedidoVenda.orcamento?.validade);
		// messages.dataComissao = isRequired(pedidoVenda.orcamento?.dataComissao);
		
		if(pedidoVenda.status !== 'ORCAMENTO'){
			messages.frete = isRequired(pedidoVenda.frete);
			if(pedidoVenda.frete === 'FOB' && pedidoVenda.orcamento.tipo === 'PEDIDO_VENDA'){
				messages.valorFrete = isBiggerThanZeroValor(pedidoVenda.valorFrete);
			}
		}
		
		if (!messages.itens) {
			messages.itens = [...Array(pedidoVenda.itens.length)].map((_, i) => ({
				produto: null,
				quantidade: null,
				valorUnitario: null
			}));
		}

		for (let i = 0; i < pedidoVenda.itens.length; ++i) {
			messages.itens[i].produto = isEntityRequired(pedidoVenda.itens[i].produto);
			messages.itens[i].quantidade = isBiggerThanZero(pedidoVenda.itens[i].quantidade);
			messages.itens[i].valorUnitario = isBiggerThanZeroValor(pedidoVenda.itens[i].valorUnitario);
		}

		//ANEXOS
        if (!messages.anexos) {
			messages.anexos = [...Array(pedidoVenda.anexos.length)].map((_, i) => ({
				descricao: null,
				arquivo: null,
			}));
		}

		for (let i = 0; i < pedidoVenda.anexos.length; ++i) {
			messages.anexos[i].descricao = isRequired(pedidoVenda.anexos[i].descricao);
            if(!pedidoVenda.anexos[i]?.arquivo?.id){
                messages.anexos[i].arquivo = isRequired(pedidoVenda.anexos[i].arquivo?.conteudo?.conteudo)
            }
		}

		return messages;
	},
	...baseService(resourceUrl),
	async listarPedidosVenda(params) {
		const query = [];
		if (params.cliente) {
			query.push(`cliente=${params.cliente.id}`);
		}
		if (params.vendedor) {
			query.push(`vendedor=${params.vendedor.id}`);
		}
		if (params.produto) {
			query.push(`produto=${params.produto.id}`);
		}
		if (params.numero) {
			query.push(`numero=${params.numero}`);
		}
		if (params.status[0]) {
			query.push(`status=${params.status}`);
		}
		if (params.inclusaoInicio){
			query.push(`inclusaoInicio=${params.inclusaoInicio}`);
		}
		if (params.inclusaoFim){
			query.push(`inclusaoFim=${params.inclusaoFim}`);
		}
		if (params.entregaInicio){
			query.push(`entregaInicio=${params.entregaInicio}`);
		}
		if (params.entregaFim){
			query.push(`entregaFim=${params.entregaFim}`);
		}
		if (params.tipoOrcamento && params.tipoOrcamento.length > 0){
			query.push(`tipoOrcamento=${params.tipoOrcamento}`);
		}
		return await baseService(resourceUrl).listar(query);
	}
};

export const status = [
	{label: "Rascunho", value: "RASCUNHO" , color: '#FF9800', icon: 'fa-shopping-cart'},
	{label: "Erro", value: "ERRO" , color: '#F44336', icon: 'fa-times'},
	{label: "Aguardando", value: "AGUARDANDO", color: '#2196F3', icon: 'fa-clock'},
	{label: "Confirmado", value: "CONFIRMADO", color: 'rgb(96, 125, 139)', icon: 'fa-lock'},
	{label: "Bloqueado", value: "BLOQUEADO", color: '#F44336', icon: 'fa-lock'},
	{label: "Carteira", value: "CARTEIRA", color: '#607D8B', icon: 'fa-wallet'},
	{label: "Faturado", value: "FATURADO", color: '#4CAF50', icon: 'fa-check'},
	{label: "Orçamento", value: "ORCAMENTO", icon: "fa-solid fa-file-invoice-dollar", color: "#795548"},
	{label: "Aprovado", value: "APROVADO", icon: "fa-solid fa-thumbs-up", color: "#4CAF50"},
	{label: "Perdido", value: "PERDIDO", icon: "fa-solid fa-thumbs-down", color: "#F44336"}
];

export const tiposOrcamento = [
	{label: "Pedido Venda", value: "PEDIDO_VENDA" , color: '#006992', icon: 'fa-shop'},
	{label: "Distribuidor", value: "DISTRIBUIDOR" , color: '#001d4a', icon: 'fa-warehouse'}
];

export const codigosTributarios = [	
	{label: "10", value: 10},
	{label: "51", value: 51},
	{label: "100", value: 100},
	{label: "151", value: 151},
	{label: "600", value: 600},
	{label: "890", value: 890},
	{label: "990", value: 990},
	{label: "8090", value: 8090},
	{label: "9090", value: 9090},
	{label: "590", value: 590},
	{label: "690", value: 690},
	{label: "250", value: 250},
	{label: "251", value: 251},
	{label: "500", value: 500},
	{label: "241", value: 241},
	{label: "200", value: 200},
	{label: "400", value: 400},
	{label: "300", value: 300},
	{label: "341", value: 341},
	{label: "140", value: 140},
	{label: "141", value: 141},
	{label: "700", value: 700},
	{label: "390", value: 390},
	{label: "220", value: 220},
	{label: "320", value: 320},
	{label: "1041", value: 1041},
	{label: "2041", value: 2041},
	{label: "3041", value: 3041},
	{label: "4000", value: 4000}
].sort((a, b) => a.value - b.value);

export const pagamentoFrete = [
	{label: "CIF - Frete Incluso", value: "CIF"},
	{label: "FOB - Frete à pagar", value: "FOB"}
]

export const formaPagamento = [
	{label: "Nenhuma", value: null},
	{label: "Dinheiro", value: "DINHEIRO"},
	{label: "Boleto Bancário", value: "BOLETO_BANCARIO"},
	{label: "Depósito Bancário", value:"DEPOSITO_BANCARIO"},
	{label: "Sem Pagamento", value: "SEM_PAGAMENTO"}
];

