import React from "react";
import moment from "moment";
import {If} from "../conditional/If";
import {CardSLA} from "../cardsla";

export function CardCliente({cliente, ordemServico}) {

	if (!cliente) {
		return null;
	}

	let iconContrato = <i className="fas fa-big fa-file-circle-xmark" style={{color: "#F44336"}}/>;
	let contrato = "Avulso";

	if (ordemServico?.contrato?.id) {
		iconContrato = <i className="fas fa-big fa-star" style={{color: "#FFC107"}}/>;
		contrato = ordemServico.contrato.autoComplete;
	}

	return (
		<table>
			<tbody>
			<tr>
				<td style={{width: "3em", textAlign: "center"}}>{iconContrato}</td>
				<td><b>{contrato}</b></td>
			</tr>
			<tr>
				<td style={{width: "3em", textAlign: "center"}}>
					<i className="fas fa-big fa-user" style={{color: "#3F51B5"}}/>
				</td>
				<td>
					<div style={{fontWeight: "bold"}}>{cliente.nomeFantasia}</div>
					<div style={{fontSize: ".9em"}}>{cliente.endereco}</div>
				</td>
			</tr>
			</tbody>
		</table>
	);

}
