import React, { useState } from "react";

import { AutoComplete } from ".";
import { usuarioService } from "../../service/usuarioService";
import { useDialog } from "../../utils/dialogContext";
import { EditarEmpresaUsuarioModal } from "../../pages/Empresas/EditarEmpresaUsuariosModal";

export function UsuarioPortalAutoComplete(props) {
  const { showDialog } = useDialog();

  const [usuarios, setUsuarios] = useState([]);
  const [emptyMessage, setEmptyMessage] = useState("");

  function completeMethod(event) {
    const query = [];

    query.push(`email=${encodeURIComponent(event.query)}`);
    query.push(`status=ATIVO`);
    if (props.repetidos) query.push(`repetidos=${[props?.repetidos]}`);

    usuarioService.autocompleteDtoUsuarioPortal(query).then((res) => {
      if (res.length) {
        setUsuarios(res);
      } else {
        setUsuarios([
          {
            id: -1,
            nome: event.query,
            autoComplete: `Cadastrar ${event.query}`,
          },
        ]);
      }
    });
  }

  

  return (
    <AutoComplete
      col={3}
      placeholder="email"
      
      label="Usuario"
      icon="fa-solid fa-user"
      name="usuario"
      value={props.value}
      onChange={props.onChange}
      completeMethod={completeMethod}
      suggestions={usuarios}
      invalid={emptyMessage}
      {...props}
    />
  );
}
