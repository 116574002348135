import React from "react";
import {Button} from "./index";

export function ToggleViewHidden({params, onChange}) {

    return (
        <Button
            secondary
            style={{textAlign: "left"}}
            title="Visualizar registros desativados"
            onClick={() => onChange({name: "visualizarDesativados", value: !params.visualizarDesativados})}
            autowidth
            icon={`fa-solid ${params.visualizarDesativados ? "fa-eye": "fa-eye-slash"}`}
        />
    );

}
