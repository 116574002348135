import React, { useCallback } from "react";

import { Dropdown } from "../../components/dropdown";
import { InputTextERadioButton } from "../../components/inputgroup/inputTextERadioButton";
import { InputNumber } from "../../components/inputnumber";
import {
  optionsEmissao,
  optionsPeriodicidadeParcela,
} from "../../service/contratoService";
import {
  isBiggerThanZeroValor,
  isEntityRequired,
  isRequired,
} from "../../utils/fieldValidator";
import { CondicaoPagamentoAutoComplete } from "../../components/autocomplete/CondicaoPagamentoAutoComplete";
import { Checkbox } from "../../components/checkbox";
import { useAuth } from "../../context/AuthContext";

export function EditaFinanceiro({
  contrato,
  setContrato,
  messages,
  setMessages,
}) {
  const { roles } = useAuth();

  const handleChange = useCallback(
    (event) => {
      setContrato({ ...contrato, [event.name]: event.value });
    },
    [contrato, setContrato]
  );

  const handleChangeContratoParametros = useCallback(
    (event) => {
      setContrato((contratoAtualizado) => {
        return {
          ...contratoAtualizado,
          contratoParametros: {
            ...contratoAtualizado.contratoParametros,
            [event.name]: event.value,
          },
        };
      });
    },
    [setContrato]
  );

  const handleCheckbox = (nameCheckbox, value) => {
    setMessages((messages) => {
      return { ...messages, [nameCheckbox]: null };
    });
    setContrato((contratoAtualizado) => {
      return {
        ...contratoAtualizado,
        contratoParametros: {
          ...contratoAtualizado.contratoParametros,
          [nameCheckbox]: !value,
        },
      };
    });
  };

  return (
    <>
      <InputNumber
        col={2}
        label="Valor da Parcela"
        value={contrato?.valor}
        invalid={messages.valor}
        onBlur={() => {
          messages.valor = isBiggerThanZeroValor(contrato.valor);
          setMessages({ ...messages });
        }}
        onChange={handleChange}
        name="valor"
        min={0}
        mode="currency"
        currency="BRL"
        locale="pt-BR"
        required
      />

      <InputNumber
        col={1}
        label="Qtde Parcelas"
        value={contrato?.quantidadeParcelas}
        onChange={handleChange}
        name="quantidadeParcelas"
        locale="pt-BR"
      />

      <InputNumber
        col={2}
        label="Ordem de Compra"
        value={contrato?.odemDeCompra}
        onChange={handleChange}
        name="ordemdecompra"
        locale="pt-BR"
      />

      <InputNumber
        showButtons
        col={2}
        label="Dia do vencimento"
        value={contrato?.diaVencimento}
        invalid={messages.diaVencimento}
        onBlur={() => {
          messages.diaVencimento = isBiggerThanZeroValor(
            contrato.diaVencimento
          );
          setMessages({ ...messages });
        }}
        onChange={handleChange}
        name="diaVencimento"
        min={1}
        max={31}
        locale="pt-BR"
        required
        mode="decimal"
        prefix="Todo dia "
      />

      <Dropdown
        label="Periodicidade das Parcelas"
        col={2}
        onBlur={() => {
          messages.periodicidadeParcela = isRequired(
            contrato.periodicidadeParcela
          );
          setMessages({ ...messages });
        }}
        options={optionsPeriodicidadeParcela}
        name="periodicidadeParcela"
        value={contrato?.periodicidadeParcela}
        onChange={handleChange}
        required
        invalid={messages.periodicidadeParcela}
      />
      <InputNumber
        col={1}
        label="Divisor"
        value={contrato?.divisorParcela}
        onChange={handleChange}
        name="divisorParcela"
        locale="pt-BR"
      />

      <Dropdown
        label="Emissão"
        col={2}
        onBlur={() => {
          messages.emissao = isRequired(contrato.emissao);
          setMessages({ ...messages });
        }}
        options={optionsEmissao}
        name="emissao"
        value={contrato?.emissao}
        onChange={handleChange}
        required
        invalid={messages.emissao}
      />

      <CondicaoPagamentoAutoComplete
        dropdown
        col={3}
        value={contrato?.condicaoPagamento}
        name="condicaoPagamento"
        onChange={handleChange}
        invalid={messages.condicaoPagamento}
        onBlur={() => {
          messages.condicaoPagamento = isEntityRequired(
            contrato.condicaoPagamento
          );
          setMessages({ ...messages });
        }}
        disabled={!roles.COPC}
      />

      <InputTextERadioButton
        col={3}
        label="Multa"
        value={contrato.contratoParametros?.multaDescricao}
        onChange={handleChangeContratoParametros}
        checked={contrato.contratoParametros?.multa}
        setChecked={handleCheckbox}
        name="multaDescricao"
        nameCheckbox="multa"
        invalid={messages.multa}
        required={contrato.contratoParametros?.multa}
        onBlur={() => {
          if (contrato.contratoParametros?.multa) {
            messages.multa = isRequired(
              contrato.contratoParametros?.multaDescricao
            );
            setMessages({ ...messages });
          } else {
            messages.multa = isRequired(
              contrato.contratoParametros?.multaDescricao
            );
            setMessages({ ...messages });
          }
        }}
      />

      <InputTextERadioButton
        col={3}
        label="Reajuste"
        value={contrato.contratoParametros?.reajusteDescricao}
        onChange={handleChangeContratoParametros}
        checked={contrato.contratoParametros?.reajuste}
        setChecked={handleCheckbox}
        name="reajusteDescricao"
        nameCheckbox="reajuste"
        invalid={messages.reajuste}
        required={contrato.contratoParametros?.reajuste}
        onBlur={() => {
          if (contrato.contratoParametros?.reajuste) {
            messages.reajuste = isRequired(
              contrato.contratoParametros?.reajusteDescricao
            );
            setMessages({ ...messages });
          } else {
            messages.reajuste = isRequired(
              contrato.contratoParametros?.reajusteDescricao
            );
            setMessages({ ...messages });
          }
        }}
      />

      <InputTextERadioButton
        col={3}
        label="Índice de Reajuste"
        value={contrato.contratoParametros?.indiceReajusteDescricao}
        onChange={handleChangeContratoParametros}
        checked={contrato.contratoParametros?.indiceReajuste}
        setChecked={handleCheckbox}
        name="indiceReajusteDescricao"
        nameCheckbox="indiceReajuste"
        invalid={messages.indiceReajuste}
        required={contrato.contratoParametros?.indiceReajuste}
        onBlur={() => {
          if (contrato.contratoParametros?.indiceReajuste) {
            messages.indiceReajuste = isRequired(
              contrato.contratoParametros?.indiceReajusteDescricao
            );
            setMessages({ ...messages });
          } else {
            messages.indiceReajuste = isRequired(
              contrato.contratoParametros?.indiceReajusteDescricao
            );
            setMessages({ ...messages });
          }
        }}
      />

      <div className="col-1">
        <label>&nbsp;</label>
        <Checkbox
          label="Renovado"
          name="renovado"
          value={contrato.renovado}
          onChange={handleChange}
        />
      </div>

      <div className="col-2">
        <label>&nbsp;</label>
        <Checkbox
          label="Comissão Paga"
          title="Se o flag estiver marcado, a comissão já foi paga anteriormente"
          name="comissaoPaga"
          value={contrato.comissaoPaga}
          onChange={handleChange}
        />
      </div>
    </>
  );
}
