import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../panel";
import {Button} from "../button";
import {BlockUI} from "primereact/blockui";
import {toast} from "react-toastify";
import { ConfirmDialog } from "../confirmdialog";
import { useBreakpoint } from "../../context/BreakpointContext";

export function FormEdit(props) {
    
    const {breakpoint} = useBreakpoint()
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false);

    const [confirmacao, setConfirmacao] = useState(false)
    const [confirmacaoRestaurar, setConfirmacaoRestaurar] = useState(false)

    function handleVoltar() {
        navigate(-1);
    }

    function handleHistorico() {
        let paths = location.pathname.split("/");
        paths = paths.slice(-(paths.length - 1));
        const lastElement = paths[paths.length - 1];
        const newPaths = [...paths.slice(0, paths.length - 1), "historico", lastElement];
        const path = newPaths.join("/");
        navigate("/" + path);
    }

    async function handleSalvar() {

        if (props.onSalvar) {
			return await props.onSalvar();
		}

        const messages = props.service.validar(props.value);
        if (!messages.isEmpty()) {
            if (props.onValidate) {
                props.onValidate(messages);
            }
            return;
        }
        setLoading(true);
        let {value} = props;
        if (props.preSalvar) {
            await props.preSalvar(value);
        }
        toast.promise(
            props.service.salvar(value).then(value => {
                if (props.onSalvar) {
                    props.onSalvar(value);
                }
                handleVoltar();
            }).finally(e => setLoading(false)),
            {
                "pending": `Salvando ${props.descricao}. Aguarde...`,
                "success": `${props.descricao} salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar ${props.descricao}: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }

    function handleRestaurar() {
        const messages = props.service.validar(props.value);
        if (!messages.isEmpty() && props.onValidate) {
            props.onValidate(messages);
            return;
        }
        setLoading(true);

        const newValue = {...props.value, status:'ATIVO'}
        toast.promise(
            props.service.salvar(newValue).then(value => {
                if (props.onSalvar) {
                    props.onSalvar(value);
                }
                handleVoltar();
            }).finally(e => setLoading(false)),
            {
                "pending": `Restaurando ${props.descricao}. Aguarde...`,
                "success": `${props.descricao} restaurado com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar ${props.descricao}: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }

    function handleExcluir() {
        setLoading(true);
        toast.promise(
            props.service.excluir(props.value?.id).then(handleVoltar),
            {
                "pending": `${toastDeletar[0]} ${props.descricao}. Aguarde...`,
                "success": `${props.descricao} ${toastDeletar[1]}  com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao ${toastDeletar[2]}  ${props.descricao}: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }

    const verificacaoStatus =  props.value?.status === 'ATIVO';
    const labelDeletar = verificacaoStatus ? 'Desativar' : 'Excluir'
    const iconDeletar = verificacaoStatus ? 'fa-solid fa-eye-slash' : 'fa-solid fa-trash'
    const titleDeletar = verificacaoStatus ? 'Desativar' : 'Excluir permanentemente'
    const toastDeletar = verificacaoStatus ? ['Desativando', 'dasativado', 'desativar'] : ['Excluindo permanentemente', 'excluído permanentemente', 'excluir permanentemente']

    const isLargeDevice = breakpoint === 'lg' || breakpoint === 'md'
    return (
        <BlockUI blocked={props.blocked}>
            <Panel header={ props.descricao} icons={!!props.headerRight ? props.headerRight : null}> 
                <PanelContent>
                    {props.children}
                </PanelContent>
                <PanelFooter>
                    {
                        props.podeDeletar && props.value?.id &&
                        <div style={{float: "left"}}>
                            <Button 
                                disabled={!props.value?.id || props.lockDelete || loading || props.carregando } 
                                icon={iconDeletar} 
                                danger="true" 
                                label={isLargeDevice ? labelDeletar : null} 
                                onClick={() => setConfirmacao(true)}
                                title={titleDeletar}
                                autowidth={!isLargeDevice}
                            />
                        </div>
                    }
                    {
                        props.podeDeletar && !verificacaoStatus && props.value?.id && props.value.status &&
                        <div style={{float: "left"}}>
                            <Button 
                                icon='fa-solid fa-clock-rotate-left' 
                                disabled={!props.value?.id || props.lockDelete || loading || props.carregando } 
                                label={isLargeDevice ? 'Restaurar' : null}
                                onClick={() => setConfirmacaoRestaurar(true)}
                                title={'Restaurar Equipamento'}
                                autowidth={!isLargeDevice}
                            />
                        </div>
                    }
                    {
                        (props.podeSalvar === undefined || props.podeSalvar) && !(props.value?.status === 'DESATIVADO') &&  !(props.value?.status === 'SUSPENSO') &&
                        <Button 
                            icon="fa-solid fa-floppy-disk" 
                            disabled={loading || props.lockSave} 
                            loading={loading} 
                            success='true'
                            label={isLargeDevice ? "Salvar" : null}
                            onClick={() => {
								if (!!props.salvar) {
									props.salvar()
								} else {
									handleSalvar()
								}
							}}
                            autowidth={!isLargeDevice + ""}
                        />
                    }
                    {
                        props.podeBaixar && <Button icon="fa-solid fa-file-arrow-down" success disabled={loading || props.carregando } label="PDF" loading={loading || props.carregando}  onClick={props.baixar} title="Fazer download do pdf do pedido de compra"/>
                    }
                    {
                        (props.podeVoltar === undefined || props.podeVoltar) &&
                        <Button 
                            icon="fa-solid fa-arrow-left" 
                            secondary='true' 
                            disabled={loading || props.carregando } 
                            label={isLargeDevice ? "Voltar" : null} 
                            onClick={handleVoltar}
                            autowidth={!isLargeDevice + ""}
                
                        />
                    }
                    {
                        !props.semHistorico && props.value?.id &&
                        <div style={{float: "left"}}>
                            <Button 
                                icon="fa-solid fa-clock" 
                                secondary
                                disabled={!props?.value?.id || loading} 
                                title='Histórico de alterações' 
                                onClick={handleHistorico}
                                autowidth='true' 
                            />
                        </div>
                    }
                    <ConfirmDialog
                        message={`Tem certeza de que deseja ${toastDeletar} este registro?`}
                        accept={handleExcluir}
                        reject={() => {return}}
                        visible={confirmacao}
                        onHide={() => setConfirmacao(false)}
                    />
                     <ConfirmDialog
                        message='Tem certeza de que deseja restaurar este registro?'
                        accept={handleRestaurar}
                        reject={() => {return}}
                        visible={confirmacaoRestaurar}
                        onHide={() => setConfirmacaoRestaurar(false)}
                    />
                </PanelFooter>
            </Panel>
        </BlockUI>
    );
}