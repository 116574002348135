import React from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {useAuth} from "../../context/AuthContext";

export const TabelaUsuario = React.memo(({value}) => {

    const navigate = useNavigate();
    const {roles} = useAuth();

    function handleEditar(event) {
        if (!roles.USRC && !roles.USRB) return;
        navigate(`/usuarios/${event.data.id}`);
    }

    function templateStatus(data) {
        if (data.status === "ATIVO") {
            return (
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <i title="Ativado" className="fa-solid fa-user fa-lg" style={{color: "#00940a"}}/>
                </div>
            )
        } else {
            return (
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <i title="Desativado" className="fa-solid fa-user fa-lg" style={{color: "#d10404"}}/>
                </div>
            )
        }
    }

    return (
        <DataTable value={value} onRowDoubleClick={handleEditar} valuelength={value?.length}>
            <Column style={{width: "2em"}} sortable header="Status" body={e => templateStatus(e)}/>
            <Column sortable header="Nome" field="nome"/>
            <Column sortable header="E-mail" field="email"/>
        </DataTable>
    );

});
