import React, {useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {ordemServicoService} from "../../service/ordemServicoService";
import {CsvInput} from "../../components/chips/CsvInput";
import { InputTextarea } from "../../components/inputtextarea";

export function OrdemServicoOrcamentoEmail({ordensServico}) {

	let titulo;
	let numeros;

	if (ordensServico.length === 1) {
		numeros = `Orçamento para a Ordem de Serviço ${ordensServico[0].numero}`;
		titulo = `Orçamento para a Ordem de Serviço`;
	} else {
		numeros = `Orçamentos para as Ordens de Serviço ${ordensServico.map(os => os.numero).join(", ")}`;
		titulo = `Orçamentos para as Ordens de Serviço`;
	}

	const destinatarios = [];

	if (ordensServico[0].cliente?.email) {
		destinatarios.push(ordensServico[0].cliente?.email);
	}

	const [params, setParams] = useState({
		titulo,
		conteudo: "",
		destinatarios,
		numeros,
		ordensServico: ordensServico.map(os => os.id),
		cliente: ordensServico[0].cliente?.id
	});

	const [visible, setVisible] = useState(true);
	const [blocked, setBlocked] = useState(0);

	function handleFechar() {
		setVisible(false);
	}

	function handleChange(event) {
		setParams({...params, [event.name]: event.value});
	}

	function enviarOrcamentos() {
		setBlocked(2);
		const parametros = {
			...params,
			destinatarios: params.destinatarios.filter(des => des.length > 0)
		}

		ordemServicoService.enviarOrcamentos({...parametros}).then(() => {
			setBlocked(0);
			handleFechar();
		});
	}

	return (
		<Dialog style={{maxWidth: "1200px", width: "90%"}} header={`Enviando Orçamentos para OS ${ordensServico.map(os => os.numero).join(", ")}`} visible={visible} onHide={handleFechar}>
			<PanelContent>
				<CsvInput disabled={blocked} col={12} name="destinatarios" value={params.destinatarios} onChange={handleChange} label={<span>Destinatários <i>(ao final de cada endereço, pressione Enter)</i></span>}/>
				<InputText maxLength={255}  disabled={blocked} label="TítuloO" name="titulo" value={params.titulo} onChange={handleChange} col={12}/>
				<InputTextarea  disabled={blocked} label="Conteúdo" name="conteudo" value={params.conteudo} onChange={handleChange} col={12} style={{height: "13em"}}/>
			</PanelContent>
			<DialogFooter>
				<Button loading={blocked === 2} disabled={blocked} label="Enviar" success onClick={enviarOrcamentos} icon="fa-solid fa-envelope"/>
				<Button label="Cancelar" secondary onClick={handleFechar} icon="fa-solid fa-times"/>
			</DialogFooter>
		</Dialog>
	);

}
