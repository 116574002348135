const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function isRequired(value, message = "Campo obrigatório") {
    return value?.trim()?.length ? null : message;
}

export function isNotNull(value, message = "Campo obrigatório") {
    return value ? null : message;
}

export function isEntityRequired(value, message = "Campo obrigatório") {
    return value?.id ? null : message;
}

export function isRequiredEmail(value) {
    return isRequired(value) || (emailRegex.test(value) ? null : "e-mail inválido");
}

export function isEmail(email){
    return emailRegex.test(email) ? null : "e-mail inválido";
}

export function isRequiredNome(value) {
    return isRequired(value) || (value?.split(" ").length < 2 ? "Nome incompleto" : null);
}

export function isBiggerThanZero(number, message = "O número deve ser maior que zero.") {
    return Number(number) > 0 ? null : message;
}

export function isBiggerThanZeroValor(number, message) {
    return Number(number) > 0 ? null : (message?.length > 0 ? message : "O valor deve ser maior que zero.");
}

export function isArrayNotEmpty(array) {
    return array?.length ? null : "Você precisa selecionar ao menos uma opção";
}

export function hasMoreThenOneWord(name) {
    return name.split(" ").length > 1;
}

export function isPassword(password) {
    return isRequired(password) || (password.includes(" ") ? "A senha não deve conter espaço"  : null);
}

export function isPasswordEqual(password, password2) {
    return isRequired(password2) || (password === password2 ? null : "As senhas não são iguais");
}

const INVALID_CNPJS = [
    "00000000000000",
    "11111111111111",
    "22222222222222",
    "33333333333333",
    "44444444444444",
    "55555555555555",
    "66666666666666",
    "77777777777777",
    "88888888888888",
    "99999999999999"
];

export function isCNPJ(cnpj) {
    const required = isRequired(cnpj);
    if(!!required) return required;
    if (cnpj === "") return "CNPJ inválido";
    cnpj = cnpj.replace(/[^0-9]/g, "");
    if (cnpj.length !== 14) return "CNPJ inválido";
    if (INVALID_CNPJS.includes(cnpj)) return "CNPJ inválido";
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== Number(digitos.charAt(0))) return "CNPJ inválido";
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== Number(digitos.charAt(1))) return "CNPJ inválido";
    return null;
}

export function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isCEP(cep) {
    const testeCep = /^[0-9]{8}$/
    return isRequired(cep) || (!testeCep.test(cep) ? "CEP inválido" : null)
}

const validUFs = [
    "RO", "AC", "AM", "RR", "PA",
    "AP", "TO", "MA", "PI", "CE",
    "RN", "PB", "PE", "AL", "SE",
    "BA", "MG", "SE", "RJ", "SP",
    "PR", "SC", "RS", "MS", "MT",
    "GO", "DF"
];

export function isUF(uf) {
    return isRequired(uf) || (!validUFs.includes(uf) ? "UF inválido" : null);
}

export function isEmpty(obj) {
    return obj && !Object.keys(obj).some(k => {
        if (k === "isEmpty") {
            return false;
        }
        if (obj[k] instanceof Array) {
            if (!obj[k].length) {
                return false;
            }
            for (const item of obj[k]) {
                if (!isEmpty(item)) {
                    return true;
                }
            }
            return false;
        }
        if (obj[k] instanceof Object) {
            return !isEmpty(obj[k]);
        } else {
            return obj[k];
        }
    });
}

export function buildValidator() {
    const validator = {
        isEmpty: () => isEmpty(validator)
    };
    return validator;
}

export const isTimestamp = (object) => {
    return /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/g.test(object);
}

export const isDate = (object) => {
    return /[0-9]{4}-[0-9]{2}-[0-9]{2}/g.test(object);
}

export const isTime = (object) => {
    return /[0-9]{2}:[0-9]{2}:[0-9]{2}/g.test(object);
}

export function isSeloIpemValido(selo) {
    selo = selo.replace(/[^0-9]/g, "");
    return parseInt(selo.charAt(selo.length - 1)) !== calcularDvSeloIpem(selo.substring(0, selo.length - 1)) ? "Número incorreto" : null;
}

export function calcularDvSeloIpem(selo) {
    return Array.from(selo).map((c, i) => (9 - i) * parseInt(c)).reduce((a, b) => a + b, 0) % 11 % 10;
}

export function formatNumeroSeloIpem(numero) {
    return numero.substring(0, 2) + "." + numero.substring(2, 5) + "." + numero.substring(5, 8) + "-" + numero[8];
}
