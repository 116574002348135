import { api } from "../../../service/api";
import { baseService } from "../../../service/baseService";
import { buildValidator, isBiggerThanZero, isBiggerThanZeroValor, isRequired } from "../../../utils/fieldValidator";

const resourceUrl = "/comissoes";

export const comissaoAcompanhamentoService = {
	
    validar: tipoComissao => {
		let messages = buildValidator();

		messages.descricao = isRequired(tipoComissao.descricao)
		
        //ITENS
        if (!messages.itens) {
			messages.itens = [...Array(tipoComissao.itens.length)].map((_, i) => ({
				percentual: null,
				valor: null,
			}));
		}
		for (let i = 0; i < tipoComissao.itens.length; ++i) {
			messages.itens[i].percentual = isBiggerThanZero(tipoComissao.itens[i].percentual);
			if(tipoComissao.tipo === 'FAIXA'){
				messages.itens[i].valor = isBiggerThanZeroValor(tipoComissao.itens[i].valor)
			}
		}

		return messages;
	},

	listarAcompanhamentos: async (params) => {	
		let listar;	
		if (params) {
			listar = await api.get(`${resourceUrl}/listar?${params.join("&")}`).then(response => response.data);
		} else {
			listar = await api.get(`${resourceUrl}`).then(response => response.data);
		}
		return listar;
	},

	salvarTodos: async (acompanhamentos) => {
		return await api.post(`${resourceUrl}/salvarTodos`, acompanhamentos).then(response => response.data);
	},

	...baseService(resourceUrl)
};

export const optionsComissaoAcompanhamento = [
	{icon: "fas fa-thumbs-up", value: "APROVADO", color: "#4CAF50"},
	{icon: "fas fa-thumbs-down", value: "REPROVADO", color: "#FF5722"},
];