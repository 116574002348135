import React, {useContext, useEffect, useState} from "react";
import {defaultQueryParams} from "./defaultQueryParams";
import {sessionStore} from "./sessionHandler";

const QueryParamContext = React.createContext({params: defaultQueryParams, changeParams: null});

export function QueryParamsProvider({children}) {
    const [params, setParams] = useState(sessionStore("X-QueryParams", defaultQueryParams));
    useEffect(() => {
        window.sessionStorage.setItem("X-QueryParams", JSON.stringify(params));
    }, [params]);
    function changeParams(key, event) {
        setParams(prevParams => ({...prevParams, [key]: {...prevParams[key], [event.name]: event.value}}));
    }
    return <QueryParamContext.Provider value={{params, changeParams}} children={children}/>;
}

export function useQueryParams(key) {
    const {params, changeParams} = useContext(QueryParamContext);
    function handleChange(event) {
        changeParams(key, event);
    }
    return {params: params[key], handleChange};
}
