import {buildValidator} from "../utils/fieldValidator";
import {api} from "./api";
import {baseService} from "./baseService";

const resourceUrl = "/condicoes-pagamento";

export const condicaoPagamentoService = {
    buscarPorContrato: async (id) => {
        return api.get(`${resourceUrl}/contrato/${id}`).then(res => res.data);
    },
    ...baseService(resourceUrl)
};
