import React from "react";
import {SelectButton} from "./index";
import {Tooltip} from "primereact/tooltip";

function buttonModel(item) {
    return (
        <>
            <Tooltip target={`.sla-filter-${item.value}`} position="top"/>
            <i data-pr-tooltip={item.tooltip} className={`sla-filter-${item.value} fas fa-clock`} style={{fontSize: "1.5em", margin: "-.5em", transform: "translateY(.125em)", color: item.color}}/>
        </>
    );
}

const optionsFaixaSLA = [
    {label: buttonModel({color: "#4CAF50", tooltip: "Dentro do Prazo", value: "GREEN"}), value: "GREEN"},
    {label: buttonModel({color: "#FF9800", tooltip: "Perigo de Atraso", value: "YELLOW"}), value: "YELLOW"},
    {label: buttonModel({color: "#F44336", tooltip: "Atraso Iminente", value: "RED"}), value: "RED"},
    {label: buttonModel({color: "#222222", tooltip: "Atrasado", value: "BLACK"}), value: "BLACK"},
    {label: buttonModel({color: "#2196F3", tooltip: "Backup Enviado", value: "BLUE"}), value: "BLUE"}
];

export function FaixaSLASelectButton(props) {

    return (
        <SelectButton className="sla-select" col={12} options={optionsFaixaSLA} label="SLA" name="sla" {...props}/>
    );

}
