import React, {useCallback, useEffect, useState} from 'react';

import {useNavigate, useParams} from "react-router-dom";
import {TabPanel, TabView} from 'primereact/tabview';
import {toast} from 'react-toastify';

import {EditarDadosPrincipais} from './EditarDadosPrincipais';
import {EditarClausulasContratuais} from './EditarClausulasContratuais';
import {EditarAcompanhamento} from './Acompanhamento';
import {EditaFinanceiro} from './EditarFinanceiro';
import {contratoService, status} from '../../service/contratoService';
import {VisualizarEventos} from '../Eventos/VisualizarEventos';
import {FormTab} from '../../components/formtab';
import {PanelContent} from '../../components/panel';
import {buildValidator, isEmpty} from '../../utils/fieldValidator';
import {Button} from '../../components/button';
import {EditarParametros} from './EditarParametros';
import {EditarCliente} from './EditarCliente';
import {withDialog} from "../../utils/dialogContext";
import {BlockUI} from "primereact/blockui";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {EditarContratoCobertura} from "./EditarContratoCobertura";
import {tipoContratoService} from '../../service/tipoContratoService';
import {useAuth} from "../../context/AuthContext";
import {InformationDialog} from '../../components/dialog/InformationDialog';
import {EditarAnexo} from './EditarAnexo';
import {EditarCoberturaFranquia} from "./EditarCoberturaFranquia";
import "./styles.scss";
import {EditarContratoModelos} from "./EditarContratoModelos";
import {ordemServicoService} from "../../service/ordemServicoService";
import {ProgressDialog} from "../../components/progressdialog/ProgressDialog";
import {Checkbox} from "../../components/checkbox";
import {EditarContratoCoberturaEquipamento} from "./EditarContratoCoberturaEquipamento";

export const EditarContrato = withDialog(({showDialog}) => {

    let {id} = useParams();
    const navigate = useNavigate();
    const {roles} = useAuth();
    const [contrato, setContrato] = useState(contratoService.criar());
    const [messages, setMessages] = useState({
        ...buildValidator(),
        clausulas: [],
        acompanhamentos: [],
        anexos: [],
        produtos: [],
        servicos: [],
        equipamentos: [],
        modelos: []
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!id) return;
        if (id === 'new') return;
        contratoService.buscar(id).then(contrato => {
            if(!contrato) return;
            setContrato(contrato);
        })
    }, [id, loading, setContrato])

    const [tiposContrato, setTiposContrato] = useState([]);

	useEffect(() => {
        tipoContratoService.listar(["query="]).then(tiposContrato => {
            setTiposContrato(tiposContrato);
        });
	}, []);

	function handleChange(event) {
	    setContrato(prevContrato => ({...prevContrato, [event.name]: event.value}));
    }

    
	const handleChangeParametros = useCallback((event) => {
		setContrato((contratoAtualizado) => {
            return {...contratoAtualizado, contratoParametros: {...contratoAtualizado.contratoParametros, [event.name]: event.value}}
        });
	}, [setContrato])

    function handleSalvar() {
        const messages = contratoService.validar(contrato);
        if (!messages.isEmpty()) {
            setMessages(messages);
            showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;
        }

        setLoading(true);

        toast.promise(
            contratoService.salvar(contrato)
                .then(() => navigate(-1))
                .finally(() => setLoading(false)),
            {
                "pending": `Salvando contrato. Aguarde...`,
                "success": `Contrato salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar contrato`;
                    }
                }
            }
        );
    }

    async function atualizarOrdensServico() {
	    showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja reprocessar todas as ordens de serviço vinculadas a este contrato? Este processo pode levar vários minutos." onYes={async () => {
            showDialog(<ProgressDialog onProgress={async setProgress => {
                let progress = 0;
                const osIds = await ordemServicoService.buscarOrdensServicoContrato(contrato.id);
                for (const osId of osIds) {
                    await ordemServicoService.salvar(await ordemServicoService.buscar(osId));
                    setProgress(++progress / osIds.length);
                }
            }}/>);
        }}/>);
    }

    const botoesAdicionais = () => {
        const botoes = [];
        if (contrato.status === "PENDENTE") {
            botoes.push(
                <Button key={1} icon="fa-solid fa-check" success disabled={loading === 1} label="Aprovar" auto_width loading={loading === 1} onClick={() => {
                    showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja aprovar este contrato?" onYes={async () => {
                        setLoading(1);
                        await contratoService.salvar({...contrato, status: "ATIVO", postBack: true}).then(setContrato);
                        setLoading(0);
                    }}/>)
                }}/>
            );
        }
        if (contrato.status === "PENDENTE" || contrato.status === "ATIVO") {
            botoes.push(
                <Button key={2} icon="fa-solid fa-x" danger disabled={loading} label="Cancelar" auto_width loading={loading === 2} onClick={() => {
                    showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja cancelar este contrato?" onYes={async () => {
                        setLoading(2);
                        await contratoService.salvar({...contrato, status: "CANCELADO", postBack: true}).then(setContrato);
                        setLoading(0);
                    }}/>);
                }}/>
            );
        }
        if (contrato.status === "ATIVO" || contrato.status === "ENCERRADO") {
            botoes.push(
                <Button key={3} icon="fa-solid fa-arrows-rotate" label="Renovar" disabled={loading} auto_width loading={loading === 3} onClick={() => {
                    setLoading(2);
                    setLoading(0);
                }}/>
            );
        }
        if (contrato.status === "ATIVO") {
            botoes.push(
                <Button key="reprocessar" label="Atualizar OS" danger icon="fas fa-refresh" onClick={atualizarOrdensServico}/>
            );
        }
        return (
            <div style={{float: "left"}}>{botoes}</div>
        );
    }

    const headerStatus = useCallback(() => {
        if (!contrato.id) {
            return;
        }
        const value = status.filter(status => status.value === contrato.status)[0];
		return (
            <div className="flex" title={value.label}>
                <i className={`fa-solid ${value.icon} fa-lg mr-2`} style={{color: value.color}}/>
                <strong>{value.label}</strong>
            </div>
        );
	}, [contrato.id, contrato.status]);

    const descricao = !contrato.id ? 'Contrato' : `Contrato nº ${contrato.numero}`;

    function headerTemplate(e) {
		let style = {};

        switch (e.index) {
			case 0:
				if (messages.cliente || messages.inicio || messages.fim || messages.tipoContrato ) {
					style.color = "#F44336";
				}
				break;
			case 1:
				break;
			case 2:
                if (contrato.contratoParametros?.cobeturaMO) {
                    if(messages.cobeturaMODescricão){
                        style.color = "#F44336";
                    } 
                }
                if(contrato.contratoParametros?.localidade){
                    if(messages.localidade){
                        style.color = "#F44336";
                    }  
                }
                if(contrato.contratoParametros?.treinamentoOperacional){
                    if(messages.treinamentoOperacional){
                        style.color = "#F44336";
                    }  
                }
                if (contrato.contratoParametros?.condenacaoDoEquipamento) {
                    if(messages.condenacaoDoEquipamento){
                        style.color = "#F44336";
                    } 
                }
                if(contrato.contratoParametros?.suporte){
                    if(messages.suporte){
                        style.color = "#F44336";
                    }  
                }
                if(contrato.contratoParametros?.logisticaZecode){
                    if(messages.logisticaZecode){
                        style.color = "#F44336";
                    }  
                }
                if (contrato.contratoParametros?.slaLaboratorio) {
                    if(messages.slaLaboratorio){
                        style.color = "#F44336";
                    } 
                }
                if(contrato.contratoParametros?.descontoPeca){
                    if(messages.descontoPeca){
                        style.color = "#F44336";
                    }  
                }
                if(contrato.contratoParametros?.preventiva){
                    if(messages.preventiva){
                        style.color = "#F44336";
                    }  
                }
                if (contrato.contratoParametros?.backupCliente) {
                    if(messages.backupCliente){
                        style.color = "#F44336";
                    } 
                }
                if(contrato.contratoParametros?.backupContrato){
                    if(messages.backupContrato){
                        style.color = "#F44336";
                    }  
                }
                if(contrato.contratoParametros?.recuperacoModulos){
                    if(messages.recuperacoModulos){
                        style.color = "#F44336";
                    }  
                }
                if (contrato.contratoParametros?.baseline) {
                    if(messages.baseline){
                        style.color = "#F44336";
                    } 
                }
                if(contrato.contratoParametros?.mauUso){
                    if(messages.mauUso){
                        style.color = "#F44336";
                    }  
                }
                if(contrato.contratoParametros?.manutencaoCorretiva){
                    if(messages.manutencaoCorretiva){
                        style.color = "#F44336";
                    }  
                }
				break;
			case 3:
                if (messages.clausulas.some(mi => !isEmpty(mi))) {
					style.color = "#F44336";
				}
				break;
			case 4:
                if (messages.equipamentos.some(mi => !isEmpty(mi))) {
					style.color = "#F44336";
				}
                if (messages.produtos.some(mi => !isEmpty(mi))) {
					style.color = "#F44336";
				}
                if (messages.servicos.some(mi => !isEmpty(mi))) {
					style.color = "#F44336";
				}
				break;
			case 5:
                if (messages.acompanhamentos.some(mi => !isEmpty(mi))) {
					style.color = "#F44336";
				}
				break;
			case 6:
                if (messages.valor || messages.diaVencimento || messages.peridiocidadeParcela || messages.emissao || messages.condicaoPagamento) {
					style.color = "#F44336";
				}

                if (contrato.contratoParametros?.multa) {
                    if(messages.multa){
                        style.color = "#F44336";
                    } 
                }
                if(contrato.contratoParametros?.reajuste){
                    if(messages.reajuste){
                        style.color = "#F44336";
                    }  
                }
                if(contrato.contratoParametros?.indiceReajuste){
                    if(messages.indiceReajuste){
                        style.color = "#F44336";
                    }  
                }

				break;
            case 7:
                break;
            case 8:
                if (messages.anexos.some(mi => !isEmpty(mi))) {
                    style.color = "#F44336";
                }
                break;
			default:
				break;
		}
        
		return (
			<div className={`${e?.className}`} aria-controls={`${e.ariaControls}`} onClick={e.onClick} onKeyDown={e.onKeyDown} style={style}>
				<i className={`${e?.leftIconElement?.props?.className}`}/>
				<div className={`${e?.titleClassName}`} >{e?.titleElement?.props?.children}</div>
			</div>
		);
	}

    return (
        <BlockUI blocked={loading}>
            <FormTab
                descricao={descricao}
                service={contratoService}
                value={contrato}
                blocked={id !== "new" && !contrato.id}
                onValidate={setMessages}
                carregando={loading}
                salvar={handleSalvar}
                podeSalvar={roles.CONC}
                podeDeletar={false}
                botoes_adicionais={botoesAdicionais()}
                header_right={headerStatus}
            >			
                <TabPanel  header="Dados Principais" leftIcon='fa-solid fa-database' headerTemplate={headerTemplate} invalid={messages?.dadosPrincipais?.some(element => element)}>
                    <PanelContent>
                        <EditarDadosPrincipais contrato={contrato} setContrato={setContrato} messages={messages} setMessages={setMessages} tiposContrato={tiposContrato}/>
                    </PanelContent>
                </TabPanel>
                <TabPanel header="Clientes" leftIcon='fa-solid fa-user-tie' headerTemplate={headerTemplate} invalid={messages?.clientes?.some(element => element)}>
                    <PanelContent>
                        <EditarCliente contrato={contrato} setContrato={setContrato} messages={messages} setMessages={setMessages}/>
                    </PanelContent>
                </TabPanel>
                <TabPanel header="Parâmetros" leftIcon='fa-solid fa-square-check' headerTemplate={headerTemplate} invalid={messages?.contratoParametros?.some(element => element)}>
                    <PanelContent>
                        <EditarParametros contrato={contrato} setContrato={setContrato} messages={messages} setMessages={setMessages}/>
                    </PanelContent>
                </TabPanel>
                <TabPanel header="Clausulas" leftIcon='fa-solid fa-handshake' headerTemplate={headerTemplate} invalid={messages?.clausulas?.some(element => element)}>
                    <PanelContent>
                        <EditarClausulasContratuais contrato={contrato} setContrato={setContrato} messages={messages} setMessages={setMessages}/>
                    </PanelContent>
                </TabPanel>
                <TabPanel header="Coberturas" leftIcon="fa-solid fa-shield-heart" headerTemplate={headerTemplate} invalid={
                    ["equipamentos", "produtos", "servicos"].some(k => messages[k]?.some(cc => Object.keys(cc).some(cck => cc[cck])))
                }>
                    <PanelContent>
                        <div className={`grid col-12 grid-nogutter cobtotal-container ${contrato.coberturaTotalProdutos && contrato.coberturaTotalServicos}`}>
                            <div className="col-3 cobtotal-checkbox-container">
                                <div>
                                    <Checkbox label={<b>Cobertura Total de Peças</b>} value={contrato.coberturaTotalProdutos} onChange={handleChange} name="coberturaTotalProdutos"/>
                                </div>
                                <div>
                                    <Checkbox label={<b>Cobertura Total de Serviços</b>} value={contrato.coberturaTotalServicos} onChange={handleChange} name="coberturaTotalServicos"/>
                                </div>
                                <div>
                                    <Checkbox label={<b>Suporte</b>} value={contrato.contratoParametros?.suporte} onChange={handleChangeParametros} name="suporte"/>
                                </div>
                            </div>
                            <div className="col-9">
                                <i>
                                    As configurações de cobertura de peças e serviços abaixo sobrepõe esta opção.<br/>
                                    Se uma peça/serviço não estiver na lista de cobertura, mas a opção acima estiver marcada, entende-se que a peça/serviço está coberta.<br/>
                                    Se uma peça/serviço estiver na lista de cobertura, os limites e valores para ela aplicados serão respeitados (<b>cobertura condicional</b>).
                                </i>
                            </div>
                        </div>
                        <div className="col-12">
                            <TabView>
                                <TabPanel header="Peças" leftIcon="fa-solid fa-cubes" headerTemplate={headerTemplate} invalid={messages.produtos?.some(cc => Object.keys(cc).some(cck => cc[cck]))}>
                                    <PanelContent>
                                        <i>
                                            Define a cobertura de peças (de reposição) durante a periodicidade definida<br/>
                                            O valor excedente será cobrado caso algum dos limites seja atingido
                                        </i>
                                        <EditarContratoCobertura messages={messages} setMessages={setMessages} contrato={contrato} setContrato={setContrato} tipo="produtos"/>
                                    </PanelContent>
                                </TabPanel>
                                <TabPanel header="Serviços" leftIcon="fa-solid fa-wrench" headerTemplate={headerTemplate} invalid={messages.servicos?.some(cc => Object.keys(cc).some(cck => cc[cck]))}>
                                    <PanelContent>
                                        <i>
                                            Define a cobertura de serviços (a serem executados) durante a periodicidade definida<br/>
                                            O valor excedente será cobrado caso algum dos limites seja atingido
                                        </i>
                                        <EditarContratoCobertura messages={messages} setMessages={setMessages} contrato={contrato} setContrato={setContrato} tipo="servicos"/>
                                    </PanelContent>
                                </TabPanel>
                                <TabPanel header="Modelos" leftIcon="fa-solid fa-boxes" headerTemplate={headerTemplate} invalid={messages.modelos?.some(cc => Object.keys(cc).some(cck => cc[cck]))}>
                                    <PanelContent>
                                        <EditarContratoModelos contrato={contrato} setContrato={setContrato}/>
                                    </PanelContent>
                                </TabPanel>
                                <TabPanel header="Equipamentos" leftIcon="fa-solid fa-terminal" headerTemplate={headerTemplate} invalid={messages.equipamentos?.some(cc => Object.keys(cc).some(cck => cc[cck]))}>
                                    <PanelContent>
                                        <i>
                                            Define quantidade de ordens de serviço a serem cobertas durante a periodicidade definida<br/>
                                            O valor excedente (dos produtos e serviços) será cobrado caso algum dos limites seja atingido
                                        </i>
                                        <div className="col-12">
                                            <EditarContratoCoberturaEquipamento messages={messages} setMessages={setMessages} contrato={contrato} setContrato={setContrato}/>
                                        </div>
                                    </PanelContent>
                                </TabPanel>
                                <TabPanel header="Franquia" leftIcon="fa-solid fa-cubes" headerTemplate={headerTemplate}>
                                    <EditarCoberturaFranquia contrato={contrato} setContrato={setContrato}/>
                                </TabPanel>
                            </TabView>
                        </div>
                    </PanelContent>
                </TabPanel>
                <TabPanel header="Acompanhamentos" leftIcon='fa-solid fa-business-time' headerTemplate={headerTemplate} invalid={messages?.acompanhamentos?.some(element => element)}>
                    <PanelContent>
                        <EditarAcompanhamento contrato={contrato} setContrato={setContrato} messages={messages} setMessages={setMessages}/>
                    </PanelContent> 
                </TabPanel>
                <TabPanel header="Financeiro" leftIcon='fa-solid fa-coins' headerTemplate={headerTemplate} invalid={messages?.financeiro?.some(element => element)}>
                    <PanelContent> 
                        <EditaFinanceiro contrato={contrato} setContrato={setContrato} messages={messages} setMessages={setMessages}/>
                    </PanelContent>
                </TabPanel>
                <TabPanel header="Eventos" leftIcon='fa-solid fa-file-lines' headerTemplate={headerTemplate}>
                    <PanelContent>
                        <VisualizarEventos eventos={contrato.eventos}/>
                    </PanelContent>
                </TabPanel>            
                <TabPanel header="Anexos" leftIcon='fa-solid fa-paperclip' headerTemplate={headerTemplate}>
                    <PanelContent>
                        <EditarAnexo contrato={contrato} setContrato={setContrato} messages={messages} setMessages={setMessages}/>
                    </PanelContent>
                </TabPanel>            
            </FormTab>
        </BlockUI>
    );

});
