import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {buildValidator} from '../../../utils/fieldValidator';
import {TabPanel} from 'primereact/tabview';
import {FormTab} from '../../../components/formtab';
import {toast} from 'react-toastify';
import {icmsService} from '../services/icmsService';
import {useAuth} from "../../../context/AuthContext";
import { EditarDadosPrincipais } from './EditarDadosPrincipais';


export function EditarIcms() {

	const {id} = useParams();
	const navigate = useNavigate();
	const {roles} = useAuth();
	const [icms, setIcms] = useState(icmsService.criar());
	const [messages, setMessages] = useState(buildValidator());
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (id !== "new") {
			icmsService.buscar(id).then(setIcms);
		}
	}, [id, loading, setIcms]);

	const handleSalvar =  useCallback( async () => {

        const messages = icmsService.validar(icms);
		
        if (!messages.isEmpty()) {
			setMessages(messages);
            return;
        } else {
			setLoading(true);
			const messagesBackend =  await icmsService.validarBackend(icms) ;			
			if (messagesBackend) {
				messages.origem = messagesBackend;
				messages.destino = messagesBackend;				
				setMessages(messages);
				setLoading(false);
				return;
			}
			setLoading(false);
		}
		
        setLoading(true);

        
        toast.promise(
			icmsService.salvar(icms).then(value => {
                navigate(-1)
            }).finally(() => setLoading(false)),
            {
                "pending": `Salvando alíquota de ICMS. Aguarde...`,
                "success": `Alíquota de ICMS salva com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar alíquota de ICMS`;
                    }
                }
            }
        );
    }, [icms, navigate]);
	
	return (
		
		<FormTab
			podeDeletar={false}
			podeSalvar={!!roles.LTPC}
			descricao={"Alíquota de ICMS"}
			service={icmsService}
			value={icms}
			salvar={handleSalvar}
			blocked={id !== "new" && !icms.id}
			onValidate={setMessages}
		>

			<TabPanel header="Dados Principais" leftIcon='fa-solid fa-file-invoice-dollar'>
				<EditarDadosPrincipais icms={icms} setIcms={setIcms} messages={messages} setMessages={setMessages}/>
            </TabPanel>
			
		</FormTab>
	);

}

