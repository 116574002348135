import React from "react";
import {InputTextarea as PInputTextarea} from "primereact/inputtextarea";
import classNames from "classnames";
import {calcularTamanho} from "../../service/guiHelper";

export function InputTextAreaFull (props) {

    function handleChange(event) {
        if (props.onChange) {
            props.onChange({name: props.name, value: event.target.value, index: props.index});
        }
    }

    const classes = calcularTamanho(props.col);

    const inputClasses = classNames({
        "p-invalid block": props.invalid,
        'w-full': true
    });

    const newLocal = props.required ? <small style={{color: props.value?.length ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : null;

    return (
        <div className={classes}>
            <label>{props.label}</label>
            <PInputTextarea {...props} maxLength={props.maxLength? props.maxLength : 3800} placeholder={props.placeholder ? `Digite ${props.placeholder}` : ""} className={inputClasses} onChange={handleChange}/>
            {props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : newLocal}
        </div>
    );

}
