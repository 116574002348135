import React, {useMemo, useState} from 'react';
import { FiltrosIcms  } from './FiltrosIcms';
import { TabelaIcms } from './TabelaIcms';

export function Icms() {

    const [imcss, setIcmss] = useState([]);

    return (
        <div>
            <FiltrosIcms setValue={useMemo(() => setIcmss, [])}/>
            <TabelaIcms value={useMemo(() => imcss, [imcss])}/>
        </div>
    );

}
