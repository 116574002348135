import React from 'react';

import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Spinner } from "../../components/spinner";

export function RequireRole({roleNavigation, roleCreate, roleEdit}) {
	const {roles, carregando} = useAuth()
	let {id} = useParams();
	let location = useLocation();
	const componente = carregando ? <Spinner basico/> : <Outlet/>
	
	if(carregando || !roles) return componente;

	if (id === undefined) {
		if (!roles[roleNavigation]) {
			return <Navigate to="/acessoNegado" state={{from: location}}/>;
		}

		return componente
	}
	if (id === 'new') {
		if (!roles[roleCreate]) {
			return <Navigate to="/acessoNegado" state={{from: location}}/>;
		}
		return componente
	}
	
	return componente;
}