import React, {useMemo} from 'react';
import {TabelaProdutoTipos} from './TabelaProdutoTipos';
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {useBreakpoint} from "../../context/BreakpointContext";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {Panel} from "primereact/panel";
import {SearchButton} from "../../components/button/SearchButton";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {useQueryClientTipoProduto} from "../../utils/query-clients/queryClients";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {If} from "../../components/conditional/If";
import {BlockUI} from "primereact/blockui";

export function ProdutoTipos() {

	const {roles} = useAuth();
	const navigate = useNavigate();
	const {isLargeDevice} = useBreakpoint();
	const {query, handleSearch} = useQueryClientTipoProduto();
	const {params, handleChange} = useQueryParams(QueryParamsKeys.TipoProduto);

	function handleCriar() {
		navigate("/produtos/tipos/new");
	}

	return (
		<BlockUI blocked={query.isLoading}>
			<Panel headerTemplate={() => headerTemplate('Tipo de Produto', 'fa-box')}>
				<PanelContent>
					<InputText placeholder="a descrição do tipo" col={12} label="Descrição" value={params.descricao}
							   name="descricao" onChange={handleChange}/>
				</PanelContent>
				<PanelFooter>
					<If condition={!!roles.PRTA}>
						<Button success onClick={handleCriar} label={isLargeDevice ? "Novo" : null} icon="pi pi-plus" autowidth={!isLargeDevice}/>
					</If>
					<SearchButton query={query} onSearch={handleSearch}/>
				</PanelFooter>
			</Panel>
			{useMemo(() => <TabelaProdutoTipos value={query.data || []}/>, [query.data])}
			<RefetchIndicator isRefetching={query.isRefetching}/>
		</BlockUI>
	);

}
