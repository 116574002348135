import React, {useState} from "react";
import {AutoComplete} from "./index";
import {prateleiraService} from "../../service/prateleiraService";

export function PrateleiraAutoComplete(props) {

    const [prateleiras, setPrateleiras] = useState([]);
    const [emptyMessage, setEmptyMessage] = useState("");

    function completeMethod(event) {
        const query = [];
        if (event.query.length) {
            query.push(`codigo,descricao=lk=${encodeURIComponent(event.query)}`);
        }
        prateleiraService.listar([`query=${query.join(";")}`]).then(prateleiras => {
            setPrateleiras(prateleiras);
            if (!prateleiras[0]) {
                setEmptyMessage("Nenhum registro encontrado");
            } else {
                setEmptyMessage("");
            }
        });
    }

    return (
        <AutoComplete col={3}
                      placeholder="pesquise por código ou descrição"
                      label="Prateleira"
                      icon="fa-solid fa-inbox"
                      name="prateleira"
                      value={props.value}
                      onChange={props.onChange}
                      completeMethod={completeMethod}
                      suggestions={prateleiras}
                      invalid={emptyMessage}
                      {...props}
        />
    );

}
