import React from "react";
import {buildValidator, isEntityRequired} from '../utils/fieldValidator';
import {statusPadrao} from '../utils/statusPadrao';
import {baseService} from './baseService';
import moment from "moment";

const resourceUrl = "/estoques/movimentos";

export const estoqueMovimentoService = {
    criar: () => {
        return {
            id: null,
            _key: Math.random(),
	        estoque: null,
	        quantidade: 0,
	        produto: null,
	        responsavel: null,
	        data: moment().format("YYYY-MM-DDTHH:mm:ss"),
	        notaFiscalNumero: "",
	        notaFiscalSerie: "",
			operacao: "LABORATORIO"
        };
    },
    validar: (estoqueMovimento) => {
        const messages = buildValidator();
        messages.produto = isEntityRequired(estoqueMovimento.produto);
        messages.quantidade = estoqueMovimento.quantidade ? null : "Campo obrigatório";
        messages.responsavel = isEntityRequired(estoqueMovimento.responsavel);
        return messages;
    },
    ...baseService(resourceUrl),
    async listarEstoqueMovimentos(params) {
        const query = [];
        if (params.numero) query.push(`numero=${params.numero}`);
        if (params.inicio?.length) query.push(`inicio=${params.inicio}`);
        if (params.fim?.length) query.push(`fim=${params.fim}`);
        if (params.estoque?.id) query.push(`estoque=${params.estoque.id}`);
        if (params.produto?.id) query.push(`produto=${params.produto.id}`);
        if (params.responsavel?.id) query.push(`responsavel=${params.responsavel.id}`);
        if (params.notaFiscalNumero?.length) query.push(`fim=${params.notaFiscalNumero}`);
        if (params.notaFiscalSerie?.length) query.push(`fim=${params.notaFiscalSerie}`);
        if (params.descricao?.length) query.push(`descricao=${params.descricao}`);
        if (!query.length) {
            query.push("");
        }
        return await estoqueMovimentoService.listar(query);
    }
};
