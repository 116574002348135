import React, { useCallback, useEffect, useState } from "react";
import { DataTable } from "../../components/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { empresaService } from "../../service/empresaService";
import { ClienteAutoComplete } from "../../components/autocomplete/ClienteAutoComplete";
import { clienteService } from "../../service/clienteService";
import { Button } from "../../components/button";
import { useDialog } from "../../utils/dialogContext";
import { ConfirmDialogV2 } from "../../components/confirmdialog";
import { ContratoAutoComplete } from "../../components/autocomplete/ContratoAutoComplete";
import { contratoService } from "../../service/contratoService";

export const EditarEmpresaFiliais = ({
  empresa,
  filiais,
  setFiliais,
  setLoading,
  atualizarFiliais
}) => {
  const navigate = useNavigate();
  const { roles } = useAuth();
  const { showDialog } = useDialog();

  const [loadingTabela, setLoadingTabela] = useState(false);
  const [contrato, setContrato] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [empresasSelecionados, setEmpresasSelecionados] = useState([]);



  const handleEditar = (event) => {
    if (!roles.EMPC) return;

    toast(`Empresa ${event.data.nomeFantasia} foi selecionada.`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      progressStyle: {},
    });
    navigate(`/empresas/${event.data.id}`);
  };

  const handleExcluir = useCallback(
    async (e) => {
      setLoading(true)
      // Executa o mapeamento de empresas para buscar as informações completas e remover a matriz
      await Promise.all(
        empresasSelecionados
          .filter((filial) => filial.id)
          .map(async (filial) => {
            const completo = await empresaService.buscar(filial.id);
            return empresaService.salvar({
              ...completo,
              matriz: null,
              acessoPortal: false
            })
          })
      );

      // Filtra as filiais removendo as selecionadas
      const empresas = filiais.filter(
        (usuario) =>
          !empresasSelecionados.some(
            (usuarioSelecionado) =>
              (usuarioSelecionado._key &&
                usuario._key === usuarioSelecionado._key) ||
              (usuarioSelecionado.id && usuario.id === usuarioSelecionado.id)
          )
      );

      // Atualiza o estado das filiais e limpa as empresas selecionadas
      setFiliais(empresas);
      setEmpresasSelecionados([]);
      setLoading(false)
    },
    [empresasSelecionados, filiais, setFiliais, setLoading]
  );

  const excluir = useCallback(() => {
    showDialog(
      <ConfirmDialogV2
        message="Tem certeza de que deseja remover as filiais selecionadas?"
        onYes={handleExcluir}
      />
    );
  }, [handleExcluir, showDialog]);

  function removerNaoNumeros(texto) {
    return texto.replace(/\D/g, ""); // \D corresponde a qualquer caractere que não seja um dígito (número)
  }
  const handleClienteSelect = async (e) => {
    if (e.originalEvent._reactName === "onBlur") return;

    setLoading(true);

    const cnpj = removerNaoNumeros(e.value.cpfCnpj);
    
    let empresaJaExiste = await empresaService.buscarPorCnpj(cnpj);

    if (empresaJaExiste?.id) {
      setCliente((c) => null);
      
      let filial = {
        ...empresaJaExiste,
        matriz: empresa,
        tipoEmpresa: "FILIAL",
        acessoPortal: true,
        portalAtivadoPor: "MANUALMENTE",
        postBack: true,
      }

      return empresaService
        .salvar(filial)
        .then((value) => {
          setFiliais((filiais) => [
            value,
            ...filiais,
          ]);
        })
        .finally(() => setLoading(false))
    }

    clienteService.buscar(e.value.id).then((cliente) => {
      setCliente((c) => null);
      const novaEmpresa = {
        ...empresaService.criar(),
        cliente: cliente,
        razaoSocial: cliente.razaoSocial,
        nomeFantasia: cliente.nomeFantasia,
        cnpj: cliente.cpfCnpj,
        cep: cliente.vwCliente.cep,
        endereco: cliente.vwCliente.endereco,
        bairro: cliente.vwCliente.bairro,
        uf: cliente.vwCliente.uf,
        email: cliente.vwCliente.email,
        telefone: cliente.vwCliente.telefones[0],
        cidade: cliente.vwCliente.municipio,
        inscricaoEstadual: cliente.vwCliente.inscricaoEstadual,
        complemento: cliente.vwCliente.complemento,
        tipoEmpresa: "FILIAL",
        acessoPortal: true,
        matriz: empresa,
        _key: Math.random() * 1000,
        portalAtivadoPor: "MANUALMENTE",
        postBack: true,
      };

      return empresaService
          .salvar(novaEmpresa)
          .then((value) => {
            setFiliais((filiais) => [
              value,
              ...filiais,
            ]);
          })
          .finally(() => setLoading(false))
    });
  };
  const handleContratoSelect = async (e) => {
    if (e.originalEvent._reactName === "onBlur") return;

    setContrato(null); //resetar o contrato
    setLoading(true);

    //completando o contrato
    const contratoCompleto = await contratoService.buscar(e.value.id);
    
    //verificando se tem filiais
    if (!contratoCompleto?.clientes?.length) return setLoading(false); 

    // Usando Promise.all para aguardar todas as operações assíncronas
    const novasFiliais = await Promise.all(
      contratoCompleto.clientes
        .filter((cliente) => cliente?.vwCliente?.status === "ATIVO") //filtrando clientes ativos
        .map(async (cliente) => {
          const cnpj = cliente.cpfCnpj;

          //verificando se a empresa ja existe
          let empresaJaExiste = await empresaService.buscarPorCnpj(cnpj);

          if (empresaJaExiste?.id) {
            return {
              ...empresaJaExiste,
              matriz: empresa,
              tipoEmpresa: "FILIAL",
              acessoPortal: true,
              portalAtivadoPor: contratoCompleto.numero,
            };
          } else {

            return {
              ...empresaService.criar(),
              cliente: cliente,
              razaoSocial: cliente?.razaoSocial,
              nomeFantasia: cliente?.nomeFantasia,
              cnpj: cliente?.cpfCnpj,
              cep: cliente?.vwCliente.cep,
              endereco: cliente?.vwCliente.endereco,
              bairro: cliente?.vwCliente.bairro,
              uf: cliente?.vwCliente.uf,
              email: cliente?.vwCliente.email,
              telefone: cliente?.vwCliente.telefones[0],
              cidade: cliente?.vwCliente.municipio,
              inscricaoEstadual: cliente?.vwCliente.inscricaoEstadual,
              complemento: cliente?.vwCliente.complemento,
              tipoEmpresa: "FILIAL",
              acessoPortal: true,
              matriz: empresa,
              _key: Math.random() * 1000, // Pode ser substituído por um UUID
              cnpjFormatado: cliente?.cpfCnpjFormatado,
              portalAtivadoPor: contratoCompleto.numero,
            };
          }
        })
    );

    var clientesFiliaisCnpjs = contratoCompleto?.clientes
      .filter((cliente) => cliente?.vwCliente?.status !== "ATIVO")
      .map((item) => item.cpfCnpj
    );
    
    const filiaisRemover = await Promise.all(
      filiais
      .filter((item) => clientesFiliaisCnpjs.includes(item.cnpj))
      .map(async (filial) => {
          const completo = await empresaService.buscar(filial.id);
          return {
            ...completo,
            matriz: null,
            portalAtivadoPor: null,
          };
        })
    );
    
    const salvarFiliais = [...novasFiliais,...filiaisRemover]

    await Promise.all(salvarFiliais.map((async (filial) => {
      await empresaService.salvar(filial);
    })))

    atualizarFiliais()

    setLoading(false);
  };

  const templateStatus = (data) => {
    if (data.status === "ATIVO") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            title="Ativado"
            className="fa-solid fa-city fa-lg	"
            style={{ color: "#00940a" }}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i
            title="Desativado"
            className="fa-solid fa-city fa-lg	"
            style={{ color: "#d10404" }}
          />
        </div>
      );
    }
  };

  return (
    <div className="col-12">
      <div className="w-full flex align-itens-center p-0">
        <div className="grid grid-nogutter w-full">
          {/* <ClienteAutoComplete
            col={6}
            value={cliente}
            onChange={(e) => setCliente(e.value)}
            onSelect={handleClienteSelect}
            cnpjReptidos={filiais.map((item) => item.cnpj)}
          /> */}
          <ContratoAutoComplete
            col={12}
            value={contrato}
            onChange={(e) => setContrato(e.value)}
            cliente={empresa.cliente.id}
            onSelect={handleContratoSelect}
            dropdown
            ativos
            suporte
          />
        </div>
        <div className="align-self-center">
          <Button
            danger
            icon="fa-solid fa-trash"
            title="Remover filiais selecionadas"
            disabled={empresasSelecionados.length === 0}
            onClick={excluir}
          />
        </div>
      </div>
      <DataTable
        value={filiais}
        onRowDoubleClick={handleEditar}
        valuelength={filiais?.length}
        loading={loadingTabela}
        selectionMode="checkbox"
        selection={empresasSelecionados}
        onSelectionChange={(e) => setEmpresasSelecionados(e.value)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: "1em" }} />
        <Column
          style={{ width: "2em" }}
          sortable
          header="Status"
          body={(e) => templateStatus(e)}
        />
        <Column
          sortable
          field="razaoSocial"
          header="Razão Social"
          style={{ width: "*" }}
        />
        <Column
          sortable
          field="nomeFantasia"
          header="Nome Fantasia"
          style={{ width: "*" }}
        />
        <Column
          sortable
          field="cnpjFormatado"
          header="CNPJ"
          style={{ width: "*" }}
        />
        <Column
          sortable
          field="portalAtivadoPor"
          header="Contrato"
          style={{ width: "*" }}
        />
      </DataTable>
    </div>
  );
};
