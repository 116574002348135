import React, {useState} from "react";

import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {toast} from "react-toastify";
import moment from "moment";
import saveAs from "file-saver";

import { headerTemplate } from "../../utils/templates";
import { useBreakpoint } from "../../context/BreakpointContext";
import { ClienteAutoComplete } from "../../components/autocomplete/ClienteAutoComplete";
import { Calendar } from "../../components/calendar";
import { InputNumber } from "../../components/inputnumber";
import { EquipamentoAutoComplete } from "../../components/autocomplete/equipamentoAutoComplete";
import { UsuarioAutoComplete } from "../../components/autocomplete/usuarioAutoComplete";
import { useAuth } from "../../context/AuthContext";
import { RelatoriosDropdown } from "../../components/dropdown/relatoriosDropdown";
import { relatoriosServicoService } from "../../service/relatoriosServicoService";

export function Relatorios() {

    const {roles} = useAuth();
    const [loading, setLoading] = useState(false);    
    const [params, setParams] = useState({
        relatorio: 'ROS',
        descricao: "",
        cliente: null,
        numeroNF: null,
        numeroSerie: "",
        textoBuscaCliente: "",
        inicioOS: moment().subtract(1, 'months').format("YYYY-MM-DD"),
        fimOS: moment().format("YYYY-MM-DD"),
    });

    const {breakpoint} = useBreakpoint()
    const isLargeDevice = breakpoint === 'lg' || breakpoint === 'md'

    function handleChange(event) {
        setParams({...params, [event.name]: event.value});
    }

    async function handleBaixar(params) {
        setLoading(true);
        const query = [];
        if (params.cliente?.id) query.push(`cliente=${params.cliente.id}`);
        if (params.inicioOS) query.push(`inicioOS=${params.inicioOS}`);
        if (params.fimOS) query.push(`fimOS=${params.fimOS}`);
        if (params.equipamento?.id) query.push(`equipamento=${params.equipamento.id}`);
        if (params.numeroSerie) query.push(`numeroSerie=${params.numeroSerie}`);
        if (params.numeroNF) query.push(`numeroNF=${params.numeroNF}`);
        if (params.responsavel?.id) query.push(`responsavel=${params.responsavel.id}`);
        if (params.textoBuscaCliente) query.push(`textoBuscaCliente=${params.textoBuscaCliente}`);
        toast.promise(
            relatoriosServicoService.baixarRelatorio(params.relatorio , query).then((data) => {
                console.log("data_antes",data);
                saveAs(new Blob([data], {type: "application/csv"}), `${moment().format("YYYYDDMMHHmmss")}.csv`);           
            }).finally(() => { setLoading(false) }),
            {
                "pending": `Gerando relatório. Aguarde...`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao procurar ordens de serviço: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }
    
    return (
        <Panel headerTemplate={() => headerTemplate('Relatório de Ordens de Serviço', 'fa-file-lines')} >
            <PanelContent>
                <RelatoriosDropdown col={12}  value={params.relatorio} name="relatorio" onChange={handleChange}/>
                <ClienteAutoComplete forceSelection={false} name="cliente" col={6} value={params.cliente} onChange={handleChange}/>
                <InputText col={6} label="Pesquise por" placeholder='um CNPJ (somente nº), nome fantasia ou razão social' value={params.textoBuscaCliente} name="textoBuscaCliente" onChange={handleChange}/>
                <Calendar label="Período de Início" col={2} name="inicioOS" value={params.inicioOS} onChange={handleChange}/>
                <Calendar label="&nbsp;" col={2} name="fimOS" value={params.fimOS} onChange={handleChange}/>
                <InputText label="Número de Série" name="numeroSerie" col={2} value={params.numeroSerie} onChange={handleChange} />
                <InputNumber label="Número da NF" name="numeroNF" col={2} value={params.numeroNF} onChange={handleChange} />
                <EquipamentoAutoComplete col={4} name="equipamento" value={params.equipamento} onChange={handleChange}/>
                <UsuarioAutoComplete col={5} disabled={!roles.OSSL} value={params.responsavel} name="responsavel" onChange={handleChange} label="Responsável"/>
            </PanelContent>
            <PanelFooter>        
                <Button 
                    info 
                    disabled={loading} 
                    loading={loading} 
                    onClick={() => handleBaixar(params)} 
                    label={isLargeDevice ? "Baixar" : null}
                    icon="fa-solid fa-file-arrow-down"
                    autowidth={!isLargeDevice}
                />
            </PanelFooter>
        </Panel>
    );

}
