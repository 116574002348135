import React, {useCallback} from "react";

import {useNavigate} from "react-router-dom";
import {Column} from "primereact/column";

import {DataTable} from "../../components/datatable";
import {status} from "../../service/contratoService";
import moment from "moment";
import {formatCurrency} from "../../utils/numberFormatter";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";

export function TabelaContratos({value}) {

    const navigate = useNavigate();

    const handleEditar = useCallback((event) => {
        navigate(`/contratos/${event.data.id}`);
    }, [navigate]);

    const templateStatus = (e) => {
        const value = status.filter(status => status.value === e.status)[0];
        return(
            <div className="flex justify-content-center" title={value.label}>
                <i className={`fa-solid ${value.icon} fa-lg`} style={{color: value.color}}/>
            </div>
        )
    }
    
    const valorTotalGeral = () => {
        let total = 0;
        for(let contrato of value) {
            total += contrato.valor;
        }
        return formatCurrency(total);
    }

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total Geral:" colSpan={8} footerStyle={{textAlign: 'right'}}/>
                <Column footer={valorTotalGeral} footerStyle={{textAlign: 'right'}} />
            </Row>
        </ColumnGroup>
    );

    return (     
        <DataTable value={value} onRowDoubleClick={handleEditar} valuelength={value?.length} footerColumnGroup={footerGroup}>
            <Column sortable header="Status" field="status" body={templateStatus} alignHeader='center' style={{width: '7em'}}/>
            <Column sortable header="Número" field="numero" style={{textAlign: 'right', width: '5em'}}/>
            <Column sortable header="Tipo" field="tipo" style={{textAlign: 'center', width: '15em'}}/>
            <Column sortable header="Cliente" field="clienteNomeFantasia" body={useCallback(e => `${e.clienteCpfCnpj} - ${e.clienteNomeFantasia}`, [])}/>
            <Column sortable header="Periodicidade das Parcelas" field="periodicidadeParcela" style={{textAlign: 'center', width: '12em'}}/>
            <Column sortable header="Vendedor" field="autoCompleteVendedor" />
            <Column sortable header="Início" field="inicio" body={useCallback(e => `${moment(e.inicio).format('DD/MM/YYYY')}`, [])} style={{width: '7em'}}/>
            <Column sortable header="Fim" field="fim" body={useCallback(e => `${moment(e.fim).format('DD/MM/YYYY')}`, [])} style={{width: '7em'}}/>
            <Column sortable header="Valor" field="valor" style={{textAlign: "right", width: "10em"}} body={(b) =>formatCurrency(b.valor)}/>
        </DataTable>
    );

}
