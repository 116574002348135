import React, {useCallback, useState} from 'react';
import { CfopAutoComplete } from "../../../components/autocomplete/CfopAutoComplete";
import { ClienteAutoComplete } from "../../../components/autocomplete/ClienteAutoComplete";
import { CondicaoPagamentoAutoComplete } from "../../../components/autocomplete/CondicaoPagamentoAutoComplete";
import { TipoMovimentoAutoComplete } from "../../../components/autocomplete/TipoMovimentoAutoComplete";
import { TransportadoraAutoComplete } from "../../../components/autocomplete/TransportadoraAutoComplete";
import { VendedorAutoComplete } from "../../../components/autocomplete/VendedorAutoComplete";
import { Calendar } from "../../../components/calendar";
import { Dropdown } from "../../../components/dropdown";
import { InputCurrency } from '../../../components/inputnumber';
import { InputText } from "../../../components/inputtext";
import { clienteService } from '../../../service/clienteService';
import { formaPagamento, pagamentoFrete } from "../services/pedidoVendaService";
import { transportadoraService } from '../services/transportadoraService';
import { isEntityRequired, isRequired } from '../../../utils/fieldValidator';
import { BlockUI } from 'primereact/blockui';
import { PanelContent } from '../../../components/panel';
import { tipoMovimentoService } from '../services/tipoMovimentoService';
export function EditarDadosPrincipais({pedidoVenda, setPedidoVenda, messages, setMessages, disabled}) {

	const [blocked, setBlocked] = useState(false);
     
    const handleChange = useCallback((event) => {		
		setPedidoVenda({...pedidoVenda, [event.name]: event.value});
	}, [pedidoVenda, setPedidoVenda]);

	const handleSelectTipoMovimento = useCallback(async (event) => {
		if (event.originalEvent?.type === "blur") return;
		if (event.value.id) {
			setBlocked(true);
		  	try {
				const tipoMovimento = await tipoMovimentoService.buscar(event.value.id);
				pedidoVenda.tipoMovimento = tipoMovimento;
				if (tipoMovimento.vwTipoMovimento.emissao === "N") {
			  	pedidoVenda.condicaoPagamento = null;
			}
				setBlocked(false);
				setPedidoVenda({ ...pedidoVenda });
		  	} catch (error) {
				console.error("Erro ao buscar tipo de movimento:", error);
				setBlocked(false);
		  	}
		}
	  }, [pedidoVenda, setPedidoVenda, setBlocked]);

	const handleSelectCliente = useCallback(async (event) => {
		if (event.originalEvent?.type === "blur") return;
		if (event.value.id) {
			setBlocked(true);
			try {
				const cliente = await clienteService.buscar(event.value.id);
				const vendedor = cliente.vwCliente.vendedor;
				const condicaoPagamento = cliente.vwCliente.condicaoPagamento;
				setBlocked(false);
				setPedidoVenda((prevState) => ({
					...prevState,
					cliente,
					vendedor,
					condicaoPagamento,
				}));
			} catch (error) {
				console.error("Erro ao buscar cliente:", error);
				setBlocked(false);
			}
		}
	}, [setPedidoVenda, setBlocked]);

	const handleSelectTransportadora = useCallback(async (event) => {
		if (event.originalEvent?.type === "blur") return;
		if (event.value.id) {
			setBlocked(true);
		  	try {
				const transportadora = await transportadoraService.buscar(event.value.id);
				setBlocked(false);
				setPedidoVenda((prevState) => ({
			  	...prevState,
			  	transportadora,
				}));
		  	} catch (error) {
				console.error("Erro ao buscar transportadora:", error);
				setBlocked(false);
		  	}
		}
	  }, [setPedidoVenda, setBlocked]);
	

	const  validarItem = (name) =>  {        
		let erro;	
		
		switch (name) {
            case 'cliente':
                erro = isEntityRequired(pedidoVenda.cliente)
                break;
            case 'cfop':
                erro = isEntityRequired(pedidoVenda.cfop);
                break;
            case 'vendedor':
                erro = isEntityRequired(pedidoVenda.vendedor);
                break;
            case 'dataPedido':
                erro = isRequired(pedidoVenda.dataPedido);
                break;
            case 'dataEntrega':
                erro = isRequired(pedidoVenda.dataEntrega);
                break;
            case 'frete':
				if(pedidoVenda.status === 'ORCAMENTO') return;
				erro = isRequired(pedidoVenda.frete);
                break;
            case 'formaPagamento':
                erro = isRequired(pedidoVenda.formaPagamento);
                break;
            case 'tipoMovimento':
                erro = isEntityRequired(pedidoVenda.tipoMovimento);
                break;
			case 'condicaoPagamento':
				
				if (pedidoVenda.tipoMovimento?.vwTipoMovimento?.emissao !== 'N') {
					erro = isEntityRequired(pedidoVenda.condicaoPagamento?.id)
				}
				break;
	
            default:
                break;
        }
        
        setMessages((messages) => {
            return {...messages, [name]: erro}
        })
    }

    return (
		<BlockUI blocked={blocked}>
        	<PanelContent>
				<Calendar 
					col={2} 
					disabled 
					name="registro" 
					showTime showSeconds 
					label="Data de Inclusão" 
					value={pedidoVenda?.registro} 
					onChange={handleChange}
					
				/>            

				<div className="col-6"/>
				
				<Calendar  
					col={2} 
					disabled={disabled} 
					name="dataPedido" 
					label="Data do Pedido" 
					value={pedidoVenda?.dataPedido} 
					onChange={handleChange} 
					invalid={messages.dataPedido}
					onBlur={() => validarItem('dataPedido')}
					
				/>

				<Calendar 
					col={2} 
					disabled={disabled} 
					name="dataEntrega" 
					minDate={new Date()} 
					label="Data de Entrega" 
					value={pedidoVenda?.dataEntrega} 
					onChange={handleChange} 
					invalid={messages.dataEntrega}
					onBlur={() => validarItem('dataEntrega')}
					
				/>
				
				<ClienteAutoComplete 
					disabled={disabled} 
					col={4} 
					value={pedidoVenda?.cliente} 
					name="cliente" 
					onChange={handleChange} 
					onSelect={handleSelectCliente} 
					invalid={messages.cliente}
					onBlur={() => validarItem('cliente')}
					
				/>

				<InputText 
					readOnly 
					col={4}  
					label="Nome Fantasia" 
					value={pedidoVenda?.cliente?.nomeFantasia || ""} 
				/>

				<InputText 
					readOnly 
					col={2} 
					label="Status" 
					value={pedidoVenda?.cliente?.vwCliente?.status || ""} 
				/>
				
				<InputText 
					readOnly 
					col={2} 
					label="CPF/CNPJ" 
					value={pedidoVenda?.cliente?.vwCliente?.cpfCnpjFormatado || ""}
				/>
				
				<TipoMovimentoAutoComplete 
					disabled={disabled} 
					dropdown 
					col={4} 
					value={pedidoVenda?.tipoMovimento} 
					name="tipoMovimento" 
					onChange={handleChange} 
					onSelect={handleSelectTipoMovimento}
					invalid={messages.tipoMovimento}
					onBlur={() => validarItem('tipoMovimento')}
					
				/>

				<CfopAutoComplete 
					disabled={disabled} 
					col={4}
					dropdown 
					value={pedidoVenda?.cfop} 
					name="cfop" 
					onChange={handleChange} 
					invalid={messages.cfop}
					onBlur={() => validarItem('cfop')}
					
				/>

				<CondicaoPagamentoAutoComplete 
					disabled={disabled} 
					dropdown col={4} 
					value={pedidoVenda?.condicaoPagamento} 
					name="condicaoPagamento" 
					onChange={handleChange} 
					invalid={messages.condicaoPagamento}
					onBlur={() => validarItem('condicaoPagamento')}
					
				/>
				
				<VendedorAutoComplete 
					disabled={disabled} 
					dropdown col={12}
					value={pedidoVenda?.vendedor} 
					name="vendedor" 
					onChange={handleChange} 
					invalid={messages.vendedor}
					onBlur={() => validarItem('vendedor')}
					
				/>

				<TransportadoraAutoComplete 
					disabled={disabled} 
					col={4} 
					value={pedidoVenda?.transportadora} 
					name="transportadora" 
					onSelect={handleSelectTransportadora} 
					onChange={handleChange}
					
				/>
									
				<InputText 
					readOnly 
					col={4}  
					label="Nome Fantasia" 
					value={pedidoVenda?.transportadora?.nomeFantasia || ""} 
				/>
				
				<InputText 
					readOnly 
					col={2} 
					label="Status" 
					value={pedidoVenda?.transportadora?.vwTransportadora?.status || ""} 
				/>

				<InputText 
					readOnly 
					col={2} 
					label="CPF/CNPJ" 
					value={pedidoVenda?.transportadora?.vwTransportadora?.cpfCnpjFormatado || ""} 
				/>

				<Dropdown 
					disabled={disabled} 
					options={pagamentoFrete} 
					col={4}  
					name="frete" 
					label="Pagamento do Frete" 
					value={pedidoVenda?.frete} 
					onChange={handleChange} 
					invalid={messages.frete}
					onBlur={() => validarItem('frete')}
					
				/>		

				<InputCurrency 
					disabled={disabled} 
					col={2} 
					value={pedidoVenda?.valorFrete} 
					min={0} 
					label="Valor Frete" 
					name="valorFrete" 
					onChange={handleChange}
					
				/>
				<Dropdown 
					disabled={disabled} 
					options={formaPagamento} 
					col={4}  
					name="formaPagamento" 
					label="Forma de Pagamento" 
					value={pedidoVenda?.formaPagamento} 
					onChange={handleChange} 
					invalid={messages.formaPagamento}
					onBlur={() => validarItem('formaPagamento')}
					
				/>

				<InputText 
					multiline 
					style={{height: "13em"}} 
					col={12} 
					name="complemento" 
					label="Observações" 
					value={pedidoVenda?.complemento} 
					onChange={handleChange}
					disabled={disabled}
				/>
			</PanelContent>
		</BlockUI>
    );
}