import React, {useContext, useState} from "react";
import {PanelContent} from "../../../components/panel";
import {Dialog, DialogFooter} from "../../../components/dialog";
import {Button} from "../../../components/button";
import {ordemServicoService} from "../../../service/ordemServicoService";
import {ProgressDialog} from "../../../components/progressdialog/ProgressDialog";
import {DialogContext} from "../../../utils/dialogContext";

export function ConcluirLaudo({ordensServico, onModalClose}) {

    const {showDialog} = useContext(DialogContext);
    const [visible, setVisible] = useState(true);

    async function handleYes() {
        showDialog(
            <ProgressDialog onProgress={async setProgress => {
                let progress = 0;
                for (const ordemServico of ordensServico) {
                    await ordemServicoService.concluirLaudos(ordemServico);
                    setProgress(++progress / ordensServico.length);
                }
                handleClose();
                await onModalClose();
            }}/>
        );
    }

    function handleNo() {
        handleClose();
    }

    function handleClose() {
        setVisible(false);
    }

    return (
        <Dialog visible={visible} style={{width: "400px"}} onHide={handleClose} header="Confirmação">
            <PanelContent>
                <div className="col-12">
                    O laudo foi concluído com sucesso?
                </div>
            </PanelContent>
            <DialogFooter>
                <Button icon="fas fa-check" success label="Sim" onClick={handleYes}/>
                <Button icon="fas fa-times" danger label="Não" onClick={handleNo}/>
            </DialogFooter>
        </Dialog>
    );

}
