import React from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {If} from "../../components/conditional/If";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";

export const TabelaProduto = React.memo(({value}) => {

    const navigate = useNavigate();

    function handleEditar(event) {
        navigate(`/produtos/${event.data.id}`);
    }

    const templateStatus = (data) => {
        return (
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <i title={data.status === "ATIVO" ? "Ativo" : "Desativado"} className="fa-solid fa-box fa-lg" style={{color: (data.status === "ATIVO" ? "#00940a" : "#d10404")}}/>
                <If condition={data.equipamentoEspecial} children={<i style={{marginLeft: ".25em"}} className="fas fa-star" title="Equipamento Especial"/>}/>
            </div>
        );
    }

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column rowSpan={2} style={{width: "2em"}} header="Status"/>
                <Column rowSpan={2} header="Produto"/>
                <Column rowSpan={2} header="Descrição"/>
                <Column rowSpan={2} header="Part Number"/>
                <Column rowSpan={2} header="NCM"/>
                <Column colSpan={4} style={{textAlign: "center"}} header="Saldo Disponível"/>
            </Row>
            <Row>
                <Column style={{width: "10em", textAlign: "center"}} header="Almoxarifado"/>
                <Column style={{width: "10em", textAlign: "center"}} header="Laboratório"/>
                <Column style={{width: "10em", textAlign: "center"}} header="Técnicos"/>
                <Column style={{width: "10em", textAlign: "center"}} header="Recondicionadas"/>
            </Row>
        </ColumnGroup>
    );

    return (
        <DataTable headerColumnGroup={headerColumnGroup} value={value} onRowDoubleClick={handleEditar} valuelength={value?.length}>
            <Column sortable field="status" body={e => templateStatus(e)}/>
            <Column sortable field="produtoId"/>
            <Column sortable field="descricao"/>
            <Column sortable field="partNumber"/>
            <Column sortable field="ncm"/>
            <Column style={{textAlign: "right"}} field="saldoDisponivel" body={p => p.saldoDisponivel}/>
            <Column style={{textAlign: "right"}} field="saldoDisponivelLaboratorio" body={p => p.saldoDisponivelLaboratorio}/>
            <Column style={{textAlign: "right"}} field="saldoDisponivelTecnico" body={p => p.saldoDisponivelTecnico}/>
            <Column style={{textAlign: "right"}} field="saldoRecondicionadas" body={p => p.saldoRecondicionadas}/>
        </DataTable>
    );

});

export function printSaldo(produto, origem) {
    if (!produto) {
        return null;
    }
    return (
        <table>
            <tbody>
                <tr>
                    <td>Novas:</td>
                    <td style={{textAlign: "right"}}>{produto?.[`saldoDisponivel${origem}`]}</td>
                </tr>
                <tr>
                    <td>Recondicionadas:</td>
                    <td style={{textAlign: "right"}}>{produto?.saldoRecondicionadas}</td>
                </tr>
            </tbody>
        </table>
    );
}
