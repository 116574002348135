import {baseService} from "./baseService";
import {api} from "./api";

const resourceUrl = "/calendarios";

export const calendarioService = {

    ...baseService(resourceUrl),

    criar() {
        return {
            _key: Math.random(),
            descricao: "",
            feriados: []
        };
    },

    criarFeriado() {
        return {
            _key: Math.random(),
            nome: "",
            data: null,
            anual: false
        };
    },

    async padrao() {
        return await api.get("/calendarios/padrao").then(res => res.data);
    },

    async listarCalendarios(params) {
        const query = [];
        if (params.descricao?.length) query.push(`descricao=lk=${params.descricao}`);
        return await calendarioService.listar([`query=${query.join(";")}`]);
    }

};
