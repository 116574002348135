import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from '../../context/AuthContext';
import {equipamentoService} from '../../service/equipamentoService';
import {InputText} from "../../components/inputtext";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import { buildValidator, isEntityRequired, isRequired } from '../../utils/fieldValidator';
import { toast } from 'react-toastify';
import { withDialog } from '../../utils/dialogContext';
import { InformationDialog } from '../../components/dialog/InformationDialog';

export const EditarEquipamento = withDialog(({showDialog}) => {

    const {id} = useParams();
    const {roles} = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState(buildValidator());
    const [equipamento, setEquipamento] = useState(equipamentoService.criar());

    function handleChange(event) {
        setEquipamento({...equipamento, [event.name]: event.value});
    }

    useEffect(() => {
        if (id !== "new") {
            equipamentoService.buscar(id).then(setEquipamento);
        }
    }, [id]);

    function handleHistorico() {
        navigate(`/equipamentos/historico/${equipamento.id}`);
    }

    function handleSalvar() {
        setLoading(true);

        const messages = equipamentoService.validar(equipamento);
        if (!messages.isEmpty()) {
            setMessages(messages);
            showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;
        }

        setLoading(true);

        toast.promise(
            equipamentoService.salvar(equipamento).then(handleVoltar)
                .finally(() => setLoading(false)),
            {
                "pending": `Salvando equipamento. Aguarde...`,
                "success": `Equipamento salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar equipamento`;
                    }
                }
            }
        );
    }

    function handleVoltar() {
        navigate(-1);
    }

    return (
        <Panel>
            <PanelContent>
                <InputText 
                    col={12}
                    name="descricao" 
                    label="Descrição" 
                    value={equipamento.descricao} 
                    onChange={handleChange}
                />
                <InputText 
                    col={3} 
                    name="serial" 
                    label="Número de Série" 
                    value={equipamento.serial} 
                    onChange={handleChange}
                    onBlur={() => {
                        messages.serial = isRequired(equipamento.serial);
                        setMessages({...messages});
                    }} 
                    invalid={messages.serial} 
                />
                <InputText col={3} name="numeroAtivo" label="Número do Ativo" value={equipamento.numeroAtivo} onChange={handleChange}/>
                <InputText col={3} name="etiqueta" label="Etiqueta" value={equipamento.etiqueta} onChange={handleChange}/>
                <InputText col={3} name="partNumber" label="P Number" value={equipamento.partNumber} onChange={handleChange}/>
                <ClienteAutoComplete col={6} value={equipamento.cliente} name="cliente" onChange={handleChange}/>
                <ProdutoAutoComplete 
                    col={6} 
                    value={equipamento.produto}
                    name="produto" 
                    onChange={handleChange}
                    onBlur={() => {
                        messages.produto = isEntityRequired(equipamento.produto);
                        setMessages({...messages});
                    }} 
                    invalid={messages.produto} 
                />
                <InputText col={12} name="informacoes" label="Informações" value={equipamento.informacoes} onChange={handleChange}/>
                <InputText col={12} name="acessorios" label="Acessórios" value={equipamento.acessorios} onChange={handleChange}/>            
            </PanelContent>
            <PanelFooter>
                <div style={{float: "left"}}>
                    <Button disabled={loading} icon="fas fa-clock" onClick={handleHistorico}/>
                </div>
                {!!roles.EQPC ? <Button loading={loading === 0} disabled={loading === 0} icon="fas fa-save" label="Salvar" onClick={handleSalvar} success/> : null}
                <Button disabled={loading} icon="fas fa-arrow-left" label="Voltar" onClick={handleVoltar} secondary/>
            </PanelFooter>
        </Panel>
    );
});