import React from "react";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {formatCurrency} from "../../utils/numberFormatter";
import {sum} from "../../utils/mathUtils";
import {useDialog} from "../../utils/dialogContext";
import {DialogHintOutras} from "./DialogHintOutras";

export const TabelaResultado = React.memo(({value}) => {

    const {showDialog} = useDialog();

    function showHintOutras() {
        showDialog(<DialogHintOutras/>);
    }

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column sortable field="nome" rowSpan={2} header="Nome"/>
                <Column colSpan={2} style={{textAlign: "center"}} header="Total"/>
                <Column colSpan={2} style={{textAlign: "center"}} header={<>Outras <i className="fas fa-question-circle" style={{color: "#2196F3", cursor: "pointer"}} onClick={showHintOutras}/></>}/>
                <Column colSpan={2} style={{textAlign: "center"}} header="Sem Orçamento"/>
                <Column colSpan={2} style={{textAlign: "center"}} header="Gerado"/>
                <Column colSpan={2} style={{textAlign: "center"}} header="Enviado"/>
                <Column colSpan={2} style={{textAlign: "center"}} header="Aprovado"/>
                <Column colSpan={2} style={{textAlign: "center"}} header="Recusado (OS Cancelada)"/>
                <Column colSpan={2} style={{textAlign: "center"}} header="Recusado (OS Aberta)"/>
            </Row>
            <Row>
                <Column sortable field="total" style={{textAlign: "center"}} header="Qtde"/>
                <Column sortable field="totalValor" style={{textAlign: "center"}} header="Valor"/>
                <Column sortable field="semLaudo" style={{textAlign: "center"}} header="Qtde"/>
                <Column sortable field="semLaudoValor" style={{textAlign: "center"}} header="Valor"/>
                <Column sortable field="semOrcamento" style={{textAlign: "center"}} header="Qtde"/>
                <Column sortable field="semOrcamentoValor" style={{textAlign: "center"}} header="Valor"/>
                <Column sortable field="gerado" style={{textAlign: "center"}} header="Qtde"/>
                <Column sortable field="geradoValor" style={{textAlign: "center"}} header="Valor"/>
                <Column sortable field="enviado" style={{textAlign: "center"}} header="Qtde"/>
                <Column sortable field="enviadoValor" style={{textAlign: "center"}} header="Valor"/>
                <Column sortable field="aprovado" style={{textAlign: "center"}} header="Qtde"/>
                <Column sortable field="aprovadoValor" style={{textAlign: "center"}} header="Valor"/>
                <Column sortable field="recusadoCancelada" style={{textAlign: "center"}} header="Qtde"/>
                <Column sortable field="recusadoCanceladaValor" style={{textAlign: "center"}} header="Valor"/>
                <Column sortable field="recusado" style={{textAlign: "center"}} header="Qtde"/>
                <Column sortable field="recusadoValor" style={{textAlign: "center"}} header="Valor"/>
            </Row>
        </ColumnGroup>
    );

    const footerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column/>
                <Column footer={<div style={{textAlign: "right"}}>{value.map(bo => Object.keys(bo).filter(k => k !== "nome" && !k.endsWith("Valor")).map(k => bo[k]).reduce(...sum)).reduce((a, b) => a + b, 0)}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatCurrency(value.map(bo => Object.keys(bo).filter(k => k !== "nome" && k.endsWith("Valor")).map(k => bo[k]).reduce(...sum)).reduce((a, b) => a + b, 0))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{value.map(bo => bo.semLaudo).reduce((a, b) => a + b, 0)}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatCurrency(value.map(bo => bo.semLaudoValor).reduce((a, b) => a + b, 0))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{value.map(bo => bo.semOrcamento).reduce((a, b) => a + b, 0)}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatCurrency(value.map(bo => bo.semOrcamentoValor).reduce((a, b) => a + b, 0))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{value.map(bo => bo.gerado).reduce((a, b) => a + b, 0)}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatCurrency(value.map(bo => bo.geradoValor).reduce((a, b) => a + b, 0))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{value.map(bo => bo.enviado).reduce((a, b) => a + b, 0)}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatCurrency(value.map(bo => bo.enviadoValor).reduce((a, b) => a + b, 0))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{value.map(bo => bo.aprovado).reduce((a, b) => a + b, 0)}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatCurrency(value.map(bo => bo.aprovadoValor).reduce((a, b) => a + b, 0))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{value.map(bo => bo.recusadoCancelada).reduce((a, b) => a + b, 0)}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatCurrency(value.map(bo => bo.recusadoCanceladaValor).reduce((a, b) => a + b, 0))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{value.map(bo => bo.recusado).reduce((a, b) => a + b, 0)}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatCurrency(value.map(bo => bo.recusadoValor).reduce((a, b) => a + b, 0))}</div>}/>

            </Row>
        </ColumnGroup>
    );

    return (
        <DataTable paginator={false} footer={null} headerColumnGroup={headerColumnGroup} footerColumnGroup={footerColumnGroup} value={value} valuelength={value?.length}>
            <Column field="nome" body={bo => bo.nome || "Não identificado"}/>
            <Column style={{width: "6em", textAlign: "right"}} field="total" body={bo => Object.keys(bo).filter(k => k !== "nome" && !k.endsWith("Valor")).map(k => bo[k]).reduce(...sum)}/>
            <Column style={{width: "8em", textAlign: "right"}} field="totalValor" body={bo => formatCurrency(Object.keys(bo).filter(k => k !== "nome" && k.endsWith("Valor")).map(k => bo[k]).reduce(...sum))}/>
            <Column style={{width: "6em", textAlign: "right"}} field="semLaudo"/>
            <Column style={{width: "8em", textAlign: "right"}} field="semLaudoValor" body={bo => formatCurrency(bo.semLaudoValor)}/>
            <Column style={{width: "6em", textAlign: "right"}} field="semOrcamento"/>
            <Column style={{width: "8em", textAlign: "right"}} field="semOrcamentoValor" body={bo => formatCurrency(bo.semOrcamentoValor)}/>
            <Column style={{width: "6em", textAlign: "right"}} field="gerado"/>
            <Column style={{width: "8em", textAlign: "right"}} field="geradoValor" body={bo => formatCurrency(bo.geradoValor)}/>
            <Column style={{width: "6em", textAlign: "right"}} field="enviado"/>
            <Column style={{width: "8em", textAlign: "right"}} field="enviadoValor" body={bo => formatCurrency(bo.enviadoValor)}/>
            <Column style={{width: "6em", textAlign: "right"}} field="aprovado"/>
            <Column style={{width: "8em", textAlign: "right"}} field="aprovadoValor" body={bo => formatCurrency(bo.aprovadoValor)}/>
            <Column style={{width: "6em", textAlign: "right"}} field="recusadoCancelada"/>
            <Column style={{width: "8em", textAlign: "right"}} field="recusadoValor" body={bo => formatCurrency(bo.recusadoCanceladaValor)}/>
            <Column style={{width: "6em", textAlign: "right"}} field="recusado"/>
            <Column style={{width: "8em", textAlign: "right"}} field="recusadoValor" body={bo => formatCurrency(bo.recusadoValor)}/>
        </DataTable>
    );
});

export function printSaldo(produto, origem) {
    if (!produto) {
        return null;
    }
    return (
        <table>
            <tbody>
                <tr>
                    <td>Novas:</td>
                    <td style={{textAlign: "right"}}>{produto[`saldoDisponivel${origem}`]}</td>
                </tr>
                <tr>
                    <td>Recondicionadas:</td>
                    <td style={{textAlign: "right"}}>{produto.saldoRecondicionadas}</td>
                </tr>
            </tbody>
        </table>
    );
}
