import React, {useEffect, useMemo} from "react";
import {TabPanel, TabView} from "primereact/tabview";
import {TabelaOrdemServico} from "./TabelaOrdemServico";
import {usePagesAndTabs} from "../../utils/PageAndTabContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {TabelaTotais, tabHeader} from "./utils";
import {LaboratorioActionProvider} from "./OrdemServicoActionContext";

export function GuiasLaboratorio({value}) {

    const {pageAndTab, setTab} = usePagesAndTabs(QueryParamsKeys.Laboratorio);

    const ordensServico = useMemo(() => ({
        laudo: value.filter(os => os.status === "LAUDO"),
        manutencao: value.filter(os => os.status === "MANUTENCAO"),
        limpeza: value.filter(os => os.status === "LIMPEZA"),
        teste: value.filter(os => os.status === "TESTE"),
        fechada: value.filter(os => os.status === "FECHADA")
    }), [value]);

    useEffect(() => {
        if (!Object.keys(ordensServico).map(k => ordensServico[k].length)[pageAndTab.tab]) {
            setTab(0);
        }
    }, [ordensServico]);

    const tabs = [
        <TabPanel key="laudo" header={tabHeader("laudo", ordensServico)}>
            <TabelaOrdemServico status="LAUDO" value={ordensServico.laudo}/>
        </TabPanel>,
        <TabPanel key="manutencao" header={tabHeader("manutencao", ordensServico)}>
            <TabelaOrdemServico status="MANUTENCAO" value={ordensServico.manutencao}/>
        </TabPanel>,
        <TabPanel key="limpeza" header={tabHeader("limpeza", ordensServico)}>
            <TabelaOrdemServico status="LIMPEZA" value={ordensServico.limpeza}/>
        </TabPanel>,
        <TabPanel key="teste" header={tabHeader("teste", ordensServico)}>
            <TabelaOrdemServico status="TESTE" value={ordensServico.teste}/>
        </TabPanel>
    ];

    if (ordensServico.fechada?.length) {
        tabs.push(
            <TabPanel key="fechada" header={tabHeader("fechada", ordensServico)}>
                <TabelaOrdemServico status="FECHADA" value={ordensServico.fechada}/>
            </TabPanel>
        );
    }

    return (
        <LaboratorioActionProvider>
            <div className="tabview-escandaloso">
                <TabView activeIndex={pageAndTab.tab} onTabChange={e => setTab(e.index)} style={{marginTop: "1em"}}>
                    {tabs}
                </TabView>
                <TabelaTotais value={value}/>
            </div>
        </LaboratorioActionProvider>
    );

}
