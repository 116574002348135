import React, {useCallback, useState} from "react";

import moment from "moment";
import {toast} from "react-toastify";
import {Panel} from "primereact/panel";

import {useBreakpoint} from "../../../../context/BreakpointContext";
import { comissaoAcompanhamentoService, optionsComissaoAcompanhamento } from "../../services/comissaoAcompanhamentoService";
import {Button} from "../../../../components/button";
import {useAuth} from "../../../../context/AuthContext";
import {Calendar} from "../../../../components/calendar";
import {PanelContent, PanelFooter} from "../../../../components/panel";
import { InformationDialog } from "../../../../components/dialog/InformationDialog";
import { TipoComissaoDropdown } from "../../../../components/dropdown/tipoComissaoDropdown";
import { VendedorAutoComplete } from "../../../../components/autocomplete/VendedorAutoComplete";
import { withDialog } from "../../../../utils/dialogContext";
import {headerTemplate} from "../../../../utils/templates";
import {agrupar} from "./utils";
import { SelectButton } from "../../../../components/selectbutton";
import { restricoesButtons } from "../../../../service/ordemServicoService";
import { comissaoFechamentoService } from "../../services/comissaoFechamentoService";

export const FiltrosAcompanhamentoIndividual = withDialog(React.memo(({setValue, tiposComissao, showDialog, vendedor, setFechamento}) => {
    
	const {breakpoint} = useBreakpoint();
    const isLargeDevice = breakpoint === 'lg' || breakpoint === 'md';
    const [loading, setLoading] = useState(0);

    const [params, setParams] = useState({
		inicio: moment().startOf('month').format("YYYY-MM-DD"),
		fim: moment().endOf('month').format("YYYY-MM-DD"),
        tipoComissao:  'todos',
		status: null,		
		mes: moment().format("YYYY-MM"),
	});

    const handleChange = useCallback((event) => {
		setParams({...params, [event.name]: event.value});
	}, [params])
   
	const handleProcurar = useCallback(async () => {		
		const query = [];
		if(!params?.mes){
			return showDialog(<InformationDialog 
				header="Informação" 
				message="A data não pode ser nula. Por favor informe uma data."
			/>);
		}
		if(!vendedor?.id){
			return showDialog(<InformationDialog 
				header="Informação" 
				message="Para visualizar essas informações seu usuário deve estar vinculado a um consultor de vendas."
			/>);
		}
		const jaTemFechamento = await comissaoFechamentoService.buscarFechamento(moment(params.mes).startOf('month').format("YYYY-MM-DD"));

		

		query.push(`inicio=${moment(params.mes).startOf('month').format("YYYY-MM-DD")}`);
		query.push(`fim=${moment(params.mes).endOf('month').format("YYYY-MM-DD")}`);
		query.push(`vendedor=${vendedor?.id}`);

		if (params?.tipoComissao && params?.tipoComissao !== 'todos') query.push(`tipoComissao=${params.tipoComissao}`);
		if (params?.status) query.push(`status=${params.status}`);
		setLoading("Procurar");
		toast.promise(
			comissaoAcompanhamentoService.listar(query).then((value) => {
				if(jaTemFechamento.id){
					setFechamento(jaTemFechamento)
				}else{
					setFechamento(null);
				}
				setValue(agrupar(value));
			}).then(() => setLoading(0)),
			{
				"pending": `Procurando acompanhamento. Aguarde...`,
				"error": {
					render(e) {
						setLoading(0);
						return `Falha ao procurar acompanhamento: ${e.data?.response?.data}`;
					}
				}
			}
		);
	}, [params.mes, params.status, params.tipoComissao, setFechamento, setValue, showDialog, vendedor]);

    return (
        <Panel headerTemplate={() => headerTemplate('Acompanhamento de Comissão do Consultor de Vendas', 'fa-magnifying-glass-dollar')}>
            <PanelContent>
				<Calendar 
					label="Período de fechamento" 
					col={2} 
					name="mes" 
					value={params.mes} 
					onChange={handleChange}
					view="month"
					dateFormat="mm/yy"
					showButtonBar={false}
				/>

				<VendedorAutoComplete 
                    col={3} 
                    disabled={true} 
                    value={vendedor} 
                    name="vendedor" 
                    label="Consultor de Vendas"
                />				

				<TipoComissaoDropdown
					value={params?.tipoComissao}
					tiposComissao={[{descricao: 'TODOS', id: 'todos'} , ...tiposComissao]}
					label="Tipo de Comissão"
					name="tipoComissao"
					onChange={handleChange} 
					col={3}
				/>

				<SelectButton 
					pt={{button: ({context}) => ({className: context.selected ? params.status === "APROVADO" ? "sb-success" : "sb-unsuccess" : null})}}
					onChange={handleChange}
					itemTemplate={restricoesButtons}
					col={2}
					label="Status"
					name="status"
					value={params?.status}
					options={optionsComissaoAcompanhamento}
				/>


            </PanelContent>
            <PanelFooter>
                <Button 
                    info 
                    disabled={loading}
                    loading={loading === "Procurar"}
                    onClick={handleProcurar}
                    label={isLargeDevice ? "Procurar" : null}
                    icon="pi pi-search"
                    autowidth={!isLargeDevice}
                />
            </PanelFooter>
        </Panel>
    );

}));
