import React from "react";
import {ordemServicoItemPecaStatus} from "../../service/ordemServicoItemService";

export function CardSolicitacaoPeca({peca}) {

    let status;

    if (peca.status === "COMPRAS" && peca.produto.saldoDisponivel) {
        status = ordemServicoItemPecaStatus.DISPONIVEL;
    } else {
        status = ordemServicoItemPecaStatus[peca.status];
    }

    return (
        <div className={`card-peca ${peca.status.toLowerCase()}`}>
            {status}
            {peca.autoComplete}
        </div>
    );

}
