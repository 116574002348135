import React, {useState} from 'react';
import {AutoComplete} from '.';
import { listaPrecoService } from '../../pages/Comercial/services/listaPrecoService';


export function ListaPrecoAutoComplete(props) {

	const [listasPreco, setListasPreco] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("");

	function completeMethod(event) {
		const query = [
			"page=0",
			"size=20"
		];
		if (event.query.length) query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);
		listaPrecoService.autocomplete(query).then(res => {
			setListasPreco(res);
			if (!res[0]) {
				setEmptyMessage("Nenhum registro encontrado");
			} else {
				setEmptyMessage("");
			}
		});
	}

	return (
		<AutoComplete
			col={3}
			placeholder="descrição"
			label="Lista de Preço"
			name="listaPreco"
			completeMethod={completeMethod}
			suggestions={listasPreco}
			invalid={emptyMessage}
			icon="fa-solid fa-file-invoice-dollar"
			forceSelection
			{...props}
		/>
	);

}
