import React from "react";
import {SelectButton} from "./index";
import {Dropdown} from "../dropdown";

const optionStatusAtendimento = [
    {label: <><i className="fas fa-road"/> Deslocamento</>, value: "DESLOCAMENTO"},
    {label: <><i className="fas fa-briefcase"/> Atendimento</>, value: "ATENDIMENTO"},
    {label: <><i className="fas fa-asterisk"/> Todos</>, value: ""},
];

export function StatusAtendimentoSelectButton(props) {

    return <Dropdown label="Status Atendimento" options={optionStatusAtendimento} {...props}/>;

}
