import React, {useContext, useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {optionsSetor, optionsTipo, prateleiraService} from "../../service/prateleiraService";
import {useParams} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import {If} from "../../components/conditional/If";
import {DialogContext} from "../../utils/dialogContext";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {Dropdown} from "../../components/dropdown";

export function EditarPrateleira() {

	const {id} = useParams();
	const {roles} = useAuth();
	const navigate = useNavigate();
	const {showDialog} = useContext(DialogContext);
	const [loading, setLoading] = useState(0);
	const [prateleira, setPrateleira] = useState(prateleiraService.criar());

	useEffect(() => {
		if (id !== "new") {
			setLoading("Buscar");
			prateleiraService.buscar(id).then(prateleira => {
				setPrateleira(prateleira);
				setLoading(0);
			});
		}
	}, [id]);

	function handleChange(event) {
		setPrateleira(prevPrateleira => ({...prevPrateleira, [event.name]: event.value}));
	}

	function handleSalvar() {
		prateleiraService.salvar(prateleira).then(handleVoltar);
	}

	function handleVoltar() {
		navigate(-1);
	}

	function handleExcluir() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja excluir esta prateleira?" onYes={() => {
			prateleiraService.excluir(prateleira.id).then(handleVoltar);
		}}/>);
	}

	return (
		<BlockUI blocked={loading}>
			<Panel header="Prateleira">
				<PanelContent>
					<InputText col={5} label="Descrição" name="descricao" value={prateleira.descricao} onChange={handleChange}/>
					<InputText col={3} label="Código" name="codigo" value={prateleira.codigo} onChange={handleChange}/>
					<Dropdown col={2} label="Setor" value={prateleira.setor} name="setor" options={optionsSetor} onChange={handleChange}/>
					<Dropdown col={2} label="Tipo" value={prateleira.tipo} name="tipo" options={optionsTipo} onChange={handleChange}/>
					<InputText col={7} label="Observações" name="observacoes" value={prateleira.observacoes} onChange={handleChange}/>
					<InputText col={1} label="ID" name="codId" value={prateleira.codId} onChange={handleChange}/>
					<InputText col={1} label="Altura" name="codAltura" value={prateleira.codAltura} onChange={handleChange}/>
					<InputText col={3} label="Local" name="local" value={prateleira.local} onChange={handleChange}/>
				</PanelContent>
				<PanelFooter>
					<div style={{float: "left"}}>
						<If condition={roles.PRAD && prateleira.id} children={<Button label="Excluir" danger onClick={handleExcluir} icon="fas fa-trash"/>}/>
					</div>
					<If condition={roles.PRAC} children={<Button label="Salvar" success onClick={handleSalvar} icon="fas fa-save"/>}/>
					<Button label="Voltar" secondary onClick={handleVoltar} icon="fas fa-arrow-left"/>
				</PanelFooter>
			</Panel>
		</BlockUI>
	);

}
