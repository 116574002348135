import React from "react";
import { Dialog as PDialog } from "primereact/dialog";
import {Button} from "../button";

export function Dialog(props) {

    const renderFooter = () => {
        if (!props.footer) {
            return null;
        }
        switch (props.footer) {
            case 'especifico':
                return(
                    <div>
                        <Button label={props.acceptLabel} onClick={props.onYes}  loading={props.loading}/>
                    </div>
                )
            case "information":
                return (
                    <div>
                        <Button label={props.closeLabel ? props.closeLabel : "OK"} onClick={props.onHide}  loading={props.loading}/>
                    </div>
                );
            case "escolha":
                return (
                    <div>
                        <Button label={props.acceptLabel ? props.acceptLabel : "Sim"} icon={props.iconAccept} onClick={props.onYes} autoFocus success loading={props.loading}/>
                        <Button label={props.rejectLabel ? props.rejectLabel : "Não"} icon={props.iconReject} onClick={props.onNo}  loading={props.loading}/>
                    </div>
                )
            default:
                return (
                    <div>
                        <Button label={props.rejectLabel ? props.rejectLabel : "Não"} icon="pi pi-times" onClick={props.onHide} danger loading={props.loading}/>
                        <Button label={props.acceptLabel ? props.acceptLabel : "Sim"} icon="pi pi-check" onClick={props.onYes} autoFocus success loading={props.loading}/>
                    </div>
                );
        }
    }

    return (
        <PDialog {...props} footer={renderFooter} modal onHide={props.onHide}>
            {props.icon ? <i className={props.icon} style={{fontSize: "2rem", verticalAlign: "middle", marginRight: ".25em"}}/> : null}
            {props.children}
        </PDialog>
    );

}

export function DialogFooter(props) {
    return <div className="tzm-dialog-footer" {...props}/>;
}
