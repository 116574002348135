import { buildValidator, isRequired } from '../utils/fieldValidator';
import { baseService } from './baseService';

const resourceUrl = "/estoques";

export const estoqueService = {
    criar: () => {
        return {
            id: null,
            descricao: "",
            codigo: null,
            sistema: "OMEGA"
        };
    },
    validar: (estoque) => {
        const messages = buildValidator();
        messages.descricao = isRequired(estoque.descricao);
        return messages;
    },
    ...baseService(resourceUrl)
};

export const optionsSistema = [
    {label: "Omega", value: "OMEGA"},
    {label: "Services", value: "SERVICES"}
];
