import React, {useContext, useState} from "react";
import {Dialog, DialogFooter} from "../../../components/dialog";
import {PanelContent} from "../../../components/panel";
import {Button} from "../../../components/button";
import {DialogContext} from "../../../utils/dialogContext";
import {RetrocederEtapa} from "../../__Commons/AvancarEtapa";
import {ProgressDialog} from "../../../components/progressdialog/ProgressDialog";
import {ordemServicoService} from "../../../service/ordemServicoService";
import {Observacao} from "../../__Commons/Observacao";
import {InformationDialog} from "../../../components/dialog/InformationDialog";

export function ConcluirManutencaoEquipamentoEspecial({ordensServico, onModalClose}) {

    const {showDialog} = useContext(DialogContext);
    const [visible, setVisible] = useState(true);

    function handleClose() {
        setVisible(false);
    }

    function handleYes() {
        if (ordensServico.some(os => (!os.limpezaConcluida || !os.testesConcluidos) && (!os.itens?.[0]?.limpezaConcluida || !os.itens?.[0]?.testesConcluidos))) {
            showDialog(<InformationDialog header="Aviso" message="Para equipamentos especiais é preciso concluir a limpeza e teste antes de concluir a manutenção."/>);
            handleClose();
            return;
        }
        showDialog(<Observacao message="Adicione algum comentário e/ou clique em OK" onModalClose={observacoes => {
            showDialog(<ProgressDialog onProgress={async setProgress => {
                let progresso = 0;
                for (const ordemServico of ordensServico) {
                    await ordemServicoService.concluirManutencaoEquipamentoEspecial(ordemServico, "EXPEDICAO", observacoes);
                    setProgress(++progresso / ordensServico.length);
                }
                await onModalClose();
                handleClose();
            }}/>);
        }}/>);
    }

    function handleNo() {
        showDialog(<RetrocederEtapa ordensServico={ordensServico} status="MANUTENCAO" onModalClose={onModalClose}/>);
        handleClose();
    }

    return (
        <Dialog style={{width: "550px"}} header="Confirmação" visible={visible} onHide={handleClose}>
            <PanelContent>
                A manutenção foi concluída com sucesso?
            </PanelContent>
            <DialogFooter>
                <Button icon="fas fa-check" success label="Sim" onClick={handleYes}/>
                <Button icon="fas fa-times" danger label="Não" onClick={handleNo}/>
            </DialogFooter>
        </Dialog>
    );

}
