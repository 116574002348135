const moment = require('moment');
require('moment/locale/pt-br');

export const formatMonthYear =  (value) => {    
    moment.locale('pt-br');
    let mes = moment(value).format("MMMM  [-] YYYY");
    mes = mes.charAt(0).toUpperCase() + mes.slice(1);
    return mes;
} 

export const formatDateTime = (value) => {
    return new Date(value).toLocaleString();
}


