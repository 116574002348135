import moment from "moment";
import { EstoqueRecondicionado } from "../pages/BackOffice/Estoque/EstoqueRecondicionado";
import { Compras } from "../pages/Compras";

export const QueryParamsKeys = {
  Backoffice: "OrdemServico-Backoffice",
  Laboratorio: "OrdemServico-Laboratorio",
  Campo: "OrdemServico-Campo",
  CampoAgendamento: "OrdemServico-CampoAgendamento",
  Comercial: "OrdemServico-Comercial",
  PedidoVenda: "PedidoVenda",
  EstoqueCompra: "EstoqueCompra",
  BackofficeEstoque: "BackofficeEstoque",
  Prateleira: "Prateleira",
  Projeto: "Projeto",
  EstoqueRecondicionado: "EstoqueRecondicionado",
  AcordoNivelServico: "AcordoNivelServico",
  Calendario: "Calendario",
  Compras: "Compras",
  Fluxos: "Fluxos",
  Faturamento: "Faturamento",
  Contrato: "Contrato",
  Produto: "Produto",
  TipoClausula: "TipoClausula",
  Cliente: "Cliente",
  Usuario: "Usuario",
  Equipamento: "Equipamento",
  SeloIpem: "SeloIpem",
  MovimentoEstoque: "MovimentoEstoque",
  Empresa: "Empresa",
  MarcaProduto: "MarcaProduto",
  TipoProduto: "TipoProduto",
  Perfil: "Perfil",
  ListaPreco: "ListaPreco",
  ContaEmail: "ContaEmail",
  Suporte: "Suporte",
  QuestaoAvaliacao: "QuestaoAvaliacao"
};

export const defaultQueryParams = {
  [QueryParamsKeys.Backoffice]: {
    status: "ABERTAS",
    numero: null,
    modoNumero: "OS",
    equipamento: null,
    prateleira: "",
    modoData: "INICIO",
    formaDespacho: null,
    restricoes: [],
    pecas: null,
    responsavel: null,
    gerenteComercial: null,
    cliente: null,
    clienteStr: null,
    agruparFaturamento: false,
    equipamentoEspecial: null,
    responsaveis: [],
    cores: [],
    cancelada: "",
    statusContrato: "",
  },
  [QueryParamsKeys.Laboratorio]: {
    status: "ABERTAS",
    numero: null,
    modoNumero: "OS",
    equipamento: null,
    prateleira: "",
    modoData: "INICIO",
    formaDespacho: null,
    restricoes: [],
    pecas: null,
    responsavel: null,
    gerenteComercial: null,
    cliente: null,
    clienteStr: null,
    agruparFaturamento: false,
    equipamentoEspecial: null,
    responsaveis: [],
    cores: [],
    cancelada: "",
    statusContrato: "",
  },
  [QueryParamsKeys.Campo]: {
    status: "ABERTAS",
    numero: null,
    modoNumero: "OS",
    equipamento: null,
    prateleira: "",
    modoData: "INICIO",
    formaDespacho: null,
    restricoes: [],
    pecas: null,
    responsavel: null,
    gerenteComercial: null,
    cliente: null,
    fornecedor: null,
    clienteStr: null,
    agruparFaturamento: false,
    equipamentoEspecial: null,
    responsaveis: [],
    statusAgendamento: "",
    statusAtendimento: "",
    cores: [],
    cancelada: "",
    statusContrato: "",
  },
  [QueryParamsKeys.CampoAgendamento]: {
    status: "ABERTAS",
    numero: null,
    modoNumero: "OS",
    equipamento: null,
    prateleira: "",
    modoData: "PREVISAO",
    formaDespacho: null,
    restricoes: [],
    pecas: null,
    responsavel: null,
    gerenteComercial: null,
    cliente: null,
    fornecedor: null,
    clienteStr: null,
    agruparFaturamento: false,
    equipamentoEspecial: null,
    responsaveis: [],
    dataReferencia: moment()
      .startOf("week")
      .add(1, "days")
      .format("YYYY-MM-DD"),
    statusAtendimento: "",
  },
  [QueryParamsKeys.Comercial]: {
    status: [
      "LANCADA",
      "ABERTA",
      "SUSPENSA",
      "ESTOQUE",
      "ORCAMENTO",
      "COMPRAS",
      "LAUDO",
      "FATURAMENTO",
      "EXECUTADA",
      "MANUTENCAO",
      "LIMPEZA",
      "TESTE",
      "RECEPCAO",
      "EXPEDICAO",
            "ATENDIMENTO",
        ],
        tipo: null,
        numero: null,
        numeroOmega: null,
        cliente: null,
        responsavel: null,
        equipamento: null,
        fluxo: null,
        inicio: null,
        fim: null,
        prateleira: "",
        etapa: null,
        orcamentoStatus: null,
        gestor: "GESTOR",
        numeros: null,
        modoNumeros: "OS",
        modoData: "INICIO",
        pecas: null,
        page: 0,
        operacao: "TODOS",
        cores: [],
        cancelada: "",
        statusContrato: "",
  },
  [QueryParamsKeys.Suporte]: {
        status: "ABERTAS",
        tipo: null,
        numero: null,
        numeroOmega: null,
        cliente: null,
        responsavel: null,
        equipamento: null,
        fluxo: null,
        inicio: null,
        fim: null,
        prateleira: "",
        etapa: null,
        orcamentoStatus: null,
        gestor: "GESTOR",
        numeros: null,
        modoNumeros: "OS",
        modoData: "INICIO",
        pecas: null,
        page: 0,
        operacao: "TODOS",
        cores: [],
        cancelada: ""
    },
    [QueryParamsKeys.PedidoVenda]: {
    cliente: null,
    vendedor: null,
    produto: null,
    numero: null,
    inclusaoInicio: moment().subtract(1, "months").format("YYYY-MM-DD"),
    inclusaoFim: moment().format("YYYY-MM-DD"),
    entregaInicio: null,
    entregaFim: null,
    status: [],
    tipoOrcamento: [],
  },
  [QueryParamsKeys.EstoqueCompra]: {
    inicio: moment().add(-1, "months").format("YYYY-MM-DD"),
    fim: moment().format("YYYY-MM-DD"),
    responsavel: null,
    cliente: null,
    numero: null,
    statusPecas: "SOLICITADO",
  },
  [QueryParamsKeys.BackofficeEstoque]: {
    status: "SOLICITADO",
    numero: null,
    cliente: null,
    produto: null,
    disponiveis: false,
    operacao: "LABORATORIO",
  },
  [QueryParamsKeys.Prateleira]: {
    descricao: "",
  },
  [QueryParamsKeys.Projeto]: {
    numero: null,
    cliente: null,
    objeto: null,
  },
  [QueryParamsKeys.EstoqueRecondicionado]: {
    produto: "",
    tipo: "",
  },
  [QueryParamsKeys.AcordoNivelServico]: {
    descricao: "",
  },
  [QueryParamsKeys.Calendario]: {
    descricao: "",
  },
  [QueryParamsKeys.Compras]: {
    produto: null,
    ordemServico: null,
    quantidades: null,
    responsavel: null,
  },
  [QueryParamsKeys.Fluxos]: {
    descricao: "",
  },
  [QueryParamsKeys.Faturamento]: {
    numero: null,
    modoNumero: "FAT",
    cliente: null,
    vendedor: null,
    status: "ABERTO",
    operacao: "TODOS",
  },
  [QueryParamsKeys.Contrato]: {
    cliente: null,
    numero: null,
    status: ["PENDENTE", "ATIVO"],
    inicio: null,
    fim: null,
    vendedor: null,
    periodicidadeParcela: null,
    vencimento: "",
  },
  [QueryParamsKeys.Produto]: {
    descricao: "",
    equipamentoEspecial: "",
    visualizarDesativados: false,
  },
  [QueryParamsKeys.TipoClausula]: {
    textoDeBusca: [],
  },
  [QueryParamsKeys.Cliente]: {
    textoDeBusca: "",
    tipo: null,
    vendedor: "",
  },
  [QueryParamsKeys.Usuario]: {
    nome: "",
    email: "",
    perfil: "",
  },
  [QueryParamsKeys.Equipamento]: {
    descricao: "",
    serial: "",
  },
  [QueryParamsKeys.SeloIpem]: {
    inicio: "",
    fim: "",
    responsavel: null,
  },
  [QueryParamsKeys.MovimentoEstoque]: {
    numero: "",
    inicio: "",
    fim: "",
    estoque: null,
    produto: null,
    responsavel: null,
    notaFiscalNumero: "",
    notaFiscalSerie: "",
    descricao: "",
    },
    [QueryParamsKeys.Empresa]: {
        textoDeBusca: "",
        tipoEmpresa: "MATRIZ"
  },
  [QueryParamsKeys.MarcaProduto]: {
    descricao: "",
  },
  [QueryParamsKeys.TipoProduto]: {
    descricao: "",
  },
  [QueryParamsKeys.Perfil]: {
    descricao: "",
  },
  [QueryParamsKeys.ListaPreco]: {
    cliente: null,
    numero: null,
    status: [],
    descricao: "",
  },
  [QueryParamsKeys.ContaEmail]: {
    nome: "",
    email: "",
    login: "",
  },
  [QueryParamsKeys.QuestaoAvaliacao]: {
    questao: ""
  }
};
