import React, {useMemo} from 'react';
import {TabelaPedidoVenda} from './TabelaPedidoVenda';
import {BlockUI} from "primereact/blockui";
import {RefetchIndicator} from "../../../components/refetchindicator/RefetchIndicator";
import {useQueryClientPedidoVenda} from "../../../utils/query-clients/queryClients";
import {headerTemplate} from "../../../utils/templates";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {Button} from "../../../components/button";
import {If} from "../../../components/conditional/If";
import {useAuth} from "../../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {useBreakpoint} from "../../../context/BreakpointContext";
import {Panel} from "primereact/panel";
import {FiltrosPedidoVenda} from "./FiltrosPedidoVenda";
import {SearchButton} from "../../../components/button/SearchButton";

export function PedidoVenda() {

    const {roles} = useAuth();
    const navigate = useNavigate();
    const {isLargeDevice} = useBreakpoint();
    const {query, handleSearch} = useQueryClientPedidoVenda();

    function handleCriar() {
        navigate("/pedidos-venda/editar/orcamento/new");
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel headerTemplate={() => headerTemplate("Pedido de Venda", "fa-file-invoice")}>
                <PanelContent>
                    <FiltrosPedidoVenda/>
                </PanelContent>
                <PanelFooter>
                    <If condition={roles.PDVA}>
                        <Button success onClick={handleCriar} label={isLargeDevice ? "Novo" : null} icon="pi pi-plus" autowidth={!isLargeDevice}/>
                    </If>
                    <SearchButton query={query} onSearch={handleSearch}/>
                </PanelFooter>
            </Panel>
            <TabelaPedidoVenda value={useMemo(() => query.data || [], [query.data])}/>
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
