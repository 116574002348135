import React, {useEffect, useMemo, useState} from "react";

import {Panel} from "primereact/panel";
import moment from "moment";
import {headerTemplate} from "../../utils/templates";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {formatCurrency, formatDate, formatPercentage} from "../../utils/numberFormatter";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Calendar} from "../../components/calendar";
import {Button} from "../../components/button";
import {BlockUI} from "primereact/blockui";
import { relatorioOrdemServicoRestricaoService } from "../../service/RelatorioOrdemServicoRestricaoService";

function orderEtapas(a) {
    switch (a.etapaDescricao) {
        case "Recepção": return 0;
        case "Laudo": return 1;
        case "Manutenção": return 2;
        case "Limpeza": return 3;
        case "Teste": return 4;
        case "Expedição": return 5;
        default: return 6;
    }
}

function sortsEtapas(a, b) {
    return orderEtapas(a) - orderEtapas(b);
}

export function RelatorioOrdemServicoRestricao() {

    const [valorDia, setValorDia] = useState([])
    const [loading, setLoading] = useState(false);
    const [evolucao, setEvolucao] = useState([]);
    const [params, setParams] = useState({
        inicio: moment().subtract(1, "week").format("YYYY-MM-DD"),
        fim: moment().format("YYYY-MM-DD")
    });

    async function handleProcurar() {
        setLoading(true);
        setValorDia((await relatorioOrdemServicoRestricaoService.listar()));
        setEvolucao((await relatorioOrdemServicoRestricaoService.listarRelatorioEvolucao(params)));
        setLoading(false);
        
    }

    useEffect(handleProcurar, []);

    function handleChange(event) {
        setParams(prevParams => ({...prevParams, [event.name]: event.value}));
    }

    return (
        <BlockUI blocked={loading}>
            <Panel headerTemplate={() => headerTemplate('Ordem de Serviço (Laboratório) - Restrições', 'fa-file-lines')} >
                <PanelContent>
                    <div className="col-12">
                        <DataTable paginator={false} value={valorDia}>
                            <Column style={{textAlign: "center"}} header="Sem Restrição" field="semRestricao"/>
                            <Column style={{textAlign: "center"}} header="Laudo" field="laudo"/>
                            <Column style={{textAlign: "center"}} header="Recuperação" field="ressolda"/>
                            <Column style={{textAlign: "center"}} header="Preço" field="preco"/>
                            <Column style={{textAlign: "center"}} header="Estoque" field="estoque"/>
                            <Column style={{textAlign: "center"}} header="Compras" field="compras"/>
                            <Column style={{textAlign: "center"}} header="Sem Orçamento" field="orcamentoAusente"/>
                            <Column style={{textAlign: "center"}} header="Orçamento" field="orcamento"/>
                            <Column style={{textAlign: "center"}} header="Orç. Aprovado" field="orcamentoAprovado"/>
                            <Column style={{textAlign: "center"}} header="Orç. Reprovado" field="orcamentoReprovado"/>
                            <Column style={{textAlign: "center"}} header="Faturamento" field="faturamento"/>
                            <Column style={{textAlign: "center"}} header="NF Transporte" field="transporte"/>
                            <Column style={{textAlign: "center"}} header="Pendência" field="pendencia"/>                            
                        </DataTable>
                    </div>
                </PanelContent>
            </Panel>

            <Panel headerTemplate={() => headerTemplate('Ordem de Serviço (Laboratório) - Evolução', 'fa-file-lines')} >
                <PanelContent>
                    <Calendar label="Período" name="inicio" value={params.inicio} onChange={handleChange} col={2}/>
                    <Calendar label="&nbsp;" name="fim" value={params.fim} onChange={handleChange} col={2}/>
                </PanelContent>
                <PanelFooter>
                    <Button icon="fas fa-refresh" label="Atualizar" onClick={handleProcurar}/>
                </PanelFooter>
                <PanelContent>
                    <div className="col-12">
                        <DataTable paginator={false} value={evolucao}>
                            <Column style={{textAlign: "center"}} header="Data" field="data" body={r => formatDate(r.data)}/>
                            <Column style={{textAlign: "center"}} header="Sem Restrição" field="semRestricao"/>
                            <Column style={{textAlign: "center"}} header="Laudo" field="laudo"/>
                            <Column style={{textAlign: "center"}} header="Recuperação" field="ressolda"/>
                            <Column style={{textAlign: "center"}} header="Preço" field="preco"/>
                            <Column style={{textAlign: "center"}} header="Estoque" field="estoque"/>
                            <Column style={{textAlign: "center"}} header="Compras" field="compras"/>
                            <Column style={{textAlign: "center"}} header="Sem Orçamento" field="orcamentoAusente"/>
                            <Column style={{textAlign: "center"}} header="Orçamento" field="orcamento"/>
                            <Column style={{textAlign: "center"}} header="Orç. Aprovado" field="orcamentoAprovado"/>
                            <Column style={{textAlign: "center"}} header="Orç. Reprovado" field="orcamentoReprovado"/>
                            <Column style={{textAlign: "center"}} header="Faturamento" field="faturamento"/>
                            <Column style={{textAlign: "center"}} header="NF Transporte" field="transporte"/>
                            <Column style={{textAlign: "center"}} header="Pendência" field="pendencia"/>                            
                        </DataTable>
                    </div>
                </PanelContent>
            </Panel>
        </BlockUI>
    );

}
