import React, {useEffect, useState} from 'react';
import {FormEdit} from "../../components/form/Edit";
import {useParams} from "react-router-dom";
import {InputText} from "../../components/inputtext";
import {buildValidator} from "../../utils/fieldValidator";
import {useAuth} from '../../context/AuthContext';
import {estoqueService, optionsSistema} from '../../service/estoqueService';
import {Dropdown} from "../../components/dropdown";

export function EditarEstoques() {

	const {id} = useParams();
	const {roles} = useAuth();
	const [estoque, setEstoque] = useState(estoqueService.criar());
	const [messages, setMessages] = useState(buildValidator());

	function handleChange(event) {
		setEstoque(prevEstoque => ({...prevEstoque, [event.name]: event.value}));
	}

	useEffect(() => {
		if (id !== "new") {
			estoqueService.buscar(id).then(setEstoque);
		}
	}, [id]);

	return (
		<FormEdit podeDeletar={!!roles.ESTD} podeSalvar={!!roles.ESTC} descricao="Estoque" onValidate={setMessages} service={estoqueService} value={estoque} blocked={id !== "new" && !estoque.id}>
			<InputText required invalid={messages.codigo} col={2} label="Código" name="codigo" value={estoque.codigo} onChange={handleChange}/>
			<InputText required invalid={messages.descricao} col={6} label="Descrição" name="descricao" value={estoque.descricao} onChange={handleChange}/>
			<Dropdown col={4} options={optionsSistema} name="sistema" value={estoque.sistema} onChange={handleChange} label="Sistema"/>
		</FormEdit>
	);

}
