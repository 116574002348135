import React from "react";
import {If} from "../conditional/If";

export function FauxInput(props) {

	return (
		<div className={`xm faux-input-container col-${props.col}`}>
			<If condition={props.label?.length} children={<label>{props.label}</label>}/>
			<div className="faux-input">{props.value}</div>
		</div>
	);

}
