import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {estoqueMovimentoService, labelStatus} from "../../service/estoqueMovimentoService";
import {Button} from "../../components/button";
import {BlockUI} from "primereact/blockui";
import {withDialog} from "../../utils/dialogContext";
import {ConfirmDialogV2} from "../../components/confirmdialog";

export function TabelaEstoqueMovimento({value}) {

    const navigate = useNavigate();

    function handleEditar(event) {
        navigate(`/estoques/movimentos/${event.data.id}`);
    }

    const estoqueMovimentoHeaderGroup = (
        <ColumnGroup>
            <Row>
                <Column sortable style={{width: "12em"}} header="Número"/>
                <Column sortable header="Produto"/>
                <Column sortable header="Estoque"/>
                <Column sortable style={{width: "10em"}} header="Quantidade"/>
                <Column sortable header="Identificação de Movimentação"/>
            </Row>
        </ColumnGroup>
    );

    return (
        <DataTable selectionMode="checkbox" headerColumnGroup={estoqueMovimentoHeaderGroup} value={value} onRowDoubleClick={handleEditar} valuelength={value?.length}>
            <Column header="Número" field="numero"/>
            <Column header="Produto" body={em => em.produto?.autoComplete}/>
            <Column header="Estoque" body={em => em.estoque?.autoComplete}/>
            <Column header="Quantidade" field="quantidade"/>
            <Column header="Identificação de Movimentação" field="descricao"/>
        </DataTable>
    );

}
