import React, {useContext, useState} from "react";
import {Panel} from "primereact/panel";
import {WeekSchedule} from "../../../components/week-schedule/WeekSchedule";
import {filtrosNumeros, optionsTiposFiltered} from "../../../service/ordemServicoService";
import {InputText} from "../../../components/inputtext";
import {InputNumber} from "../../../components/inputnumber";
import {Dropdown} from "../../../components/dropdown";
import {Calendar} from "../../../components/calendar";
import {ClienteAutoComplete} from "../../../components/autocomplete/ClienteAutoComplete";
import {EquipamentoAutoComplete} from "../../../components/autocomplete/equipamentoAutoComplete";
import {VendedorAutoComplete} from "../../../components/autocomplete/VendedorAutoComplete";
import {UsuarioAutoComplete} from "../../../components/autocomplete/usuarioAutoComplete";
import {StatusAtendimentoSelectButton} from "../../../components/selectbutton/statusAtendimentoSelectButton";
import {useAuth} from "../../../context/AuthContext";
import {PanelContent, PanelFooter} from "../../../components/panel";
import moment from "moment";
import {Button} from "../../../components/button";
import {BlockUI} from "primereact/blockui";
import {useNavigate} from "react-router-dom";
import {DialogContext} from "../../../utils/dialogContext";
import {Deslocamentos} from "./Deslocamentos";
import {TemposParados} from "./TemposParados";
import {Pendencias} from "./Pendencias";
import {useQueryParams} from "../../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../../utils/defaultQueryParams";
import {useQueryClientCampoAgendamento} from "../../../utils/query-clients/queryClients";
import {SearchButton} from "../../../components/button/SearchButton";

export function CampoAgendamento() {

    const {roles} = useAuth();
    const navigate = useNavigate();
    const {showDialog} = useContext(DialogContext);
    const [selection, setSelection] = useState([]);
    const {query, handleSearch} = useQueryClientCampoAgendamento();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.CampoAgendamento);

    const optionsTipos = optionsTiposFiltered(roles);

    function handleEditar(ordemServico) {
        navigate(`/campo/${ordemServico.id}`);
    }

    function handleDeslocamento() {
        showDialog(<Deslocamentos ordemServico={selection[0]} onModalClose={handleSearch}/>);
    }

    function handleTempoParado() {
        showDialog(<TemposParados ordemServico={selection[0]} onModalClose={handleSearch}/>);
    }

    function handlePendencia() {
        showDialog(<Pendencias ordemServico={selection[0]} onModalClose={handleSearch}/>);
    }

    function handleChangeLocal(event) {
        if (!event.value) {
            event.value = moment().format("YYYY-MM-DD");
        }
        event.value = moment(event.value).startOf("week").add(1, "days").toDate();
        handleChange(event);
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel header="Agenda de Ordens de Serviço de Campo">
                <PanelContent>
                    <Calendar col={2} value={params.dataReferencia} name="dataReferencia" onChange={handleChangeLocal} label="Semana"/>
                    {
                        params.modoNumeros === 'CHAMADO'
                            ? <InputText label="Número" name="numeros" col={2} value={params.numeros} onChange={handleChange}/>
                            : <InputNumber label="Número" name="numeros" col={2} value={params.numeros} onChange={handleChange} />
                    }
                    <Dropdown col={2} label="&nbsp;" name="modoNumero" options={filtrosNumeros} onChange={handleChange} value={params.modoNumero}/>
                    <Dropdown disabled={optionsTipos.length === 1} label="Tipo da OS" options={optionsTipos} name="tipo" col={2} value={params.tipo} onChange={handleChange}/>
                    <ClienteAutoComplete forceSelection={false} name="cliente" col={4} value={params.cliente} onChange={handleChange}/>
                    <EquipamentoAutoComplete col={4} name="equipamento" value={params.equipamento} onChange={handleChange}/>
                    <VendedorAutoComplete label="Consultor de Vendas" col={4} name="gerenteComercial" value={params.gerenteComercial} onChange={handleChange}/>
                    <StatusAtendimentoSelectButton col={4} name="statusAtendimento" value={params.statusAtendimento} onChange={handleChange}/>
                    <UsuarioAutoComplete disabled={!roles.OSSL} multiple col={8} value={params.responsaveis} name="responsaveis" onChange={handleChange} label="Responsáveis"/>
                </PanelContent>
                <PanelFooter>
                    <SearchButton query={query} onSearch={handleSearch}/>
                </PanelFooter>
            </Panel>
            <Panel style={{marginTop: "1em"}}>
                <Button disabled={!selection?.length} label="Deslocamento" icon="fas fa-road" onClick={handleDeslocamento}/>
                <Button disabled={!selection?.length} warning label="Tempo em Espera" icon="fas fa-hourglass" onClick={handleTempoParado}/>
                <Button disabled={!selection?.length} label="Pendência" danger icon="fas fa-car-crash" onClick={handlePendencia}/>
            </Panel>
            <WeekSchedule
                single
                selection={selection}
                onSelectionChange={setSelection}
                start={params.dataReferencia}
                ordensServico={query.data}
                onDoubleClick={handleEditar}
                gestor={roles.OSSL}
            />
        </BlockUI>
    );

}
