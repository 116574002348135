import React from "react";

import {useNavigate} from "react-router-dom";
import {Column} from "primereact/column";

import {DataTable} from "../../components/datatable";

export const TabelaContaEmail = React.memo(({value}) => {

    const navigate = useNavigate();

    const handleEditar = (event) => {
        navigate(`/contas-email/${event.data.id}`);
    }

    return (
        <DataTable value={value} onRowDoubleClick={handleEditar} valuelength={value?.length}>
            <Column sortable header="Descrição" field="descricao"/>
            <Column sortable header="Usuário" field="usuario"/>
            <Column sortable header="Servidor" field="host"/>
        </DataTable>
    );

});