import React, {useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import moment from "moment";
import {headerTemplate} from "../../utils/templates";
import {TabelaResultado} from "./TabelaResultado";
import {backlogOrcamentoService} from "../../service/backlogOrcamentoService";
import {toast} from "react-toastify";
import {saveAs} from "file-saver";
import {Dropdown} from "../../components/dropdown";
import {Calendar} from "../../components/calendar";
import {Button as PButton} from "primereact/button";

const optionsStatus = [
    {label: <><i className="fas fa-asterisk"/> Todas</>, value: ""},
    {label: <><i className="fas fa-folder-open"/> Abertas</>, value: "ABERTAS"},
    {label: <><i className="fas fa-folder-closed"/> Fechadas</>, value: "FECHADAS"}
];

const optionsOperacoes = [
    {label: <><i className="fas fa-asterisk"/> Todas</>, value: ""},
    {label: <><i className="fas fa-building-flag" style={{color: "rgb(76, 175, 80)"}}/> Campo</>, value: "CAMPO"},
    {label: <><i className="fas fa-house-flag" style={{color: "rgb(121, 85, 72)"}}/> Laboratório</>, value: "LABORATORIO"}
];

export function BacklogOrcamento() {

    const [params, setParams] = useState({
        relatorio: "RORC",
        status: "ABERTAS",
        operacao: "LABORATORIO",
        inicio: moment().subtract(1, 'months').format("YYYY-MM-DD"),
        fim: moment().format("YYYY-MM-DD"),
    });

    const [loading, setLoading] = useState(false);
    const [geral, setGeral] = useState([{}]);
    const [individuais, setIndividuais] = useState([]);

    function handleChange(event) {
        setParams({...params, [event.name]: event.value});
    }

    useEffect(handleProcurar, []);

    async function handleProcurar() {
        setLoading(true);
        setGeral(await backlogOrcamentoService.listarBacklogGeral(params));
        setIndividuais(await backlogOrcamentoService.listarBacklog(params));
        setLoading(false);
    }
    
    async function handleBaixar() {
        setLoading(true);
        toast.promise(
            backlogOrcamentoService.baixarRelatorio(params).then((data) => {
                saveAs(new Blob([data], {type: "application/pdf"}), `BacklogOrcamento.pdf`);
            }).finally(() => { setLoading(false) }),
            {
                "pending": `Gerando relatório. Aguarde...`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao Backlog de Orçamento`;
                    }
                }
            }
        );
    }

    return (
        <div>
            <Panel headerTemplate={() => headerTemplate("Backlog de Orçamentos", "fa-file-lines")} >
                {/*<TabelaGeral params={params} loading={loading} value={geral} onChange={handleChange} onProcurar={handleProcurar}/>*/}
                <PanelContent>
                    <Dropdown label="Status" col={2} name="status" value={params.status} options={optionsStatus} onChange={handleChange}/>
                    <Dropdown label="Operação" col={2} name="operacao" value={params.operacao} options={optionsOperacoes} onChange={handleChange}/>
                    <Calendar label="Período" col={2} name="inicio" value={params.inicio} onChange={handleChange}/>
                    <Calendar label="&nbsp;" col={2} name="fim" value={params.fim} onChange={handleChange}/>
                </PanelContent>
                <PanelFooter>
                    <PButton label="Procurar" disabled={loading} loading={loading} onClick={handleProcurar} icon="pi pi-search"/>
                    <Button info disabled={loading} loading={loading} onClick={() => handleBaixar(params)} label="Baixar" icon="fa-solid fa-file-pdf"/>
                </PanelFooter>
            </Panel>
            <Panel>
            <TabelaResultado value={individuais}/>
                {/*<PanelFooter>*/}
                {/*    <Button info disabled={loading} loading={loading} onClick={() => handleBaixar(params)} label="Baixar" icon="fa-solid fa-file-pdf"/>*/}
                {/*</PanelFooter>*/}
            </Panel>
        </div>
    );

}
