import React, {useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import {useBreakpoint} from "../../context/BreakpointContext";
import {InputText} from "../../components/inputtext";
import {BlockUI} from "primereact/blockui";
import {Checkbox} from "../../components/checkbox";

export function EditarEvento({evento, setEvento, onSalvar, onVoltar, loading}) {

	const {breakpoint} = useBreakpoint();
	const [blocked, setBlocked] = useState(false);
	const isLargeDevice = breakpoint === "lg" || breakpoint === "md";

	function handleChange(event) {
		setEvento({...evento, [event.name]: event.value});
	}

	async function handleSalvar() {
		setBlocked(true);
		if (onSalvar) {
			await onSalvar(evento);
		}
		setBlocked(false);
	}

	return (
		<BlockUI blocked={blocked}>
			<Panel header="Registro de Evento">
				<PanelContent>
					<InputText multiline style={{height: "13em"}} col={12} name="observacoes" label="Detalhamento do Evento" value={evento.observacoes} onChange={handleChange}/>
					<Checkbox label="Notificar cliente" value={evento.notificarCliente} onChange={handleChange} name="notificarCliente"/>
				</PanelContent>
				<PanelFooter>
					<Button icon="fa-solid fa-floppy-disk" disabled={loading} loading={loading} success label={isLargeDevice ? "Salvar" : null} onClick={handleSalvar} autowidth={!isLargeDevice}/>
					<Button icon="fa-solid fa-arrow-left" secondary disabled={loading} label={isLargeDevice ? "Voltar" : null} onClick={onVoltar} autowidth={!isLargeDevice}/>
				</PanelFooter>
			</Panel>
		</BlockUI>
	);

}
