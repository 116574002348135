import React, {useEffect} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {BlockUI} from "primereact/blockui";
import {useAuth} from "../../../context/AuthContext";
import {If} from "../../../components/conditional/If";
import {RefetchIndicator} from "../../../components/refetchindicator/RefetchIndicator";
import {FiltrosOrdemServicoLaboratorio} from "../../__Commons/FiltrosOrdemServicoLaboratorio";
import {useQueryClientLaboratorio} from "../../../utils/query-clients/queryClients";
import {SearchButton} from "../../../components/button/SearchButton";
import {usePagesAndTabs} from "../../../utils/PageAndTabContext";
import {QueryParamsKeys} from "../../../utils/defaultQueryParams";
import {GuiasLaboratorio} from "../../__Commons-OrdemServico/GuiasLaboratorio";

export function LaboratorioLaudo() {

	const {roles} = useAuth();
	const {query, handleSearch} = useQueryClientLaboratorio();
	const {pageAndTab, setScroll} = usePagesAndTabs(QueryParamsKeys.Laboratorio);

	useEffect(() => {
		if (pageAndTab.scroll) {
			setTimeout(() => {
				window.scrollTo(0, pageAndTab.scroll);
				setScroll(0);
			}, 50);
		}
	}, []);

	return (
		<If condition={roles.LABA}>
			<BlockUI blocked={query.isLoading}>
				<Panel header="Laboratório - Laudo/Manutenção/Limpeza/Teste">
					<PanelContent>
						<FiltrosOrdemServicoLaboratorio/>
					</PanelContent>
					<PanelFooter>
						<SearchButton query={query} onSearch={handleSearch}/>
					</PanelFooter>
				</Panel>
				<GuiasLaboratorio value={query.data || []}/>
				<RefetchIndicator isRefetching={query.isRefetching}/>
			</BlockUI>
		</If>
	);

}
