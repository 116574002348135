import React, {useMemo, useState} from "react";
import {Panel} from "primereact/panel";
import {BlockUI} from "primereact/blockui";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import {InputText} from "../../components/inputtext";
import {calendarioService} from "../../service/calendarioService";
import {useNavigate} from "react-router-dom";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {useQueryClientCalendario} from "../../utils/query-clients/queryClients";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {SearchButton} from "../../components/button/SearchButton";

export function Calendarios() {

    const navigate = useNavigate();
    const {query, handleSearch} = useQueryClientCalendario();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.Calendario);

    function handleNew() {
        navigate("/sla/calendarios/new");
    }

    function handleEdit(event) {
        navigate(`/sla/calendarios/${event.data.id}`);
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel header="Calendários">
                <PanelContent>
                    <InputText col={12} name="descricao" value={params.descricao} onChange={handleChange} label="Descrição"/>
                </PanelContent>
                <PanelFooter>
                    <Button icon="fas fa-plus" success label="Novo" onClick={handleNew}/>
                    <SearchButton query={query} onSearch={handleSearch}/>
                </PanelFooter>
            </Panel>
            <DataTable onRowDoubleClick={handleEdit} value={useMemo(() => query.data || [], [query.data])}>
                <Column header="Descrição" field="descricao"/>
            </DataTable>
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
