import {Dropdown} from "./index";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../context/AuthContext";

export function OrdemServicoTipoDropdown(props) {

	const {roles} = useAuth();
	const [tipos, setTipos] = useState([]);

	useEffect(() => {
		const tipos = [];
		if (!!roles.OSSJ) tipos.push({label: "Preventiva", value: "PREVENTIVA"});
		if (!!roles.OSSK) tipos.push({label: "Corretiva", value: "CORRETIVA"});
		if (!!roles.OSSX) tipos.push({label: "Projeto", value: "PROJETO"});
		setTipos(tipos);
		if (tipos.length === 1 && !props.disabled) {
			props.onChange({name: props.name || "tipo", index: props.index, value: tipos[0].value, automatico: true});
		}
	}, [roles]);

	return <Dropdown name="tipo" label="Tipo" options={tipos} {...props} disabled={tipos.length === 1 || props.disabled}/>;

}
