import React, {useCallback} from "react";
import {MultiSelect} from "./index";
import {optionsStatus} from "../../service/ordemServicoService";

export function OrdemServicoStatusMultiselect(props) {

	const statusTemplate = useCallback(option => {
		return (
			<div className="flex align-items-center">
				<i style={{color: `${option.color}`}} className={`fa-lg	 fa-solid ${option.icon}`}/>
				<div className="ml-1">{option.label}</div>
			</div>
		);
	}, []);

	return (
		<MultiSelect label="Status"
		             showSelectAll
		             options={optionsStatus}
		             itemTemplate={statusTemplate}
		             placeholder="Todos"
		             display="chip"
		             {...props}
		/>
	);

}
