import React from "react";

export const optionsOperacao = [
    {label: "Campo", value: "CAMPO", color: '#4CAF50', icon: "fa-solid fa-building-flag"},
    {label: "Laboratório", value: "LABORATORIO", icon: "fa-solid fa-house-flag", color: "#795548"},
    {label: "Suporte", value: "SUPORTE", icon: "fa-solid fa-headset", color: "#E91E63"}
];

export const optionsOperacaoTemplate = ((option) => {
    return (
        <div className="flex align-items-center">
            <i style={{color: `${option.color}`}} className={`fa-lg	 fa-solid ${option.icon} mr-2`}/>
            <div>{option.label}</div>
        </div>
    );
}); 

export const optionsOperacaoSelecionadaTemplate = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <i style={{color: `${option.color}`}} className={`fa-lg	 fa-solid ${option.icon} mr-2`}/>
                <div>{option.label}</div>
            </div>
        );
    }

    return <span>{props.placeholder}</span>;
};