import React, {useCallback, useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { pedidoVendaService, status } from '../../services/pedidoVendaService';
import { buildValidator, isEmpty } from '../../../../utils/fieldValidator';
import { toast } from 'react-toastify';
import { Button } from '../../../../components/button';
import { FormTab } from '../../../../components/formtab';
import { TabPanel } from 'primereact/tabview';
import { PanelContent } from '../../../../components/panel';
import { EditarDadosPrincipais } from './EditarDadosPrincipais';
import { EditarOrcamentoPedidoVendaItens } from './EditarOrcamentoPedidoVendaItens';
import { EditarEventos } from './EditarEventos';
import {useAuth} from "../../../../context/AuthContext";
import { withDialog } from '../../../../utils/dialogContext';
import { InformationDialog } from '../../../../components/dialog/InformationDialog';
import { Dialog } from '../../../../components/dialog';
import { InputTextarea } from '../../../../components/inputtextarea';
import { ConfirmDialogV2 } from '../../../../components/confirmdialog';
import { EditarPedidoVendaAnexo } from '../EditarPedidoVendaAnexo';

export const EditarOrcamentoPedidoVenda = withDialog(({showDialog}) => {

	const {id} = useParams();
	const navigate = useNavigate();
	const {roles, usuario} = useAuth();
	const [pedidoVenda, setPedidoVenda] = useState({...pedidoVendaService.criarOrcamento()});
	const [messages, setMessages] = useState({...buildValidator(), itens: [], anexos: []});
	const [loading, setLoading] = useState(false);
	const [motivoPerda, setMotivoPerda] = useState('');
	const [orcamentoPerdido, setOrcamentoPerdido] = useState(false);
	const disabled = pedidoVenda.status === 'AGUARDANDO' || pedidoVenda.status === 'PERDIDO';
	const disabledItens = !pedidoVenda.cliente?.id;

	useEffect(() => {		
		if (id !== "new") {
			pedidoVendaService.buscar(id).then(pedidoVenda => {
				let carregarPedidoVenda = {...pedidoVenda, 
					itens: [...pedidoVenda.itens].map(item => {
						return {
							...item,
							percentualIpi: item?.percentualIpi * 100,
							percentualIcms: item?.percentualIcms * 100,
							percentualPis: item?.percentualPis * 100,
							percentualCofins: item?.percentualCofins * 100,
						}
					})
						
				}
				setPedidoVenda(carregarPedidoVenda)

			});
		}	
	}, [id, loading, setPedidoVenda]);
	
    const headerStatus = useCallback(() => {
        const value = status.filter(status => status.value === pedidoVenda.status)[0];
		return(
            <div className="flex" title={value.label}>
                <i className={`fa-solid ${value.icon} fa-lg mr-2`} style={{color: value.color}}/>
                <strong>{value.label}</strong>
            </div>
        )
	}, [pedidoVenda.status]);

	const handleSalvar = useCallback(() => {

		const messages = pedidoVendaService.validarOrcamento(pedidoVenda);
		
        if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;        
		}

		if(pedidoVenda.status !== 'ORCAMENTO'){
			if(pedidoVenda.itens.length <= 0){
				showDialog(<InformationDialog header="Informação" message="Nenhum item adicionado. Por favor adicione pelo menos 1 item antes de continuar com essa operação."/>);
				return;
			}
		}

        setLoading(true);

		let salvarOrcamento = {...pedidoVenda}

		if(salvarOrcamento.itens.length > 0){
			salvarOrcamento = {
				...salvarOrcamento,
				itens: [...pedidoVenda.itens].map(item => {
					return {
						...item,
						percentualIpi: item?.percentualIpi / 100,
						percentualIcms: item?.percentualIcms / 100,
						percentualPis: item?.percentualPis / 100,
						percentualCofins: item?.percentualCofins / 100,
					}
				}),
				orcamento: {
					...salvarOrcamento.orcamento,
					valorProdutos: pedidoVenda.itens?.reduce((soma, i) => soma + i.valorTotal, 0),
					valorTotal: pedidoVenda.itens?.reduce((soma, i) => soma + i.valorTotal, 0) + salvarOrcamento.valorFrete,
				}
			}
		}

		if(typeof(salvarOrcamento?.orcamento?.indice) === 'string'){
			salvarOrcamento = {
				...salvarOrcamento,
				orcamento: {
					...salvarOrcamento.orcamento,
					indice: null
				}
			}
		}

        toast.promise(
			pedidoVendaService.salvar(salvarOrcamento).then(value => {
                navigate(-1)
            }).finally(() => setLoading(false)),
            {
                "pending": `Salvando orçamento do pedido de venda. Aguarde...`,
                "success": `Orçamento do pedido de venda salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar orçamento do pedido de venda`;
                    }
                }
            }
        );
    }, [navigate, pedidoVenda, showDialog]);

	const handleAprovar = useCallback(() => {

        const messages = pedidoVendaService.validarOrcamento(pedidoVenda);
        if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;
        }

		if(pedidoVenda.status !== 'ORCAMENTO'){
			console.log(pedidoVenda.status)
			if(pedidoVenda.itens.length <= 0){
				showDialog(<InformationDialog header="Informação" message="Nenhum item adicionado. Por favor adicione pelo menos 1 item antes de continuar com essa operação."/>);
				return;
			}
		}

		let aprovarOrcamento;

		if(pedidoVenda.orcamento.tipo === 'PEDIDO_VENDA'){
			aprovarOrcamento = {
				...pedidoVenda, 
				status: 'RASCUNHO', 
				vendedor: pedidoVenda.orcamento.vendedor, 
				condicaoPagamento: pedidoVenda.orcamento.condicaoPagamento, 
				complemento: pedidoVenda.orcamento.observacao,
				postBack: true
			}
		}else{
			aprovarOrcamento = {...pedidoVenda, status: 'APROVADO'}
		}

		if(typeof(aprovarOrcamento?.orcamento?.indice) === 'string'){
			aprovarOrcamento = {
				...aprovarOrcamento,
				orcamento: {
					...aprovarOrcamento.orcamento,
					indice: null
				}
			}
		}

        setLoading(true);


        
        toast.promise(
			pedidoVendaService.salvar(aprovarOrcamento).then(value => {
				if(pedidoVenda?.orcamento?.tipo === "PEDIDO_VENDA"){ 
					return navigate(`/pedidos-venda/${value.sistema.toLowerCase()}/${value.id}`)
				};
                navigate(-1)
            }).finally(() => setLoading(false)),
            {
                "pending": `Salvando orçamento do pedido de venda. Aguarde...`,
                "success": `Orçamento do pedido de venda salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar orçamento do pedido de venda`;
                    }
                }
            }
        );
    }, [navigate, pedidoVenda, showDialog]);

	const handlePerder = useCallback(() => {
		const messages = pedidoVendaService.validarOrcamento(pedidoVenda);
		
        if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;        
		}

		if(pedidoVenda.status !== 'ORCAMENTO'){
			if(pedidoVenda.itens.length <= 0){
				showDialog(<InformationDialog header="Informação" message="Nenhum item adicionado. Por favor adicione pelo menos 1 item antes de continuar com essa operação."/>);
				return;
			}
		}
		
		let perderOrcamento = {
			...pedidoVenda, 
			status: 'PERDIDO', 
			complemento: pedidoVenda.orcamento.observacao,
			eventos: [...pedidoVenda.eventos,{
				...pedidoVendaService.criarEvento(pedidoVenda.eventos),
				usuario: usuario.email,
				observacoes: motivoPerda
			}]
		}

		if(typeof(perderOrcamento?.orcamento?.indice) === 'string'){
			perderOrcamento = {
				...perderOrcamento,
				orcamento: {
					...perderOrcamento.orcamento,
					indice: null
				}
			}
		}

        setLoading(true);
        toast.promise(
			pedidoVendaService.salvar(perderOrcamento).then(value => {
                navigate(-1)
            }).finally(() => setLoading(false)),
            {
                "pending": `Salvando orçamento do pedido de venda. Aguarde...`,
                "success": `Orçamento do pedido de venda salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar orçamento do pedido de venda`;
                    }
                }
            }
        );
    }, [motivoPerda, navigate, pedidoVenda, showDialog, usuario.email]);
	
	const perdido = pedidoVenda.status === 'ORCAMENTO';
	const converter = pedidoVenda.status === 'ORCAMENTO';

	const handleConfirmar = () => {
		const messages = pedidoVendaService.validarOrcamento(pedidoVenda);
		
        if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;        
		}
		if(pedidoVenda.status !== 'ORCAMENTO'){
			if(pedidoVenda.itens.length <= 0){
				showDialog(<InformationDialog header="Informação" message="Nenhum item adicionado. Por favor adicione pelo menos 1 item antes de continuar com essa operação."/>);
				return;
			}
		}

		showDialog(
            <ConfirmDialogV2 
                message="Tem certeza de que deseja aprovar esse orçamento?" 
                onYes={handleAprovar}
            />
        );
	}

	const handleModalPerdido = () => {
		const messages = pedidoVendaService.validarOrcamento(pedidoVenda);
		
        if (!messages.isEmpty()) {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;        
		}
		if(pedidoVenda.status !== 'ORCAMENTO'){
			if(pedidoVenda.itens.length <= 0){
				showDialog(<InformationDialog header="Informação" message="Nenhum item adicionado. Por favor adicione pelo menos 1 item antes de continuar com essa operação."/>);
				return;
			}
		}
		setOrcamentoPerdido(true)
	}

	const botoesAdicionais = [
		converter ?  <div style={{float: "left"}} key='confirmar'>
				<Button label="Aprovar" title="O orçamento foi aprovado pelo cliente." 
						icon="fa-solid fa-user-check" primary onClick={handleConfirmar} disabled={loading}/>			
				</div> : null,

		perdido ? <div style={{float: "left"}} key='perdido'>
		 					<Button 
								label="Perdido" 
								title="O orçamento foi recusado pelo cliente."  
								warning
								icon="fa-solid fa-user-slash" 
								onClick={handleModalPerdido} 
								disabled={loading}
							/>
					</div> : null
	];

	const descricao = !pedidoVenda.id ? 'Orçamento de Pedido de Venda' : `Orçamento de Pedido de Venda nº ${pedidoVenda.numero}`;

	function headerTemplate(e) {
		let style = {};
		switch (e.index) {
			case 0:
				if (messages.cliente || messages.condicaoPagamento || messages.vendedor || messages.frete || messages.formaPagamento) {
					style.color = "#F44336";
				}
				break;
			case 1:
				if (messages.itens.some(mi => !isEmpty(mi))) {
					style.color = "#F44336";
				}
				break;
			case 2:
				break;
			case 3:
				if (messages.anexos.some(mi => !isEmpty(mi))) {
					style.color = "#F44336";
				}
				break;
			default:
				break;
		}
		return (
			<div className={`${e?.className}`} aria-controls={`${e.ariaControls}`} onClick={e.onClick} onKeyDown={e.onKeyDown} style={style}>
				<i className={`${e?.leftIconElement?.props?.className}`}/>
				<div className={`${e?.titleClassName}`} >{e?.titleElement?.props?.children}</div>
			</div>
		);
	}



	return (
		<>
			<FormTab
				podeDeletar={false}
				podeSalvar={!!roles.PDVC}
				descricao={descricao}
				service={pedidoVendaService}
				value={pedidoVenda}
				salvar={handleSalvar}
				lockSave={disabled || loading}
				blocked={id !== "new" && !pedidoVenda.id}
				onValidate={setMessages}			
				header_right={headerStatus}
				botoes_adicionais={botoesAdicionais}
			>

				<TabPanel header="Dados Principais" leftIcon='fa-solid fa-database' headerTemplate={headerTemplate}>
					<EditarDadosPrincipais 
						pedidoVenda={pedidoVenda} 
						setPedidoVenda={setPedidoVenda} 
						messages={messages} 
						setMessages={setMessages} 
						disabled={disabled}
					/>
				</TabPanel>

				<TabPanel header="Itens" leftIcon='fa-solid fa-list-ol' headerTemplate={headerTemplate} disabled={disabledItens}>
					<EditarOrcamentoPedidoVendaItens  
						pedidoVenda={pedidoVenda} 
						setPedidoVenda={setPedidoVenda} 
						messages={messages} 
						setMessages={setMessages} 
						disabled={disabled}
					/>
				</TabPanel>

				<TabPanel header="Eventos" leftIcon='fa-solid fa-file-lines'>
					<PanelContent>
						<EditarEventos 
							pedidoVenda={pedidoVenda} 
							setPedidoVenda={setPedidoVenda} 
							messages={messages} 
							setMessages={setMessages} 
							disabled={disabled}
						/>
					</PanelContent>
				</TabPanel>
				<TabPanel header="Anexos" leftIcon='fa-solid fa-paperclip' headerTemplate={headerTemplate}>
                    <PanelContent>
                        <EditarPedidoVendaAnexo 
							pedidoVenda={pedidoVenda} 
							setPedidoVenda={setPedidoVenda}
							messages={messages} 
							setMessages={setMessages} 
						/>
                    </PanelContent>
                </TabPanel>

				
			</FormTab>

            <Dialog 
                footer='especifico' 
                iconAccept='fa-solid fa-file-invoice-dollar' 
                acceptLabel={'Confirmar'}
                visible={orcamentoPerdido} 
                modal
                onHide={() => setOrcamentoPerdido(false)} 
                header='Informe'
                onYes={handlePerder}
				loading={loading}
            >	
					<label className='ml-2'>Motivo da Perda:</label>
					<InputTextarea
						value={motivoPerda}
						onChange={(e) => setMotivoPerda(e.value)}
						cols={100} 
						rows={10} 
						col={12}
					/>
            </Dialog>
			{/* <EditarOrcamentoPedidoVendaPerdido
				visible={c}
				setVisible={setOrcamentoPerdido}
				pedidoVenda={pedidoVenda}
				setPedidoVenda={setPedidoVenda}
			/> */}
		</>
	);
});