import {baseService} from "./baseService";
import {buildValidator, isBiggerThanZero, isRequired} from "../utils/fieldValidator";

export const contaEmailService = {
    criar: () => {
        return {
            id: null,
            descricao: "",
            nome: "",
            usuario: "",
            host: "",
            porta: 0,
            ssl:false,
        };
    },
    validar: (contaEmail) => {
        const messages = buildValidator();
        messages.descricao = isRequired(contaEmail.descricao);
        messages.nome = isRequired(contaEmail.nome);
        messages.usuario = isRequired(contaEmail.usuario);
        if (!contaEmail.id) {
            messages.senha = isRequired(contaEmail.senha);
        }
        messages.host = isRequired(contaEmail.host);
        messages.porta = isBiggerThanZero(contaEmail.porta);
        return messages;
    },
    ...baseService("/contas-email"),
    async listarContasEmail(params) {
        const query = [];
        if (params.descricao?.length) query.push(`descricao=lk=${params.descricao}`);
        if (params.usuario?.length) query.push(`usuario=lk=${params.usuario}`);
        if (params.host?.length) query.push(`host=lk=${params.host}`);
        return await contaEmailService.listar([`query=${query.join(";")}&sort=descricao`]);
    }
};
