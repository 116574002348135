import React, {useContext, useEffect, useMemo, useState} from "react";
import {DataTable} from "../../components/datatable";
import {isStacked} from "../../context/BreakpointContext";
import {Column} from "primereact/column";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {InputCurrency, InputNumber} from "../../components/inputnumber";
import {formatCurrency} from "../../utils/numberFormatter";
import {PanelContent} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {produtoService, resolverSaldo} from "../../service/produtoService";
import {DialogContext} from "../../utils/dialogContext";
import {useAuth} from "../../context/AuthContext";
import {
	optionsOrdemServicoItemPecaStatus,
	ordemServicoItemPecaStatus,
	ordemServicoItemService
} from "../../service/ordemServicoItemService";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {Dropdown} from "../../components/dropdown";
import {BlockUI} from "primereact/blockui";
import {SelectEstoquePeca} from "../__Commons/SelectEstoquePeca";
import {If} from "../../components/conditional/If";
import {Tooltip} from "primereact/tooltip";
import {origensPreco} from "../../service/ordemServicoService";
import {resolverEstoque} from "../__Commons-OrdemServico/ConfirmacaoEstoquePecaCampo";
import moment from "moment";
import {EstoqueAutoComplete} from "../../components/autocomplete/EstoqueAutoComplete";

export function EditarOrdemServicoItemPeca({ordemServico, item, index, handleChange, messages, validarItem}) {

	const {usuario, roles} = useAuth();
	const {showDialog} = useContext(DialogContext);
	const [pecasSelecionadas, setPecasSelecionadas] = useState([]);
	const [blockedPecas, setBlockedPecas] = useState(false);
	const [sequencia, setSequencia] = useState(0);

	const isFranquia = useMemo(() => ordemServico.contrato?.valorFranquia > 0, [ordemServico.contrato]);
	const disableEdit = (
		!roles.OSSU
		&& (
			item.orcamentos?.some(osio => !["RECUSADO", "CANCELADO"].includes(osio.status))
			|| ordemServico.faturamentoConfirmado
			|| ["FATURAMENTO", "EXPEDICAO"].includes(ordemServico.status)
		)
	);

	useEffect(() => {
		if (item.pecas?.length) {
			setSequencia(Math.max(...item.pecas.map(osip => osip.sequencia)) || 0);
		}
	}, [item.id]);

	async function handleChangePeca(event) {
		const peca = item.pecas[event.index];
		if (event.name === "status") {
			switch (event.value) {
				case "LIBERADO":
					return;
				case "MOVIMENTADO":
					if (!roles.CAMT && !roles.OSSY) {
						return;
					}
					if (ordemServico.operacao === "CAMPO" && roles.CAMT) {
						peca.estoqueConsumoNovas = usuario.estoqueNovas;
						peca.estoqueConsumoRecondicionadas = usuario.estoqueRecondicionadas;
					}
					break;
			}
		}
		if (event.name === "status" && event.value === "MOVIMENTADO" && peca.estoque === "NOVAS" && peca.quantidade > resolverSaldo(resolverEstoque(peca, ordemServico.operacao), peca.produto)) {
			showDialog(<InformationDialog message="Não é possível marcar como entregue uma peça sem estoque disponível."/>);
			return;
		}
		peca[event.name] = event.value;
		switch (event.name) {
			case "quantidade":
			case "produto":
				if (peca.produto?.id) {
					try {
						setBlockedPecas(true);
						const produto = await produtoService.buscar(peca.produto?.id);
						const resposta = await produtoService.buscarPreco({
							produto: peca.produto.id,
							cliente: ordemServico.cliente?.id,
							ordemServico: ordemServico.id,
							contrato: ordemServico.contrato?.id,
							equipamento: item.equipamento?.id,
							data: ordemServico.inicio,
							mauUso: item.flags?.includes("MAU_USO"),
							quantidade: peca.quantidade
						});
						peca.valorUnitario = resposta.valorUnitario;
						peca.valorTabela = resposta.valorTabela;
						peca.origemPreco = resposta.origem;
						if (resposta.listaPrecoItemId) {
							peca.listaPrecoItem = {id: resposta.listaPrecoItemId};
						}
						peca.valorTotal = peca.valorUnitario * peca.quantidade;
						peca.valorFranquia = peca.valorTabela * peca.quantidade;
						peca.produto = produto;
						if (ordemServico.operacao === "CAMPO") {
							if (peca.estoque === "NOVAS" && roles.CAMT) {
								peca.estoqueConsumoNovas = usuario.estoqueNovas;
								peca.estoqueConsumoRecondicionadas = usuario.estoqueRecondicionadas;
								if (peca.quantidade <= resolverSaldo(resolverEstoque(peca), peca.produto)) {
									peca.status = "MOVIMENTADO";
								}
							}
							if (peca.estoque === "NOVAS" && peca.quantidade > resolverSaldo(resolverEstoque(peca), peca.produto)) {
								peca.status = "SOLICITADO";
							}
						}
					} catch (error) {
						console.error(error);
					} finally {
						setBlockedPecas(false);
					}
				}
				break;
			case "valorUnitario":
				if (event.value) {
					peca.valorTotal = event.value * peca.quantidade;
					peca.origemPreco = "MANUAL";
				} else {
					peca.valorTotal = 0;
				}
				break;
			default:
				break;
		}
		handleChange({name: "pecas", value: item.pecas});
	}

	function adicionarPeca() {
		const peca = {
			...ordemServicoItemService.criarPeca(),
			sequencia: sequencia + 1,
			adicionadoPor: usuario
		};
		if (ordemServico.operacao === "CAMPO") {
			peca.estoqueConsumoNovas = usuario.estoqueNovas;
			peca.estoqueConsumoRecondicionadas = usuario.estoqueRecondicionadas;
		}
		item.pecas.push(peca);
		handleChange({name: "pecas", value: item.pecas});
		setSequencia(sequencia + 1);
	}

	function removerPecas() {
		if (pecasSelecionadas.some(ps => ps.produto || ps.quantidade || ps.valorUnitario)) {
			showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover as peças selecionadas?" onYes={() => {
				removerPecasSelecionadas();
			}}/>);
		} else {
			removerPecasSelecionadas();
		}
	}

	function removerPecasSelecionadas() {
		if (pecasSelecionadas.some(pc => ["MOVIMENTADO", "CANCELADO"].includes(pc.status))) {
			showDialog(<InformationDialog header="Informação" message="Não é possível remover itens com movimentação de estoque"/>);
			return;
		}
		item.pecas = item.pecas.filter(osi => !pecasSelecionadas.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
		if (!item.id) {
			item.pecas.forEach((osi, i) => osi.sequencia = i + 1);
		}
		handleChange({name: "pecas", value: item.pecas});
		setPecasSelecionadas([]);
		validarItem();
	}

	const pecaControlSet = (
		<div>
			<Button disabled={disableEdit} icon="pi pi-plus" onClick={adicionarPeca}/>
			<Button disabled={disableEdit || !pecasSelecionadas?.length} onClick={removerPecas} danger icon="pi pi-minus"/>
		</div>
	);

	const pecaHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={roles.OSSO ? 6 : 5} header={pecaControlSet}/>
				{roles.OSSO ? <Column headerStyle={{textAlign: "right"}} header={formatCurrency(item.pecas.filter(peca => peca.status !== 'CANCELADO').map(osip => osip.valorTotal || 0).reduce((a, b) => a + b, 0))}/> : null}
			</Row>
			<Row>
				<Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
				<Column style={{width: "1em"}} header="Item" />
				<Column style={{width: "3em"}} header="Status"/>
				<Column header="Dados Principais"/>
				<Column style={{width: "14em"}} header="Quantidade"  alignHeader={"center"}/>
				{roles.OSSO ? <Column style={{width: "15em"}} alignHeader={"center"} header="Valor Unitário"/> : null}
				{roles.OSSO ? <Column style={{width: "11em"}} alignHeader={"center"} header="Valor Total"/> : null}
			</Row>
		</ColumnGroup>
	);

	const pecaFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={roles.OSSO ? 6 : 5} footer={pecaControlSet}/>
				{roles.OSSO ? <Column footerStyle={{textAlign: "right"}} footer={formatCurrency(item.pecas.filter(peca => peca.status !== 'CANCELADO').map(osip => osip.valorTotal || 0).reduce((a, b) => a + b, 0))}/> : null}
			</Row>
		</ColumnGroup>
	);

	function corretivaPeca(osip, column) {
		const estoque = resolverEstoque(osip, ordemServico.operacao);
		return (
			<>
				<InputText
					reduced
					readOnly={disableEdit}
					index={column.rowIndex}
					label="Identificação da peça"
					value={osip.identificacao}
					onChange={handleChangePeca}
					name="identificacao"
				/>
				<If condition={ordemServico.operacao === "CAMPO" && !roles.OSSY}>
					<div className="col-12" style={{fontSize: ".8em", fontStyle: "italic"}}>
						Adicionada por: {osip.adicionadoPor?.nome} às {moment(osip.registro).format("DD/MM/YY HH:mm")} - {estoque ? `(Estoque: ${estoque?.autoComplete})` : "Estoque não selecionado"}
					</div>
				</If>
				<If condition={ordemServico.operacao === "CAMPO" && roles.OSSY}>
					<>
						<EstoqueAutoComplete
							disabled={osip.status === "MOVIMENTADO"}
							index={column.rowIndex}
							col={6}
							name="estoqueConsumoNovas"
							value={osip.estoqueConsumoNovas}
							label="Estoque Novas"
							onChange={handleChangePeca}
						/>
						<EstoqueAutoComplete
							disabled={osip.status === "MOVIMENTADO"}
							index={column.rowIndex}
							col={6}
							name="estoqueConsumoRecondicionadas"
							value={osip.estoqueConsumoRecondicionadas}
							label="Estoque Recondicionadas"
							onChange={handleChangePeca}
						/>
						<div className="col-12" style={{fontSize: ".8em", fontStyle: "italic"}}>
							Adicionada por: {osip.adicionadoPor?.nome} às {moment(osip.registro).format("DD/MM/YY HH:mm")} - {estoque ? `(Estoque: ${estoque?.autoComplete})` : "Estoque não selecionado"}
						</div>
					</>
				</If>
			</>
		);
	}

	function bloquearEdicao(osip) {
		return (
			disableEdit || ["MOVIMENTADO", "CANCELADO"].includes(osip.status)
		);
	}

	return (
		<PanelContent>
			<div className="col-12" style={{position: "relative"}}>
				<BlockUI blocked={blockedPecas} >
					<DataTable paginator
							selection={pecasSelecionadas}
							onSelectionChange={e => setPecasSelecionadas(e.value)}
							value={item.pecas}
							headerColumnGroup={pecaHeaderGroup}
							footerColumnGroup={pecaFooterGroup}
							selectionMode="checkbox"
							header={isStacked() ? null : pecaControlSet}
							footer={isStacked() ? null : pecaControlSet}
					>
						<Column selectionMode="multiple"/>
						<Column header="Item" field="sequencia" style={{textAlign: "center"}}/>
						<Column header="Status" field="status" style={{textAlign: "center"}} body={(osip, c) => {
							return (
								<span>
									{ordemServicoItemPecaStatus[osip?.status]}<br/>
									{osip.estoque === "RECONDICIONADAS" ? <><i title="Não Integrar" className="fas fa-lock" style={{color: "#FFC107"}}/><br/></> : null}
									{
										messages.itens?.[index]?.pecas?.[c.rowIndex]?.status
											? <span style={{color: "#F44336"}}>{messages.itens?.[index]?.pecas?.[c.rowIndex]?.status}</span>
											: null
									}
								</span>
							);
						}}/>
						<Column header="Dados Principais" style={{flexDirection: "column", alignItems: "start"}} field="produto" body={(osip, column) => (
							<div className="grid">
								<ProdutoAutoComplete readOnly={osip.liberadoPor || bloquearEdicao(osip)} onBlur={validarItem} required invalid={messages.itens?.[index]?.pecas?.[column.rowIndex]?.produto} col={ordemServico.operacao === "CAMPO" ? 8 : 12} label="Produto" index={column.rowIndex} onChange={handleChangePeca} name="produto" value={osip.produto}/>
								{ordemServico.operacao === "CAMPO" ? <Dropdown disabled={disableEdit} options={optionsOrdemServicoItemPecaStatus} col={4} label="Status" name="status" value={osip.status} index={column.rowIndex} onChange={handleChangePeca}/> : null}
								{ordemServico.tipo === "CORRETIVA" ? corretivaPeca(osip, column) : null}
							</div>
						)}/>
						<Column header="Quantidade" style={{flexDirection: "column", alignItems: "start"}} field="quantidade" body={(osip, column) => (
							<>
								<div style={{width: "100%", padding: "0 .5em .5em .5em", whiteSpace: "nowrap"}}>
									<table style={{minWidth: "auto", width: "100%"}}>
										<tr>
											<td style={ordemServico.operacao === "LABORATORIO" && osip.estoque === "NOVAS" ? {fontWeight: "bold"} : null}>Novas:</td>
											<td style={{textAlign: "right"}}>{osip.produto?.vwProduto?.saldoDisponivel}</td>
										</tr>
										<tr>
											<td style={osip.estoque === "RECONDICIONADAS" ? {fontWeight: "bold"} : null}>Recondicionadas:</td>
											<td style={{textAlign: "right"}}>
												<If condition={ordemServico.operacao === "LABORATORIO"}>
													{osip.produto?.vwProduto?.saldoRecondicionadas}
												</If>
												<If condition={ordemServico.operacao === "CAMPO"}>
													{resolverSaldo(osip.estoqueConsumoRecondicionadas || usuario.estoqueRecondicionadas, osip.produto)}
												</If>
											</td>
										</tr>
										<If condition={ordemServico.operacao === "CAMPO"}>
											<tr>
												<td style={osip.estoque === "NOVAS" ? {fontWeight: "bold"} : null}>Técnicos:</td>
												<td style={{textAlign: "right"}}>{resolverSaldo(osip.estoqueConsumoNovas || usuario.estoqueNovas, osip.produto)}</td>
											</tr>
										</If>
									</table>
								</div>
								<InputNumber readOnly={osip.liberadoPor || osip.status === "CANCELADO" || disableEdit} required onBlur={validarItem} invalid={messages.itens?.[index]?.pecas?.[column.rowIndex]?.quantidade} index={column.rowIndex} onChange={handleChangePeca} name="quantidade" value={osip.quantidade}/>
								<If condition={ordemServico.operacao === "CAMPO"}>
									<SelectEstoquePeca
										disabled={osip.liberadoPor || (ordemServico.operacao === "CAMPO" && !roles.CAMT) || bloquearEdicao(osip) || ["MOVIMENTADO", "CANCELADO"].includes(osip.status)}
										estoqueNovas={true}
										index={column.rowIndex}
										name="estoque"
										value={osip.estoque}
										onChange={handleChangePeca}
									/>
								</If>
							</>
						)}/>
						{
							roles.OSSO ? (
								<Column header="Valor Unitário" style={{flexDirection: "column", alignItems: "start"}} field="valorUnitario" body={(osip, column) => (
									<div>
										<InputCurrency readOnly={disableEdit} label="Valor de Tabela" value={osip.valorTabela} name="valorTabela" index={column.rowIndex} onChange={handleChangePeca}/>
										<InputCurrency readOnly={disableEdit} label="Valor Unitário" onBlur={validarItem} invalid={messages.itens?.[index]?.pecas?.[column.rowIndex]?.valorUnitario} name="valorUnitario" index={column.rowIndex} value={osip.valorUnitario} onChange={handleChangePeca}/>
										{origensPreco[osip.origemPreco]}
									</div>
								)}/>
							) : null
						}
						{roles.OSSO ? <Column header="Valor Total" field="valorTotal" style={{textAlign: "right"}} body={osip => (
							<>
								<span style={osip.valorTotal ? {fontWeight: "bold"} : {}}>{formatCurrency(osip.valorTotal)}</span><br/>
								{isFranquia ? (
									<>
										<br/>Franquia:<br/>
										<span style={osip.valorFranquia ? {fontWeight: "bold"} : {}}>{formatCurrency(osip.valorFranquia)}</span>
									</>
								) : null}
							</>
						)}/> : null}
					</DataTable>
				</BlockUI>	
			</div>
			{disableEdit ? <Tooltip target="input" content="Não é possível alterar peças com orçamento em aberto ou em processo de faturamento" position="bottom"/> : null}
		</PanelContent>
	);

}
