import React, { useState } from "react";

import { Column } from "primereact/column";

import { manterChavesExclusivas } from "./utils";
import { TabelaEditarFechamentoExpandida } from "./TabelaEditarFechamentoExpandida";
import { DataTable } from "../../../../components/datatable";
import { InputText } from "../../../../components/inputtext";
import moment from "moment";
import { formatMonthYear } from "../../../../utils/DateFormatter";

export const TabelaEditarFechamento = React.memo(({fechamento, tiposComissao, setAcompanhamento}) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [loadingRow, setLoadingRow] = useState(false);
   
    const rowExpansionTemplate = (data) => (
        <TabelaEditarFechamentoExpandida
            data={data} 
            todosTiposComissaoCompletos={tiposComissao}
            loadingRow={loadingRow}
        />
    )
    
    const mes = moment(fechamento?.fim).format("YYYY-MM")

    return (
        <>
                <InputText 
					value={formatMonthYear(mes)} 
					label="Mês de fechamento" 
					col={2} 
                    readOnly
					view="month"
					dateFormat="mm/yy"
					showButtonBar={false}
				/>
				<InputText 
					label="Fechado em"
					col={2} 
					name="fim" 
					readOnly
					value={moment(fechamento?.fechadoEm).format("DD/MM/YYYY HH:mm:ss")} // {moment(fechamento?.fechadoEm).format("YYYY-MM-DD HH:mm:ss")}  
				/>
				<InputText
					label="Fechado por"
					value={fechamento?.fechadoPor}
					readOnly
					col={6}
				/>
            <div className="col-12">
                <DataTable
                    value={fechamento?.vendedores}
                    valuelength={fechamento?.vendedores?.length}
                    expandedRows={expandedRows} 
                    onRowToggle={(e) => {   
                        console.log(e)             
                        const expandRow = manterChavesExclusivas(e.data, expandedRows)                
                        setExpandedRows(expandRow)
                    }}
                    paginator={false}
                    rowExpansionTemplate={rowExpansionTemplate}
                    dataKey="id"
                >
                    <Column expander style={{ width: '5rem' }} />
                    <Column 
                        sortable 
                        field="vendedor.autoComplete" 
                        header="Consultor de Vendas" 
                        style={{textAlign: "left", width: "*"}}
                        body={(data) => (
                            <div className="font-bold">
                                {data?.vendedor.autoComplete}
                            </div>
                        )}
                    />
                </DataTable>
            </div>
        </>
    );
});