import React, {useEffect, useState} from "react";

import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import moment from "moment";

import {headerTemplate} from "../../utils/templates";
import {useBreakpoint} from "../../context/BreakpointContext";
import {Calendar} from "../../components/calendar";
import { backlogSLAService } from "../../service/backlogSLAService";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { DataTable } from "../../components/datatable";
import { formatDate } from "../../utils/numberFormatter";
import {formatNumber} from "chart.js/helpers";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";

const sum = [(a, b) => a + b, 0];

export function BacklogSLA() {

    const [loading, setLoading] = useState(false);    
    const [params, setParams] = useState({
        cliente: null,
        inicio: moment().subtract(1, 'week').format("YYYY-MM-DD"),
        fim: moment().format("YYYY-MM-DD"),
    });
    const [geral, setGeral] = useState([{}]);
    const {breakpoint} = useBreakpoint()
    const isLargeDevice = breakpoint === 'lg' || breakpoint === 'md'

    function handleChange(event) {
        setParams({...params, [event.name]: event.value});
    }

    useEffect(handleProcurar, []);

    async function handleProcurar() {
        setLoading(true);
        const {inicio, fim} = params;
        setGeral(await backlogSLAService.listarBacklog(params));
        setLoading(false);
    } 

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column rowSpan={2} field="data" header="Data"/>
                <Column rowSpan={2} field="backlogInicio" style={{textAlign: "center"}} header="Acumulado"/>
                <Column rowSpan={2} field="abertas" style={{textAlign: "center"}} header="Abertas"/>
                <Column colSpan={4} field="encerradas" style={{textAlign: "center"}} header="Encerradas"/>
            </Row>                
            <Row>
                <Column field="encerradas" style={{textAlign: "center"}} header="Todas"/>
                <Column field="encerradasSemSla" style={{textAlign: "center"}} header="Sem SLA"/>
                <Column field="encerradasSlaOk" style={{textAlign: "center"}} header="SLA OK"/>
                <Column field="encerradasSlaEstourado" style={{textAlign: "center"}} header="SLA Excedido"/>
            </Row>
        </ColumnGroup>
    );

    const footerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column colSpan={2}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geral.map(g => g.abertas).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geral.map(g => g.fechadas).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geral.map(g => g.fechadasSemSla).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geral.map(g => g.fechadasDentroPrazo).reduce(...sum))}</div>}/>
                <Column footer={<div style={{textAlign: "right"}}>{formatNumber(geral.map(g => g.fechadasPrazoExcedido).reduce(...sum))}</div>}/>
            </Row>
        </ColumnGroup>
    );    

    return (
        <div>
            <Panel headerTemplate={() => headerTemplate('Backlog de SLA', 'fa-file-lines')} >
                <PanelContent>
                    <Calendar label="Período" col={2} name="inicio" value={params.inicio} onChange={handleChange}/>
                    <Calendar label="&nbsp;" col={2} name="fim" value={params.fim} onChange={handleChange}/>
                    <ClienteAutoComplete forceSelection={false} col={8} name="cliente" value={params.cliente} onChange={handleChange}/>
                </PanelContent>
                <PanelFooter>
                    <Button disabled={loading} loading={loading} onClick={handleProcurar} label="Procurar" icon="pi pi-search"/>
                </PanelFooter>
            </Panel>
            <DataTable paginator={false} footer={null} headerColumnGroup={headerColumnGroup} footerColumnGroup={footerColumnGroup} value={geral} valuelength={geral?.length}>
                <Column field="data" style={{textAlign: "center"}} body={r => formatDate(r.data)}/>
                <Column field="acumulado" style={{width: "12em", textAlign: "right"}} body={(r, i) => {
                    if (i.rowIndex) {
                        const diff = geral[i.rowIndex - 1].acumulado - geral[i.rowIndex].acumulado;
                        if (diff > 0) {
                            return <div><span className="backlog-sla-indicator green"><i className="fas fa-arrow-down"/> {Math.abs(diff)}</span>{formatNumber(r.acumulado)}</div>;
                        } else if (diff < 0) {
                            return <div><span className="backlog-sla-indicator red"><i className="fas fa-arrow-up"/> {Math.abs(diff)}</span>{formatNumber(r.acumulado)}</div>;
                        } else {
                            return <div><span className="backlog-sla-indicator"><i className="fas fa-equals"/> {Math.abs(diff)}</span>{formatNumber(r.acumulado)}</div>;
                        }
                    }
                    return formatNumber(r.acumulado);
                }}/>
                <Column field="abertas" style={{width: "12em", textAlign: "right"}} body={r => formatNumber(r.abertas)}/>
                <Column field="fechadas" style={{width: "12em", textAlign: "right"}} body={r => formatNumber(r.fechadas)}/>
                <Column field="fechadasSemSla" style={{width: "12em", textAlign: "right"}} body={r => formatNumber(r.fechadasSemSla)}/>
                <Column field="fechadasDentroPrazo" style={{width: "12em", textAlign: "right"}} body={r => formatNumber(r.fechadasDentroPrazo)}/>
                <Column field="fechadasPrazoExcedido" style={{width: "12em", textAlign: "right"}} body={r => formatNumber(r.fechadasPrazoExcedido)}/>
            </DataTable>
        </div>
    );

}
