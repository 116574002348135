import {BlockUI} from "primereact/blockui";
import {Column} from "primereact/column";
import {Panel} from "primereact/panel";
import React, {useEffect, useState} from "react";
import {DataTable} from "../../components/datatable";
import {PanelContent, PanelFooter} from "../../components/panel";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";
import {Button} from "../../components/button";
import {useContext} from "react/index";
import {DialogContext} from "../../utils/dialogContext";
import {VisualizarPrevisoesCompra} from "./VisualizarPrevisoesCompra";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {InputNumber} from "../../components/inputnumber";
import {Dropdown} from "../../components/dropdown";
import {Chip} from "primereact/chip";
import {useNavigate} from "react-router-dom";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import { toast } from "react-toastify";
import saveAs from "file-saver";
import moment from "moment";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {useQueryClientCompras} from "../../utils/query-clients/queryClients";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";

const optionsQuantidades = [
	{label: "Todos", value: null},
	{label: "Sem estoque", value: "SEM_ESTOQUE"},
	{label: "Sem pedido de compra", value: "SEM_COMPRAS"}
];

export function Compras() {

	const navigate = useNavigate();
	const {showDialog} = useContext(DialogContext);
	const [compras, setCompras] = useState([]);
	const {query, handleSearch} = useQueryClientCompras();
	const [loading, setLoading] = useState(false);
	const {params, handleChange} = useQueryParams(QueryParamsKeys.Compras);

	function visualizarPedido(peca) {
		showDialog(<VisualizarPrevisoesCompra codigo={peca.produto.codigo}/>);
	}

	useEffect(() => {
		if (query.data) {
			const compras = query.data;
			for (const c of compras) {
				c.equipamentos = {};
				for (const os of c.ordensServico) {
					if (c.equipamentos[os.equipamentoProduto?.autoComplete]) {
						c.equipamentos[os.equipamentoProduto?.autoComplete].numeros.push(os.numero);
					} else {
						c.equipamentos[os.equipamentoProduto?.autoComplete] = {
							autoComplete: os.equipamentoProduto?.autoComplete,
							numeros: [
								os.numero
							]
						};
					}
				}
			}
			setCompras(compras);
		}
	}, [query.data]);

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column sortable field="produto.codigo" rowSpan={2} header="Produto"/>
				<Column sortable field="produtoEquipamento" rowSpan={2} header="Ordens de Serviço"/>
				<Column colSpan={3} style={{textAlign: "center"}} header="Quantidades"/>
				<Column rowSpan={2} header="Visualizar"/>
			</Row>
			<Row>
				<Column sortable field="saldoDisponivel" style={{width: "7em", textAlign: "center"}} header="Estoque"/>
				<Column sortable field="quantidade" style={{width: "7em", textAlign: "center"}} header="Solicitada"/>
				<Column sortable field="pedCompras" style={{width: "7em", textAlign: "center"}} header="Comprada"/>
			</Row>
		</ColumnGroup> 
	);

	function visualizarOrdemServico(numero) {
		navigate(`/ordens-servico/${numero}`);
	}

	async function handleBaixar() {
        setLoading(true);
        const query = [];
		if (params.produto?.id) query.push(`produto=${params.produto.id}`);
		if (params.ordemServico) query.push(`ordemServico=${params.ordemServico}`);
		if (params.quantidades?.length) query.push(`quantidades=${params.quantidades}`);
		if (params.responsavel?.id) query.push(`responsavel=${params.responsavel.id}`);
		toast.promise(
			ordemServicoItemService.baixarCompras(query).then(data => {
				console.log("data_antes",data);
				saveAs(new Blob([data], {type: "application/csv"}), `${moment().format("YYYYDDMMHHmmss")}.csv`);           
	
			}).finally(() => { setLoading(false) }),
            {
                "pending": `Gerando relatório. Aguarde...`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao procurar ordens de Ordens de Serviço - Cliente: ${e.data?.response?.data}`;
                    }
                }
            }
		)
    }

	function itemTemplateOrdensServico(e) {
		return (
			<div style={{display: "flex", border: "1px solid rgba(0, 0, 0, .2)", padding: ".5em", borderRadius: "4px", alignItems: "center", justifyContent: "center", margin: ".25em 0"}}>
				<span style={{flex: 1, maxWidth: "20em", padding: ".25em"}}>{e.autoComplete}</span>
				<span className="compras-os-chip-container">
					{e.numeros.map(os => <Chip className="compras-os-chip" onClick={() => visualizarOrdemServico(os)} style={{cursor: "pointer", margin: ".25em"}} key={os} label={os}/>)}
				</span>
			</div>
		);
	}

	return (
		<BlockUI blocked={query.isLoading}>
			<Panel header="Previsões de Compra">
				<PanelContent>
					<ProdutoAutoComplete value={params.produto} name="produto" onChange={handleChange} label="Produto" col={4}/>
					<InputNumber value={params.ordemServico} name="ordemServico" onChange={handleChange} label="Nº da Ordem de Serviço" col={2}/>
					<Dropdown options={optionsQuantidades} label="Quantidades" name="quantidades" value={params.quantidades} onChange={handleChange} col={2}/>
					<UsuarioAutoComplete col={4} name="responsavel" label="Responsável" onChange={handleChange} value={params.responsavel}/>
				</PanelContent>
				<PanelFooter>
					<Button loading={query.isLoading || query.isRefetching} disabled={query.isLoading || query.isRefetching} icon="fas fa-search" label="Procurar" onClick={handleSearch}/>
					<Button info disabled={loading} loading={loading} onClick={handleBaixar} label={"Baixar"} icon="fa-solid fa-file-arrow-down"/>
				</PanelFooter>
			</Panel>
			<DataTable rows={50} headerColumnGroup={headerGroup} value={compras}>
				<Column header="Produto" field="produto.autoComplete" />
				<Column header="Equipamento" body={c => Object.values(c.equipamentos).map(itemTemplateOrdensServico)}/>
				<Column style={{width: "5em", textAlign: "center"}} header="Quantidade em Estoque" field="saldoDisponivel"/>
				<Column style={{width: "5em", textAlign: "center"}} header="Quantidade Solicitada" field="quantidade"/>
				<Column style={{width: "5em", textAlign: "center"}} header="Quantidade Comprada" field="pedCompras"/>
				<Column style={{width: "5em", textAlign: "center"}} header="Visualizar" body={c => (
					<Button secondary={!c.pedCompras} disabled={!c.pedCompras} onClick={() => visualizarPedido(c)} icon={`${!c.pedCompras ? "fas fa-times" : "fas fa-search"}`}/>
				)}/>
			</DataTable>
			<RefetchIndicator isRefetching={query.isRefetching}/>
		</BlockUI>
	);

}
