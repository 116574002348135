import React, {useMemo} from 'react';
import {TabelaEquipamento} from './TabelaEquipamento';
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {Button} from "../../components/button";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {useBreakpoint} from "../../context/BreakpointContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {useQueryClientEquipamento} from "../../utils/query-clients/queryClients";
import {useQueryParams} from "../../utils/QueryParamContext";
import {SearchButton} from "../../components/button/SearchButton";
import {If} from "../../components/conditional/If";
import {Panel} from "primereact/panel";
import {BlockUI} from "primereact/blockui";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {ToggleViewHidden} from "../../components/button/ViewHiddenButton";

export function Equipamento() {

    const {roles} = useAuth();
    const navigate = useNavigate();
    const {isLargeDevice} = useBreakpoint();
    const {query, handleSearch} = useQueryClientEquipamento();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.Equipamento);

    function handleCriar() {
        navigate("/equipamentos/new");
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel headerTemplate={() => headerTemplate('Equipamento', 'fa-box')} >
                <PanelContent>
                    <InputText placeholder="a descrição, etiqueta, número do ativo ou part number." col={6} label="Descrição" value={params.textoDeBusca} name="textoDeBusca" onChange={handleChange}/>
                    <InputText placeholder="o número serial do equipamento" col={6} label="Serial" value={params.serial} name="serial" onChange={handleChange}/>
                    <ClienteAutoComplete name="cliente" col={6} value={params.cliente} onChange={handleChange}/>
                    <ProdutoAutoComplete col={6} value={params.produto} name="produto" onChange={handleChange}/>
                </PanelContent>
                <PanelFooter>
                    <div style={{float: "left"}}>
                        <ToggleViewHidden params={params} onChange={handleChange}/>
                    </div>
                    <If condition={roles.EQPA}>
                        <Button success onClick={handleCriar} label={isLargeDevice ? "Novo" : null} icon="pi pi-plus" autowidth={!isLargeDevice}/>
                    </If>
                    <SearchButton onSearch={handleSearch} query={query}/>
                </PanelFooter>
            </Panel>
            {useMemo(() => <TabelaEquipamento value={query.data || []}/>, [query.data])}
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
