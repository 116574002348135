import {buildValidator, isRequired} from '../utils/fieldValidator';
import {baseService} from './baseService';

const resourceUrl = "/produtos/marcas";

export const marcaService = {
    criar: () => {
        return {
            id: null,
            descricao: "",
        };
    },
    validar: (empresa) => {
        const messages = buildValidator();
        messages.descricao = isRequired(empresa.descricao);
        return messages;
    },
    ...baseService(resourceUrl),
    async listarMarcasProduto(params) {
        const query = [];
        if (params.descricao?.length) query.push(`descricao=lk=${params.descricao}`);
        if (!query[0]) {
            query.push("");
        }
        return await marcaService.listar([`query=${query.join(";")}`]);
    }
};
