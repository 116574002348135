import { api } from "./api";

export const historicoService = {
	versoes: async (path, entidadeId)  => {
		return await api.get(`${path}/versoes/${entidadeId}`).then(res => res.data)
	},
	revisoes: async  (path, entidadeId) => {
		return await api.get(`${path}/revisoes/${entidadeId}`).then(res => res.data)
	}
};
