import React from "react";
import {PanelContent} from "../../components/panel";
import {InputCurrency} from "../../components/inputnumber";
import {InputText} from "../../components/inputtext";
import {SimpleFileUpload} from "../../components/fileupload/SimpleFileUpload";
import {optionsStatusRessolda, restricoesButtons} from "../../service/ordemServicoService";
import {SelectButton} from "../../components/selectbutton";

export function EditarOrdemServicoTerceirizacao({ordemServico, setOrdemServico}) {

    function handleChange(event) {
        setOrdemServico(prevOrdemServico => {
            prevOrdemServico[event.name] = event.value;
            return {...prevOrdemServico};
        });
    }

    return (
        <PanelContent>
            <InputCurrency label="Valor do Serviço" col={2} name="terceirizacaoValorServico" value={ordemServico.terceirizacaoValorServico} onChange={handleChange}/>
            <InputCurrency label="Valor do Deslocamento" col={2} name="terceirizacaoValorDeslocamento" value={ordemServico.terceirizacaoValorDeslocamento} onChange={handleChange}/>
            <div className="col-6" style={{padding: 0}}>
                <label style={{width: "100%", margin: "0 .5rem"}}>Relatório do Terceiro</label>
                <SimpleFileUpload label="Relatório do Terceiro" name="terceirizacaoRelatorio" value={ordemServico.terceirizacaoRelatorio} onChange={handleChange}/>
            </div>
            <SelectButton itemTemplate={restricoesButtons} col={2} label="Status" name="terceirizacaoStatus" value={ordemServico.terceirizacaoStatus} onChange={handleChange} options={optionsStatusRessolda}/>
            <InputText col={12} multiline style={{height: "10em"}} label="Observações" name="terceirizacaoObservacoes" value={ordemServico.terceirizacaoObservacoes} onChange={handleChange}/>
        </PanelContent>
    );

}
