import React, { useEffect, useState } from "react";

import { Panel } from "primereact/panel";
import { PanelContent, PanelFooter } from "../../../components/panel";
import { Button } from "../../../components/button";
import moment from "moment";

import { headerTemplate } from "../../../utils/templates";
import { useBreakpoint } from "../../../context/BreakpointContext";
import { Calendar } from "../../../components/calendar";
import { backlogSLAService } from "../../../service/backlogSLAService";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { DataTable } from "../../../components/datatable";
import { formatDate, formatDateTime } from "../../../utils/numberFormatter";
import { formatNumber } from "chart.js/helpers";
import { ClienteAutoComplete } from "../../../components/autocomplete/ClienteAutoComplete";
import { relatorioOrdemServicoSuporteService } from "../../../service/relatorioOrdemServicoSuporteService";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { SelectButton } from "../../../components/selectbutton";

const sum = [(a, b) => a + b, 0];

export function RelatorioOrdemServicoSuporte() {
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    cliente: null,
    status: "ABERTA",
    inicio: moment().subtract(1, "week").format("YYYY-MM-DD"),
    inicioFim: moment().format("YYYY-MM-DD"),
  });
  const [geral, setGeral] = useState([{}]);
  const { breakpoint } = useBreakpoint();
  const isLargeDevice = breakpoint === "lg" || breakpoint === "md";

  const optionsFilterStatus = [
    {
      label: (
        <>
          <i className="fas fa-folder-open" /> Abertas
        </>
      ),
      value: "ABERTA",
    },
    {
      label: (
        <>
          <i className="fas fa-folder-closed" /> Fechadas
        </>
      ),
      value: "FECHADA",
    },
    {
      label: (
        <>
          <i className="fas fa-asterisk" /> Todas
        </>
      ),
      value: null,
    },
  ];

  function handleChange(event) {
    setParams({ ...params, [event.name]: event.value });
  }

  useEffect(handleProcurar, []);

  async function handleProcurar() {
    setLoading(true);
    setGeral(await relatorioOrdemServicoSuporteService.listar(params));
    setLoading(false);
  }

  async function handleBaixar() {
    setLoading(true);

    toast.promise(
      relatorioOrdemServicoSuporteService
        .csv(params)
        .then((data) => {
          saveAs(
            new Blob([data], { type: "application/csv" }),
            `Suporte${moment().format("YYYYDDMMHHmmss")}.csv`
          );
        })
        .finally(() => {
          setLoading(false);
        }),
      {
        pending: `Gerando relatório. Aguarde...`,
        error: {
          render(e) {
            setLoading(false);
            return `Falha ao procurar ordens de Ordens de Serviço - Cliente: ${e.data?.response?.data}`;
          },
        },
      }
    );
  }

  const headerColumnGroup = (
    <ColumnGroup>
      <Row>
        <Column field="Ordem de serviço" header="Ordem de Serviço" />
        <Column field="" style={{ textAlign: "center" }} header="Cliente" />
        <Column field="" style={{ textAlign: "center" }} header="Equipamento" />
        <Column field="" style={{ textAlign: "center" }} header="Peças" />
        <Column field="" style={{ textAlign: "center" }} header="Problema" />
        <Column
          field=""
          style={{ textAlign: "center" }}
          header="Encerramento SLA"
        />
        <Column field="" style={{ textAlign: "center" }} header="SLA Violado" />
      </Row>
    </ColumnGroup>
  );

  const footerColumnGroup = (
    <ColumnGroup>
      <Row>
        <Column />
        <Column colSpan={2} />
        <Column colSpan={12} />
      </Row>
    </ColumnGroup>
  );

  const templateOs = (data) => {
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center">
          <i className="fa-solid fa-hashtag text-base mr-2"></i>
          <strong className="mr-2">Número: </strong>
          <p>{data.numero}</p>
        </div>
        <div className="flex align-items-center mt-1">
          <i className="fa-solid fa-headset text-base mr-2"></i>
          <strong className="mr-2">Chamado: </strong>
          <p>{data.numeroChamado}</p>
        </div>
        <div className="flex align-items-center mt-1">
          <i className="fa-solid fa-calendar-day text-base mr-2"></i>
          <strong className="mr-2">Início: </strong>
          <p>{data.inicio ? formatDateTime(data.inicio) : ""}</p>
        </div>
        <div className="flex align-items-center mt-1">
          <i className="fa-solid fa-calendar-check text-base mr-2"></i>
          <strong className="mr-2">Fim: </strong>
          <p>{data.fim ? formatDateTime(data.fim) : ""}</p>
        </div>
        <div className="flex align-items-center mt-1">
          <i className="fa-solid fa-user text-base mr-2"></i>
          <strong className="mr-2">Responsável: </strong>
          <p>{data.responsavel}</p>
        </div>
      </div>
    );
  };

  const templateEquipamento = (data) => {
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center">
          <i className="fa-solid fa-hashtag text-base mr-2"></i>
          <strong className="mr-2">Código: </strong>
          <p>{data.codigo}</p>
        </div>
        <div className="flex align-items-center mt-1">
          <i className="fa-solid fa-headset text-base mr-2"></i>
          <strong className="mr-2">Descrição: </strong>
          <p>{data.descricao}</p>
        </div>
        <div className="flex align-items-center mt-1">
          <i className="fa-solid fa-calendar-day text-base mr-2"></i>
          <strong className="mr-2">Serial: </strong>
          <p>{data.serial}</p>
        </div>
      </div>
    );
  };

  const templateSlaViolado = (data) => {
    if (data.slaViolado) {
      return (
        <i
          title="SLA fora do prazo."
          className="fa-solid fa-circle-exclamation text-xl header-warning"
        />
      );
    } else {
      return (
        <i
          title="SLA dentro do prazo."
          className="fa-solid fa-hourglass text-xl text-green-600"
        />
      );
    }
  };

  const templatePecas = (data) => {
    return (
      <div>
        {data?.pecas?.map((peca) => (
          <div className="flex align-items-center mt-1">
            <i className="fa-solid fa-circle text-xs mr-1" />
            <p className="text-sm p-0 m-0">{peca?.produto?.descricao}</p>
          </div>
        ))}
      </div>
    );
  };

  const templateProblema = (data) => {
    return (
      <div>
        <Tooltip
          target={`.tooltip-${data.id}`}
          position="left"
          tooltipOptions={{ showDelay: 2000, hideDelay: 300 }}
        >
          <p>{data?.problemaIndicado}</p>
        </Tooltip>
        <i
          className={`
            tooltip-${data?.id} 
            fa-solid 
            fa-file 
            custom-target-icon 
            cursor-pointer 
            text-xl 
            text-gray-700 
            p-text-secondary
          `}
          data-pr-tooltip={data?.problemaIndicado}
          // data-pr-position="bottom"
          // data-pr-at="bottom+5 top"
          // data-pr-my="left center-2"
        />
      </div>
    );
  };
  return (
    <div>
      <Panel
        headerTemplate={() =>
          headerTemplate("Relatorio de Ordens de Serviço", "fa-file-lines")
        }
      >
        <PanelContent>
          <Calendar
            label="Período"
            col={2}
            name="inicio"
            value={params.inicio}
            onChange={handleChange}
          />
          <Calendar
            label="&nbsp;"
            col={2}
            name="inicioFim"
            value={params.inicioFim}
            onChange={handleChange}
          />
          <ClienteAutoComplete
            forceSelection={false}
            col={5}
            name="cliente"
            value={params.cliente}
            onChange={handleChange}
          />
          <SelectButton
            options={optionsFilterStatus}
            col={3}
            name="status"
            value={params.status}
            onChange={handleChange}
            label="Status"
          />
        </PanelContent>
        <PanelFooter>
          <Button
            disabled={loading}
            loading={loading}
            onClick={handleBaixar}
            label="Baixar"
            icon="pi pi-download"
          />
          <Button
            disabled={loading}
            loading={loading}
            onClick={handleProcurar}
            label="Procurar"
            icon="pi pi-search"
          />
        </PanelFooter>
      </Panel>
      <DataTable
        paginator={false}
        footer={null}
        headerColumnGroup={headerColumnGroup}
        footerColumnGroup={footerColumnGroup}
        value={geral}
        valuelength={geral?.length}
      >
        <Column
          field="numero"
          style={{ textAlign: "center", width: "25em" }}
          body={templateOs}
        />

        <Column
          field="cliente"
          style={{ textAlign: "center", width: "25em" }}
        />
        <Column
          style={{ textAlign: "center", width: "35em" }}
          body={templateEquipamento}
        />
        <Column
          field="pecas"
          style={{ textAlign: "center", width: "35em" }}
          body={templatePecas}
        />
        <Column
          field="problemaIndicado"
          style={{ textAlign: "center", width: "3em" }}
          body={templateProblema}
        />

        <Column
          field="previsaoFim"
          style={{ textAlign: "center", width: "7em" }}
          body={(r) => formatDateTime(r.previsaoFim)}
        />
        <Column
          field="slaViolado"
          style={{ textAlign: "center", width: "3em" }}
          body={templateSlaViolado}
        />
      </DataTable>
    </div>
  );
}
