import React, {} from 'react';
import {InputText} from "../../components/inputtext";
import { InputMask } from '../../components/inputmask';
import { DataTable } from '../../components/datatable';
import { Column } from 'primereact/column';

export function Matriz({matriz}) {

    const labelCliente = matriz?.cpfCnpj?.length === 11 ? 'CPF' : 'CPF OU CNPJ';
    const maskCliente = matriz?.cpfCnpj?.length === 11 ? '999.999.999-99' : null;

    return (
        <>
            <InputText
                col={12}
                name="clienteId"
                label="Cliente"
                value={matriz.clienteId}
                readOnly
            />
            <InputText
                col={4}
                name="razaoSocial"
                label="Razão Social"
                value={matriz.razaoSocial}
                readOnly
            />

            <InputText
                readOnly
                col={4}
                name="nomeFantasia"
                label="Nome Fantasia"
                value={matriz.nomeFantasia}
            />

            <InputMask
                unmask
                mask={matriz?.cpfCnpj?.length === 14 ? '99.999.999/9999-99' : maskCliente}
                col={4}
                name="cnpj"
                label={matriz?.cpfCnpj?.length === 14 ? 'CNPJ' : labelCliente}
                value={matriz?.cpfCnpj}
                readOnly
            />

            <InputMask 
                unmask 
                mask='99999-999' 
                col={2} 
                name="cep" 
                label="CEP" 
                value={matriz?.cep} readOnly
            />

            <InputText
                col={5}
                readOnly
                name="endereco"
                label="Endereço"
                value={matriz?.endereco}
            />
 
            <InputText
                readOnly
                col={3}
                name="municipio"
                label="Município"
                value={matriz?.municipio}
            />

            <InputText
                readOnly
                col={2}
                name="uf"
                label="UF"
                value={matriz?.uf}
            />

            <div className="col-3"> 
                <DataTable 
                    emptyMessage='Nenhum telefone encontrado.' 
                    paginator={false}
                    footer={null} 
                    value={matriz?.telefones} 
                    valuelength={matriz?.telefones?.length}
                >
                <Column header="Telefones" body={(e) => e} style={{ width: '*'}} />
                </DataTable>
            </div>
        </>
    );

}
