import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../../components/datatable";
import {Column} from "primereact/column";
import {status} from "../services/pedidoVendaService";
import {toast} from "react-toastify";
import saveAs from "file-saver";
import { listaPrecoService } from "../services/listaPrecoService";
import { IconSimpleFileUpload } from "../../../components/fileupload/IconSimpleFileUpload";
import { withDialog } from "../../../utils/dialogContext";
import { InformationDialog } from "../../../components/dialog/InformationDialog";
import { useAuth } from "../../../context/AuthContext";
export const statusIcone = (s) => {
    const value = status.filter(status => status.value === s.status)[0];
	return (
        <div className="flex justify-content-center" title={value.label}>
			<i className={`fa-solid ${value.icon} fa-lg`} style={{color: value.color}}/>
		</div>
	);
}

export const TabelaListaPreco =  withDialog(React.memo(({showDialog, value}) => {
    const {roles} = useAuth();
    
    const navigate = useNavigate();
    const [loading, setLoading] = useState(0);
    
    const [loadingDownload, setLoadingDownload] = useState([]);
    function handleEditar(event) {
        if(loading !== 0) return;
        navigate(`/listas-preco/${event.data.id}`);
    }


    async function downloadCSV(data) {
        setLoadingDownload([...loadingDownload ,`CSV-${data.id}`]);
        toast.promise(
            listaPrecoService.downloadCSV(data.id).then((result) => {                
                saveAs(new Blob([result], {type: "application/csv"}), `${"Lista de preço - "+data.codigo}.csv`);           
            }).finally(() => setLoadingDownload(loadingDownload.filter((item) => item !== `CSV-${data.id}`))),
            {
                "pending": `Gerando relatório. Aguarde...`,
                "error": {
                    render(e) {
                        return `Falha ao fazer download: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }

    async function uploadCSV(lista ,event) {
        setLoading(`CSV`);
        let criterio = {listaPrecoId: lista?.id, conteudo: event?.value?.conteudo?.conteudo};        
        toast.promise(
            listaPrecoService.uploadCSV(criterio)
            .then((res) => {
                if(res?.mensagem) {
                    showDialog(<InformationDialog 
                        header="Informação" 
                        message={res.mensagem}
                    />)
                }else{
                    navigate('/listas-preco/atualizacao/lote', { state: {listaPreco: res} })
                }
            })
            .finally(() => setLoading(0)),
            {
                "pending": `Fazendo upload do arquivo. Aguarde...`,
                "error": {
                    render(e) {
                        return `Falha ao fazer upload: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }

    const acoes = (data, infos) => {
        let botoes = [];
        botoes.push(
                <span 
                    key={5} 
                    className={`${loadingDownload.find(item => item === `CSV-${data.id}`) ? 'fas fa-spinner fa-spin ' : 'fa-solid fa-file-csv tzm-icon'} mr-3 fa-xl text-green-800`}
                    title="Download CSV" 
                    onClick={() => downloadCSV(data)}
                />
        )
        
        if(roles.LTPC)
        botoes.push( <IconSimpleFileUpload
            accept='.csv'  
            name="arquivo"  
            value={data.arquivo}  
            onChange={(e) => uploadCSV(data, e)}
            title='Fazer upload de arquivo CSV'
            loading={ loading === `CSV`} 
        />)
        return (
                <div className="flex justify-content-center align-items-center" >
                    {botoes}
                </div>
        )
    }

    return (
        <DataTable
            value={value}
            onRowDoubleClick={handleEditar}
            valuelength={value?.length}
        >
            <Column sortable field="codigo" header="Código" style={{textAlign: "left", width: "15em"}}/>
            <Column sortable field="descricao" header="Descrição" style={{textAlign: "left", width: "*"}}/>
            <Column style={{textAlign: "center"}} body={(data, infos) => acoes(data, infos)}/>

        </DataTable>
    );
}));
