import React, {useEffect} from 'react';
import {Card} from 'primereact/card';
import "./styles.scss";

export function Expired(props) {

    useEffect(() => {
        props.setStaticMenuInactive(true)
    }, [props])

    return (
        <Card className='height flex flex-column align-items-center justify-content-center'>
            <div style={{textAlign: 'center'}}>
                <i className="fa-solid fa-link-slash fa-10x"/>
                <h2 style={{marginTop: '0.50em'}}>{'O link que você está tentando acessar expirou, favor entrar em contrato com o responsável pelo orçamento.'}</h2>
            </div>
        </Card>
    );
}