import React, {useEffect, useMemo, useState} from "react";
import {useAuth} from "../../context/AuthContext";
import {usePagesAndTabs} from "../../utils/PageAndTabContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {Button} from "../../components/button";
import {If} from "../../components/conditional/If";
import {ButtonConfirmarAtribuicao} from "../__Commons/ConfirmarAtribuicao";
import {Column} from "primereact/column";
import {CardOrdemServico} from "../../components/cards/CardOrdemServico";
import {CardCliente} from "../../components/cards/CardCliente";
import {CardSLA} from "../../components/cardsla";
import {CardEquipamento} from "../../components/cards/CardEquipamento";
import {useQueryClientLaboratorio} from "../../utils/query-clients/queryClients";
import {DataTable} from "../../components/datatable";
import {disabledButtons, FiltroRecebimento, tabelaTotais} from "./utils";
import {useOrdemServicoActions} from "./OrdemServicoActionContext";

export function TabelaOrdemServico({status, value, onChangeSelecionados}) {

    const {roles} = useAuth();
    const {handleSearch} = useQueryClientLaboratorio();
    const [selecionados, setSelecionados] = useState([]);
    const {pageAndTab, setPage} = usePagesAndTabs(QueryParamsKeys.Laboratorio);
    const [aguardando, setAguardando] = useState(false);
    const {handleConclusao, handlePrateleira, handleResponsavel, handleEditar} = useOrdemServicoActions();
    const disable = disabledButtons(status, selecionados);

    const ordensServico = useMemo(() => {
        if (aguardando) {
            return value.filter(os => (status === "LAUDO" && !os.confirmadoLaudo) || (status === "EXPEDICAO" && !os.confirmadoExpedicao));
        }
        return value;
    }, [aguardando, value]);

    useEffect(() => setSelecionados([]), [ordensServico]);

    function handleChangeSelecionados(e) {
        setSelecionados(e.value);
        if (onChangeSelecionados) {
            onChangeSelecionados(e.value);
        }
    }

    const header = (
        <div>
            <div style={{float: "left"}}>
                <If condition={["LAUDO", "EXPEDICAO"].includes(status)}>
                    <FiltroRecebimento name="aguardando" value={aguardando} onChange={e => setAguardando(e.value)}/>
                </If>
                <If condition={status !== "FECHADA"}>
                    <Button disabled={disable.prateleira} icon="fas fa-inbox" label={aguardando ? "Recebido" : "Prateleira"} onClick={() => handlePrateleira(selecionados)}/>
                    <If condition={!aguardando}>
                        <Button disabled={disable.responsavel} icon="fas fa-user" label="Responsável" onClick={() => handleResponsavel(status, selecionados)}/>
                        <Button disabled={disable.concluido} icon="fas fa-check" label="Concluído" onClick={() => handleConclusao(status, selecionados)}/>
                    </If>
                </If>
                <If condition={status === "MANUTENCAO" && roles.LABG}>
                    <ButtonConfirmarAtribuicao ordensServico={ordensServico} onModalClose={handleSearch}/>
                </If>
            </div>
            {tabelaTotais(ordensServico.length, status, aguardando)}
        </div>
    );

    return (
        <DataTable
            first={20 * pageAndTab.page[status.toLowerCase()]}
            onPage={e => setPage({...pageAndTab.page, [status.toLowerCase()]: e.page})}
            rowClassName={os => ({"tabela-os": true, [os.cor?.toLowerCase()]: true})}
            header={header}
            footer={tabelaTotais(ordensServico.length, status)}
            value={ordensServico}
            onRowDoubleClick={e => handleEditar(status, e)}
            selection={selecionados}
            selectionMode="checkbox"
            onSelectionChange={handleChangeSelecionados}
        >
            {status !== "FECHADA" ? <Column style={{width: "3em"}} selectionMode="multiple"/> : null}
            <Column sortable field="inicio" header="Dados da OS" body={os => <CardOrdemServico hideOrcamento={["RECEPCAO", "EXPEDICAO"].includes(os.status)} ordemServico={os}/>}/>
            <Column sortable field="cliente.autoComplete" header="Contrato/Cliente" body={os => (
                <div>
                    <CardCliente cliente={os.cliente} ordemServico={os}/>
                    <If condition={os.contrato?.acordoNivelServico?.id && os.previsaoFim && os.inicio}>
                        <CardSLA ordemServico={os}/>
                    </If>
                </div>
            )}/>
            <Column sortable field="equipamento.autoComplete" header="Equipamento" body={os => <CardEquipamento ordemServico={os} equipamento={os.equipamento}/>}/>
        </DataTable>
    );

}
