import React, {useEffect, useRef} from "react";
import {Button} from "../button";
import {InputText} from "primereact/inputtext";
import {arquivoService} from "../../service/arquivoService";
import saveAs from "file-saver";

const commonDocExtensions = [
	"image/bmp",
	"image/png",
	"image/jpeg",
	"application/pdf",
	"application/msword",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"application/excel",
	"application/msword",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"application/vnd.ms-powerpoint",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	"application/vnd.openxmlformats-officedocument.presentationml.slideshow"
].join(",");

export function SimpleFileUpload(props) {

	const inputRef = useRef(null);

	useEffect(() => {
		inputRef.current.onloadend = (event) => {
			console.log(event);
		}
	}, []);

	function handleFileOpen() {
		if (inputRef.current) {
			inputRef.current.click();
		}
	}

	function baixarArquivo() {
		arquivoService.baixar(props.value).then(data => {
			saveAs(new Blob([data], {type: props.value?.contentType}), props.value?.fileName);
		});
	}

	function handleFileChange(event) {
		const value = event.target.value?.replace(/\\/g, "/");
		let fileName = value.substring(value.lastIndexOf("/") + 1);
		const selectedFile = inputRef.current.files[0];
		if (selectedFile) {
			const reader = new FileReader();
			reader.onloadend = (event) => {
				if (event.target.readyState === 2) {
					if (props.onChange) {
						const value = {
							fileName,
							contentType: event.target.result.split(";")[0].split(":")[1],
							conteudo: {
								conteudo: event.target.result.split(",")[1]
							}
						};
						props.onChange({name: props.name, index: props.index, value});
					}
				}
			}
			reader.readAsDataURL(selectedFile);
		}
	}

	const newLocal = props.required ? <small style={{color: props.value?.length ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : <small>&nbsp;</small>;

	function handleFileRemove() {
		props.onRemove(props.index);
	}

	if (props.buttonOnly) {
		return (
			<>
				{
					props.value
						? <><Button label={props.value.fileName} disabled={props.disabled} icon="fa-solid fa-times" onClick={handleFileRemove}/></>
						: <Button success={props.success} disabled={props.disabled} icon={props.icon || "fa-solid fa-folder-open"} onClick={handleFileOpen}/>
				}
				<input accept={props.accept || commonDocExtensions} style={{display: "none"}} ref={inputRef} type="file" onChange={handleFileChange}/>
			</>
		);
	}

	return (
		<div style={{position: "relative"}}>
			<InputText className={`${props.invalid ? 'p-invalid' : ''}`} id="username2-help" invalid={props.invalid} style={{maxWidth: `calc(100% - ${props.value?.id ? "92" : "56"}px)`, marginLeft: ".5em"}} disabled label={null} col={12} value={props.value?.fileName} onBlur={props.onBlur}/>
			<div style={{position: "absolute", right: ".25em", top: 0}}>
				{props.hide ? null : <Button disabled={props.disabled} icon="fa-solid fa-folder-open" onClick={handleFileOpen}/>}
				{props.hide || !props.value?.id ? null : <Button icon="fas fa-download" onClick={baixarArquivo}/>}
			</div>
			<input accept={props.accept || commonDocExtensions} style={{display: "none"}} ref={inputRef} type="file" onChange={handleFileChange}/>
			{props.reduced ? null : (props.invalid ? <small style={{marginLeft: '0.6rem'}} id="username2-help" className="p-error block">{props.invalid}</small> : newLocal)}
		</div>
	);

}
