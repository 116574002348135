import React, { useCallback } from "react";

import { useNavigate } from "react-router-dom";
import { Column } from "primereact/column";

import { DataTable } from "../../../../components/datatable";
import { comissaoFechamentoService } from "../../services/comissaoFechamentoService";
import saveAs from "file-saver";
import {toast} from "react-toastify";

import { formatCurrency } from "../../../../utils/numberFormatter";
import { formatMonthYear } from "../../../../utils/DateFormatter";
import moment from "moment";

export const TabelaFechamento = React.memo(({value}) => 
{
    const navigate = useNavigate();

    
    const handleEditar = useCallback((event) => {
        navigate(`/comercial/comissao/fechamento/${event.data.id}`);
    }, [navigate]);

    const acoes = (pedido) => {
        let botoes = [];
        botoes.push(<span key={5} className="fa-solid fa-file-pdf tzm-icon" title="Imprimir Fechamento Consolidado" onClick={() => fechamentoConsolidado(pedido)} />)
        return <div style={{ textAlign: "center" }}>{botoes}</div>;
    }

    const fechamentoConsolidado = (comissaoFechamento) => {
		toast.promise(
			comissaoFechamentoService.fechamentoConsolidado(comissaoFechamento.id).then(data => {
				saveAs(new Blob([data], {type: "application/pdf"}), `Fechamento.pdf`);
			}),
			{
				"pending": `Fazendo download do pdf. Aguarde...`,
				"success": `Download realizado com sucesso.`,
				"error": {
					render(e) {
						return `Falha ao realizar downlaod.`;
					}
				}
			}
		);
	}

    return (
        <DataTable value={value}
                onRowDoubleClick={handleEditar}
                valuelength={value?.length}
        >
            <Column sortable header="Período do Fechamento" field="inicio" body={r => formatMonthYear(r.inicio)}/>            
            <Column sortable header="Fechado por" field="fechadoPor" />
            <Column sortable header="Fechado em" field="fechadoEm" body={m => moment(m.fechadoEm).format("DD/MM/YYYY HH:mm")}/>
            <Column sortable header="Total Comissão" field="valorComissao" body={m => formatCurrency(m.valorComissao)}/>
            <Column sortable header="Total Faturamento" field="valorFaturamento" body={m => formatCurrency(m.valorFaturamento)}/>
            <Column style={{textAlign: "center"}} body={acoes}/>
            
        </DataTable>
    );
});