import { buildValidator, isRequired } from '../utils/fieldValidator';
import { baseService } from './baseService';

const resourceUrl = "/contratos/clausulas/tipo";

export const tiposDeClausulaService = {
    criar: () => {
        return {
            id: null,
            codigo: '',
            descricao: '',
        };
    },
    validar: (tipoDeClausula) => {
        const messages = buildValidator();
        messages.descricao = isRequired(tipoDeClausula.descricao);
        messages.codigo = isRequired(tipoDeClausula.codigo);
        return messages;
    },
    ...baseService(resourceUrl),
    async listarTiposClausula(params) {
        const query = [];
        if (params.textoDeBusca?.length) query.push(`codigo,descricao=lk=${params.textoDeBusca}`);
        return await tiposDeClausulaService.listar([`query=${query.join(";")}`]);
    }
};
