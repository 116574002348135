import {api} from "./api";

const resourceUrl = '/relatorios-servicos';

export const relatorioOperacoesService = {

    async listarPorOperador(params) {
        return await api.get(`/relatorios/operacoes/operador?${Object.keys(params).filter(k => params[k]).map(k => `${k}=${params[k]}`).join("&")}`).then(res => res.data);
    },

    async listarPorOrdem(params) {
        return await api.get(`/relatorios/operacoes/ordem?${Object.keys(params).filter(k => params[k]).map(k => `${k}=${params[k]}`).join("&")}`).then(res => res.data);
    },

    async csvOperador(criterio) {		
		return await api.get(`/relatorios/operacoes/csvOperador?${criterio.join("&")}`, {}, {responseType: "blob"}).then(response => response.data);
	},

    async csvOrdemServico(criterio) {		
		return await api.get(`/relatorios/operacoes/csvOrdemServico?${criterio.join("&")}`, {}, {responseType: "blob"}).then(response => response.data);
	},

    
}
