import { api } from "../../../service/api";
import { baseService } from "../../../service/baseService";

const resourceUrl = "/comissoes-fechamento";

export const comissaoFechamentoService = {

	fechamentoConsolidado: async id =>  {		
		return await api.get(`/relatorios-financeiro/comissao-fechamento/${id}`, {responseType: "blob"}).then(response => response.data);
	},
	buscarFechamento: async inicio => {
		return await api.get(`${resourceUrl}/buscar/${inicio}`).then(response => response.data);
	},
	...baseService(resourceUrl)
};

