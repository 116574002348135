import React, {useContext, useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {useNavigate, useParams} from "react-router-dom";
import {labelRestricao, ordemServicoService} from "../../../service/ordemServicoService";
import {BlockUI} from "primereact/blockui";
import {InputNumber} from "../../../components/inputnumber";
import {ClienteAutoComplete} from "../../../components/autocomplete/ClienteAutoComplete";
import {InputText} from "../../../components/inputtext";
import {InputCheckbox} from "../../../components/toogleinputtext";
import {ProdutoAutoComplete} from "../../../components/autocomplete/produtoAutoComplete";
import {acessorios} from "../../OrdensServico/EditarOrdemServicoItemDadosPrincipais";
import {Button} from "../../../components/button";
import {FauxInput} from "../../../components/fauxinput";
import {equipamentoService} from "../../../service/equipamentoService";
import {ordemServicoItemService} from "../../../service/ordemServicoItemService";
import {DialogContext} from "../../../utils/dialogContext";
import {If} from "../../../components/conditional/If";
import {Dropdown} from "../../../components/dropdown";
import {contratoService} from "../../../service/contratoService";
import {useAuth} from "../../../context/AuthContext";
import {OrdemServicoTimeline} from "../../../components/cards/OrdemServicoTimeline";
import {Checkbox} from "../../../components/checkbox";
import {TabPanel, TabView} from "primereact/tabview";
import {Acompanhamento} from "../../__Commons/Acompanhamento";
import {RegistrarEvento} from "../../__Commons/RegistrarEvento";
import {eventoService} from "../../../service/eventoService";
import {EditarPecas} from "../../__Commons/EditarPecas";
import {EditarServicos} from "../../__Commons/EditarServicos";
import {FotoVideo} from "../../__Commons/FotoVideo";
import {AvancarEtapa} from "../../__Commons/AvancarEtapa";
import {InformationDialog} from "../../../components/dialog/InformationDialog";
import {findRestricoesBloqueantes} from "../../../utils/ordemServicoUtils";
import moment from "moment";
import {ConcluirManutencaoEquipamentoEspecial} from "./ConcluirManutencaoEquipamentoEspecial";
import {EditarEquipamentoBackup} from "../../__Commons/EditarEquipamentoBackup";
import {InputButton} from "../../../components/inputbutton";
import {EditarOrdemServicoMovimentacaoFisica} from "../../OrdensServico/EditarOrdemServicoMovimentacaoFisica";

export function LaboratorioManutencaoEditar() {

	const navigate = useNavigate();
	const {usuario, roles} = useAuth();
	const [messages, setMessages] = useState();
	const {showDialog} = useContext(DialogContext);
	const [loading, setLoading] = useState(0);
	const [contratos, setContratos] = useState([]);
	const [ordemServico, setOrdemServico] = useState({
		...ordemServicoService.criar(),
		itens: [
			{...ordemServicoItemService.criar(), equipamento: equipamentoService.criar()},
		]
	});
	const {id} = useParams();
	const disableByOrcamento = !roles.OSSU && ordemServico.itens[0].orcamentos?.some(osio => !["RECUSADO", "CANCELADO"].includes(osio.status));

	useEffect(() => {
		if (id !== "new") {
			setLoading("Carregar");
			ordemServicoService.buscar(id).then(ordemServico => {
				if (!ordemServico.itens?.length) {
					ordemServico.itens = [
						ordemServicoItemService.criar()
					];
				}
				if (!ordemServico.itens[0].equipamento) {
					ordemServico.itens[0].equipamento = equipamentoService.criar();
				}
				setOrdemServico(ordemServico);
				setLoading(0);
			});
		}
	}, [id]);

	async function handleChange(event) {
		if (event.name === "cliente" && event.value?.id) {
			const contratos = await contratoService[ordemServico.tipo === "PROJETO" ? "listarProjetosAtivos" : "listarContratosAtivos"](event.value.id);
			setContratos(contratos.map(c => ({label: c.autoComplete, value: c})));
			if (contratos.length === 1) {
				await handleChange({name: "contrato", value: contratos[0]});
			} else {
				await handleChange({name: "contrato", value: null});
			}
		}
		setOrdemServico(prevOrdemServico => {
			return ({...prevOrdemServico, [event.name]: event.value, _alterado: true});
		});
	}

	function handleChangeItem(event) {
		setOrdemServico(prevOrdemServico => {
			prevOrdemServico.itens[0][event.name] = event.value;
			return {...prevOrdemServico, _alterado: true};
		});
	}

	function handleChangeAcessorio(event) {
		setOrdemServico(prevOrdemServico => {
			prevOrdemServico.itens[0].acessorios.filter(ia => ia.tipo === event.option).forEach(ia => ia[event.name] = event.value);
			return {...prevOrdemServico, _alterado: true};
		});
	}

	function handleChangeToggleAcessorio(event) {
		setOrdemServico(prevOrdemServico => {
			if (!prevOrdemServico.itens[0].acessorios.some(ia => ia.tipo === event.name)) {
				prevOrdemServico.itens[0].acessorios.push({tipo: event.name, identificacao: ""});
			} else {
				prevOrdemServico.itens[0].acessorios = prevOrdemServico.itens[0].acessorios.filter(ia => ia.tipo !== event.name);
			}
			return {...prevOrdemServico, _alterado: true};
		});
	}

	async function handleSalvar() {
		const messages = ordemServicoService.validarLaboratorioLaudo(ordemServico);
		setMessages(messages);
		if (!messages.isEmpty()) {
			showDialog(<InformationDialog message="Por favor, confira as mensagens de validação e tente novamente."/>);
			return;
		}
		setLoading("Salvar");
		if (ordemServico.itens[0].limpezaConcluida && !ordemServico.itens[0].limpezaConcluidaPor?.id) {
			ordemServico.itens[0].limpezaConcluidaPor = usuario;
			ordemServico.itens[0].limpezaConcluidaEm = moment().format("YYYY-MM-DDTHH:mm:ss");
		}
		if (ordemServico.itens[0].testesConcluidos && !ordemServico.itens[0].testesConcluidosPor?.id) {
			ordemServico.itens[0].testesConcluidosPor = usuario;
			ordemServico.itens[0].testesConcluidosEm = moment().format("YYYY-MM-DDTHH:mm:ss");
		}
		await ordemServicoService.salvarOrdemServico(ordemServico, usuario);
		setOrdemServico(await ordemServicoService.buscar(id));
		setLoading(0);
	}

	function validarMensagens() {
		setMessages(ordemServicoService.validarBackofficeRecepcao(ordemServico));
	}

	function handleVoltar() {
		navigate(-1);
	}

	function handleChangeFlag(event) {
		setOrdemServico(prevOrdemServico => {
			if (!event.value) {
				ordemServico.itens[0].flags = ordemServico.itens[0].flags.filter(f => event.name !== f);
			} else {
				ordemServico.itens[0].flags.push(event.name);
			}
			return {...prevOrdemServico, _alterado: true};
		});
	}

	function registrarEvento() {
		showDialog(<RegistrarEvento onModalClose={async evento => {
			const acompanhamento = ordemServico.acompanhamentos[ordemServico.acompanhamentos.length - 1];
			evento = await eventoService.salvar({...evento, postBack: true});
			await ordemServicoService.salvarAcompanhamentoEvento({eventos: evento.id, acompanhamento: acompanhamento.id});
			setOrdemServico(prevOrdemServico => {
				acompanhamento.eventos.push(evento);
				return {...prevOrdemServico, _alterado: true};
			});
		}}/>);
	}

	async function handleConclusao() {
		if (!ordemServico.responsavel?.id) {
			showDialog(<InformationDialog message="A ordem de serviço não pode avançar sem ter um responsável atribuído"/>);
			return;
		}
		const restricoes = findRestricoesBloqueantes(ordemServico);
		if (restricoes.length) {
			showDialog(<InformationDialog header="Informação" message={(
				<div>
					<div>Por favor, verifique as restrições antes de concluir esta etapa:</div>
					{restricoes.map(osr => <div className="os-restricao" key={osr}><i className="fas fa-exclamation-triangle"/> {labelRestricao[osr]}</div>)}
				</div>
			)}/>);
		} else {
			if (ordemServico.itens[0].equipamento?.produto?.equipamentoEspecial) {
				showDialog(<ConcluirManutencaoEquipamentoEspecial ordensServico={[ordemServico]} onModalClose={() => navigate("/laboratorio/laudo")}/>);
				return;
			}
			showDialog(<AvancarEtapa status="MANUTENCAO" ordensServico={[ordemServico]} onModalClose={() => navigate("/laboratorio/laudo")}/>);
		}
	}

	function editarEquipamentoBackup() {
		showDialog(<EditarEquipamentoBackup equipamentoBackup={ordemServico.itens[0].equipamentoBackup} onModalClose={backup => {
			setOrdemServico(prevOrdemServico => {
				prevOrdemServico.itens[0].equipamentoBackup = backup;
				prevOrdemServico._alterado = true;
				return {...prevOrdemServico};
			});
		}}/>);
	}

	return (
		<BlockUI blocked={loading}>
			<Panel header={`Laboratório - Manutenção: Ordem de Serviço ${ordemServico.numero || ""}`}>
				<OrdemServicoTimeline ordemServico={ordemServico}/>
				<TabView>
					<TabPanel leftIcon="fas fa-database" header="Dados Principais">
						<PanelContent>
							<InputNumber readOnly value={ordemServico.numero} col={1} label="Nº"/>
							<ClienteAutoComplete onBlur={validarMensagens} invalid={messages?.cliente} required readOnly={ordemServico.id} col={5} value={ordemServico.cliente} name="cliente" onChange={handleChange}/>
							<If condition={ordemServico.id} children={<FauxInput readOnly label="Contrato" col={2} value={ordemServico.contrato?.numero || <span style={{color: "#F44336"}}><i className="fas fa-file-circle-xmark"/> Avulso</span>}/>}/>
							<If condition={!ordemServico.id} children={<Dropdown options={contratos} label="Contrato" col={2} name="contrato" value={ordemServico.contrato} onChange={handleChange}/>}/>
							<FauxInput readOnly label="SLA" col={2} value={ordemServico.contrato?.acordoNivelServico?.descricao || <span style={{color: "#F44336"}}><i className="fas fa-file-circle-xmark"/> Sem SLA definido</span>}/>
							<InputText col={2} readOnly label="Prazo para Conclusão (SLA)" value={ordemServico.previsaoFim && moment(ordemServico.previsaoFim).format("DD/MM/YYYY HH:mm")}/>
							<InputText col={2} name="numeroChamado" label="Nº do Chamado" value={ordemServico.numeroChamado} onChange={handleChange}/>
							<InputText col={2} name="numeroPedidoCliente" label="Nº do Pedido do Cliente" value={ordemServico.numeroPedidoCliente} onChange={handleChange}/>
							<InputText col={2} name="numeroPedidoCompra" label="Nº do Pedido de Compra" value={ordemServico.numeroPedidoCompra} onChange={handleChange}/>
							<InputText col={3} name="solicitanteNome" label="Nome do Solicitante" value={ordemServico.solicitanteNome} onChange={handleChange}/>
							<InputText col={3} name="responsavelInstrumentos" label="Responsável pelo Equipamento" value={ordemServico.responsavelInstrumentos} onChange={handleChange}/>
							<InputText readOnly value={ordemServico.itens[0].equipamento.serial} col={3} label="Nº de Série"/>
							<ProdutoAutoComplete readOnly label="Modelo do Equipamento" col={4} value={ordemServico.itens[0].equipamento.produto}/>
							<InputText col={2} label="Nº do Ativo" value={ordemServico.itens[0].equipamento.numeroAtivo}/>
							<InputText col={3} label="Identificação" value={ordemServico.itens[0].equipamento.descricao}/>
							<label style={{display: "block", width: "100%", marginLeft: "6px"}}>Acessórios</label>
							{
								acessorios.map(a => (
									<InputCheckbox placeholder="Nº de Identificação"
									               col={a.col || 3}
									               label={a.label}
									               inputName="identificacao"
									               inputValue={ordemServico.itens[0].acessorios.filter(ia => ia.tipo === a.name)[0]?.identificacao}
									               onInputChange={handleChangeAcessorio}
									               checkboxName={a.name}
									               checkboxValue={ordemServico.itens[0].acessorios.some(osia => osia.tipo === a.name)}
									               onCheckboxChange={handleChangeToggleAcessorio}
									/>
								))
							}
							<div style={{height: "15px", width: "100%"}}/>
							<InputText col={7} name="complemento" label="Complemento (Detalhamento do Equipamento)" value={ordemServico.itens[0].complemento} onChange={handleChangeItem}/>
							<InputButton readOnly buttonLabel="Alterar" buttonIcon="fas fa-edit" col={5} label="Equipamento Backup" value={ordemServico.itens[0].equipamentoBackup?.equipamento?.autoComplete || ""} onButtonClick={editarEquipamentoBackup}/>
							<InputText multiline style={{height: "10em"}} col={12} maxLength={3800} name="problemaIndicado" label="Problema Indicado" value={ordemServico.itens[0].problemaIndicado} onChange={handleChangeItem}/>
							<InputText multiline style={{height: "10em"}} col={12} maxLength={3800} name="laudoExterno" label="Laudo Externo" value={ordemServico.itens[0].laudoExterno} onChange={handleChangeItem}/>
							<InputText multiline style={{height: "13em"}} col={12} maxLength={3800} name="laudoInterno" label="Laudo Interno" value={ordemServico.itens[0].laudoInterno} onChange={handleChangeItem}/>
							<Checkbox col={2} label="Perda Total" name="PERDA_TOTAL" value={ordemServico.itens[0].flags.includes("PERDA_TOTAL")} onChange={handleChangeFlag}/>
							<Checkbox col={2} label="Local Diferente" name="LOCAL_DIFERENTE" value={ordemServico.itens[0].flags.includes("LOCAL_DIFERENTE")} onChange={handleChangeFlag}/>
							<Checkbox title={disableByOrcamento ? "Este campo fica desabilitado após aprovação de orçamento" : null} readOnly={disableByOrcamento} col={2} label="Mau Uso" value={ordemServico.itens[0].flags.includes("MAU_USO")}/>
							<Checkbox col={2} label="Equipamento Não Encontrado" name="EQUIPAMENTO_NAO_ENCONTRADO" value={ordemServico.itens[0].flags.includes("EQUIPAMENTO_NAO_ENCONTRADO")} onChange={handleChangeFlag}/>
							<If condition={ordemServico.itens[0].equipamento?.produto?.equipamentoEspecial}>
								<Checkbox col={2} label="Limpeza Concluída" name="limpezaConcluida" value={ordemServico.itens[0].limpezaConcluida} onChange={handleChangeItem}/>
								<Checkbox col={2} label="Testes Concluídos" name="testesConcluidos" value={ordemServico.itens[0].testesConcluidos} onChange={handleChangeItem}/>
							</If>
						</PanelContent>
					</TabPanel>
					<TabPanel leftIcon="fas fa-boxes" header="Peças">
						<PanelContent>
							<EditarPecas messages={messages} ordemServico={ordemServico} setOrdemServico={setOrdemServico}/>
						</PanelContent>
					</TabPanel>
					<TabPanel leftIcon="fas fa-screwdriver-wrench" header="Serviços">
						<PanelContent>
							<EditarServicos messages={messages} ordemServico={ordemServico} setOrdemServico={setOrdemServico}/>
						</PanelContent>
					</TabPanel>
					<TabPanel disabled={!ordemServico.id} leftIcon="fas fa-camera" header="Fotos & Vídeos">
						<FotoVideo ordemServico={ordemServico} setOrdemServico={setOrdemServico}/>
					</TabPanel>
					<TabPanel disabled={!ordemServico.id} leftIcon="fas fa-clock" header="Histórico">
						<Acompanhamento ordemServico={ordemServico}/>
					</TabPanel>
					<TabPanel header="Movimentação Física" leftIcon="fa-solid fa-luggage-cart">
						<EditarOrdemServicoMovimentacaoFisica item={ordemServico.itens[0]}/>
					</TabPanel>
				</TabView>
				<PanelFooter>
					<If condition={ordemServico.id} children={<Button label="Registrar Evento" warning icon="fa-solid fa-file-lines" onClick={registrarEvento}/>}/>
					<If condition={ordemServico.id} children={<i className="fa-solid fa-circle" style={{lineHeight: "36px", color: "rgba(0, 0, 0, .1)", margin: "0 1em"}}/>}/>
					<If condition={ordemServico.id} children={<Button disabled={!ordemServico.responsavel?.id || (!roles.LABB && !roles.LABG) || ordemServico._alterado || (ordemServico.responsavel?.id !== usuario.id && !roles.OSSV)} label="Concluído" icon="fa-solid fa-check" onClick={handleConclusao}/>}/>
					<If condition={roles.OSSC} children={<Button disabled={!ordemServico.responsavel?.id || (!roles.LABB && !roles.LABG) || !ordemServico._alterado || (ordemServico.responsavel?.id !== usuario.id && !roles.OSSV)} loading={loading === "Salvar"} icon="fas fa-save" success label="Salvar" onClick={handleSalvar}/>}/>
					<Button icon="fas fa-arrow-left" secondary label="Voltar" onClick={handleVoltar}/>
				</PanelFooter>
			</Panel>
		</BlockUI>
	);

}
