import React, {useEffect, useRef} from "react";
import {Button} from "../button";
import {InputText} from "primereact/inputtext";
import {arquivoService} from "../../service/arquivoService";
import saveAs from "file-saver";

const commonDocExtensions = [
	"image/bmp",
	"image/png",
	"image/jpeg",
	"application/pdf",
	"application/msword",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"application/excel",
	"application/msword",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"application/vnd.ms-powerpoint",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	"application/vnd.openxmlformats-officedocument.presentationml.slideshow"
].join(",");

export function IconSimpleFileUpload(props) {

	const inputRef = useRef(null);

	useEffect(() => {
		inputRef.current.onloadend = (event) => {
			console.log(event);
		}
	}, []);

	function handleFileOpen() {
        if(props.loading) return;
		if (inputRef.current) {
			inputRef.current.click();
		}
	}

	function baixarArquivo() {
		arquivoService.baixar(props.value).then(data => {
			saveAs(new Blob([data], {type: props.value?.contentType}), props.value?.fileName);
		});
	}

	function handleFileChange(event) {
		const value = event.target.value?.replace(/\\/g, "/");
		let fileName = value.substring(value.lastIndexOf("/") + 1);
		const selectedFile = inputRef.current.files[0];
		if (selectedFile) {
			const reader = new FileReader();
			reader.onloadend = (event) => {
				if (event.target.readyState === 2) {
					if (props.onChange) {
						const value = {
							fileName,
							contentType: event.target.result.split(";")[0].split(":")[1],
							conteudo: {
								conteudo: event.target.result.split(",")[1]
							}
						};
						props.onChange({name: props.name, index: props.index, value});
					}
				}
			}
			reader.readAsDataURL(selectedFile);
		}
	}

	const newLocal = props.required ? <small style={{color: props.value?.length ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : <small>&nbsp;</small>;

	return (
		<div className={`${props.loading ? '' : 'cursor-pointer'}`} title={props?.title}>
            <span 
                className={`${props?.loading ? 'fas fa-spinner fa-spin ' : 'fa-solid fa-file-arrow-up tzm-icon'} ${props?.sizeIcon ? 'fa-'+props?.sizeIcon : 'fa-xl'} text-primary`}  
                onClick={handleFileOpen}
            />
			<input 
                accept={props.accept || commonDocExtensions} 
                style={{display: "none"}} 
                ref={inputRef} 
                type="file" 
                onChange={handleFileChange}
            />
		</div>
	);

}
