import React, { useCallback } from "react";
import { MultiSelect } from ".";
import { tiposOrcamento } from "../../pages/Comercial/services/pedidoVendaService";


export function PedidoTipoOrcamentoMultiSelect(props) {

	const pedidoTipoOrcamentoOptionsTemplate = useCallback((option) => {
		return (
            <div className="flex align-items-center">
				<i style={{color: `${option.color}`}} className={`fa-lg	 fa-solid ${option.icon}`}/>
                <div className="ml-1">{option.label}</div>
            </div>
        );
	}, []);


	return <MultiSelect 
		label="Tipo de Orçamento" 
		showSelectAll={false} 
		options={tiposOrcamento} 
		itemTemplate={pedidoTipoOrcamentoOptionsTemplate}		
		optionLabel={'label'}
		placeholderSelecionar=" tipo de orçamento"
		display="chip"
		{...props}
	/>;
}