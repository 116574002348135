import React, { useCallback } from 'react';
import {InputText} from "../../components/inputtext";
import { InputMask } from '../../components/inputmask';
import {Column} from "primereact/column";
import { DataTable } from '../../components/datatable';
import { ListaPrecoAutoComplete } from '../../components/autocomplete/ListaPrecoAutoComplete';
import {VendedorAutoComplete} from "../../components/autocomplete/VendedorAutoComplete";

export function EditarDadosPrincipais({cliente, setCliente, messages, setMessages}) {

    const handleChange = useCallback((event) => {
		setCliente({...cliente, [event.name]: event.value});
	}, [cliente, setCliente]);

    const labelCliente = cliente?.cpfCnpj?.length === 11 ? 'CPF' : 'CPF OU CNPJ';
    const maskCliente = cliente?.cpfCnpj?.length === 11 ? '999.999.999-99' : null;

    return (
        <>
            <InputText 
                col={12} 
                name="clienteId" 
                label="Cliente" 
                value={cliente?.vwCliente?.clienteId} readOnly/>
            <InputText col={4} name="razaoSocial" label="Razão Social" value={cliente?.razaoSocial} readOnly/>
            <InputText readOnly col={4} name="nomeFantasia" label="Nome Fantasia" value={cliente?.nomeFantasia}/>
            <InputMask
                unmask
                mask={cliente?.cpfCnpj?.length === 14 ? '99.999.999/9999-99' : maskCliente}
                col={2}
                name="cnpj"
                label={cliente?.cpfCnpj?.length === 14 ? 'CNPJ' : labelCliente}
                value={cliente?.cpfCnpj}
                readOnly
            />
            <InputText readOnly col={2} name="inscricaoEstadual" label="Inscrição Estadual" value={cliente?.vwCliente?.inscricaoEstadual}/>                        
            <InputMask unmask mask='99999-999' col={2} name="cep" label="CEP" value={cliente?.vwCliente?.cep} readOnly/>
            <InputText col={5} readOnly name="logradouro" label="Endereço" value={cliente?.vwCliente?.endereco}/>
            <InputText readOnly col={3} name="municipio" label="Município" value={cliente?.vwCliente?.municipio}/>
            <InputText readOnly col={2} name="uf" label="UF" value={cliente?.vwCliente?.uf}/>                        
            <InputText readOnly col={4} name="condicaoPagamento" label="Condição de Pagamento" value={cliente?.vwCliente?.condicaoPagamento?.autoComplete}/>                        
            <ListaPrecoAutoComplete dropdown col={4} value={cliente.listaPreco} name="listaPreco" onChange={handleChange} invalid={messages.vendedor}/>
            <VendedorAutoComplete readOnly col={4} value={cliente?.vwCliente?.vendedor} name="vendedor" label="Vendedor"/>
            <div className="col-3"> 
                <DataTable 
                    emptyMessage='Nenhum telefone encontrado.' 
                    paginator={false}
                    footer={null} 
                    value={cliente?.vwCliente?.telefones} 
                    valuelength={cliente?.vwCliente?.telefones?.length}
                >
                    <Column field='id' header="Telefones" body={(e) => e} style={{ width: '*'}} />
                </DataTable>
            </div>
            <div className="col-9"/>            
        </> 
    );
}
