import React from "react";
import {Dropdown} from "../dropdown";

const optionsStatusAgendamento = [
    {label: <><i className="fas fa-calendar-check"/> Agendadas</>, value: "AGENDADO"},
    {label: <><i className="fas fa-calendar-xmark"/> Sem Agendamento</>, value: "SEM_AGENDAMENTO"},
    {label: <><i className="fas fa-asterisk"/> Todos</>, value: ""},
];

export function StatusAgendamentoSelectButton(props) {

    return <Dropdown label="Status Agendamento" options={optionsStatusAgendamento} {...props}/>;

}
