import React, {useContext, useEffect, useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "../../components/button";
import {ordemServicoService} from "../../service/ordemServicoService";
import {useAuth} from "../../context/AuthContext";
import {ProgressDialog} from "../../components/progressdialog/ProgressDialog";
import {DialogContext} from "../../utils/dialogContext";

export function ConfirmarAtribuicao({ordensServico, onModalClose}) {

    const {usuario} = useAuth();
    const {showDialog} = useContext(DialogContext);
    const [visible, setVisible] = useState(true);
    const [selection, setSelection] = useState([]);

    function handleClose() {
        setVisible(false);
    }

    function handleConfirmar() {
        showDialog(<ProgressDialog onProgress={async setProgress => {
            let progresso = 0;
            for (const ordemServico of selection) {
                await ordemServicoService.reatribuir({ordemServico: ordemServico.id, operador: usuario, responsavel: ordemServico.solicitante}, false);
                setProgress(++progresso / selection.length);
            }
            if (onModalClose) {
                await onModalClose();
            }
        }}/>);
        handleClose();
    }

    async function handleRecusar() {
        let progresso = 0;
        showDialog(<ProgressDialog onProgress={async setProgress => {
            for (const ordemServico of selection) {
                await ordemServicoService.recusarReatribuicao(ordemServico.id);
                setProgress(++progresso / selection.length);
            }
            if (onModalClose) {
                await onModalClose();
            }
        }}/>);
        handleClose();
    }

    return (
        <Dialog header="Confirmar Atribuição de Ordem de Serviço" visible={visible} onHide={handleClose} style={{width: "950px"}}>
            <PanelContent>
                <div className="col-12">
                    <DataTable paginator rows={10} value={ordensServico} selectionMode="checkbox" selection={selection} onSelectionChange={e => setSelection(e.value)}>
                        <Column selectionMode="multiple"/>
                        <Column style={{width: "10em"}} field="numero" header="Nº da OS"/>
                        <Column field="equipamento.autoComplete" header="Equipamento"/>
                        <Column style={{minWidth: "20em"}} field="solicitante.nome" header="Responsável"/>
                    </DataTable>
                </div>
            </PanelContent>
            <DialogFooter>
                <Button icon="fas fa-check" success label="Confirmar" onClick={handleConfirmar}/>
                <Button icon="fas fa-cancel" danger label="Recusar" onClick={handleRecusar}/>
                <Button icon="fas fa-times" secondary label="Fechar" onClick={handleClose}/>
            </DialogFooter>
        </Dialog>
    )

}

export function ButtonConfirmarAtribuicao({ordensServico, onModalClose}) {

    const {showDialog} = useContext(DialogContext);
    const [loading, setLoading] = useState(0);
    const [countOrdensServico, setCountOrdensServico] = useState(0);

    useEffect(() => {
        contarPendencias().then();
    }, [ordensServico]);

    async function contarPendencias() {
        setCountOrdensServico(await ordemServicoService.contarAtribuicoesPendentes());
    }

    async function confirmarAtribuicoes() {
        setLoading("Confirmar");
        const ordensServico = await ordemServicoService.listar(["solicitadas=true"]);
        showDialog(<ConfirmarAtribuicao ordensServico={ordensServico} onModalClose={onModalClose}/>);
        setLoading(0);
    }

    return (
        <Button loading={loading === "Confirmar"} disabled={!countOrdensServico} icon="fas fa-users" label={(
            <span className="balloon-attr-pend">
                <i>{countOrdensServico}</i>Confirmar Atribuições
            </span>
        )} onClick={confirmarAtribuicoes}/>
    );

}
