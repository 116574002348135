import {buildValidator} from "../utils/fieldValidator";
import {api, apiAnonymous} from "./api";
import {baseService} from "./baseService";

const resourceUrl = "/arquivos";

export const arquivoService = {
    criar: () => {
        return {};
    },
    validar: arquivo => {
        return buildValidator();
    },
    verificarPath: async (path) => {
        return api.get(`${resourceUrl}/verificar-path`, {
            params: {path: path}
        }).then(res => res.data);
    },
    async baixar(arquivo) {
        return await apiAnonymous.get(`/arquivos/download/${arquivo.id}`, {responseType: "blob"}).then(response => response.data);
    },
    ...baseService(resourceUrl)
};
