import React, {useEffect, useState} from "react";
import {Dialog} from "../dialog";
import {ProgressBar} from "primereact/progressbar";

export function ProgressDialog({mode, onProgress, onFinish}) {

    const [progresso, setProgresso] = useState(0);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        if (onProgress) {
            onProgress(setProgresso);
        }
    }, [onProgress]);

    useEffect(() => {
        if (progresso >= 1) {
            if (onFinish) {
                onFinish();
            }
            setVisible(false);
        }
    }, [progresso]);

    return (
        <Dialog closable={false} visible={visible} style={{width: "450px"}} header="Processando">
            <div>Processando itens. Por favor, aguarde...</div>
            <div style={{fontSize: ".8em", fontStyle: "italic", margin: "1em 0"}}>
                Esta operação pode levar algum tempo. Por favor, mantenha esta janela aberta.
            </div>
            <ProgressBar mode={mode || "determinate"} showValue={false} value={progresso * 100}/>
        </Dialog>
    );

}
