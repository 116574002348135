import React, {useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {InputNumber} from "../../components/inputnumber";
import {ansService} from "../../service/ansService";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import {BlockUI} from "primereact/blockui";

export function FaixasANS() {

    const [setupSLA, setSetupSLA] = useState({
        faixaVerde: 0,
        faixaAmarela: 0,
        faixaVermelha: 0
    });

    const [blocked, setBlocked] = useState(true);

    useEffect(() => {
        ansService.configuracoes().then(setupSLA => {
            setSetupSLA(setupSLA);
            setBlocked(false);
        });
    }, []);

    function handleChange(event) {
        setSetupSLA(prevSetupSLA => ({...prevSetupSLA, [event.name]: event.value}));
    }

    function handleSalvar() {
        setBlocked(true);
        ansService.salvarConfiguracoes({...setupSLA, postBack: true}).then(setupSLA => {
            setSetupSLA(setupSLA);
            setBlocked(false);
        });
    }

    return (
        <BlockUI blocked={blocked}>
            <Panel header="Faixas de SLA">
                <PanelContent>
                    <div className="col-12" style={{whiteSpace: "nowrap", overflowX: "hidden"}}>
                        <div className="bars-sla" style={{backgroundColor: "#4CAF50", width: `${setupSLA.faixaVerde}%`}}/>
                        <div className="bars-sla" style={{backgroundColor: "#FF9800", width: `${setupSLA.faixaAmarela - setupSLA.faixaVerde}%`}}/>
                        <div className="bars-sla" style={{backgroundColor: "#F44336", width: `${setupSLA.faixaVermelha - setupSLA.faixaAmarela}%`}}/>
                        <div className="bars-sla" style={{backgroundColor: "#222222", width: `${100 - setupSLA.faixaVermelha}%`}}/>
                        <div className="bars-sla-ruler"/>
                    </div>
                    <div className="col-1"/>
                    <InputNumber min={0} max={setupSLA.faixaAmarela} suffix="%" label="Limite da Faixa Verde" name="faixaVerde" value={setupSLA.faixaVerde} onChange={handleChange} col={2}/>
                    <div className="col-2"/>
                    <InputNumber min={setupSLA.faixaVerde} max={setupSLA.faixaVermelha} suffix="%" label="Limite da Faixa Amarela" name="faixaAmarela" value={setupSLA.faixaAmarela} onChange={handleChange} col={2}/>
                    <div className="col-2"/>
                    <InputNumber min={setupSLA.faixaAmarela} max={100} suffix="%" label="Limite da Faixa Vermelha" name="faixaVermelha" value={setupSLA.faixaVermelha} onChange={handleChange} col={2}/>
                    <div className="col-1"/>
                </PanelContent>
                <PanelFooter>
                    <Button icon="fas fa-save" success label="Salvar" onClick={handleSalvar}/>
                </PanelFooter>
            </Panel>
        </BlockUI>
    );

}
