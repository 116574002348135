import React from "react"

import {Dropdown} from "./index";
import {optionsOrcamentoStatus} from "../../service/ordemServicoService";

export function OrcamentoStatusDropdown(props) {

	return <Dropdown name="status" label="Status do Orçamento" dataKey="id" options={[{label: "<Qualquer>", value: null}, ...optionsOrcamentoStatus]} optionLabel="label"  {...props}/>;

}
