import React, {useMemo} from 'react';

import {TabelaContaEmail} from "./TabelaContaEmail";
import {useAuth} from "../../context/AuthContext";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {Panel} from "primereact/panel";
import {BlockUI} from "primereact/blockui";
import {useQueryClientContaEmail} from "../../utils/query-clients/queryClients";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {SearchButton} from "../../components/button/SearchButton";
import {useNavigate} from "react-router-dom";
import {If} from "../../components/conditional/If";

export function ContasEmail() {

    const {roles} = useAuth();
    const navigate = useNavigate();
    const {query, handleSearch} = useQueryClientContaEmail();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.ContaEmail);

    function handleCriar() {
        navigate("/contas-email/new");
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel headerTemplate={() => headerTemplate("Conta de e-mail", 'fa-mail')}>
                <PanelContent>
                    <InputText placeholder="a descrição da conta de e-mail" col={4} label="Descrição" value={params.descricao} name="descricao" onChange={handleChange}/>
                    <InputText placeholder="o nome de usuário da conta de e-mail" col={4} label="Usuário" value={params.usuario} name="usuario" onChange={handleChange}/>
                    <InputText placeholder="o endereço do servidor da conta de e-mail" col={4} label="Servidor" value={params.host} name="host" onChange={handleChange}/>
                </PanelContent>
                <PanelFooter>
                    <If condition={!!roles.EMLA}>
                        <Button success="true" onClick={handleCriar} label="Novo" icon="pi pi-plus"/>
                    </If>
                    <SearchButton query={query} onSearch={handleSearch}/>
                </PanelFooter>
            </Panel>
            {useMemo(() => <TabelaContaEmail value={query.data || []}/>, [query.data])}
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
