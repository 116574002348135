import React from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../../components/datatable";
import {useAuth} from "../../../context/AuthContext";
import {Column} from "primereact/column";
import { status } from "../services/pedidoVendaService";

export const statusIcone = (s) => {
	const value = status.filter(status => status.value === s.status)[0];
	return (
		<div className="flex justify-content-center" title={value.label}>
			<i className={`fa-solid ${value.icon} fa-lg`} style={{color: value.color}}/>
		</div>
	);
}

export const TabelaIcms = React.memo(({value}) => {
   
    const navigate = useNavigate();
    const {roles} = useAuth()

    function handleEditar(event) {
        if(!roles.ICMC) return;
        navigate(`/icms/${event.data.id}`);
    }

    return (
        <DataTable
            value={value}
            onRowDoubleClick={handleEditar}
            valuelength={value?.length}
        >
            <Column sortable field="origem" header="Origem" style={{textAlign: "left", width: "*"}}/>
            <Column sortable field="destino" header="Destino" style={{textAlign: "left", width: "*"}}/>
            <Column sortable field="aliquota" header="Alíquota" style={{textAlign: "left", width: "15em"}}/>
        </DataTable>
    );

});
