import React, {useEffect, useMemo, useState} from "react";
import {Panel} from "primereact/panel";
import {BlockUI} from "primereact/blockui";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {DataTable} from "../../../components/datatable";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {contratoService} from "../../../service/contratoService";
import {Column} from "primereact/column";
import {Button} from "../../../components/button";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {ClienteAutoComplete} from "../../../components/autocomplete/ClienteAutoComplete";
import {InputText} from "../../../components/inputtext";
import {InputNumber} from "../../../components/inputnumber";
import {toQueryString} from "../../../utils/stringUtils";
import {useQueryParams} from "../../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../../utils/defaultQueryParams";
import {useQueryClientProjeto} from "../../../utils/query-clients/queryClients";
import {RefetchIndicator} from "../../../components/refetchindicator/RefetchIndicator";
import {SearchButton} from "../../../components/button/SearchButton";

export function Projetos() {

    const navigate = useNavigate();
    const {query, handleSearch} = useQueryClientProjeto();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.Projeto);

    function handleNew() {
        navigate("/projetos/new");
    }

    function handleEditar(event) {
        navigate(`/projetos/${event.data.id}`);
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel header="Projetos">
                <PanelContent>
                    <InputNumber col={2} name="numero" value={params.numero} onChange={handleChange} label="Nº"/>
                    <ClienteAutoComplete col={6} forceSelection={false} name="cliente" value={params.cliente} onChange={handleChange}/>
                    <InputText col={4} name="objeto" value={params.objeto} onChange={handleChange} label="Descrição"/>
                </PanelContent>
                <PanelFooter>
                    <Button icon="fas fa-plus" success label="Novo" onClick={handleNew}/>
                    <SearchButton query={query} onSearch={handleSearch}/>
                </PanelFooter>
            </Panel>
            <DataTable value={useMemo(() => query.data || [], [query.data])} onRowDoubleClick={handleEditar}>
                <Column sortable header="Nº" field="numero"/>
                <Column sortable header="Cliente" field="cliente.autoComplete"/>
                <Column sortable header="Descrição" field="objeto"/>
                <Column sortable style={{width: "8em", textAlign: "center"}} header="Início" field="inicio" body={p => p.inicio && moment(p.inicio).format("DD/MM/YYYY")}/>
                <Column sortable style={{width: "8em", textAlign: "center"}} header="Fim" field="fim" body={p => p.fim && moment(p.fim).format("DD/MM/YYYY")}/>
            </DataTable>
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
