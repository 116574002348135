import {baseService} from './baseService';
import {api} from "./api";

const resourceUrl = '/vendedores'

export const vendedorService = {

    ...baseService(resourceUrl),

    async findByUsuario() {
        return await api.get("/vendedores/eu").then(response => response.data);
    }

};
