import React from "react";

import { ToggleButton as PToggleButton} from 'primereact/togglebutton';

import { calcularTamanho } from "../../service/guiHelper";

export function ToggleButton(props) {

    const classes = calcularTamanho(props.col);

    function handleChange(event) {
        // return props.onChange && props.onChange({name: props.name, index: props.index, value: event.value});
		return props.onChange?.({name: props.name, index: props.index, value: !props.value});
    }

	return (
		<div className={classes}>
			{props.label ? <label>{props.label}</label> : null}
			<PToggleButton checked={props.value} {...props} onChange={handleChange}/>
		</div>
	);

}
