import {
  buildValidator,
  isBiggerThanZero,
  isCEP,
  isCNPJ,
  isEntityRequired,
  isRequired,
  isUF,
} from "../utils/fieldValidator";
import { api } from "./api";
import { baseService } from "./baseService";
import { toast } from "react-toastify";

const resourceUrl = "/empresas";

export const empresaService = {
  criar: () => {
    return {
      id: null,
      cliente: null,
      cnpj: null,
      razaoSocial: null,
      nomeFantasia: null,
      cep: null,
      uf: null,
      cidade: null,
      endereco: null,
      bairro: null,
      numero: null,
      telefone: null,
      logotipo: null,
      acessoPortal: false,
      tipoEmpresa: "FILIAL",
      status: "ATIVO",
    };
  },
  validar: (empresa) => {
    const messages = buildValidator();
    messages.razaoSocial = isRequired(empresa.razaoSocial);
    messages.nomeFantasia = isRequired(empresa.nomeFantasia);
    messages.cnpj = isCNPJ(empresa.cnpj);
    messages.cep = isCEP(empresa.cep);
    messages.bairro = isRequired(empresa.bairro);
    messages.cidade = isRequired(empresa.cidade);
    messages.uf = isUF(empresa.uf);
    messages.endereco = isRequired(empresa.endereco);
    messages.logotipo = isRequired(empresa.logotipo?.fileName);

    return messages;
  },
  ...baseService(resourceUrl),
  async listarEmpresas(params) {
    const query = [];
    if (params.textoDeBusca?.length)
      query.push(`textoDeBusca=${params.textoDeBusca}`);
    if (!params.visualizarDesativados) {
      query.push("status=ATIVO");
    }
    if (params.tipoEmpresa) {
      query.push(`tipoEmpresa=${params.tipoEmpresa}`);
    }
    if (!query[0]) {
      query.push("");
    }
    return await empresaService.listar(query);
  },

  async buscarPorCnpj(cnpj) {
    return await api
      .get(`${resourceUrl}/buscar-cnpj/${cnpj}`)
      .then((response) => response.data);
  },

  async listarFiliais(id) {
    const query = [];
    query.push(`matriz=${id}`);
    query.push("status=ATIVO");

    if (!query[0]) {
      query.push("");
    }
    return await api
      .get(`${resourceUrl + "/listar-filiais"}?${query.join("&")}`)
      .then((response) => response.data);
  },
};
