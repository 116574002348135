import React, {useEffect, useMemo, useState} from 'react';

import { useParams } from 'react-router-dom';
import { TabPanel } from 'primereact/tabview';
import { BlockUI } from 'primereact/blockui';

import { TabelaEditarFechamento } from './TabelaEditarFechamento';
import { FormTab } from '../../../../components/formtab';
import { PanelContent } from '../../../../components/panel';
import { comissaoFechamentoService } from '../../services/comissaoFechamentoService';

export function EditarFechamento() {
    const [fechamento, setFechamento] = useState([]);
    const [tiposComissao, setTiposComissao] = useState([]);
    const [loading, setLoading] = useState(false);
    const {id} = useParams();

    useEffect(() => {
		if (id !== "new") {
            setLoading(true);
			comissaoFechamentoService.buscar(id).then((a) => {
                console.log(a)
                setFechamento(a)
            }).finally(() => {
                setLoading(false);
            });
		}
	}, [id]);

    return (
        <BlockUI blocked={loading}>
            <FormTab
                descricao='Fechamento'
                podeDeletar={false}
                podeSalvar={false}
                service={comissaoFechamentoService}
                value={fechamento}
                salvar={null}
                semHistorico
            >
                <TabPanel header="Dados Principais" leftIcon='fa-solid fa-database'> 
                    <PanelContent>
                        <TabelaEditarFechamento
                            tiposComissao={useMemo(() => tiposComissao, [tiposComissao])} 
                            setFechamento={useMemo(() => setFechamento, [])} 
                            fechamento={useMemo(() => fechamento, [fechamento])}
                        />
                    </PanelContent>
                </TabPanel>
            </FormTab>
        </BlockUI>
    );
}