import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {avaliacaoService} from "../../../service/avaliacaoService";
import {BlockUI} from "primereact/blockui";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {Button} from "../../../components/button";
import {InputText} from "../../../components/inputtext";
import {useNavigate} from "react-router-dom";
import {useDialog} from "../../../utils/dialogContext";
import {ConfirmDialogV2} from "../../../components/confirmdialog";
import {If} from "../../../components/conditional/If";
import {useAuth} from "../../../context/AuthContext";

export function EditarOrdemServicoAvaliacaoQuestao() {
	const {id} = useParams();
	const {roles} = useAuth();
	const navigate = useNavigate();
	const {showDialog} = useDialog();
	const [loading, setLoading] = useState(0);
 	const [questao, setQuestao] = useState(avaliacaoService.criarQuestao());
	useEffect(() => {
		if (id !== "new") {
			avaliacaoService.buscarQuestao(id).then(setQuestao);
		}
	}, [id]);
	function handleChange(e) {
		setQuestao(prevQuestao => ({...prevQuestao, [e.name]: e.value}));
	}
	function handleVoltar() {
		navigate(-1);
	}
	async function handleSalvar() {
		setLoading(3);
		await avaliacaoService.salvarQuestao(questao).then(handleVoltar);
	}
	function handleExcluir() {
		showDialog(
			<ConfirmDialogV2 message="Tem certeza de que deseja desativar essa questão?" onYes={async () => {
				setLoading(1);
				await avaliacaoService.excluirQuestao(questao);
				handleVoltar();
			}}/>
		);
	}
	async function handleRestaurar() {
		setLoading(2);
		await avaliacaoService.restaurarQuestao(questao);
		handleVoltar();
	}
	return (
		<BlockUI blocked={!questao.id}>
			<Panel header="Questão de Avaliação de Atendimento">
				<PanelContent>
					<InputText label="Questão" name="questao" value={questao.questao} onChange={handleChange}/>
				</PanelContent>
				<PanelFooter>
					<If condition={questao.status === "ATIVO"}>
						<div className="absolute left-0 ml-3">
							<Button danger disabled={loading || !roles.QAAD} loading={loading === 1} label="Excluir" icon="fas fa-trash" onClick={handleExcluir}/>
						</div>
					</If>
					<If condition={questao.status === "DESATIVADO"}>
						<div className="absolute left-0 ml-3">
							<Button success disabled={loading || !roles.QAAD} loading={loading === 2} label="Restaurar" icon="fas fa-recycle" onClick={handleRestaurar}/>
						</div>
					</If>
					<Button success disabled={loading || (!roles.QAAA && !roles.QAAC)} loading={loading === 3} label="Salvar" icon="fas fa-save" onClick={handleSalvar}/>
					<Button secondary disabled={loading} label="Voltar" icon="fas fa-arrow-left" onClick={handleVoltar}/>
				</PanelFooter>
			</Panel>
		</BlockUI>
	);
}
