import {BlockUI} from "primereact/blockui";
import {Panel} from "primereact/panel";
import React, {useEffect, useState} from "react";
import {headerTemplate} from "../../utils/templates";
import {Calendar} from "../../components/calendar";
import moment from "moment";
import {PanelContent, PanelFooter} from "../../components/panel";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {SelectButton} from "../../components/selectbutton";
import {Column} from "primereact/column";
import {produtividadeService} from "../../service/produtividadeService";
import {useAuth} from "../../context/AuthContext";
import {DataTable} from "../../components/datatable";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {optionsFiltroEquipe} from "../../service/ordemServicoService";
import {Button} from "../../components/button";

const colunasVisiveis = [
	"Laudo",
	"Execução",
	"Ressolda",
	"Limpeza",
	"Teste"
];

function printEtapaX(p, e) {
	return (
		<div>
			{p.etapasX[e]?.distinct || ""}
			{p.etapasX[e]?.reworked ? <span style={{float: "right"}}>({p.etapasX[e]?.reworked || 0})</span> : ""}
		</div>
	);
}

function printEtapasX(p, e) {
	return (
		<div>
			{p.map(p => p.etapasX[e]?.distinct || 0).reduce((a, b) => a + b, 0)}
			<span style={{float: "right"}}>({p.map(p => p.etapasX[e]?.reworked || 0).reduce((a, b) => a + b, 0)})</span>
		</div>
	);
}

export function Produtividade() {

	const {usuario} = useAuth();

	const [params, setParams] = useState({
		usuario: usuario.id,
		inicio: moment().add(-7, "days").format("YYYY-MM-DD"),
		fim: moment().format("YYYY-MM-DD"),
		modo: "GESTOR",
		operador: null
	});

	const [block, setBlock] = useState(false);
	const [autoRefresh, setAutoRefresh] = useState(false);
	const [etapasPendentes, setEtapasPendentes] = useState([]);
	const [etapasExecutadas, setEtapasExecutadas] = useState([]);
	const [produtividades, setProdutividades] = useState([]);

	function handleChange(event) {
		setParams({ ...params, [event.name]: event.value});
	}

	useEffect(() => {
		listarProdutividade();
		const listarInterval = setInterval(() => {
			if (autoRefresh) {
				listarProdutividade();
			}
		}, 60000);
		return () => {
			clearInterval(listarInterval);
		};
	}, []);

	async function listarProdutividade() {
		setBlock(true);
		await produtividadeService.listar(params).then(produtividades => {
			for (const produtividade of produtividades) {
				produtividade.etapasP = {};
				produtividade.etapasX = {};
				for (const ordemServico of produtividade.ordensServico) {
					if (!etapasPendentes.includes(ordemServico.etapa)) {
						etapasPendentes.push(ordemServico.etapa);
					}
					produtividade.etapasP[ordemServico.etapa] = (produtividade.etapasP[ordemServico.etapa] || 0) + 1;
				}
				for (const acompanhamento of produtividade.acompanhamentos) {
					if (!etapasExecutadas.includes(acompanhamento.etapa)) {
						etapasExecutadas.push(acompanhamento.etapa);
					}
					if (!produtividade.etapasX[acompanhamento.etapa]) {
						produtividade.etapasX[acompanhamento.etapa] = [];
					}
					produtividade.etapasX[acompanhamento.etapa].push(acompanhamento.numero);
				}
				Object.keys(produtividade.etapasX).forEach(k => {
					const distinct = new Set(produtividade.etapasX[k]).size;
					const reworked = produtividade.etapasX[k].length - distinct;
					produtividade.etapasX[k] = {distinct, reworked};
				});
			}
			setEtapasPendentes(etapasPendentes);
			setEtapasExecutadas(etapasExecutadas);
			setProdutividades(produtividades);
		});
		setBlock(false);
	}

	/* TODO - REMOVER controlar exibição das colunas por preferências do usuário */
	function sortByLER(a, b) {
		return colunasVisiveis.indexOf(a) - colunasVisiveis.indexOf(b);
	}
	const pendentes = etapasPendentes.filter(ep => colunasVisiveis.includes(ep)).sort(sortByLER);
	const executadas = etapasExecutadas.filter(ex => colunasVisiveis.includes(ex)).sort(sortByLER);

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column rowSpan={2} header="Responsável"/>
				<Column style={{textAlign: "center"}} colSpan={pendentes.length} header="Pendentes"/>
				<Column style={{textAlign: "center"}} colSpan={executadas.length} header="Executados"/>
			</Row>
			<Row>
				{pendentes.map(e => <Column style={{textAlign: "center"}} header={e}/>)}
				{executadas.map(e => <Column header={<div style={{width: "100%", whiteSpace: "nowrap !important"}}>{e}<span style={{width: "auto", display: "inline !important", float: "right"}}>(R)</span></div>}/>)}
			</Row>
		</ColumnGroup>
	);

	const footerGroup = (
		<ColumnGroup>
			<Row>
				<Column/>
				{pendentes.map(e => <Column style={{textAlign: "center"}} footer={produtividades.map(p => p.etapasP[e] || 0).reduce((a, b) => a + b, 0)}/>)}
				{executadas.map(e => <Column footer={printEtapasX(produtividades, e)}/>)}
			</Row>
		</ColumnGroup>
	);

	return (
		<BlockUI blocked={block}>
			<Panel headerTemplate={() => headerTemplate("Relatório de Produtividade", "fa-solid fa-chart-bar")}>
				<PanelContent>
					<Calendar col={2} label="Período" name="inicio" value={params.inicio} onChange={handleChange} />
					<Calendar col={2} label="&nbsp;" name="fim" value={params.fim} onChange={handleChange} />
					<UsuarioAutoComplete gestor col={7} label="Operador" name="operador" value={params.operador} onChange={handleChange}/>
					<SelectButton label="&nbsp;" col={1} options={optionsFiltroEquipe} name="modo" value={params.modo} onChange={handleChange}/>
				</PanelContent>
				<PanelFooter>
					<Button icon="fa-solid fa-refresh" label="Atualizar" onClick={listarProdutividade}/>
					<div style={{float: "left"}}>
						{/*<ToggleButton icon="fa-solid fa-refresh" onLabel={null} offLabel={null} onIcon="fa-solid fa-refresh fa-spin" offIcon="fa-solid fa-cancel" checked={autoRefresh} onChange={toggleAutoRefresh}/>*/}
					</div>
				</PanelFooter>
			</Panel>
			<div style={{height: "2em"}}/>
			<DataTable headerColumnGroup={headerGroup}
			           value={produtividades}
			           rows={50}
			           footerColumnGroup={footerGroup}
			>
				<Column header="Responsável" field="responsavel" body={p => p.responsavel?.nome || usuario.nome} />
				{pendentes.map(e => <Column style={{width: "8em", textAlign: "center"}} header={e} body={p => p.etapasP[e]}/>)}
				{executadas.map(e => <Column style={{width: "8em"}} header={e} body={p => printEtapaX(p, e)}/>)}
			</DataTable>
		</BlockUI>
	);

}
