import {buildValidator, isBiggerThanZero, isRequired} from '../utils/fieldValidator';
import { baseService } from './baseService';
import {api} from "./api";

const resourceUrl = "/acordos-nivel-servico";

export const ansService = {
    criar: () => {
        return {
            id: null,
            descricao: "",
            expedientes: [
                {
                    _key: Math.random(),
                    diasSemana: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"],
                    inicio: "08:00",
                    fim: "18:00"
                },
                {
                    _key: Math.random(),
                    diasSemana: ["SATURDAY"],
                    inicio: "08:00",
                    fim: "12:00"
                }
            ],
            itens: [],
            calendario: null
        };
    },
    validar: (ans) => {
        const messages = buildValidator();
        messages.descricao = isRequired(ans.descricao);
        messages.itens = [...Array(ans.itens.length)].map((_, i) => ({
            tempoContagem: isBiggerThanZero(ans.itens[i].tempoContagem),
            statusInicial: isRequired(ans.itens[i].statusInicial),
            modoContagem: isRequired(ans.itens[i].modoContagem)
        }));
        return messages;
    },
    criarItem() {
        return {
            _key: Math.random(),
            tempoContagem: 0,
            statusInicial: "ABERTA",
            statusFinais: [],
            modoContagem: "DIA_UTIL",
            flags: [],
            suspenderContagem: false
        };
    },
    criarExpediente() {
        return {
            _key: Math.random(),
            diasSemana: [],
            inicio: "",
            fim: ""
        };
    },
    ...baseService(resourceUrl),
    async configuracoes() {
        return await api.get("/configuracoes/sla").then(response => response.data);
    },
    async salvarConfiguracoes(setupSLA) {
        return await api.post("/configuracoes/sla", setupSLA).then(response => response.data);
    },
    async listarAns(params) {
        const query = [];
        if (params.descricao?.length) query.push(`descricao=lk=${params.descricao}`);
        return await ansService.listar([`query=${query.join(";")}`]);
    }
};

export const optionsDiasSemana = [
    {label: "DOM", value: "SUNDAY"},
    {label: "SEG", value: "MONDAY"},
    {label: "TER", value: "TUESDAY"},
    {label: "QUA", value: "WEDNESDAY"},
    {label: "QUI", value: "THURSDAY"},
    {label: "SEX", value: "FRIDAY"},
    {label: "SÁB", value: "SATURDAY"}
];

export const optionsContagem = [
    {label: "Dias úteis", value: "DIA_UTIL"},
    {label: "Horas úteis", value: "HORA_UTIL"},
    {label: "Minutos úteis", value: "MINUTO_UTIL"},
    {label: "Dias corridos", value: "DIA_CORRIDO"},
    {label: "Horas corridas", value: "HORA_CORRIDA"}
];

export const optionsFlags = [
    {label: "Não repete", value: "UNICO"},
    {label: "Pausa contagem", value: "PAUSA"}
];
