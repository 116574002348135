import React, {useState} from "react";
import {InputCurrency, InputNumber} from "../../components/inputnumber";
import {InputMask} from "../../components/inputmask";
import {Calendar} from "../../components/calendar";
import {InputText} from "../../components/inputtext";
import {PanelContent} from "../../components/panel";
import {ordemServicoNotaFiscalService} from "../../service/ordemServicoNotaFiscalService";
import {SelecionarOrdemServicoNotaFiscal} from "./SelecionarOrdemServicoNotaFiscal";
import {withDialog} from "../../utils/dialogContext";
import {DataTable} from "../../components/datatable";
import {EquipamentoAutoComplete} from "../../components/autocomplete/equipamentoAutoComplete";
import {Column} from "primereact/column";
import {Button} from "../../components/button";
import {Row} from "primereact/row";
import {ColumnGroup} from "primereact/columngroup";
import {formatCurrency} from "../../utils/numberFormatter";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {isStacked} from "../../context/BreakpointContext";

export const EditarOrdemServicoNotaFiscal = withDialog(({ordemServico, setOrdemServico, showDialog, messages, setMessages}) => {

	const [editavel, setEditavel] = useState(false);
	const [itensSelecionados, setItensSelecionados] = useState([]);

	function handleChange(event) {
		setOrdemServico({...ordemServico, notaFiscal: {...ordemServico.notaFiscal, _alterado: true, itens: [], [event.name]: event.value}});
	}

	function buscarNotaFiscal() {
		if (ordemServico.notaFiscal?.numero) {
			ordemServicoNotaFiscalService.buscarPorNumero(ordemServico.notaFiscal?.numero, ordemServico.cliente?.cpfCnpj.replace(/[^\d]/g, "")).then(notasFiscais => {
				if (notasFiscais.length) {
					if (notasFiscais.length > 1) {
						showDialog(<SelecionarOrdemServicoNotaFiscal key={Math.random()} notasFiscais={notasFiscais} onSelect={notaFiscal => {
							ordemServico.notaFiscal = notaFiscal;
							setOrdemServico({...ordemServico});
						}}/>);
					} else {
						ordemServico.notaFiscal = notasFiscais[0];
						setOrdemServico({...ordemServico});
					}
				} else {
					setOrdemServico({...ordemServico, notaFiscal: {
						numero: ordemServico.notaFiscal.numero,
						itens: [
							{
								equipamento: ordemServico.itens?.[0]?.equipamento,
								valor: 0
							}
						]
					}});
					setEditavel(true);
				}
			});
		}
	}

	function adicionarItem() {
		const notaFiscal = ordemServico.notaFiscal;
		notaFiscal.itens.unshift(ordemServicoNotaFiscalService.criarItem());
		setOrdemServico(prevOrdemServico => ({...prevOrdemServico, notaFiscal}));
	}

	function removerItens() {
		if (itensSelecionados?.length) {
			showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os itens selecionados?" onYes={() => {
				const notaFiscal = ordemServico.notaFiscal;
				notaFiscal.itens = notaFiscal.itens?.filter(nfi => !itensSelecionados.some(nfis => (nfis._key && nfis._key === nfi._key) || (nfis.id && nfis.id === nfi.id)));
				setOrdemServico(prevOrdemServico => ({...prevOrdemServico, notaFiscal}));
			}}/>);
		}
	}

	const notaFiscalItemControlSet = (
		<div>
			<Button disabled={!ordemServico.notaFiscal} onClick={adicionarItem} icon="fa-solid fa-plus"/>
			<Button onClick={removerItens} danger disabled={!ordemServico.notaFiscal || !itensSelecionados?.length} icon="fa-solid fa-minus"/>
		</div>
	);

	const notaFiscalItemHeaderColumnGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={2} header={notaFiscalItemControlSet}/>
				<Column header={<div style={{textAlign: "right"}}>{formatCurrency(ordemServico.notaFiscal?.itens?.map(nfi => nfi.valor).reduce((a, b) => a + b, 0))}</div>}/>
			</Row>
			<Row>
				<Column style={{width: "3em"}} selectionMode="multiple"/>
				<Column header="Equipamento"/>
				<Column header="Valor"/>
			</Row>
		</ColumnGroup>
	);

	const notaFiscalItemFooterColumnGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={2} footer={notaFiscalItemControlSet}/>
				<Column header={<div style={{textAlign: "right"}}>{formatCurrency(ordemServico.notaFiscal?.itens?.map(nfi => nfi.valor).reduce((a, b) => a + b, 0))}</div>}/>
			</Row>
		</ColumnGroup>
	);

	function handleChangeItem(event) {
		const notaFiscal = ordemServico.notaFiscal;
		notaFiscal._alterado = true;
		notaFiscal.itens[event.index][event.name] = event.value;
		setOrdemServico({...ordemServico, notaFiscal});
	}

	return (
		<PanelContent>
			<InputNumber required invalid={messages.notaFiscal?.numero} onBlur={buscarNotaFiscal} col={3} label="Número" name="numero" value={ordemServico.notaFiscal?.numero} onChange={handleChange}/>
			<InputMask required invalid={messages.notaFiscal?.chave} readOnly={ordemServico.notaFiscal?.id && !editavel} unmask mask="9999 9999 9999 9999 9999 9999 9999 9999 9999 9999 9999" col={6} label="Chave" name="chave" value={ordemServico.notaFiscal?.chave} onChange={handleChange}/>
			<Calendar required invalid={messages.notaFiscal?.data} readOnly={ordemServico.notaFiscal?.id && !editavel} col={3} label="Data" name="data" value={ordemServico.notaFiscal?.data} onChange={handleChange}/>
			<InputText readOnly={ordemServico.notaFiscal?.id && !editavel} col={9} label="Descrição" name="descricao" value={ordemServico.notaFiscal?.descricao} onChange={handleChange}/>
			<InputCurrency required={ordemServico.notaFiscal?.valor == null} invalid={messages.notaFiscal?.valor}  col={3} label="Valor" name="valor" value={ordemServico.notaFiscal?.valor} onChange={handleChange}/>
			<div className="col-12">
				<DataTable headerColumnGroup={notaFiscalItemHeaderColumnGroup}
						   footerColumnGroup={notaFiscalItemFooterColumnGroup}
						   header={isStacked() ? null : notaFiscalItemControlSet}
						   footer={isStacked() ? null : notaFiscalItemControlSet}
						   value={ordemServico.notaFiscal?.itens}
						   selectionMode="checkbox"
						   selection={itensSelecionados}
						   onSelectionChange={e => setItensSelecionados(e.value)}
				>
					<Column style={{width: "3em"}} selectionMode="multiple"/>
					<Column field="equipamento.autoComplete" header="Equipamento" body={(nfi, c) => <EquipamentoAutoComplete name="equipamento" onChange={handleChangeItem} label={null} reduced index={c.rowIndex} value={nfi.equipamento}/>}/>
					<Column style={{width: "15em"}} field="valor" header="Valor" body={(nfi, c) => <InputCurrency name="valor" onChange={handleChangeItem} reduced index={c.rowIndex} value={nfi.valor}/>}/>
				</DataTable>
			</div>
			<InputText readOnly={ordemServico.notaFiscal?.id && !editavel} multiline style={{height: "13em"}} col={12} label="Observações" name="observacoes" value={ordemServico.notaFiscal?.observacoes} onChange={handleChange}/>
		</PanelContent>
	);

});
