import React, {useCallback, useEffect, useState} from "react";
import {BlockUI} from "primereact/blockui";
import {Panel} from "primereact/panel";
import {InputText} from "../../components/inputtext";
import {PanelContent, PanelFooter} from "../../components/panel";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {useNavigate} from "react-router-dom";
import {Button} from "../../components/button";
import {labelSetor, labelTipo, optionsSetor, optionsTipo} from "../../service/prateleiraService";
import {If} from "../../components/conditional/If";
import {useAuth} from "../../context/AuthContext";
import {Dropdown} from "../../components/dropdown";
import {ordemServicoService} from "../../service/ordemServicoService";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {useQueryClientPrateleira} from "../../utils/query-clients/queryClients";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {SearchButton} from "../../components/button/SearchButton";
import {usePagesAndTabs} from "../../utils/PageAndTabContext";

export function Prateleiras() {

	const {roles} = useAuth();
	const navigate = useNavigate();
	const [expanded, setExpanded] = useState([]);
	const {query, handleSearch} = useQueryClientPrateleira();
	const [ordensServico, setOrdensServico] = useState([]);
	const {params, handleChange} = useQueryParams(QueryParamsKeys.Prateleira);
	const {pageAndTab, setScroll, setPage} = usePagesAndTabs(QueryParamsKeys.Comercial);
	const [pages, setPages] = useState(pageAndTab.page);

	useEffect(() => {
		if (pageAndTab.scroll) {
			setTimeout(() => {
				window.scrollTo(0, pageAndTab.scroll);
				setScroll(0);
			}, 50);
		}
	}, []);

	function handleCriar() {
		navigate("/laboratorio/prateleiras/new");
	}

	async function expandRow(event) {
		await ordemServicoService.listar(["status=RECEPCAO,LAUDO,MANUTENCAO,LIMPEZA,TESTE,EXPEDICAO", `prateleira=${event.data.descricao}`]).then(os => setOrdensServico(os));
		setExpanded([event.data]);
	}

	function collapseRow() {
		setExpanded([]);
	}

	const templatePrateleira = useCallback(() => {
		return (
			<DataTable emptyMessage="Nenhuma ordem de serviço nesta prateleira" value={ordensServico}>
				<Column header="Nº" field="numero"/>
				<Column header="Cliente" field="cliente.autoComplete"/>
				<Column header="Equipamento" field="equipamento.autoComplete"/>
			</DataTable>
		);
	}, [ordensServico]);

	return (
		<If condition={roles.PRAB}>
			<BlockUI blocked={query.isLoading}>
				<Panel header="Prateleiras">
					<PanelContent>
						<InputText col={6} label="Descrição" name="descricao" value={params.descricao} onChange={handleChange}/>
						<Dropdown col={3} label="Setor" value={params.setor} name="setor" options={[{label: "Qualquer", value: null}, ...optionsSetor]} onChange={handleChange}/>
						<Dropdown col={3} label="Tipo" value={params.tipo} name="tipo" options={[{label: "Qualquer", value: null}, ...optionsTipo]} onChange={handleChange}/>
					</PanelContent>
					<PanelFooter>
						<If condition={roles.PRAA} children={<Button label="Novo" warning icon="fas fa-plus" onClick={handleCriar}/>}/>
						<SearchButton query={query} onSearch={handleSearch}/>
					</PanelFooter>
				</Panel>
				<DataTable
					expandedRows={expanded}
					onRowExpand={expandRow}
					onRowCollapse={collapseRow}
					rowExpansionTemplate={templatePrateleira}
					value={query.data || []}
					first={20 * pages}
					onPage={e => {
						setPage(e.page);
						setPages(e.page);
					}}
					onRowDoubleClick={e => {
						setScroll();
						navigate(`/laboratorio/prateleiras/${e.data.id}`);
					}}
				>
					<Column style={{width: "3em"}} expander/>
					<Column sortable field="descricao" header="Descrição"/>
					<Column sortable field="codigo" header="Código"/>
					<Column sortable field="setor" header="Setor" body={p => labelSetor[p.setor]}/>
					<Column field="tipo" header="Tipo" body={p => [labelTipo[p.tipo]]}/>
				</DataTable>
				<RefetchIndicator isRefetching={query.isRefetching}/>
			</BlockUI>
		</If>
	);

}
