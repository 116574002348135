import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {toast} from "react-toastify";
import {useAuth} from "../../context/AuthContext";
import { headerTemplate } from "../../utils/templates";
import { useBreakpoint } from "../../context/BreakpointContext";
import { estoqueService } from "../../service/estoqueService";

export const FiltrosEstoques = React.memo(({setValue}) => {

	const navigate = useNavigate();

	const {roles} = useAuth()

    const {breakpoint} = useBreakpoint()
    const isLargeDevice = breakpoint === 'lg' || breakpoint === 'md'

	const [loading, setLoading] = useState(false);

	const [params, setParams] = useState({
		descricao: "",
	});

	function handleChange(event) {
		setParams({...params, [event.name]: event.value});
	}

	function handleCriar() {
		navigate("/estoques/new");
	}

	async function handleProcurar(params) {
		const query = [];
		if (params.descricao?.length) query.push(`descricao=${params.descricao}`);
		setLoading(true);
		toast.promise(
			estoqueService.listar([`query=${query.join(";")}`]).then(setValue).then(() => setLoading(false)),
			{
				"pending": `Procurando estoques. Aguarde...`,
				"error": {
					render(e) {
						setLoading(false);
						return `Falha ao procurar estoques: ${e.data?.response?.data}`;
					}
				}
			}
		);
	}

	return (
		<Panel headerTemplate={() => headerTemplate('Estoque', 'fa-box')}>
			<PanelContent>
				<InputText placeholder="a descrição do estoque" col={12} label="Descrição" value={params.descricao}
				           name="descricao" onChange={handleChange}/>
			</PanelContent>
			<PanelFooter>
				{!!roles.ESTA &&
					<Button 
						disabled={loading} 
						success 
						onClick={handleCriar} 
						label={isLargeDevice ? "Novo" : null} 
						icon="pi pi-plus"
						autowidth={!isLargeDevice}
					/>
				}
				<Button 
					info 
					disabled={loading} 
					loading={loading}
					onClick={() => handleProcurar(params)}
					label={isLargeDevice ? "Procurar" : null}
					icon="pi pi-search"
					autowidth={!isLargeDevice}
				/>
			</PanelFooter>
		</Panel>
	);

});
