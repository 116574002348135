import React, {useState} from 'react';
import {AutoComplete} from '.';
import { transportadoraService } from '../../pages/Comercial/services/transportadoraService';

export function TransportadoraAutoComplete(props) {

	const [transportadoras, setTransportadoras] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("")

	function completeMethod(event) {
		const query = [];
		if (event.query.length) query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);
		transportadoraService.autocomplete(query).then(res => {
			setTransportadoras(res);
			if (!res[0]) {
				setEmptyMessage("Nenhum registro encontrado");
			} else {
				setEmptyMessage("");
			}
		});
	}

	const itemTemplate = transportadora => {
        return (
            <div className="flex align-items-center" style={{gap: '0.5rem'}}>
                <i className={"fa-solid fa-truck"}/>
                <div>{transportadora.cpfCnpj} - {transportadora.razaoSocial} ({transportadora.municipio})</div>
            </div>
        );
	};

	return (
		<AutoComplete
			col={3}
			placeholder="CNPJ (somente nº), nome fantasia ou razão social"
			label="Transportadora"
			name="transportadora"
			value={props.value}
			itemTemplate={itemTemplate}
			forceSelection
			completeMethod={completeMethod}
			suggestions={transportadoras}
			invalid={emptyMessage}			
			{...props}
		/>
	)
}
