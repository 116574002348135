import React from "react";
import {Dropdown} from "./index";
import { modelosRelatorio } from "../../service/relatoriosServicoService";


export function RelatoriosDropdown(props) {

	return (
		<Dropdown 
            label="Selecione um Relatório" 
            options={modelosRelatorio} 
            //optionLabel="label"
            {...props}
        />
	);
}