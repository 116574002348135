import { api } from "../../../service/api";
import { baseService } from "../../../service/baseService";
import { buildValidator, isBiggerThanZeroValor, isNotNull } from "../../../utils/fieldValidator";

const resourceUrl = "/icms";

export const icmsService = {  
    criar: () => {
        return {
            id: null,
            origem: null,
            destino: null,
            aliquota: 0            
        };
    },
    verificarPath: async (path) => {
        return api.get(`${resourceUrl}/verificar-path`, {
            params: {path: path}
        }).then(res => res.data);
    }, 
    
    validar(icms) {
        const messages = buildValidator();
        messages.origem = isNotNull(icms.origem);
        messages.destino = isNotNull(icms.destino);
        messages.aliquota = isBiggerThanZeroValor(icms.aliquota);
        return messages;
    },

	validarBackend: async icms =>  {
        const query = [];
		query.push(`origem=${icms.origem}`);
        query.push(`destino=${icms.destino}`);
        if (icms.id) {
            query.push(`id=${icms.id}`);
       }
        return await api.get(`${resourceUrl}/validar?${query.join("&")}`).then(response => response.data);
	},
    ...baseService(resourceUrl)
};

export const optionsUf = [
    {label: "Acre", value: "AC"},
    {label: "Alagoas", value: "AL"},    
    {label: "Amapá", value: "AP"},
    {label: "Amazonas", value: "AM"},
    {label: "Bahia", value: "BA"},
    {label: "Ceará", value: "CE"},
    {label: "Distrito Federal", value: "DF"},
    {label: "Espírito Santo	", value: "ES"},
    {label: "Goiás", value: "GO"},
    {label: "Maranhão", value: "MA"},
    {label: "Mato Grosso", value: "MT"},
    {label: "Mato Grosso do Sul	", value: "MS"},    
    {label: "Minas Gerais", value: "MG"},    
    {label: "Pará", value: "PA"},
    {label: "Paraíba", value: "PB"},
    {label: "Paraná", value: "PR"},
    {label: "Pernambuco", value: "PE"},
    {label: "Piauí", value: "PI"},
    {label: "Rio de Janeiro	", value: "RJ"},
    {label: "Rio Grande do Norte", value: "RN"},
    {label: "Rio Grande do sul	", value: "RS"},
    {label: "Rondônia", value: "RO"},
    {label: "Roraima", value: "RR"},
    {label: "Santa Catarina	", value: "SC"},
    {label: "São Paulo	", value: "SP"},
    {label: "Sergipe", value: "SE"},
    {label: "Tocantins", value: "TO"}
];
