import React, { useState } from "react";

import { seloIpemService } from "../../service/seloIpemService";
import { UsuarioAutoComplete } from "../../components/autocomplete/usuarioAutoComplete";
import { PanelContent, PanelFooter } from "../../components/panel";
import { Dialog } from "../../components/dialog";
import { Button } from "../../components/button";

export function TrocarResponsavel({selosIpem, onModalClose}) {

	const [visible, setVisible] = useState(true);
	const [blocked, setBlocked] = useState(false);
	const [responsavel, setResponsavel] = useState(null);

	const handleChange = event => setResponsavel(event.value);

	async function confirmarTrocaResponsavel() {
		setBlocked(true);
		for (const seloIpem of selosIpem) {
			if (!(seloIpem.responsavel?.id === responsavel.id)) {
				await seloIpemService.buscar(seloIpem.id).then(async seloIpem => await seloIpemService.salvar({...seloIpem, responsavel}));
			}
		}
		setBlocked(false);
		if (onModalClose) {
			onModalClose();
		}
		setVisible(false);
	}

	return (
		<Dialog style={{width: "600px"}} header="Alteração de Responsável por Selos do IPEM" visible={visible} modal onHide={() => setVisible(false)}>
			<PanelContent>
				<UsuarioAutoComplete col={12} label="Responsável" value={responsavel} onChange={handleChange} name="responsavel"/>
			</PanelContent>
			<PanelFooter>
				<Button disabled={blocked} loading={blocked} label="Alterar" icon="fa-solid fa-save" onClick={confirmarTrocaResponsavel}/>
			</PanelFooter>
		</Dialog>
	);

}
