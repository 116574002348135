import React, {createContext, useState, useContext, useEffect} from 'react';

export const BreakpointContext = createContext(null);

const BreakpointProvider = ({children}) => {

	const getDeviceConfig = width => {
		if (width < 320) {
			return "xs";
		}
		if (width >= 320 && width <= 960) {
			return "sm";
		}
		if (width > 960 && width < 1024) {
			return "md";
		}
		if (width >= 1024) {
			return "lg";
		}
	}

	const [breakpoint, setBreakpoint] = useState(() => getDeviceConfig(window.innerWidth));
	const isLargeDevice = breakpoint === "lg" || breakpoint === "md";

	useEffect(() => {
		let timer;
		const calcInnerWidth = () => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				setBreakpoint(getDeviceConfig(window.innerWidth))
			}, 250);
		}
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

	return (
		<BreakpointContext.Provider value={{breakpoint, isLargeDevice}}>
			{children}
		</BreakpointContext.Provider>
	);

};

function useBreakpoint() {
	const context = useContext(BreakpointContext);
	if (!context) {
		throw new Error('UseAuth must be used within an AuthProvider');
	}
	return context;
}

export {BreakpointProvider, useBreakpoint};

export function isStacked() {
	return window.innerWidth > 960;
}
