import React, {useContext, useEffect, useState} from "react";
import {QueryParamsKeys} from "./defaultQueryParams";
import {sessionStore} from "./sessionHandler";

export const defaultPagesAndTabs = Object.assign({}, ...Object.values(QueryParamsKeys).map(k => ({[k]: {page: 0, tab: 0, scroll: 0}})));

defaultPagesAndTabs[QueryParamsKeys.Laboratorio] = {
    tab: 0,
    page: {
        recepcao: 0,
        laudo: 0,
        manutencao: 0,
        limpeza: 0,
        teste: 0,
        expedicao: 0,
        fechada: 0
    },
    scroll: 0
};

const PageAndTabContext = React.createContext(defaultPagesAndTabs);

export function PageAndTabProvider({children}) {

    const [pagesAndTabs, setPagesAndTabs] = useState(sessionStore("X-PagesAndTabs", defaultPagesAndTabs));

    useEffect(() => {
        window.sessionStorage.setItem("X-PagesAndTabs", JSON.stringify(pagesAndTabs));
    }, [pagesAndTabs]);

    function changePage(name, page) {
        setPagesAndTabs(prevPagesAndTabs => {
            prevPagesAndTabs[name].page = page;
            return {...prevPagesAndTabs};
        });
    }

    function changeTab(name, tab) {
        setPagesAndTabs(prevPagesAndTabs => {
            prevPagesAndTabs[name].tab = tab;
            return {...prevPagesAndTabs};
        });
    }

    function changeScroll(name, scroll) {
        setPagesAndTabs(prevPagesAndTabs => {
            prevPagesAndTabs[name].scroll = scroll;
            return {...prevPagesAndTabs};
        });
    }

    return (
        <PageAndTabContext.Provider children={children} value={{pagesAndTabs, changePage, changeTab, changeScroll}}/>
    );

}

export function usePagesAndTabs(key) {
    const {pagesAndTabs, changePage, changeTab, changeScroll} = useContext(PageAndTabContext);
    function setPage(page) {
        changePage(key, page);
    }
    function setTab(tab) {
        changeTab(key, tab);
    }
    function setScroll(scroll) {
        if (scroll === undefined) {
            scroll = document.getElementsByTagName("html")[0].scrollTop;
        }
        changeScroll(key, scroll);
    }
    return {pageAndTab: pagesAndTabs[key], setPage, setTab, setScroll};
}
