import {ConfirmDialogV2} from "../../components/confirmdialog";
import {Foto} from "../../components/foto";
import {toSrc} from "../../service/fotoUtils";
import React from "react";
import {PanelContent} from "../../components/panel";
import {useDialog, withDialog} from "../../utils/dialogContext";
import {arquivoService} from "../../service/arquivoService";
import {mimeTypesIcons, mimeTypesImages} from "../../utils/constants";
import saveAs from "file-saver";
import {iconStatus, labelStatus} from "../../service/ordemServicoService";
import {acceptedTypes} from "../OrdensServico/EditarOrdemServicoItemFoto";
import {useAuth} from "../../context/AuthContext";
import {Observacao} from "../__Commons/Observacao";
import {If} from "../../components/conditional/If";
import moment from "moment";

export function EditarOrdemServicoItemFoto({ordemServico, item, handleChange, readOnly}) {

	const {usuario} = useAuth();
	const {showDialog} = useDialog();

	function handleFoto(event) {
		showDialog(<Observacao header="A imagem evidencia" required onModalClose={texto => {
			item.fotos.push({
				status: ordemServico.status,
				responsavel: usuario,
				fileName: event.conteudo.fileName,
				contentType: event.conteudo.contentType,
				texto,
				conteudo: {
					conteudo: event.conteudo.content
				}
			});
			handleChange({name: "fotos", value: item.fotos});
		}}/>);
	}

	function excluirFoto(index) {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja excluir esta foto?" onYes={() => {
			item.fotos.splice(index, 1);
			handleChange({name: "fotos", value: item.fotos});
		}}/>);
	}

	function downloadArquivo(file) {
		arquivoService.baixar(file).then(data => {
			saveAs(new Blob([data], {type: file.contentType}), file.fileName);
		});
	}

	function renderFoto(f, index) {
		if (!f.contentType || mimeTypesImages.includes(f.contentType)) {
			return (
				<div className="md:col-4 sm:col-12 xm relative">
					<Foto
						contentType={f.contentType}
						thumb
						accept={acceptedTypes.join(", ")}
						readOnly={item.fotos[index]?.id && item.fotos[index]?.responsavel?.id !== usuario.id}
						value={toSrc(item.fotos[index])}
						onChange={conteudo => handleFoto({conteudo, index})}
						onDelete={() => excluirFoto(index)}
					/>
					<div className="foto-status">
						<div>{iconStatus[f.status]} {labelStatus[f.status]}</div>
						<div>{f.texto?.length ? <i>"{f.texto}"</i> : null}</div>
						<div>
							{f.responsavel?.nome}
							<If condition={f.registro}>
								{` às ${moment(f.registro).format("DD/MM/YYYY HH:mm")}`}
							</If>
						</div>
					</div>
				</div>
			);
		} else {
			const fileParts = f.fileName.split(".");
			const fileExt = fileParts[fileParts.length - 1].toUpperCase();
			return (
				<div
					className="
						md:col-4
						sm:col-12
						xm
						flex
						align-items-center
						justify-content-center
						flex-column
						cursor-pointer
						gap-1
						relative
					"
					onClick={() => downloadArquivo(f)}
				>
					<i className={`text-8xl ${mimeTypesIcons[f.contentType]}`}/>
					Documento {fileExt}
					<div className="text-xs font-italic">Clique para baixar</div>
					<div className="foto-status">
						<div>{iconStatus[f.status]} {labelStatus[f.status]}</div>
						<div>{f.texto?.length ? <i>"{f.texto}"</i> : null}</div>
						<div>
							{f.responsavel?.nome}
							<If condition={f.registro}>
								{` às ${moment(f.registro).format("DD/MM/YYYY HH:mm")}`}
							</If>
						</div>
					</div>
				</div>
			);
		}
	}

	let listFotos = [...item.fotos];

	if (!readOnly) {
		listFotos.push({});
	}

	return (
		<PanelContent>
			{listFotos.map(renderFoto)}
		</PanelContent>
	);

}
