import React, {useEffect, useMemo, useState} from 'react';
import {TabelaOrdemServico} from "./TabelaOrdemServico";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import {useBreakpoint} from "../../context/BreakpointContext";
import {BlockUI} from "primereact/blockui";
import {Panel} from "primereact/panel";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {FiltrosOrdemServicoComercial} from "../__Commons/FiltrosOrdemServicoComercial";
import {If} from "../../components/conditional/If";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {useQueryClientComercial} from "../../utils/query-clients/queryClients";
import {SearchButton} from "../../components/button/SearchButton";
import {usePagesAndTabs} from "../../utils/PageAndTabContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";

export function OrdensServico() {

    const navigate = useNavigate();
    const {roles} = useAuth();
    const {isLargeDevice} = useBreakpoint();
    const {query, handleSearch} = useQueryClientComercial();
    const [hideFilters, setHideFilters] = useState(false);
    const {pageAndTab, setScroll} = usePagesAndTabs(QueryParamsKeys.Comercial);

    useEffect(() => {
        if (pageAndTab.scroll) {
            setTimeout(() => {
                window.scrollTo(0, pageAndTab.scroll);
                setScroll(0);
            }, 50);
        }
    }, []);

    function handleCriar() {
        navigate("/ordens-servico/new");
    }

    function handleCriarPorNotaFiscal() {
        navigate("/ordens-servico/notas-fiscais");
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <If condition={!hideFilters}>
                <Panel headerTemplate={() => headerTemplate("Ordens de Serviço", "fa-scroll")}>
                    <PanelContent>
                        <FiltrosOrdemServicoComercial/>
                    </PanelContent>
                    <PanelFooter>
                        {/*{roles.OSSA && <Button success onClick={handleCriarPorNotaFiscal} label={isLargeDevice ? "Entrada Por Nota Fiscal" : null} icon="fa-solid fa-receipt" autowidth={!isLargeDevice}/>}*/}
                        {/*{roles.OSSA && <Button success onClick={handleCriar} label={isLargeDevice ? "Novo" : null} icon="pi pi-plus" autowidth={!isLargeDevice}/>}*/}
                        <SearchButton query={query} onSearch={handleSearch}/>
                    </PanelFooter>
                </Panel>
            </If>
            <TabelaOrdemServico value={useMemo(() => query.data || [], [query.data])} setHideFilters={setHideFilters}/>
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
