import React, { useCallback, useEffect, useState } from "react";
import { DataTable } from "../../components/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { empresaService } from "../../service/empresaService";
import { ClienteAutoComplete } from "../../components/autocomplete/ClienteAutoComplete";
import { clienteService } from "../../service/clienteService";
import { Button } from "../../components/button";
import { useDialog } from "../../utils/dialogContext";
import { ConfirmDialogV2 } from "../../components/confirmdialog";
import { ContratoAutoComplete } from "../../components/autocomplete/ContratoAutoComplete";
import { contratoService, status } from "../../service/contratoService";
import moment from "moment";
import { formatCurrency } from "../../utils/numberFormatter";

export const EditarEmpresaContratos = ({
  empresa,
  contratos,
  setContratos,
}) => {
  const navigate = useNavigate();
  const { roles } = useAuth();
  const { showDialog } = useDialog();

  const [loadingTabela, setLoadingTabela] = useState(false);



  const handleEditar = (event) => {
    if (!roles.EMPC) return;

    toast(`Empresa ${event.data.nomeFantasia} foi selecionada.`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      progressStyle: {},
    });
    navigate(`/empresas/${event.data.id}`);
  };

  const templateStatus = (e) => {
    const value = status.filter((status) => status.value === e.status)[0];
    return (
      <div className="flex justify-content-center" title={value.label}>
        <i
          className={`fa-solid ${value.icon} fa-lg`}
          style={{ color: value.color }}
        />
      </div>
    );
  };

  return (
    <div className="col-12">
      <div className="w-full flex align-itens-center p-0"></div>
      <DataTable
        value={contratos}
        onRowDoubleClick={handleEditar}
        valuelength={contratos?.length}
		loading={loadingTabela}
      >
        <Column
          sortable
          header="Status"
          field="status"
          body={templateStatus}
          alignHeader="center"
          style={{ width: "7em" }}
        />
        <Column
          sortable
          header="Número"
          field="numero"
          style={{ textAlign: "right", width: "5em" }}
        />
        <Column
          sortable
          header="Tipo"
          field="tipo"
          style={{ textAlign: "center", width: "15em" }}
        />
        <Column
          sortable
          header="Cliente"
          field="clienteNomeFantasia"
          body={useCallback(
            (e) => `${e.clienteCpfCnpj} - ${e.clienteNomeFantasia}`,
            []
          )}
        />
        <Column
          sortable
          header="Periodicidade das Parcelas"
          field="periodicidadeParcela"
          style={{ textAlign: "center", width: "12em" }}
        />
        <Column sortable header="Vendedor" field="autoCompleteVendedor" />
        <Column
          sortable
          header="Início"
          field="inicio"
          body={useCallback(
            (e) => `${moment(e.inicio).format("DD/MM/YYYY")}`,
            []
          )}
          style={{ width: "7em" }}
        />
        <Column
          sortable
          header="Fim"
          field="fim"
          body={useCallback((e) => `${moment(e.fim).format("DD/MM/YYYY")}`, [])}
          style={{ width: "7em" }}
        />
        <Column
          sortable
          header="Valor"
          field="valor"
          style={{ textAlign: "right", width: "10em" }}
          body={(b) => formatCurrency(b.valor)}
        />
      </DataTable>
    </div>
  );
};
