import React, {useState} from "react";
import {Dialog, DialogFooter} from "../../../components/dialog";
import {PanelContent} from "../../../components/panel";
import {Button} from "../../../components/button";
import {InputNumber} from "../../../components/inputnumber";
import {Calendar} from "../../../components/calendar";
import {InputText} from "../../../components/inputtext";
import {buildValidator} from "../../../utils/fieldValidator";
import {ordemServicoDeslocamentoService} from "../../../service/ordemServicoService";
import moment from "moment";

export function EditarDeslocamento({deslocamento, onChange}) {

    const [visible, setVisible] = useState(true);
    const [messages, setMessages] = useState(buildValidator());
    const [entidade, setEntidade] = useState({...deslocamento});

    function handleSalvar() {
        const messages = ordemServicoDeslocamentoService.validar(entidade);
        if (messages.isEmpty()) {
            if (onChange) {
                onChange(entidade);
            }
            handleClose();
        } else {
            setMessages({...messages});
        }
    }

    function handleChange(event) {
        setEntidade(prevEntidade => ({...prevEntidade, [event.name]: event.value}));
    }

    function handleClose() {
        setVisible(false);
    }

    return (
        <Dialog header="Deslocamento" visible={visible} style={{width: "550px"}} onHide={handleClose}>
            <PanelContent>
                <Calendar invalid={messages.dataInicial} showTime label="Início" col={6} name="dataInicial" value={entidade.dataInicial} onChange={handleChange}/>
                <InputNumber invalid={messages.odometroInicial} label="Odômetro Início" col={6} name="odometroInicial" value={entidade.odometroInicial} onChange={handleChange}/>
                <Calendar minDate={moment(entidade.dataInicial).toDate()} invalid={messages.dataFinal} showTime label="Fim" col={6} name="dataFinal" value={entidade.dataFinal} onChange={handleChange}/>
                <InputNumber invalid={messages.odometroFinal} label="Odômetro Fim" col={6} name="odometroFinal" value={entidade.odometroFinal} onChange={handleChange}/>
                <InputText multiline style={{height: "10em"}} col={12} label="Observações" name="observacoes" value={entidade.observacoes} onChange={handleChange}/>
            </PanelContent>
            <DialogFooter>
                <Button icon="fas fa-save" success label="Salvar" onClick={handleSalvar}/>
                <Button icon="fas fa-times" secondary label="Fechar" onClick={handleClose}/>
            </DialogFooter>
        </Dialog>
    );

}
