import React, {useState} from 'react';

import {AutoComplete} from '.';
import { tiposDeClausulaService } from '../../service/tiposDeClausula';

export function TipoClausulaAutoComplete(props) {

	const [tipoClausula, setTipoClausula] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("");

	function completeMethod(event) {
		const query = [];

		if (event.query?.length) query.push(`codigo,descricao=lk=${encodeURIComponent(event.query)}`);

		tiposDeClausulaService.listar([`query=${query.join(";")}`]).then(res => {
			setTipoClausula(res);
			if (!res[0]) {
				setEmptyMessage("Nenhum registro encontrado");
			} else {
				setEmptyMessage("");
			}
		});
	}

	const itemTemplate = clausula => {
        return (
            <div className="flex align-items-center" style={{gap: '0.5rem'}}>
                <i className={"fa-solid fa-user-tie"}/>
                <div>{clausula.codigo} - {clausula.descricao}</div>
            </div>
        );
    }

	return (
		<AutoComplete
			field='descricao'
			col={3}
			placeholder="código ou descrição"
			name="contratoClausulaTipo"
			value={props.value}
			itemTemplate={itemTemplate}
			onChange={props.onChange}
			completeMethod={completeMethod}
			suggestions={tipoClausula}
			invalid={emptyMessage}
			icon="fa-solid fa-handshake"
			{...props}
		/>
	)
}
