import React, { useEffect } from 'react';
import { Card } from 'primereact/card';
import './styles.scss'
import { Button } from '../../components/button';
import { useNavigate } from 'react-router-dom';

export function AcessoNegado(props) {

    useEffect(() => {
        props.setStaticMenuInactive(true)
    }, [props])

    const navigate = useNavigate()

    const reconectar = () => {
        navigate("/clientes");
        props.setStaticMenuInactive(false)
    }

  return (
    <Card className='height flex flex-column align-items-center justify-content-center'>
        <div style={{textAlign: 'center'}}>
            <i className="fa-solid fa-triangle-exclamation fa-10x"></i>
            <h2 style={{marginTop: '0.50em'}}>Você não tem acesso a esta página.</h2>
            <Button
                label={'Voltar ao inicio'}
                icon='fa-solid fa-house'
                justIcon
                secondary
                large
                onClick={reconectar}
            />
        </div>
    </Card>
  )
}
