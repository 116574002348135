import React, {useMemo} from 'react';
import {TabelaFluxo} from './TabelaFluxo';
import {useQueryClientFluxos} from "../../utils/query-clients/queryClients";
import {BlockUI} from "primereact/blockui";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {InputText} from "../../components/inputtext";
import {If} from "../../components/conditional/If";
import {Button} from "../../components/button";
import {Panel} from "primereact/panel";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {useBreakpoint} from "../../context/BreakpointContext";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";

export function Fluxos() {

    const {roles} = useAuth();
    const navigate = useNavigate();
    const {isLargeDevice} = useBreakpoint();
    const {query, handleSearch} = useQueryClientFluxos();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.Fluxos);

    function handleCriar() {
        navigate("/ordens-servico/fluxos/new");
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel headerTemplate={() => headerTemplate("Fluxos de Ordem de Serviço", "fa-box")}>
                <PanelContent>
                    <InputText placeholder="a descrição" col={12} label="Descrição" value={params.descricao} name="descricao" onChange={handleChange}/>
                </PanelContent>
                <PanelFooter>
                    <If condition={roles.OSEA}>
                        <Button success onClick={handleCriar} label="Novo" icon="pi pi-plus" autowidth={!isLargeDevice}/>
                    </If>
                    <Button info disabled={query.isLoading || query.isRefetching} loading={query.isLoading || query.isRefetching} onClick={handleSearch} label="Procurar" icon="pi pi-search" autowidth={!isLargeDevice}/>
                </PanelFooter>
            </Panel>
            {useMemo(() => <TabelaFluxo value={query.data || []}/>, [query.data])}
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
