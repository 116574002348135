import React from "react";
import {InputText as PInputText} from "primereact/inputtext";
import { ToggleButton as PToggleButton} from 'primereact/togglebutton';
import classNames from "classnames";
import {calcularTamanho} from "../../service/guiHelper";

export function ToogleInputText({col, onChangeToggle, checked, onChange, ...props}) {

	const classes = calcularTamanho(col);

	function handleChange(event) {
		if (onChange) {
			onChange({name: props.name, value: event.target.value, index: props.index});
		}
	}

	const inputClasses = classNames({
		"p-invalid block": props.invalid,
	});

	const newLocal = props.required ? <small style={{color: props.value?.length ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : null;

	return (
		<div className={classes}>
			<label>{props.label}</label>
			<div className="p-inputgroup">
				<PToggleButton
					onLabel="Sim"
					offLabel="Não"
					onChange={onChangeToggle}
					checked={checked}
					className='md:col-4 tzm-input-group-button auto-width'
					style={{marginRight: "0px", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px"}}
				/>
				<PInputText {...props} placeholder={props.placeholder ? `Digite ${props.placeholder}`: null }  className={inputClasses} onChange={handleChange}/>
			</div>
			{props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : newLocal}
		</div>
	);

}

export function InputCheckbox(props) {

	const classes = calcularTamanho(props.col);

	function handleInputChange(event) {
		if (props.onInputChange) {
			props.onInputChange({name: props.inputName, option: props.checkboxName, value: event.target.value, index: props.index});
		}
	}

	function handleCheckboxChange() {
		if (props.onCheckboxChange) {
			props.onCheckboxChange({name: props.checkboxName, index: props.index, value: !props.checkboxValue});
		}
	}

	const inputClasses = classNames({
		"p-invalid block": props.invalid,
	});

	const newLocal = props.required ? <small style={{color: props.value?.length ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : null;

	return (
		<div className={classes}>
			<label>{props.label}</label>
			<div className="p-inputgroup">
				{
					props.hideCheckbox ? null : (
						<PToggleButton
							onLabel="Sim"
							offLabel="Não"
							onChange={handleCheckboxChange}
							checked={props.checkboxValue}
							className='md:col-4 tzm-input-group-button auto-width'
							style={{marginRight: "0px", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px"}}
						/>
					)
				}
				<PInputText readOnly={!props.checkboxValue} {...props} placeholder={props.checkboxValue ? props.placeholder : ""} name={props.inputName} value={props.inputValue} className={inputClasses} onChange={handleInputChange}/>
			</div>
			{props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : newLocal}
		</div>
	);

}
