import React, {useState} from "react";
import {Column} from "primereact/column";
import {DataTable} from "../../components/datatable";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {Button} from "../../components/button";

export function EditarContratoModelos({contrato, setContrato}) {

    const [selecionados, setSelecionados] = useState([]);

    function adicionarModelo() {
        contrato.modelos.unshift({_key: Math.random(), autoComplete: ""});
        setContrato({...contrato});
    }

    function removerModelos() {
        contrato.modelos = contrato.modelos.filter(m => !selecionados.some(i => (m._key ? m._key === i._key : (m.id && m.id === i.id))));
        setContrato({...contrato});
        setSelecionados([]);
    }

    function handleChange(event) {
        const {modelos} = contrato;
        if (event.value?.id) {
            modelos[event.index] = {_key: Math.random(), ...event.value};
        } else {
            modelos[event.index] = event.value;
        }
        setContrato({...contrato});
    }

    const controlSet = (
        <div>
            <Button icon="pi pi-plus" onClick={adicionarModelo}/>
            <Button disabled={!selecionados?.length} onClick={removerModelos} danger icon="pi pi-minus"/>
        </div>
    );

    return (
        <div className="col-12">
            <DataTable selectionMode="checkbox"
                       selection={selecionados}
                       onSelectionChange={e => setSelecionados(e.value)}
                       header={controlSet}
                       footer={controlSet}
                       value={contrato.modelos}
            >
                <Column style={{width: "3em"}} selectionMode="multiple"/>
                <Column field="produto" header="Modelo" body={(m, c) => <ProdutoAutoComplete col={12} label={null} reduced index={c.rowIndex} value={m} onChange={handleChange}/>}/>
            </DataTable>
        </div>
    );

}
