import React, { useState } from "react";

import { seloIpemService } from "../../service/seloIpemService";
import { UsuarioAutoComplete } from "../../components/autocomplete/usuarioAutoComplete";
import { PanelContent, PanelFooter } from "../../components/panel";
import { InputMask } from "../../components/inputmask";
import { Button } from "../../components/button";
import { Dialog } from "../../components/dialog";
import { buildValidator, calcularDvSeloIpem, isSeloIpemValido } from "../../utils/fieldValidator";
import { withDialog } from "../../utils/dialogContext";
import { InformationDialog } from "../../components/dialog/InformationDialog";

export const CadastroLoteSeloIpem = withDialog(({showDialog}) => {

	const [params, setParams] = useState({
		inicio: "",
		fim: "",
		responsavel: null
	});

	const [messages, setMessages] = useState(buildValidator());

	const [visible, setVisible] = useState(true);
	const [blocked, setBlocked] = useState(false);

	function handleChange(event) {
		setParams({...params, [event.name]: event.value});
	}

	async function confirmarCadastroLote() {
		if (!messages.isEmpty()) {
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
			return;
		}

		let numero = Math.floor(Number(params.inicio) / 10);
		const fim = Math.floor(Number(params.fim) / 10);
		
		if (numero < fim) {
			setBlocked(true);
			while (numero <= fim) {
				await seloIpemService.salvar({
					status: "LIVRE",
					numero: `${numero}${calcularDvSeloIpem(String(numero))}`,
					responsavel: params.responsavel
				});
				++numero;
			}
			setBlocked(false);
			setVisible(false);
		} else {
			showDialog(<InformationDialog header="Informação" message="O número inicial deve ser menor que o final"/>);
		}
	}

	return (
		<Dialog style={{width: "600px"}} header="Cadastro em Lote de Selos do IPEM" visible={visible} modal onHide={() => setVisible(false)}>
			<PanelContent>
				<InputMask required onBlur={() => {
					messages.inicio = isSeloIpemValido(params.inicio);
					setMessages(messages);
				}} invalid={messages.inicio} unmask mask="99.999.999-9" col={6} label="Número Inicial" value={params.inicio} onChange={handleChange} name="inicio"/>
				<InputMask required onBlur={() => {
					messages.fim = isSeloIpemValido(params.fim);
					setMessages(messages);
				}} invalid={messages.fim} unmask mask="99.999.999-9" col={6} label="Número Final" value={params.fim} onChange={handleChange} name="fim"/>
				<UsuarioAutoComplete col={12} label="Responsável" value={params.responsavel} onChange={handleChange} name="responsavel"/>
			</PanelContent>
			<PanelFooter>
				<Button disabled={blocked} loading={blocked} label="Cadastrar" icon="fa-solid fa-save" onClick={confirmarCadastroLote}/>
			</PanelFooter>
		</Dialog>
	);

});