import React from "react";
import {Dropdown as PDropdown} from "primereact/dropdown";
import classNames from "classnames";
import {calcularTamanho} from "../../service/guiHelper";

export function Dropdown(props) {

	function handleChange(event) {
		if (props.onChange) {
			props.onChange({name: props.name, value: event.target.value, index: props.index});
		}
	}

	const classes = calcularTamanho(props.col) + ' ' + (props.className || "");

	const inputClasses = classNames({
		"p-invalid": props.invalid
	});

	const newLocal = props.required ? <small style={{color: props.value ? "transparent" : "#888", userSelect: "none"}} className="block">Campo obrigatório</small> : <small>&nbsp;</small>;

	return (
		<div className={classes}>
			<label>{props.label}</label>
			{props.labelRight ? <label style={{float: "right"}}>{props.labelRight}</label> : null}
			<PDropdown emptyMessage="Nenhuma opção disponível" {...props} placeholder={props.placeholder} className={inputClasses} onChange={handleChange} readOnly={props.readOnly}/>
			{props.reduced ? null : (props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : newLocal)}
		</div>
	);

}

export function SimpleDropdown(props) {

	function handleChange(event) {
		if (props.onChange) {
			props.onChange({name: props.name, value: event.target.value, index: props.index});
		}
	}

	const inputClasses = classNames({
		"p-invalid block": props.invalid
	});

	return (
		<PDropdown {...props} className={inputClasses} onChange={handleChange}/>
	);

}