import React, {useContext} from "react";

export const DialogContext = React.createContext({
	dialogs: [],
	showDialog: () => {}
});

export const DialogProvider = DialogContext.Provider;

export const withDialog = Component => props => (
	<DialogContext.Consumer>
		{({showDialog}) => <Component {...props} showDialog={showDialog}/>}
	</DialogContext.Consumer>
);

export function useDialog() {
	return useContext(DialogContext);
}
