import React, {useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {Button} from "../../components/button";
import {InputText} from "../../components/inputtext";
import {useDialog} from "../../utils/dialogContext";
import {InformationDialog} from "../../components/dialog/InformationDialog";

export function Observacao({header, message, onModalClose, required}) {
	const [visible, setVisible] = useState(true);
	const [observacao, setObservacao] = useState("");
	const {showDialog} = useDialog();
	async function handleClose() {
		if (required && !observacao?.trim().length) {
			showDialog(<InformationDialog header="Aviso" message="O preenchimento do texto é obrigatório."/>);
			return;
		}
		if (onModalClose) {
			await onModalClose(observacao);
		}
		setVisible(false);
	}
	return (
		<Dialog visible={visible} style={{width: "550px"}} header={header || "Observações"} closable={false}>
			<PanelContent>
				<InputText
					label={message || ""}
					multiline
					style={{height: "8em"}}
					value={observacao}
					onChange={e => setObservacao(e.value)}
				/>
			</PanelContent>
			<DialogFooter>
				<Button icon="fas fa-save" success label="OK" onClick={handleClose}/>
			</DialogFooter>
		</Dialog>
	);
}
