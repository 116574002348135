import React, {useState} from "react";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {Button} from "../../components/button";
import {formatCurrency} from "../../utils/numberFormatter";

export function SelecionarOrdemServicoNotaFiscal({notasFiscais, onModalClose, onSelect}) {

	const [visible, setVisible] = useState(true);

	function selecionarNotaFiscal(event) {
		if (onSelect) {
			onSelect(event.data);
		}
		handleFechar();
	}

	function handleFechar() {
		setVisible(false);
		if (onModalClose) {
			onModalClose();
		}
	}

	function cadastrarNovaNota() {
		if (onSelect) {
			onSelect({
				id: null,
				numero: notasFiscais[0].numero,
				cliente: notasFiscais[0].cliente,
				valor: null,
				descricao: null,
				observacoes: null,
				data: null
			});
		}
		handleFechar();
	}

	return (
		<Dialog header="Selecionar Nota Fiscal" visible={visible} onHide={handleFechar} style={{width: "90% !important", maxWidth: "900px !important"}}>
			<PanelContent>
				<div className="col-12">
					Foram encontradas várias notas fiscais com o mesmo número. Por favor, selecione a correta (ou clique em "Cadastrar Nova Nota", caso seja uma nova entrada).
				</div>
				<div className="col-12">
					<DataTable onRowClick={selecionarNotaFiscal} value={notasFiscais}>
						<Column style={{cursor: "pointer", textAlign: "right"}} header="Nº" field="numero"/>
						<Column style={{cursor: "pointer"}} header="Chave" field="chave" body={nf => nf.chave?.match(/.{1,4}/g).join("-")}/>
						<Column style={{cursor: "pointer"}} header="Descrição" field="descricao"/>
						<Column style={{cursor: "pointer", textAlign: "right"}} header="Valor" field="valor" body={nf => formatCurrency(nf.valor)}/>
					</DataTable>
				</div>
			</PanelContent>
			<DialogFooter>
				<Button label="Cadastrar Nova Nota" icon="fa-solid fa-plus" onClick={cadastrarNovaNota}/>
			</DialogFooter>
		</Dialog>
	);

}
