import React, {useState} from 'react';

import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';

import {ClienteAutoComplete} from '../../components/autocomplete/ClienteAutoComplete';
import {Button} from '../../components/button';
import {DataTable} from '../../components/datatable';
import {clienteService} from '../../service/clienteService';
import {withDialog} from "../../utils/dialogContext";
import {ConfirmDialogV2} from "../../components/confirmdialog";

export const EditarCliente = withDialog(({contrato, setContrato, messages, setMessages, showDialog}) => {

    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [clienteAdicional, setClienteAdicional] = useState()

    const adicionarCliente = () => {  
        if(!clienteAdicional?.id) return;

        const clienteRepitido = contrato.clientes.filter(cli => cli.id === clienteAdicional.id);
        const eOClientePrincipal = contrato.cliente?.id === clienteAdicional.id

        if(!!clienteRepitido[0]) { 
            messages.clienteAdicional = "Cliente ja inserido"
            setMessages({...messages});
            return
        }

        if(eOClientePrincipal){
            messages.clienteAdicional = "Este cliente já está insrido na aba dados principais."
            setMessages({...messages});
            return
        }

        messages.clienteAdicional = null
        setMessages({...messages});

        clienteService.buscar(clienteAdicional.id).then(cliente => {
            if(!cliente) return;

            setContrato(contrato =>{
                return {...contrato, clientes: [...contrato.clientes, cliente]}
            })
        })

        setClienteAdicional('')
    }

    const excluirCliente = () => {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os clientes selecionados?" onYes={() => {
            const clientes = contrato.clientes.filter(osi => !itensSelecionados.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
            setContrato({...contrato, clientes})
            setItensSelecionados([]);
        }}/>);
    }
    
    const rightToolbarTemplate = () => {
       return ( 
            <div>
                <Button 
                    icon="pi pi-plus" 
                    onClick={adicionarCliente}
                    disabled={!clienteAdicional?.id || !contrato.cliente?.id}
                />
                <Button
                    danger='true'
                    icon='fa-solid fa-x'
                    onClick={excluirCliente}
                    disabled={itensSelecionados.length === 0}
                />
            </div>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <ClienteAutoComplete  
                col={12} 
                value={clienteAdicional} 
                onChange={(e) => setClienteAdicional(e.value)} 
                label={null}
                invalid={messages.clienteAdicional}
                onBlur={() => {
                    if( !contrato.cliente?.id) return
                    adicionarCliente()
                }} 
            />
        )
    }

    // const razaoSocialTemplate = (data) => {
    //     function capitalizeFirstLetter(string) {
    //         return string.charAt(0).toUpperCase() + string.slice(1);
    //     }

    //     const filial = <i className={`fa-solid fa-store tooltip-${data.id}`} style={{color:'rgb(48, 63, 159)', fontSize: '1.2rem'}}/>
    //     const unico  = <i className={`fa-solid fa-shop tooltip-${data.id}`} style={{color:'rgb(96, 125, 139)', fontSize: '1.2rem'}}/>
    //     const matriz  = <i className={`fa-solid fa-building tooltip-${data.id}`} style={{color:'rgb(244, 67, 54)', fontSize: '1.2rem'}}/>

    //     const icon = data.tipo === 'FILIAL' ?  filial : ( data.tipo === 'UNICO' ? unico : matriz);

    //     return (
    //         <>
    //             <Tooltip target={`.tooltip-${data.id}`} position='left' mouseTrack > 
    //                 <div className="flex align-items-center flex-column justify-content-center p-2">
    //                     <div>{capitalizeFirstLetter(data.tipo.toLowerCase())}</div>
    //                     {
    //                         data.tipo === 'FILIAL' && (
    //                             <>
    //                                 <div className="mt-2"> Dados da Matriz: </div>
    //                                 <div className="mt-2">{`Razão Social: ${data.grupoRazaoSocial}`}</div>
    //                                 <div className="mt-2">{`Nome Fantasia: ${data.grupoNomeFantasia}`}</div>
    //                                 <div className="mt-2">{`${data.cpfCnpj.length === 14 ? 'CNPJ' : 'CPF' }: ${data.cpfCnpj}`}</div>
    //                             </>
    //                         )
    //                     }
    //                 </div>
    //             </Tooltip>
    //             <div> {icon} {data.razaoSocial}</div>
    //         </>
    //     )
    // }

    const templateStatus = (data) => {
        if(data.vwCliente.status === 'ATIVO'){
            return (
                <div style={{display: 'flex', alignItems: "center", justifyContent:"center"}}>
                    <i title="Ativado" className="fa-solid fa-user-tie fa-lg" style={{color:'#00940a'}}/>
                </div>
            )
        }else{
            return (
                <div style={{display: 'flex', alignItems: "center", justifyContent:"center"}}>
                    <i title="Desativado" className="fa-solid fa-user-tie fa-lg" style={{color:'#d10404'}}/>
                </div>
            )
        }
    }

    return (
        <>
            <div className='col-12'>
                <Toolbar className="mb-0 p-1 col-12 grid p-fluid" left={leftToolbarTemplate} right={rightToolbarTemplate}/>
            </div>
            <div className='col-12 p-0'>
                    <DataTable 
                        value={contrato.clientes}
                        emptyMessage='Nenhuma equipamento foi adicionado.'
                        valuelength={contrato.clientes?.length}
                        rows={100}
                        paginator={false}

                        //onRowClick={handleRowClick}
                        selectionMode="checkbox"
                        selection={itensSelecionados}
                        onSelectionChange={e => setItensSelecionados(e.value)}   
                        size='small'
                    >
                        <Column selectionMode="multiple" headerStyle={{width: '1em'}}/>
                        <Column style={{ width: '2em' }} sortable header="Status" body={e => templateStatus(e)}/>
                        <Column sortable field="vwCliente.clienteId" header="Cliente"  style={{ width: '*' }} />
                        <Column 
                            sortable 
                            field="razaoSocial" 
                            header="Razão Social"  
                            style={{ width: '*' }}
                            //body={razaoSocialTemplate}
                        />
                        <Column sortable field="nomeFantasia" header="Nome Fantasia"  style={{ width: '*' }} />
                        <Column sortable field="vwCliente.cpfCnpjFormatado" header="CNPJ"  style={{ width: '*'}} />
                        <Column sortable field="vwCliente.municipio" header="Município"  style={{ width: '*' }} />
                        <Column sortable field="vwCliente.uf" header="UF"  style={{ width: '*', textAlign: 'center' }} />
                    </DataTable>
            </div>
        </>
    );

});
