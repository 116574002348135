import React, { useRef, useState }  from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton } from 'primereact/selectbutton';
import {Button} from "primereact/button";
import {toast} from "react-toastify";
import { Chip } from 'primereact/chip';

import { useAuth } from './context/AuthContext';
import { useAppConfiguracoes } from './context/AppConfiguracoesContext';
import { usuarioService } from './service/usuarioService';
import { Password } from './components/password';
import { InputText } from './components/inputtext';
import { Dialog } from './components/dialog';
import { buildValidator, isPassword, isPasswordEqual } from './utils/fieldValidator';
import { prateleiraService } from './service/prateleiraService';
import { ToggleButton } from './components/togglebutton';

export const AppTopbar = (props) => {

    const {usuario, setUsuario, roles} = useAuth();
    // const {theme, scale, scales, incrementScale, decrementScale, changeTheme, setScale, layoutColorMode} = useAppConfiguracoes()
    const {theme, scale, scales, incrementScale, decrementScale, changeTheme} = useAppConfiguracoes()
    const [messages, setMessages] = useState(buildValidator());


    const [trocarSenha, setTrocarSenha] = useState(false);
    const [senha, setSenha] = useState();
    const [confirmarSenha, setConfirmarSenha] = useState();
    const [usuarioSalvo, setUsuarioSalvo] = useState()
    const [prateleiras, setPrateleiras] = useState([])

    const dialogFuncMap = {
        'trocarSenha': setTrocarSenha,
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        setSenha('')
        setConfirmarSenha('')
    }

    const options = [
        {icon: 'fa-solid fa-sun', value: 'lara-light-blue'},
        {icon: 'fa-solid fa-moon', value: 'lara-dark-blue'},
    ];

    const justifyTemplate = (option) => {
        return <i className={option.icon}></i>;
    }

    ////////////////////////////-------------------------------------------
    const config = useRef(null);
    const profile = useRef(null);
    const prateleira = useRef(null);

    const salvarUsuario = () => {
        messages.senha = isPassword(senha);
        setMessages({...messages});
        messages.confirmarSenha = isPasswordEqual(senha, confirmarSenha);
        setMessages({...messages});

        if(!senha || !confirmarSenha || !!messages.senha || !!messages.confirmarSenha) return;

        toast.promise(
            usuarioService.salvar({...usuarioSalvo, senha: senha}).then(() => {onHide('trocarSenha')}),
            {
                "pending": `Alterando senha. Aguarde...`,
                "success": `Senha alterada com sucesso!`,
                "error": {
                    render(e) {
                        return `Falha ao alterar a senha.`;
                    }
                }
            }
        );
    }

    const alterarSenha = () => {
        dialogFuncMap[`trocarSenha`](true)
    }

    const abrirConfiguracoes = (e, ref) => {
        switch (ref) {
            case "config":
                config.current.toggle(e);
                break;
            case "profile":
                profile.current.toggle(e);
                break;
            case "prateleira":
                prateleira.current.toggle(e);
                prateleiraService.listar([`query=tipo==BANCADA`]).then(setPrateleiras);
                break;
            default:
                break;
        }
        usuarioService.buscar(usuario.id).then(setUsuarioSalvo);
    }

    const decrementScaleOrIcrementScale = (incrementOrDecrement) => {
        clearTimeout(timeoutId);
        let id;
        if(incrementOrDecrement === 'increment'){
            incrementScale()
            id = setTimeout(() => saveUser(scale + 1, theme), 3000);
        }else{
            id = setTimeout(() => saveUser(scale - 1, theme), 3000);
            decrementScale()
        }

        setTimeoutId(id)
    }

    const [timeoutId, setTimeoutId] = useState()

    const saveUser = (scale, theme) => {
        toast.promise(
            usuarioService.salvar({...usuarioSalvo, escalaWeb: scale, tema: theme}),
            {
                "pending": `Salvando Configurações. Aguarde...`,
                "success": `Configuração alterada com sucesso!`,
                "error": {
                    render(e) {
                        return `Falha ao alterar a configuração.`;
                    }
                }
            }
        );
    }

    const salvarPrateleira = () => {
        if (usuario?.bancadaTrabalho === usuarioSalvo?.bancadaTrabalho?.descricao) return;
        setUsuario({...usuario, bancadaTrabalho: usuarioSalvo?.bancadaTrabalho})
        toast.promise(
            usuarioService.atualizarBancada(usuario.id, usuarioSalvo?.bancadaTrabalho?.id),
            {
                "pending": `Salvando Configurações. Aguarde...`,
                "success": `Configuração alterada com sucesso!`,
                "error": {
                    render(e) {
                        return `Falha ao alterar a configuração.`;
                    }
                }
            }
        );
    }

    function toSrc(base64) {
        if (!base64) return;
        return base64.startsWith("data:image") ? base64 : `data:image/jpeg;base64,${base64}`;
    }

    async function limparBancada(e) {
        setUsuario(prevUsuario => ({...prevUsuario, bancadaTrabalho: null}));
        await usuarioService.limparBancada(usuario.id);
        prateleira.current.toggle(e);
    }

    function prateleiraItemTemplate(prateleira) {
        return (
            <ToggleButton
                key={prateleira.id}
                onLabel={prateleira.codigo}
                offLabel={prateleira.codigo}
                onIcon="fa-solid fa-inbox"
                offIcon="fa-solid fa-inbox"
                checked={usuarioSalvo?.bancadaTrabalho?.id === prateleira.id}
                onChange={() => {
                    if (usuarioSalvo?.bancadaTrabalho?.id === prateleira?.id) {
                        setUsuarioSalvo(prevUsuarioSalvo => ({...prevUsuarioSalvo, bancadaTrabalho: null}));
                    } else {
                        setUsuarioSalvo(prevUsuarioSalvo => ({...prevUsuarioSalvo, bancadaTrabalho: prateleira}));
                    }
                }}
                className="w-10rem mb-2"
                col={6}
            />
        )
    }

    return (
        <>
            <div className="layout-topbar">

                <Link to="/clientes" className="layout-topbar-logo">
                    <img src={usuario?.logotipo ? toSrc(usuario.logotipo) : 'assets/layout/images/zecode.svg'} alt="logo"/>
                    <span>TZM Services</span>
                </Link>

                <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                    <i className="pi pi-bars"/>
                </button>

                <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                    <i className="pi pi-ellipsis-v" />
                </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    <li className='flex align-items-center '>
                        <Chip className='h-2rem chip-profile ' label={usuario?.email} icon='fa-solid fa-user'onClick={(e) => abrirConfiguracoes(e, 'profile')} />
                    </li>

                    {
                        roles?.LABT && 
                        <li className='flex align-items-center ml-3'>
                            <Chip 
                                className='h-2rem chip-profile' 
                                label={usuario?.bancadaTrabalho?.descricao || 'Selecione sua bancada de trabalho'}
                                icon='fa-solid fa-inbox'
                                onClick={(e) => abrirConfiguracoes(e, 'prateleira')} 
                            />
                        </li>
                    }
                    <li>
                        <button title='Configurações' className="p-link layout-topbar-button" onClick={(e) => abrirConfiguracoes(e, 'config')}>
                            <i className="pi pi-cog"/>
                            <span>Configurações</span>
                        </button>
                    </li>
                    <li>
                        <button title="Recarregar" className="p-link layout-topbar-button" onClick={() => window.location.reload(true)}>
                            <i className="pi pi-refresh"/>
                            <span>Recarregar</span>
                        </button>
                    </li>
                    <li>
                        <button title='Sair da aplicação' className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="fa-solid fa-right-from-bracket"/>
                            <span>Sair</span>
                        </button>
                    </li>
                </ul>
            </div>
            <OverlayPanel  ref={config}  showCloseIcon id="overlay_panel" style={{width: '250px'}}>
                <div children='layout-config-content' >
                    <h5 className="mt-0">Zoom</h5>
                    <div className="tzm-config-scale">
                        <Button icon="pi pi-minus" onClick={() => decrementScaleOrIcrementScale('decrement')} className="p-button-text" disabled={scale === scales[0]} />
                        {
                            scales.map((item) => {
                                return <i className={classNames('pi pi-circle-on', {'tzm-scale-active': item === scale})} key={item}/>
                            })
                        }
                        <Button icon="pi pi-plus" onClick={() => decrementScaleOrIcrementScale('increment')} className="p-button-text" disabled={scale === scales[scales.length - 1]} />
                    </div>

                    <h5>Tema</h5>
                    <SelectButton
                        className='tzm-theme'
                        value={theme} options={options}
                        itemTemplate={justifyTemplate}
                        onChange={() =>{
                            const newTheme = theme === 'lara-light-blue' ? 'lara-dark-blue' : 'lara-light-blue'
                            const newSchema = theme === 'lara-light-blue' ? 'dark' : 'light'
                            changeTheme(newTheme, newSchema)
                            
                            clearTimeout(timeoutId);
                            const id = setTimeout(() => saveUser(scale, newTheme), 3000);
                            setTimeoutId(id)
                        }}
                    />

                </div>
            </OverlayPanel>
            <OverlayPanel  ref={profile}  showCloseIcon id="overlay_panel" style={{width: '250px'}}>
                <div children='layout-config-content' >
    
                    <h5 className='my-1'>Nome</h5>
                    <InputText 
                        value={usuarioSalvo?.nome}
                        readOnly
                        style={{fontWeight: '700'}}
                    />
                    <h5 className='my-1'>Email</h5>
                    <InputText 
                        value={usuarioSalvo?.email}
                        style={{fontWeight: '700'}}
                        readOnly
                    />
                    
                    <div className='mt-4 flex justify-content-end'>
                        <Button label='Trocar Senha' icon='fa-solid fa-key' onClick={alterarSenha} className="p-button-secondary" />
                    </div>

                </div>
            </OverlayPanel>
            <OverlayPanel onHide={salvarPrateleira}  ref={prateleira}  showCloseIcon id="overlay_panel" style={{width: '280px'}}>
                <div children='layout-config-content' >
                    <div className="grid">
                        {prateleiras?.map(prateleiraItemTemplate)}
                    </div>
                </div>
            </OverlayPanel>
            <Dialog
                onHide={() => onHide('trocarSenha')}
                footer={true}
                header='Alteração de Senha'
                visible={trocarSenha}
                acceptLabel='Salvar'
                rejectLabel='Cancelar'
                onYes={salvarUsuario}
                width='20vw'
                breakpoints={{'960px': '75vw', '640px': '100vw'}}
            >
                <div className='grid p-fluid py-4'>
                    <Password
                        required
                        onBlur={() => {
                            messages.senha = isPassword(senha);
                            setMessages({...messages});
                        }}
                        invalid={messages.senha}
                        col={12}
                        name="senha"
                        label="Nova senha"
                        value={senha}
                        onChange={(event) => setSenha(event.value)}
                    />
                    <Password
                        required
                        onBlur={() => {
                            messages.confirmarSenha = isPasswordEqual(senha, confirmarSenha);
                            setMessages({...messages});
                        }}
                        invalid={messages.confirmarSenha}
                        col={12}
                        name="confirmarSenha"
                        label="Confirma a nova senha"
                        value={confirmarSenha}
                        onChange={(event) => setConfirmarSenha(event.value)}
                    />
                </div>
            </Dialog>
        </>
    );
}
