import moment from "moment";
import {BlockUI} from "primereact/blockui";
import {TabPanel} from "primereact/tabview";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {Button} from "../../components/button";
import {Calendar} from "../../components/calendar";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {Dropdown} from "../../components/dropdown";
import {OrdemServicoFluxoDropdown} from "../../components/dropdown/ordemServicoFluxoDropdown";
import {FormTab} from '../../components/formtab';
import {InputMask} from "../../components/inputmask";
import {InputText} from "../../components/inputtext";
import {PanelContent} from "../../components/panel";
import {useAuth} from "../../context/AuthContext";
import {useBreakpoint} from "../../context/BreakpointContext";
import {arquivoService} from "../../service/arquivoService";
import {eventoService} from "../../service/eventoService";
import {ordemServicoFluxoService} from "../../service/ordemServicoFluxoService";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";
import {iconStatus, labelStatus, optionsStatus, ordemServicoService} from "../../service/ordemServicoService";
import {useDialog} from "../../utils/dialogContext";
import {
	buildValidator,
	isEmpty,
	isEntityRequired,
	isNotNull,
	isRequiredEmail,
	isRequiredNome
} from "../../utils/fieldValidator";
import {EditarEvento} from "../Eventos/EditarEvento";
import {InformationDialog} from '../../components/dialog/InformationDialog';
import {ordemServicoNotaFiscalService} from "../../service/ordemServicoNotaFiscalService";
import {InputTextarea} from '../../components/inputtextarea';
import {Acompanhamento} from "../__Commons/Acompanhamento";
import {SLA} from "../__Commons/SLA";
import {If} from '../../components/conditional/If';
import {ClienteContratoDropdown} from "../../components/autocomplete/ClienteContratoDropdown";
import {Tooltip} from "primereact/tooltip";
import {EditarOrdemServicoLaudo} from "../OrdensServico/EditarOrdemServicoLaudo";
import {TrocarContrato} from "../OrdensServico/TrocarContrato";
import {EditarOrdemServicoItem, EditarOrdemServicoItemFields} from "./EditarOrdemServicoItem";
import {EditarAcompanhamento} from "../OrdensServico/EditarAcompanhamento";
import {EditarLaudo} from "../OrdensServico/EditarLaudo";
import {EditarOrdemServicoEncaminhamentoLaboratorio} from "./EditarOrdemServicoEncaminhamentoLaboratorio";
import {Checkbox} from "../../components/checkbox";
import {contratoService} from "../../service/contratoService";

export function renderPosicaoPrevisaoAtendimento(ospa) {
	if (ospa.inicio) {
		if (ospa.fim) {
			if (moment(ospa.previsao).isBefore(moment(ospa.fim))) {
				return <i className="fa-solid fa-circle" style={{color: "#F44336"}}/>;
			}
			return <i className="fa-solid fa-circle" style={{color: "#4CAF50"}}/>;
		}
		if (ospa.previsao) {
			if (moment(ospa.previsao).isBefore(moment())) {
				return <i className="fa-solid fa-circle" style={{color: "#F44336"}}/>;
			}
		}
	}
	return <i className="fa-solid fa-hourglass-half" style={{color: "#B0BEC5"}}/>;
}

export function EditarOrdemServicoSuporte() {

	const {id} = useParams();
	const navigate = useNavigate();
	const {showDialog} = useDialog();
	const {roles, usuario} = useAuth();
	const {breakpoint} = useBreakpoint();
	const isLargeDevice = breakpoint === "lg" || breakpoint === "md";
	const [loading, setLoading] = useState(false);
	const [etapas, setEtapas] = useState([]);
	const [ordemServico, setOrdemServico] = useState({
		...ordemServicoService.criar(),
		tipo: "SUPORTE",
		operacao: "SUPORTE",
		itens: [{
			...ordemServicoItemService.criar(),
			sequencia: 1
		}]
	});
	const [item, setItem] = useState(null);
	const [itemIndex, setItemIndex] = useState(-1);
	const [laudo, setLaudo] = useState(null);
	const [acompanhamento, setAcompanhamento] = useState(null);
	const [messages, setMessages] = useState({...buildValidator(), itens: []});
	const [evento, setEvento] = useState(null);
	const [fluxos, setFluxos] = useState([]);
	const [blocked, setBlocked] = useState(false);
	const [trocarFluxo, setTrocarFluxo] = useState(false);
	const [trocarEtapa, setTrocarEtapa] = useState(false);

	async function handleChange(event) {
		switch (event.name) {
			case "_fluxo":
				if (event.value) {
					ordemServico._fluxoAlterado = true;
					ordemServico.etapas = [...event.value?.etapas];
					ordemServico.etapa = ordemServico.etapas.sort((a, b) => a.sequencia - b.sequencia)[0];
					ordemServico.operacao = event.value?.operacao;
					ordemServico.notificarCliente = event.value?.notificarCliente;
					setEtapas(ordemServico.etapas.map(e => ({label: e.descricao, value: e})));
				} else {
					ordemServico.etapas = [];
					ordemServico.etapa = null;
					setEtapas([]);
				}
				break;
			case "contrato":
				if (event.value?.id) {
					ordemServico.condicaoPagamento = event.value.condicaoPagamento;
				}
				if (ordemServico.id && ordemServico.contrato?.id !== event.value?.id && ordemServico.itens?.some(i => i.pecas?.length || i.servicos?.length)) {
					showDialog(<TrocarContrato ordemServico={ordemServico} novoContrato={event.value} onModalClose={setOrdemServico}/>);
					return;
				}
				break;
			case "responsavel":
				if (event.value?.id) {
					event.value._manual = true;
				}
				break;
			case "status":
				ordemServico._status = "manual";
				break;
			case "cliente":
				if (event.value?.id) {
					ordemServico.clienteFaturamento = event.value;
				} else {
					ordemServico.clienteFaturamento = null;
				}
				setMessages(prevMessages => ({...prevMessages, cliente: isEntityRequired(event.value)}));
				break;
			case "etapa":
				ordemServico._etapaAlterada = true;
				if (ordemServico.operacao === "LABORATORIO" && event.value?.status === "FATURAMENTO" && !ordemServico.notaFiscal?.numero && !ordemServico.naoGerarRetorno) {
					showDialog(<InformationDialog header="Informação" message="Não é possível encaminhar a ordem de serviço para faturamento sem nota fiscal de entrada."/>);
					return;
				}
				break;
			default:
				break;
		}
		ordemServico[event.name] = event.value;
		setOrdemServico({...ordemServico, _alterado: !event.automatico});
	}

	useEffect(() => {
		ordemServicoFluxoService.listar(["descricao=Suporte"]).then(setFluxos);
		if (id !== "new") {
			ordemServicoService.buscarOrdemServico(id).then(ordemServico => {
				setEtapas(ordemServico.etapas.map(e => ({label: e.descricao, value: e})));
				if (ordemServico.acompanhamentos) {
					ordemServico.acompanhamentos = ordemServico.acompanhamentos.sort((a, b) => {
						if (a.inicio !== b.inicio) {
							return a.inicio.localeCompare(b.inicio);
						}
						return a.fim ? 1 : b.fim ? -1 : 0;
					});
				}
				setOrdemServico(ordemServico);
			});
		}
	}, [id]);

	function salvarItem(item) {
		const totalPecas = item.pecas.map(osip => osip.valorTotal || 0).reduce((a, b) => a + b, 0);
		const totalServicos = item.servicos.map(osis => osis.valorTotal || 0).reduce((a, b) => a + b, 0);
		item.valorTotal = totalPecas + totalServicos;
		const index = ordemServico.itens?.findIndex(osi => (osi._key && item._key === osi._key) || (osi.id && item.id === osi.id));
		if (index > -1) {
			ordemServico.itens[index] = item;
		} else {
			ordemServico.itens.push(item);
		}
		setOrdemServico({...ordemServico});
		setMessages(ordemServicoService.validar(ordemServico));
		setItem(null);
	}

	async function salvarAcompanhamento(acompanhamento) {
		const index = ordemServico.acompanhamentos.findIndex(osa => (osa._key && osa._key === acompanhamento._key) || (osa.id && osa.id === acompanhamento.id));
		if (index > -1) {
			ordemServico.acompanhamentos[index] = acompanhamento;
		} else {
			ordemServico.acompanhamentos.push(acompanhamento);
		}
		setOrdemServico({...ordemServico});
		setLoading(true);
		await handleSalvar();
		if (["Laboratório", "Laboratorio"].includes(acompanhamento.etapa?.descricao)) {
			setAcompanhamento(null);
			setLoading(false);
		} else {
			handleVoltar();
		}
	}

	async function validarSalvar() {
		setLoading(true);
		const messages = ordemServicoService.validar(ordemServico);
		setMessages(messages);
		if (!messages.isEmpty()) {
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
			setLoading(false);
			return;
		}
		if (!ordemServico.id && !ordemServico.contrato?.id) {
			if (await contratoService.hasContratoSuporte(ordemServico.cliente?.id)) {
				showDialog(
					<ConfirmDialogV2
						red
						message="Este cliente possui contrato, mas o equipamento não está coberto. Tem certeza de que deseja continuar com o suporte?"
						onYes={handleSalvar}
						onNo={() => setLoading(false)}
					/>
				);
			} else {
				showDialog(
					<ConfirmDialogV2
						red
						message="Este cliente não possui cobertura contratual e esta ordem de serviço será tratada como avulsa. Tem certeza de que deseja continuar com o suporte?"
						onYes={handleSalvar}
						onNo={() => setLoading(false)}
					/>
				);
			}
		} else {
			await handleSalvar();
		}
	}

	async function handleSalvar() {
		if (ordemServico.itens && ordemServico.notaFiscal?.numero && ordemServico.notaFiscal.itens?.length) {
			const checks = [];
			for (const item of ordemServico.itens) {
				if (item.equipamento?.id && !ordemServico.notaFiscal.itens?.some(nfi => nfi.equipamento?.id === item.equipamento.id)) {
					checks.push(`${item.equipamento.autoComplete} não pertence à nota fiscal desta OS`);
				}
			}
			if (checks.length) {
				showDialog(<InformationDialog header="Informação" message={checks.map(c => <div key={c}>{c}</div>)}/>);
				return;
			}
		}
		setBlocked(true);
		setLoading(false);
		if (ordemServico.notaFiscal?.numero && (!ordemServico.notaFiscal.id || ordemServico.notaFiscal._alterado)) {
			ordemServico.notaFiscal.postBack = true;
			ordemServico.notaFiscal.cliente = ordemServico.cliente;
			ordemServico.notaFiscal = await ordemServicoNotaFiscalService.salvar(ordemServico.notaFiscal);
		} else if (!ordemServico.notaFiscal?.numero) {
			ordemServico.notaFiscal = null;
		}
		if (!ordemServico.id) {
			if (ordemServico._fluxo?.id) {
				ordemServico.fluxo = `${ordemServico._fluxo.descricao} (v${ordemServico._fluxo.versao})`;
				ordemServico.operacao = ordemServico._fluxo.operacao;
			}
			const acompanhamento = ordemServicoService.criarAcompanhamento();
			acompanhamento.etapa = ordemServico.etapa;
			acompanhamento.observacoes = "Ordem de serviço iniciada";
			acompanhamento.atribuinte = usuario;
			acompanhamento.responsavel = ordemServico.responsavel;
			acompanhamento.operacao = ordemServico.operacao;
			ordemServico.acompanhamentos.push(acompanhamento);
		} else {
			if (ordemServico._fluxoAlterado) {
				ordemServico.fluxo = `${ordemServico._fluxo.descricao} (v${ordemServico._fluxo.versao})`;
				ordemServico.operacao = ordemServico._fluxo.operacao;
				const acompanhamento = ordemServicoService.criarAcompanhamento();
				acompanhamento.etapa = ordemServico.etapa;
				acompanhamento.observacoes = "Fluxo alterado manualmente";
				acompanhamento.atribuinte = usuario;
				acompanhamento.responsavel = ordemServico.responsavel;
				ordemServico.acompanhamentos[ordemServico.acompanhamentos.length - 1].tipo = "CORRECAO";
				ordemServico.acompanhamentos.push(acompanhamento);
				ordemServico._fluxoAlterado = false;
			}
			if (ordemServico._etapaAlterada) {
				const acompanhamento = ordemServicoService.criarAcompanhamento();
				acompanhamento.inicio = moment().add(1, "seconds").format("YYYY-MM-DDTHH:mm:ss");
				acompanhamento.etapa = ordemServico.etapa;
				acompanhamento.observacoes = "Etapa alterada manualmente";
				acompanhamento.atribuinte = usuario;
				acompanhamento.responsavel = ordemServico.responsavel;
				ordemServico.acompanhamentos[ordemServico.acompanhamentos.length - 1].tipo = "CORRECAO";
				ordemServico.acompanhamentos.push(acompanhamento);
				ordemServico._etapaAlterada = false;
			}
		}
		setTrocarFluxo(false);
		setTrocarEtapa(false);
		const acompanhamento = ordemServico.acompanhamentos[ordemServico.acompanhamentos.length - 1];
		if (acompanhamento) {
			ordemServico.etapa = acompanhamento.etapa;
			if (ordemServico.responsavel?._manual) {
				acompanhamento.atribuinte = usuario;
				acompanhamento.responsavel = ordemServico.responsavel;
			} else {
				ordemServico.responsavel = acompanhamento.responsavel;
			}
		}
		if (ordemServico.assinatura) {
			if (ordemServico.assinatura.conteudo?.conteudo?.length) {
				ordemServico.assinatura.id = (await arquivoService.salvar({...ordemServico.assinatura, postBack: true})).id;
			} else if (!ordemServico.assinatura.id) {
				ordemServico.assinatura = null;
			}
		}
		if (ordemServico.status !== "CANCELADA" && ordemServico.etapa.status && ordemServico._status !== "manual") {
			ordemServico.status = ordemServico.etapa.status;
		}
		if (ordemServico.status === "FECHADA" && !ordemServico.fim?.length) {
			ordemServico.fim = moment().format("YYYY-MM-DDTHH:mm:ss");
		}
		ordemServico._alterado = false;
		const itens = ordemServico.itens;
		if (!ordemServico.id) {
			ordemServico.equipamento_ = itens[0].equipamento;
		}
		return await toast.promise(
			ordemServicoService.salvar({...ordemServico, postBack: true}).then(async ordemServico => {
				const os = {
					id: ordemServico.id,
					status: ordemServico.status,
					numero: ordemServico.numero,
					notaFiscal: ordemServico.notaFiscal
				};
				ordemServico.itens[0] = await ordemServicoItemService.salvarDependencias({...itens[0], ordemServico: os, postBack: true});
				await ordemServicoService.atualizarValoresOrdemServico(ordemServico.id);
				if (id !== ordemServico.id) {
					navigate(`/suporte/${ordemServico.id}`);
				} else {
					setOrdemServico({...ordemServico});
				}
				setBlocked(false);
				return ordemServico;
			}),
			{
				"pending": `Salvando ordem de serviço. Aguarde...`,
				"success": `Ordem de serviço salva com sucesso!`,
				"error": {
					render(e) {
						return `Falha ao salvar ordem de serviço: ${e.data?.response?.data}`;
					}
				}
			}
		);
	}

	function registrarEvento() {
		const notificarCliente = ordemServico.etapa?.notificarCliente;
		setEvento({...eventoService.criar(), notificarCliente, usuario: usuario.email, tipo: "REGISTRO_ACOMPANHAMENTO"});
	}

	function avancarEtapa() {
		const messages = ordemServicoService.validar(ordemServico);
		if (ordemServico.status === "FATURAMENTO" && !ordemServico.faturamentoData) {
			showDialog(<InformationDialog header="Informação" message="Para avançar do faturamento, você deve aprová-lo primeiro."/>);
			return;
		}
		if (!messages.isEmpty()) {
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
			setMessages({...messages});
		} else {
			setAcompanhamento({...ordemServicoService.criarAcompanhamento(), operacao: ordemServico.operacao});
		}
	}

	function handleVoltar(ignorarAlteracoes = false) {
		if (ordemServico._alterado && ignorarAlteracoes !== true) {
			showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja descartar as alterações?" onYes={() => navigate("/suporte")}/>);
		} else {
			navigate("/suporte");
		}
	}

	async function salvarEvento(evento) {
		if (ordemServico.acompanhamentos?.length) {
			const acompanhamento = ordemServico.acompanhamentos[ordemServico.acompanhamentos.length - 1];
			evento = await eventoService.salvar({...evento, postBack: true});
			await ordemServicoService.salvarAcompanhamentoEvento({eventos: evento.id, acompanhamento: acompanhamento.id});
			acompanhamento.eventos.push(evento);
			setEvento(null);
		}
	}

	function headerTemplate(e) {
		let style = {};
		switch (e.index) {
			case 0:
				if (messages.responsavel || messages.cliente || messages.clienteFaturamento || messages.etapa) {
					style.color = "#F44336";
				}
				break;
			case 1:
				if (messages.itens?.some(mi => !isEmpty(mi))) {
					style.color = "#F44336";
				}
				break;
			case 2:
				break;
			case 3:
				break;
			default:
				break;
		}
		return (
			<div className={`${e?.className}`} aria-controls={`${e.ariaControls}`} onClick={e.onClick} onKeyDown={e.onKeyDown} style={style}>
				<i className={`${e?.leftIconElement?.props?.className}`}/>
				<div className={`${e?.titleClassName}`} >{e?.titleElement?.props?.children}</div>
			</div>
		);
	}

	if (acompanhamento) {
		return (
			<EditarAcompanhamento
				ordemServico={ordemServico}
				setOrdemServico={setOrdemServico}
				acompanhamento={acompanhamento}
				setAcompanhamento={setAcompanhamento}
				onSalvar={salvarAcompanhamento}
				required
			/>
		);
	}

	if (evento) {
		return <EditarEvento evento={evento} setEvento={setEvento} onSalvar={salvarEvento} onVoltar={() => setEvento(null)}/>
	}

	if (laudo) {
		return <EditarLaudo ordemServico={ordemServico} setOrdemServico={setOrdemServico} laudo={laudo} setLaudo={setLaudo}/>
	}

	if (item) {
		return (
			<EditarOrdemServicoItem index={itemIndex}
			                        messages={messages}
			                        setMessages={setMessages}
			                        ordemServico={ordemServico}
			                        setOrdemServico={setOrdemServico}
			                        item={item}
			                        setItem={setItem}
			                        setLaudo={setLaudo}
			                        onSalvar={salvarItem}
			/>
		);
	}

	function headerRight() {
		if (["PREVENTIVA", "PROJETO"].includes(ordemServico.tipo)) {
			return <>{iconStatus[ordemServico.status]} <b>{labelStatus[ordemServico.status]}</b></>;
		} else {
			return (					
				<>
					<If condition={ordemServico.pedidoVendaNumero}>
						<b>{"Pedido Venda Retorno Nº " +  ordemServico.pedidoVendaNumero}</b><b className="mx-2 text-lg"> | </b>
					</If>
					<If condition={ordemServico.pedidoVendaFaturamentoNumero}>
						<b>{"Pedido Venda Serviço Nº " +  ordemServico.pedidoVendaFaturamentoNumero}</b><b className="mx-2 text-lg"> | </b>
					</If>
					<If condition={ordemServico.pedidoVendaFaturamentoProdutoNumero}>
						<b>{"Pedido Venda Produto Nº " +  ordemServico.pedidoVendaFaturamentoProdutoNumero}</b><b className="mx-2 text-lg"> | </b>						
					</If>
					<b>Nº OS Omega {ordemServico?.itens[0]?.numero}</b>
					<b className="mx-2 text-lg"> | </b>
					{iconStatus[ordemServico.status]}
					<b className="ml-1">{labelStatus[ordemServico.status]}</b>
				</>
			);
		}
	}

	function habilitarTrocaEtapa() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja trocar manualmente a etapa da ordem de serviço?" onYes={() => {
			setTrocarEtapa(true);
		}}/>);
	}

	function habilitarTrocaFluxo() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja trocar o fluxo de operação da ordem de serviço?" onYes={() => {
			setTrocarFluxo(true);
			setTrocarEtapa(true);
		}}/>);
	}

	const hasOrcamentoAberto = ordemServico.status === "FECHADA" || ordemServico.itens?.some(osi => osi.orcamentos?.some(osio => ["GERADO", "ENVIADO"].includes(osio.status)));

	return (
		<BlockUI blocked={blocked}>
			<FormTab
				podeSalvar={!!roles.OSSC && ordemServico.operacao === "SUPORTE"}
				podeDeletar={false}
				descricao={`Ordem de Serviço${ordemServico.numero ? ` nº ${ordemServico.numero}` : ""}`}
				service={ordemServicoService}
				value={ordemServico}
				blocked={id !== "new" && !ordemServico.id}
				header_right={headerRight}
				botoes_adicionais={
					<>
						{ordemServico.id && ordemServico.operacao === "SUPORTE" ? <Button disabled={loading} label={isLargeDevice ? "Registrar Evento" : null} autowidth={!isLargeDevice.toString()} warning icon="fa-solid fa-file-lines" onClick={registrarEvento}/> : null}
						{ordemServico.id && ordemServico.operacao === "SUPORTE" && ordemServico.etapa?.proximas?.length ? <Button disabled={loading} label={isLargeDevice ? "Avançar Etapa" : null} autowidth={!isLargeDevice.toString()} icon="fa-solid fa-arrow-right" onClick={avancarEtapa}/> : null}
					</>
				}
				carregando={loading}
				onVoltar={handleVoltar}
				onSalvar={validarSalvar}
			>
				<TabPanel headerTemplate={headerTemplate} header="Dados Principais" leftIcon="fa-solid fa-database">
					<PanelContent>
						<InputText readOnly col={2} name="numero" label="Número da OS" value={ordemServico.numero}/>
						<Dropdown disabled={!roles.OSSH} options={optionsStatus} col={2} name="status" label="Status" value={ordemServico.status} onChange={handleChange}/>
						<Calendar showTime onChange={handleChange} readOnly={!roles.OSSI} col={2} name="inicio" label="Início" value={ordemServico.inicio}/>
						<Calendar showTime onChange={handleChange} readOnly={!roles.OSSI} col={2} name="fim" label="Fim" value={ordemServico.fim}/>
						<div className="col-4"/>
						{ordemServico.contrato?.valorFranquia ? <Calendar showTime onChange={handleChange} readOnly={!roles.OSSI} col={2} name="dataConsumoFranquia" label="Data de Consumo da Franquia" value={ordemServico.dataConsumoFranquia}/> : null}
						{
							ordemServico.id && !trocarFluxo
							? <InputText labelRight={!!roles.OSSE && !trocarFluxo ? <span style={{color: "#2196F3", cursor: "pointer"}} onClick={habilitarTrocaFluxo}>Trocar</span> : null} col={3} readOnly label="Fluxo de Operação" name="fluxo" value={ordemServico.fluxo}/>
							: <OrdemServicoFluxoDropdown operacao="SUPORTE" fluxos={fluxos} required invalid={messages._fluxo} onBlur={() => {
								messages._fluxo = isEntityRequired(ordemServico._fluxo);
								setMessages({...messages});
							}} disabled={!roles.OSSE} dataKey="id" col={3} label="Fluxo de Operação" tipo={ordemServico.tipo} name="_fluxo" value={ordemServico._fluxo} onChange={handleChange}/>
						}
						<Dropdown dataKey="id" required labelRight={!!roles.OSSF && !trocarEtapa && ordemServico.id ? <span style={{color: "#2196F3", cursor: "pointer"}} onClick={habilitarTrocaEtapa}>Trocar</span> : null} invalid={messages.etapa} onBlur={() => {
							messages.etapa = isNotNull(ordemServico.etapa);
							setMessages({...messages});
						}} disabled={(ordemServico.id || !roles.OSSF) && !trocarEtapa} col={3} label="Etapa" name="etapa" value={ordemServico.etapa} options={etapas} onChange={handleChange}/>
						<UsuarioAutoComplete forceSelection onBlur={() => {
							messages.responsavel = isEntityRequired(ordemServico.responsavel);
							setMessages({...messages});
						}} name="responsavel" invalid={messages.responsavel} label="Responsável" readOnly={!roles.OSSG} col={6} value={ordemServico.responsavel} onChange={handleChange}/>
						<ClienteAutoComplete readOnly={ordemServico.id} required onBlur={() => {
							messages.cliente = isEntityRequired(ordemServico.cliente);
							setMessages({...messages});
						}} invalid={messages.cliente} col={6} value={ordemServico.cliente} name="cliente" onChange={handleChange}/>
						<ClienteContratoDropdown
							os
							autoSelect
							tipo={ordemServico.tipo}
							col={3}
							className="clienteContrato"
							disabled={ordemServico.id}
							serial={ordemServico.itens[0].equipamento?.serial || "???"}
							cliente={ordemServico.cliente}
							value={ordemServico.contrato}
							onChange={handleChange}
						/>
						{hasOrcamentoAberto ? <Tooltip position="bottom" target=".clienteContrato" content="Não é possível trocar o contrato com orçamentos em aberto"/> : null}
						<InputText readOnly col={3} label="SLA" value={ordemServico.contrato?.acordoNivelServicoSuporte?.descricao}/>
						<InputText readOnly col={5} label="Endereço" value={ordemServico.cliente?.vwCliente?.endereco || ""}/>
						<InputMask readOnly col={2} name="cep" label="CEP"  mask='99999-999' value={ordemServico.cliente?.vwCliente?.cep || ""} />
						<InputText readOnly col={2} label="Município" value={ordemServico.cliente?.vwCliente?.municipio || ""}/>
						<InputText readOnly col={1} label="UF" value={ordemServico.cliente?.vwCliente?.uf || ""}/>
						<InputText readOnly col={2} label="Contato" value={ordemServico.cliente?.contato || ""}/>
						<ClienteAutoComplete
							required
							onBlur={() => {
								messages.clienteFaturamento = isEntityRequired(ordemServico.clienteFaturamento);
								setMessages({...messages});
							}}
							invalid={messages.clienteFaturamento}
							col={4}
							value={ordemServico.clienteFaturamento}
							name="clienteFaturamento"
							onChange={handleChange}
							label="Cliente para Faturamento"
						/>
						<InputText col={2} name="numeroPedidoCliente" label="Nº do Pedido do Cliente" value={ordemServico.numeroPedidoCliente} onChange={handleChange}/>
						<InputText col={2} name="numeroPedidoCompra" label="Nº do Pedido de Compra" value={ordemServico.numeroPedidoCompra} onChange={handleChange}/>
						<InputText col={4} name="responsavelInstrumentos" label="Responsável pelos Equipamentos" value={ordemServico.responsavelInstrumentos} onChange={handleChange}/>
						<InputText
							col={4}
							name="solicitanteNome"
							label="Nome do Solicitante"
							value={ordemServico.solicitanteNome}
							onChange={handleChange}
							onBlur={() => {
								if (ordemServico?.solicitanteNome?.length > 0) {
									messages.solicitanteNome = isRequiredNome(ordemServico.solicitanteNome);
									setMessages({...messages});
								} else {
									messages.solicitanteNome = null;
									setMessages({...messages});
								}
							}}
							invalid={messages.solicitanteNome}
						/>
						<InputMask unmask mask="(99) 99999-999?9" col={2} name="solicitanteContato" label="Contato do Solicitante" value={ordemServico.solicitanteContato} onChange={handleChange}/>
						<InputText 
							col={3} 
							name="emailContato" 
							label="Email do Contato" 
							value={ordemServico.emailContato} 
							onChange={handleChange}
							invalid={messages.emailContato}
							onBlur={() => {
								if(ordemServico?.emailContato?.length > 0) {
									messages.emailContato = isRequiredEmail(ordemServico.emailContato);
									setMessages({...messages});
								}else{
									messages.emailContato = null;
									setMessages({...messages});
								}
							}}
						/>
						<UsuarioAutoComplete
							disabled={ordemServico.id}
							tipo="CLIENTE"
							forceSelection
							invalid={messages.responsavelPortal}
							name="responsavelPortal"
							label="Usuário Portal"
							col={3}
							value={ordemServico.responsavelPortal}
							onChange={handleChange}
						/>
						<InputTextarea style={{height: "10em"}} col={12} name="observacao" label="Detalhes do Serviço" value={ordemServico.observacao} onChange={handleChange}/>
						<Checkbox style={{marginTop: ".5em"}} col={2} label="Notificar cliente" name="notificarCliente" value={ordemServico.notificarCliente} onChange={handleChange}/>
					</PanelContent>
				</TabPanel>
				<TabPanel headerTemplate={headerTemplate} disabled={!ordemServico.cliente?.id || !ordemServico.tipo?.length} header="Detalhes do Serviço" leftIcon="fa-solid fa-wrench">
					<EditarOrdemServicoItemFields
							index={0}
							ordemServico={ordemServico}
							setOrdemServico={setOrdemServico}
							messages={messages}
							setMessages={setMessages}
							item={ordemServico.itens[0]}
							setItem={setItem}
							handleChange={event => {
								ordemServico.itens[0][event.name] = event.value;
								setOrdemServico({...ordemServico});
							}}
							handleSalvar={validarSalvar}
							setLaudo={setLaudo}
					/>
				</TabPanel>
				{/*<TabPanel headerTemplate={headerTemplate} disabled={!ordemServico.cliente?.id || !ordemServico.tipo?.length} header="Atendimento por Chat" leftIcon="fa-solid fa-headset">*/}
				{/*	<OrdemServicoChat ordemServico={ordemServico}/>*/}
				{/*</TabPanel>*/}
				<TabPanel headerTemplate={headerTemplate} disabled={!ordemServico.id || !ordemServico.contrato?.acordoNivelServicoSuporte} header="Prazos do SLA" leftIcon="fa-solid fa-signature">
					<SLA ordemServico={ordemServico}/>
				</TabPanel>
				<TabPanel headerTemplate={headerTemplate} header="Histórico" leftIcon="fa-solid fa-clock">
					<Acompanhamento ordemServico={ordemServico}/>
				</TabPanel>
				<TabPanel disabled={!ordemServico.id} headerTemplate={headerTemplate} header="Laudos" leftIcon="fa-solid fa-diagnoses">
					<EditarOrdemServicoLaudo laudos={ordemServico.laudos} setLaudo={setLaudo} ordemServico={ordemServico} handleChange={handleChange}/>
				</TabPanel>
				<TabPanel
					disabled={
						!ordemServico.id
						|| !["Laboratório", "Laboratorio", "Backoffice"].some(l => ordemServico.acompanhamentos?.some(osa => osa.etapa?.descricao?.includes(l)))
					}
					headerTemplate={headerTemplate}
					header="Envio para Laboratório"
					leftIcon="fa-solid fa-boxes"
				>
					<EditarOrdemServicoEncaminhamentoLaboratorio ordemServico={ordemServico} setOrdemServico={setOrdemServico}/>
				</TabPanel>
			</FormTab>
		</BlockUI>
	);

}
