import React from "react";
import {ClienteAutoComplete} from "../../../components/autocomplete/ClienteAutoComplete";
import {InputNumber} from "../../../components/inputnumber";
import {PedidoStatusSelect} from "../../../components/multiselect/pedidoStatusMultiselect";
import {Calendar} from "../../../components/calendar";
import {VendedorAutoComplete} from "../../../components/autocomplete/VendedorAutoComplete";
import {ProdutoAutoComplete} from "../../../components/autocomplete/produtoAutoComplete";
import {PedidoTipoOrcamentoMultiSelect} from "../../../components/multiselect/PedidoTipoOrcamentoMultiSelect";
import {useQueryParams} from "../../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../../utils/defaultQueryParams";

export function FiltrosPedidoVenda() {

    const {params, handleChange} = useQueryParams(QueryParamsKeys.PedidoVenda);

    return (
        <>
            <Calendar col={2} name="inclusaoInicio" label="Período de Inclusão" value={params.inclusaoInicio} onChange={handleChange}/>
            <Calendar col={2} name="inclusaoFim" maxDate={new Date()} label="." value={params.inclusaoFim} onChange={handleChange}/>
            <Calendar col={2} name="entregaInicio" label="Período de Entrega" value={params.entregaInicio} onChange={handleChange}/>
            <Calendar col={2} name="entregaFim" label="." value={params.entregaFim} onChange={handleChange}/>
            <PedidoStatusSelect col={2} name="status" value={params.status} onChange={handleChange}/>
            <InputNumber col={2} name="numero" label="Número" value={params.numero} onChange={handleChange} min={0} placeholder="o nº do pedido"/>
            <ClienteAutoComplete col={3} value={params.cliente} name="cliente" onChange={handleChange}/>
            <VendedorAutoComplete col={3} value={params.vendedor} name="vendedor" onChange={handleChange}/>
            <ProdutoAutoComplete col={3} value={params.produto} name="produto" onChange={handleChange}/>
            <PedidoTipoOrcamentoMultiSelect label="Tipo de Orçamento" onChange={handleChange} name="tipoOrcamento" value={params.tipoOrcamento} col={3}/>
        </>
    );

}
