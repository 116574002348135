import React, {useContext, useState} from "react";
import {PanelContent} from "../../../components/panel";
import {Dialog, DialogFooter} from "../../../components/dialog";
import {Button} from "../../../components/button";
import {optionsStatusRessolda, ordemServicoService, restricoesButtons} from "../../../service/ordemServicoService";
import {ProgressDialog} from "../../../components/progressdialog/ProgressDialog";
import {DialogContext} from "../../../utils/dialogContext";
import {SelectButton} from "../../../components/selectbutton";
import {InputText} from "../../../components/inputtext";
import {Checkbox} from "../../../components/checkbox";

export function ConcluirRessolda({ordensServico, onModalClose}) {

    const {showDialog} = useContext(DialogContext);
    const [visible, setVisible] = useState(true);
    const [criterio, setCriterio] = useState({
        status: "CONCLUIDO",
        observacoes: "",
        placaCondenada: false
    });

    async function handleConcluir() {
        showDialog(
            <ProgressDialog onProgress={async setProgress => {
                let progress = 0;
                for (const ordemServico of ordensServico) {
                    await ordemServicoService.concluirRessoldas({
                        ...criterio,
                        id: ordemServico.id
                    });
                    setProgress(++progress / ordensServico.length);
                }
                handleClose();
                await onModalClose();
            }}/>
        );
    }

    function handleChange(event) {
        setCriterio(prevCriterio => ({...prevCriterio, [event.name]: event.value}));
    }

    function handleClose() {
        setVisible(false);
    }

    return (
        <Dialog visible={visible} style={{width: "650px"}} onHide={handleClose} header="Conclusão de Serviço de Recuperação">
            <PanelContent>
                <div className="col-12">
                    A recuperação da placa foi concluída com sucesso?
                </div>
                <div className="col-3"/>
                <SelectButton onChange={handleChange} itemTemplate={restricoesButtons} col={6} name="status" value={criterio.status} options={optionsStatusRessolda} reduced/>
                <div className="col-3"/>
                <InputText col={12} label="Observações" name="observacoes" multiline style={{height: "10em"}} onChange={handleChange}/>
                <Checkbox disabled={criterio.status !== "MALSUCEDIDO"} col={12} name="placaCondenada" value={criterio.placaCondenada} onChange={handleChange} label="Placa condenada (não é possível recuperá-la)"/>
            </PanelContent>
            <DialogFooter>
                <Button icon="fas fa-check" label="Concluir" onClick={handleConcluir}/>
            </DialogFooter>
        </Dialog>
    );

}
