import React from "react";
import {Column} from "primereact/column";
import {iconStatus, labelStatus} from "../../service/ordemServicoService";
import moment from "moment";
import {formatMinutes} from "../../utils/numberFormatter";
import {DataTable} from "../../components/datatable";
import {renderPosicaoPrevisaoAtendimento} from "../OrdensServico/EditarOrdemServico";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";

const previsoesAtendimentoHeaderGroup = (
	<ColumnGroup>
		<Row>
			<Column rowSpan={2} style={{width: "3em"}}/>
			<Column rowSpan={2} header="Nome do Termo"/>
			<Column colSpan={2} header="Status"/>
			<Column rowSpan={2} style={{width: "13em"}} header="Início"/>
			<Column colSpan={2} header="Previsão"/>
			<Column rowSpan={2} style={{width: "13em"}} header="Conclusão"/>
			<Column rowSpan={2} header="Δt" style={{width: "6em"}}/>
		</Row>
		<Row>
			<Column header="Inicial"/>
			<Column header="Final"/>
			<Column header="Concluir Até" style={{width: "13em"}}/>
			<Column header="Tempo Restante" style={{width: "13em"}}/>
		</Row>
	</ColumnGroup>
);

export function SLA({ordemServico}) {
	const tableSLA = ordemServico.previsoesAtendimento?.length ? (
		<div>
			<div className="my-2 font-bold">SLA de Laboratório</div>
			<SLATable ordemServico={ordemServico} previsoesAtendimento={ordemServico.previsoesAtendimento}/>
		</div>
	) : null;
	const tableSLASuporte = ordemServico.previsoesAtendimentoSuporte?.length ? (
		<div>
			<div className="my-2 font-bold">SLA de Suporte</div>
			<SLATable ordemServico={ordemServico} previsoesAtendimento={ordemServico.previsoesAtendimentoSuporte}/>
		</div>
	) : null;
	return (
		<div className="flex flex-column gap-2">
			{tableSLA}
			{tableSLASuporte}
		</div>
	);
}

export function SLATable({ordemServico, previsoesAtendimento}) {
	return (
		<DataTable headerColumnGroup={previsoesAtendimentoHeaderGroup} value={previsoesAtendimento}>
			<Column style={{textAlign: "center"}} header="Posição" field="posicao" body={renderPosicaoPrevisaoAtendimento}/>
			<Column header="Nome do Termo" field="descricao" body={ospa => ospa.descricao}/>
			<Column
				header="Status Inicial"
				field="statusInicial"
				body={ospa => <span>{iconStatus[ospa.statusInicial]} {labelStatus[ospa.statusInicial]}</span>}
			/>
			<Column
				header="Status Final"
				field="statusFinais"
				body={ospa => ospa.fim ? ospa.statusFinais.map(sf =>
						<span className="mr-2">{iconStatus[sf]} {labelStatus[sf]}</span>) :
					<span className="text-gray-400">Em andamento</span>}
			/>
			<Column
				style={{width: "13em", textAlign: "center"}}
				header="Início"
				field="inicio"
				body={ospa => moment(ospa.inicio).format("DD/MM/YYYY HH:mm")}
			/>
			<Column
				style={{width: "13em", textAlign: "center"}}
				header="Prazo"
				field="previsao"
				body={ospa => moment(ospa.previsao).format("DD/MM/YYYY HH:mm")}
			/>
			<Column
				header="Tempo Restante" field="tempoRestante" style={{textAlign: "center"}} body={ospa => {
				if (ordemServico.status === "SUSPENSA" && ospa.statusInicial !== "SUSPENSA") {
					return "Pausado";
				}
				const minutes = moment(ospa.previsao).diff(ospa.fim || moment(), "minutes");
				return <span style={{color: minutes > 0 ? "inherit" : "#F44336"}}>{minutes < 0 ? "[-] " : ""}{formatMinutes(Math.abs(minutes))}</span>;
			}}
			/>
			<Column
				style={{width: "13em", textAlign: "center"}}
				header="Conclusão"
				field="fim"
				body={ospa => ospa.fim && moment(ospa.fim).format("DD/MM/YYYY HH:mm")}
			/>
			<Column
				header="Δt" field="intervalo" style={{textAlign: "center"}} body={ospa => {
				const minutos = [];
				if (ordemServico.status === "SUSPENSA" && ospa.statusInicial !== "SUSPENSA") {
					return <div>Pausado</div>;
				} else if (ospa.inicio) {
					minutos.push(
						<div style={{whiteSpace: "nowrap"}}>
							<i
								title="Tempo Transcorrido"
								style={{color: "#4CAF50"}}
								className="fas fa-stopwatch"
							/> {formatMinutes(Math.max(0, Math.abs(moment(ospa.inicio).diff(moment(ospa.fim), "minutes")) + 1 - ospa.minutosSuspenso))}
						</div>
					);
				}
				if (ospa.minutosSuspenso) {
					minutos.push(
						<div style={{whiteSpace: "nowrap"}}>
							<i
								title="Tempo em Espera"
								style={{color: "#F44336"}}
								className="fas fa-stopwatch"
							/> {ospa.minutosSuspenso < 0 ? "[-]" : ""} {formatMinutes(Math.abs(ospa.minutosSuspenso))}
						</div>
					);
				}
				return minutos;
			}}
			/>
		</DataTable>
	);
}