import React, {useContext, useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {Button} from "../../components/button";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {ProgressDialog} from "../../components/progressdialog/ProgressDialog";
import {DialogContext} from "../../utils/dialogContext";
import {ordemServicoService} from "../../service/ordemServicoService";
import {useAuth} from "../../context/AuthContext";
import {InformationDialog} from "../../components/dialog/InformationDialog";

export function TrocarResponsavel({ordensServico, status, onModalClose}) {

    const {usuario, roles} = useAuth();
    const {showDialog} = useContext(DialogContext);
    const [visible, setVisible] = useState(true);
    const [responsavel, setResponsavel] = useState(usuario);

    function handleClose() {
        setVisible(false);
    }

    async function handleSalvar() {
        if (responsavel?.id) {
            showDialog(<ProgressDialog onProgress={async onProgress => {
                let progresso = 0;
                for (const ordemServico of ordensServico) {
                    await ordemServicoService.reatribuir({ordemServico: ordemServico.id, operador: usuario, responsavel}, status === "MANUTENCAO" && !roles.LABG);
                    onProgress(++progresso / ordensServico.length);
                }
                if (onModalClose) {
                    await onModalClose();
                }
            }}/>);
            handleClose();
        } else {
            showDialog(<InformationDialog message="Selecione o responsável"/>);
        }
    }

    return (
        <Dialog header="Atribuir Responsável" style={{width: "750px"}} visible={visible} onHide={handleClose}>
            <PanelContent>
                <UsuarioAutoComplete readOnly={!roles.LABG && status !== "LAUDO"} col={12} label="Responsável" name="responsavel" value={responsavel} onChange={e => setResponsavel(e.value)}/>
                {status === "MANUTENCAO" && !roles.LABG ? <div><i className="fas fa-big fa-exclamation-triangle"/> Esta solicitação ficará pendente de aprovação do(a) responsável pelo laboratório</div> : null}
            </PanelContent>
            <DialogFooter>
                <Button success icon="fas fa-save" onClick={handleSalvar} label="Confirmar"/>
                <Button secondary icon="fas fa-times" onClick={handleClose} label="Fechar"/>
            </DialogFooter>
        </Dialog>
    )

}
