import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {labelRestricao} from "../../../../service/ordemServicoService";
import {formatMinutes} from "../../../../utils/numberFormatter";
import {sum} from "../../../../utils/mathUtils";
import React from "react";

export function restricoesExpander(kpi) {
    return (
        <DataTable style={{margin: "-1em", borderBottom: "1px solid #666666"}} value={kpi.restricoes}>
            <Column style={{width: "8em"}} />
            <Column sortable header="Restrição" field="restricao" body={kpir => labelRestricao[kpir.restricao]}/>
            <Column sortable header="Atendimentos" style={{width: "12em", textAlign: "right"}} field="atividades" body={kpir => kpir.atividades.length}/>
            <Column sortable header={<span title="Somatória de Tempo em Atendimento">ΣΔt</span>} style={{width: "12em", textAlign: "right"}} field="totalMinutes" body={kpir => formatMinutes(kpir.totalMinutes)}/>
            <Column sortable header={<span title="Tempo Médio de Atendimento" style={{textDecoration: "overline"}}>Δt</span>} style={{width: "12em", textAlign: "right"}} field="averageMinutes" body={kpir => formatMinutes(kpir.averageMinutes)}/>
        </DataTable>
    );
}

export function operadoresExpander(rpi) {
    return (
        <DataTable style={{margin: "-1em", borderBottom: "1px solid #666666"}} value={rpi.operadores}>
            <Column style={{width: "8em"}} />
            <Column sortable header="Operador" field="operador" body={kpir => kpir.operador.nome}/>
            <Column sortable header="Atendimentos" style={{width: "12em", textAlign: "right"}} field="atividades" body={kpir => kpir.atividades.length}/>
            <Column sortable header={<span title="Somatória de Tempo em Atendimento">ΣΔt</span>} style={{width: "12em", textAlign: "right"}} field="totalMinutes" body={kpir => formatMinutes(kpir.totalMinutes)}/>
            <Column sortable header={<span title="Tempo Médio de Atendimento" style={{textDecoration: "overline"}}>Δt</span>} style={{width: "12em", textAlign: "right"}} field="averageMinutes" body={kpir => formatMinutes(kpir.averageMinutes)}/>
        </DataTable>
    );
}

export function processarRelatorioKpi(items) {
    const kpiData = {};
    const rpiData = {};
    for (const item of items) {
        if (!kpiData[item.usuario.id]) {
            kpiData[item.usuario.id] = {
                usuario: item.usuario,
                restricoes: {}
            };
        }
        if (!kpiData[item.usuario.id].restricoes[item.restricao]) {
            kpiData[item.usuario.id].restricoes[item.restricao] = {
                restricao: item.restricao,
                atividades: []
            };
        }
        kpiData[item.usuario.id].restricoes[item.restricao].atividades.push(item);
        if (!rpiData[item.restricao]) {
            rpiData[item.restricao] = {
                restricao: item.restricao,
                operadores: {}
            };
        }
        if (!rpiData[item.restricao].operadores[item.usuario.id]) {
        }
        rpiData[item.restricao].operadores[item.usuario.id] = {
            operador: item.usuario,
            atividades: []
        };
        rpiData[item.restricao].operadores[item.usuario.id].atividades.push(item);
    }
    const kpis = Object.values(kpiData).sort((a, b) => a.usuario.nome.localeCompare(b.usuario.nome));
    const rpis = Object.values(rpiData).sort((a, b) => a.restricao.localeCompare(b.restricao));
    for (const kpi of kpis) {
        kpi.totalActivities = Object.values(kpi.restricoes).map(r => r.atividades.length).reduce(...sum);
        kpi.totalMinutes = Object.values(kpi.restricoes).map(r => r.atividades.map(a => a.intervaloMinutos).reduce(...sum)).reduce(...sum);
        kpi.averageMinutes = Math.round(kpi.totalMinutes / kpi.totalActivities);
        kpi.restricoes = Object.values(kpi.restricoes);
        for (const kpir of kpi.restricoes) {
            kpir.totalMinutes = kpir.atividades.map(a => a.intervaloMinutos).reduce(...sum);
            kpir.averageMinutes = Math.round(kpir.totalMinutes / kpir.atividades.length);
        }
    }
    for (const rpi of rpis) {
        rpi.totalActivities = Object.values(rpi.operadores).map(r => r.atividades.length).reduce(...sum);
        rpi.totalMinutes = Object.values(rpi.operadores).map(r => r.atividades.map(a => a.intervaloMinutos).reduce(...sum)).reduce(...sum);
        rpi.averageMinutes = Math.round(rpi.totalMinutes / rpi.totalActivities);
        rpi.operadores = Object.values(rpi.operadores);
        for (const rpir of rpi.operadores) {
            rpir.totalMinutes = rpir.atividades.map(a => a.intervaloMinutos).reduce(...sum);
            rpir.averageMinutes = Math.round(rpir.totalMinutes / rpir.atividades.length);
        }
    }
    return {kpis, rpis};
}
