import React, { useState } from "react";
import { AutoComplete } from ".";
import { contratoService } from "../../service/contratoService";

export function ContratoAutoComplete(props) {
  const [contratos, setContratos] = useState([]);
  const [emptyMessage, setEmptyMessage] = useState("");

  function completeMethod(event) {
    const query = [];
    if (event.query.length) {
      query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);
    }
    if (props.cliente) {
      query.push(`cliente=${props.cliente}`);
    }
    if (props.ativos) query.push(`ativo=true`);
    if (props.suporte) query.push(`suporte=true`);
    query.push("status=ATIVO");

    contratoService.autocomplete(query).then((res) => {
      setContratos(res);
      if (!res[0]) {
        setEmptyMessage("Nenhum contrato encontrado");
      } else {
        setEmptyMessage("");
      }
    });
  }

  return (
    <AutoComplete
      col={3}
      placeholder="CNPJ (somente nº), nome fantasia ou razão social"
      label="Contrato"
      name="contrato"
      value={props.value}
      onChange={props.onChange}
      completeMethod={completeMethod}
      suggestions={contratos}
      invalid={emptyMessage}
      icon="fa-solid fa-file-signature"
      {...props}
    />
  );
}
