import React, {useState} from 'react';
import {AutoComplete} from '.';
import {servicoService} from '../../service/servicoService';
import {codigosRessolda, isRessolda} from "../../service/ordemServicoService";

export function ServicoAutoComplete(props) {

	const [servicos, setServicos] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("");

	function completeMethod(event) {
		const query = [
			"page=0",
			"size=100"
		];
		if (event.query?.length) query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);
		servicoService.autocomplete(query).then(servicos => {
			if (props.onlyRecover) {
				servicos = servicos.filter(s => isRessolda(s.codigo));
			}
			if (props.notRecover) {
				servicos = servicos.filter(s => !isRessolda(s.codigo));
			}
			setServicos(servicos);
			if (!servicos.length) {
				setEmptyMessage("Nenhum registro encontrado");
			} else {
				setEmptyMessage("");
			}
		});
	}

	return (
		<AutoComplete col={3} placeholder="descrição" label="Serviço" name="servico" completeMethod={completeMethod} suggestions={servicos} invalid={emptyMessage} icon="fa-solid fa-boxes" forceSelection {...props}/>
	);

}
