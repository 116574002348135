import React, {useMemo, useState} from 'react';
import { FiltrosTipoComissao } from './FiltrosTipoComissao';
import { TabelaTipoComissao } from './TabelaTipoComissao';

export function TipoComissao() {
    const [comissao, setTipoComissao] = useState([]);

    return (
        <div>
            <FiltrosTipoComissao setValue={useMemo(() => setTipoComissao, [])}/>
            <TabelaTipoComissao value={useMemo(() => comissao, [comissao])}/>
        </div>
    );
}