import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from '../../../components/datatable';
import { withDialog } from "../../../utils/dialogContext";
import { ConfirmDialogV2 } from '../../../components/confirmdialog';
import { clienteService } from '../../../service/clienteService';
import { ClienteAutoComplete } from '../../../components/autocomplete/ClienteAutoComplete';
import { toast } from 'react-toastify';
import { useAuth } from '../../../context/AuthContext';
import ClienteRazaoSocialDTOTemplate from '../../Templates/ClienteRazaoSocialDTOTemplate';

export const EditarListaPrecoClientes = withDialog(({ listaPreco, setListaPreco, showDialog, setLoading}) => {
    const {roles} = useAuth();
    const [cliente, setCliente] = useState(null)
    const clienteTemplate = (data) => {
        return <ClienteRazaoSocialDTOTemplate data={data} />
    }

    const desvincularCliente = useCallback((clienteRetirado) => {        
        showDialog(
            <ConfirmDialogV2
                message="Tem certeza de que deseja desvincular esse cliente da lista de preço?"
                onYes={() => {
                    setLoading(true)
                    toast.promise(
                        clienteService.buscar(clienteRetirado.id).then((clienteCompleto) =>{
                            clienteCompleto.listaPreco = null;
                            clienteService.salvar(clienteCompleto).then(() => {
                                setListaPreco(listaPreco => {
                                    return {
                                        ...listaPreco,
                                        clientes: listaPreco?.clientes?.filter(cliente => cliente?.id !== clienteCompleto.id)
                                    }
                                });
                            }).finally(() => setLoading(false));
                        }),
                        {
                            "pending": `Desvinculando cliente da lista de preço. Aguarde...`,
                            "success": `Cliente desvinculado com sucesso!`,
                            "error": {
                                render(e) {
                                    setLoading(false)
                                    return `Falha ao desvincular cliente`;
                                }
                            }
                        }
                    );
                   
                }}
            />
        );
    }, [setListaPreco, showDialog, setLoading]);

    const handleAddCliente = async (event) => {
        setLoading(true)
        setCliente(null)    
        
        const id = event?.value?.id

        let clienteCompleto = await clienteService.buscar(id)

        clienteCompleto = {
            ...clienteCompleto,
            listaPreco: listaPreco
        }
        
        await clienteService.salvar(clienteCompleto)

        const clienteDto = {
            autoComplete: clienteCompleto?.autoComplete,
            autoCompleteCondicaoPagamento: clienteCompleto?.autoCompleteCondicaoPagamento,
            clienteId: clienteCompleto?.vwCliente?.clienteId,
            condicaoPagamentoCodigo: clienteCompleto?.condicaoPagamentoCodigo, 
            condicaoPagamentoDescricao: clienteCompleto?.condicaoPagamentoDescricao, 
            cpfCnpj: clienteCompleto?.cpfCnpj, 
            grupoCpfCnpj: clienteCompleto?.grupoCpfCnpj,
            id: clienteCompleto?.id,
            inscricaoEstadual: clienteCompleto?.vwCliente?.inscricaoEstadual,
            municipio: clienteCompleto?.municipio,
            nomeFantasia: clienteCompleto?.nomeFantasia, 
            razaoSocial: clienteCompleto?.razaoSocial,
            status: clienteCompleto?.status, 
            tipo: clienteCompleto?.vwCliente?.tipo, 
            uf: clienteCompleto?.vwCliente?.uf, 
        }

        setListaPreco({
            ...listaPreco, 
            clientes: [clienteDto , ...listaPreco.clientes]
        })

        setLoading(false)
    } 
 
    const leftToolbarTemplate = () => {
        if(listaPreco?.id){
            return (
                <ClienteAutoComplete  
                    col={6} 
                    value={cliente} 
                    onChange={(e) => setCliente(e.value)} 
                    onSelect={handleAddCliente} 
                    repetidos={listaPreco?.clientes?.map(cliente => cliente?.id)}
                    disabled={!roles.LTPC}
                />
            )
        }else{
            return (
                <div className='flex align-items-center	p-3	'>
                    <span style={{color: "#F59E0B"}}> 
                        <i className="fa-xl fa-solid fa-triangle-exclamation mr-2"/>
                    </span>
                    <strong>
                        Para incluir novos clientes nesta lista de preço, é necessário que ela esteja previamente salva!
                    </strong>
                </div>
            )
        }
    }

    const acoesTemplate = (data, rowData) => {  
        return (
            <div className='flex align-items-center justify-content-around'>
                {
                    !!roles.LTPC &&
                    <div className='p-2 cursor-pointer' title='Desvincular cliente' onClick={() => desvincularCliente(data, rowData)}> 
                        <i className='fa-solid fa-link-slash fa-big text-color-secondary'/>
                    </div>
                }
            </div>
        )
    }

    return (
        <>
            <div className='col-12 ml-2'>
                <div style={{backgroundColor: '#f8f9fa', border: '1px solid #dee2e6'}} className="mb-0 p-1 col-12 grid p-fluid border-round">
                    {leftToolbarTemplate()}
                </div>
            </div>
            <div className='col-12 pt-0'>
                <DataTable value={listaPreco.clientes} 
                    valuelength={listaPreco?.length}
                    paginator={false}                    
                    emptyMessage="Nenhum cliente vinculado a essa lista de preço. ">                
                    <Column sortable field="clienteId" header="Cliente"  style={{ width: '*' }} />
                    <Column sortable field="nomeFantasia" header="Nome Fantasia"  style={{ width: '*' }} body={clienteTemplate}/>
                    <Column sortable field="razaoSocial" header="Razão Social"  style={{ width: '*' }} />                    
                    <Column sortable field="cpfCnpj" header="CNPJ"  style={{ width: '*'}} />
                    <Column sortable field="municipio" header="Município"  style={{ width: '*' }} />                    
                    <Column sortable field="uf" header="UF"  style={{ width: '*', textAlign: 'center' }} />
                    <Column sortable field="inscricaoEstadual" header="Inscrição Estadual"  style={{ width: '*' }} />
                    <Column header="Ações" key="acoes" style={{width: "6em", textAlign: "center"}} body={acoesTemplate} />
                </DataTable>
            </div>
        </>
    );
});
