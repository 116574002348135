import React, {useEffect, useMemo} from "react";
import {TabPanel, TabView} from "primereact/tabview";
import {TabelaOrdemServico} from "./TabelaOrdemServico";
import {formatNumber} from "chart.js/helpers";
import {usePagesAndTabs} from "../../utils/PageAndTabContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {tabHeader} from "./utils";
import {BackofficeActionProvider} from "./OrdemServicoActionContext";

export function GuiasBackoffice({value, onChangeSelecionados}) {

    const {pageAndTab, setTab} = usePagesAndTabs(QueryParamsKeys.Backoffice);

    const ordensServico = useMemo(() => ({
        recepcao: value.filter(os => os.status === "RECEPCAO"),
        expedicao: value.filter(os => os.status === "EXPEDICAO"),
        fechada: value.filter(os => os.status === "FECHADA")
    }), [value]);

    useEffect(() => {
        if (!Object.keys(ordensServico).map(k => ordensServico[k].length)[pageAndTab.tab]) {
            setTab(0);
        }
    }, [ordensServico]);

    const tabs = [
        <TabPanel key="recepcao" header={tabHeader("recepcao", ordensServico)}>
            <TabelaOrdemServico onChangeSelecionados={onChangeSelecionados} status="RECEPCAO" value={ordensServico.recepcao}/>
        </TabPanel>,
        <TabPanel key="expedicao" header={tabHeader("expedicao", ordensServico)}>
            <TabelaOrdemServico onChangeSelecionados={onChangeSelecionados} status="EXPEDICAO" value={ordensServico.expedicao}/>
        </TabPanel>
    ];

    if (ordensServico.fechada?.length) {
        tabs.push(
            <TabPanel key="fechada" header={tabHeader("fechada", ordensServico)}>
                <TabelaOrdemServico onChangeSelecionados={onChangeSelecionados} status="FECHADA" value={ordensServico.fechada}/>
            </TabPanel>
        );
    }

    return (
        <BackofficeActionProvider>
            <div className="tabview-escandaloso">
                <TabView activeIndex={pageAndTab.tab} onTabChange={e => setTab(e.index)} style={{marginTop: "1em"}}>
                    {tabs}
                </TabView>
                <TabelaTotais value={value}/>
            </div>
        </BackofficeActionProvider>
    );

}

function TabelaTotais({value}) {
    return (
        <div className="tabela-os-totais">
            <table>
                <tbody>
                <tr>
                    <td>Total de Registros:</td>
                    <td>{formatNumber(value.length)}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}
