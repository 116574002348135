import React, {useEffect, useState} from 'react';
import {FormEdit} from "../../components/form/Edit";
import {useParams} from "react-router-dom";
import {buildValidator, isEntityRequired} from "../../utils/fieldValidator";
import {useAuth} from '../../context/AuthContext';
import {estoqueMovimentoService} from "../../service/estoqueMovimentoService";
import {EstoqueAutoComplete} from "../../components/autocomplete/EstoqueAutoComplete";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {InputNumber} from "../../components/inputnumber";
import {InputText} from "../../components/inputtext";
import {Calendar} from "../../components/calendar";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";

export function EditarEstoqueMovimento() {

	const {id} = useParams();
	const {roles, usuario} = useAuth();

	const [estoqueMovimento, setEstoqueMovimento] = useState({
		...estoqueMovimentoService.criar(),
		responsavel: usuario
	});
	const [messages, setMessages] = useState(buildValidator());

	function handleChange(event) {
		setEstoqueMovimento({...estoqueMovimento, [event.name]: event.value});
	}

	useEffect(() => {
		if (id !== "new") {
			estoqueMovimentoService.buscar(id).then(setEstoqueMovimento);
		}
	}, [id]);

	return (
		<FormEdit podeSalvar={!!roles.EMVC && (estoqueMovimento.status !== "CANCELADO" && estoqueMovimento.status !== "MOVIMENTADO")}		        
		          descricao={`Movimento de Estoque${estoqueMovimento.numero ? ` nº ${estoqueMovimento.numero}` : ""}`}
		          onValidate={setMessages}
		          service={estoqueMovimentoService}
		          value={estoqueMovimento}				  
		          blocked={id !== "new" && !estoqueMovimento.id}>
			<InputText col={2} disabled value={estoqueMovimento.numero} label="Número"/>
			<InputText col={2} value={estoqueMovimento.notaFiscalNumero} name="notaFiscalNumero" label="Nº da NF" onChange={handleChange}/>
			<InputText col={2} value={estoqueMovimento.notaFiscalSerie} name="notaFiscalSerie" label="Série da NF" onChange={handleChange}/>
			<EstoqueAutoComplete onBlur={() => {
				messages.estoque = isEntityRequired(estoqueMovimento.estoque);
				setMessages({...messages});
			}} required invalid={messages.estoque} col={4} name="estoque" value={estoqueMovimento.estoque} label="Estoque" onChange={handleChange}/>
			<Calendar showTime col={2} value={estoqueMovimento.data} name="data" label="Data" onChange={handleChange}/>
			<ProdutoAutoComplete onBlur={() => {
				messages.produto = isEntityRequired(estoqueMovimento.produto);
				setMessages({...messages});
			}} required invalid={messages.produto} col={4} name="produto" value={estoqueMovimento.produto} label="Produto" onChange={handleChange}/>
			<InputNumber onBlur={() => {
				messages.quantidade = estoqueMovimento.quantidade ? null : "Campo obrigatório";
				setMessages({...messages});
			}} required invalid={messages.quantidade} col={2} label="Quantidade" name="quantidade" value={estoqueMovimento.quantidade} onChange={handleChange}/>
			<UsuarioAutoComplete readOnly onBlur={() => {
				messages.responsavel = isEntityRequired(estoqueMovimento.responsavel);
				setMessages({...messages});
			}} required invalid={messages.responsavel} col={4} name="responsavel" value={estoqueMovimento.responsavel} onChange={handleChange} label="Responsável"/>
			<InputText col={12} value={estoqueMovimento.descricao} onChange={handleChange} name="descricao" label="Identificação de Movimentação"/>
		</FormEdit>
	);

}
