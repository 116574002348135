import React, {useContext, useState} from "react";
import {PanelContent} from "../../components/panel";
import {Dialog, DialogFooter} from "../../components/dialog";
import {Button} from "../../components/button";
import {PrateleiraAutoComplete} from "../../components/autocomplete/PrateleiraAutoComplete";
import {ordemServicoService} from "../../service/ordemServicoService";
import {ProgressDialog} from "../../components/progressdialog/ProgressDialog";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {DialogContext} from "../../utils/dialogContext";
import {useAuth} from "../../context/AuthContext";

export function TrocarPrateleira({ordensServico, onModalClose}) {

    const {usuario} = useAuth();
    const {showDialog} = useContext(DialogContext);
    const [visible, setVisible] = useState(true);
    const [prateleira, setPrateleira] = useState(usuario.bancadaTrabalho);

    async function handleSalvar() {
        if (prateleira?.id) {
            showDialog(<ProgressDialog onProgress={async onProgress => {
                let progresso = 0;
                for (const ordemServico of ordensServico) {
                    await ordemServicoService.registrarMovimentacao(usuario, {numero: ordemServico.numero, prateleira: prateleira.id});
                    onProgress(++progresso / ordensServico.length);
                }
                if (onModalClose) {
                    await onModalClose();
                }
            }}/>);
            handleClose();
        } else {
            showDialog(<InformationDialog header="Aviso" message="Por favor, selecione a prateleira que receberá o equipamento."/>);
        }
    }

    function handleClose() {
        setVisible(false);
    }

    function handleChange(event) {
        setPrateleira(event.value);
    }

    return (
        <Dialog header="Movimentar Equipamento" style={{width: "650px"}} visible={visible} onHide={handleClose}>
            <PanelContent>
                <PrateleiraAutoComplete col={12} value={prateleira} onChange={handleChange}/>
            </PanelContent>
            <DialogFooter>
                <Button success disabled={!prateleira?.id} icon="fas fa-save" onClick={handleSalvar} label="Confirmar"/>
                <Button secondary icon="fas fa-times" onClick={handleClose} label="Fechar"/>
            </DialogFooter>
        </Dialog>
    );

}
